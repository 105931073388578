import { Detail } from './Detail';
import { Init } from './Init';
import RouteStore, { Route } from '../../Store/RouteStore';
import React from 'react';
import { ChooseMraLayout } from './ChooseMraLayout';

RouteStore.routes.resource_mras = new Route({
    path: '/resource/mras/add',
    component: (props) => <Init {...props} />,
});

RouteStore.routes.resource_mras_update = new Route({
    path: '/resource/mras/:id/update',
    component: (props) => <ChooseMraLayout {...props} detailView={false} />,
});

RouteStore.routes.resource_mras_detail = new Route({
    path: '/resource/mras/:id/detail',
    component: (props) => <ChooseMraLayout {...props} detailView={true} />,
});

RouteStore.routes.resource_deleted_mras_detail = new Route({
  path: '/resource/deleted_mras/:id/detail',
  component: (props) => <Detail {...props} deleted={true}/>, /** @todo choose layout ici aussi ? */
});