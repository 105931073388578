import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";

export class TextWidget extends Component {
         constructor(props) {
           super(props);

           this.state = {
             initialRows: null,
             rows: null
           };
         }

         render() {
           const { data } = this.props;

           return <p style={styles}>{data}</p>;
         }
  }


  const styles = {
    textAlign: 'left',
  }