import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Radar } from 'react-chartjs-2';
import MRA from '../../../Services/MRA';
import { Button } from '@material-ui/core';

export const exportRadarChart = (radarRef, mra) => {
    const base64image = radarRef.current.chartInstance.toBase64Image();
    const link = document.createElement('a');
    link.download = `mra-radar-chart-id-${mra.id}.png`;
    link.href = base64image;
    link.click();
};

export const ChartRadarDimensions = (props) => {
    const { radarRef, mra, dimensions, subdimensions, mraScores, radarMaxScale } = props;

    const [data, setData] = useState({});

    const borderColors = ['black', 'purple', 'rgb(228, 8, 120)'];
    const dimensionsOrder = {
        'Model Input(s)': 0,
        'Methodology and design': 1,
        Implementation: 2,
        'On-going monitoring': 3,
        'Model performance': 4,
        Documentation: 5,
        Governance: 6,
    };

    const options = {
        tooltips: {
            enabled: false,
        },
        scale: {
            ticks: {
                beginAtZero: true,
                z: 100,
                // suggestedMax: radarMaxScale,
                max: radarMaxScale,
                stepSize: 1,
                showLabelBackdrop: true,
                display: false,
                fontColor: 'black',
                fontSize: 9,
                // padding: 1,
            },
            gridLines: {
                z: 90,
            },
        },
        legend: {
            labels: {
                filter: (legend) =>
                    !['green', 'softgreen', 'yellow', 'orange', 'red', 'gray', 'black'].includes(legend.text),
                boxWidth: 20,
                padding: 6,
            },
            position: 'top',
        },
    };

    const plugins = [
        {
            beforeInit: function (chart, _options) {
                chart.legend.afterFit = function () {
                    this.height = this.height + 10;
                };
            },
        },
    ];

    useEffect(() => {
        const populateDataset = async () => {
            let datasetCount = 0;
            let sortedDimensions = [];

            dimensions.forEach((d) => {
                sortedDimensions[dimensionsOrder[d.title]] = d;
            });
            const applicableDimensions = {};
            const overviewScores = await MRA.getMraScoresByDimensionGroupedByType(mra.id);
            let datasets = sortedDimensions.reduce((datasets, d) => {
                Object.values(overviewScores[d.id]).forEach((s) => {
                    let label = 'missing label';
                    if (s && s.humanType && s.humanType.label) {
                        label = s.humanType.label.replace('residual', '');
                    }

                    const scoreToDisplay = s.score;
                    applicableDimensions[d.title] = applicableDimensions[d.title] ? applicableDimensions[d.title] : s.score;

                    if (datasets[s.type]) {
                        datasets[s.type] = {
                            ...datasets[s.type],
                            data: [...datasets[s.type].data, scoreToDisplay],
                        };
                    } else {
                        datasets[s.type] = {
                            label,
                            data: [scoreToDisplay],
                            backgroundColor: 'rgba(0,0,0,0)',
                            borderColor: borderColors[datasetCount],
                            pointRadius: 0,
                            pointHoverRadius: 0,
                            borderWidth: 1,
                            order: -1 * datasetCount + 3, // smaller = priority
                        };
                        datasetCount++;
                    }
                });

                return datasets;
            }, {});

            datasets = Object.values(datasets);
            setData({
                labels: sortedDimensions.map((d) => {
                    let label = d.title;
                    if (d.isKeyDimension) {
                        label = `${label}*`;
                    }
                    const hasScores = applicableDimensions[d.title] > 0;
                    if (!hasScores) {
                        label = `N/A (${label})`;
                    }
                    return label;
                }),
                datasets: [
                    {
                        label: 'gray',
                        data: [0.95, 0.95, 0.95, 0.95, 0.95, 0.95, 0.95],
                        backgroundColor: '#9FBB78',
                        borderColor: '#D9D9D9',
                        borderWidth: 0,
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        order: 100,
                    },
                    {
                        label: 'softgreen',
                        data: [2, 2, 2, 2, 2, 2, 2],
                        backgroundColor: '#9EBA77',
                        borderColor: '#9EBA77',
                        borderWidth: 0,
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        order: 101,
                    },
                    {
                        label: 'green',
                        data: [3, 3, 3, 3, 3, 3, 3],
                        backgroundColor: '#ABB96D',
                        borderColor: '#ABB96D',
                        borderWidth: 0,
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        order: 102,
                    },
                    {
                        label: 'orange',
                        data: [4, 4, 4, 4, 4, 4, 4],
                        backgroundColor: '#D2B454',
                        borderColor: '#D2B454',
                        borderWidth: 0,
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        order: 103,
                    },
                    {
                        label: 'red',
                        data: [4.5, 4.5, 4.5, 4.5, 4.5, 4.5, 4.5],
                        backgroundColor: '#CA4640',
                        borderColor: '#CA4640',
                        borderWidth: 0,
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        order: 104,
                    },
                    {
                        label: 'black',
                        data: [5, 5, 5, 5, 5, 5, 5],
                        backgroundColor: '#784D4C',
                        borderColor: '#784D4C',
                        borderWidth: 0,
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        order: 105,
                    },
                    ...datasets,
                ],
            });
        }
        populateDataset();
    }, [dimensions, mraScores, mra]);

    return (
        <>
            <div>
                <Radar ref={radarRef} data={data} options={options} plugins={plugins} />
                <label
                    style={{
                        fontSize: '10px',
                        marginLeft: '20px',
                        color: '#7A7A7A',
                        paddingTop: '20px',
                        display: 'block',
                    }}
                >
                    *key dimensions
                </label>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button variant="outlined" color="primary" onClick={() => exportRadarChart(radarRef, mra)}>
                    <i className="fa fa-download" style={{ marginRight: '6px' }}></i>
                    <span>Export</span>
                </Button>
            </div>
        </>
    );
};
ChartRadarDimensions.propTypes = {
    radarRef: PropTypes.any,
    mra: PropTypes.object,
    dimensions: PropTypes.array,
    subdimensions: PropTypes.array,
    mraScores: PropTypes.any,
    radarMaxScale: PropTypes.number,
};
