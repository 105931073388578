import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Services/Modal';
import { Typography } from '@material-ui/core';
import { ModalContent } from './ModalContent';
import { ButtonBar } from './ButtonBar';
import { ActionButton } from './ActionButton';

export const ConfirmModal = (props) => {
    const { callback, message, button, autoCloseModal = true } = props;

    const [progress, setProgress] = useState(false);

    const save = () => {
        setProgress(true);
        if (callback) {
            callback();
        }
        if (autoCloseModal) Modal.close(); // TODO fermer à la fin de callback si c'est une Promise ?
    };

    return (
        <ModalContent>
            <Typography component={'p'}>{message}</Typography>
            <ButtonBar>
                <ActionButton onClick={Modal.close} disabled={progress}>
                    {button?.cancel || 'Cancel'}
                </ActionButton>
                <ActionButton
                    loading={progress}
                    onClick={save}
                >
                    {button?.confirm || 'Confirm'}
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
};
ConfirmModal.propTypes = {
    message: PropTypes.string,
    /** Labels sur le bouton d'annulation et celui de confirmation */
    button: PropTypes.shape({
        cancel: PropTypes.string,
        confirm: PropTypes.string,
    }),
    callback: PropTypes.func,
    /** Si true, la Modal est fermée automatiquement */
    autoCloseModal: PropTypes.bool,
}
