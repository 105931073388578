import React from 'react';
import {APIResource} from "../Services/APIResource/APIResource";
import { BulkVerify } from '../Services/BulkActions/BulkVerify/BulkVerify';
import {ROLE} from "../Services/User/User";

export const ApiResourceDefaultParams = {
    id: 'legal_entities',
    name: 'Legal Entities',
    icon: 'building',
    fieldForTitle: 'title'
};

export default class LegalEntityAdmin {
    constructor(){
        this.configure();
    }

    configure(){
        let legalEntities = new APIResource(ApiResourceDefaultParams);
        legalEntities
            .setFields({
                id: {
                    title: 'ID'
                },
                uid: {
                    title: 'Unique ID'
                },
                title: {
                    title: 'Title',
                    maxLength:255, 
                },
                bfcCode: {
                    title: 'BFC Code'
                },
                copernicCode: {
                    title: 'Copernic Code'
                },
                parentLegalEntity: {
                    title: 'Step',
                    type: 'entityTree',
                    params: {
                        resource: 'legal_entities',
                        instanceId: 'legal_entities_parent_all',
                        displayField: 'title',
                        multi: false,
                        links: false,
                        orExistsMultiFilterNotAllowed: true,
                        endpoints: {
                            getAll: 'legal_entities/parent/all',
                        },
                    },
                },
                verified: { title: 'Verified', type: 'bool' },
                outOfRepository: { title: 'Out of repository', type: 'bool' },
            })
            .setLayout({
                tabs: {
                    'Legal Entity': {
                        rows: [
                            {
                                panels: {
                                    Identification: {
                                        cols: 6,
                                        fields: [
                                            'id',
                                            'uid',
                                            'title',
                                            'bfcCode',
                                            'copernicCode',
                                            'parentLegalEntity',
                                        ],
                                    },
                                    Properties: {
                                        cols: 6,
                                        fields: [
                                            'verified',
                                            'outOfRepository',
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                menuItem: { title: 'Legal Entities' },
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genInsertView({
                fields: [
                    'uid',
                    'title',
                    'bfcCode',
                    'copernicCode',
                    'parentLegalEntity',
                    'verified',
                    'outOfRepository',
                ],
                menuItem: { title: 'Add' },
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'LegalEntity';
                },
            })
            .genEditView({
                fields: [
                    'uid',
                    'title',
                    'bfcCode',
                    'copernicCode',
                    'parentLegalEntity',
                    'verified',
                    'outOfRepository',
                ],
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genDetailView({
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .addBulkAction(BulkVerify, {
                resource: legalEntities,
                icon: 'check',
                resourceId: "legal_entities",
            })
        ;
    }
}
