import ParameterStore from "../../Store/ParameterStore";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import React, {Component} from "react";
import {observer} from "mobx-react";

export class ModelStepProvider extends Component{
    render(){
        return <ModelStep
            key={Date.now()}  //Fix pour le rerender dans le contexte du didUpdate sans différence d'objet entre props et prevProps
            {...this.props}
        />
    }
}

export const ModelStep = observer(class ModelStep extends Component {
    entity;

    constructor(props) {
        super(props);

        this.entity = this.props.entity;
        this.state = {
            validatedStep: null
        }
    }

    componentDidMount() {
        this.entity = this.props.entity;
        this.getValidatedStep();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.entity !== prevProps.entity)) {
            this.entity = this.props.entity;
            this.getValidatedStep();
        }
    }

    getValidatedStep() {
        let entity = this.entity;

        if(typeof this.props.forceStep !== 'undefined'){
            this.setState({validatedStep: this.props.forceStep});
            return;
        }

        if (entity === null || typeof entity === "undefined") {
            this.setState({validatedStep: null});
            return;
        }

        if(![
                ParameterStore('MODEL_STATUS_NON_MODEL'),
                ParameterStore('MODEL_STATUS_DRAFT'),
                ParameterStore('MODEL_STATUS_UNDER_DECLARATION'),
                ParameterStore('MODEL_STATUS_ACTIVE')
            ].includes(entity.modelStatus)
        ){
            this.setState({validatedStep: null});
            return;
        }

        let validatedStep = null;
        if (entity.modelStatus === ParameterStore('MODEL_STATUS_NON_MODEL')) {
            validatedStep = 0;
        }
        if (entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT') && (entity.expectedStatus === null || typeof entity.expectedStatus === 'undefined')) {
            validatedStep = 1;
        }
        if (entity.modelStatus === ParameterStore('MODEL_STATUS_DRAFT') && typeof entity.expectedStatus === 'string') {
            validatedStep = 2;
        }
        if (entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') && (entity.expectedStatus === null || typeof entity.expectedStatus === 'undefined')) {
            validatedStep = 3;
        }
        if (entity.modelStatus === ParameterStore('MODEL_STATUS_UNDER_DECLARATION') && typeof entity.expectedStatus === 'string') {
            validatedStep = 4;
        }
        if (entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') && typeof entity.expectedStatus === 'string') {
            validatedStep = 5;
        }
        if (entity.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE') && (entity.expectedStatus === null || typeof entity.expectedStatus === 'undefined')) {
            validatedStep = null;
        }

        this.setState({validatedStep});
    }

    render() {
        return (
            <div>
                { this.state.validatedStep !== null ?
                    <Stepper activeStep={this.state.validatedStep}>
                        <Step key={1}>
                            <StepLabel>Identification tree</StepLabel>
                        </Step>
                        <Step key={2}>
                            <StepLabel>Draft</StepLabel>
                        </Step>
                        <Step key={3}>
                            <StepLabel>Candidate (awaiting validation)</StepLabel>
                        </Step>
                        <Step key={4}>
                            <StepLabel>Model under declaration</StepLabel>
                        </Step>
                        <Step key={5}>
                            <StepLabel>Ready to be inventoried (awaiting validation)</StepLabel>
                        </Step>
                        <Step key={6}>
                            <StepLabel>Inventoried model</StepLabel>
                        </Step>
                    </Stepper>
                : null }
            </div>
        );
    }

});
