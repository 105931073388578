import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalContent } from '../../Modal/ModalContent';
import { Typography } from '@material-ui/core';
import { ActionButton } from '../../Modal/ActionButton';
import { ButtonBar } from '../../Modal/ButtonBar';
import Modal from '../../../Services/Modal';

export const AskConfirmationChangeModal = (props) => {
    const { callback, closeModalImmediately = true } = props;

    const [loading, setLoading] = useState(false);

    return (
        <ModalContent>
            <Typography component={'p'}>
                You are about to edit this MRA. The change will be applied on all the models of the MRA. If you want to
                edit the MRA for a specific model, please detach it first and then edit it.
            </Typography>
            <ButtonBar>
                <ActionButton
                    loading={loading}
                    onClick={async () => {
                        closeModalImmediately ? Modal.close() : setLoading(true);
                        callback(setLoading);
                    }}
                >
                    Continue
                </ActionButton>
                <ActionButton
                    disabled={loading}
                    onClick={() => {
                        Modal.close();
                    }}
                >
                    Cancel
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
};
AskConfirmationChangeModal.propTypes = {
    /** Le callback reçoit setLoading pour réactiver les boutons, si closeModalImmediately est false */
    callback: PropTypes.func,
    closeModalImmediately: PropTypes.bool,
};

export const askConfirmationChange = (callback) => {
    Modal.open({
        title: 'Edit MRA',
        content: <AskConfirmationChangeModal callback={callback} />,
    });
}