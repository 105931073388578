import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { ModalContent } from './ModalContent';

export const ModalLoadingContent = () => {
    return (
        <ModalContent style={{ alignItems: 'center' }}>
            <CircularProgress />
        </ModalContent>
    );
};
