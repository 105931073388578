import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Http from '../../../Services/Http';
import Alert from '../../../Services/Alert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import String from '../../../Services/String';
import { Link } from 'react-router-dom';
import User from '../../../Services/User/User';
import { APIResource } from '../../../Services/APIResource/APIResource';
import AppBarStore from '../../../Store/AppBarStore';
import Navigation from '../../../Services/Navigation';
import FieldProviderStore from '../../../Services/APIResource/FieldProviders/__FieldProviderStore';
import { SuffixButton } from '../../SuffixButton/SuffixButton';

class Notice extends Component {
    constructor(props) {
        super(props);
        AppBarStore.title = 'Model certification campaign';
        this.mccResource = new APIResource({
            id: 'model_certification_campaigns',
        });
        this.state = {
            init: false,
            id: null,
            file: null, //File to upload
            fileName: null, //FileName to use on applying duplication progress
            progress: false,
            duplicates: null,
            duplicatesToProceed: {},
            default: false,
            message: null,
            messageStatus: null,
            modelCertificationCampaign: null,
            isDeleted: false,
            deletionComment: null
        };
    }

    componentDidMount() {
        this.setState({ id: this.props.match.params.id });
        this.mccResource
            .apiGetOne(this.props.match.params.id, true)
            .then((modelCertificationCampaign) => {
                // if i'm not owner or MRM
                const isModelOwner =
                    modelCertificationCampaign.modelOwner ===
                    `/api/users/${User.getId()}`;
                this.setState({ modelCertificationCampaign });
                if (!isModelOwner) {
                    Navigation.router.history.push(
                        `/resource/my_model_certification_campaigns/list`
                    );
                }
                this.setState({ init: true })
            });
    }

    accept = () => {
        const { modelCertificationCampaign } = this.state;
        modelCertificationCampaign.acknowledgmentOfReceipt = true;
        this.mccResource.apiPut(modelCertificationCampaign);
        this.goToCampaign();
    };

    goToCampaign = () => {
        Navigation.router.history.push(
            `/resource/my_model_certification_campaigns/${this.state.id}/detail`
        );
    };

    render() {
        if (!this.state.init) {
            return null;
        }
        const { modelCertificationCampaign } = this.state;
        return (
            <Grid className={'import container'}>
                {this.state.modelCertificationCampaign.isDeleted ? (
                    <Paper
                        style={styles.gridPaper}
                        className="ressource-api-box"
                    >
                        <h1 className="background-linear-gradient">
                            <div style={styles.title}>
                                This certification campaign was deleted
                            </div>
                        </h1>
                        <div style={styles.gridBody}>
                            <div
                                className={
                                    'alert alert-warning'
                                }
                                role="alert"
                            >
                                {
                                    this.state.modelCertificationCampaign.deletionComment ?
                                        String.nlToBr(this.state.modelCertificationCampaign.deletionComment)
                                        : 'Sorry, no user has write an explanation for this status.'
                                }
                            </div>
                        </div>
                    </Paper>
                ) : null}
                {this.state.message ? (
                    <div
                        className={
                            'alert alert-' +
                            (this.state.messageStatus
                                ? this.state.messageStatus
                                : 'primary')
                        }
                        role="alert"
                    >
                        {String.nlToBr(this.state.message)}
                    </div>
                ) : null}
                {this.state.modelCertificationCampaign && !this.state.modelCertificationCampaign.isDeleted && (
                    <div>
                        <Paper
                            style={styles.gridPaper}
                            className="ressource-api-box"
                        >
                            <h1 className="background-linear-gradient">
                                <div style={styles.title}>
                                    {' '}
                                    New certification campaign on your models
                                </div>
                            </h1>
                            <div style={styles.gridBody}>
                                {!this.state.modelCertificationCampaign
                                    .acknowledgmentOfReceipt
                                    ? String.nlToBr(`
                                    A new MRM certification process has been launched.

In the Model Risk Management framework, a key quality control of the models inventory is the certification process. It aims at ensuring that all models are registered in the inventory and that the information is up to date.

As Model Owner, you are kindly requested to attest the completeness and the accuracy of the models inventory:
• Confirmation that all the models you own are recorded in the inventory. Otherwise, you must notify MRM of any model you own that is not recorded ;
• Confirmation that the data on your scope of models in the inventory is complete and accurate. Otherwise, you should update any information that may be missing, incomplete or incorrect ;
• Information on any new model under development and/or model to be retired.

As a first step, please acknowledge that you are informed of the launch of the campaign
                                    `)
                                    : 'You have acknowledged this campaign.'}
                            </div>
                        </Paper>
                        {!this.state.modelCertificationCampaign
                            .acknowledgmentOfReceipt && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className="save button-general"
                                    onClick={this.accept}
                                >
                                    I acknowledge that I've received this campaign
                                </Button>
                            )}
                        {this.state.modelCertificationCampaign &&
                            this.state.modelCertificationCampaign
                                .acknowledgmentOfReceipt && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className="save button-general"
                                    onClick={() => this.goToCampaign()}
                                >
                                    Open campaign
                                </Button>
                            )}
                    </div>
                )}
            </Grid>
        );
    }
}

const styles = {
    blockHeightStyle: {
        paddingBottom: 5,
        marginBottom: 35,
    },
    title: {
        color: 'white',
        padding: 10,
    },
    gridPaper: {
        marginTop: 15,
        marginBottom: 15,
        height: '100%',
    },
    gridBody: {
        padding: 15,
        paddingTop: 0,
        color: 'black',
        fontSize: 18,
        textAlign: 'center',
    },
};

export default observer(Notice);
