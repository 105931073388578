import React, {Component} from "react";
import PropTypes from 'prop-types';
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import FieldProviderStore from "../../Services/APIResource/FieldProviders/__FieldProviderStore";
import {SuffixButton} from "../SuffixButton/SuffixButton";
import {HelperButton} from "../HelperButton/HelperButton";
import {hasGroupEntityBPCE, hasOneGroupEntityNTX, userHasRoleMRM} from "../../Store/ParameterStore";
import Http from "../../Services/Http";
import APIResourceStore from "../../Store/APIResourceStore";
import { genEditComponent } from "../../Services/APIResource/Components/ResourceEdit/ResourceEdit";

export class TieringTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entity: null,
            model: null,
            available: null,
            quantifiedMaterialityUnit: null,
            quantifiedMaterialityUnitOverride: null,
            quantifiedMaterialityUnitFinal: null,
            riskCategory: null,
            detailedUse: null,
            BPCE: false,
            NTX: false,
            table: null,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.init(this.props.entity);
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if ((this.props.entity !== prevProps.entity)) {
            this.setState({table: null}); // On force un full rerender pour les champs textes non contrôlés qui restent vides sinon
            this.init(this.props.entity);
        }
    }

    init(entity = null) {
        this.setState({entity: entity}, () => this.setState({table: this.renderTable()}));
        if(entity.model){
            this.isAutomaticCalculationAvailable(entity);
        }
    }

    setLocalTiering(local){
        let entity = this.state.entity;
        entity.local = local;
        this.setState({entity: entity}, () => this.setState({table: this.renderTable()}));
        this.handleChange('local', entity.local);
    }

    isAutomaticCalculationAvailable(entity) {
        let BPCE = false;
        let NTX = false;
        APIResourceStore.resources.allModels.getItemFromResourcePath(entity.model).then((model) => {
            BPCE = hasGroupEntityBPCE(model);
            NTX = hasOneGroupEntityNTX(model);
            Http.get(`models/${model.id}/tiering/auto`).then((response) => {
                this.setState({
                    BPCE: BPCE,
                    NTX,
                    available: response.available,
                    riskCategory: response.riskCategory,
                    detailedUse: response.detailedUse,
                }, () => this.setState({table: this.renderTable()}));
            });
        });

    }

    handleChange (fieldId, value) {
        const fieldType = this.props.resource.fields[fieldId]?.type;
        let entity = this.state.entity;
        entity[fieldId] = value;
        this.setState({entity: entity}, () => {
            if (fieldType === 'float') this.setState({table: this.renderTable()});
        }); // pas besoin de rerender la table, les champs sont à jour sauf les float
        this.props.resource.currentEditingActions.handleChange(fieldId, value, this.state.entity);
    }

    renderField(fieldId, display = false){
        let entity = this.state.entity;

        if(this.props.context === 'detail'){
            display = true;
        }

        if(
            this.props.context === 'edit'
            && !userHasRoleMRM()
            && entity.verifiedByMrm === true
        ){
            display = true;
        }

        let field = {...this.props.resource.fields[fieldId]};
        let displayComponent = null;
        let value = entity && fieldId in entity ? entity[fieldId] : null;

        if(display === true){
            field.required = false;
            if (field.display) {
                displayComponent = field.display(
                    field,
                    entity[fieldId],
                    entity,
                    {}
                );
            } else if (
                field.type &&
                FieldProviderStore[field.type]
            ) {
                displayComponent = FieldProviderStore[
                    field.type
                    ].getDisplay(
                    field,
                    entity[fieldId],
                    entity,
                    {}
                );
            } else {
                displayComponent = FieldProviderStore.default.getDisplay(
                    field,
                    entity[fieldId],
                    entity,
                    {}
                );

            }
            return (
                <div
                    key={fieldId}
                    className={
                        'detail-field ressource-api-field' +
                        (field.helperTextDisplay
                            ? ' with-helper-button'
                            : '') +
                        (field.suffix
                            ? ' with-suffix-button'
                            : '')
                    }
                >
                    {displayComponent}
                    {field.suffix ? (
                        <SuffixButton
                            suffix={field.suffix}
                            entity={entity}
                        />
                    ) : null}
                    {field.helperTextDisplay ? (
                        <HelperButton
                            helperText={
                                field.helperTextDisplay
                            }
                            entity={entity}
                        />
                    ) : null}
                </div>
            );
        }

        const onChange = (value) =>
            this.handleChange(fieldId, value);
        
        const editComponent = genEditComponent(
            field,
            value,
            onChange,
            entity,
        );
        return (
            <div
                className={
                    'edit-field ressource-api-field' +
                    (field.helperTextEdit
                        ? ' with-helper-button'
                        : '') +
                    (field.suffix
                        ? ' with-suffix-button'
                        : '')
                }
                key={fieldId}
            >
                {editComponent}

                {
                    editComponent
                    && field.suffix
                        ? (
                            <SuffixButton
                                suffix={field.suffix}
                                entity={entity}
                            />
                        ) : null}
                {
                    editComponent
                    && field.helperTextEdit
                        ? (
                            <HelperButton
                                helperText={
                                    field.helperTextEdit
                                }
                                entity={entity}
                            />
                        ) : null}
            </div>
        );
    }

    renderLocal(){
        if(
            this.props.context === 'detail'
            || this.state.entity === null
        ){
            return null;
        }
        if(this.state.entity.local === false || typeof this.state.entity.local === 'undefined'){
            return (
                <Button
                    className={'local button-table button-show-version'}
                    title=''
                    variant="contained"
                    onClick={() => this.setLocalTiering(true)}
                    color="primary"
                >
                    <Icon className={"fa fa-plus"}></Icon>
                    Add local tiering
                </Button>
            );
        }else{
            return (
                <Button
                    className={'local button-table button-show-version'}
                    title=''
                    variant="contained"
                    onClick={() => this.setLocalTiering(false)}
                    color="primary"
                >
                    <Icon className={"fa fa-minus"}></Icon>
                    Remove local tiering
                </Button>
            );
        }
    }

    renderTable() {
        if (!this.state.entity) {
            return null;
        }
        let displaySpecialQuantifiedMateriality = this.props.resource.fields['specialQuantifiedMateriality'].displayCondition(this.state.entity);
        let displaySpecialQuantifiedMaterialityOverride = this.props.resource.fields['specialQuantifiedMaterialityOverride'].displayCondition(this.state.entity);
        let nbLine = 7;
        if(!this.state.entity.local){ nbLine--; }
        if(!displaySpecialQuantifiedMateriality && !displaySpecialQuantifiedMaterialityOverride){ nbLine--; }

        let isMrmUser = userHasRoleMRM();
        return (
            <table>
                <thead>
                    <tr>
                        <th colSpan={2} className={'title section w30'}></th>
                        { !this.state.entity.createdByMrm ?
                                <th className={'title section w23'}>Declarer</th>
                        : null }
                        <th className={'title section w23'}>Automatic calculation</th>
                        <th className={'title section w23'}>MRM</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowSpan={nbLine} className={'materiality section'}>
                            Materiality
                        </td>
                        <td>
                            Metrics
                        </td>
                        { !this.state.entity.createdByMrm ?
                            <td>
                                {this.renderField('metrics', isMrmUser)}
                            </td>
                        : null }
                        <td>
                            {this.renderField('metricsAuto', true)}
                        </td>
                        <td>
                            {this.renderField('metricsOverride', !isMrmUser)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Quantified materiality
                        </td>
                        { !this.state.entity.createdByMrm ?
                            <td>
                                {this.renderField('quantifiedMateriality', isMrmUser)}
                            </td>
                        : null }
                        <td className={'na'}></td>
                        <td>
                            {this.renderField('quantifiedMaterialityOverride', !isMrmUser)}
                        </td>
                    </tr>
                    {
                        displaySpecialQuantifiedMateriality || displaySpecialQuantifiedMaterialityOverride ?
                            <tr>
                                <td>
                                    Pricing and valuation thresholds
                                </td>
                                { !this.state.entity.createdByMrm ?
                                    <td className={!displaySpecialQuantifiedMateriality ? 'na' : ''}>
                                        {
                                            displaySpecialQuantifiedMateriality ?
                                                this.renderField('specialQuantifiedMateriality', isMrmUser)
                                            : null
                                        }
                                    </td>
                                : null }
                                <td className={'na'}></td>
                                <td className={!displaySpecialQuantifiedMaterialityOverride ? 'na' : ''}>

                                    {
                                        displaySpecialQuantifiedMaterialityOverride ?
                                            this.renderField('specialQuantifiedMaterialityOverride', !isMrmUser)
                                            : null
                                    }
                                </td>
                            </tr>
                        : null
                    }
                    <tr>
                        <td>
                            Materiality quantification date
                        </td>
                        { !this.state.entity.createdByMrm ?
                            <td>
                                {this.renderField('materialityQuantifyingDate', isMrmUser)}
                            </td>
                        : null }
                        <td className={'na'}></td>
                        <td>
                            {this.renderField('materialityQuantifyingDateOverride', !isMrmUser)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Materiality level
                        </td>
                        { !this.state.entity.createdByMrm ?
                            <td>
                                {this.renderField('materialityLevel', isMrmUser)}
                            </td>
                        : null }
                        <td>
                            {this.renderField('materialityLevelAuto', true)}
                        </td>
                        <td>
                            {this.renderField('materialityLevelOverride', !isMrmUser)}
                        </td>
                    </tr>
                    { this.state.entity.local ?
                        <tr>
                            <td>
                                Materiality level for local purposes
                            </td>
                            { !this.state.entity.createdByMrm ?
                                <td>
                                    {this.renderField('materialityLevelLocal', isMrmUser)}
                                </td>
                            : null }
                            <td className={'na'}></td>
                            {userHasRoleMRM() ? (
                                    <td>{this.renderField('materialityLevelLocalOverride')}</td>
                                ) : (
                                    <td className={'na'}></td>
                                )
                            }
                        </tr>
                    : null }
                    <tr>
                        <td>
                            Materiality rationale
                        </td>
                        { !this.state.entity.createdByMrm ?
                            <td>
                                {this.renderField('materialityRationale', isMrmUser)}
                            </td>
                        : null }
                        <td className={'na'}></td>
                        <td>
                            {this.renderField('materialityRationaleOverride', !isMrmUser)}
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={2 + (this.state.entity.local ? 1 : 0)} className={'complexity section'}>
                            Complexity
                        </td>
                        <td>Complexity level</td>
                        {!this.state.entity.createdByMrm ? (
                            <td>{this.renderField('complexityLevel', isMrmUser)}</td>
                        ) : null}
                        <td  className={'na'}></td>
                        <td>{this.renderField('complexityLevelOverride', !isMrmUser)}</td>
                    </tr>
                    {this.state.entity.local ? (
                        <tr>
                            <td>Complexity level for local purposes</td>
                            {!this.state.entity.createdByMrm ? (
                                <td>{this.renderField('complexityLevelLocal', isMrmUser)}</td>
                            ) : null}
                            <td className={'na'}></td>
                            {userHasRoleMRM() ? (
                                <td>{this.renderField('complexityLevelLocalOverride')}</td>
                            ) : (
                                <td className={'na'}></td>
                            )
                            }
                        </tr>
                    ) : null}
                    <tr>
                        <td>Complexity rationale</td>
                        {!this.state.entity.createdByMrm ? (
                            <td>{this.renderField('complexityRationale', isMrmUser)}</td>
                        ) : null}
                        <td className={'na'}></td>
                        <td>{this.renderField('complexityRationaleOverride', !isMrmUser)}</td>
                    </tr>
                    <tr>
                        <td rowSpan={2} className={'model_usage section'}>
                            Model usage
                        </td>
                        <td>
                            Model usage level
                        </td>
                        { !this.state.entity.createdByMrm ?
                            <td>
                                {this.renderField('modelUsageLevel', isMrmUser)}
                            </td>
                        : null }
                        <td>
                            {this.renderField('modelUsageLevelAuto', true)}
                        </td>
                        <td>
                            {this.renderField('modelUsageLevelOverride', !isMrmUser)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Model usage rationale
                        </td>
                        { !this.state.entity.createdByMrm ?
                            <td>
                                {this.renderField('modelUsageRationale', isMrmUser)}
                            </td>
                        : null }
                        <td className={'na'}></td>
                        <td>
                            {this.renderField('modelUsageRationaleOverride', !isMrmUser)}
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={2} className={'external_impact section'}>
                            External impact
                        </td>
                        <td>
                            External impact level
                        </td>
                        { !this.state.entity.createdByMrm ?
                            <td>
                                {this.renderField('externalImpactLevel', isMrmUser)}
                            </td>
                        : null }
                        <td>
                            {this.renderField('externalImpactLevelAuto', true)}
                        </td>
                        <td>
                            {this.renderField('externalImpactLevelOverride', !isMrmUser)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            External impact rationale
                        </td>
                        { !this.state.entity.createdByMrm ?
                            <td>
                                {this.renderField('externalImpactRationale', isMrmUser)}
                            </td>
                        : null }
                        <td className={'na'}></td>
                        <td>
                            {this.renderField('externalImpactRationaleOverride', !isMrmUser)}
                        </td>
                    </tr>

                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={2} className={'title section'}>
                        Tier result
                    </td>
                    { !this.state.entity.createdByMrm ?
                        <td>
                            {this.renderField('tierResult', true)}
                        </td>
                    : null }
                    <td>
                        {this.renderField('tierResultAuto', true)}
                    </td>
                    <td>
                        {this.renderField('tierResultOverride', !isMrmUser)}
                    </td>
                </tr>
                { this.state.entity.local ?
                    <tr>
                        <td colSpan={2} className={'title section'}>
                            Tier result for local purposes
                        </td>
                        { !this.state.entity.createdByMrm ?
                            <td>
                                {this.renderField('tierResultLocal', isMrmUser)}
                            </td>
                        : null }
                        <td className={'na'}></td>
                        {userHasRoleMRM() ? (
                                <td>{this.renderField('tierResultLocalOverride')}</td>
                            ) : (
                                <td className={'na'}></td>
                            )
                        }
                    </tr>
                : null }
                <tr>
                    <td colSpan={2} className={'title section'}>
                        Tier rationale
                    </td>
                    { !this.state.entity.createdByMrm ?
                        <td>
                            {this.renderField('tierRationale', isMrmUser)}
                        </td>
                    : null }
                    <td className={'na'}></td>
                    <td>
                        {this.renderField('tierRationaleOverride', !isMrmUser)}
                    </td>
                </tr>
                </tfoot>
            </table>
        );
    }

    render() {
        if(this.state.entity === null || !this.state.entity.id){
            return null
        }
        return (
            <div className={"tiering-display"}>
                {
                    (userHasRoleMRM() === false && this.state.entity && this.state.entity.verifiedByMrm !== true) 
                    || userHasRoleMRM() ?
                        this.renderLocal()
                    : null
                }
                {this.state.BPCE ?
                    <div className={"alert alert-info"} role="alert"
                         dangerouslySetInnerHTML={{ __html: this.state.available === false ? "No automatic proposition" : `Automatic proposition based on Risk Category <b>${this.state.riskCategory}</b> and main use <b>${this.state.detailedUse}</b>.` }}
                    />
                    : null}
                {this.state.table}
            </div>
        );
    }
}
TieringTable.propTypes = {
    /** Contexte : detail, add, edit ... */
    context: PropTypes.string,
    /** Entité Tiering à afficher */
    entity: PropTypes.any,
    resource: PropTypes.any,
}
