import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
    Box,
    Button,
    CircularProgress,
    FormLabel,
    FormControl,
    Grid,
    Input,
    List,
    ListItem,
    ListItemText,
    Toolbar,
    TextField,
} from '@material-ui/core';

/*
Assets
 */

import AddIcon from '@material-ui/icons/Add';

function TabDefinitionModal(props) {
    const [order, setOrder] = React.useState(
        props.tab ? parseInt(props.tab.order) : 0
    );
    const [name, setName] = React.useState(props.tab ? props.tab.name : '');

    const handleConfigSubmit = () => {
        if (props.onSubmit) {
            const sentCode =
                props.tab && props.tab.code
                    ? props.tab.code
                    : `tab-${_.snakeCase(name)}-${Date.now()}`;
            props.onSubmit({
                id: props.tab && props.tab.id,
                code: sentCode,
                name,
                order: parseInt(order),
            });
        }
    };

    return (
        <div className="tab-definition-modal" style={styles.container}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                xs={12}
            >
                <TextField
                    label="name"
                    type="text"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
                <TextField
                    label="order"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    value={order}
                    onChange={(event) => setOrder(event.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    style={styles.bottomButton}
                    type={'button'}
                    disabled={!name}
                    onClick={handleConfigSubmit}
                    className="button-general"
                >
                    Save
                </Button>
            </Box>
        </div>
    );
}

export default TabDefinitionModal;

const styles = {
    container: {
        overflow: 'hidden',
        width: '100%',
    },
    mainGrid: {
        minHeight: '50vh',
        maxHeight: '55vh',
        overflow: 'auto',
    },
    formControl: {
        marginBottom: '10px',
    },
    bottomButton: {
        width: 'calc(100% - 30px)',
    },
};
