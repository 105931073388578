import React from 'react';
import {DisplayTextField} from "../../../Components/Display/DisplayTextField/DisplayTextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import Chip from '@material-ui/core/Chip';
import {CONTEXT_LIST} from "../APIResource";

class TextareaProvider {
    getDisplay(field, value, entity, props, resource, context){
        return <DisplayTextField {...props} fieldName={field.title} value={value ? value : ''} entity={entity} context={context}/>;
    }

    getDisplayList(field, value, entity, props, resource){
        let string = String(value);
        return this.getDisplay(field, string.substr(0, 200) + (string.length > 200 ? ' [...]' : ''), entity, props, CONTEXT_LIST);
    }

    getEdit(field, value, onChange, entity, routeParams, loading = false){
        /** @see TextProvider.getEdit pour les explications sur les keys */
        const key = loading ? `text_preload_${field.id}` : `text_${field.id}`;
        return (
          <div className={field.errorHelperText ? "field-error-control" : ""}>
            <label>
              {field.title}
              {field.requiredComputed ? " *" : ""}
            </label>
            <TextareaAutosize
              key={key}
              //disabled={loading}
              defaultValue={value ? value : ""}
              onChange={event => onChange(event.target.value)}
              rows={field.rows || 5}
              rowsMax={field.rows || 10}
              ref={field.ref}
              onBlur={field.onBlur}
              required={field.requiredComputed}
              onFocus={field.onFocus}
            />
          </div>
        );
    }

    getFilter(options = {}){
      const { field, value, onChange } = options;
        return (
          <TextField
            id={"filter-" + field.id}
            placeholder="Filter"
            value={value ? value : ""}
            onChange={event => {
              onChange(event.target.value);
            }}
            className="input-text-field-table"
          />
        );
    }

    typeLengthValidate(value, maxLength){
      var hasError = false;
      var errorText = '';
      const len = String(value).length;
      if (len > maxLength){
        hasError = true;
        errorText = 'Too many characters ('+len+'/'+maxLength+')';
      }
      return [hasError,errorText];
    }

    typeFormatValidate(stringValue){
      var hasError = false;
      var errorText = '';
  
      //var patternDiversity = "^(.)\\1+$"; // more than one character type 
      var patternRepetition=/.*([a-zA-Z])\1{3,}/; // is less than 4 alpha numeric repetition in the string ?
  
      if (stringValue.length > 0){                        //allow empty field
        if (stringValue.trim().length === 0){             //do not allow space only string  
          hasError = true;
          errorText = 'Please enter a value';
        }
        else if (!stringValue.search(patternRepetition)){ // do not allow a repetition of alphanumeric character more than 2 times
            let stringArray = stringValue.trim().split(' ');
            let stringWithError = [];
            stringArray.map((s) => {
                if(!s.search(patternRepetition)){
                    stringWithError.push(s);
                }
            })
          hasError = true;
          errorText = `Please enter an acceptable value.` + (stringWithError.length > 0 ? ` (Error : ${stringWithError.join(',')})` : '');
        }
        /*
        else if (stringValue.length > 2){                
          if (stringValue.match(patternDiversity)){ //do not allow only one repetitive character when more than 2 characters
            hasError = true;
            errorText = 'Please enter an acceptable value';
          }
        }
        */        
      }
      return [hasError,errorText];
    }

}

export default new TextareaProvider();