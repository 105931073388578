import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import Color from 'color';
import colors from './colors';
import { arrayColorsLighten } from './utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { toJS } from 'mobx/lib/mobx';

export class BarStackedWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialRows: null,
            rows: null,
        };
    }

    render() {
        const { rows = [], cols = [] } = this.props;
        // Avoid showing dataset legend as we load only one.
        // const legend = { display: false };
        const legend = {
            display: true,
            position: 'right',
            labels: {
                filter: (legend, _) => {
                    return legend.text !== 'Total';
                },
            },
        };

        // Compute bar height for adapting
        // the bar chart in a wide box
        // - a widget height is 25vh and its body is fixed at 20vh
        const barHeight = '250px';
        // const barHeight = Math.round(window.innerHeight * 0.2);

        const datasetsCount = rows.length;

        let datasets = [];

        for (let i = 0; i < datasetsCount; i++) {
            datasets.push({
                label: rows[i].label,
                data: toJS(rows[i].data),
                backgroundColor: colors[i],
                hoverBackgroundColor: colors[i],
                datalabels: {
                    labels: {
                        title: {
                            color: Color(colors[i]).isLight() ? 'black' : 'white',
                        },
                    },
                },
            });
        }

        // Create a dataset for total.
        const totalDatasets = {
            label: 'Total',
            data: [],
            backgroundColor: 'white',
            hoverBackgroundColor: 'white',
            datalabels: {
                labels: {
                    title: {
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            },
        };

        for (const col of cols) {
            let total = 0;

            rows.forEach((row) => {
                total += row.dataByCol[col] || 0;
            });

            totalDatasets.data.push('0010.' + total);
        }

        datasets.push(totalDatasets);

        const data = {
            labels: [...cols],
            datasets,
        };

        return (
            <Bar
                data={data}
                plugins={[ChartDataLabels]}
                legend={legend}
                height={barHeight}
                options={{
                    tooltips: {
                        filter: (data) => {
                            return data.datasetIndex < datasets.length - 1;
                        },
                    },
                    layout: {
                        padding: {
                            top: 0,
                        },
                    },
                    maintainAspectRatio: true,
                    scales: {
                        yAxes: [
                            {
                                stacked: true,
                                ticks: {
                                    beginAtZero: true,
                                },
                                display: false,
                            },
                        ],
                        xAxes: [
                            {
                                stacked: true,
                                ticks: {
                                    padding: 10,
                                    // Show xaxe label on multiline.
                                    callback: function (label) {
                                        if (/\s/.test(label)) {
                                            const splittedLabel = label.split(' ');
                                            const nbCharMax = 10;

                                            return splittedLabel.reduce((acc, txt) => {
                                                const lastElement = acc[acc.length - 1];

                                                if (!lastElement || lastElement.length + txt.length > nbCharMax) {
                                                    acc.push(txt);
                                                } else {
                                                    acc[acc.length - 1] = `${lastElement} ${txt}`;
                                                }

                                                return acc;
                                            }, []);
                                        } else {
                                            return label;
                                        }
                                    },
                                },
                                gridLines: {
                                    drawBorder: true,
                                    drawOnChartArea: false,
                                    drawTicks: false,
                                    display: true,
                                },
                            },
                        ],
                    },
                    plugins: {
                        datalabels: {
                            color: 'black',
                            align: 'center',
                            anchor: 'center',
                            display: (context) => {
                                return context.dataset.data[context.dataIndex];
                            },
                            formatter: (value, ctx) => {
                                if (typeof value === 'string' && value.startsWith('0010.')) {
                                    return value.replace('0010.', '');
                                }
                                return value;
                            },
                        },
                    },
                }}
            />
        );
    }
}
