import React from 'react';
import { Link } from 'react-router-dom';
import {CONTEXT_LIST} from "../APIResource";

class LinksProvider {
  getDisplay(field, value, entity, props, resource, context) {
      return <Link to={value.to} target={field.target} style={{display: "block"}}>{value.label}</Link>
  }

  getDisplayList(field, value, entity, props, resource) {
      return value.map(v => {
          return this.getDisplay(field, v, entity, props, resource, CONTEXT_LIST)
      })
  }

  getEdit(field, value, onChange, entity, routeParams, loading = false) {
    return null;
  }

  getFilter(options = {}) {
    return null;
  }
}

export default new LinksProvider();
