import RouteStore, { Route } from "../../Store/RouteStore";
import React from "react";
import UserSettingsPage from "./settings/UserSettingsPage";

RouteStore.routes.userProfileSettings = new Route({
    path: '/profile/settings',
    component: (props) => <UserSettingsPage {...props} />,
});


