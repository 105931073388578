import React, {Component} from 'react';
import {observer} from "mobx-react";
import Select from "react-select";
import APIResourceStore from "../../Store/APIResourceStore";
import Grid from "@material-ui/core/Grid";
import FieldProviderStore from "../../Services/APIResource/FieldProviders/__FieldProviderStore";


class DependentFieldsEntity extends Component {

    /**
     * Génére le champ d'édition de la valeur, de la même manière que ResourceEdit
     * @param value
     * @param field
     * @param onChange
     * @returns {*}
     */
    genField = (value, field, onChange) => {
        let editComponent = null;
        const fieldId = field.fieldId;
        field.title = "";

        if(field.params) {
            field.params.multi = true;
            field.issueButton = false;
        }

        if(!this.props.readOnly) {
            if (field.edit) {
                editComponent = field.edit(field, value, onChange);
            } else if (FieldProviderStore[field.type] && FieldProviderStore[field.type].getEdit) {
                editComponent = FieldProviderStore[field.type].getEdit(field, value, onChange);
            } else {
                editComponent = FieldProviderStore.default.getEdit(field, value, onChange);
            }
        } else {
            if (field.display) {
                editComponent = field.display(field, value, onChange);
            } else if (FieldProviderStore[field.type] && FieldProviderStore[field.type].getDisplay) {
                editComponent = FieldProviderStore[field.type].getDisplay(field, value, onChange);
            } else {
                editComponent = FieldProviderStore.default.getDisplay(field, value, onChange);
            }
        }
        return (<div className={"edit-field"} key={fieldId}>
            {editComponent}
        </div>);
    }


    render() {
        const {isFirst, resource, field, onChange, value, onValueChange, readOnly} = this.props;

        if(!resource || !resource.fields) {
            return null;
        }

        return (
            <Grid container spacing={1} className="container">
                <Grid item xs={1} className="operator">
                    { !isFirst ? "AND" : ""}
                </Grid>
                <Grid item xs={4}>
                    <Select
                        className="entity-select"
                        options={DependentFieldsSelect.getResourceFields(resource)}
                        isSearchable={!readOnly}
                        isClearable={!readOnly}
                        menuIsOpen={readOnly ? false : undefined}
                        placeholder={"Resource Field"}
                        isMulti={false}
                        onChange={onChange}
                        value={value}
                    />
                </Grid>
                <Grid item xs={7}>
                    {field && field.field && this.genField(value.value, field.field, onValueChange)}
                </Grid>
            </Grid>
        );
    }
}

export const DependentFieldsSelect = observer(class DependentFieldsSelect extends Component {

    state = {
    };

    /**
     * Retourne l'ensemble des resources possédant des champs de l'application
     * @returns {{resourceId: *, label: string, fields: *}[]}
     */
    static getResources() {
        return Object.values(APIResourceStore.resources)
            .filter(resource => resource.instanceId == resource.resourceId)
            .map(resource => ({
                resourceId: resource.resourceId,
                fields: resource.fields,
                label: resource.name
            }))
            .filter(resource => Object.values(resource.fields || {}).length);
    }

    /**
     * Retourne tous les champs pour une resource donnée
     * @param resource
     * @returns {{resourceId: *, field: any, label: *, fieldId: string}[]}
     */
    static getResourceFields(resource) {
        return Object.entries((resource && resource.fields) || {}).map(([fieldId, field]) => ({
            fieldId: fieldId,
            field: JSON.parse(JSON.stringify(field)),
            resourceId: resource.resourceId,
            label: field.title
        }));
    }

    /**
     * Retourne l'object resource selon son ID
     * @param resourceId
     * @returns {*}
     */
    static getResourceById(resourceId) {
        return resourceId ? DependentFieldsSelect.getResources().filter(r => r.resourceId == resourceId).pop() : null;
    }

    /**
     * Retourne l'object field selon son ID et l'ID de sa resource
     * @param resourceId
     * @param fieldId
     * @returns {*}
     */
    static getResourcesFieldById(resourceId, fieldId) {
        return resourceId && fieldId ? DependentFieldsSelect.getResourceFields(APIResourceStore.resources[resourceId]).filter(f => f.fieldId == fieldId).pop() : null;
    }


    handleResourceChange = (k, selection) => {
        let value = this.state.value || [{ resource: null, resourceFields: [null]}];
        if(value[k] != selection) {
            value[k].resource = selection;
            value[k].resourceFields = [null];
            this.setState({value}, this.handleChange);
        }
    };

    handleResourceFieldChange = (k, j, selection) => {
        let value = this.state.value || [{ resource: null, resourceFields: [null]}];
        if(value[k].resourceFields[j] != selection) {
            value[k].resourceFields[j] = selection;
        }
        this.setState({value}, this.handleChange);
    };

    handleValueChange = (k, j, v) => {
        let value = this.state.value;
        value[k].resourceFields[j].value = v;
        this.setState({value}, this.handleChange);
    };

    /**
     * Recrée un objet JSON en fonction des valeurs du state
     * Ignore tous les champs incomplets à la saisie (pas de resource/field/value)
     */
    handleChange = () => {
        let value = this.state.value || [{ resource: null, resourceFields: [null]}];
        let result = [];
        value.map(v => {
            let r = {};

            let entity = {};
            (v.resourceFields || []).filter(f => f && f.fieldId && (Array.isArray(f.value) ? f.value.filter(v => v || v === 0).length : f.value || f.value === 0)).map(f => {
                entity[f.fieldId] = f.value;
            });

            if(v.resource && v.resource.resourceId && Object.values(entity).length) {
                r[v.resource.resourceId] = entity;
                result.push(r);
            }
        });

        this.props.onChange(result);
    };

    /**
     * Convertit l'objet JSON en mutliples éléments représentant chacune des lignes de l'interface
     * @param props
     * @param state
     * @returns {*}
     */
    static getDerivedStateFromProps(props, state) {
        // [].concat(...object) permet de convertir les objets proxy en tableaux itérables
        const value = [].concat(...(props.values || []).map(dependentFields =>
            [].concat(...(Object.entries(dependentFields).map(([resourceId, entity]) => Object.assign({
                resource: DependentFieldsSelect.getResourceById(resourceId),
                resourceFields: Object.entries(entity)
                    .map(([fieldId, value]) => Object.assign({
                        fieldId,
                        value
                    }, DependentFieldsSelect.getResourcesFieldById(resourceId, fieldId)))
            })
        )))));

        return state.value ? state : {
            value: value.length ? value : null
        };
    }

    render(){
        const values = this.state.value || [{ resource: null, resourceFields: [null]}];

        const {readOnly} = this.props;
        if(!readOnly) {
            // Ajouter la prochaine clause OR si le dernier fieldId est rempli
            const last = values[values.length - 1];
            if (last && last.resourceFields && last.resourceFields.filter(f => f && f.fieldId).length) {
                values.push({resource: null, resourceFields: [null]});
            }

            // Ajouter la prochaine clause ET pour chaque block si le dernier fieldId est rempli
            values.map(value => {
                if (value.resourceFields) {
                    const lastField = value.resourceFields[value.resourceFields.length - 1];
                    if (lastField && lastField.fieldId) {
                        value.resourceFields.push(null);
                    }
                }
            });
        }

        return <div className="dependent-fields-editor">
            <label>Only display this parameter value if another field of the edited object has one of these parameter values:</label>
            {values.map((value, k) =>
                <Grid container spacing={0} className="container" key={k}>
                    <Grid item xs={1} className="operator">
                        { k > 0 ? "OR" : ""}
                    </Grid>
                    <Grid item xs={3} className="resource">
                        <Select
                            className="entity-select"
                            options={DependentFieldsSelect.getResources()}
                            isSearchable={!readOnly}
                            isClearable={!readOnly}
                            menuIsOpen={readOnly ? false : undefined}
                            placeholder={"Resource"}
                            isMulti={false}
                            onChange={selection => this.handleResourceChange(k, selection)}
                            value={value.resource}
                        />
                    </Grid>

                    <Grid item xs={8}>
                        {((value || {}).resourceFields || []).map((field, j) =>
                            <DependentFieldsEntity
                                key={j}
                                field={field}
                                onChange={selection => this.handleResourceFieldChange(k, j, selection)}
                                onValueChange={selection => this.handleValueChange(k, j, selection)}
                                resource={value.resource}
                                isFirst={j == 0}
                                value={value.resourceFields[j]}
                                readOnly={readOnly}
                            />
                        )}
                    </Grid>
                </Grid>
            )}
        </div>;
    }
});