import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../../Services/Modal";
import Alert from "../../../Services/Alert";
import Navigation from "../../../Services/Navigation";
import { Typography} from "@material-ui/core";
import { ActionButton } from "../../Modal/ActionButton";
import { ButtonBar } from "../../Modal/ButtonBar";
import { ModalContent } from "../../Modal/ModalContent";
import APIResourceStore from "../../../Store/APIResourceStore";

export const SpecificFrameworkToModelForm = (props) => {
    const { entity } = props;
    /**
     * @type {import('../../../Services/APIResource/APIResource').APIResource}
     */
    const resource = props.resource;

    const [progress, setProgress] = useState(false);

    const convert = async () => {        
        setProgress(true);

        try {
            const model = await resource.apiPut({id: entity.id, specificFramework: null});
            Navigation.router.history.push(`/resource/models/${model.id}/detail`);
            // On le pousse dans toutes les ressources "models"
            Object.values(APIResourceStore.resources).filter(r => r.resourceId === "models").map(modelResource => modelResource.setItem(model));
            Alert.show({ message: `The specific framework ${model.functionalID} has been added to the models list.`, type: "success" });
            Modal.close();
        } catch (error) {
            console.error(error);
            Modal.close();
        }
        setProgress(false);
    }

    return (
        <ModalContent className={'model-reject-form'}>
            <Typography>Do you confirm you want to convert the specific framework {entity.functionalID} to model ? It will be added to the inventory.</Typography>
            <ButtonBar>
                <ActionButton onClick={Modal.close} disabled={progress}>
                    Cancel
                </ActionButton>
                <ActionButton onClick={convert} loading={progress}>
                    Convert
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
}
SpecificFrameworkToModelForm.propTypes = {
    entity: PropTypes.shape({
        id: PropTypes.number,
        specificFramework: PropTypes.string,
        functionalID: PropTypes.string,
    }),
    resource: PropTypes.shape({
        apiPut: PropTypes.func,
    })
}