import React from 'react';
import { APIResource } from './APIResource/APIResource';
import { getIdFromIri } from './utils';
import ParameterStore, {
    getParamByIri,
    userIsLod2,
    userIsLod1,
    getParamBySystemId,
    userIsVal,
} from '../Store/ParameterStore';
import User from './User/User';
import Http from './Http';

import Modal from './Modal';
import { LogicalDeleteForm } from '../Components/Forms/LogicalDeleteForm/LogicalDeleteForm';
import APIResourceStore from '../Store/APIResourceStore';

/**
 * It is asked that MRM has the same rights as LoD2.
 * If one day we want to change this, we should add a new param (eg. "allowMRM").
 *
 * @param {object} model
 */
export const getUserRole = (model) => {
    return userIsLod2(User, model) ? 'LoD2' : userIsLod1(User, model) ? 'LoD1' : '';
};

/**
 * Service pour la gestion des MRA.
 *
 * Il faut noter 1 chose principale : on manipule les MraScore (entité Backend) sous forme de deux objets :
 * - un object "MraScore" qui est une réplique de l'entité backend à peu près
 *   et on peut attacher plusieurs MraScores, sous forme de liste, à un MRA
 * - un objet "ScoresMap" qui est un objet de travail qui stocke les MraScore de façon à n'en avoir qu'un
 *   seul pour une dimension + sous dimension + emetteur donné
 * On se charge donc de convertir les MraScores que l'on reçoit du back sous la forme de cette ScoresMap au départ,
 * et on fait la conversion inverse lors de l'enregistrement.
 */

/**
 * @typedef {Object} MraScore
 * @property {number} id
 * @property {number} score
 * @property {string} justification
 * @property {Object} mraSubdimension - objet de type MraSubdimension à définir
 * @property {string} type
 * @property {boolean} auto
 * @property {string} remediation - Parameter iri
 */

/**
 * @param {MraScore} mraScoreInit
 */
export const mraScoreInit = {
    id: null,
    score: null,
    justification: null,
    mraSubdimension: null,
    type: null,
    remediation: null,
};

/**
 * Clé de voûte de tout le système pour l'instant ^^
 * Cela doit être identique à la même constante en back : Mra::MRA_SCORES_TYPES
 *
 *
 *
    const STATUS_VALIDATED     = "MRA_STATUS_VALIDATED";
    const STATUS_ON_GOING      = "MRA_STATUS_ON_GOING";
    const STATUS_CONFLICT      = "MRA_STATUS_CONFLICT";
    const STATUS_FINALIZED     = "MRA_STATUS_FINALIZED";

    const PROCESS_LOD1_LOD2 = "MRA_PROCESS_LOD1_LOD2";
    const PROCESS_LOD2      = "MRA_PROCESS_LOD2";

    const TYPE_LOD1_INHERENT      = "MRA_SCORE_TYPE_LOD1_INHERENT";
    const TYPE_LOD1_RESIDUAL      = "MRA_SCORE_TYPE_LOD1_RESIDUAL";
    const TYPE_LOD2_RESIDUAL      = "MRA_SCORE_TYPE_LOD2_RESIDUAL";
    const TYPE_COMMITTEE_RESIDUAL = 'MRA_SCORE_TYPE_COMMITTEE_RESIDUAL';
 * */

export const MRA_STATUS = {
    VALIDATED: 'MRA_STATUS_VALIDATED',
    ON_GOING: 'MRA_STATUS_ON_GOING',
    CONFLICT: 'MRA_STATUS_CONFLICT',
    FINALIZED: 'MRA_STATUS_FINALIZED',
    DELETED: 'MRA_STATUS_DELETED',
};

export const MRA_PROCESS = {
    LOD1: 'MRA_PROCESS_LOD1',
    LOD1_LOD2: 'MRA_PROCESS_LOD1_LOD2',
    LOD2: 'MRA_PROCESS_LOD2',
};

export const MRA_SCORES = {
    LOD1_INHERENT: 'MRA_SCORE_TYPE_LOD1_INHERENT',
    LOD1_RESIDUAL: 'MRA_SCORE_TYPE_LOD1_RESIDUAL',
    LOD2_RESIDUAL: 'MRA_SCORE_TYPE_LOD2_RESIDUAL',
    COMMITTEE_RESIDUAL: 'MRA_SCORE_TYPE_COMMITTEE_RESIDUAL',
};
export const MRA_MODEL_RISK_ASSESSMENT_RESULT = {
    NTX: [
        'MRA_NTX_WITHOUT_RESERVATION',
        'MRA_NTX_WITH_MINOR_ISSUES',
        'MRA_NTX_WITH_POA',
        'MRA_NTX_MAJOR_WEAKNESSES',
        'MRA_NTX_INVALIDATED',
    ],
    BPCE: ['MRA_NO_RISK', 'MRA_LOW_RISK', 'MRA_MEDIUM_RISK', 'MRA_HIGH_RISK'],
    GROUP: ['MRA_GRP_NO_RISK', 'MRA_GRP_LOW_RISK', 'MRA_GRP_MEDIUM_RISK', 'MRA_GRP_HIGH_RISK', 'MRA_GRP_INVALIDATED'],
};

export const isNTXRiskAssessmentType = (type) => {
    const found = MRA_MODEL_RISK_ASSESSMENT_RESULT.NTX.find((e) => e === type);
    if (found) {
        return true;
    }
    return false;
};

export const isGROUPRiskAssessmentType = (type) => {
    const found = MRA_MODEL_RISK_ASSESSMENT_RESULT.GROUP.find((e) => e === type);
    if (found) {
        return true;
    }
    return false;
};

export const isBPCERiskAssessmentType = (type) => {
    const found = MRA_MODEL_RISK_ASSESSMENT_RESULT.BPCE.find((e) => e === type);
    if (found) {
        return true;
    }
    return false;
};

const hasScoreAndJustification = (mraScore) => {
    if (!mraScore.score || !mraScore.justification) return false;
    if (mraScore.justification.length > 0) return true;
    return false;
};

const hasJustification = (mraScore) => {
    if (mraScore.justification && mraScore.justification.length > 0) return true;
    return false;
};

const hasScoreOrJustification = (mraScore) => {
    if (!mraScore.score && !mraScore.justification) return false;
    if (mraScore.score > 0) return true;
    if (mraScore.justification.length > 0) return true;
    return false;
};

export const MRA_SCORES_TYPES = {
    MRA_SCORE_TYPE_LOD1_INHERENT: {
        showJustification: false,
        canEdit: {
            role: 'LoD1_disable', // disable lod1 inherent, see https://app.asana.com/0/1134038211401766/1200181836422744/f
            statuses: ['MRA_STATUS_CONFLICT', 'MRA_STATUS_ON_GOING'],
            processes: ['MRA_PROCESS_LOD1_LOD2', 'MRA_PROCESS_LOD1'],
        }, // process est facultatif, s'il n'est pas là on checke que statut
        isValid: {
            MRA_PROCESS_LOD1_LOD2: (_mraScore) => true,
            MRA_PROCESS_LOD1: (_mraScore) => true,
        },
    },
    MRA_SCORE_TYPE_LOD1_RESIDUAL: {
        canEdit: {
            statuses: ['MRA_STATUS_CONFLICT', 'MRA_STATUS_ON_GOING'],
            processes: ['MRA_PROCESS_LOD1_LOD2', 'MRA_PROCESS_LOD1'],
            role: 'LoD1',
        },
        isValid: {
            MRA_PROCESS_LOD1_LOD2: hasJustification,
            MRA_PROCESS_LOD1: hasJustification,
        },
    },
    MRA_SCORE_TYPE_LOD2_RESIDUAL: {
        canEdit: {
            processes: ['MRA_PROCESS_LOD1_LOD2', 'MRA_PROCESS_LOD2'],
            role: 'LoD2',
            statuses: ['MRA_STATUS_ON_GOING', 'MRA_STATUS_CONFLICT'],
        },
        isValid: {
            MRA_PROCESS_LOD2: hasScoreOrJustification,
            MRA_PROCESS_LOD1_LOD2: hasScoreOrJustification,
        },
    },
    MRA_SCORE_TYPE_COMMITTEE_RESIDUAL: {
        canEdit: {
            statuses: ['MRA_STATUS_FINALIZED'],
            processes: ['MRA_PROCESS_LOD1_LOD2'],
            role: 'LoD2',
        },
        isValid: {
            MRA_PROCESS_LOD1_LOD2: (mraScore) => mraScore.score !== null,
        },
    },
};

export const ROLE_ACCESS = {
    LoD1: {
        canEdit: {
            statuses: ['MRA_STATUS_ON_GOING', 'MRA_STATUS_CONFLICT', 'MRA_STATUS_FINALIZED'],
            processes: ['MRA_PROCESS_LOD1_LOD2', 'MRA_PROCESS_LOD1'],
        },
        canCreate: ['MRA_PROCESS_LOD1_LOD2', 'MRA_PROCESS_LOD1'],
    },
    LoD2: {
        canEdit: {
            statuses: ['MRA_STATUS_ON_GOING', 'MRA_STATUS_CONFLICT', 'MRA_STATUS_FINALIZED'],
            processes: ['MRA_PROCESS_LOD1_LOD2', 'MRA_PROCESS_LOD2'],
        },
        canCreate: ['MRA_PROCESS_LOD1_LOD2', 'MRA_PROCESS_LOD2'],
    },
};

export const canEditByProcess = (role, status, process) => {
    if (!role) return false;
    let conditions = ROLE_ACCESS[role].canEdit;
    if (conditions === undefined) return false;

    let processCondition = (conditions.processes && conditions.processes.includes(process)) || !conditions.processes;
    let statusCondition = conditions.statuses && conditions.statuses.includes(status);

    return conditions && processCondition && statusCondition;
};

export const canEditByType = (type, role, status, process) => {
    if (!type) return false;
    let conditions = MRA_SCORES_TYPES[type].canEdit;
    if (conditions === undefined) return false;

    let processCondition = (conditions.processes && conditions.processes.includes(process)) || !conditions.processes;
    let statusCondition = conditions.statuses && conditions.statuses.includes(status);

    return conditions && processCondition && statusCondition && conditions.role === role;
};

export const canEditMraScore = (mraScore, role, status, process) => {
    if (!mraScore.type) return false;
    return canEditByType(getParamByIri(mraScore.type).systemId, role, status, process);
};

/** @todo ça devrait carrément être en back ça
 * est-ce que c'est possible avec des parameters ? ....
 */

/**
 * @typedef {Object} ScoresMap
 *
 * scoresMap : {
 *      dimensionIri1: {
 *          subdimension.id : {
 *              type1: MraScore,
 *              ...
 *          }
 *          ...
 *      },
 *      ...
 * }
 */

/**
 * Service pour gérer les MraScores sous forme de `ScoresMap`
 * Laisser le service MRA se charger de l'appeler.
 *
 * @class MraScoresMap
 */
class MraScoresMap {
    /**
     *
     * @param {ScoresMap} scores - Score à aplatir
     * @param {number} lvl - Niveau en cours
     * @param {number} lvlMax - Par défaut il faut descendre de 2 niveaux pour arriver aux scores à extraire
     */
    flattenScoresMap(scores, lvl = 0, lvlMax = 2) {
        if (lvl >= lvlMax) return Object.values(scores);
        lvl++;

        return Object.values(scores).reduce((acc, o) => [...acc, ...this.flattenScoresMap(o, lvl, lvlMax)], []);
    }

    /**
     *
     * @param {ScoresMap} scores
     * @returns {[MraScore]}
     */
    toMraScores(scores) {
        let mraScoresFull = this.flattenScoresMap(scores);

        /* format MraScore :
            {
                "score": 0, // nullable
                "justification": "string", // nullable
                "type": "string", // role de l'emetteur
                "mra": "/api/iri", // iri du mra que l'on est en train de créer / updater
                "mraSubdimension": "/api/iri",
                "auto": boolean,
                "remediation": "/api/iri",
            }
        */
        let mraScores = mraScoresFull.map((o) => {
            return {
                id: o.id, // si null créera un nouveau MraScore en back
                '@id': o['@id'],
                score: o.score,
                justification: o.justification,
                mraSubdimension: o.mraSubdimension, //['@id'], /** @fixme undefined ! */
                type: o.type,
                auto: o.auto,
                remediation: o.remediation,
            };
        });
        return mraScores;
    }

    /**
     * Initialise un objet vide pour les scores d'une dimension donnée.
     *
     * @param {string} dimensionIri
     * @returns {Object} Objet vide
     */
    initByDimension(scoresMap, dimensionIri) {
        if (!scoresMap[dimensionIri]) {
            scoresMap[dimensionIri] = {};
        }
        return scoresMap;
    }

    /**
     * Initialise un objet vide pour les scores d'une dimension et d'une sous-dimension donnée.
     *
     * @param {*} scoresMap
     * @param {*} dimensionIri
     * @param {*} subdimensionIri
     */
    initByDimensionAndSubdimension(scoresMap, dimensionIri, subdimensionIri) {
        scoresMap = this.initByDimension(scoresMap, dimensionIri);
        if (!scoresMap[dimensionIri][subdimensionIri]) {
            scoresMap[dimensionIri][subdimensionIri] = {};
        }
        return scoresMap;
    }
}

/**
 * Service pour calculer les scores de MRA
 *
 * @class MRA
 */
class MRA {
    mraScoresMap = new MraScoresMap();

    /**
     * Récupération des score pour une sous-dimension.
     * On ajoute également la sous-dimension complète au mraScore. @todo pourquoi ??
     *
     * @param {*} mraSubdimension
     * @param {[MraScore]} mraScores
     * @returns {[MraScore]|[]} - Array of MraScore objects, or []
     */
    getMraScoresBySubdimension(mraSubdimension, mraScores) {
        let scores = mraScores.filter((s) => s.mraSubdimension === mraSubdimension['@id']);
        return scores.length > 0
            ? scores.map((s) => {
                  return { ...s, mraSubdimension };
              })
            : [];
    }

    getSubdimensionsFromDimension(mraDimension, mraSubdimensions) {
        return mraSubdimensions.filter((s) => s.mraDimension === mraDimension['@id']);
    }

    /**
     * Récupération de tous les scores d'une dimension donnée.
     *
     * @param {Object} mraDimension
     * @param {[Object]} mraSubdimensions - Toutes les sous dimensions possibles
     * @param {[MraScore]} mraScores
     * @returns {Array}
     */
    getMraScoresByDimension(mraDimension, mraSubdimensions, mraScores) {
        let subdimensions = this.getSubdimensionsFromDimension(mraDimension, mraSubdimensions);
        let scores = subdimensions
            .map((s) => this.getMraScoresBySubdimension(s, mraScores))
            .reduce((acc, scores) => [...acc, ...scores], []);
        return scores;
    }

    /**
     * @param {number|string} mraId
     */
    async getMraScoresByDimensionGroupedByType(mraId) {
        const data = await Http.get(`mras/${mraId}/calc_scores`);
        /**
         * @type {Object.<string, Object.<string, string>>} {typeSystemId: {dimensionId: score}}
         */
        const dimensionsScoresByType = data['hydra:member'];

        const byDimension = {}
        for (let type in dimensionsScoresByType) {
            for (let dimensionId in dimensionsScoresByType[type]) {
                byDimension[parseInt(dimensionId)] = byDimension[parseInt(dimensionId)] || {}; 
                byDimension[parseInt(dimensionId)][type] = {
                    type,
                    humanType: getParamBySystemId(type),
                    score: parseFloat(dimensionsScoresByType[type][dimensionId]),
                };
            }
        }
        return byDimension;
    }

    /**
     * La règle attendue est qu'un mraScore est valide s'il a un score, ou s'il n'a pas
     * de score qu'il ait une justification (dans le cas où la sous dimension n'est pas
     * applicable).
     *
     * @param {MraScore} mraScore
     */
    isMraScoreValid(mraScore, process) {
        let type = getParamByIri(mraScore.type).systemId;
        return (
            MRA_SCORES_TYPES[type].isValid &&
            MRA_SCORES_TYPES[type].isValid[process] &&
            MRA_SCORES_TYPES[type].isValid[process](mraScore)
        );
    }

    /**
     *
     * @param {ScoresMap} scores
     * @param {Function} filter Callback pour .filter, qui prend un MraScore en paramètre
     */
    scoresMapToMraScores(scores, filter = null) {
        let mraScores = this.mraScoresMap.toMraScores(scores);
        if (filter) {
            mraScores = mraScores.filter((s) => filter(s));
        }
        return mraScores;
    }

    /**
     *
     * @param {[MraScores]} mraScores
     * @param {Array} dimensions Array of MraDimension
     */
    scoresMapFromMraScores(mraScores, dimensions) {
        let scoresMap = this.mraScoresMap.fromMraScores(mraScores, dimensions);
        return scoresMap;
    }

    addMraScoreToScoresMap(mraScore, scoresMap, dimensions) {
        let dimensionIri = '';
        let typeIri = mraScore.type;

        if (typeof mraScore.mraSubdimension === 'string') {
            // mraScore.mraSubdimension est un Iri
            dimensionIri = dimensions.filter((d) => d.mraSubdimensions.includes(mraScore.mraSubdimension)).pop()['@id'];
        } else {
            dimensionIri = mraScore.mraSubdimension.mraDimension;
        }

        let subdimensionIri =
            typeof mraScore.mraSubdimension === 'string' ? mraScore.mraSubdimension : mraScore.mraSubdimension['@id'];

        scoresMap = this.mraScoresMap.initByDimensionAndSubdimension(scoresMap, dimensionIri, subdimensionIri);
        scoresMap[dimensionIri][subdimensionIri][typeIri] = mraScore;
        return { scoresMap, dimensionIri };
    }

    getAllowedTypes(role, status, process) {
        return Object.keys(MRA_SCORES_TYPES).filter((k) => canEditByType(k, role, status, process));
    }

    /**
     * Function pour récupérer le score d'une sous dimension.
     * Contient l'initialisation du score s'il n'existe pas encore.
     *
     * @param {ScoresMap} scoresMap
     * @param {String} dimensionIri - Dimension IRI
     * @param {String} subdimensionIri - Sous-dimension IRI
     * @param {String} role - Role de l'utilisateur ('LoD1' ...)
     * @param {String} status - Statut du MRA sous forme de string (on pourrait tester des Parameters sinon)
     * @param {String} process - Process du MRA sous forme de string (idem)
     * @returns {[MraScore]}
     */
    getMraScoresFromScoresMap(scoresMap, dimensionIri, subdimensionIri, role, status, process, _type = null) {
        scoresMap = this.mraScoresMap.initByDimensionAndSubdimension(scoresMap, dimensionIri, subdimensionIri);

        let subMap = scoresMap[dimensionIri][subdimensionIri];

        let allowedTypes = this.getAllowedTypes(role, status, process);

        /**
         * On ajoute les types qui sont permis pour un process, un role et un statut donné.
         * Les mraScores déjà présents seront utilisés uniquement pour la lecture, mais
         * on les garde.
         */
        allowedTypes.forEach((t) => {
            let typeIri = ParameterStore(t);
            if (!subMap[typeIri]) {
                subMap[typeIri] = {
                    ...mraScoreInit,
                    type: typeIri,
                    mraSubdimension: subdimensionIri,
                }; /**@todo pas la peine de rajouter role on le mettra en back */
            }
        });

        return Object.values(subMap);
    }

    /**
     *
     * @param {*} modelId
     * @param {Object} scores
     */
    makeMra(modelId, scores) {
        let mraScores = this.scoresMapToMraScores(scores);

        return {
            //score: 0, // calculé en front pour l'instant
            //scoreStandardDeviation: 0,
            //status: 0,
            //maxScore: 0,
            //result: 0,
            /* "versionDate": "2020-05-26T17:36:19.252Z",
            "versionCollectionId": 0,
            "evaluationReport": "string",*/
            model: '/api/models/' + modelId,
            mraScores: mraScores,
            open: true,
            //versionAuthor: 'string', // instance de user, mise à jour en prepersist en back
            //sourceMra: 'string', // utilisé quand ??
            //parentMra: ['string'],
            // reviews: ['string'], // à priori c'est géré plus tard ? qd qqun fait une review du mra
        };
    }

    syncScores(mraMaster, mraToSync) {
        const newScores = [];
        mraMaster.mraScores.forEach((score) => {
            const existingScore = mraToSync.mraScores.find(
                (s) => s.mraSubdimension === score.mraSubdimension && s.type === score.type
            );
            if (existingScore) {
                // update mraScores
                newScores.push({
                    ...existingScore,
                    score: score.score,
                    justification: score.justification,
                    auto: score.auto,
                    remediation: score.remediation,
                });
            } else {
                //create new ones
                newScores.push({
                    ...score,
                    id: undefined,
                });
            }
        });
        return newScores;
    }

    /**
     *
     * @param {Number} mraId
     * @param {ScoresMap} scoresMap
     */
    // Used in update view: should use simpleSave instead
    async save(id, scoresMap) {
        const mraResource = new APIResource({ id: 'mras' });
        const mra = await mraResource.getItem(id);

        mra.mraScores = this.scoresMapToMraScores(scoresMap, (s) => s.id !== undefined).map((s) => ({
            ...s,
            id: s.id ? '/api/mra_scores/' + s.id : null,
        }));

        return this.simpleSave(mra);
    }

    irify(mra) {
        // IRI conversions
        mra.criticalDimensions = mra.criticalDimensions.map((criticalDimension) =>
            typeof criticalDimension === 'object' ? criticalDimension['@id'] : criticalDimension
        );

        mra.mraScores = mra.mraScores.map((s) => ({
            ...s,
            id: s['@id'] ? s['@id'] : null,
            mraSubdimension: s.mraSubdimension['@id'] ? s.mraSubdimension['@id'] : s.mraSubdimension,
        }));

        mra.versionAuthor = typeof mra.versionAuthor === 'object' ? mra.versionAuthor['@id'] : mra.versionAuthor;
        return mra;
    }

    /**
     * 
     * @param {object} mra 
     * @returns {Promise<{ success: boolean, payload: any }>}
     */
    simpleSave(mra) {
        const mraResource = new APIResource({ id: 'mras' });
        const mraGroupResource = new APIResource({ id: 'mra_groups' });

        // handle sync multiple mras
        return new Promise((resolve, _reject) => {
            if (mra.group) {
                mraGroupResource.getItem(getIdFromIri(mra.group), true).then((group) => {
                    const mrasUpdate = group.mras.map(async (mraIRI) => {
                        //update with the same score
                        const mraId = getIdFromIri(mraIRI);
                        let mraToSave = mra;
                        if (mraId !== mra.id) {
                            mraToSave = await mraResource.getItem(mraId);
                            // sync scores
                            mraToSave.mraScores = this.syncScores(mra, mraToSave);
                            mraToSave.status = mra.status;
                            mraToSave.process = mra.process;
                            mraToSave.modelRiskAssessmentResult = mra.modelRiskAssessmentResult;
                            mraToSave.versionAuthor = mra.versionAuthor;
                            mraToSave.review = mra.review;
                        }

                        if (!mraToSave.deleted) {
                            mraToSave = this.irify(mraToSave);
                            return await mraResource.apiPut(mraToSave);
                        }
                        return mraToSave;
                    });

                    Promise.all(mrasUpdate).then((results) => {
                        const mraMaster = results.find((r) => r.id === mra.id);
                        if (mraMaster) {
                            // resolve to the MRA currently being edited
                            resolve({ success: true, payload: mraMaster });
                        } else {
                            // TODO: reject instead and refactor the edit page to handle the case
                            resolve({ success: false, payload: results });
                        }
                    });
                });
            } else {
                const mraToSave = this.irify(mra);
                mraResource.apiPut(mraToSave).then((savedMRA) => resolve({ success: true, payload: savedMRA }));
            }
        });
    }

    simpleSubmit(mra, nextStatus, role) {
        const mraResource = new APIResource({ id: 'mras' });
        const mraGroupResource = new APIResource({ id: 'mra_groups' });

        let lod2SubmitWithStatus = mra.lod2Submit;

        if (role === 'LoD1') {
            mra.lod1Submit = true;
        } else {
            mra.lod2Submit = true;
        }

        const currentProcess = getParamByIri(mra.process);

        // until both have submitted, don't change the status
        // in LOD1 + LOD2 process
        if (
            (mra.lod1Submit && mra.lod2Submit) ||
            currentProcess.systemId !== MRA_PROCESS.LOD1_LOD2 ||
            lod2SubmitWithStatus
        ) {
            mra.status = nextStatus['@id'];
            const currentStatus = getParamByIri(mra.status);
            if (currentProcess.systemId === MRA_PROCESS.LOD1_LOD2 && currentStatus.systemId === MRA_STATUS.FINALIZED) {
                const pathScoreTypeCommittee = getParamBySystemId(MRA_SCORES.COMMITTEE_RESIDUAL);
                const committeeScores = mra.mraScores.filter((mraScore) =>
                    [MRA_SCORES.COMMITTEE_RESIDUAL].includes(getParamByIri(mraScore.type).systemId)
                );

                // copy lod2 scores to committee existing scores
                // only if no scores has been set yet
                if (committeeScores.length > 0 && committeeScores.filter((cs) => cs.justification).length === 0) {
                    mra.mraScores = mra.mraScores.map((score) => {
                        if ([MRA_SCORES.COMMITTEE_RESIDUAL].includes(getParamByIri(score.type).systemId)) {
                            // update mra with matching lod2 score
                            const lod2Score = mra.mraScores.find((s) => {
                                if (
                                    getParamByIri(s.type).systemId === MRA_SCORES.LOD2_RESIDUAL &&
                                    s.mraSubdimension === score.mraSubdimension
                                ) {
                                    return true;
                                }
                                return false;
                            });

                            if (lod2Score) {
                                return {
                                    ...score,
                                    score: lod2Score.score,
                                    justification: lod2Score.justification,
                                    type: pathScoreTypeCommittee['@id'],
                                };
                            }
                            return s;
                        }
                        return score;
                    });
                } else if (committeeScores.length === 0) {
                    const lod2Scores = mra.mraScores.filter((mraScore) =>
                        [MRA_SCORES.LOD2_RESIDUAL].includes(getParamByIri(mraScore.type).systemId)
                    );

                    lod2Scores.forEach((lod2Score) => {
                        const { score, justification, mraSubdimension } = lod2Score;
                        mra.mraScores.push({
                            justification,
                            '@type': lod2Score['@type'],
                            score,
                            mraSubdimension,
                            type: pathScoreTypeCommittee['@id'],
                        });
                    });
                }
            }
        }
        // handle sync multiple mras
        return new Promise((resolve, _reject) => {
            if (mra.group) {
                mraGroupResource.getItem(getIdFromIri(mra.group)).then((group) => {
                    const mrasUpdate = group.mras.map(async (mraIRI) => {
                        //update with the same score
                        const mraId = getIdFromIri(mraIRI);
                        let mraToSave = mra;
                        if (mraId != mra.id) {
                            mraToSave = await mraResource.getItem(mraId);
                            // sync scores
                            mraToSave.mraScores = this.syncScores(mra, mraToSave);
                            mraToSave.status = mra.status;
                            mraToSave.process = mra.process;
                            mraToSave.modelRiskAssessmentResult = mra.modelRiskAssessmentResult;
                            mraToSave.versionAuthor = mra.versionAuthor;
                            mraToSave.lod1Submit = true;
                            mraToSave.lod2Submit = mra.lod2Submit;
                            mraToSave.silent = true; // avoid notification duplicates
                        } else {
                            mra.silent = false;
                        }

                        mraToSave = this.irify(mraToSave);
                        return await mraResource.apiPut(mraToSave);
                    });

                    Promise.all(mrasUpdate).then((results) => {
                        const mraMaster = results.find((r) => r.id == mra.id);
                        if (mraMaster) {
                            // resolve to the MRA currently being edited
                            resolve(mraMaster);
                        } else {
                            // TODO: reject instead and refactor the edit page to handle the case
                            resolve(undefined);
                        }
                    });
                });
            } else {
                const mraToSave = this.irify(mra);
                mraResource.apiPut(mraToSave).then((savedMRA) => resolve({ ...savedMRA, mraScores: mra.mraScores }));
            }
        });
    }

    /**
     *
     * @param {Number} mraId
     * @param {Object}  nextStatus - parameter object
     * @param {Object}  role - Lod1 || Lod2 || "" string
     */
    submit(mraId, nextStatus, role) {
        const mraResource = new APIResource({ id: 'mras' });
        const mra = mraResource.getObservableItem(mraId);
        mra.mraScores = mra.mraScores.map((s) => ({
            ...s,
            id: s.id ? '/api/mra_scores/' + s.id : null,
        }));
        /**            ^^^^^^^^^^^^^^^^^^
         * On rajoute l'iri complet au lieu de l'id seul => https://github.com/api-platform/core/pull/3145/files#diff-bbc4a791c60880831ee82ed7288684beR187
         */

        return this.simpleSubmit(mra, nextStatus, role);
    }

    sendNotification(toUser, content, link) {
        Http.post('notifications/new', {
            toUser,
            content,
            link,
            byEmail: true,
        });
    }

    removeMraScoresIds(mraScores) {
        return mraScores.map((mraScore) => ({
            ...mraScore,
            '@id': undefined,
            '@type': undefined,
            scoreForced: undefined,
        }));
    }

    delete(mra, syncMrasIds = []) {
        Modal.open({
            title: 'Deletion confirmation',
            content: (
                <LogicalDeleteForm
                    entity={syncMrasIds && syncMrasIds.length > 0 ? undefined : mra}
                    ids={syncMrasIds}
                    resource={new APIResource({ id: 'mras' })}
                    className="tooltip-top"
                    hideReplaceButton={true}
                    entityType={'mra'}
                    entityTypeLabel={'mra'}
                    entityTitle={mra.id}
                    successMessage={'Your mra has been deleted.'}
                />
            ),
        });
    }

    /**
     *
     * @param {{models, mraSource, process, dimension, context, review}} mraOptions
     */
    async create(mraOptions) {
        const mraResource = new APIResource({ id: 'mras' });
        const modelResource = new APIResource({ id: 'models' });
        const mraGroupResource = new APIResource({ id: 'mra_groups' });
        const reviewResource = new APIResource({ id: 'reviews' });

        if (mraOptions.models.length === 0) {
            return {
                error: true,
                message: `Something went wrong: no models found for creating the MRA`,
            };
        }

        // is the user authorized to create this MRA?
        // eg: LoD1 can't create a LOD2 process
        // eg: LoD2 can't create a LoD1+LoD2 process but should notify LoD1
        // On garde la logique mais elle n'est plus exécutée puisqu'en entrée
        // on limite maintenant au process LoD2 only : {@see https://app.asana.com/0/1134038211401766/1206348402476844/f}
        const shouldNotifyLod1ByModel = [];
        const notificationLabels = {};
        const currentProcess = getParamByIri(mraOptions.process);
        let withMRAOpen = false;
        const review = mraOptions.review ? await APIResourceStore.resources.reviews.getItemFromResourcePath(mraOptions.review) : null;
        const userIsValOfReview = review && userIsVal(User, review);
        const canCreateForAllModels = mraOptions.models.map((model) => {
            model.mrasEntities.forEach((m) => {
                if (m.isOpen && !m.deleted) {
                    withMRAOpen = true;
                }
            });
            /** On ne vérifie pas les droits individuels par Model si l'utilisateur est VAL de la review */
            if (userIsValOfReview) return true;

            const role = getUserRole(model);
            const accessRole = ROLE_ACCESS[role];
            if (accessRole) {
                const access = accessRole.canCreate.includes(currentProcess.systemId);
                const teamLabel = currentProcess.systemId === MRA_PROCESS.LOD1_LOD2 ? 'validation' : 'MRM';
                notificationLabels[model.id] = { process: currentProcess.label, team: teamLabel };
                if (
                    !access &&
                    (currentProcess.systemId === MRA_PROCESS.LOD1_LOD2 ||
                        currentProcess.systemId === MRA_PROCESS.LOD1) &&
                    role === 'LoD2'
                ) {
                    shouldNotifyLod1ByModel.push(true);
                } else {
                    shouldNotifyLod1ByModel.push(false);
                }
                return access;
            }
            return false;
        });

        if (withMRAOpen) {
            return {
                error: true,
                message: `You can't create a MRA while another one is opened.`,
            };
        }

        const cantCreate = canCreateForAllModels.some((cond) => cond === false);
        const shouldNotifyLod1 = shouldNotifyLod1ByModel.length > 0 && shouldNotifyLod1ByModel.every((notify) => notify);

        if (cantCreate) {
            if (shouldNotifyLod1) {
                if (mraOptions.review) {
                    reviewResource.getItemFromResourcePath(mraOptions.review).then((reviewItem) => {
                        mraOptions.models.forEach((m) => {
                            this.sendNotification(
                                getIdFromIri(m.modelOwner),
                                `A MRA is expected by the ${notificationLabels[m.id].team} team as part of the review ${
                                    reviewItem.reviewId
                                }.
Please click and start a ${notificationLabels[m.id].process} in the MRA Tab`,
                                `/resource/reviews/${reviewItem.id}/detail?tab=MRAs`
                            );
                        });
                    });
                } else {
                    mraOptions.models.forEach((m) => {
                        this.sendNotification(
                            getIdFromIri(m.modelOwner),
                            `A MRA is expected by the ${notificationLabels[m.id].team} team.
Please click and start a ${notificationLabels[m.id].process} in the MRA Tab for the model ${m.functionalID}`,
                            `/resource/models/${m.id}/detail?tab=MRA`
                        );
                    });
                }

                return {
                    error: true,
                    message: `LoD1 has been invited to start the MRA process. You will be notified when LoD1 submits its MRA`,
                };
            }
            return {
                error: true,
                message: `Your role does not allow you to create a MRA with these settings and models. 

                Be sure that you are part of the validation team assigned to the model(s).`,
            };
        } else {
            /** On ne peut plus créer de MRA avec un process autre que LOD2
             * donc ce code n'est plus utile, à supprimer dans quelques temps
             * quand le besoin sera bien couvert.
             * 
            const scopeResource = new APIResource({ id: 'scopes', name: 'Scopes' });
            if (currentProcess.systemId === MRA_PROCESS.LOD1_LOD2) {
                // notify LOD2 if lod1+lod2 process created by LOD1
                if (mainRole === 'LoD1') {
                    if (mraOptions.review) {
                        reviewResource.getItemFromResourcePath(mraOptions.review).then((reviewItem) => {
                            let assignedValidator = reviewItem.assignedValidator;
                            if (assignedValidator) {
                                this.sendNotification(
                                    getIdFromIri(assignedValidator),
                                    `A MRA has been created by LoD1 as part of the review ${reviewItem.reviewId}.
    Please edit your scores from the MRA Tab.`,
                                    `/resource/reviews/${reviewItem.id}/detail?tab=MRAs`
                                );
                            }
                            mraOptions.models.forEach((m) => {
                                if (m.modelValidatorTeams) {
                                    m.modelValidatorTeams.forEach((team) => {
                                        scopeResource.getItem(getIdFromIri(team), true).then((scope) => {
                                            scope.users.map((userIri) => {
                                                if (userIri !== assignedValidator)
                                                    this.sendNotification(
                                                        getIdFromIri(userIri),
                                                        `A MRA has been created by LoD1 as part of the review ${reviewItem.reviewId}.
                    Please edit your scores from the MRA Tab.`,
                                                        `/resource/reviews/${reviewItem.id}/detail?tab=MRAs`
                                                    );
                                            });
                                        });
                                    });
                                }
                            });
                        });
                    } else {
                        mraOptions.models.forEach((m) => {
                            if (m.modelValidatorTeams) {
                                m.modelValidatorTeams.forEach((team) => {
                                    scopeResource.getItem(getIdFromIri(team), true).then((scope) => {
                                        scope.users.map((userIri) => {
                                            this.sendNotification(
                                                getIdFromIri(userIri),
                                                `A MRA has been created by LoD1 for the model ${m.functionalID}.
                    Please edit your scores from the MRA Tab`,
                                                `/resource/models/${m.id}/detail?tab=MRA`
                                            );
                                        });
                                    });
                                });
                            }
                        });
                    }
                } else {
                    // notify LOD1 if lod1+lod2 process created by LOD2
                    if (mraOptions.review) {
                        reviewResource.getItemFromResourcePath(mraOptions.review).then((reviewItem) => {
                            mraOptions.models.forEach((m) => {
                                this.sendNotification(
                                    getIdFromIri(m.modelOwner),
                                    `A MRA is expected by the ${
                                        notificationLabels[m.id].team
                                    } team as part of the review ${reviewItem.reviewId}.
    Please edit your scores from the MRA Tab`,
                                    `/resource/reviews/${reviewItem.id}/detail?tab=MRAs`
                                );

                                if (m.modelOwnerDelegation) {
                                    this.sendNotification(
                                        getIdFromIri(m.modelOwnerDelegation),
                                        `A MRA is expected by the ${
                                            notificationLabels[m.id].team
                                        } team as part of the review ${reviewItem.reviewId}.
        Please edit your scores from the MRA Tab`,
                                        `/resource/reviews/${reviewItem.id}/detail?tab=MRAs`
                                    );
                                }
                            });
                        });
                    } else {
                        mraOptions.models.forEach((m) => {
                            this.sendNotification(
                                getIdFromIri(m.modelOwner),
                                `A MRA is expected by the ${notificationLabels[m.id].team} team.
    Please edit your scores from the MRA Tab for the model ${m.functionalID}`,
                                `/resource/models/${m.id}/detail?tab=MRA`
                            );

                            if (m.modelOwnerDelegation) {
                                this.sendNotification(
                                    getIdFromIri(m.modelOwnerDelegation),
                                    `A MRA is expected by the ${notificationLabels[m.id].team} team.
        Please edit your scores from the MRA Tab for the model ${m.functionalID}`,
                                    `/resource/models/${m.id}/detail?tab=MRA`
                                );
                            }
                        });
                    }
                }
            } */
        }

        let masterMra = null;
        if (mraOptions.mraSource) {
            // get the mra to sync the score
            // On force depuis le back car les entities ne sont pas toujours chargées pour des raisons de perf.
            const masterModel = await modelResource.getItemFromResourcePath(mraOptions.mraSource, true);
            // get the latest complete MRA
            const lastMraRef = masterModel.mrasEntities
                // TODO: the dimension should be the same than the selected one in MRA options
                .filter((e) => e.isComplete)
                .sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    if (dateA.getTime() > dateB.getTime()) return 1;
                    if (dateA.getTime() < dateB.getTime()) return -1;
                    return 0;
                })
                .pop();

            if (lastMraRef) {
                masterMra = await mraResource.getItem(lastMraRef.id);
                if (currentProcess.systemId === MRA_PROCESS.LOD2) {
                    if (masterMra.process !== currentProcess['@id']) {
                        /**
                         * Si le process du masterMra est LOD2 alors on garde ses mraScores
                         * mais sinon on garde seulement les scores committee qu'on passe en LOD2
                         * @see https://app.asana.com/0/1173729351441775/1207343634585159/f
                         */
                        const scoresRemapped = masterMra.mraScores.filter((mraScore) => {
                            return [MRA_SCORES.COMMITTEE_RESIDUAL].includes(getParamByIri(mraScore.type).systemId)
                        }).map((mraScore) => {
                            return {...mraScore, type: getParamBySystemId(MRA_SCORES.LOD2_RESIDUAL)['@id']};
                        })
                        masterMra.mraScores = scoresRemapped;
                    }
                    masterMra.mraScores = this.removeMraScoresIds(masterMra.mraScores);
                } else {
                    // On ne recopie pas les scores du committee, qui seront écrasés lors du passage en awaiting
                    const scoresWithoutCommitteesScores = masterMra.mraScores.filter((mraScore) =>
                        ![MRA_SCORES.COMMITTEE_RESIDUAL].includes(getParamByIri(mraScore.type).systemId)
                    );
                    masterMra.mraScores = this.removeMraScoresIds(scoresWithoutCommitteesScores);
                }
            } else {
                return {
                    error: true,
                    message: `Couldn't find a proper MRA to use for cloning based on the model(s) selected.`,
                };
            }
        }

        if (mraOptions.models.length > 1) {
            // create 1 mra per model
            // and attach to the same group
            const p = new Promise((resolve, reject) => {
                mraGroupResource.apiPost({}).then((group) => {
                    try {
                        const groupedMras = mraOptions.models.map((model) => {
                            let mra = this.makeMra(model.id, {});
                            mra.process = mraOptions.process;
                            mra.review = mraOptions.review;
                            mra.group = '/api/mra_groups/' + group.id;
                            mra.dimension = mraOptions.dimension;
                            mra.context = mraOptions.context;
                            if (masterMra) {
                                mra.mraScores = masterMra.mraScores;
                                mra.criticalDimensions = masterMra.criticalDimensions;
                                mra.maxScore = masterMra.maxScore;
                                mra.score = masterMra.score;
                                mra = this.irify(mra);
                            }
                            return mraResource.apiPost(mra);
                        });
                        Promise.all(groupedMras).then((createdMras) => resolve(createdMras));
                    } catch (e) {
                        reject(e);
                    }
                });
            });

            return p;
        } else {
            const model = mraOptions.models[0];
            let mra = this.makeMra(model.id, {});
            mra.process = mraOptions.process;
            mra.review = mraOptions.review;
            mra.dimension = mraOptions.dimension;
            mra.context = mraOptions.context;
            if (masterMra) {
                mra.mraScores = masterMra.mraScores;
                mra.criticalDimensions = masterMra.criticalDimensions;
                mra.maxScore = masterMra.maxScore;
                mra.score = masterMra.score;
                mra = this.irify(mra);
            }
            return mraResource.apiPost(mra);
        }
    }

    /** @deprecated */
    areScoresMatching(mraScores) {
        const lod1ResidualScores = mraScores.filter((mraScore) =>
            [MRA_SCORES.LOD1_RESIDUAL].includes(getParamByIri(mraScore.type).systemId)
        );
        const lod2ResidualScores = mraScores.filter((mraScore) =>
            [MRA_SCORES.LOD2_RESIDUAL].includes(getParamByIri(mraScore.type).systemId)
        );

        // A quick index to find the corresponding score between LoD1 and LoD2
        const lod2ScoresBySubdimensions = [];
        lod2ResidualScores.forEach((lod2Score) => {
            lod2ScoresBySubdimensions[lod2Score.mraSubdimension] = lod2Score.score;
        });

        const nonMatchingScores = lod1ResidualScores.filter((lod1Score) => {
            const lod2Score = lod2ScoresBySubdimensions[lod1Score.mraSubdimension];
            if (lod2Score !== lod1Score.score) {
                if (lod2Score === 0 && !lod1Score.score) {
                    return false;
                }
                return true;
            }
            return false;
        });

        return nonMatchingScores.length === 0;
    }

    /**
     * 
     * @param {{group: string}} mra
     * @returns {Promise<Array<{model: string, modelEntity: object, functionalID: string, initialID: string, name: string}>>} MRAs du groupe, complétés avec des informations de leur model
     */
    getMrasSameGroup = async (mra) => {
        const mraResource = new APIResource({ id: 'mras' });
        const modelResource = new APIResource({ id: 'models' });
        const mraGroupResource = new APIResource({ id: 'mra_groups' });

        const group = await mraGroupResource.getItemFromResourcePath(mra.group, true)
        const promises = group.mras.map(async (mraIRI) => {
            const mraId = getIdFromIri(mraIRI);
            if (mraId !== mra.id) {
                // resource not fetched already
                const otherMra = await mraResource.getItem(mraId, true);
                const syncModel = await modelResource.getItemFromResourcePath(otherMra.model, true);
                return {
                    ...otherMra,
                    modelEntity: syncModel,
                    functionalID: syncModel.functionalID,
                    initialID: syncModel.initialID,
                    name: syncModel.name,
                };
            }

            const syncModel = await modelResource.getItemFromResourcePath(mra.model, true);
            return {
                ...mra,
                modelEntity: syncModel,
                functionalID: syncModel.functionalID,
                initialID: syncModel.initialID,
                name: syncModel.name,
            };
        });

        return Promise.all(promises);
    }

    getAllowedScoreValues = async (mra) => {
        const data = await Http.get(`mras/${mra.id}/allowed_score_values`, {cache: false});
        return data['hydra:member'];
    }
    
    getAllowedScoreValuesFromMraScore = async (mraScore) => {
        const data = await Http.put(`mra_scores/${mraScore.id}/allowed_score_values`, mraScore);
        return data['hydra:member'];
    }
}

export default new MRA();
