import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx/lib/mobx';
import _ from 'lodash';
import {
    Box,
    Button,
    CircularProgress,
    FormLabel,
    FormControl,
    Grid,
    Input,
    List,
    ListItem,
    ListItemText,
    Toolbar,
} from '@material-ui/core';
import Select from '../../Forms/Select/Select';
import { DateRange, DateRangePicker as DRPicker } from 'react-date-range';
import { CustomReport, validateCustomReport } from './CustomDefinitions/CustomReport';
import { InventoriesReport } from './InventoriesReport';
import { PresetReport } from './Presets/PresetReport';
import { PRESET_WIDGETS } from './Presets/Presets';
/*
Assets
 */

import AppBarStore from '../../../Store/AppBarStore';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import { APIResource } from '../../../Services/APIResource/APIResource';
import { userHasRoleMRM } from '../../../Store/ParameterStore';
import User from '../../../Services/User/User';
import ScopeInput from './fields/ScopeInput';
import ScopeInputWithOperator from './fields/ScopeInputWithOperator';
import SetLink from './fields/SetLink';
import {firstLetterUppercase} from "../../../Services/APIResource/Utils";

const CustomComponent = ({ field, parameters, userSettings, setParameters, toggleExtendedView, setWidgetTitle }) => {
    switch (field.component) {
        case 'CustomReport':
            return (
                <CustomReport
                    {...field.props}
                    parameters={parameters}
                    userSettings={userSettings}
                    setParameters={setParameters}
                    toggleExtendedView={toggleExtendedView}
                />
            );
        case 'InventoriesReport':
            return (
                <InventoriesReport
                    {...field.props}
                    parameters={parameters}
                    userSettings={userSettings}
                    setParameters={setParameters}
                    toggleExtendedView={toggleExtendedView}
                />
            );
        case 'ScopeInput':
            return (
                <ScopeInput
                    field={field}
                    parameters={parameters}
                    userSettings={userSettings}
                    setParameters={setParameters}
                    toggleExtendedView={toggleExtendedView}
                />
            );
        case 'ScopeInputWithOperator':
            return (
                <ScopeInputWithOperator
                    field={field}
                    parameters={parameters}
                    userSettings={userSettings}
                    setParameters={setParameters}
                    toggleExtendedView={toggleExtendedView}
                />
            );
        case 'SetLink':
            return (
                <SetLink
                    field={field}
                    parameters={parameters}
                    userSettings={userSettings}
                    setParameters={setParameters}
                    toggleExtendedView={toggleExtendedView}
                />
            );
        default:
            return `Custom Component not found for ${field.custom}`;
    }
};

const customValidation = (field, parameters) => {
    switch (field.component) {
        case 'CustomReport':
            return validateCustomReport(parameters);
    }

    return [];
};

const INITIAL_STATE = {
    widgets: [],
    customWidgets: [],
    selectedIndex: null,
    selectedWidget: null,
    parameters: {},
    isLoading: false,
    linkedSelects: {},
    dateSelects: {},
    missingFields: [],
    extendView: false,
};

class WidgetSelectionModal extends Component {
    constructor(props) {
        super(props);
        AppBarStore.reset();
        this.state = { ...INITIAL_STATE };
        this.resource = new APIResource({ id: 'dashboard/widgets/config' });
        this.userSettingResource = new APIResource({ id: 'user_settings' });
        this.setParameters = this.setParameters.bind(this);
        this.setPresets = this.setPresets.bind(this);
        this.toggleExtendedView = this.toggleExtendedView.bind(this);
        this.handlPresetsSubmit = this.handlPresetsSubmit.bind(this);

        if (props.widget) {
            this.state = {
                ...this.state,
                widgetTitle: props.widget.value.title,
                parameters: props.widget.value.parameters,
                presets: [],
            };
        }
    }

    componentDidMount() {
        this.getWidgets();
    }

    toggleExtendedView() {
        this.setState({
            extendView: !this.state.extendView,
        });
    }

    setParameters(parameters) {
        this.setState({
            parameters: {
                ...this.state.parameters,
                ...parameters,
            },
        });
    }
    setPresets(presets) {
        this.setState({ presets });
    }
    async getWidgets() {
        this.setState({ isLoading: true });
        let widgets = [];
        try {
            widgets = (await this.resource.apiGetCollection()) || [];
        } catch (err) {
            console.warn(err.message);
        }

        let isMrmUser = userHasRoleMRM();
        let presetWidgetsAvailables = [...PRESET_WIDGETS];

        if (!isMrmUser && User.profile.firstName !== 'Dashboard-std') {
            presetWidgetsAvailables = PRESET_WIDGETS.filter(({ isMRM }) => !isMRM);
        }

        // special case, do not show "user-export-widget" which is only
        // used for the models export, not for the dashboard
        widgets = widgets.filter(w => w.systemId !== 'user-export-widget')

        const availableWidgets = [...widgets, ...presetWidgetsAvailables];

        let customWidgetsCode = ['user-defined-widget', 'inventories-widget', 'preset', 'weekly-report'];
        customWidgetsCode = [
            ...customWidgetsCode,
            ...presetWidgetsAvailables.map(({ widgetSystemId }) => widgetSystemId),
        ];

        const stdWidgets = availableWidgets.filter(({ systemId }) => !customWidgetsCode.includes(systemId));
        let customWidgets = availableWidgets.filter(({ systemId }) => customWidgetsCode.includes(systemId));

        customWidgets = [...customWidgets, ...presetWidgetsAvailables];

        const newState = { widgetTitle: '', ...this.state, widgets, stdWidgets, customWidgets, isLoading: false };

        if (this.props.widget) {
            newState.selectedIndex = widgets.findIndex((w) => w.id === this.props.widget.value.widgetSystemId);
            newState.selectedWidget = widgets[newState.selectedIndex];
            newState.userSettings = this.props.widget;
        }

        this.setState(newState);
    }

    handleConfigWidgetAdded = (args) => {
        const { widget, parameters } = args;
    };

    handlPresetsSubmit = async () => {
        const userSettingsToSave = PRESET_WIDGETS.filter((widget) => this.state.presets.includes(widget.reportId));

        await Promise.all(
            userSettingsToSave.map((userSetting) => {
                return this.userSettingResource.apiPost({
                    value: { ...userSetting, tab: this.props.tab },
                    code: `${userSetting.widgetSystemId}-${Date.now()}`,
                    type: 'dashboard-widget',
                });
            })
        );

        this.props.closeModal();
        this.props.refreshDashboard();
    };

    handleConfigSubmit = async (event) => {
        const { onSelection } = this.props;
        const { selectedWidget, parameters, widgetTitle } = this.state;
        let missingRequiredField = [];

        if (
            !parameters.fields ||
            (parameters.fields.length < 1 &&
                selectedWidget.parameters.displayFields &&
                selectedWidget.parameters.displayFields.length > 0)
        ) {
            parameters.fields = selectedWidget.parameters.displayFields;
        }
        if (!parameters.onRowClick && selectedWidget.parameters.onRowClick) {
            parameters.onRowClick = selectedWidget.parameters.onRowClick;
        }

        if (selectedWidget.parameters && selectedWidget.parameters.fields) {
            // if we have a default value set from the API use it silently
            _.each(selectedWidget.parameters.fields, (field) => {
                if (!parameters[field.name] && field.value) {
                    parameters[field.name] = field.value;
                }
            });
            // check if required values are provided

            _.each(selectedWidget.parameters.fields, (field) => {
                if (field.type === 'custom') {
                    const missingCustomFields = customValidation(field, parameters);
                    missingRequiredField = [...missingRequiredField, ...missingCustomFields];
                }

                if (field.required) {
                    if (!parameters[field.name]) {
                        missingRequiredField.push(`${field.label} is required.`);
                    }

                    // if field is a linked list, check the linkTo property also
                    if (field.linkTo) {
                        if (!parameters[field.name] || !parameters[field.name][field.linkTo]) {
                            missingRequiredField.push(`${firstLetterUppercase(field.linkTo)} is required.`);
                        }
                    }
                }
            });
        }

        if (missingRequiredField.length > 0) {
            this.setState({
                missingFields: missingRequiredField,
            });
        } else {
            // return null
            const userSetting = {
                code: `${selectedWidget.id}-${Date.now()}`,
                type: 'dashboard-widget',
                value: {
                    tab: this.props.tab,
                    title: widgetTitle || selectedWidget.title,
                    widgetSystemId: selectedWidget.id,
                    column: 0,
                    row: 0,
                    type: selectedWidget.type,
                    parameters,
                },
            };

            let persistedWidget;

            if (!this.props.widget) {
                persistedWidget = await this.userSettingResource.apiPost(userSetting);
            } else {
                userSetting.id = this.props.widget.id;
                persistedWidget = await this.userSettingResource.apiPut(userSetting);
                this.props.refreshDashboard();
            }
            onSelection({ template: selectedWidget, widget: { ...userSetting, id: persistedWidget.id } });
        }
    };

    handleListItemClick = async (event, selectedIndex, selectedWidget) => {
        this.setState({
            selectedIndex,
            selectedWidget,
            widgetTitle: selectedWidget.title,
        });
        if (selectedWidget.parameters.fields) {
            const { parameters } = this.state;
            selectedWidget.parameters.fields.forEach((f) => {
                if (f.name && !parameters[f.name] && f.type === 'dateRange') {
                    const startDate = new Date();
                    const endDate = new Date();
                    endDate.setDate(endDate.getDate() + 30);
                    parameters[f.name] = { startDate, endDate, selection: f.name };
                }
                if (f.name && f.defaultValue && !parameters[f.name]) {
                    parameters[f.name] = f.defaultValue;
                }
            });
        } else {
            this.setState({parameters: selectedWidget.parameters})
        }
    };

    handleInputChange = (event, selectContext) => {
        // clear missing fields
        this.setState({ missingFields: [] });
        // in case it's a select reconstruct the event
        if (selectContext && selectContext.action) {
            event = {
                target: {
                    type: 'select',
                    name: selectContext.name,
                    value: this.getSelectValue(event),
                },
            };
        }

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        // const name = target.name;
        const parameters = this.state.parameters;
        _.set(parameters, selectContext.name, value);
        this.setState({
            parameters: {
                ...parameters,
            },
        });
    };

    removeParameter = (fieldpath) => {
        const parameters = this.state.parameters;
        _.unset(parameters, fieldpath);
        this.setState({
            parameters: {
                ...parameters,
            },
        });
    };

    /**
     * event on date range change
     *
     * data => { [field.name]: {
     *  startDate: Date,
     *  endDate: Date
     * }
     * }
     *
     * @memberof WidgetSelectionModal
     */
    handleDateRangeChange = (field, data) => {
        const range = Object.keys(data)
            .map((d) => data[d])
            .shift();
        const parameters = this.state.parameters;
        _.set(parameters, field, range);
        this.setState({
            parameters: {
                ...parameters,
            },
        });
    };

    handleWidgetTitleChange = (event) => {
        this.setState({
            widgetTitle: event.target.value,
        });
    };

    getSelectValue = (selection) => {
        if (!selection) {
            return null;
        }
        if (Array.isArray(selection)) {
            return selection.map((s) => s.value);
        }
        return selection.value;
    };

    getSelectOptions(listOfValues) {
        if (!Array.isArray(listOfValues)) {
            throw new Error('error_wrong_list_of_values_format');
        }
        return listOfValues.map((item) =>
            typeof item === 'string' ? { value: item, label: item } : { value: item, label: item.label }
        );
    }

    renderWidgetConfig = (widget) => {
        const { fields } = widget.parameters;
        const { parameters } = this.state;

        let fieldsComponents;

        if (widget.description) {
            fieldsComponents = <div>{widget.description}</div>;
        } else {
            fieldsComponents = fields.map((field) => (
                <FormControl key={field.name} style={styles.formControl}>
                    {this.renderFormField(field)}
                </FormControl>
            ));

            fieldsComponents.unshift(
                <FormControl key={'widget-title'} style={{ marginBottom: 15 }}>
                    {!widget.noTitle && (
                        <>
                            <FormLabel color="secondary">Title</FormLabel>
                            <Input
                                placeholder="Title"
                                name="wiget-title"
                                type="text"
                                onChange={this.handleWidgetTitleChange}
                                value={this.state.widgetTitle}
                            />
                        </>
                    )}
                </FormControl>
            );
        }
        return <div style={styles.sections}>{fieldsComponents}</div>;
    };

    /**
     *
     *
     * @param {*} field
     * @returns
     * @memberof WidgetSelectionModal
     */
    renderFormField(field) {
        if (field.hidden) {
            return;
        }
        switch (field.type) {
            case 'preset':
                return <PresetReport setPresets={this.setPresets} />;
            case 'custom':
                return (
                    <CustomComponent
                        field={field}
                        parameters={this.state.parameters}
                        userSettings={this.state.userSettings}
                        setParameters={this.setParameters}
                        toggleExtendedView={this.toggleExtendedView}
                    />
                );
            case 'select':
                const options = this.getSelectOptions(field.options);
                return (
                    <Select
                        name={field.name}
                        label={field.placeholder || field.label}
                        required={field.required}
                        //placeholder={field.placeholder || field.label}
                        isMulti={field.multiple}
                        options={options}
                        onChange={this.handleInputChange}
                        defaultValue={field.defaultValue}
                        // defaultInputValue={field.defaultValue}
                    ></Select>
                );
            case 'dateRange':
                return (
                    <React.Fragment>
                        <FormLabel htmlFor={field.name} color="secondary">
                            {field.label}
                        </FormLabel>
                        <DateRange
                            showSelectionPreview={false}
                            onChange={this.handleDateRangeChange.bind(this, field.name)}
                            moveRangeOnFirstSelection={false}
                            rangeColors={['#ac0577']}
                            ranges={[this.state.parameters ? this.state.parameters[field.name] : {}]}
                            className={'widget-selector-date-range'}
                        />
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment>
                        <FormLabel htmlFor={field.name} color="secondary">
                            {field.label}
                        </FormLabel>
                        <Input
                            placeholder={field.label}
                            name={field.name}
                            type={field.inputType}
                            onChange={this.handleInputChange}
                            value={this.state.parameters[field.name]}
                        />
                    </React.Fragment>
                );
        }
    }

    /**
     *
     *
     * @returns
     * @memberof WidgetSelectionModal
     */
    renderWidgetList(widgets) {
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            );
        }
        return (
            <List style={{ ...styles.sections, display: 'flex', flexDirection: 'column' }}>
                {widgets
                    ? widgets.map((widget, index) => (
                          <ListItem
                              key={widget.systemId}
                              button={true}
                              style={styles.widgetLi}
                              selected={this.state.selectedIndex === index}
                              onClick={(event) => this.handleListItemClick(event, index, widget)}
                          >
                              <ListItemText primary={widget.title} style={{ textAlign: 'center' }} />
                          </ListItem>
                      ))
                    : undefined}
            </List>
        );
    }

    render = () => {
        const { selectedWidget, extendView, stdWidgets, customWidgets } = this.state;

        return (
            <div className="widget-selection-modal" style={styles.container}>
                <Grid container style={styles.mainGrid} spacing={3}>
                    {!extendView && (
                        <Grid item xs={6} style={{ display: 'flex', borderRight: '1px solid #f4f2f2' }}>
                            <div style={{ width: '50%', display: 'inline-block' }}>
                                <h2 style={{ textAlign: 'center' }}>Widgets</h2>
                                <div style={{}}>{this.renderWidgetList(stdWidgets)}</div>
                            </div>
                            <div style={{ width: '50%', display: 'inline-block' }}>
                                <h2 style={{ textAlign: 'center' }}>Custom reports</h2>
                                {this.renderWidgetList(customWidgets)}
                            </div>
                        </Grid>
                    )}
                    <Grid item xs={extendView ? 12 : 6}>
                        <h2>Widget settings</h2>
                        {this.state.missingFields && this.state.missingFields.length > 0 && (
                            <div style={styles.missingFields}>
                                {this.state.missingFields.map((field) => (
                                    <li key={`missing${field}`}>{field}</li>
                                ))}
                            </div>
                        )}
                        {selectedWidget ? (
                            this.renderWidgetConfig(selectedWidget)
                        ) : (
                            <small className="text-maincolor">Select a widget in the left panel to configure it</small>
                        )}

                        <Box style={styles.bottomButton}>
                            {selectedWidget && (
                                <Button
                                    variant="outline"
                                    color="secondary"
                                    startIcon={extendView ? <ArrowBackIcon /> : <ZoomOutMapIcon />}
                                    style={styles.backButton}
                                    type="button"
                                    onClick={this.toggleExtendedView}
                                >
                                    {extendView ? 'Widgets list' : 'Extend view'}
                                </Button>
                            )}

                            {selectedWidget && selectedWidget.systemId === 'preset' && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    type="button"
                                    disabled={!selectedWidget}
                                    onClick={this.handlPresetsSubmit}
                                    className="button-general"
                                >
                                    <span>ADD WIDGETS</span>
                                </Button>
                            )}

                            {!selectedWidget ||
                                (selectedWidget.systemId !== 'preset' && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        type="button"
                                        disabled={!selectedWidget}
                                        onClick={this.handleConfigSubmit}
                                        className="button-general"
                                    >
                                        {!this.props.widget && <span>ADD A WIDGET</span>}
                                        {this.props.widget && <span>SAVE WIDGET</span>}
                                    </Button>
                                ))}
                        </Box>
                    </Grid>
                </Grid>
            </div>
        );
    };
}

export default WidgetSelectionModal;

const styles = {
    container: {
        position: 'relative',
        overflow: 'hidden',
        height: 'calc(100% - 120px)',
    },
    mainGrid: {
        minHeight: '50vh',
        maxHeight: '55vh',
        overflow: 'auto',
    },
    sections: {
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'auto',
        marginBottom: '20px',
    },
    formControl: {
        marginBottom: 10,
    },
    backButton: {
        marginRight: '10px',
        color: '#ac0577',
    },
    bottomButton: {
        position: 'absolute',
        right: 15,
        bottom: 0,
    },
    widgetLi: {
        display: 'block',
        width: 'auto',
        padding: '5px 10px',
        backgroundColor: 'rgba(0,0,0,0.10)',
        borderRadius: '6px',
        margin: '6px',
    },
    missingFields: {
        color: 'red',
        fontSize: '18px',
        margin: '10px 0 30px',
        position: 'absolute',
        right: 15,
        bottom: '30px',
        zIndex: 10000,
        background: 'rgba(255,255,255,0.7)',
        padding: '20px',
    },
};
