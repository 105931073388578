import React from 'react';
import { APIResource } from "../Services/APIResource/APIResource";
import {
    PARAMETER_TYPE_MITIGATION_MEASURE_TYPE,
    PARAMETER_TYPE_MITIGATION_DEFICIENCY_TYPE,
    PARAMETER_TYPE_MITIGATION_STATUS,
} from "./ParameterAdmin";
import { DocumentManager } from "../Components/Display/DocumentManager/DocumentManager";
import ParameterStore, {
    userHasDeveloperRights,
    userHasMRMRights,
    userHasNoRole,
    userHasOwnershipRights,
    userIsVal,
} from "../Store/ParameterStore";
import { ChangeLog } from '../Components/Display/ChangeLog/ChangeLog';
import User from "../Services/User/User";
import APIResourceStore from "../Store/APIResourceStore";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";
import ModelProvider from '../Services/APIResource/FieldProviders/ModelProvider';
import {needHighlightField} from "./ModelAdmin";
import {postSaveRedirectToCertification} from "./CertificationAdmin";
import { isGranted } from './common';
import EntityProvider from "../Services/APIResource/FieldProviders/EntityProvider";

export const ApiResourceDefaultParams = {
    id: 'mitigation_actions',
    name: 'Mitigation action',
    icon: 'cog',
    fieldForTitle: 'toString',
    breadcrumbName: "M.A.",
    componentForTitle: (entity, resource, resourceEditComponent) => {
        return <Breadcrumb entity={entity} resource={resource} resourceEditComponent={resourceEditComponent} relationalProperty={'coveredModels'} resourcePath={'models'} />
    },
    keepQueryParams: () => {
        return ['certification-id'];
    }
};

export default class MitigationActionAdmin {
    constructor() {
        this.configure();
    }

    configure() {

        let mitigationAction = new APIResource(ApiResourceDefaultParams);

        mitigationAction
            .setFields({
                title: {
                    title: 'Title',
                    helperText: 'Label or title given to this specific mitigation measure',
                },
                mitigationMeasureType: {
                    title: 'Mitigation measure type',
                    type: 'parameter',
                    helperText: 'Identification of the type of mitigation mesure applied to the model',
                    params: { type: PARAMETER_TYPE_MITIGATION_MEASURE_TYPE, multi: false },
                    required: true,
                    highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams)
                },
                description: {
                    title: 'Description',
                    type: 'textarea',
                    helperText: 'Short description of the mitigation measure',
                },
                status: {
                    title: 'Status',
                    type: 'parameter',
                    params: { type: PARAMETER_TYPE_MITIGATION_STATUS, multi: false },
                },
                startDate: {
                    title: 'Start date',
                    type: 'date',
                    helperText: 'Starting date of the mitigation measure application',
                },
                deficienciesTypes: {
                    title: 'Deficiencies',
                    type: 'parameter',
                    helperText: 'More details about the kind of deficiencies covered by the MOC A / MOC B',
                    params: { type: PARAMETER_TYPE_MITIGATION_DEFICIENCY_TYPE, multi: true },
                    displayConditions: (entity) =>
                        entity.mitigationMeasureType === ParameterStore('MITIGATION_MEASURE_TYPE_MOC_A') ||
                        entity.mitigationMeasureType === ParameterStore('MITIGATION_MEASURE_TYPE_MOC_B'),
                },
                percent: {
                    title: 'Amount (%)',
                    type: 'int',
                    helperText: 'Amount of the mitigation measure (for MOC and Add-ons)',
                },
                amount: {
                    title: 'Amount (value)',
                    helperText: 'Amount of the mitigation measure (for MOC and Add-ons)',
                },
                originatingIssue: {
                    title: 'Originating Notice',
                    type: 'entity',
                    helperText: 'Notice that led to the implementation of the current mitigation measure',
                    params: {
                        resource: 'notices',
                        displayField: 'toString',
                        multi: false,
                        links: true,
                        endpoints: {
                            getAll: 'notices/all-notices',
                        },
                        filters: (notice, entity, key, context) => {
                            if(!entity){
                                return notice;
                            }
                            if(
                                Array.isArray(notice.models)
                                && Array.isArray(entity.coveredModels)
                                && notice.models.length === entity.coveredModels.length
                                && notice.models.every((val, index) => val['@id'] === entity.coveredModels[index])
                            ){
                                return notice;
                            }
                            return null;
                        }
                    },
                    edit: (field, value, onChange, entity, routeParams) => {
                        if(routeParams && routeParams.noticeId){
                            entity.originatingIssue = `/api/notices/${routeParams.noticeId}`;
                        }
                        return EntityProvider.getEdit(field, value, onChange, entity, routeParams);
                    },
                },
                coveredModels: {
                    title: 'Covered models',
                    type: 'model',
                    helperText: 'Models covered by the mitigation measure',
                    params: {
                        resource: 'models',
                        instanceId: 'allModels',
                        displayField: 'toString',
                        multi: true,
                        links: true,
                        linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                        endpoints: {
                            getAll: 'models/all-models',
                        },
                    },
                    required: true,
                    edit: (field, value, onChange, entity, routeParams) => {
                        if(routeParams && routeParams.modelId){
                            entity.coveredModels = ['/api/models/' + routeParams.modelId];
                        }else if(routeParams && routeParams.noticeId){
                            entity.coveredModels = APIResourceStore.resources.notices.getObservableItem(routeParams.noticeId).models;
                        }

                        return ModelProvider.getEdit(field, value, onChange, entity, routeParams);
                    },
                },
                reserveModels: {
                    title: 'Mitigation models',
                    type: 'model',
                    helperText: 'If the mitigation measure corresponds to the use of reserve models, indicate here the ID of the reserve models',
                    params: {
                        resource: 'models',
                        instanceId: 'allModels',
                        displayField: 'toString',
                        multi: true,
                        links: true,
                        linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                        endpoints: {
                            getAll: 'models/all-models',
                        },
                    },
                    // il faut peut etre mettre un systemid à la main en prod et l'utiliser, sans écraser les parametres ? ou alors penser à la migration de données !
                    displayConditions: (entity) => [ParameterStore('MITIGATION_MEASURE_TYPE_MODEL_RISK_RESERVE'), ParameterStore('MITIGATION_MEASURE_TYPE_RISK_NOT_IN_MODEL_ENGINE')] .includes(entity.mitigationMeasureType),
                },
                documentsEntities: {
                    title: 'Documents',
                    display: (field, value, entity, _props) => (
                        <DocumentManager values={value} entity={entity} entityResource={'mitigation_actions'} />
                    ),
                    displayList: () => null,
                },
                changeLogsEntities: {
                    title: 'Audit trail',
                    inParentList: false,
                    display: (field, value, entity, props) => (
                        <ChangeLog
                            field={field}
                            values={value}
                            entity={entity}
                            entityResource={'mitigation_actions'}
                            props={props}
                        />
                    ),
                    edit: () => null,
                    displayList: () => null,
                },
                //Additional field without relation with ModelUse
                changeLogComment: {
                    title: 'Justification of the data update',
                    inParentList: false,
                    type: 'textarea',
                    display: () => null,
                    token: false,
                },
            })
            .setLayout({
                tabs: {
                    'Mitigation Measures': {
                        rows: [
                            {
                                panels: {
                                    'Measure Description': {
                                        cols: 6,
                                        fields: [
                                            'mitigationMeasureType',
                                            'title',
                                            'description',
                                            'status',
                                            'startDate',
                                            'deficienciesTypes',
                                            'coveredModels',
                                        ],
                                    },
                                    Amount: {
                                        cols: 6,
                                        fields: ['percent', 'amount'],
                                    },
                                },
                            },
                            {
                                panels: {
                                    'Mitigation models': {
                                        cols: 6,
                                        fields: ['reserveModels'],
                                    },
                                    Origin: {
                                        cols: 6,
                                        fields: ['originatingIssue'],
                                    },
                                },
                            },
                        ],
                    },
                    Documents: {
                        rows: [
                            {
                                panels: {
                                    Documents: {
                                        cols: 12,
                                        fields: ['documentsEntities'],
                                    },
                                },
                            },
                        ],
                    },
                    'Audit trail': {
                        rows: [
                            {
                                panels: {
                                    'Audit trail': {
                                        cols: 12,
                                        fields: ['changeLogsEntities', 'changeLogComment'],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genInsertView({
                additionalRoutes: [
                    '/resource/mitigation_actions/add/:modelId',
                    '/resource/mitigation_actions/add/notice/:noticeId'
                ],
                postSaveRedirect: postSaveRedirectToCertification,
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'MitigationAction';
                },
                additionalActionButtons: (entity, resource, resourceEditComponent, queryParams) => {
                    let additionalActionButtons = [];

                    if (
                        !(
                            typeof queryParams !== 'object'
                            || queryParams.get('certification-id') === undefined
                            || queryParams.get('certification-id') === ''
                            || queryParams.get('certification-id') === null
                        )
                    ) {
                        additionalActionButtons.push({
                            link: '/resource/my_model_certification_campaigns/' + queryParams.get('certification-id') + '/detail',
                            tooltip: 'Go back to certification',
                            icon: 'chevron-left',
                        });
                    }
                    return additionalActionButtons;
                }
            })
            .genEditView({
                postSaveRedirect: postSaveRedirectToCertification,
                additionalActionButtons: (entity, resource, resourceEditComponent, queryParams) => {
                    let additionalActionButtons = [];

                    if (
                        !(
                            typeof queryParams !== 'object'
                            || queryParams.get('certification-id') === undefined
                            || queryParams.get('certification-id') === ''
                            || queryParams.get('certification-id') === null
                        )
                    ) {
                        additionalActionButtons.push({
                            link: '/resource/my_model_certification_campaigns/' + queryParams.get('certification-id') + '/detail',
                            tooltip: 'Go back to certification',
                            icon: 'chevron-left',
                        });
                    }
                    return additionalActionButtons;
                },
                itemAccessCondition: (entity) => entity && entity.id ? isGranted(entity, mitigationAction.resourceId) : false,
            })
            .genDetailView({
                additionalLinkButton: (entity) => {
                    let models = [];
                    if(entity.coveredModels){
                        entity.coveredModels.forEach((modelPath) => {
                            if(modelPath){
                                let model = APIResourceStore.resources.allModels.getObservableItemByPath(modelPath);
                                models.push({
                                    title: model.functionalID,
                                    link: '/resource/models/' + model.id + '/detail',
                                });
                            }
                        })
                    }
                    if(models.length > 0){
                        return [{
                            links: models,
                            tooltip: 'Associated models',
                            icon: 'link',
                        }];
                    }
                },
                additionalActionButtons: (entity, resource, resourceEditComponent, queryParams) => {
                    let additionalActionButtons = [];

                    if (
                        !(
                            typeof queryParams !== 'object'
                            || queryParams.get('certification-id') === undefined
                            || queryParams.get('certification-id') === ''
                            || queryParams.get('certification-id') === null
                        )
                    ) {
                        additionalActionButtons.push({
                            link: '/resource/my_model_certification_campaigns/' + queryParams.get('certification-id') + '/detail',
                            tooltip: 'Go back to certification',
                            icon: 'chevron-left',
                        });
                    }
                    return additionalActionButtons;
                }
            })
            .genListView({
                fields: [
                    'mitigationMeasureType',
                    'title',
                    'description',
                    'status',
                    'startDate',
                    'deficienciesTypes',
                    'coveredModels',
                    'percent',
                    'amount',
                    'reserveModels',
                    'originatingIssue',
                ],
                permanentFilters: {
                    "coveredModels.modelStatus[]": ParameterStore("MODEL_STATUS_ACTIVE")
                },
                itemAccessCondition: (entity) => !userHasNoRole(User, entity)
            })
            .allowDelete({
                itemAccessCondition: (entity) => {
                    return (
                        (entity &&
                            entity.coveredModels.some((modelPath) => {
                                let model = APIResourceStore.resources.models.getObservableItemByPath(modelPath);
                                return userHasDeveloperRights(User, model) ||
                                userHasOwnershipRights(User.getId(), model) ||
                                userIsVal(User, model) ||
                                userHasMRMRights(User, model);
                            })
                        )
                    );
                },
            });
    }
}
