import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { APIResource } from '../../../Services/APIResource/APIResource';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MRA, {
    MRA_SCORES_TYPES,
    canEditMraScore,
    canEditByType,
    MRA_SCORES,
    MRA_STATUS,
    MRA_PROCESS,
} from '../../../Services/MRA';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Float from '../../../Services/Float';
import String from '../../../Services/String';
import { getParamByIri, getParamBySystemId, userIsLod2, userIsLod1 } from '../../../Store/ParameterStore';
import { toJS } from 'mobx/lib/mobx';
import User from '../../../Services/User/User';
import { ScorePicker } from '../Edit/ScorePicker';
import { getIdFromIri } from '../../../Services/utils';
import { useTranslation } from 'react-i18next';
import StarImage from '../../../Styles/Assets/img/star.png';

let debounceTimeout = null;

const debounce = (fn, ms) => {
    if (debounceTimeout) {
        clearTimeout(debounceTimeout);
        debounceTimeout = null;
    }

    debounceTimeout = setTimeout(() => {
        fn();
        clearTimeout(debounceTimeout);
        debounceTimeout = null;
    }, ms);
};

const EditableInput = ({
    children,
    mraProcess,
    scoreType,
    mraScore,
    mraStatus,
    userRole,
    styles,
    mraScoresBySubdimension,
    handleScoreChange,
    subdimension,
    isNTXDimension = false,
    isGROUPDimension = false,
    lod1Submit,
}) => {
    // const subdimension = mraScoresBySubdimension[0].mraSubdimension
    const pathScoreType = useMemo(() => getParamBySystemId(scoreType), [scoreType]);
    const defaultScore = useMemo(
        () =>
            Object.keys(mraScore).length > 0
                ? mraScore
                : {
                      type: pathScoreType['@id'],
                      mraSubdimension: subdimension['@id'],
                      score: 0,
                      justification: null,
                  },
        [mraScore]
    );

    // const [currentMraScore, setCurrentMraScore] = useState(defaultScore)
    const [currentScore, setCurrentScore] = useState(defaultScore.score);
    const [currentJustification, setCurrentJustification] = useState(defaultScore.justification);

    const hideLoD2 =
        scoreType.indexOf('LOD1') === -1 &&
        userRole === 'LoD1' &&
        mraStatus.systemId === MRA_STATUS.ON_GOING &&
        mraProcess.systemId === MRA_PROCESS.LOD1_LOD2;

    useEffect(() => {
        // debounce to avoid performance issues while typing.
        // debounce(() => handleScoreChange(currentScore), 50)
        handleScoreChange({
            ...defaultScore,
            score: currentScore,
            justification: currentJustification,
        });
    }, [currentScore, currentJustification, handleScoreChange]);

    // LOD1 should not see other scores if status is ON_GOING
    if (hideLoD2) {
        return (
            <React.Fragment>
                <TableCell style={styles.scoreWithColumn}>Restricted field</TableCell>
                <TableCell style={styles.justificationWithColumn}>-</TableCell>
            </React.Fragment>
        );
    }

    const canEdit = canEditByType(
        scoreType,
        userRole,
        mraStatus && mraStatus.systemId,
        mraProcess && mraProcess.systemId
    );

    if (!canEdit || (userRole === 'LoD1' && mraStatus.systemId !== MRA_STATUS.CONFLICT)) {
        return children;
    }

    let showJustification = true;
    if (userRole === 'LoD1' && scoreType !== MRA_SCORES.LOD1_RESIDUAL) {
        showJustification = false;
    }

    // if no scores submitted by LOD1, show blank instead of N/A
    if (
        !mraScore.score &&
        !lod1Submit &&
        scoreType.indexOf('LOD1') >= 0 &&
        [MRA_STATUS.ON_GOING, MRA_STATUS.VALIDATED, MRA_STATUS.FINALIZED].includes(mraStatus.systemId)
    ) {
        return (
            <React.Fragment>
                <TableCell style={styles.scoreWithColumn}> </TableCell>
                <TableCell style={styles.justificationWithColumn}> </TableCell>
            </React.Fragment>
        );
    }

    // si mraScore, vérifier que l'utilisateur peut modifier la donnée en fonction de son type
    if (Object.keys(mraScore).length > 0) {
        return (
            <React.Fragment>
                <TableCell style={styles.scoreEditionWithColumn}>
                    <ScorePicker
                        onChange={(value) => {
                            setCurrentScore(value);
                        }}
                        max={isNTXDimension || isGROUPDimension ? 5 : 4}
                        initValue={currentScore}
                    />
                </TableCell>
                {showJustification && (
                    <TableCell style={styles.justificationWithColumn}>
                        <TextField
                            id="outlined-full-width"
                            label="Score justification"
                            className="input-score-justification"
                            placeholder="Please explain here the given score."
                            multiline
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            defaultValue={currentJustification}
                            onBlur={(event) => {
                                setCurrentJustification(event.target.value);
                            }}
                        />
                    </TableCell>
                )}
            </React.Fragment>
        );
    }

    // Sinon si le score n'existe pas et que le type de score est editable
    // pour l'utilisateur, lui permettre de modifier le champ.
    return (
        <React.Fragment>
            <TableCell style={styles.scoreEditionWithColumn}>
                <ScorePicker
                    max={isNTXDimension || isGROUPDimension ? 5 : 4}
                    onChange={(value) => {
                        setCurrentScore(value);
                    }}
                />
            </TableCell>
            {showJustification && (
                <TableCell style={styles.justificationWithColumn}>
                    <TextField
                        id="outlined-full-width"
                        label="Score justification"
                        className="input-score-justification"
                        placeholder="Please explain here the given score."
                        multiline
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onBlur={(event) => {
                            setCurrentJustification(event.target.value);
                        }}
                    />
                </TableCell>
            )}
        </React.Fragment>
    );
};

const displayDimensionScore = (dimensionScore) => {
    if (isNaN(dimensionScore) || dimensionScore === 0) {
        return 'N/A';
    }

    return Float.format(dimensionScore, 2);
};

const getAverageScoresByDimensions = (dimensionScores) => {
    // We need to group residual and inherent scores
    const {
        inherentScoreGrouped,
        residualScoreGrouped,
        Lod2ResidualScoreGrouped,
        committeeScoreGrouped,
    } = dimensionScores.reduce(
        (scores, currentScore) => {
            if (currentScore.score > 0 && currentScore.type && currentScore.type.systemId) {
                if (currentScore.type.systemId.indexOf('INHERENT') > 0) {
                    scores.inherentScoreGrouped.push(currentScore.score);
                }

                if (currentScore.type.systemId.indexOf('LOD1_RESIDUAL') > 0) {
                    scores.residualScoreGrouped.push(currentScore.score);
                }

                if (currentScore.type.systemId.indexOf('LOD2_RESIDUAL') > 0) {
                    scores.Lod2ResidualScoreGrouped.push(currentScore.score);
                }

                if (currentScore.type.systemId === MRA_SCORES.COMMITTEE_RESIDUAL) {
                    scores.committeeScoreGrouped.push(currentScore.score);
                }
            }

            return scores;
        },
        { inherentScoreGrouped: [], residualScoreGrouped: [], Lod2ResidualScoreGrouped: [], committeeScoreGrouped: [] }
    );

    const inherentScore =
        inherentScoreGrouped.reduce((a, b) => a + b, 0) / inherentScoreGrouped.filter((i) => i > 0).length;
    const residualScore =
        residualScoreGrouped.reduce((a, b) => a + b, 0) / residualScoreGrouped.filter((i) => i > 0).length;
    const LoD2ResidualScore =
        Lod2ResidualScoreGrouped.length > 0
            ? Lod2ResidualScoreGrouped.reduce((a, b) => a + b, 0) / Lod2ResidualScoreGrouped.filter((i) => i > 0).length
            : undefined;
    const committeeScore =
        committeeScoreGrouped.length > 0
            ? committeeScoreGrouped.reduce((a, b) => a + b, 0) / committeeScoreGrouped.filter((i) => i !== 0).length
            : undefined;

    return {
        inherentScore,
        residualScore,
        LoD2ResidualScore,
        committeeScore,
    };
};

const fmtGuide = (text) => {
    // add new line before numbered bullet points
    const regex = /([0-9]:|- |[0-9]\. )/g;
    return text.replace(regex, '<br><br>$1');
};

const DimensionScoresBelow = ({ dimensionScores, mraProcess, userRole, mraStatus }) => {
    const { residualScore, LoD2ResidualScore } = getAverageScoresByDimensions(dimensionScores);
    const hideLoD2 =
        userRole === 'LoD1' &&
        mraStatus.systemId === MRA_STATUS.ON_GOING &&
        mraProcess.systemId === MRA_PROCESS.LOD1_LOD2;

    return (
        <TableBody>
            <TableCell>
                <span style={{ fontWeight: 'bold', color: '#ac0577' }}>Average scores</span>
            </TableCell>
            {mraProcess && mraProcess.systemId !== MRA_PROCESS.LOD2 && (
                <>
                    <TableCell>
                        <span style={{ fontWeight: 'bold', color: '#ac0577' }}>
                            {displayDimensionScore(residualScore)}
                        </span>
                    </TableCell>
                    <TableCell />
                </>
            )}
            {mraProcess && mraProcess.systemId !== MRA_PROCESS.LOD1 && !hideLoD2 && (
                <TableCell>
                    <span style={{ fontWeight: 'bold', color: '#ac0577' }}>
                        {displayDimensionScore(LoD2ResidualScore)}
                    </span>
                </TableCell>
            )}
        </TableBody>
    );
};

const DimensionCommitteeScore = ({ dimensionScores, mraProcess }) => {
    const { committeeScore } = getAverageScoresByDimensions(dimensionScores);

    return (
        <React.Fragment>
            {committeeScore && (
                <>
                    <span>Committee score: </span>
                    <span className="text-highlight" style={{ color: 'white', marginLeft: '5px' }}>
                        {displayDimensionScore(committeeScore)}
                    </span>
                </>
            )}
        </React.Fragment>
    );
};

const TableCellsInput = ({
    mraScoresTypesFiltered,
    mraScoresSubdimension,
    userRole,
    mraStatus,
    lod1Submit,
    mraProcess,
    subdimension,
    handleScoreChange,
    isNTXDimension,
    isGROUPDimension,
}) => {
    return mraScoresTypesFiltered.map(([scoreType, config], j) => {
        if (scoreType.indexOf('INHERENT') >= 0) {
            return null;
        }

        let scores = mraScoresSubdimension.filter((s) => getParamByIri(s.type).systemId === scoreType);
        let mraScore = scores.length > 0 ? scores.pop() : {};

        return (
            <EditableInput
                styles={styles}
                key={`editable_input_${j}`}
                mraProcess={mraProcess}
                mraStatus={mraStatus}
                mraScore={mraScore}
                scoreType={scoreType}
                mraScoresBySubdimension={mraScoresSubdimension}
                subdimension={subdimension}
                userRole={userRole}
                isNTXDimension={isNTXDimension}
                isGROUPDimension={isGROUPDimension}
                handleScoreChange={handleScoreChange}
                lod1Submit={lod1Submit}
            >
                <TableCell style={styles.scoreWithColumn}>{mraScore.score || 'N/A'}</TableCell>
                {config.showJustification === false || (
                    <TableCell style={styles.justificationWithColumn}>
                        {mraScore.justification ? String.nlToBr(mraScore.justification) : '-'}
                    </TableCell>
                )}
            </EditableInput>
        );
    });
};

const SubDimensionsDisplay = ({
    mraScoresTypesFiltered,
    mraScores,
    dimension,
    isNTXDimension,
    isGROUPDimension,
    mraStatus,
    userRole,
    lod1Submit,
    mraProcess,
    handleScoreChange,
}) => {
    const { t } = useTranslation();
    // On regroupe les scores par sous dimension
    //
    let mraScoresBySubdimension = mraScores.reduce((map, o) => {
        if (map[o.mraSubdimension.title]) {
            map[o.mraSubdimension.title].push(o);
        } else {
            map[o.mraSubdimension.title] = [o];
        }
        return map;
    }, {});

    let subdimensions = dimension.mraSubdimensions.map((subdimension, i) => {
        const subdimensionTitle = subdimension.title;

        let mraScoresSubdimension = mraScoresBySubdimension[subdimensionTitle] || [];

        return (
            <TableRow key={`${subdimensionTitle}_${i}`} className="tableMraScore">
                <TableCell>
                    <span>{t(subdimensionTitle)}</span>
                    <div style={{ marginTop: '5px' }}>
                        <span className="tableHelper">
                            <span className="tableHelperIcon"> ? </span>

                            <div className="tableHelperPopup">
                                <div className="tableHelperPopupHeading">Description</div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: fmtGuide(
                                            t(`${subdimensionTitle}_description`, subdimension.description)
                                            // subdimension.description
                                        ),
                                    }}
                                />
                            </div>
                        </span>
                        <span className="tableHelper">
                            <span className="tableHelperIcon"> S </span>

                            <div className="tableHelperPopup">
                                <div
                                    className="tableHelperPopupHeading"
                                    style={{ paddingBottom: 0, marginBottom: '-15px' }}
                                >
                                    {t('Scoring Guide')}
                                </div>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: fmtGuide(
                                            t(`${subdimensionTitle}_scoring_guide`, subdimension.scoringGuide)
                                        ),
                                    }}
                                />
                                {!isNTXDimension && !isGROUPDimension && (
                                    <>
                                        <div className="tableHelperPopupHeading">Scoring Frequency</div>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: fmtGuide(subdimension.scoringFrequency),
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </span>
                    </div>
                </TableCell>
                <TableCellsInput
                    mraScoresSubdimension={mraScoresSubdimension}
                    userRole={userRole}
                    mraStatus={mraStatus}
                    lod1Submit={lod1Submit}
                    mraProcess={mraProcess}
                    subdimension={subdimension}
                    handleScoreChange={handleScoreChange}
                    mraScoresTypesFiltered={mraScoresTypesFiltered}
                    isNTXDimension={isNTXDimension}
                    isGROUPDimension={isGROUPDimension}
                />
            </TableRow>
        );
    });

    return <TableBody>{subdimensions}</TableBody>;
};

export const OverviewDimension = (props) => {
    const {
        mraScores,
        dimension,
        overviewScores,
        mraProcess,
        mraStatus,
        userRole,
        handleScoreChange,
        lod1Submit,
    } = props;
    const parentDimension = useMemo(() => getParamByIri(dimension.dimension), [dimension]);
    const isNTXDimension = useMemo(
        () => parentDimension.systemId && parentDimension.systemId === 'MRA_DIMENSION_SOURCE_NTX',
        [parentDimension]
    );
    const isGROUPDimension = useMemo(
        () => parentDimension.systemId && parentDimension.systemId === 'MRA_DIMENSION_SOURCE_GROUP',
        [parentDimension]
    );
    const { t, i18n } = useTranslation();

    const overviewScoresWithHumanTypes = useMemo(() => {
        return Object.keys(overviewScores).map((key) => {
            return {
                type: getParamBySystemId(key),
                score: overviewScores[key].score,
            };
        });
    }, [overviewScores]);

    // Liste de couples [type.systemId, valeur de MRA_SCORES_TYPES associée]
    const [mraScoresTypesFiltered, setMraScoresTypesFiltered] = useState([]);

    useEffect(() => {
        let filtered = Object.entries(MRA_SCORES_TYPES).filter(([k, v]) =>
            v.canEdit.processes.includes((mraProcess && mraProcess.systemId) || '')
        );
        setMraScoresTypesFiltered(filtered);
    }, [mraProcess]);

    const headers = useMemo(() => {
        return mraScoresTypesFiltered.map(([scoreType, config], j) => {
            if (scoreType.indexOf('INHERENT') >= 0) {
                return null;
            }
            const param = getParamBySystemId(scoreType)
            
            let label = param && param.label ? param.label : '';
            // remove residual from any labels
            label = label.replace('residual ', '');
            return (
                <React.Fragment key={'th' + j}>
                    <TableCell style={styles.th}>{t(label)}</TableCell>
                    {config.showJustification === false || (
                        <TableCell style={styles.th}>{t('Justification')}</TableCell>
                    )}
                </React.Fragment>
            );
        });
    }, [mraScoresTypesFiltered, i18n.language]);

    return (
        <div className="sub-dimension container">
            <Paper>
                <AppBar position="static" className="background-lowlight" style={{ zIndex: 'auto' }}>
                    <Toolbar>
                        {dimension.isKeyDimension && (
                            <img src={StarImage} style={{ width: '24px', marginRight: '10px' }} />
                        )}
                        <Typography variant="h6" style={styles.dimensionTitle}>
                            {t(dimension.title)}
                        </Typography>
                        <div style={styles.dimensionScore}>
                            <DimensionCommitteeScore
                                dimensionScores={overviewScoresWithHumanTypes}
                                mraProcess={mraProcess}
                            />
                        </div>
                    </Toolbar>
                </AppBar>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={styles.th}>{t('Subdimension')}</TableCell>
                            {headers}
                        </TableRow>
                    </TableHead>
                    <SubDimensionsDisplay
                        {...props}
                        mraScoresTypesFiltered={mraScoresTypesFiltered}
                        isNTXDimension={isNTXDimension}
                        isGROUPDimension={isGROUPDimension}
                    />
                    <DimensionScoresBelow
                        dimensionScores={overviewScoresWithHumanTypes}
                        mraProcess={mraProcess}
                        userRole={userRole}
                        mraStatus={mraStatus}
                    />
                </Table>
            </Paper>
        </div>
    );
};

OverviewDimension.propTypes = {
    /** Liste de scores pour la dimension donnée */
    mraScores: PropTypes.array,
    dimension: PropTypes.any,
    overviewScores: PropTypes.array,
    /** Objet Parameter */
    mraProcess: PropTypes.object,
};

export const OverviewDimensions = (props) => {
    const {
        mraProcess,
        dimension,
        userRole,
        insertionFromImport,
        mraScores,
        mraStatus,
        onScoreChange,
        lod1Submit,
        mra,
    } = props;

    if (!mraScores) return null;

    const subdimensionsResource = useMemo(
        () =>
            new APIResource({
                id: 'mra_subdimensions',
                instanceId: 'mra_subdimensions',
                name: 'Subdimensions',
            }),
        []
    );
    const dimensionsResource = useMemo(
        () =>
            new APIResource({
                id: 'mra_dimensions',
                instanceId: 'mra_dimensions',
                name: 'Dimensions',
            }),
        []
    );

    const [dimensionComponents, setDimensionComponents] = useState([]);
    const [mraDimensions, setMraDimensions] = useState([]);
    const [mraSubdimensions, setMraSubdimensions] = useState([]);

    useEffect(() => {
        /**
         * Chargement simulané des différentes sources de données provenant de l'API
         */
        const getData = () => {
            let subdimensions = subdimensionsResource.apiGetCollection({
                page: 1,
                rowsPerPage: 10000,
            });

            let dimensions = dimensionsResource.apiGetCollection({
                page: 1,
                rowsPerPage: 10000,
                filters: {
                    dimension: getIdFromIri(dimension),
                },
            });

            Promise.all([subdimensions, dimensions]).then(function (loadedData) {
                const dimensions = loadedData[1];
                const keyDimensions = dimensions.filter((d) => d.isKeyDimension);
                const otherDimensions = dimensions.filter((d) => !d.isKeyDimension);
                const sortedDimensions = [...keyDimensions, ...otherDimensions];
                setMraDimensions(sortedDimensions);
                setMraSubdimensions(loadedData[0]);
            });
        };

        getData();
    }, [dimension, subdimensionsResource, dimensionsResource]);

    /**
     * Mise à jour des affichages des différentes dimensions et de leurs scores
     */
    useEffect(() => {
        const updateDimensionComponents = async () => {
            let dimensions = [];
            const overviewScores = await MRA.getMraScoresByDimensionGroupedByType(mra.id);
            mraDimensions.forEach((dimension) => {
                let dimensionScores = MRA.getMraScoresByDimension(dimension, mraSubdimensions, mraScores);
                dimensions.push({
                    key: dimension.id,
                    dimension: {
                        ...dimension,
                        mraSubdimensions: MRA.getSubdimensionsFromDimension(dimension, mraSubdimensions),
                    },
                    mraScores: dimensionScores,
                    overviewScores: overviewScores[dimension.id],
                });
            });

            setDimensionComponents(dimensions);
        }
        if (mraScores) {
            updateDimensionComponents();
        }
    }, [mraDimensions, mraSubdimensions, mraScores, mra]);

    return (
        <>
            {dimensionComponents.map((c) => (
                <OverviewDimension
                    key={JSON.stringify(c)}
                    dimension={c.dimension}
                    mraScores={c.mraScores}
                    overviewScores={c.overviewScores}
                    mraProcess={mraProcess}
                    mraStatus={mraStatus}
                    lod1Submit={lod1Submit}
                    userRole={insertionFromImport ? '' : userRole}
                    handleScoreChange={onScoreChange}
                />
            ))}
        </>
    );
};

OverviewDimensions.propTypes = {
    /** Objet process du MRA */
    mraProcess: PropTypes.object,
    mra: PropTypes.shape({id: PropTypes.any}),
};

const styles = {
    th: {
        fontSize: 18,
    },
    scoreWithColumn: {
        width: '5%',
    },
    scoreEditionWithColumn: {
        width: '15%',
    },
    justificationWithColumn: {
        width: '25%',
    },
    dimensionScore: {
        textAlign: 'right',
        width: '70%',
        fontSize: 18,
    },
    dimensionTitle: {
        width: '30%',
    },
};
