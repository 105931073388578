import React from "react";
import ParameterStore, {userHasRoleMRM, userIsVal, userValidatorTeamManagedRights} from "../../Store/ParameterStore";
import Http from '../../Services/Http';
import Alert from "../Alert";
import Modal from "../Modal";
import Navigation from "../Navigation";
import ValidateFindingNotice, { isItemExcludedFromValidation } from "../../Components/Review/ValidateFindingNotice";
import {
    REVIEW_STATUS_ASSESSMENT,
    REVIEW_STATUS_ASSESSMENT_AWAITING,
    REVIEW_STATUS_CLOSED,
    REVIEW_STATUS_DISMISSED,
    REVIEW_STATUS_PLANNING,
    REVIEW_STATUS_PLANNING_AWAITING,
} from "../../Admin/ReviewAdmin";
import User from "../User/User";
import APIResourceStore from "../../Store/APIResourceStore";
import EntityErrorModal from "../../Components/Forms/EntityErrorModal/EntityErrorModal";
import {CONTEXT_EDIT} from "../APIResource/APIResource";
import {FINDING_STATUS_DELETED} from "../../Admin/FindingAdmin";

export const requestValidationOfPlannedDocumentationDeliveryDate = (
    entity, _resource
) => {
    let requestedBy = 'Lod1';
    if(userIsVal(User, entity) || userHasRoleMRM()){
        requestedBy = 'Lod2'; //entity.plannedDocumentationRequestConfirmationByLod2 = true;
    }
    Http.post('review/planned-documentation/request',{
        reviewId: entity.id,
        requestedBy: requestedBy
    }).then(() => {
        Alert.show({
            message: 'Your request has been sent.',
            type: 'success',
        });
    }).catch(() => {

    });
}

/**
 * 
 * @param {{findingsEntities: Array|undefined, noticesEntities: Array|undefined}} entity - Review
 * @returns {boolean}
 */
export const hasAtLeastOneNotice = (entity) => {
    /** Si une Finding a une severité NA, alors on autorise l'absence de Notice : */
    if (
        typeof entity.findingsEntities !== 'undefined'
        && entity.findingsEntities.some(finding => APIResourceStore.resources?.parameters?.getObservableItemByPath(finding.severity)?.systemId === 'FINDING_SEVERITY_NA')
    ) {
        return true;
    }
    if(
        typeof entity.noticesEntities === 'undefined'
        || entity.noticesEntities.length === 0
    ){
        return false;
    }
    return entity.noticesEntities.some(notice => notice.status !== ParameterStore('NOTICE_STATUS_DELETED'));
}

export const hasAtLeastOneFinding = (entity) => {
    if(
        typeof entity.findingsEntities === 'undefined'
        || entity.findingsEntities.length === 0
    ){
        return false;
    }
    return entity.findingsEntities.some(finding => finding.status !== ParameterStore(FINDING_STATUS_DELETED));
}

/**
 * 
 * @param {*} resource 
 * @param {{findingsEntities: Array, noticesEntities: Array, reviewStatus: string}} entity 
 * @param {string} currentStatus 
 * @param {*} nextStatus 
 * @param {*} resourceComponent 
 * @param {*} tooltip 
 */
const openValidateModal = (
    resource,
    entity,
    currentStatus,
    nextStatus,
    resourceComponent,
    tooltip
) => {
    Modal.open({
        title: 'Validate and close',
        content: (
            <ValidateFindingNotice
                findings={entity.findingsEntities}
                notices={entity.noticesEntities}
                resource={resource}
                entity={entity}
                currentStatus={currentStatus}
                nextStatus={nextStatus}
                resourceComponent={resourceComponent}
                tooltip={tooltip}
            />
        ),
        onClose: () => {
            entity.reviewStatus = currentStatus;
        }
    });
};

/** 
 * En cas d'échec de validation de l'entité, on sauvegarde les informations du formulaire. 
 * 
 * @param {*} entity
 * @param {*} resourceComponent
 * @param {import('../APIResource/APIResource').APIResource} resource
 */
const saveCurrentEntity = (entity, resourceComponent, resource) => {
    if(resourceComponent){
        if(resourceComponent.getComponentName() === 'ResourceEdit'){
            resourceComponent.setState({entity: entity});
            /** 
             * apiPut sert juste à sauvegarder les modifications actuelles, pas besoin de remettre
             * à jour le state de resourceEdit ensuite, sinon on perdrait les informations entrées depuis le retour
             * de validation en erreur
             */
            resource.apiPut(entity).then(r => {});
        }else{
            resourceComponent.entity = entity;
        }
        resourceComponent.forceUpdate();
    } else {
        resource.apiPut(entity).then(r => {});
    }
}

/**
 * 
 * @param {import('../APIResource/APIResource').APIResource} resource 
 * @param {*} resourceComponent 
 * @param {string} currentStatus 
 * @param {Object} entity 
 * @param {string} [tooltip] 
 * @param {(entity: *) => void} successCallback 
 */
const validate = (resource, resourceComponent, currentStatus, entity, tooltip, successCallback) => {
    resource.validate(
        entity,
        (tooltip !== undefined && tooltip !== null ? 'To ' + tooltip.toLowerCase() + ', the' : 'The') + ' following fields are required: ',
        CONTEXT_EDIT,
        false
    ).then((success) => {
        let nextStatusAlreadyDefined = entity.reviewStatus;
        //On va sauvegarder la review avec les nouvelles valeurs fournies par l'utilisateur mais sans le nouveau statut
        entity.reviewStatus = currentStatus;

        if(!Array.isArray(success) || (Array.isArray(success) && success.length === 0)){
            //Si pas d'erreur alors on définit le nouveau statut
            entity.reviewStatus = nextStatusAlreadyDefined;
            if(typeof successCallback === 'function'){
                successCallback(entity);
            }
        }
    }).catch((errors) => {
        //Si erreur on revient au statut précédent
        entity.reviewStatus = currentStatus;
        Modal.open({
            title: "Please complete the review",
            content: <EntityErrorModal
                errors={errors}
                entityId={entity.id}
                resource={resource}
                resourceComponent={resourceComponent}
            />,
        });
        //Navigation.router.history.push(`/resource/${resource.instanceId}/${entity.id}/edit`);
    })
}

/**
 * 
 * @param {import('../APIResource/APIResource').APIResource} resource 
 * @param {Object} entity 
 * @param {*} currentStatus 
 * @param {*} nextStatus 
 * @param {*} resourceComponent
 * @param {string} tooltip 
 */
export const submitReview = (
    resource,
    entity,
    currentStatus,
    nextStatus,
    resourceComponent,
    tooltip,
    spinner = false
) => {

    let defineStatusThenValidate = () => {
        entity.reviewStatus = nextStatus;

        let success = 'The review has been saved';
        if (
            nextStatus === ParameterStore(REVIEW_STATUS_PLANNING)
        ) {
            if (userValidatorTeamManagedRights(User.profile.managedScopes, entity)) {
                success = 'The review has entered the planning phase.';
            } else {
                success = 'The review has entered the planning phase. VAL manager has been notified.';
            }
        } else if (
            nextStatus === ParameterStore(REVIEW_STATUS_PLANNING_AWAITING)
            || nextStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING)
        ) {
            success = 'The review has been submitted to VAL manager for validation.';
        } else if (nextStatus === ParameterStore(REVIEW_STATUS_CLOSED)) {
            success = 'The review has been closed.';
        } else if (nextStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT)) {
            success = 'The review entered the assessment phase.';
        } else if (nextStatus === ParameterStore(REVIEW_STATUS_DISMISSED)) {
            success = 'The review is now cancelled.';
        }

        if (
            nextStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT_AWAITING)
        ) {//La cloture est demandée
            // Vérification des findings/notices si on n'a pas coché "No findings/notices applicable to this review."
            if (!entity?.issue) {
                if (
                    hasAtLeastOneFinding(entity) === false
                ) {
                    //Notice mais pas de finding -> Erreur
                    Alert.show({
                        message: 'At least one finding is required at this stage.',
                        type: 'warning',
                    });
                    entity.reviewStatus = currentStatus;
                    /** échec de validation de l'entité, on sauvegarde les informations si nécessaire */
                    saveCurrentEntity(entity, resourceComponent, resource);
                    Navigation.router.history.push(
                        `/resource/${resource.instanceId}/${entity.id}/detail?tab=Notices`
                    );
                    return;
                }
                if (
                    !entity.validate && 
                    (
                        // On n'affiche pas la Modal s'il n'y a ni notice ni finding à valider.
                        entity.findingsEntities.filter(f => !isItemExcludedFromValidation(f, "finding", false)).length > 0
                        || entity.noticesEntities.filter(n => !isItemExcludedFromValidation(n, "notice", false)).length > 0
                    ) 
                ) {
                    // Validation des champs nécessaires à la Review avant de valider les Findings/Notices
                    validate(
                        resource,
                        resourceComponent,
                        currentStatus,
                        entity,
                        tooltip,
                        (entity) => {
                            //Vérification de tous les champs requis des findings et notices
                            openValidateModal(
                                resource,
                                entity,
                                currentStatus,
                                nextStatus,
                                resourceComponent,
                                tooltip
                            );
                        }
                    )
                    return;
                }
            }
        }

        validate(
            resource,
            resourceComponent,
            currentStatus,
            entity,
            tooltip,
            (entity) => {
                //Si tous les champs sont ok, alors on sauvegarde le statut suivant sans vérification
                entity.reviewStatus = nextStatus;

                if(resourceComponent && resourceComponent.getComponentName() === 'ResourceEdit'){
                    resourceComponent.setState({apiUpdateInProgress: true});
                }
                resource.apiPut(entity)
                    .then((entity) => {
                        Alert.show({message: success, type: "success"});
                        if(resourceComponent && resourceComponent.getComponentName() === 'ResourceEdit'){
                            resourceComponent.setState({entity: entity});
                        }else{
                            resourceComponent.entity = entity;
                        }
                        Navigation.router.history.push(`/resource/${resource.instanceId}/${entity.id}/detail`);
                    })
                    .catch(() => {
                        entity.reviewStatus = currentStatus;
                        /** échec de validation de l'entité, on sauvegarde les informations */
                        saveCurrentEntity(entity, resourceComponent, resource);
                    })
                    .finally(() => {
                        if(resourceComponent && resourceComponent.getComponentName() === 'ResourceEdit'){
                            resourceComponent.setState({apiUpdateInProgress: false});
                        }
                    });
            }
        );
    }

    if(resourceComponent && resourceComponent.getComponentName() === 'ResourceEdit'){
        resource.apiPut(entity).then((e) => {
            defineStatusThenValidate(e);
        })
    }else{
        defineStatusThenValidate(entity);
    }



};

export const noticeMissingFinding = () => {
    Alert.show({message: "All the notices must be associated to at least one finding", type: 'warning'});
}
