import React, { Component } from "react";
import Modal from "../../Services/Modal";
import Alert from "../../Services/Alert";
import ParameterStore from "../../Store/ParameterStore";
import {
    DOCUMENT_ACTION_DELETE,
    DOCUMENT_ACTION_LIST,
    DOCUMENT_ACTION_SHOW,
    DocumentManager
} from "../Display/DocumentManager/DocumentManager";
import {EntityForm} from "../Forms/EntityForm/EntityForm";
import { ModalContent } from "../Modal/ModalContent";
import { ButtonBar } from "../Modal/ButtonBar";
import { ActionButton } from "../Modal/ActionButton";
import Navigation from "../../Services/Navigation";

const noticeFields = {
    deliveryDate: {
        title: 'Delivery date',
        type: 'date',
        helperText: 'Delivery date of LoD 1 documentation in order to close the notice',
        required: true,
    },
    deliveryComment: {
        title: 'Delivery comment',
        type: 'textarea',
        helperText: 'Comment provided by LoD 1 when completing the action',
        required: true,
    },
    documentsEntities: {
        title:
            "Please attach documents to justify the delivery.",
        type: "documents",
        params: {
            entityResource: "notices",
            propertyName: "documentsEntities",
            fieldName: "Document",
            allowedCategory: false,
            links: false,
            allowedAction: (entity, document, action) =>
                [DOCUMENT_ACTION_LIST, DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_DELETE].includes(action),
        },
        // eslint-disable-next-line react/display-name
        display: (field, value, entity) => (
            <DocumentManager
                values={value}
                entity={entity}
                entityResource={"notices"}
                fieldName={"Document"}
                propertyName={"documentsEntities"}
                allowedAction={(entity, document, action) =>
                    [DOCUMENT_ACTION_LIST, DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_DELETE].includes(action)
                }
            />
        ),
    },
};

export class MarkAsDeliveredForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: false,
        };
        this.notice = null;
    }

    handleSave(){
        if (
            (this.notice.deliveryDate === null || this.notice.deliveryDate === undefined)
            || (this.notice.deliveryComment === null || this.notice.deliveryComment === undefined)
        ) {
            Alert.show({
                message: "The delivery date and the delivery comments are required.",
                type: "error"
            });
            return;
        }

        this.setState({progress: true});

        this.save("Your notice has been saved.");
    }

    save(message){

        this.notice.status =  ParameterStore('NOTICE_STATUS_WAITING_FOR_VALIDATION');
        const {id , deliveryDate, deliveryComment, status} = this.notice;
        // On n'envoie pas les documents, qui ont déjà été associés par le DocumentManager
        this.props.resource.apiPut({
            id, deliveryDate, deliveryComment, status
        }).then(() => {
            Alert.show({ message: message, type: "success" });
            this.props.resource.getItem(this.notice.id, true).then((notice) => {
                Modal.close();
                this.setState({progress: false});
                this.props.resourceComponent.entity = notice;
                this.props.resourceComponent.forceUpdate();
            });
        }).catch(err => {
            console.error(err);
            Modal.close();
            this.setState({progress: false});
        });
    }

    setNotice(getFields){
        let fields = getFields(this.notice)
        this.notice.deliveryDate = fields.deliveryDate;
        this.notice.deliveryComment = fields.deliveryComment;
        this.notice.documentsEntities = fields.documentsEntities;
    }

    render() {
        this.notice = this.props.entity;
        if(!this.notice.deliveryDate){
            this.notice.deliveryDate = new Date();
        }
        return (
            <ModalContent>
                <EntityForm
                    entity={this.props.entity}
                    fields={noticeFields}
                    onUpdate={(n) => this.setNotice(n)}
                />
                <ButtonBar>
                    <ActionButton
                        onClick={() => {
                            this.handleSave();
                        }}
                        loading={this.state.progress}
                    >
                        Save
                    </ActionButton>
                </ButtonBar>
            </ModalContent>
        );
    }
}
