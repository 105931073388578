import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import FieldProviderStore from '../../FieldProviders/__FieldProviderStore';
import AppBarStore from '../../../../Store/AppBarStore';
import Environment from '../../../Environment';
import Navigation from '../../../Navigation';
import {
    hasRightsForOperation,
    hasRightsForField,
    renderAdditionalActionButtons,
    renderAdditionalLinkButton,
} from '../../Utils';
import { SuffixButton } from '../../../../Components/SuffixButton/SuffixButton';
import { HelperButton } from '../../../../Components/HelperButton/HelperButton';
import User, { ROLE } from '../../../User/User';
import Alert from '../../../Alert';
import {APIResource, CONTEXT_DELETE, CONTEXT_DETAIL, CONTEXT_EDIT, CONTEXT_LIST} from "../../APIResource";
import LoadingIndicator from "../../../../Components/LoadingIndicator/LoadingIndicator";
import {OpenModal} from "../../../../Components/Modal/OpenModal";
import ModalComplementary from "../../../ModalComplementary";
import Modal from "../../../Modal";

/**
 * @todo on devrait séparer le test de l'affichage display de la génération du composant.
 * 
 * @param {*} field 
 * @param {*} entity 
 * @param {string} fieldId 
 * @param {import('../../APIResource').APIResource} resource - APIResource pour tester le displayCondition, automatiquement affiché si vide, default null
 * @param {*} settingsApi - default {}
 * @param {ResourceDetail} resourceDetail - default null
 * @returns
 */
export const genDisplayComponent = (field, entity, fieldId, resource = null, settingsApi = {}, resourceDetail = null) => {
    let displayComponent;

    if (
        !hasRightsForField(field, {
            hasRole: settingsApi && settingsApi.hasRole,
            operation: CONTEXT_DETAIL,
            entity,
        })
    ) {
        displayComponent = FieldProviderStore.default.getDisplay(field, '- Restricted field -', entity, {
            textStyle: {
                color: 'gray',
                fontStyle: 'italic',
            },
        });
    } else if (
        (field.displayConditions || field.displayCondition) &&
        resource &&
        !resource.filter(entity, field.displayConditions || field.displayCondition, {}, CONTEXT_DETAIL, fieldId)
            .length
    ) {
        return;
    } else if (field.display) {
        displayComponent = field.display(field, entity[fieldId], entity, {}, resourceDetail);
    } else if (field.type && FieldProviderStore[field.type]) {
        displayComponent = FieldProviderStore[field.type].getDisplay(field, entity[fieldId], entity, {});
    } else {
        displayComponent = FieldProviderStore.default.getDisplay(field, entity[fieldId], entity, {});
    }
    return displayComponent;
}

export const ResourceDetail = observer(
    class ResourceDetail extends Component {
        entity = {
            __init: false
        };

        constructor(props) {
            super(props);
            /** @type {{resource: import('../../APIResource').APIResource}} */
            this.props;

            this.genLayout = this.genLayout.bind(this);
            this.loadFromApi = true;
            this.layout = this.props.resource.genOperationLayout(CONTEXT_DETAIL);
            this.initCompleted = null;
            this.nbFieldsPerTabCache = {};
            this.entity = {};

            this.state = {
                currentTab: !this.props.inModal ? this.props.resource.getTabId(CONTEXT_DETAIL, Environment.getUriParameter('tab')) : 0,
                currentTabName: !this.props.inModal && Environment.getUriParameter('tab') ? Environment.getUriParameter('tab') : '',
                nbFieldsPerTab: {},
                editRights: null,
                deleteRights: null,
                /** Vrai tant que entity n'est pas récupérée du back ou du cache. */
                loadingEntity: true,
            };

            this.setAppBarTitle();
        }

        getComponentName(){
            return 'ResourceDetail';
        }

        setAppBarTitle() {
            let self = this;
            if(!this.props.inModal){
                if(this.props.resource.componentForTitle){
                    this.props.resource.getItem(this.props?.match?.params?.id || this.props.objectId).then((entity) => {
                        AppBarStore.title = this.props.resource.componentForTitle(entity, this.props.resource, self);
                    });
                }else if (this.props.resource.fieldForTitle) {
                    this.props.resource.getItem(this.props?.match?.params?.id || this.props.objectId).then((entity) => {
                        let entityTitle = entity ? entity[this.props.resource.fieldForTitle] : '';
                        AppBarStore.title = this.props.resource.name + ': ' + entityTitle;
                        if(this.props.resource.resourceId === 'models'){
                            AppBarStore.previousTitle = entityTitle;
                        }
                    });
                } else {
                    AppBarStore.title = this.props.resource.name + ': #' + this.props?.match?.params?.id || this.props.objectId + ' detail';
                }
                AppBarStore.appBarStoreContext = CONTEXT_DETAIL;
            }
        }

        UNSAFE_componentWillMount() {
            if(this.loadFromApi){
                this.entity = this.props.resource.getObservableItem(this.props?.match?.params?.id || this.props.objectId, false, true);
                this.props.resource.getItem(this.props?.match?.params?.id || this.props.objectId, this.loadFromApi).then((entity) => {
                    this.entity = entity;
                    this.loadFromApi = false;
                    if (this.props.resource.getAclForDetailFields) {
                        this.props.resource.getAclForDetailFields({
                            entity: entity || { id: this.props?.match?.params?.id || this.props.objectId },
                            resource: this.props.resource,
                            context: this,
                            routeParams: this.props?.match?.params,
                        });
                    }
                    this.setState({loadingEntity: false});
                });
            }
        }

        componentDidMount() {
            Navigation.id = 'main-content-resource-detail';
            let entity = this.props.resource.getObservableItem(this.props?.match?.params?.id || this.props.objectId, this.state.loadingEntity ? false : this.loadFromApi, this.state.loadingEntity);
            if (this.props.resource.operations[CONTEXT_DETAIL] && this.props.resource.operations[CONTEXT_DETAIL].onInit) {
                this.props.resource.operations[CONTEXT_DETAIL].onInit({
                    entity: entity || { id: this.props?.match?.params?.id || this.props.objectId },
                    resource: this.props.resource,
                    context: this,
                });
            }
            this.verifyAllTabs();
        }

        componentWillUnmount() {
            Navigation.id = '';
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if (
                prevProps.match?.params.id !== this.props.match?.params?.id
                || prevProps.objectId !== this.props.objectId
            ) {
                if (prevProps.resource !== this.props.resource)
                    this.layout = this.props.resource.genOperationLayout(CONTEXT_DETAIL);

                const entityId = prevProps.match?.params.id !== this.props.match?.params?.id ? this.props.match?.params?.id : this.props.objectId;
                // On va chercher dans les items preload (donc avec des propriétés non chargées pour l'instant) :
                this.entity = this.props.resource.getObservableItem(entityId, false);
                this.setState({loadingEntity: true});
                this.props.resource.getItem(entityId, true).then((entity) => {
                    this.entity = entity;
                    if (this.props.resource.getAclForDetailFields) {
                        this.props.resource.getAclForDetailFields({
                            entity: entity || { id: entityId },
                            resource: this.props.resource,
                            context: this,
                            routeParams: this.props?.match?.params,
                        });
                    }
                    this.setState({loadingEntity: false});
                });
            } else if (this.entity && this.entity.__init && this.initCompleted !== this.entity.__init) {
                this.initTabs();
                this.initCompleted = this.entity.__init;
                this.verifyEditRights(this.entity);
                this.verifyDeleteRights(this.entity);
            } else if (this.state.loadingEntity !== prevState.loadingEntity && this.state.loadingEntity === false) {
                this.initTabs();
                this.initCompleted = this.entity.__init;
                this.setAppBarTitle();
                this.verifyEditRights(this.entity);
                this.verifyDeleteRights(this.entity);
            } else if (this.state.loadingEntity !== prevState.loadingEntity && this.state.loadingEntity === true) {
                this.initTabs();
                this.setAppBarTitle();
            }
        }

        initTabs = () => {
            if (!this.props.inModal) {
                this.verifyAllTabs();
            } else {
                this.setState({
                    currentTab: 0,
                    currentTabName: '',
                });
                this.verifyAllTabs(true);
            }
        }

        verifyAllTabs = (userClickTab) => {
            Object.keys(this.layout.tabs).forEach(this.verifyTabDisplayCondition);
            this.setState({ nbFieldsPerTab: this.nbFieldsPerTabCache });
            if (!userClickTab) {
                let newTab = this.props.resource.getTabId(CONTEXT_DETAIL, Environment.getUriParameter('tab'), this.nbFieldsPerTabCache);
                this.setState({
                    currentTab: newTab,
                    currentTabName: Object.keys(this.layout.tabs)[newTab],
                });
                this.forceUpdate();
            }
        };

        verifyEditRights = (entity) => {
            return this.verifyRights(entity, CONTEXT_EDIT);
        };

        verifyDeleteRights = (entity) => {
            return this.verifyRights(entity, CONTEXT_DELETE);
        };

        verifyRights = (entity, context) => {
            let varForState = null;
            switch (context){
                case CONTEXT_EDIT:
                    varForState = 'editRights';
                    break;
                case CONTEXT_DELETE:
                    varForState = 'deleteRights';
                    break;
                default:
                    break;
            }

            Promise.resolve(
                hasRightsForOperation(context, {
                    ...this.props,
                    entity,
                    hasRole: this.props.settingsApi && this.props.settingsApi.hasOneRole,
                })
            ).then((rights) => {
                this.setState({ [varForState]: rights })
            });
        };

        verifyTabDisplayCondition = (tabLabel) => {
            let count = 0;
            if (
                this.layout.tabs[tabLabel].displayConditions &&
                this.layout.tabs[tabLabel].displayConditions(this.entity) === false
            ) {
                this.nbFieldsPerTabCache[tabLabel] = 0;
                return;
            }
            const rows = this.layout.tabs[tabLabel].rows;
            for (let rowId in rows) {
                let currentRow = rows[rowId];
                for (let panelId in currentRow.panels) {
                    const panelFields = currentRow.panels[panelId].fields;
                    for (let fieldIndex in panelFields) {
                        let fieldId = panelFields[parseInt(fieldIndex)];
                        const fieldData = this.genField(fieldId, this.entity);
                        if (fieldData) {
                            count++;
                        }
                    }
                }
            }
            this.nbFieldsPerTabCache[tabLabel] = count;
        };

        genField = (fieldId, entity) => {
            let field = this.props.resource.fields[fieldId];
            let displayComponent = null;
            if (!field) {
                console.error('Champ inconnu', this.props.resource.instanceId, fieldId);
                return;
            }

            displayComponent = genDisplayComponent(field, entity, fieldId, this.props.resource, this.props.settingsApi, this);
            return (
                displayComponent &&
                <div
                    key={fieldId}
                    className={
                        'detail-field ressource-api-field' +
                        (field.helperTextDisplay ? ' with-helper-button' : '') +
                        (field.suffix ? ' with-suffix-button' : '')
                    }
                >
                    {displayComponent}
                    {field.suffix ? <SuffixButton suffix={field.suffix} entity={entity} /> : null}
                    {field.helperTextDisplay ? (
                        <HelperButton helperText={field.helperTextDisplay} entity={entity} />
                    ) : null}
                </div>
            );
        };

        refreshParent = () => {
            if(this.props.inModal && this.props.openModalComponent){
                let parentResource = null;
                if (this.props.openModalComponent.props.parentInstanceId){
                    parentResource = new APIResource({
                        instanceId: this.props.openModalComponent.props.parentInstanceId
                    });
                }
                if (parentResource) {
                    parentResource.apiGetOne(this.props.openModalComponent.props.parentId, true).then((e) => {
                        if(this.props.openModalComponent.props.parentResourceComponent){
                            this.props.openModalComponent.props.parentResourceComponent.entity = e;
                            this.props.openModalComponent.props.parentResourceComponent.forceUpdate();
                        }
                        if(this.props.openModalComponent.props.newLayer) {
                            ModalComplementary.close()
                        }else{
                            Modal.close()
                        }
                    });
                }
            }
        }

        /**
         *
         * Generate the layout for the detail page
         * @param {*} entity
         * @returns
         */

        genLayout(entity) {
            let self = this;
            let { nbFieldsPerTab } = this.state;

            if (entity.deleted) {
                if (User.hasOneRole(ROLE.ADMIN) || User.hasOneRole(ROLE.MRM) || User.hasOneRole(ROLE.IG)) {
                    Alert.show({
                        message: 'Warning : This element is deleted.',
                        type: 'warning'
                    });
                } else {
                    Navigation.router.history.push('/');
                }
            }

            if (this.layout) {
                let genHeader = function (entity, context) {
                    return typeof self.props.resource.operations[context].header === 'function'
                        ? self.props.resource.operations[context].header(entity, context)
                        : null;
                };
                let genTabs = function () {
                    let tabs = [];
                    for (let tabLabel in self.layout.tabs) {
                        tabs.push(
                            <Tab
                                label={tabLabel}
                                key={tabLabel}
                                className={nbFieldsPerTab[tabLabel] < 1 ? 'display-hidden' : ''}
                            />
                        );
                    }
                    return tabs;
                };
                let genContent = function () {
                    let panels = [];
                    const currentTabLabel = Object.keys(self.layout.tabs)[self.state.currentTab];
                    const rows = self.layout.tabs[currentTabLabel].rows;

                    for (let rowId in rows) {
                        let currentRow = rows[rowId];

                        for (let panelId in currentRow.panels) {
                            const panelFields = currentRow.panels[panelId].fields;

                            let fields = [];
                            for (let fieldIndex in panelFields) {
                                let fieldId = panelFields[parseInt(fieldIndex)];

                                const fieldData = self.genField(fieldId, entity);
                                if (fieldData) {
                                    fields.push(fieldData);
                                }
                            }
                            if (fields.length) {
                                panels.push(
                                    <Grid
                                        key={panelId}
                                        item
                                        xs={currentRow.panels[panelId].cols}
                                        style={styles.blockHeightStyle}
                                        className="ressource-api-container"
                                    >
                                        <Paper style={styles.gridPaper} className="ressource-api-box">
                                            <h1 className="background-linear-gradient">{panelId}</h1>
                                            {fields}
                                        </Paper>
                                    </Grid>
                                );
                            }
                        }
                    }
                    return (
                        <Grid
                            container
                            spacing={2}
                            className={`container resource-detail ${
                                self.props.resource.instanceId ? self.props.resource.instanceId.toLowerCase() : ''
                            } ${self.props.resource.resourceId ? self.props.resource.resourceId.toLowerCase() : ''}`}
                        >
                            {panels}
                        </Grid>
                    );
                };

                let additionalActionButtons = renderAdditionalActionButtons(
                    this.props.resource,
                    entity,
                    CONTEXT_DETAIL,
                    this
                );
                let allowEditInModal = false;
                if(self.props.inModal && typeof self.props.openModalComponent.props.allowStayInModal === 'function'){
                    allowEditInModal = self.props.openModalComponent.props.allowStayInModal(entity, CONTEXT_EDIT);
                }else if(self.props.inModal && typeof self.props.openModalComponent.props.allowStayInModal === 'boolean'){
                    allowEditInModal = self.props.openModalComponent.props.allowStayInModal;
                }
                return (
                    <div>
                        {genHeader(entity, CONTEXT_DETAIL)}
                        {Object.keys(self.layout.tabs).length > 1 ? (
                            <AppBar position="static" color="default">
                                <Tabs
                                    value={this.state.currentTab}
                                    onChange={(event, val) => {
                                        this.setState({
                                            currentTab: val,
                                            currentTabName: Object.keys(this.layout.tabs)[val],
                                        });
                                        this.verifyAllTabs(true);
                                    }}
                                    className="tabs-menu"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="standard"
                                    scrollButtons="auto"
                                    key={'resourceDetailTab'}
                                >
                                    {genTabs()}
                                </Tabs>
                            </AppBar>
                        ) : null}
                        {genContent()}
                        {Array.isArray(additionalActionButtons) && additionalActionButtons.length > 0 ? (
                            <div
                                className={
                                    'button-custom-bar-bottom resource-detail' +
                                    (self.props.resource.instanceId
                                        ? ' ' + self.props.resource.instanceId.toLowerCase()
                                        : '') +
                                    (self.props.resource.resourceId
                                        ? ' ' + self.props.resource.resourceId.toLowerCase()
                                        : '')
                                }
                            >
                                {additionalActionButtons}
                            </div>
                        ) : null}
                        <div
                            className={
                                'button-bar-bottom resource-detail ' +
                                (self.props.resource.instanceId
                                    ? ' ' + self.props.resource.instanceId.toLowerCase()
                                    : '') +
                                (self.props.resource.resourceId
                                    ? ' ' + self.props.resource.resourceId.toLowerCase()
                                    : '')
                            }
                        >
                            {!this.props.inModal && renderAdditionalLinkButton(this.props.resource, entity, CONTEXT_DETAIL, this)}
                            {this.state.deleteRights &&
                            // no accessCondition or condition is true for this entity...
                            (!self.props.resource.operations[CONTEXT_DELETE].itemAccessCondition ||
                                self.props.resource.operations[CONTEXT_DELETE].itemAccessCondition(entity)) ? (
                                self.props.resource.operations[CONTEXT_DELETE].component ? (
                                    self.props.resource.operations[CONTEXT_DELETE].component(entity)
                                ) : (
                                    <Button
                                        variant="contained"
                                        className="button-delete primary tooltip"
                                        onClick={() => {
                                            self.props.resource
                                                .deleteItemWithModalConfirmation(entity)
                                                .then((entity) => {
                                                    if(this.props.inModal && this.props.openModalComponent){
                                                        this.refreshParent();
                                                    } else if (self.props.resource.operations[CONTEXT_DELETE].returnPath === undefined) {
                                                        Navigation.router.history.push(
                                                            '/resource/' + this.props.resource.instanceId + '/list'
                                                        );
                                                    } else if (self.props.resource.operations[CONTEXT_DELETE].returnPath) {
                                                        Navigation.router.history.push(
                                                            self.props.resource.operations[CONTEXT_DELETE].returnPath(entity)
                                                        );
                                                    }
                                                });
                                        }}
                                    >
                                        <i className="fa fa-trash-alt"></i>
                                        <span className="tooltiptext">Delete</span>
                                    </Button>
                                )
                            ) : null}
                            {!self.props.inModal && this.state.editRights === null ? (
                                <Button variant="contained" disabled className="button-edit warning tooltip">
                                    <i className="fa fa-edit"></i>
                                    <span className="tooltiptext">Edit</span>
                                </Button>
                            ) : !self.props.inModal && this.state.editRights ? (
                                <Link
                                    to={
                                        '/resource/' +
                                        this.props.resource.instanceId +
                                        '/' +
                                        entity.id +
                                        '/edit' +
                                        this.props.resource.getQueryParams(CONTEXT_DETAIL, self.state.currentTabName)
                                    }
                                    className="item"
                                >
                                    <Button variant="contained" className="button-edit warning tooltip">
                                        <i className="fa fa-edit"></i>
                                        <span className="tooltiptext">Edit</span>
                                    </Button>
                                </Link>
                            ) : null}

                            {self.props.inModal && this.state.editRights === null ? (
                                <Button variant="contained" disabled className="button-edit warning tooltip">
                                    <i className="fa fa-edit"></i>
                                    <span className="tooltiptext">Edit</span>
                                </Button>
                            ) : self.props.inModal && allowEditInModal === false && this.state.editRights === true ? (
                                <Link
                                    to={
                                        '/resource/' +
                                        this.props.resource.instanceId +
                                        '/' +
                                        entity.id +
                                        '/edit' +
                                        this.props.resource.getQueryParams(CONTEXT_DETAIL, self.state.currentTabName)
                                    }
                                    className="item"
                                >
                                    <Button variant="contained" className="button-edit warning tooltip">
                                        <i className="fa fa-edit"></i>
                                        <span className="tooltiptext">Edit</span>
                                    </Button>
                                </Link>
                            ) : self.props.inModal && allowEditInModal === true && this.state.editRights === true ? (
                                <OpenModal
                                    {...self.props.openModalComponent.props}
                                    withIcon={true}
                                    containerClassName="item"
                                    context={CONTEXT_EDIT}
                                    flat={false}
                                />
                            ) : null }

                            {hasRightsForOperation(CONTEXT_LIST, {
                                ...this.props,
                                entity,
                                hasRole: this.props.settingsApi && this.props.settingsApi.hasOneRole,
                            }) ? (
                                <Link to={'/resource/' + this.props.resource.instanceId + '/list'} className="item">
                                    <Button variant="contained" className="secondary tooltip">
                                        <i className="fa fa-list"></i>
                                        <span className="tooltiptext">{this.props.resource.name} list</span>
                                    </Button>
                                </Link>
                            ) : null}
                        </div>
                    </div>
                );
            } else {
            }
        }

        renderAdditionalActionButtons;
        renderAdditionalLinkButton;

        genLoading() {
            if(!this.props.inModal){
                return null;
            }

            return <LoadingIndicator styles={styles.loadingIndicator} />;
        }

        render() {
            return !this.state.loadingEntity ? this.genLayout(this.entity) : this.genLoading();
        }
    }
);

const styles = {
    blockHeightStyle: {
        paddingBottom: 5,
        marginBottom: 35,
    },
    gridPaper: {
        marginTop: 15,
        marginBottom: 15,
        height: '100%',
    },
    loadingIndicator: {
        display: 'flex',
        transition: 'color 150ms',
        alignSelf: 'center',
        fontSize: '0.5rem',
        margin: '5rem 0.5rem 5rem 0.5rem',
        textAlign: 'center',
    },
};
