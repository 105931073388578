import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';

import MainMenuItemComponent from './MainMenuItemComponent';

import User, { ROLE } from '../../Services/User/User';

const MainMenuItem = (props) => {
    const { text, to, icon, children = [], role } = props;
    const classes = useStyles();
    const isExpandable = children && children.length > 0;
    const [open, setOpen] = useState(false);

    function handleClick(event) {
        setOpen(!open);
    }

    const MainMenuItemStandard = (
        <MainMenuItemComponent
            className={classes.mainMenuItem}
            to={to}
            onClick={handleClick}
        >
            {icon && (
                <ListItemIcon className={classes.mainMenuItemIcon}>
                    {icon}
                </ListItemIcon>
            )}
            <ListItemText primary={text} inset={!icon} />
            {!to && isExpandable && !open && <IconExpandMore />}
            {!to && isExpandable && open && <IconExpandLess />}
        </MainMenuItemComponent>
    );

    const MainMenuItemChildren = isExpandable ? (
        <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className={classes.mainMenuItemNested}
        >
            <Divider />
            <List component="div" disablePadding>
                {children.map((child, index) => (
                    <MainMenuItem {...child} key={index} />
                ))}
            </List>
        </Collapse>
    ) : null;

    let hasAccess = User.hasOneRole(ROLE.STD_USER);
    if(!hasAccess){
        hasAccess = User.hasOneRole(ROLE.IG);
    }
    if(typeof role === 'string'){
        hasAccess = User.hasOneRole(role);
    }else if(typeof role === 'function'){
        hasAccess = role();
    }

    if (hasAccess) {
        return (
            <>
                {MainMenuItemStandard}
                {MainMenuItemChildren}
            </>
        );
    } else {
        return <></>;
    }
};

const useStyles = makeStyles({
    mainMenuItem: {
        color: '#fff',

        '&.active': {
            background: 'rgba(255, 255, 255, 0.08)',
            '& .MuiListItemIcon-root': {
                //color: '#fff',
            },
        },
        '& .MuiListItemText-primary': {
            //fontWeight: '600', // à réactiver si on veut le menu en gras
        },
    },
    mainMenuItemNested: {
        '& .MuiListItemIcon-root': {
            color: '#388df5',
        },
        '& .MuiListItem-root': {
            marginLeft: '48px',
            width: '232px',
            paddingTop: '2px',
            paddingBottom: '2px',
            color: '#388df5',
        },
    },
    mainMenuItemIcon: {
        color: '#388df5',
        '& .MuiIcon-root': {
            width: '1.5em',
        },
    },
});

export default MainMenuItem;
