import React, {Component} from 'react';

export class SuffixButton extends Component{

    constructor(props) {
        super(props);
    }

    render() {
        let suffix = typeof this.props.suffix === 'string' ? this.props.suffix : this.props.suffix(this.props.entity);
        return (
            (suffix !== null ?
                <span className="suffix">{suffix}</span>
            : null)
        );
    }
};