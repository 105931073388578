class LocalNotification {
         notify(notification){
           this.show("From " + notification.fromUser.fullName, {
             body: notification.content,
             link: notification.link
               ? `${notification.link}`
               : ""
           });
         }

         show(message, params)  {
          let notification;
          if (!("Notification" in window)) {
             console.warn(
               "Ce navigateur ne supporte pas les notifications desktop"
             );
             return;
           }
           if (Notification.permission === "granted") {
             notification = new Notification(message, params);
           }

           else if (Notification.permission !== "denied") {
             Notification.requestPermission(function(permission) {
               // Quelque soit la réponse de l'utilisateur, nous nous assurons de stocker cette information
               if (!("permission" in Notification)) {
                 Notification.permission = permission;
               }

               // Si l'utilisateur est OK, on crée une notification
               if (permission === "granted") {
                notification = new Notification(message, params);
               }
             });
           }
           if (notification && params.link) {
              notification.onclick = (event) => {
                window.location.href = params.link;
              }
           }
         }
       }


       export default new LocalNotification();