import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FieldProviderStore from '../../../Services/APIResource/FieldProviders/__FieldProviderStore';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from "../../../Services/Alert";
import Http from "../../../Services/Http";
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { OptionalTooltip } from '../../OptionalTooltip/OptionalTooltip';
import Navigation from '../../../Services/Navigation';
import {OpenModal} from "../../Modal/OpenModal";
import Checkbox from "@material-ui/core/Checkbox";
import {RowCheckbox} from "../../../Services/APIResource/Components/DataTable/DataTable";

export class TableDisplay extends Component {
    constructor(props) {
        super(props);

        this.filter = this.filter.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.stopPropagation = this.stopPropagation.bind(this);

        this.state = {
            initialRows: null,
            rows: null,
            loading: false,
            activeFilters: {},
            selection: [],
            selectAll: false,
        };
    }

    componentDidMount() {
        if (this.props.loading !== undefined ) this.setState({loading: this.props.loading})
        if(this.props.endpoints !== undefined){
            this.loadRows();
        }else if (this.props.rows !== undefined) {
            this.setState({
                initialRows: this.props.rows,
                rows: this.props.rows,
            });
        }
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (this.props.rows && this.props.rows !== prevProps.rows) {
            if(this.props.endpoints !== undefined){
                this.loadRows();
            }else if (this.props.rows !== undefined) {
                this.setState({
                    initialRows: this.props.rows,
                    rows: this.props.rows,
                });
            }
        }
        
        if (this.props.loading !== undefined && this.props.loading !== prevProps.loading) this.setState({loading: this.props.loading});
    }

    loadRows(){
        this.setState({ loading: true });
        Http.get(this.props.endpoints).then((response) => {
            this.setState({
                loading: false, 
                initialRows: response.rows,
                rows: response.rows,
            });
        }).catch((error) => {
            this.setState({ loading: false });
            Alert.show({
                message: Http.getErrorMessage(error),
                type: "success",
            });
        })
    }

    /**
     * Bind pour éviter les rerender
     */
    stopPropagation(e) {e.stopPropagation()}

    onSelectionChange(id, checked) {
        const selection = new Set(this.state.selection);
        checked ? selection.add(id) : selection.delete(id);
        this.setState({ selection: Array.from(selection) }, () => {
            this.props?.onSelectionChange?.(this.state.selection)
        });

    }

    /**
     * @param {number} i - Indice de l'item dans la liste
     * @param {React.ChangeEvent<HTMLInputElement>} event
     * @param {boolean} checked
     */
    toggleCheckbox (id, event, checked) {
        this.setState({selectAll: false})
        this.onSelectionChange(id, checked);
    }

    bulkCheckbox() {
        if ((this.props.bulkActions && Object.keys(this.props.bulkActions).length > 0) || this.props.onSelectionChange) {
            return (
                <TableCell padding="checkbox" key={`th_-1`} className="table-cell-checkbox">
                    <div className="cell-content">
                        <Checkbox
                            indeterminate={false}
                            checked={
                                this.state.selection.length === this.props.rows.length &&
                                this.props.rows.length > 0
                            }
                            onChange={this.selectAll}
                            className="input-checkbox-table"
                        />
                    </div>
                </TableCell>
            );
        }
    }

    selectAll() {
        let selection = [];
        if (this.state.selection.length !== this.props.rows.length) {
            for (let i in this.props.rows) {
                selection.push(this.props.rows[i].id);
            }
        }
        this.setState({ selection, selectAll: true },  () => {
            this.props?.onSelectionChange?.(this.state.selection)
        });
    }

    filter(value) {
        if (value === '') {
            this.setState({ rows: this.state.initialRows });
            return;
        }
        let filtersRows = [];
        let regex = new RegExp(value, 'i');
        for (let i in this.state.initialRows) {
            for (let j in this.props.cols) {
                if (
                    regex.test(
                        this.state.initialRows[i][this.props.cols[j].field]
                    )
                ) {
                    filtersRows.push(this.state.initialRows[i]);
                    break;
                }
            }
        }
        this.setState({ rows: filtersRows });
    }

    getFilter() {
        if (this.props.filter === undefined || this.props.filter === true) {
            return (
                <FormControl className={'td_filter_control'}>
                    <TextField
                        className={'td_filter_input'}
                        placeholder="Filter"
                        onChange={(event) => this.filter(event.target.value)}
                    />
                </FormControl>
            );
        }
    }

    /**
     *
     * @param {string} id - fieldId
     * @param {*} value
     * @param {import('../../APIResource').APIResourceField['params']} params
     * @returns
     */
    applyFilter(id, value, params) {
        return new Promise((resolve, reject) => {
            try {
                let field = this.props.cols[id];
                const { activeFilters } = this.state;
                let canonicalFieldId = field.canonicalFieldName ?? id;

                if(typeof value === "string" && params && params.filterMulti){
                    value = value.split(/[\s,]+/);
                }

                if (params?.listFilterTransform) {
                    value = Array.isArray(value)
                        ? value.map((v) => params.listFilterTransform(v))
                        : params.listFilterTransform(value);
                }

                if (field.type && FieldProviderStore[field.type] && FieldProviderStore[field.type].applyFilter) {
                    FieldProviderStore[field.type].applyFilter(
                        canonicalFieldId,
                        value,
                        activeFilters,
                        (...args) => {
                            this.setState({
                                activeFilters,
                            });
                            resolve(...args);
                        }
                    );
                } else {
                    FieldProviderStore.default.applyFilter(canonicalFieldId, value, activeFilters, (...args) => {
                        this.setState({
                            activeFilters,
                        });
                        resolve(...args);
                    });
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    onFilterChange(id, value, params) {

        this.applyFilter(id, value, params).then(() => {
        });

        return;
    }

    getFilterValue(fieldDefinition) {
        const filters = this.state.activeFilters;
        let canonicalFieldId = fieldDefinition.canonicalFieldName ?? fieldDefinition.id ?? fieldDefinition.field;
        switch (fieldDefinition.type) {
            case 'date':
                return {
                    startDate: filters[`${canonicalFieldId}[after]`],
                    endDate: filters[`${canonicalFieldId}[before]`],
                };
            default:
                return filters && filters[canonicalFieldId] !== undefined ? filters[canonicalFieldId] : null;
        }
    }

    getFilterByField(fieldDefinition){
        if(!this.props.filterColumns){
            return null;
        }

        let canonicalFieldId = fieldDefinition.canonicalFieldName ?? fieldDefinition.id ?? fieldDefinition.field;

        let filterComponent = null;

        if (fieldDefinition.filter) {
            filterComponent = (
                <div className="filter-custom">
                    {fieldDefinition.filter(
                        fieldDefinition,
                        (value) => {
                            this.onFilterChange(canonicalFieldId, value, fieldDefinition.params);
                        },
                        this.getFilterValue(fieldDefinition)
                    )}
                </div>
            );
        } else {
            if (fieldDefinition.type && FieldProviderStore[fieldDefinition.type]) {
                filterComponent = (
                    <div className="filter">
                        {FieldProviderStore[fieldDefinition.type].getFilter({
                            field: fieldDefinition,
                            value: this.getFilterValue(fieldDefinition),
                            onChange: (value) => {
                                this.onFilterChange(fieldDefinition?.params?.filterPropertyName ?? canonicalFieldId, value, fieldDefinition.params);
                            },
                        })}
                    </div>
                );
            } else {
                filterComponent = (
                    <div className="filter">
                        {FieldProviderStore.default.getFilter({
                            field: fieldDefinition,
                            value: this.getFilterValue(fieldDefinition),
                            onChange: (value) => {
                                this.onFilterChange(fieldDefinition?.params?.filterPropertyName ?? canonicalFieldId, value, fieldDefinition.params);
                            }
                        })}
                    </div>
                );
            }
        }

        return filterComponent;
    }

    tHeader() {
        let ths = [];
        for (let i in this.props.cols) {

            if(!this.props.cols[i].headerStyles){
                this.props.cols[i].headerStyles = {};
            }

            ths.push(
                <TableCell key={'th_' + i} style={this.props.cols[i].headerStyles}>
                    {typeof this.props.cols[i].label === 'function'
                        ? this.props.cols[i].label()
                        : this.props.cols[i].label}
                    {this.getFilterByField(this.props.cols[i])}
                </TableCell>
            );
        }
        if (this.props.actions) {
            let actionsColIndex =
                this.props.actionsColIndex !== undefined && !isNaN(this.props.actionsColIndex)
                    ? this.props.actionsColIndex
                    : 0; 
            ths.splice(
                actionsColIndex,
                0,
                <TableCell key={'th_actions' + actionsColIndex}>
                    {this.props.actionLabel ? this.props.actionLabel : 'Actions'}
                </TableCell>
            );
        }

        return (
            <TableHead>
                <TableRow>
                    {this.bulkCheckbox()}
                    {ths}
                </TableRow>
            </TableHead>
        );
    }

    rowLink(rows, i){
        let targetResource =
            this.props.resource.operations.list.targetDetailResource ||
            this.props.resource.instanceId;
        let id = rows[i].id;
        if(!id && rows[i]['@id']){
            id = parseInt(rows[i]['@id'].split('/').pop(), 10)
        }
        Navigation.router.history.push(
            '/resource/' + targetResource + '/' + id + '/detail'
        );
    }

    tBody(rows) {

        if(this.state.loading){
            return <TableBody>
                <TableRow
                    key={"loading"}
                >
                    <TableCell
                        key={'loading_' + (typeof this.props.cols === 'object' ? Object.keys(this.props.cols).length : this.props.cols.length)}
                        colSpan={(typeof this.props.cols === 'object' ? Object.keys(this.props.cols).length : this.props.cols.length)}
                    >
                        <Box textAlign="center">
                            <CircularProgress />
                        </Box>
                    </TableCell>
                </TableRow>
            </TableBody>;
        }

        let trs = [];
        for (let i in rows) {
            let tds = [];
            const entity = rows[i];
            for (let j in this.props.cols) {
                if (!this.props.cols[j].styles) {
                    this.props.cols[j].styles = {};
                }
                let displayComponent = null;
                let fieldDefinition = this.props.cols[j];
                let field = fieldDefinition.field;

                if(entity[field] === undefined && !fieldDefinition.forceDisplay){
                    displayComponent = FieldProviderStore.default.getDisplay(
                        fieldDefinition,
                        '- Restricted field -',
                        entity,
                        {
                            plainText: true,
                            flat: true,
                            textStyle: {
                                color: 'gray',
                                fontStyle: 'italic',
                            }
                        }
                    );
                }else if (fieldDefinition.display) {
                    displayComponent = fieldDefinition.display(
                        fieldDefinition,
                        entity[field],
                        entity,
                        { fieldDefinition }
                    );
                } else if (
                    fieldDefinition.type &&
                    FieldProviderStore[fieldDefinition.type]
                ) {
                    displayComponent = FieldProviderStore[
                        fieldDefinition.type
                    ].getDisplayList(fieldDefinition, entity[field], entity, {
                        plainText: true,
                        flat: true,
                        multi: true,
                    });
                } else {
                    displayComponent = FieldProviderStore.default.getDisplayList(
                        fieldDefinition,
                        entity[field],
                        entity,
                        { plainText: true }
                    );
                }
                tds.push(
                    <TableCell
                        key={i + '-' + j}
                        style={this.props.cols[j].styles}
                        className={this.props.cols[j].className || ''}
                    >
                        <Tooltip
                            title={
                                !fieldDefinition.noTooltip
                                    ? (
                                        fieldDefinition.tooltip !== undefined ?
                                        // on se donne la possibilité d'afficher un tooltip particulier, sinon on affiche le composant
                                        // il faudrait peut-être uniformiser les appels à display, tooltip etc.
                                        fieldDefinition.tooltip(fieldDefinition, entity[field], entity) 
                                        : displayComponent
                                    )
                                    : ''
                            }
                            placement={i === 0 ? 'bottom' : 'top'}
                            arrow
                        >
                            <div
                                className={
                                    'cell-content ' + this.props.cols[j].label
                                }
                                style={
                                    Object.assign(
                                        this.props.cols[j].style ? this.props.cols[j].style : {},
                                    this.props.cols[j].width
                                        ? {
                                              width:
                                                  this.props.cols[j].width +
                                                  'px',
                                          }
                                        : {})
                                }
                            >
                                {displayComponent}
                            </div>
                        </Tooltip>
                    </TableCell>
                );
            }

            if (this.props.actions) {
                let actionsColIndex =
                    this.props.actionsColIndex !== undefined && !isNaN(this.props.actionsColIndex)
                        ? this.props.actionsColIndex
                        : 0;
            tds.splice(
                    actionsColIndex,
                    0,
                    <TableCell
                        key={i + '_actions' + actionsColIndex}
                        style={{ width: '25%' }}
                        className="td_actions"
                    >
                        {this.props.actions(rows[i])}
                    </TableCell>
                );
            }

            if ((this.props.bulkActions && Object.keys(this.props.bulkActions).length > 0) || this.props.onSelectionChange) {
                tds.unshift(<TableCell padding="checkbox" key={`${i}--1`} className="table-cell-checkbox">
                    <div className="cell-content">
                        <RowCheckbox
                            id={entity.id}
                            checked={this.state.selection.includes(entity.id)}
                            onChange={this.toggleCheckbox}
                            onClick={this.stopPropagation}
                        />
                    </div>
                </TableCell>);
            }

            trs.push(
                <TableRow
                    key={i}
                    className={this.props.selectedRow && this.props.selectedRow === rows[i].id ? 'active' : ''}
                    style={this.props.allowDoubleClick || this.props.allowClick ? {cursor: 'pointer'} : {}}
                    onDoubleClick={() => {
                        if (!this.props.resource || !this.props.resource.operations || !this.props.resource.operations.detail || !this.props.allowDoubleClick) {
                            return;
                        }
                        this.rowLink(rows, i);
                    }}
                    onClick={() => {
                        if (!this.props.resource || !this.props.resource.operations || !this.props.resource.operations.detail || !this.props.allowClick) {
                            return;
                        }
                        this.rowLink(rows, i);
                    }}
                >
                    {tds}
                </TableRow>
            );
        }

        return <TableBody>{trs}</TableBody>;
    }

    bulkActions = () => {
        let self = this;

        if (this.state.selection.length === 0) {
            return null;
        }

        let genActions = function () {
            let actions = [];
            for (let i in self.props.bulkActions) {
                let actionLabel = null;
                let withIcon = false;
                if (self.props.bulkActions[i].parameters?.icon) {
                    if (Array.isArray(self.props.bulkActions[i].parameters.icon)) {
                        actionLabel =
                            <i className={`${self.props.bulkActions[i].parameters.icon[0]} fa-${self.props.bulkActions[i].parameters.icon[1]}`}></i>;
                    } else {
                        actionLabel =
                            <i className={`fa fa-${self.props.bulkActions[i].parameters.icon}`}></i>;
                    }
                    withIcon = true;
                } else {
                    actionLabel = self.props.bulkActions[i].getLabel();
                }
                if (actionLabel) {
                    if (withIcon) {
                        actions.push(
                            <Tooltip
                                title={self.props.bulkActions[i].getLabel()}
                                placement="top-start"
                                arrow
                                key={i}
                                className={'withIcon'}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleChange(self, self.props.bulkActions[i].getId())}
                                >
                                    {actionLabel}
                                </Button>
                            </Tooltip>
                        );
                    } else {
                        actions.push(
                            <Button
                                variant="contained"
                                color="primary"
                                className="button-general"
                                key={i}
                                onClick={() => handleChange(self, self.props.bulkActions[i].getId())}
                            >
                                {actionLabel}
                            </Button>
                        );
                    }
                }
            }
            return actions;
        };

        let handleChange = function (dataTable, value) {
            if (value !== -1) {
                for (let i in dataTable.props.bulkActions) {
                    if (dataTable.props.bulkActions[i].getId() === value) {
                        dataTable.props.bulkActions[i].run(dataTable.state.selection, dataTable, {selectAll: dataTable.state.selectAll});
                        dataTable.setState({ selection: [], selectAll: false });
                        return;
                    }
                }
            }
        };

        if (Object.keys(this.props.bulkActions).length > 0) {
            return genActions();
        }
    };

    buttons() {
        if (this.props.buttons === undefined) {
            return null;
        }

        let buttons = [];
        for (let i in this.props.buttons) {
            const buttonProps = this.props.buttons[i];
            if(buttonProps !== null) {
                if (buttonProps.inModal) {
                    buttons.push(
                        <OptionalTooltip key={'tooltip_' + (i || '')} title={buttonProps.tooltip} arrow placement="top">
                            <OpenModal
                                key={'td_button_' + i}
                                {...buttonProps.modalProps}
                                modalTitle={buttonProps.label}
                                preload={true}
                                button={
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="button-general"
                                        disabled={buttonProps.disabled}
                                    >
                                        {buttonProps.icon ? (
                                            <Icon
                                                className={
                                                    'fa ' + buttonProps.icon
                                                }
                                            ></Icon>
                                        ) : null}
                                        {buttonProps.label}
                                    </Button>
                                }
                            />
                        </OptionalTooltip>
                    );
                } else if (buttonProps.to) {
                    buttons.push(
                        <OptionalTooltip key={'tooltip_' + (i || '')} title={buttonProps.tooltip} arrow placement="top">
                            <Link
                                key={'td_button_' + i}
                                to={buttonProps.to}
                                style={styles.actionLink}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="button-general"
                                    disabled={buttonProps.disabled}
                                >
                                    {buttonProps.icon ? (
                                        <Icon
                                            className={
                                                'fa ' + buttonProps.icon
                                            }
                                        ></Icon>
                                    ) : null}
                                    {buttonProps.label}
                                </Button>
                            </Link>
                        </OptionalTooltip>
                    );
                } else if (buttonProps.onClick) {

                    buttons.push(
                        <OptionalTooltip key={'tooltip_' + (i || '')} title={buttonProps.tooltip} arrow placement="top">
                            <Button
                                key={'td_button_' + i}
                                style={{zIndex: 0}}
                                disabled={buttonProps.disabled}
                                variant="contained"
                                color="primary"
                                className="button-general"
                                onClick={() =>
                                    buttonProps.onClick(buttonProps)
                                }
                            >
                                {buttonProps.icon ? (
                                    <Icon
                                        className={'fa ' + buttonProps.icon}
                                    ></Icon>
                                ) : null}
                                {buttonProps.label}
                            </Button>
                        </OptionalTooltip>
                    );
                } else {
                    //buttonProps is a component
                    buttons.push(buttonProps)
                }
            }
        }

        let bulkActions = this.bulkActions();
        if(bulkActions)
            buttons.push(bulkActions);

        return (
            <Grid item xs={12}>
                <Paper>{buttons}</Paper>
            </Grid>
        );
    }

    render() {
        return (
            <div>
                {this.props.label ? <label>{this.props.label}</label> : null}
                <Grid
                    container
                    spacing={2}
                    className={
                        this.props.className + ' ' +
                        (this.props.buttons === undefined
                            ? 'container'
                            : 'container detail-inner-new-button')
                    }
                >
                    {this.state.selection.length > 0 ? this.buttons(true) : this.buttons(false)}
                    {
                        !this.props.hideIfEmpty
                        || (
                            this.props.hideIfEmpty
                            && this.state.rows
                            && this.state.rows.length > 0
                        ) ?
                            <Grid item xs={12}>
                                {this.getFilter()}
                                <Paper className="container-no-padding table-scroll">
                                    <Table
                                        className={
                                            `${this.props.tableClassName} table-display-component`
                                        }
                                        size={this.props.dense ? 'small' : 'medium'}
                                    >
                                        {this.tHeader()}
                                        {this.tBody(this.state.rows)}
                                    </Table>
                                </Paper>
                            </Grid>
                        : null
                    }
                </Grid>
            </div>
        );
    }
}
TableDisplay.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    tableClassName: PropTypes.string,
    /** Pour récupérer les valeurs si besoin */
    endpoints: PropTypes.string,
    resource: PropTypes.object,
    filter: PropTypes.bool,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.any,
            icon: PropTypes.string,
            label: PropTypes.string,
            onClick: PropTypes.func,
            disabled: PropTypes.bool,
            /** si présent on affiche un tooltip sur le bouton */
            tooltip: PropTypes.string,
        })
    ),
    actions: PropTypes.any,
    actionLabel: PropTypes.string,
    /** index de la colonne Actions, dernière colonne si vide */
    actionsColIndex: PropTypes.number,
    /** @todo array of "fieldDefinition" à préciser */
    cols: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    rows: PropTypes.array,
    /** id de la ligne sélectionnée */
    selectedRow: PropTypes.any,
    /** callback appelé à chaque changement de selection, reçoit toute la sélection */
    onSelectionChange: PropTypes.func,
    hideIfEmpty: PropTypes.bool,
    dense: PropTypes.bool,
    loading: PropTypes.bool,
};

const styles = {
    actionLink: {
        marginLeft: 5,
        zIndex: 0,
    },
};
