import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Http from "../../Services/Http";
import Alert from "../../Services/Alert";
import EntityProvider from "../../Services/APIResource/FieldProviders/EntityProvider";
import ParameterStore, {userHasRoleMRM, userHasValidatorRights} from "../../Store/ParameterStore";
import User from "../../Services/User/User";
import APIResourceStore from "../../Store/APIResourceStore";
import Tooltip from '@material-ui/core/Tooltip';
import Modal from "../../Services/Modal";
import { ConfirmModal } from "../Modal/ConfirmModal";
import {FINDING_STATUS_DRAFT, FINDING_STATUS_DELETED} from "../../Admin/FindingAdmin";
import {REVIEW_STATUS_ASSESSMENT} from "../../Admin/ReviewAdmin";

const ReviewIssueRelation = (props) => {

    const { review, model, entity, value, fieldDefinition, type, resource, resourcePath, params, inModal } = props;
    const { resourceDetailComponent } = props;
    const [processing, setProcessing] = useState(false);
    const [valuesListComponents, setValuesListComponents] = useState([]);

    useEffect(() => {
        listEntities(value);
    
        return () => {
            /** @todo cancel relationEntities promises */ 
        }
    }, [value])

    const removeAssociation = (findingPath, noticePath) => {
        setProcessing(true)
        Http
            .post(
                'reviews/disassociate-finding-notice',
                {findingPath: findingPath, noticePath: noticePath}
            )
            .then((_res) => {
                Alert.show({
                    message: 'Your findings and notices are successfully dissociated.',
                    type: 'success',
                })
                setProcessing(false)
                let parentEntityId = null;
                if(review !== undefined){
                    parentEntityId = review.id
                }
                if(model !== undefined){
                    parentEntityId = model.id
                }
                if(parentEntityId !== null){
                    resource.getItemFromResourcePath(`/api/${resourcePath}/${parentEntityId}`, true).then((parentEntity) => { 
                        resourceDetailComponent.entity = parentEntity;
                        resourceDetailComponent.forceUpdate();
                    });
                }
            })
            .catch((_err) => {
                setProcessing(false)
            })
        ;
    }

    const listEntities = async (entities) => {
        let list = [];
        let paths = [...entities];
        const relationEntities = await Promise.all(paths.map(path => APIResourceStore.resources['resource_' + type + 's_reviews'].getItemFromResourcePath(path)));
        relationEntities.forEach(
            (relationEntity, index) => {
                if (
                    (
                        type === 'finding'
                        && (
                            (relationEntity.status === ParameterStore(FINDING_STATUS_DRAFT) && (User.profile.isMemberOfValidatorTeam || userHasRoleMRM()))
                            || relationEntity.status !== ParameterStore(FINDING_STATUS_DRAFT)
                        )
                        && (
                            relationEntity.status !== ParameterStore(FINDING_STATUS_DELETED)
                        )
                    )
                    || (
                        type === 'notice'
                        && (
                            (relationEntity.status === ParameterStore('NOTICE_STATUS_DRAFT') && (User.profile.isMemberOfValidatorTeam || userHasRoleMRM()))
                            || relationEntity.status !== ParameterStore('NOTICE_STATUS_DRAFT')
                        )
                    )
                ) {
                    list.push(
                        <Tooltip
                            title={(type === 'notice' && relationEntity.notice_description) || (type === 'finding' && relationEntity.weaknesses_description) || ''}
                            arrow
                            key={`tooltip-associate-${index}`}
                        >
                            <div
                                key={'associate-remove-' + type + '-' + index}
                            >
                                {EntityProvider.getDisplay(fieldDefinition, relationEntity['@id'], entity, {flat: true, showLoading: true, modalLinks: inModal})}
                                { (userHasValidatorRights(User.getId(), review ?? model) || userHasRoleMRM())
                                && (
                                    (
                                        review
                                        && review.reviewStatus === ParameterStore(REVIEW_STATUS_ASSESSMENT)
                                    ) || model
                                )
                                    ?
                                    <Button
                                        color="secondary"
                                        className={"tooltip"}
                                        onClick={() =>
                                            Modal.open({
                                                title: "Confirmation of dissociation",
                                                content: (
                                                    <ConfirmModal
                                                        button={{confirm: 'Confirm the dissociation'}}
                                                        callback={() => type === 'finding' ?
                                                            removeAssociation(relationEntity['@id'], 'api/notices/' + entity.id)
                                                            : removeAssociation('api/findings/' + entity.id, relationEntity['@id'])}
                                                    />
                                                ),
                                                size: "medium",
                                            })
                                        }
                                        disabled={processing}
                                    >
                                        <i className="fa fa-unlink"></i>
                                        <span className="tooltiptext">Dissociate</span>
                                    </Button>
                                    : null }
                            </div>
                        </Tooltip>
                    )
                }
            }
        );
        setValuesListComponents(list);
    }

    return (
        <Grid container spacing={2} className="container">
            <Grid item xs={12}>
                <>{valuesListComponents.length ? valuesListComponents : '-'}</>
            </Grid>
        </Grid>
    );
}

export default ReviewIssueRelation;