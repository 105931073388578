import React, { PureComponent } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import ObliqueAaxisTick from "../ObliqueAaxisTick";

const COLORS = ['#00838A','#F6A800', '#BDCF41',  '#E74D5D'];

export default class ModelByMraStatusBetweenDatesChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data : []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.data && (this.props.data !== prevProps.data)){
            this.setState({data: this.props.data})
        }
    }

    render() {
        return (
            <ResponsiveContainer>
                <LineChart
                    width={800}
                    height={350}
                    data={this.state.data}
                    margin={{
                        top: 5, right: 0, left: 0, bottom: 75,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" tick={<ObliqueAaxisTick />} />
                    <YAxis />
                    <Tooltip />
                    <Legend wrapperStyle={{ fontSize: 16, top : -25 }} verticalAlign="top" height={40} />
                    <Line type="monotone" dataKey="Validated" stroke={COLORS[0]} activeDot={{ r: 6 }} />
                    <Line type="monotone" dataKey="From LoD1" stroke={COLORS[1]} activeDot={{ r: 6 }} />
                    <Line type="monotone" dataKey="Modified by LoD2" stroke={COLORS[2]} activeDot={{ r: 6 }}/>
                    <Line type="monotone" dataKey="Reviewed by LoD1" stroke={COLORS[3]} activeDot={{ r: 6 }}/>
                </LineChart>
            </ResponsiveContainer>
        )
    }
}