import Axios from 'axios';
import App from '../Config/App';
import Alert from './Alert';
import DateFormatter from "./DateFormatter";
import String from "./String";
import APIResourceStore from "../Store/APIResourceStore";
import User from './User/User';

/**
 * @returns {Array<string>} - Liste éventuelle des properties demandées au back
 */
const extractProperties = (path) => {
    /** @type {{entries: () => Array<[string, string]>}} */
    const searchParams = new URLSearchParams(path);
    return Array.from(searchParams.entries()).filter(([k, _v]) => k.endsWith('properties[]')).map(([_k, v]) => v);
}

/**
 * Renvoie le path expurgé des properties.
 * Attention, il contient les autres arguments du GET, ce n'est pas le root path.
 *
 * @param {string} path
 * @returns {string}
 */
const getShortPath = (path) => path.replace(/&?properties\[\]=[^&]+/g, '');

class Http {
    requestConfig = {
        headers: {}
    };

    cache = {};
    promiseCaches = {};
    defaultCache = App.httpDefaultCacheTimeout ?? 1000;

    setJWT(jwt) {
        this.requestConfig.headers['Authorization'] = 'Bearer ' + jwt;
    }

    /**
     * HTTP GET method
     * @param {string} path
     * @param {Object} options
     * @param {{token: any}} [options.request]
     * @param {boolean|number|undefined} [options.cache] - Cache peut contenir :
     *  - {..., } cache est undefined, le cache est appelé (valeur par défaut)
     *  - {..., cache: false} pour forcer l'appel à API et ne pas mettre en cache
     *  - {..., cache: n} où n est la valeur en s durant laquelle le résultat de l'appel sera mis en cache
     *  - {..., cache: true} pour mettre en cache l'appel API et utiliser la valeur de mise en cache par défaut
     */
    get(path, options) {
        let self = this;
        const date = new Date();
        const cacheTimeout =
            !options ||
            options.cache === undefined
                ? self.defaultCache
                : options.cache === true
                    ? self.defaultCache
                    : 1000 * options.cache;

        const properties = extractProperties(path);
        const shortPath = getShortPath(path);

        const existingCache = this.getExistingCache(path, properties, shortPath);
        // bugfix, pour beneficier du cache la valeur de cache doit explicitement etre définie undefined est une valeur qui faisait eviter le cache
        // j'ai fix ici, mais sinon il faudrait fix dans tous les appels au cache.
        if (
            (!options || (options && (options.cache === undefined || options.cache)))
            && existingCache
            && date.getTime() < existingCache.expires
        ) {
            if ((path.match(/dimensions/) || []).length > 0)
                console.log("[Http.get] utilise cache dimension pour :", path, ", result :", existingCache.promise);
            return existingCache.promise
        } else {
            if ((path.match(/dimensions/) || []).length > 0)
                console.log("[Http.get] KO n'utilise pas le cache dimension pour :", path, ", cache existe ?", false || existingCache, ", expiration :", existingCache?.expires, ", now :", date.getTime());
            const promise = new Promise((resolve, reject) => {
                let config = JSON.parse(JSON.stringify(this.requestConfig));
                if (options && options.request) {
                    config.cancelToken = options.request.token
                }
                Axios.get(App.api + '/' + path, config)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(function (error) {
                        if (self.handleCancellation(error)) {
                            delete self.cache[path];
                            return;
                        }
                        ;
                        if (!self.handle401(error) && error.message !== 'NoAlert') {
                            Alert.show({message: self.getErrorMessage(error), type: 'error'});
                        }
                        delete self.cache[path];
                        reject(error);
                    })
                    .finally(function () {
                        if (!cacheTimeout) {
                            delete self.cache[path];
                        }
                    });
            });
            self.cache[path] = {
                expires: date.getTime() + cacheTimeout,
                promise,
                shortPath,
                properties,
            };
            return promise;
        }
    }

    /**
     * HTTP POST method
     * @param path
     * @param object
     * @param {Object} requestConfig - request options
     */
    post(path, object, requestConfig = {}) {
        let self = this;
        return new Promise((resolve, reject) => {
            object = this.removeTimeZone(object);
            Axios.post(App.api + '/' + path, object, this.genRequestConfig(requestConfig))
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if (self.handleCancellation(error)) return;
                    self.handle401(error);
                    Alert.show({message: self.getErrorMessage(error), type: 'error'});
                    reject(error);
                })
        });
    }

    /**
     * HTTP POST with multipart/form-data
     * @param path
     * @param datas
     *
     * @description datas : {
     *     file : File()
     *     params1: value1,
     *     params2: value2,
     *     ...
     * }
     *
     */
    postFile(path, datas = {}) {
        let self = this;
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            for (let i in datas) {
                if (datas[i] && typeof datas[i] === 'object' && datas[i].constructor !== File) {
                    formData.append(i, JSON.stringify(datas[i]));
                } else {
                    formData.append(i, datas[i]);
                }
            }
            let requestConfig = JSON.parse(JSON.stringify(this.requestConfig));
            requestConfig.headers['Content-Type'] = 'multipart/form-data';
            Axios.post(App.api + '/' + path, formData, requestConfig)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    self.handle401(error);
                    Alert.show({message: self.getErrorMessage(error), type: 'error'});
                    reject(error);
                })
        });

    }

    openFile(path, fileName) {
        let self = this;
        let requestConfig = JSON.parse(JSON.stringify(this.requestConfig));
        requestConfig.responseType = 'blob';
        return Axios.get(App.api + path, requestConfig)
            .then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                //document.body.appendChild(link);
                link.click();
            })
            .catch(function (error) {
                self.handle401(error);
                Alert.show({message: self.getErrorMessage(error), type: 'error'});
            });
    }

    openEncryptedFile(path, fileName) {
        let self = this;
        if (self.decryptingSession && self.decryptingSession === path + fileName) {
            return;
        }
        self.decryptingSession = path + fileName;
        let requestConfig = JSON.parse(JSON.stringify(this.requestConfig));
        requestConfig.responseType = 'blob';

        let decryptionSessionToken = String.random(20);

        window.open(App.backend + path + '/' + decryptionSessionToken + '?t=' + User.jwt, "_blank")

        //Axios.get(App.api + path + '/' + decryptionSessionToken, requestConfig)
        //    .then(function (response) {
        //        const url = window.URL.createObjectURL(new Blob([response.data]));
        //        const link = document.createElement('a');
        //        link.href = url;
        //        link.setAttribute('download', fileName.substring(14));
        //        //document.body.appendChild(link);
        //        link.click();
        //        delete self.decryptingSession;
        //    })
        //    .catch(function (error) {
        //        self.handle401(error);
        //        Alert.show({ message: self.getErrorMessage(error), type: 'error' });
        //        delete self.decryptingSession;
        //    });

        setTimeout(() => {
            let formData = new FormData();
            formData.append('env', App.env);
            formData.append('sessionToken', decryptionSessionToken);
            Axios.post(App.kms, formData, requestConfig)
                .then(function (response) {
                    delete self.decryptingSession;
                })
                .catch(function (error) {
                    self.handle401(error);
                    Alert.show({message: self.getErrorMessage(error), type: 'error'});
                    delete self.decryptingSession;
                })
        }, 4000);
    }

    openEncryptedFiles(documentIds) {
        let self = this;

        let firstDocument = APIResourceStore.resources.documents.getItem(documentIds[0]);
        let path = "/documents/download";
        let fileName = firstDocument.documentName;

        if (self.decryptingSession && self.decryptingSession === path + fileName + firstDocument.id) {
            return;
        }
        self.decryptingSession = path + fileName + firstDocument.id;
        let requestConfig = JSON.parse(JSON.stringify(this.requestConfig));
        requestConfig.responseType = 'blob';

        let decryptionSessionToken = String.random(20);

        Axios.post(App.api + path + '/' + decryptionSessionToken, {ids: documentIds}, requestConfig)
            .then(function (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'documents.zip');
                //document.body.appendChild(link);
                link.click();
                delete self.decryptingSession;
            })
            .catch(function (error) {
                self.handle401(error);
                Alert.show({message: self.getErrorMessage(error), type: 'error'});
                delete self.decryptingSession;
            });

        setTimeout(() => {
            let formData = new FormData();
            formData.append('env', App.env);
            formData.append('sessionToken', decryptionSessionToken);
            Axios.post(App.kms, formData, requestConfig)
                .then(function (response) {
                })
                .catch(function (error) {
                    self.handle401(error);
                    Alert.show({message: self.getErrorMessage(error), type: 'error'});
                })
        }, 4000);
    }

    genRequestConfig(requestConfig) {
        return {
            ...this.requestConfig,
            ...requestConfig,
            ...(requestConfig.request ? {cancelToken: requestConfig.request.token} : {}),
            ...(requestConfig.token ? {cancelToken: requestConfig.token} : {}),
        };
    }

    /**
     * HTTP PUT method
     * @param path
     * @param object
     */
    put(path, object, requestConfig = {}) {
        let self = this;
        return new Promise((resolve, reject) => {
            object = this.removeTimeZone(object);
            Axios.put(App.api + '/' + path, object, this.genRequestConfig(requestConfig))
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if (self.handleCancellation(error)) return;
                    self.handle401(error);
                    Alert.show({message: self.getErrorMessage(error), type: 'error'});
                    reject(error);
                })
        });
    }

    delete(path, requestConfig = {}) {
        let self = this;
        return new Promise((resolve, reject) => {
            Axios.delete(App.api + '/' + path, this.genRequestConfig(requestConfig))
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if (self.handleCancellation(error)) return;
                    self.handle401(error);
                    Alert.show({message: self.getErrorMessage(error), type: 'error'});
                    reject(error);
                })
        });

    }

    handleCancellation(error) {
        if (this.isCancel(error)) {
            console.log('Request cancelled', error.message);
            return true;
        }
        return false;
    }

    handle401(error) {
        if (error.response && error.response.data && error.response.data.code && (error.response.data.code === 401 || error.response.data.code === 403)) {
            window.location = App.backend + '/auth/login';
            return true;
        }
        return false;
    }

    getErrorMessage(error) {
        if (error.response && error.response.data && error.response.data['hydra:description']) {
            return (error.response.data['hydra:title'] || 'Error') + ': ' + error.response.data['hydra:description'];
        }
        if (error.response && error.response.data && error.response.data['detail']) {
            return 'Error: ' + error.response.data['detail'];
        }
        return error.message || 'Woops! Something went awry…'
    }

    //Axios convert date to local, passing date in string fix this behavior
    removeTimeZone(object) {
        for (let key in object) {
            if (object.hasOwnProperty(key) && object[key] instanceof Date) {
                object[key] = DateFormatter.dateToIso8601Full(object[key]);
            }
        }
        return object;
    }

    getRequest() {
        return Axios.CancelToken.source();
    }

    isCancel(thrown) {
        return Axios.isCancel(thrown);
    }

    /**
     *
     * @param {string} path
     * @param {*} properties
     * @param {*} shortPath
     * @returns
     */
    getExistingCache(path, properties, shortPath) {
        if (path && (path.match(/dimensions/) || []).length > 0)
            console.log("appel getExistingCache(", path, ") -> ", this.cache[path]);

        return this.cache[path];

        /** @todo à tester, version du cache un peu améliorée */
        const cache = (
            Object.values(this.cache).filter(
                (c) =>
                    c.shortPath === shortPath &&
                    (properties.every((p) => c.properties.includes(p)) || c.properties.length === 0) &&
                    new Date().getTime() < c.expires
            )?.[0] || this.cache[path]
        );
        console.log("getExistingCache",
            properties,
            shortPath,
            cache ?? "pas de cache trouvé,"
        );
        return cache;
    }

    /**
     * Remplit le cache sans passer par une requête get.
     *
     * Sert surtout à simuler le retour et la mise en cache des appels au back lors du preload.
     * @todo pas activé en prod encore
     */
    cacheWarmUp(endpoint, data, properties = []) {
        this.cache[endpoint] = {
            expires: (new Date()).getTime() + 5 * this.defaultCache, // on le fait durer un peu plus longtemps que les autres caches
            promise: new Promise((resolve) => resolve(data)),
            properties,
            shortPath: getShortPath(endpoint),/** @todo gérer les page= et perPage ou on se contente de les garder dans le shortPath */
        };
    }
}

export default new Http();
