import React, {Component} from "react";
import {ActionButton} from "../../Modal/ActionButton";
import Modal from "../../../Services/Modal";
import {ButtonBar} from "../../Modal/ButtonBar";
import Navigation from "../../../Services/Navigation";
import {Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {HIGHLIGHT_SEPARATOR} from "../../../Admin/common";

const EntityErrorModal = (props) => {
    const { entityId, resource, resourceComponent, errors } = props;

    let goTo = function(path, operation, tabName, hightlight) {
        Modal.close();
        path += `/${operation}?tab=${tabName}`;
        if(hightlight){
            path += `&highlight=${hightlight}`;
        }
        if(resourceComponent.getComponentName() === 'ResourceEdit'){
            Navigation.router.history.push('/blank');
            setTimeout(() => {
                Navigation.router.history.push(path);
                resourceComponent.setState({currentTab: resource.getTabId(operation, tabName)})
            },10)
        }else{
            Navigation.router.history.push(path);
            resourceComponent.setState({currentTab: resource.getTabId(operation, tabName)})
        }

    }

    let renderErrors = function(){
        let layout = resource.genErrorLayout();
        let tabs = [];
        let viewForTab = [];
        let hightlight = [];
        for (let tab in layout) {
            for(let panel in layout[tab]){
                for(let field in layout[tab][panel]){
                    for(let item in errors) {
                        if (
                            errors[item].fieldName === layout[tab][panel][field]
                            || (
                                errors[item].fieldId === layout[tab][panel][field]
                                && typeof resource.fields[errors[item].fieldId].edit === 'function'
                                && resource.fields[errors[item].fieldId].edit(resource.fields[errors[item].fieldId]) === null
                            )
                        ) {
                            if(!tabs[tab]){
                                tabs[tab] = [];
                                viewForTab[tab] = 'detail';
                            }
                            if(!tabs[tab][panel]){
                                tabs[tab][panel] = [];
                            }
                            tabs[tab][panel].push(errors[item].field + ': ' + errors[item].detail);
                        }else if (
                            errors[item].field.charAt(0) .toLowerCase() + errors[item].field.substring(1) === layout[tab][panel][field]
                        ) {
                            if(!tabs[tab]){
                                tabs[tab] = [];
                                viewForTab[tab] = 'edit';
                                hightlight[tab] = [];
                            }
                            if(!tabs[tab][panel]){
                                tabs[tab][panel] = [];
                            }
                            tabs[tab][panel].push(errors[item].field);
                            hightlight[tab].push(errors[item].field.charAt(0) .toLowerCase() + errors[item].field.substring(1));
                        }else if (
                            errors[item].fieldId === layout[tab][panel][field]
                        ) {
                            if(!tabs[tab]){
                                tabs[tab] = [];
                                viewForTab[tab] = 'edit';
                                hightlight[tab] = [];
                            }
                            if(!tabs[tab][panel]){
                                tabs[tab][panel] = [];
                            }
                            tabs[tab][panel].push(errors[item].field);
                            hightlight[tab].push(errors[item].fieldId);
                        }
                    }
                }
            }
        }
        let list = [];
        for(let tab in tabs){
            let renderPanels = [];
            for(let panel in tabs[tab]){
                let renderFields = [];
                for(let field in tabs[tab][panel]){
                    renderFields.push(<li key={`render-field-${field}`}>{tabs[tab][panel][field]}</li>);
                }
                renderPanels.push(<div key={`panel_${panel}`}>
                    {panel != tab ?
                    <Typography variant="h6">
                        {panel}
                    </Typography>
                    : null }
                    <ul>{renderFields}</ul>
                </div>)
            }
            list.push(
                <div>
                    <Typography variant="h5">
                        {tab}
                        <Button key={`edit-${tab}`} variant="contained" className="button-edit warning" onClick={() => {
                            let path = `/resource/${resource.instanceId}/${entityId}`;
                            if(hightlight && hightlight[tab]){
                                goTo(path, viewForTab[tab], tab, hightlight[tab].join(HIGHLIGHT_SEPARATOR));
                            }else{
                                goTo(path, viewForTab[tab], tab);
                            }

                        }}>
                            <i className="fa fa-edit"></i>
                        </Button>
                    </Typography>
                    {renderPanels}
                </div>
            );
        }
        return list;
    }

    return (
        <div className={"entity-error-modal"}>
            {renderErrors()}
            <ButtonBar>
                <ActionButton
                    onClick={Modal.close}
                >
                    Cancel
                </ActionButton>
            </ButtonBar>
        </div>
    );
};
export default EntityErrorModal;