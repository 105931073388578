import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Modal from "../../Services/Modal";
import { LogicalDeleteForm } from "../Forms/LogicalDeleteForm/LogicalDeleteForm";

export class LogicalDeleteButton extends Component {
    constructor(props) {
        super(props);
    }

    openDeleteForm(entity, resource) {
        Modal.open({
            title: "Deletion confirmation",
            content: <LogicalDeleteForm {...this.props} entity={entity} resource={resource} />
        });
    }

    render() {
        return (
            <Button
                key={"action_delete_" + this.props.entity.id}
                variant="contained"
                color="secondary"
                style={styles.actionLink}
                className={
                    "trash item button-table button-logical-delete button-revert tooltip " +
                    this.props.className
                }
                onClick={() => {
                    if (this.props.onPreValidate) {
                        this.props.onPreValidate(() => this.openDeleteForm(this.props.entity, this.props.resource))
                    }
                    else {
                        this.openDeleteForm(this.props.entity, this.props.resource)
                    }
                }}
            >
                <i className="fa fa-trash-alt"></i>
                <span className="tooltiptext">Delete</span>
            </Button>
        );
    }
}

let styles = {
    actionLink: {
        marginLeft: 5
    }
};
