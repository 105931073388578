import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export const Header = (props) =>
    (props.text || props.children) && (
        <Typography {...props} component="div" className={'header-component'}>
            {props.text}
            {props.children}
        </Typography>
    );
Header.propTypes = {
    ...Typography.propTypes,
    /** Message à afficher, en plus des éventuels children. */
    text: PropTypes.string,
};
