import React, { Component } from "react";
import Navigation from "../../../Services/Navigation";
import Modal from "../../../Services/Modal";
import Button from "@material-ui/core/Button";
import { ButtonColumn } from "../../Modal/ButtonColumn";

class ModalDeclareModel extends Component {
    render() {
        return (
            <div className={"declare-modal"}>
                <ButtonColumn>
                    <Button
                        style={{ marginTop: 10 }}
                        variant="contained"
                        color="secondary"
                        className="save button-general"
                        onClick={() => {
                            Modal.close();
                            Navigation.router.history.push(
                                "/resource/models/add"
                            );
                        }}
                    >
                        Quick declaration
                    </Button>
                    <Button
                        style={{ marginTop: 10 }}
                        variant="contained"
                        color="secondary"
                        className="save button-general"
                        onClick={() => {
                            Modal.close();
                            Navigation.router.history.push(
                                "/resource/models/test"
                            );
                        }}
                    >
                        Declaration with identification tree
                    </Button>
                </ButtonColumn>
            </div>
        );
    }
}

export default ModalDeclareModel;
