import RouteStore, {Route} from "../../Store/RouteStore";
import React from "react";
import Add from "./Add";

RouteStore.routes.resource_models_new = new Route({
    path: '/resource/models/test',
    component: (props) => <Add {...props}/>,
    menuItem: {
        title: 'New Model',
        icon: 'brain'
    },
});