import { decorate, observable } from 'mobx/lib/mobx';

class RouteStore {
    routes = {};
}

decorate(RouteStore, {
    routes: observable,
});

/**
 * @class
 * @typedef {Object} RouteParams
 * @property {string} path
 * @property {} component
 * @property {} children default {}
 * @property {boolean} active default false
 * @property {} menuItem default null ! attention non utilisé !! devrait servir à construire le menu
 * @property {array|function} accessControl une array de rôles autorisés, ou une fonction qui renvoie true si l'accès est autorisé.
 *      Cette fonction reçoit pour l'instant uniquement un objet vide.
 *      voir src/Components/Root/Root.js
 */
export class Route {
    /** 
     * @constructor 
     * @param {RouteParams} params
     */
    constructor(params) {
        this.path = params.path;
        this.component = params.component;
        this.children = params.children ? params.children : {};
        this.active = params.active ? params.active : false;
        this.menuItem = params.menuItem ? params.menuItem : null;
        this.accessControl = params.accessControl || null;
    }
}
decorate(Route, {
    active: observable,
    menuItem: observable,
});

export default new RouteStore();
