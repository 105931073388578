import React, { useState } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Modal from '../../Services/Modal';
import Alert from '../../Services/Alert';
import Http from '../../Services/Http';
import { ButtonBar } from '../Modal/ButtonBar';
import String from '../../Services/String';
import { ActionButton } from '../Modal/ActionButton';
import { ModalContent } from '../Modal/ModalContent';

/**
 * @param {Object} props
 * @param {{id: string}} props.entity - Entité à cloner
 * @param {import('../../Services/APIResource/APIResource').APIResource} props.resource - Resource de l'entité source
 * @param {string} props.message - Message à afficher dans la Modal
 * @param {string} props.errorMessage - Message affiché en cas d'erreur
 * @param {(clonedEntity) => string} props.successMessage - Callback qui renvoie le message affiché en cas de réussite
 */
export const CloneModal = (props) => {
    const { entity, resource, errorMessage, successMessage } = props;
    const [apiCloneInProgress, setApiCloneInProgress] = useState(false);
    const history = useHistory();

    const message =
        props.message ||
        `You are about to clone this resource by duplicating all the data. 
        Do you want to continue ?
        In order to simply update the data of this resource, please use the below "Edit" yellow button.`;

    const handleCloneClick = async () => {
        setApiCloneInProgress(true);

        try {
            const clonedEntity = await Http.get(`${resource.resourceId}/${entity.id}/clone`, entity);
            /** On insère la nouvelle entité directement dans APIResource */
            resource.setItem(clonedEntity);
            setApiCloneInProgress(false);
            history.push(`/resource/${resource.instanceId}/${clonedEntity.id}/edit`);
            Alert.show({
                message: successMessage ? successMessage(clonedEntity) : 'Your new resource has been declared.',
                type: 'success',
            });
            Modal.close();
        } catch (error) {
            setApiCloneInProgress(false);
            Alert.show({
                message: `${errorMessage || 'Your resource cannot be cloned.'}\n${Http.getErrorMessage(error)}`,
                type: 'error',
            });
        }
    };

    return (
        <ModalContent>
            <Typography style={{ textAlign: 'center' }}>{String.nlToBr(message)}</Typography>
            <ButtonBar>
                <ActionButton onClick={Modal.close} disabled={apiCloneInProgress}>
                    Cancel
                </ActionButton>
                <ActionButton onClick={handleCloneClick} loading={apiCloneInProgress}>
                    Confirm
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
};
CloneModal.propTypes = {
    entity: PropTypes.object.isRequired,
    /** @todo object of resource ?? */
    resource: PropTypes.object.isRequired,
    message: PropTypes.string,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.func,
};
