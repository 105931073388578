import React from 'react';
import PropTypes from 'prop-types';
import { CloneModal } from '../../CloneModal/CloneModal';
import { userHasRoleMRM } from '../../../Store/ParameterStore';

export const CloneModelModal = (props) => {
    const { entity } = props;
    const message = `You are about to create a new model sheet by duplicating all the data from ${entity.functionalID} and initiate a declaration process. 
        Do you want to continue ?
        In order to simply update the data of this model, please use the below "Edit" yellow button.`;
    const errorMessage = 'Your model cannot be cloned.';
    const successMessage = (clonedEntity) =>
        userHasRoleMRM()
            ? `Your new model ${clonedEntity.functionalID} (cloned from ${entity.functionalID}) has been declared.`
            : `Your new model ${clonedEntity.functionalID} (cloned from ${entity.functionalID}) has been declared as a ${clonedEntity.modelStatusSimplified} model.`;

    return <CloneModal {...props} message={message} errorMessage={errorMessage} successMessage={successMessage} />;
};
CloneModelModal.propTypes = {
    entity: PropTypes.object.isRequired,
    /** @todo object of resource ?? */
    resource: PropTypes.object.isRequired,
};
