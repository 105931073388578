import React from 'react';
import PropTypes from 'prop-types';
import Http from '../../Http';
import Alert from '../../Alert';
import Modal from '../../Modal';
import { ModalContent } from '../../../Components/Modal/ModalContent';
import { openLoadingModal, validateIds } from '../bulkUtils';
import ParameterStore, { userHasRoleMRM } from '../../../Store/ParameterStore';
import RetireModelForm from '../../../Components/Model/Retire/RetireModelForm';
import User from '../../User/User';

export class BulkRetire {
    getId() {
        return 'bulkRetire';
    }

    getLabel() {
        return 'Confirm/discard retirement request';
    }

    /**
     * @param {import('../../APIResource/APIResource').APIResourceBulkParams} params
     */
    setParameters(params) {
        this.parameters = params;
    }

    /**
     * @param {Array} ids
     * @param {*} parent - DataTable en cours
     */
    async run(ids, parent) {
        this.ids = ids;

        const errorMessage = this.parameters.forbiddenAccessMessage || "You don't have the rights.";

        openLoadingModal();

        if (
            !(await validateIds(ids, this.parameters.itemAccessCondition, this.parameters.resource, [
                ...this.parameters.itemAccessConditionEntityFields,
                'retirementStatus',
            ]))
        ) {
            Modal.close();
            Alert.show({ message: errorMessage, type: 'warning' });
            return;
        }

        /** Deuxieme test, sur le retirementStatus */
        if (userHasRoleMRM()) {
            if (
                !(await validateIds(
                    ids,
                    (entity) => entity.retirementStatus === ParameterStore('MODEL_RETIREMENT_STATUS_PROPOSED_LOD1'),
                    this.parameters.resource,
                    [...this.parameters.itemAccessConditionEntityFields, 'retirementStatus']
                ))
            ) {
                Modal.close();
                Alert.show({ message: 'The "Retirement status" of all the selected models must be "Proposed by LoD1"', type: 'warning' });
                return;
            }
        }
        else {
            if (
                !(await validateIds(
                    ids,
                    (entity) => entity.retirementStatus === ParameterStore('MODEL_RETIREMENT_STATUS_PROPOSED_MRM'),
                    this.parameters.resource,
                    [...this.parameters.itemAccessConditionEntityFields, 'retirementStatus']
                ))
            ) {
                Modal.close();
                Alert.show({ message: 'The "Retirement status" of all the selected models must be "Proposed by MRM"', type: 'warning' });
                return;
            }
        }

        Modal.open({
            title: 'Bulk Retire',
            content: <BulkRetireForm ids={this.ids} resource={this.parameters.resource} parent={parent} />,
        });
    }
}

const BulkRetireForm = (props) => {
    /** @type {{ids: Array, resource: import('../../APIResource/APIResource').APIResource, parent: *}} */
    const { ids, resource, parent } = props;

    /**
     * Contient les champs nécessaire à afficher dans RetireModelForm pour un bulk, sans mettre ceux qui
     * peuvent être différents entre les différents ids.
     * On fait ça pour ne pas perdre de temps à récupérer un des models du back.
     */
    const entityPlaceholder = {
        foregroundModelRelations: [],
        // NB: Le statut doit être le même également dans bulkRetire
        retirementStatus: userHasRoleMRM() ?  ParameterStore('MODEL_RETIREMENT_STATUS_PROPOSED_LOD1') : ParameterStore('MODEL_RETIREMENT_STATUS_PROPOSED_MRM'),
        // Si on n'est pas MRM, alors on est LoD1 donc on fait comme si on était modelOwner du point de vue de RetireModelForm 
        ...(userHasRoleMRM() ? {} : {modelOwner: `/api/users/${User.getId()}`})
    };

    /**
     *
     * @param {*} retiringModel
     * @param {Array} fields
     * @param {string} status
     */
    const bulkRetire = (retiringModel, fields, status) => {
        // On attribue à tous les models les valeurs récupérées dans le retiringModel :
        const changedFields = {
            ...Object.keys(fields).reduce(
                (acc, f) =>
                    f === 'retirementDocumentsEntities'
                        ? acc
                        : { ...acc, [f]: retiringModel[f] },
                {}
            ),
            retirementStatus: ParameterStore(status),
            documents: retiringModel.documents, // car les fields ne contiennent que les DocumentEntities, donc on doit ajouter le vrai lien
        };
        Http.post(resource.resourceId + '/bulk-retire', {
            ids: ids,
            fields: changedFields,
        }).then((response) => {
            parent
                .refreshApiData({ forceReload: true })
                .then(() => parent.setState({ selection: [], progress: false }));
            Alert.show({ message: response.message || 'Bulk Retire successfully processed.', type: "success" });
            Modal.close();
        });
    };

    const bulkResetRetire = (entity, keepJustification) => {
        const changedFields = {
            retirementStatus: null,
            retirementJustificationLod1: keepJustification ? entity.retirementJustificationLod1 : null,
            retirementJustificationMrm: null,
            retirementCommittee: null,
            retirementRequestDate: null,
            retirementExpectedDate: null,
        };

        Http.post(resource.resourceId + '/bulk/edit', {
            ids: ids,
            fields: changedFields,
        }).then((response) => {
            parent
                .refreshApiData({ forceReload: true })
                .then(() => parent.setState({ selection: [], progress: false }));
            Alert.show({ message: response.message || 'Bulk Retire successfully refused.', type: "success" });
            Modal.close();
        });
    };

    return (
        <ModalContent>
            <RetireModelForm
                entity={entityPlaceholder}
                resource={resource}
                retirementCallback={bulkRetire}
                retirementResetCallback={bulkResetRetire}
                isBulk={true}
            />
        </ModalContent>
    );
};
BulkRetireForm.propTypes = {
    /** Liste des ids à traiter en bulk */
    ids: PropTypes.array,
    /** ApiResource à utiliser */
    resource: PropTypes.object,
    /** DataTable source de l'appel au bulk */
    parent: PropTypes.any,
};
