import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import RetireModelForm, { retirementFields } from "./RetireModelForm";

/**
 * Modal pour le workflow de retrait d'un model.
 * En fonction du role et du statut, les choix sont différents !
 *
 */
const ModelRetirementModal = (props) => {
    const { entity, resource, resourceDetailComponent } = props;

    return (
        <div className="retire-modal">
            <Grid
                container
                justify="center"
                spacing={2}
                className="container resource-edit"
            >
                <Grid item xs={12}>
                    <RetireModelForm
                        entity={entity}
                        resource={resource}
                        resourceDetailComponent={resourceDetailComponent}
                    />
                </Grid>
            </Grid>
        </div>
    );
};
ModelRetirementModal.propTypes = {
    entity: PropTypes.object,
    resource: PropTypes.any,
    resourceDetailComponent: PropTypes.any,
};

export { retirementFields };
export default ModelRetirementModal;
