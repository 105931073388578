import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Services/Modal';
import { Button, CircularProgress, Icon, TextField } from '@material-ui/core';
import { ModalContent } from '../Modal/ModalContent';
import { ButtonBar } from '../Modal/ButtonBar';
import { ActionButton } from '../Modal/ActionButton';
import APIResourceStore from '../../Store/APIResourceStore';

const ParameterQuickAddForm = (props) => {
    const { label, parameterType, onSubmit } = props;

    const [value, setValue] = useState('');
    const [progress, setProgress] = useState(false);

    const apiAddParameter = async () => {
        if (!value || !parameterType) return;
        setProgress(true);
        const parameter = {
            label: value,
            verified: true,
            type: parameterType,
        };
        /** @type {import("../../Services/APIResource/APIResource").APIResource} */
        const resource = APIResourceStore.resources.parameters;
        const newParameter = await resource.apiPost(parameter);
        onSubmit(newParameter['@id']);
        setProgress(false);
        Modal.close();
    };

    return (
        <ModalContent>
            <TextField
                label={label}
                onChange={(event) => setValue(event.target.value)}
                value={value}
                placeholder="New parameter"
            />
            <ButtonBar>
                <ActionButton disabled={progress} onClick={apiAddParameter}>
                    Add {progress && <CircularProgress size={20} />}
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
};
ParameterQuickAddForm.propTypes = {
    label: PropTypes.string,
    parameterType: PropTypes.number,
    /** Action déclenchée après le POST vers l'api. */
    onSubmit: PropTypes.func,
};

export const ParameterQuickAdd = (props) => {
    const { label, parameterType, onChange } = props;

    const openParameterQuickAddModal = () => {
        Modal.open({
            title: 'New parameter for ' + label,
            content: <ParameterQuickAddForm label={label} onSubmit={onChange} parameterType={parameterType} />,
        });
    };

    return (
        <Button
            variant="contained"
            color="primary"
            style={styles.green}
            onClick={openParameterQuickAddModal}
        >
            <Icon className="fas fa-plus" />
        </Button>
    );
};
ParameterQuickAdd.propTypes = {
    label: PropTypes.string,
    parameterType: PropTypes.number,
    /** Exécutée lors de la mise à jour du paramètre */
    onChange: PropTypes.func,
};

const styles = {
    green: {
        backgroundColor: '#0dbbb7',
    },
};
