import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    Input,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import APIResourceStore from '../../../../Store/APIResourceStore';
import { DateRange } from 'react-date-range';
import Select from "../../../Forms/Select/Select";
import BaseSelect from "../fields/BaseSelect"
import OperatorSelect from '../fields/OperatorSelect';
import { ParameterSelect } from "../../../Forms/ParameterSelect/ParameterSelect";
import { EntitySelectAsync } from "../../../Forms/EntitySelectAsync/EntitySelectAsync";
import { EntitySelect } from "../../../Forms/EntitySelect/EntitySelect";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LightFieldNames from "./light-field-names"

const ROOT_MODELS_NAME = {
    certification: {
        title: 'Certification'
    },
    changelog: {
        title: 'Changelog'
    },
    deliverable: {
        title: 'Deliverable'
    },
    implementation: {
        title: 'Implementation'
    },
    issue: {
        title: 'Trouble'
    },
    mitigationAction: {
        title: 'Mitigation Action'
    },
    model: {
        title: 'Model'
    },
    modelCertificationCampaign: {
        title: 'Model Certification Campaign'
    },
    modelUse: {
        title: 'Model Use'
    },
    mra: {
        title: 'MRA'
    },
    mraDimension: {
        title: 'MRA Dimension'
    },
    mraScore: {
        title: 'MRA Score'
    },
    notice: {
        title: 'Notice'
    },
    review: {
        title: 'Review'
    },
    recommendationIggBce: {
        title: 'Recommendation IGG BCE'
    },
    tiering: {
        title: 'Tiering'
    },
}
const HUMAN_FIELDS = {
    ...ROOT_MODELS_NAME,
    verifiedByMrm: {
        title: 'Verified By MRM'
    },
    submit: {
        title: 'Submit'
    },
    reviewCommittees: {
        title: 'Review Committees'
    },
}

const BLACKLIST = [
    'complexityLevel',
    'complexityLevelOverride',
    'complexityLevelLocal',
    'complexityRationale',
    'complexityRationaleOverride',
    'externalImpactLevel',
    'externalImpactLevelAuto',
    'externalImpactLevelOverride',
    'externalImpactRationale',
    'externalImpactRationaleOverride',
    'modelUsageLevel',
    'modelUsageLevelAuto',
    'modelUsageLevelOverride',
    'materialityLevel',
    'materialityLevelAuto',
    'materialityLevelLocal',
    'materialityLevelOverride',
    'materialityRationale',
    'materialityRationaleOverride',
    'modelUsageRationale',
    'modelUsageRationaleOverride',
    'quantifiedMateriality',
    'quantifiedMaterialityOverride',
    'quantifiedMaterialityUnit',
    'quantifiedMaterialityUnitOverride',
    'metrics',
    'metricsAuto',
    'metricsOverride',
    'specialQuantifiedMateriality',
    'specialQuantifiedMaterialityOverride',
    'tierRationale',
    'tierRationaleOverride',
    'tierResult',
    'tierResultAuto',
    'tierResultLocal',
    'tierResultOverride',
    'mrmTeamsOverride',
    'issuer'
];


const LINKS = [
    { value: '/resource/models/list', label: 'Models Inventory' },
    { value: '/resource/my_models/list', label: 'My models' },
    { value: '/resource/my_draft_models/list', label: 'My draft models' },
    { value: '/resource/declaration_models/list', label: 'My declarations in progress' },
    { value: '/resource/models_val/list', label: 'My validation perimeter' },
    { value: '/resource/retired_models/list', label: 'Retired models' },
    { value: '/resource/models_pending_request/list', label: 'Models pending requests' },
    { value: '/resource/models_under_declaration/list', label: 'Models under declaration' },
    { value: '/resource/specific_frameworks/list', label: 'Specific frameworks' },
    { value: '/resource/deleted_models/list', label: 'Deleted models' },
    { value: '/resource/non_models/list', label: 'Non-Models' },
    { value: '/resource/model_uses/list', label: 'Model Uses' },
    { value: '/resource/implementations/list', label: 'Implementations' },
    { value: '/resource/i_t_systems/list', label: 'IT System' },
    { value: '/resource/mitigation_actions/list', label: 'Mitigation Actions' },
    { value: '/resource/review_committees/list', label: 'Meetings' },
    { value: '/resource/model_certification_campaigns/list', label: 'MRM dashboard' },
    { value: '/resource/my_model_certification_campaigns/list', label: 'My certifications' },
    { value: '/resource/deleted_model_certification_campaigns/list', label: 'Deleted certifications campaigns' },
    { value: '/resource/change_logs/list', label: 'Changelogs list' },
    { value: '/resource/planned_reviews/list', label: 'Planned reviews' },
    { value: '/resource/ongoing_reviews/list', label: 'Ongoing reviews' },
    { value: '/resource/pending_reviews/list', label: 'Pending requests' },
    { value: '/resource/closed_reviews/list', label: 'Closed reviews' },
    { value: '/resource/dismissed_reviews/list', label: 'Dismissed reviews' },
    { value: '/resource/findings/list', label: 'Findings' },
    { value: '/resource/notices/list', label: 'Notices' },
    { value: '/resource/recommendation_igg_bces/list', label: 'IGG / BCE Recos list' },
    // { value: '/resource/recommendation_igg_bces/add', label: '' },
    { value: '/import/recommendation', label: 'IGG / BCE Recos import' },
    { value: '/resource/workflows/list', label: 'Workflows list' },
    // { value: '/resource/workflows/add', label: '' },
    { value: '/resource/process/list', label: 'Process list' },
    { value: '/resource/my_troubles/list', label: 'My Troubles' },
    { value: '/resource/waiting_troubles/list', label: 'Waiting for assignment Troubles' },
    { value: '/resource/open_troubles/list', label: 'Open Troubles' },
    { value: '/resource/closed_troubles/list', label: 'Closed Troubles' },
    { value: '/resource/users/list', label: 'Users' },
    { value: '/resource/users/assignation', label: 'Users assignation' },
    { value: '/resource/scopes/list', label: 'Scopes' },
    { value: '/resource/legal_entities/list', label: 'Legal Entities' },
    { value: '/resource/b_ls/list', label: 'Business Lines' },
    { value: '/resource/o_us/list', label: 'Organizational Units' },
    { value: '/resource/parameters/list', label: 'Parameters' },
    { value: '/resource/variables/list', label: 'Variables' },
]

const CHART_OPTIONS = [
    {
        value: 'pie chart',
        label: 'Pie chart',
    },
    {
        value: 'bar chart',
        label: 'Bar chart',
    },
    {
        value: 'table',
        label: 'Table',
    },
    {
        value: 'count',
        label: 'Count',
    },
    {
        value: 'ratio',
        label: 'Ratio',
    },
    {
        value: 'orderDesc',
        label: 'Top by X'
    },
    {
        value: 'orderAsc',
        label: 'Bottom by X'
    },
    {
        value: 'top',
        label: 'Top Rankings',
    },
    {
        value: 'bottom',
        label: 'Bottom Rankings',
    },
]

const REPORT_TYPES = {
    SIMPLE: 'simpleReport',
    RATIO: 'ratioReport',
    TOP: 'topReport',
    BOTTOM: 'bottomReport',
    COUNT: 'countReport',
    ORDER_BY_DESC: 'orderByDescReport',
    ORDER_BY_ASC: 'orderByAscReport',
}

const CHART_TO_REPORT = {
    'pie chart': REPORT_TYPES.SIMPLE,
    'bar chart': REPORT_TYPES.SIMPLE,
    'table': REPORT_TYPES.SIMPLE,
    'count': REPORT_TYPES.COUNT,
    'ratio': REPORT_TYPES.RATIO,
    'top': REPORT_TYPES.TOP,
    'bottom': REPORT_TYPES.BOTTOM,
    'orderDesc': REPORT_TYPES.ORDER_BY_DESC,
    'orderAsc': REPORT_TYPES.ORDER_BY_ASC,

}

const advancedSettingsAvailability = (chartType) => {
    return [
        'count',
        'ratio',
    ].includes(chartType)
}

const CHART_TO_OPERATION = {
    'pie chart': 'groupby',
    'bar chart': 'groupby',
    'table': 'groupby',
    'ratio': 'ratio',
    'count': 'count',
    'top': 'top',
    'bottom': 'bottom',
    'min': 'min',
    'max': 'max',
    'orderDesc': 'orderbydesc',
    'orderAsc': 'orderbyasc'

}

const OP_EQUALS = "is"
const OP_NOT_EQUALS = "is not"
const OP_LOWER_GREATER_THAN = ["lower than", "greater than",]
const OP_BETWEEN = "between"
const BEFORE_NOW = 'before now';
const AFTER_NOW = 'after now';
const THIS_YEAR = 'this year';
const LAST_YEAR = 'last year';
const TWELVE_MONTHS_AGO = "12 months ago"
const THIS_MONTH = 'this month';
const LAST_MONTH = 'last month';
const THIRTY_DAYS_AGO = "30 days ago";
const OP_IS_MYSELF = "is myself";
const OP_IS_MYTEAM = "is my team";

const OPERATORS_WITHOUT_VALUE = [
    BEFORE_NOW,
    AFTER_NOW,
    THIS_YEAR,
    LAST_YEAR,
    TWELVE_MONTHS_AGO,
    THIS_MONTH,
    LAST_MONTH,
    THIRTY_DAYS_AGO,
    OP_IS_MYSELF,
    OP_IS_MYTEAM,
];

const TYPE_TO_OPERATORS = {
    string: [OP_EQUALS, OP_NOT_EQUALS],
    boolean: [OP_EQUALS, OP_NOT_EQUALS],
    parameter: [OP_EQUALS, OP_NOT_EQUALS],
    integer: [OP_EQUALS, ...OP_LOWER_GREATER_THAN, OP_NOT_EQUALS],
    smallint: [OP_EQUALS, ...OP_LOWER_GREATER_THAN, OP_NOT_EQUALS],
    float: [OP_EQUALS, ...OP_LOWER_GREATER_THAN, OP_NOT_EQUALS],
    datetime: [
        OP_BETWEEN,
        BEFORE_NOW,
        AFTER_NOW,
        THIS_YEAR,
        LAST_YEAR,
        THIS_MONTH,
        LAST_MONTH,
        TWELVE_MONTHS_AGO,
        THIRTY_DAYS_AGO,
    ],
    date: [
        OP_BETWEEN,
        BEFORE_NOW,
        AFTER_NOW,
        THIS_YEAR,
        LAST_YEAR,
        THIS_MONTH,
        LAST_MONTH,
        TWELVE_MONTHS_AGO,
        THIRTY_DAYS_AGO,
    ],
    user: [OP_IS_MYSELF, OP_EQUALS, OP_NOT_EQUALS],
    scope: [OP_IS_MYTEAM, OP_EQUALS, OP_NOT_EQUALS],
    bl: [OP_EQUALS, OP_NOT_EQUALS],
    ou: [OP_EQUALS, OP_NOT_EQUALS],
    legalEntity: [OP_EQUALS, OP_NOT_EQUALS],
    itsystem: [OP_EQUALS, OP_NOT_EQUALS],
    reviewCommittee: [OP_EQUALS, OP_NOT_EQUALS],
    establishment: [OP_EQUALS, OP_NOT_EQUALS],
};

// format is [Component, "label for component"]
const TYPE_TO_INPUT = {
    "string": [TextInput, "Value"],
    "boolean": [BooleanInput, "Value"],
    "integer": [TextInput, "Value"],
    "parameter": [ParameterInput, "Value"],
    "smallint": [TextInput, "Value"],
    "float": [TextInput, "Value"],
    "datetime": [DateRangeSelect, "Period"],
    "date": [DateRangeSelect, "Period"],
    "id": [TextInput, "Value"],
    "user": [UserInput, "Value"],
    "scope": [ScopeInput, "Value"],
    "bl": [BLInput, "Value"],
    "ou": [OUInput, "Value"],
    "legalEntity": [LegalEntityInput, "Value"],
    "itsystem": [ITSystemInput, "Value"],
    "reviewCommittee": [ReviewCommitteeInput, "Value"],
    "establishment": [EstablishmentInput, "Value"],
}

// derive type from attribute type and entity of a field
// example: type is id + entity is user ==> input should be a user select
function deriveType(type, entity) {
    if (!entity) return type
    if (type === 'id' && entity === 'App\\Entity\\User') {
        return "user"
    }
    if (type === 'id' && entity === 'App\\Entity\\Scope') {
        return "scope"
    }
    if (type === 'id' && entity === 'App\\Entity\\BL') {
        return "bl"
    }
    if (type === 'id' && entity === 'App\\Entity\\OU') {
        return "ou"
    }
    if (type === 'id' && entity === 'App\\Entity\\LegalEntity') {
        return "legalEntity"
    }
    if (type === 'id' && entity === 'App\\Entity\\ITSystem') {
        return "itsystem"
    }
    if (type === 'id' && entity === 'App\\Entity\\ReviewCommittee') {
        return "reviewcommittee"
    }
    if (type === 'id' && entity === 'App\\Entity\\Establishment') {
        return "establishment"
    }
    return type
}

function BooleanInput({ field, value = 1, label, onChange }) {

    const onBooleanChange = useCallback((event) => {
        onChange(event.target.value)
    }, [])

    return <div>
        <div className="select-component">
            <label>{label}</label>
        </div>
        <RadioGroup row defaultValue="1" onChange={onBooleanChange} value={value}>
            <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label="True"
                labelPlacement="start"
            />
            <FormControlLabel
                value="0"
                control={<Radio color="primary" />}
                label="False"
                labelPlacement="start"
            />
        </RadioGroup>
    </div>
}

function ParameterInput({ field, value, label, onChange, isMulti = false  }) {
    const onParameterChange = useCallback((value) => {
        onChange(value);
    }, []);

    if (field.code) {
        return (
            <div style={{ marginBottom: '50px' }}>
                <ParameterSelect
                    multi={isMulti}
                    label={label}
                    field={{
                        params: {
                            type: field.code,
                        },
                    }}
                    onChange={onParameterChange}
                    value={value}
                    required={false}
                    clearable={true}
                />
            </div>
        );
    }

    return <TextInput label={label} onBlur={onChange} defaultValue={value} />;
}


function UserInput({ onChange, value, label }) {
    const onValueChange = (iriPath) => {
        if (iriPath) {
            let id;

            if (Array.isArray(iriPath)) {
                id = iriPath.map((i) => i.split('/').pop());
            } else {
                id = iriPath.split('/').pop();
            }

            onChange(id);
        }
    }
    return <EntitySelectAsync
        label={label}
        resourceId={'users'}
        resourceLabel={'fullNameWithTeam'}
        clearable={true}
        multi={true}
        issueButton={false}
        endpoints={{ getAll: 'users/all-users/all' }}
        value={value}
        onChange={(v) => onValueChange(v)}
    />
}

function ScopeInput({ onChange, value, label }) {
    const onValueChange = (iriPath) => {
        if (iriPath) {
            let id;

            if (Array.isArray(iriPath)) {
                id = iriPath.map((i) => i.split('/').pop());
            } else {
                id = iriPath.split('/').pop();
            }

            onChange(id);
        }
    }
    return <EntitySelectAsync
        label={label}
        resourceId={'scopes'}
        resourceLabel={'title'}
        clearable={true}
        multi={true}
        issueButton={false}
        endpoints={{ getAll: 'scopes/all-scopes/all' }}
        value={value}
        onChange={(v) => onValueChange(v)}
    />
}

function ITSystemInput({ onChange, value, label }) {
    const onValueChange = (iriPath) => {
        if (iriPath) {
            let id;

            if (Array.isArray(iriPath)) {
                id = iriPath.map((i) => i.split('/').pop());
            } else {
                id = iriPath.split('/').pop();
            }

            onChange(id);
        }
    }
    return <EntitySelect
        label={label}
        resourceId={'i_t_systems'}
        resourceLabel={'name'}
        clearable={true}
        multi={true}
        issueButton={false}
        /* endpoints={{getAll: 'scopes/all-scopes/all'}} */
        value={value}
        onChange={(v) => onValueChange(v)}
    />
}
function BLInput({ onChange, value, label }) {

    const onValueChange = useCallback((iriPath) => {
        if (iriPath) {
            let id;

            if (Array.isArray(iriPath)) {
                id = iriPath.map((i) => i.split('/').pop());
            } else {
                id = iriPath.split('/').pop();
            }

            onChange(id);
        }
    }, [onChange])

    return (
        <EntitySelectAsync
            label={null}
            resourceId='b_ls'
            instanceId='b_ls_all'
            resourceLabel='title'
            value={value}
            onChange={onValueChange}
            clearable={true}
            multi={true}
            endpoints={{ getAll: 'business_lines/all' }}
        />

    )
}

function OUInput({ onChange, value, label }) {

    const onValueChange = useCallback((iriPath) => {
        if (iriPath) {
            let id;

            if (Array.isArray(iriPath)) {
                id = iriPath.map((i) => i.split('/').pop());
            } else {
                id = iriPath.split('/').pop();
            }

            onChange(id);
        }
    }, [onChange])

    return (
        <EntitySelectAsync
            label={null}
            resourceId='o_us'
            instanceId='o_us_all'
            resourceLabel='title'
            value={value}
            onChange={onValueChange}
            clearable={true}
            multi={true}
            endpoints={{ getAll: 'o_us/all' }}
        />

    )
}

function LegalEntityInput({ onChange, value, label }) {

    const onValueChange = useCallback((iriPath) => {
        if (iriPath) {
            let id;

            if (Array.isArray(iriPath)) {
                id = iriPath.map((i) => i.split('/').pop());
            } else {
                id = iriPath.split('/').pop();
            }

            onChange(id);
        }
    }, [onChange])

    return (
        <EntitySelectAsync
            label={null}
            resourceId='legal_entities'
            instanceId='legal_entities_all'
            resourceLabel='title'
            value={value}
            onChange={onValueChange}
            clearable={true}
            multi={true}
            endpoints={{ getAll: 'legal_entities/all' }}
        />

    )
}

function ReviewCommitteeInput({ onChange, value, label }) {
    const onValueChange = (iriPath) => {
        if (iriPath) {
            let id;

            if (Array.isArray(iriPath)) {
                id = iriPath.map((i) => i.split('/').pop());
            } else {
                id = iriPath.split('/').pop();
            }

            onChange(id);
        }
    }
    return <EntitySelect
        label={label}
        resourceId={'review_committees'}
        resourceLabel={'id'}
        clearable={true}
        multi={true}
        issueButton={false}
        value={value}
        onChange={(v) => onValueChange(v)}
    />
}

function EstablishmentInput({ onChange, value, label }) {

    const onValueChange = useCallback((iriPath) => {
        if (iriPath) {
            let id;

            if (Array.isArray(iriPath)) {
                id = iriPath.map((i) => i.split('/').pop());
            } else {
                id = iriPath.split('/').pop();
            }

            onChange(id);
        }
    }, [onChange])

    return (
        <EntitySelectAsync
            label={null}
            resourceId='establishments'
            instanceId='establishments_all'
            resourceLabel='title'
            value={value}
            onChange={onValueChange}
            clearable={true}
            multi={true}
            endpoints={{ getAll: 'establishments/all' }}
        />

    )
}

function DataSourceSelect({ models, onChange, value }) {

    const options = useMemo(() => {
        return models.map(model => ({
            label: model.humanizedName || model.name,
            value: model,
            equals: (a, b) => {
                return a.name === b.name
            }
        }))
    }, [models])

    return <BaseSelect name="model" label="Data Source" required options={options} onChange={onChange} value={value} />
}

function AttributeSelect({ name, label, fields, onChange, value, filter }) {

    if (!fields) return null


    const options = useMemo(() => {

        let fs = fields
        if (filter) {
            fs = fields.filter(filter)
        }

        return fs.map(field => ({
            label: field.humanizedName || field.name,
            value: field,
            equals: (a, b) => {
                return a.name === b.name
            }

        }))
    }, [fields])

    const formatOptionLabel = useCallback((option) => {
        const relations = option.label.split(">>")

        return <div>{relations.join(' → ')}</div>
    }, [])

    return <BaseSelect name={name} label={label} options={options} onChange={onChange} value={value} formatOptionLabel={formatOptionLabel} />
}

function TextInput({ name, label, onChange = () => { }, onBlur = undefined, value, defaultValue }) {



    return <div>
        <div className="select-component">
            <label>{label}</label>
        </div>
        <Input
            name={name || "textinput"}
            type='text'
            onChange={(e) => !onBlur && onChange(e.target.value)}
            onBlur={(e) => onBlur && onBlur(e.target.value)}
            defaultValue={defaultValue}
        />
    </div>

}

function getOperators(field, index) {
    if (field) {
        const derivedType = deriveType(field.type, field.entity)
        return TYPE_TO_OPERATORS[derivedType]
    }
}

function ConditionInput({ field, index, onChange, value, operator }) {
    if (field) {
        const derivedType = deriveType(field.type, field.entity)
        const [ComponentInput, label] = TYPE_TO_INPUT[derivedType]

        if (operator && OPERATORS_WITHOUT_VALUE.includes(operator)) {
            return <></>;
        }

        if (ComponentInput) {
            return (
                <ComponentInput
                    isMulti={true}
                    name={`condInput${index}`}
                    label={label}
                    field={field}
                    onChange={onChange}
                    value={value}
                    defaultValue={value}
                    onBlur={onChange}
                />
            );
        }

        return <span>No Input defined for this type: {field.type}</span>

    }

    return null
}

function ArrayFields({ label, buttonText, fields, addField, FieldComponent }) {
    return (
        <div>
            <div className="select-component">
                <label>{label}</label>
            </div>
            <div style={styles.condContainer}>
                {fields.map((f, i) => (
                    <FieldComponent field={f} index={i} />
                ))}
            </div>
            <div
                onClick={addField}
                style={{
                    display: 'inline-block',
                    cursor: 'pointer',
                    margin: '10px 0px',
                    background: 'lightgrey',
                    color: 'black',
                    padding: '10px 20px',
                    borderRadius: '5px',
                }}
            >
                + {buttonText}
            </div>
        </div>
    );
}

function ConditionArrayFields({ fields, addField, addAdvancedField, FieldComponent, showLightFilterBtn }) {
    return (
        <div>
            <div className="select-component">
                <label>Filters</label>
            </div>
            <div style={styles.condContainer}>
                {fields.map((f, i) => (
                    <FieldComponent field={f} index={i} />
                ))}
            </div>
            <div>
                {showLightFilterBtn && (
                    <div
                        onClick={addField}
                        style={{
                            display: 'inline-block',
                            cursor: 'pointer',
                            margin: '10px 10px 10px 0px',
                            background: 'lightgrey',
                            color: 'black',
                            padding: '10px 20px',
                            borderRadius: '5px',
                        }}
                    >
                        + Filter
                    </div>
                )}
                <div
                    onClick={addAdvancedField}
                    style={{
                        display: 'inline-block',
                        cursor: 'pointer',
                        margin: '10px 0px',
                        background: 'lightgrey',
                        color: 'black',
                        padding: '10px 20px',
                        borderRadius: '5px',
                    }}
                >
                    + {!showLightFilterBtn ? 'Filter' : 'Advanced filter'}
                </div>
            </div>
        </div>
    );
}

function Segments({ segments, onChange, fields }) {
    const onChangeAttribute = useCallback((index, value) => {
        const newSegments = [...segments]
        newSegments[index] = value

        onChange([
            ...newSegments
        ])
    }, [segments])

    const removeSegment = useCallback((index) => {

        const newSegments = [...segments]

        newSegments.splice(index, 1)
        onChange([
            ...newSegments
        ])
    }, [segments])

    const addSegment = useCallback(() => {
        onChange([
            ...segments,
            {}
        ])
    }, [segments])

    const changeLabelName = useCallback((index, value) => {

        const newSegments = [...segments]

        newSegments[index] = {
            ...newSegments[index],
            columnName: value
        }

        if (!value) {
            delete newSegments[index].columnName
        }

        onChange([
            ...newSegments
        ])
    }, [segments])

    const Segment = useCallback(({ field, index }) => {

        // const currentField = conditions && conditions[index] && conditions[index].field
        return <div key={`segment_${index}`} style={styles.cond}>
            <div style={{ fontWeight: 'bold', marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>{`Column #${index}`}</span>
                <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => removeSegment(index)}><DeleteForeverIcon /></span>
            </div>
            <div>
                <AttributeSelect fields={fields} name="newSegmentName" label=""
                    onChange={(o) => onChangeAttribute(index, o.value)} value={field} />
                <TextInput name={`column_label_${index}`} label="Column label" onBlur={(v) => changeLabelName(index, v)} defaultValue={field.columnName} />
            </div>
        </div>
    }, [segments])

    return <ArrayFields label="Columns" buttonText="Add a column"
        fields={segments}
        addField={addSegment}
        FieldComponent={Segment}
    />
}

function Conditions({ conditions, onConditionsChange, model }) {

    const onChange = useCallback((index, property, value) => {
        const newConds = [...conditions]

        newConds[index] = {
            ...newConds[index],
            [property]: value
        }

        onConditionsChange([
            ...newConds
        ])
    }, [conditions])

    const addFilter = useCallback(() => {
        onConditionsChange([
            ...conditions,
            {
                light: true,
                field: null,
                op: null,
                value: null

            }
        ])
    }, [conditions])

    const addAdvancedFilter = useCallback(() => {
        onConditionsChange([
            ...conditions,
            {
                light: false,
                field: null,
                op: null,
                value: null,
            },
        ]);
    }, [conditions]);

    const removeFilter = useCallback((index) => {

        const newConditions = [...conditions]

        newConditions.splice(index, 1)
        onConditionsChange([
            ...newConditions
        ])
    }, [conditions])

    const onChangeAttribute = useCallback((index, value) => {
        const newConds = [...conditions]
        newConds[index] = {
            ...newConds[index],
            field: value,
            value: undefined
        }

        onConditionsChange([
            ...newConds
        ])
    }, [conditions])

    const lightFields = useMemo(() => {
        return (
            model &&
            model.linkedFields.filter(({ name }) => LightFieldNames.includes(name))) || [];
    }, [model])

    const Condition = useCallback(({ field, index }) => {
        const operators = getOperators(field.field, index)
        const linkedFields = model && (field.light ? lightFields : model.linkedFields);

        return (
            <div key={`cond_${index}`} style={styles.cond}>
                <div
                    style={{
                        fontWeight: 'bold',
                        marginTop: '10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <span>{`${field.light ? 'Filter' : 'Advanced filter'} #${index + 1}`}</span>
                    <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => removeFilter(index)}>
                        <DeleteForeverIcon />
                    </span>
                </div>
                <AttributeSelect
                    fields={linkedFields}
                    name="newFilterName"
                    label="Field"
                    onChange={(o) => onChangeAttribute(index, o.value)}
                    value={field.field}
                />
                <OperatorSelect
                    operators={operators}
                    name="newFilterOperator"
                    label="Operator"
                    value={field.op}
                    onChange={(o) => onChange(index, 'op', o.value)}
                />
                <ConditionInput
                    field={field.field}
                    index={index}
                    onChange={(v) => onChange(index, 'value', v)}
                    value={field.value}
                    operator={field.op}
                />
            </div>
        );
    }, [conditions])

    return (
        <>
            <ConditionArrayFields
                showLightFilterBtn={lightFields.length > 0}
                fields={conditions}
                addField={addFilter}
                addAdvancedField={addAdvancedFilter}
                FieldComponent={Condition}
                FieldComponentProps={Condition}
            />
        </>
    );
}


function excludeFieldDate(field) {
    return (field.type !== 'date' && field.type !== 'datetime')
}


function ExportReport({ models, model, segment, conditions, onDataSourceChange, onSegmentChange, onConditionsChange }) {
    return <>
        <Conditions model={model} conditions={conditions} onConditionsChange={onConditionsChange} />
    </>
}


function SimpleReport({ models, model, segment, conditions, onDataSourceChange, onSegmentChange, onConditionsChange }) {


    return <>
        <div style={styles.flex}>
            <div style={styles.width50}>
                <DataSourceSelect models={models} onChange={onDataSourceChange} value={model} />
            </div>
            <div style={styles.width50}>
                <AttributeSelect name="segment" label="Segment" fields={model && model.linkedFields} onChange={onSegmentChange} value={segment} filter={excludeFieldDate} />
            </div>

        </div>
        <Conditions model={model} conditions={conditions} onConditionsChange={onConditionsChange} />
    </>
}

function TopOrBottomReport({ limit, models, model, conditions, segment, onLimitChange, onConditionsChange, onDataSourceChange, onSegmentChange }) {

    return <>
        <div style={styles.width50}>
            <TextInput name="limit" label="Number of elements to show" onBlur={onLimitChange} defaultValue={limit} />
        </div>

        <SimpleReport models={models} model={model}
            conditions={conditions} segment={segment}
            onConditionsChange={onConditionsChange}
            onDataSourceChange={onDataSourceChange}
            onSegmentChange={onSegmentChange}

        />
    </>
}








function OrderByReport({ limit, models, model, conditions, segments, onLimitChange, onConditionsChange, onDataSourceChange, onSegmentsChange, onOrderbyChange, orderby }) {

    return <>
        <div style={styles.width50}>
            <TextInput name="limit" label="Number of elements to show" onBlur={onLimitChange} defaultValue={limit} />
        </div>

        <div style={styles.flex}>
            <div style={styles.width50}>
                <DataSourceSelect models={models} onChange={onDataSourceChange} value={model} />
            </div>

            {/* <div style={styles.width50}> */}
            {/* <AttributeSelect name="segment" label="Segment" fields={model && model.linkedFields} onChange={onSegmentChange} value={segment} filter={excludeFieldDate} /> */}
            {/* </div> */}

        </div>

        <Segments name="segments" label="Segments" fields={model && model.linkedFields} segments={segments} onChange={onSegmentsChange} />
        <div style={styles.width50}>
            <AttributeSelect name="orderby" label="Order By" fields={model && model.linkedFields} onChange={onOrderbyChange} value={orderby} />
        </div>
        <Conditions model={model} conditions={conditions} onConditionsChange={onConditionsChange} />
    </>
}

function TopReport({ limit, models, model, conditions, segment, onLimitChange, onConditionsChange, onDataSourceChange, onSegmentChange }) {

    return <>
        <div style={styles.reportExplainer}>This report displays a table with the top X elements grouped by the segment selected.</div>
        <TopOrBottomReport limit={limit} models={models} model={model} conditions={conditions} segment={segment} onLimitChange={onLimitChange} onConditionsChange={onConditionsChange} onSegmentChange={onSegmentChange} onDataSourceChange={onDataSourceChange} />
    </>
}

function BottomReport({ limit, models, model, conditions, segment, onLimitChange, onConditionsChange, onDataSourceChange, onSegmentChange }) {

    return <>
        <div style={styles.reportExplainer}>This report displays a table with the bottom X elements grouped by the segment selected.</div>
        <TopOrBottomReport limit={limit} models={models} model={model} conditions={conditions} segment={segment} onLimitChange={onLimitChange} onConditionsChange={onConditionsChange} onSegmentChange={onSegmentChange} onDataSourceChange={onDataSourceChange} />
    </>
}

function CountReport({ models, model, conditions, onDataSourceChange, onConditionsChange }) {

    return <>
        <div style={styles.flex}>
            <div style={styles.width50}>
                <DataSourceSelect models={models} onChange={onDataSourceChange} value={model} />
            </div>
        </div>
        <Conditions model={model} conditions={conditions} onConditionsChange={onConditionsChange} />
    </>
}

function RatioReport({ models, counts, onCountsChange }) {
    const [selected, setSelected] = useState(0)

    const onDataSourceChange = useCallback((option) => {
        if (counts && counts[selected]) {
            counts[selected].model = option.value
            onCountsChange([
                ...counts
            ])

        }
    }, [selected, counts])


    const onConditionsChange = useCallback((conditions) => {
        if (counts && counts[selected]) {
            counts[selected].conditions = conditions
            onCountsChange([
                ...counts
            ])
        }
    }, [selected, counts])

    const { model, conditions } = useMemo(() => {
        if (counts && counts[selected]) {
            return { model: counts[selected].model, conditions: counts[selected].conditions }
        }
        return { model: null, conditions: [] }

    }, [selected, counts])

    const queryBoxStyles = useCallback((idx) => {
        if (idx === selected) {
            return {
                ...styles.queryBox,
                ...styles.queryBoxSelected
            }
        }
        return styles.queryBox
    }, [selected])

    return <>
        <div>
            <div style={queryBoxStyles(0)} onClick={() => setSelected(0)}>
                <div>Query 1</div>
                <div style={styles.queryClickto} >
                    {selected === 0 ? 'selected' : 'click to setup'}
                </div>
            </div>
            <div style={styles.ratioSeparator}>/</div>
            <div style={queryBoxStyles(1)} onClick={() => setSelected(1)}>
                <div>Query 2</div>
                <div style={styles.queryClickto} >
                    {selected === 1 ? 'selected' : 'click to setup'}
                </div>
            </div>

        </div>
        <div>

            <div style={styles.queryTitle}>You are editing query {selected === 0 ? '1' : '2'}</div>
            <CountReport models={models} model={model} conditions={conditions} onDataSourceChange={onDataSourceChange} onConditionsChange={onConditionsChange} key={`countreport_${selected}`} />
        </div>
    </>
}

function DateRangeSelect({ name, label, onChange, value }) {
    const defaultValue = [{
        startDate: new Date(),
        endDate: null,
        key: 'selection'
    }]
    const items = value && Array.isArray(value) && value.map((v) => ({ 
        endDate: typeof v.endDate === 'string' ? new Date(v.endDate) : v.endDate, 
        startDate: typeof v.startDate === 'string' ? new Date(v.startDate) : v.startDate, 
        key: v.key, 
    }));

    /** React maintient la référence entre les rerender, pas besoin de hook */
    const handleChange = (selection) => {
        let endDate = new Date(selection.endDate.getTime());
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        onChange([{...selection, endDate }]);
    }

    return <div className="select-component">
        <label htmlFor={name}>
            {label}
        </label>
        <div>
            <DateRange
                startDatePlaceholder=""
                endDatePlaceholder=""
                editableDateInputs={false}
                onChange={item => handleChange(item.selection)}
                moveRangeOnFirstSelection={false}
                rangeColors={['#ac0577']}
                ranges={items || defaultValue}
            />
        </div>
    </div>
}

const INITIAL_STATE_COUNTS = [
    {
        model: null,
        conditions: []
    },
    {
        model: null,
        conditions: []
    }
]

const CustomReport = ({ models, parameters, setParameters, toggleExtendedView, userSettings, exportMode = false, exportDatasource }) => {
    const modelsHumanized = useMemo(() => {
        const APIResourceStoreFields = Object.keys(APIResourceStore.resources).reduce((fields, entityKey) => {
            return {
                ...fields,
                ...APIResourceStore.resources[entityKey].fields
            }
        }, {})

        const humanReadableFields = {
            ...APIResourceStoreFields,
            ...HUMAN_FIELDS,
        }

        return models.map((m) => {
            let modelHumanName = m.name;
            if (humanReadableFields[m.name]) {
                modelHumanName = humanReadableFields[m.name].title;
            }
            return {
                ...m,
                humanizedName: modelHumanName,
                linkedFields: m.linkedFields.reduce((fields, f) => {
                    const splitPath = f.name.split(' >> ');
                    if (f.type === 'id') {
                        // remove last part to hide the ID
                        // it's a special case where we show
                        // a select list to choose (user, scopes, etc...)
                        splitPath.pop()
                    }
                    const pathLength = splitPath.length
                    let countTranslations = 0
                    let shouldBeRemoved = false
                    const humanizedName = splitPath
                        .map((entity) => {
                            if (BLACKLIST.includes(entity)) {
                                shouldBeRemoved = true
                                return "_"
                            }
                            // if we don't find the translation
                            // with the original key
                            // try with one character less to handle
                            // pluralization cases
                            const unpluralize = entity.slice(0, -1)
                            if (humanReadableFields[entity] && humanReadableFields[entity].title) {
                                countTranslations++;
                                return humanReadableFields[entity].title;
                            } else if (humanReadableFields[unpluralize] && humanReadableFields[unpluralize].title) {
                                countTranslations++;
                                return humanReadableFields[unpluralize].title;
                            }

                            return entity;
                        })
                        .join(' >> ');

                    if (countTranslations === pathLength && !shouldBeRemoved) {
                        fields = [
                            ...fields,
                            {
                                ...f,
                                humanizedName
                            }
                        ]
                    }
                    // else {
                    //     console.log("not found or blacklisted", f.name)
                    // }

                    return fields
                }, []),
            };
        });
    }, [models]);
    const [chartType, setChartType] = useState(CHART_OPTIONS[0].value);
    const [reportType, setReportType] = useState(REPORT_TYPES.SIMPLE);
    const [model, setModel] = useState(modelsHumanized[0]);
    const [segment, setSegment] = useState();
    const [conditions, setConditions] = useState([]);
    const [segments, setSegments] = useState([]);
    const [counts, setCounts] = useState([...INITIAL_STATE_COUNTS]);
    const [limit, setLimit] = useState(10);
    const [orderby, setOrderby] = useState();
    const [link, setLink] = useState();
    const [settingsIsOpen, setSettingsIsOpen] = useState(false)

    const chartDefaultValue = useMemo(() => {
        return userSettings ? CHART_OPTIONS.find((co) => co.value === userSettings.value.parameters.chart) : CHART_OPTIONS[0]
    }, [])

    const showAdvancedSettings = useMemo(() => {
        return advancedSettingsAvailability(chartType)
    }, [chartType])

    const onDatasourceChange = useCallback((value) => {
        setModel(value);
        setSegment(undefined);
        setConditions([]);
        setSegments([]);
        setLimit(10);
        setOrderby(undefined);
        setCounts([...INITIAL_STATE_COUNTS]);
    }, [])
    const onChartTypeChange = useCallback((o) => {
        onDatasourceChange(undefined);
        setLink();
        setChartType(o.value);
        setReportType(CHART_TO_REPORT[o.value]);
    }, [])

    useEffect(() => {
        toggleExtendedView();
        return () => { };
    }, []);


    useEffect(() => {
        if (exportMode && modelsHumanized) {

            // exportMode uses only 1 datasource
            // ChartType isn't used
            // so only segment and filters should be shown
            const matchingModel = modelsHumanized.find(m => m.name === exportDatasource)

            setModel(matchingModel)
        }
    }, [exportMode, modelsHumanized])

    useEffect(() => {
        const operation = CHART_TO_OPERATION[chartType];

        let models = [];

        let virtualSegment = {}
        if (exportMode) {
            // we always want the id of the datasource as a segment
            // to send it to the export service that requires a list of IDS
            virtualSegment.name = "id"
        }

        if (model) {
            models = [
                {
                    name: model.name,
                    ...(virtualSegment && { segment: virtualSegment }),
                    ...(segment && { segment }),
                    ...(segments && { segments }),
                    ...(conditions && { conditions }),
                },
            ];
        } else if (counts) {
            models = counts.map((c) => ({
                ...(c.model && {
                    name: c.model.name,
                    ...(c.conditions && { conditions: c.conditions }),
                }),
            }));
        }

        let chart = chartType
        if (exportMode) {
            chart = 'table'
        }

        setParameters({
            ...(chart && { chart }),
            ...(chart && { operation }), // groupby, count, top, bottom, min, max, ratio
            models,
            ...(limit && { limit }),
            ...(orderby && { orderby }),
            ...(link && { link }),
             // Nécessaire en back pour gérer correctement les dates choisies :
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
    }, [chartType, model, segment, counts, conditions, limit, orderby, segments, link]);

    useEffect(() => {
        if (userSettings) {

            const { parameters } = userSettings.value
            setReportType(CHART_TO_REPORT[parameters.chart])
            setChartType(parameters.chart)
            if (parameters.limit) {
                setLimit(parameters.limit)
            }
            if (parameters.orderby) {
                setOrderby(parameters.orderby)
            }
            if (parameters.link) {
                setLink(parameters.link)
            }
            if (CHART_TO_REPORT[parameters.chart] === REPORT_TYPES.RATIO) {

                const modelName1 = parameters.models[0].name
                const modelName2 = parameters.models[1].name
                const matchingModel1 = modelsHumanized.find(m => m.name === modelName1)
                const matchingModel2 = modelsHumanized.find(m => m.name === modelName2)
                setModel(undefined)
                setCounts([
                    {
                        model: matchingModel1,
                        conditions: parameters.models[0].conditions
                    },
                    {
                        model: matchingModel2,
                        conditions: parameters.models[1].conditions

                    }
                ])
            } else {
                const modelName = parameters.models[0].name
                const matchingModel = modelsHumanized.find(m => m.name === modelName)
                setConditions(parameters.models[0].conditions)
                setModel(matchingModel)
                if (parameters.models[0].segment) {
                    setSegment(parameters.models[0].segment)
                }
                if (parameters.models[0].segments) {
                    setSegments(parameters.models[0].segments)
                }

            }
        }
    }, [userSettings]);


    // KEEP - export fields shown in condition inputs
    // const toexport = modelsHumanized.filter(y => y.name === 'model')
    //       .reduce((fields, y) => {
    //           return y.linkedFields
    //               .map(e => ({name: e.name, label: e.humanizedName}))
    //       }, [])
    // console.log(JSON.stringify(toexport))

    if (exportMode) {
        return (<div>
            <ExportReport
                models={modelsHumanized}
                model={model}
                conditions={conditions}
                segment={segment}
                onConditionsChange={(cs) => setConditions(cs)}
                onDataSourceChange={() => { }}
                onSegmentChange={() => { }}
            />
        </div>)
    }


    return (
        <div>
            <pre>{/* {JSON.stringify(parameters, undefined, 2)} */}</pre>
            <div style={styles.width50}>
                <Select
                    name="chart"
                    label="Chart Type"
                    required
                    options={CHART_OPTIONS}
                    onChange={onChartTypeChange}
                    defaultValue={chartDefaultValue}
                ></Select>
            </div>

            {reportType === REPORT_TYPES.SIMPLE && (
                <SimpleReport
                    models={modelsHumanized}
                    model={model}
                    conditions={conditions}
                    segment={segment}
                    onConditionsChange={(cs) => setConditions(cs)}
                    onDataSourceChange={(o) => onDatasourceChange(o.value)}
                    onSegmentChange={(o) => setSegment(o.value)}
                />
            )}

            {reportType === REPORT_TYPES.RATIO && (
                <RatioReport models={modelsHumanized} counts={counts} onCountsChange={(c) => setCounts(c)} />
            )}

            {reportType === REPORT_TYPES.COUNT && (
                <CountReport
                    models={modelsHumanized}
                    model={model}
                    conditions={conditions}
                    onConditionsChange={(cs) => setConditions(cs)}
                    onDataSourceChange={(o) => onDatasourceChange(o.value)}
                />
            )}

            {reportType === REPORT_TYPES.TOP && (
                <TopReport
                    models={modelsHumanized}
                    model={model}
                    segment={segment}
                    conditions={conditions}
                    onConditionsChange={(cs) => setConditions(cs)}
                    onDataSourceChange={(o) => onDatasourceChange(o.value)}
                    limit={limit}
                    onLimitChange={(v) => setLimit(v)}
                    onSegmentChange={(o) => setSegment(o.value)}
                />
            )}
            {reportType === REPORT_TYPES.BOTTOM && (
                <BottomReport
                    models={modelsHumanized}
                    model={model}
                    segment={segment}
                    conditions={conditions}
                    onConditionsChange={(cs) => setConditions(cs)}
                    onDataSourceChange={(o) => onDatasourceChange(o.value)}
                    limit={limit}
                    onLimitChange={(v) => setLimit(v)}
                    onSegmentChange={(o) => setSegment(o.value)}
                />
            )}

            {reportType === REPORT_TYPES.ORDER_BY_DESC && (
                <OrderByReport
                    models={modelsHumanized}
                    model={model}
                    segments={segments}
                    conditions={conditions}
                    onConditionsChange={(cs) => setConditions(cs)}
                    onDataSourceChange={(o) => onDatasourceChange(o.value)}
                    limit={limit}
                    onLimitChange={(v) => setLimit(v)}
                    onSegmentsChange={(s) => setSegments(s)}
                    onOrderbyChange={(o) => setOrderby(o.value)}
                    orderby={orderby}
                />
            )}

            {reportType === REPORT_TYPES.ORDER_BY_ASC && (
                <OrderByReport
                    models={modelsHumanized}
                    model={model}
                    segments={segments}
                    conditions={conditions}
                    onConditionsChange={(cs) => setConditions(cs)}
                    onDataSourceChange={(o) => onDatasourceChange(o.value)}
                    limit={limit}
                    onLimitChange={(v) => setLimit(v)}
                    onSegmentsChange={(s) => setSegments(s)}
                    onOrderbyChange={(o) => setOrderby(o.value)}
                    orderby={orderby}
                />
            )}
            {showAdvancedSettings &&
                <div style={styles.accordion}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="adv-settings"
                            id="adv-settings"
                        >
                            <Typography style={styles.accordionTitle}>Open advanced settings</Typography>
                        </AccordionSummary>
                        <AccordionDetails  >
                            <BaseSelect name="links" label="Associate a link with your chart (shortcut)" options={LINKS} value={link} onChange={(e) => setLink(e.value)} />
                        </AccordionDetails>
                    </Accordion>
                </div>
            }
        </div>
    );
};

const validateCustomReport = (parameters) => {
    // there's should be at least one models
    const missingFields = []
    const { models, chart } = parameters

    if (CHART_TO_REPORT[chart] === REPORT_TYPES.RATIO) {
        const hasModels = models.every(model => model.name)
        if (!hasModels) {
            missingFields.push("You need to setup both queries for a Ratio report.")
        }
    }

    if (CHART_TO_REPORT[chart] === REPORT_TYPES.TOP || CHART_TO_REPORT[chart] === REPORT_TYPES.BOTTOM) {
        if (!parameters.limit) {
            missingFields.push("You need to set the number of elements you want to display in the table.")
        }
    }

    if (CHART_TO_REPORT[chart] === REPORT_TYPES.ORDER_BY_ASC || CHART_TO_REPORT[chart] === REPORT_TYPES.ORDER_BY_DESC) {
        if (!parameters.limit) {
            missingFields.push("You need to set the number of elements you want to display in the table.")
        }
        if (!parameters.orderby) {
            missingFields.push("You need to choose by which element you want to order the chart.")
        }
    }

    models.forEach((model, i) => {
        if (model.conditions) {
            // conditions must have a target, an operator and a value
            model.conditions.forEach((condition, idx) => {
                const { value, field, op } = condition
                if (!field || !op || (!value && !OPERATORS_WITHOUT_VALUE.includes(op))) {
                    missingFields.push(`Missing data in filter #${idx + 1} for query ${i} (${model.name})`);
                }
            })
        }
    })

    return missingFields
}

export { CustomReport, validateCustomReport, BaseSelect };

const styles = {
    width50: {
        width: "50%",
        marginRight: "10px"
    },
    flex: {
        display: "flex"
    },
    condContainer: {
        display: "block"
    },
    cond: {
        width: "30%",
        marginRight: "32px",
        marginBottom: "20px",
        display: "inline-block",
        minWidth: "352px", // min width of date range component
        verticalAlign: "top",
    },
    queryBox: {
        display: "inline-block",
        padding: '20px',
        cursor: "pointer",
        opacity: "0.5",
        marginTop: "10px",
        borderRadius: "10px",
        background: "rgba(0, 0, 0, 0.1)",
        fontSize: "16px",
        width: "75px"
    },
    ratioSeparator: {
        fontSize: "38px",
        display: "inline-block",
        margin: "0 20px"
    },
    queryClickto: {
        marginTop: "5px",
        opacity: "0.6",
        fontSize: "12px"
    },
    queryTitle: {
        fontSize: "18px",
        fontWeight: "bold",
        margin: "30px 0 5px 0"
    },
    queryBoxSelected: {
        opacity: '1',
    },
    reportExplainer: {
        marginTop: '10px',
        marginBottom: '20px',
        opacity: '0.6'
    },
    accordion: {
        width: '40%',
        marginTop: '10px',
        marginBottom: '20px'
    },
    accordionTitle: {
        fontWeight: 'bold'
    }

}
