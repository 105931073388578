import React, {Component} from "react";
import Http from '../../Http';
import Alert from "../../Alert";
import Modal from "../../Modal";
import TextField from "@material-ui/core/TextField";
import {APIResource} from "../../APIResource/APIResource";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ActionButton } from "../../../Components/Modal/ActionButton";
import { ButtonBar } from "../../../Components/Modal/ButtonBar";
import {openLoadingModal, validateIds} from "../bulkUtils";

export class BulkAddCertifications{
    getId(){
        return 'bulkAddCertifications'
    }

    getLabel(){
        return 'Certify'
    }

    setParameters(params){
        this.parameters = params;
    }

    async run(ids, params = null){
        this.ids = ids;

        const errorMessage =
            this.parameters.forbiddenAccessMessage || "You don't have the rights to certify all this models.";

        openLoadingModal();

        if (
            !(await validateIds(
                ids,
                this.parameters.itemAccessCondition,
                this.parameters.resource,
                this.parameters.itemAccessConditionEntityFields,
                this.parameters.bulkValidateRoles
            ))
        ) {
            Modal.close();
            Alert.show({ message: errorMessage, type: 'warning' });
            return;
        }


        //une campagne est en cours sur les modèles. Erreur : "No ongoing campaign on the selected model(s)"
        const campaignInProgress = await Http.post(`${this.parameters.resource.resourceId}/bulk/validateIdsToCertify`, {
            ids: ids,
        });
        if(Array.isArray(campaignInProgress['hydra:member']['allow_bulk_action'])){
            Modal.close();
            Alert.show({ message: campaignInProgress['hydra:member']['allow_bulk_action'].join("\n"), type: 'warning' });
            return;
        }


        Modal.open({
            title: 'Bulk Certification',
            content: <AddCertificationForm fields={this.parameters.fields} ids={this.ids}/>
        })
    }
}

export class AddCertificationForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            note: '',
            progress: false,
        };
    }

    save(){
        this.setState({progress: true});
        Http.post('models/addCertifications', {
            ids: this.props.ids,
            note: this.state.note,
        }).then((response) => {
            const modelResource = new APIResource({
                id: 'models',
                name: 'Models',
            });
            if (response.status === 'OK') {
                response['hydra:member'].forEach(model => modelResource.setItem(model));
            }
            if (this.props.ids.length === 1 && this.props.resourceDetailComponent) {
                this.props.resourceDetailComponent.entity = modelResource.getObservableItem(this.props.ids[0]);
                this.props.resourceDetailComponent.forceUpdate();
            }
            Alert.show({
                message:
                    this.props.ids.length > 1
                        ? 'Bulk certification successfully processed.'
                        : 'Certification successfully processed.',
                type: "success"
            });
            this.setState({progress: false});
            Modal.close();
        });
    }

    render() {
        const models = this.props.ids.length > 1
            ? "these models"
            : this.props.modelName
                ? <span>the <code>{this.props.modelName}</code> model</span>
                : "this model";
        return (
            <div className="container">
                <div>
                    <TextField
                        id={'note'}
                        label={"Note"}
                        fullWidth={true}
                        multiline={true}
                        onChange={(event) => {this.setState({note: event.target.value})}}
                        value={this.state.note}
                    />
                </div>
                <ButtonBar>
                    <ActionButton
                        onClick={this.save.bind(this)} 
                        disabled={this.state.progress}
                        loading={this.state.progress}
                    >
                        Certify {models}
                    </ActionButton>
                </ButtonBar>
            </div>
        )
    }
}