import React from 'react';
import {APIResource} from "../Services/APIResource/APIResource";
import {DocumentAddForm, DocumentList} from "../Components/Display/DocumentManager/DocumentManager";
import {BulkDelete} from "../Services/BulkActions/BulkDelete/BulkDelete";
import {ROLE} from "../Services/User/User";
import ModalDocument from "../Services/ModalDocument";
import ParameterStore, {userHasRoleADMIN} from "../Store/ParameterStore";

export const ApiResourceDefaultParams = {
    id: 'documents',
    name: 'Procedures',
    icon: 'book',
    fieldForTitle: 'title',
    permanentFilters: {
        procedures: true
    }
};

export default class ProcedureAdmin {
    constructor(){
        this.configure();
    }

    configure(){
        let procedures = new APIResource(ApiResourceDefaultParams);
        procedures
            .setFields({
                id: {title: 'ID'},
                title: {
                    title: 'Document',
                    edit: () => (
                        <DocumentAddForm
                        />
                    ),
                    displayList: (field, value, entity) => (
                        <DocumentList values={[entity]} />
                    ),
                    width: 500
                },
            })
            .setLayout({
                tabs: {
                    'Procedure' : {
                        rows: [
                            {
                                panels: {
                                    'Properties': {
                                        cols: 12,
                                        fields: ['title']
                                    }
                                }
                            }
                        ]
                    },
                }
            })
            .genInsertView({
                routeAccessControl: [ROLE.ADMIN],
                postSaveRedirect: 'list',
                fields: ['title'],
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'Document';
                },
                insertButtonAction: (resourceComponent, event) => {
                    ModalDocument.open({
                        title: "New procedure",
                        content: (
                            <DocumentAddForm
                                fileTypes={null}
                                allowedCategory={false}
                                multiple={false}
                                onSave={(document, callback) => {
                                    let singleDocument = document.documents[0];
                                    procedures.apiPostFile({
                                        id: singleDocument.id,
                                        "@type": 'Document',
                                        title: singleDocument.title,
                                        type: document.type,
                                        category: ParameterStore('DOCUMENT_CATEGORY_PROCEDURE'),
                                        file: singleDocument.documentFile,
                                        externalUrl: document.externalUrl,
                                        description: document.description,
                                        comment: document.comment,
                                    });

                                    procedures.apiGetCollection({ forceReload: true });

                                    ModalDocument.close();
                                }}
                            />
                        ),
                    });
                }
            })
            .genListView({
                menuItem: { title: 'Procedures' },
                permanentFilters: { "procedures": true },
                showDefaultAddButton: true
            })
        ;

        if(userHasRoleADMIN()) {
            procedures.addBulkAction(BulkDelete, {
                resource: procedures,
                icon: 'trash-alt',
                entityType: 'procedure',
                entityTypeLabel: 'Procedure',
                softDelete: false,
                forbiddenAccessMessage: "You don't have the rights to delete all this procedures."
            });
        }
    }
}
