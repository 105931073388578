import React, { PureComponent } from 'react';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import {Paper} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DateFormatter from "../../../Services/DateFormatter";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';


const COLORS = ['#00838A', '#E74D5D'];

export default class ValidModelsByModelTypesForDate extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data : [],
            date: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.data && (this.props.data.validModelsByModelTypesForDate
            && (this.state.data.length === 0 || this.props.data.validModelsByModelTypesForDate !== prevProps.data.validModelsByModelTypesForDate))){

            let dateList = Object.keys(this.props.data.validModelsByModelTypesForDate);
            this.setState({date: dateList[0]});
            this.setState({data: this.formatData(this.props.data.validModelsByModelTypesForDate)});
        }
    }

    formatData(rawData){
        let datas = [];
        if(rawData.length === 0){
            return null;
        }

        for(let date in rawData){
            for(let modelType in rawData[date]){
                let row = rawData[date][modelType];
                datas.push({name: row.modelType, 'Validated': row.validated, 'Not validated': row.not_validated});
            }
        }
        return datas;
    }

    updateValue(reportingName, value){
        if(this.props.onChangeDate){
            this.props.onChangeDate({'name': reportingName, 'request': value});
            this.setState({data: []});
        }
    }

    render() {
        return (
            <div className="container" style={styles.margin}>
                <Paper>
                    <AppBar position="static" className="background-lowlight background-linear-gradient">
                        <Toolbar style={styles.toolBar}>
                            <Typography variant="h6">
                                Validations by model types
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={styles.date}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="startDate"
                                label="Date"
                                value={ this.state.date ? this.state.date : null }
                                onChange={(date) => { this.updateValue('validModelsByModelTypesForDate', DateFormatter.dateToIso8601(date)) }}
                                format="dd MMMM yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div style={styles.chart}>
                        <ResponsiveContainer>
                            <BarChart
                                data={this.state.data}
                                margin={{
                                    top: 5, right: 0, left: 0, bottom: 130,
                                }}
                            >
                                <CartesianGrid stroke="#f5f5f5" />
                                <XAxis interval={0} dataKey="name" angle={-60} textAnchor='end' tick={{'fontSize': 12}}/>
                                <YAxis />
                                <Tooltip />
                                <Legend wrapperStyle={{ fontSize: 12, top : -25 }} layout="horizontal" verticalAlign="top"/>
                                <Bar dataKey="Validated" fill={COLORS[0]} barSize={20}/>
                                <Bar dataKey="Not validated" fill={COLORS[1]} barSize={20}/>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Paper>
            </div>
        );
    }
}
const styles = {
    toolBar: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    container:{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    chart:{
        width: '98%',
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 10,
        height: 400,
    },
    date:{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 0,
        marginBottom: 0,
        width: 200,
        paddingBottom: 50,
    },
    margin: {
        paddingRight: 0,
        paddingBottom: 0,
    }
}