import React, {Component} from 'react';
import {observer} from "mobx-react";
import {File} from "../Forms/File/File";
import Button from "@material-ui/core/Button";
import Alert from "../../Services/Alert";
import Http from "../../Services/Http";
import {TableDisplay} from "../Display/TableDisplay/TableDisplay";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import String from "../../Services/String";
import {DisplayTextField} from "../Display/DisplayTextField/DisplayTextField";
import {Link} from "react-router-dom";
import AppBarStore from "../../Store/AppBarStore";
import User from "../../Services/User/User";
import {ActionButton} from '../Modal/ActionButton';
import {ButtonBar} from '../Modal/ButtonBar';
import {Icon} from '@material-ui/core';

class Import extends Component {

    constructor(props) {
        super(props);
        AppBarStore.title = 'Model import';
        this.save = this.save.bind(this);
        this.saveAll = this.saveAll.bind(this);
        this.updateAll = this.updateAll.bind(this);
        this.abortAll = this.abortAll.bind(this);
        this.apply = this.apply.bind(this);
        this.state = {
            file: null,//File to upload
            fileName: null,//FileName to use on applying duplication progress
            progress: false,
            duplicates: null,
            duplicatesToProceed: {},
            default: false,
            message: null,
            messageStatus: null,
            running: undefined,
        };
    }

    async componentDidMount() {
        let response = await Http.get('import/model/running', {cache: false}) || {};
        if (response.status === 'OK') {
            this.setState({running: response.running});
        } else {
            console.error("unexpected response from import/model/running", response);
        }
    }

    onDrop(files, error) {
        if (error === false && files.length === 1) {
            this.setState({file: files[0]});
        }
    }

    selectDuplicate(line, target) {
        let duplicatesToProceed = this.state.duplicatesToProceed;
        if (target.checked === true && target.value !== 'false') {
            duplicatesToProceed[line] = target.value;
        } else {
            delete duplicatesToProceed[line];
        }
        this.setState({duplicatesToProceed: duplicatesToProceed});
    }

    save() {
        if (this.state.file === null) {
            Alert.show({message: 'Please drop or select a file', type: 'error'});
            return;
        }
        this.setState({progress: true, message: null, messageStatus: null});
        let logoutInterval = setInterval(() => {
            User.lastActivity = new Date();
        }, 1000);
        Http.postFile('import/model', {file: this.state.file}).then((response) => {
            if (response.status === 'ERROR') {
                this.setState({message: response.message, messageStatus: 'error'});
            } else if (response.status === 'PENDING') {
                this.setState({message: response.message, messageStatus: 'success'});
            } else if (response.status === 'OK') {
                // Using message from response :
                this.setState({message: response.message, messageStatus: 'success', running: true});
                if (response.duplicates && response.duplicates.length > 0) {
                    this.setState({fileName: response.fileName, duplicates: response.duplicates});
                }
            }
            this.setState({progress: false});
            clearInterval(logoutInterval);
        }).catch((_error) => {
            this.setState({progress: false, message: null, messageStatus: null});
            Alert.show({message: 'Something went wrong, please try again', type: 'error'});
            clearInterval(logoutInterval);
        });
    }

    saveAll() {
        let duplicatesToProceed = {};
        for (let i in this.state.duplicates) {
            duplicatesToProceed[this.state.duplicates[i].line] = 'new';
        }
        this.setState({duplicatesToProceed: duplicatesToProceed});
    }

    updateAll() {
        let duplicatesToProceed = {};
        for (let i in this.state.duplicates) {
            duplicatesToProceed[this.state.duplicates[i].line] = this.state.duplicates[i].targets[0].id;
        }
        this.setState({duplicatesToProceed: duplicatesToProceed});
    }

    abortAll() {
        let duplicatesToProceed = {};
        for (let i in this.state.duplicates) {
            duplicatesToProceed[this.state.duplicates[i].line] = 'false';
        }
        this.setState({duplicatesToProceed: duplicatesToProceed});
    }

    apply() {
        this.setState({progress: true, message: null, messageStatus: null});
        let logoutInterval = setInterval(() => {
            User.lastActivity = new Date();
        }, 1000);
        Http.postFile('import/model', {
            fileName: this.state.fileName,
            duplicatesToProceed: this.state.duplicatesToProceed
        }).then((response) => {
            if (response.status === 'ERROR') {
                this.setState({message: response.message, messageStatus: 'error'});
            } else if (response.status === 'PENDING') {
                this.setState({message: response.message, messageStatus: 'success'});
            } else if (response.status === 'OK') {
                // For async import, we use response message :
                this.setState({message: response.message, messageStatus: 'success', duplicates: null});
            }
            this.setState({progress: false});
            clearInterval(logoutInterval);
        }).catch((_error) => {
            this.setState({progress: false, message: null, messageStatus: null});
            Alert.show({message: 'Something went wrong, please try again', type: 'error'});
            clearInterval(logoutInterval);
        });
    }

    renderDuplicates() {
        let render = [];
        for (let [index, elm] of Object.entries(this.state.duplicates)) {
            render.push(
                <div className={"import_duplicate_item"} key={index}>
                    <Grid key={elm['line']} item>
                        <Paper>
                            <form>
                                <h2>Model at line {elm['line']}</h2>
                                <TableDisplay
                                    filter={false}
                                    rows={[elm['from']]}
                                    cols={[
                                        {label: 'Functional ID', field: 'functionalID'},
                                        {label: 'Risk category', field: 'riskCategory'},
                                        {label: 'Micro risk category', field: 'microRiskCategory'},
                                        {label: 'Name', field: 'name'},
                                        {label: 'Model owner', field: 'modelOwner'},
                                        {label: 'Model type', field: 'modelType'},
                                    ]}
                                    actions={() => {
                                        return (
                                            <div style={{display: 'block'}}>
                                                <div className="form-check">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={'model_' + elm['line']}
                                                            value="false"
                                                            checked={this.state.duplicatesToProceed[elm['line']] === undefined || this.state.duplicatesToProceed[elm['line']] === 'false' ? true : false}
                                                            className="form-check-input abort"
                                                            onChange={(event) => {
                                                                this.selectDuplicate(elm['line'], event.target)
                                                            }}
                                                        />
                                                        Abort importation of this model
                                                    </label>
                                                </div>
                                                <br/>
                                                <div className="form-check">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            name={'model_' + elm['line']}
                                                            value={'new'}
                                                            className="form-check-input new"
                                                            onChange={(event) => {
                                                                this.selectDuplicate(elm['line'], event.target)
                                                            }}
                                                            checked={this.state.duplicatesToProceed[elm['line']] === 'new' ? true : false}
                                                        />
                                                        Save as new model
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    }}/>
                                <h2>Potential duplicates</h2>
                                <TableDisplay filter={false} rows={elm['targets']} cols={[
                                    {
                                        label: 'Functional ID',
                                        field: 'functionalID',
                                        display: (field, value, entity) => {
                                            return <Link to={'/resource/models/' + entity.id + '/detail'}
                                                         target="_blank">
                                                <DisplayTextField plainText={true} fieldName={field.title}
                                                                  value={value ? value : ''}/>
                                            </Link>
                                        }
                                    },
                                    {label: 'Risk category', field: 'riskCategory'},
                                    {label: 'Micro risk category', field: 'microRiskCategory'},
                                    {label: 'Name', field: 'name'},
                                    {label: 'Model owner', field: 'modelOwner'},
                                    {label: 'Model type', field: 'modelType'},
                                ]}
                                              actionLabel={"Choose one model"}
                                              actions={(value) => {
                                                  return (
                                                      <div className="form-check">
                                                          <label>
                                                              <input
                                                                  type="radio"
                                                                  name={'model_' + elm['line']}
                                                                  value={value.id}
                                                                  className="form-check-input model"
                                                                  onChange={(event) => {
                                                                      this.selectDuplicate(elm['line'], event.target)
                                                                  }}
                                                                  checked={this.state.duplicatesToProceed[elm['line']] === value.id ? true : false}
                                                              />
                                                              Update this model with datas from line {elm['line']}
                                                          </label>
                                                      </div>
                                                  )
                                              }}/>
                            </form>
                        </Paper>
                    </Grid>
                </div>
            );
        }
        return (
            <div>
                <h2>Some duplicates found</h2>
                <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Button variant="contained" color="secondary" className="save button-general button-table"
                            onClick={this.saveAll.bind(this)}>
                        Save all as new
                    </Button>
                    <Button variant="contained" color="secondary" className="save button-general button-table"
                            onClick={this.updateAll.bind(this)}>
                        Update all
                    </Button>
                    <Button variant="contained" color="secondary" className="save button-general button-table"
                            onClick={this.abortAll.bind(this)}>
                        Abort all
                    </Button>
                    <Button variant="contained" className="save button-general success" onClick={this.apply.bind(this)}>
                        <i className={"fas fa-check"}></i> Apply these updates
                    </Button>
                </div>
                {render}
                <Button variant="contained" className="save button-general success" onClick={this.apply.bind(this)}>
                    <i className={"fas fa-check"}></i> Apply these updates
                </Button>
            </div>
        );
    }

    render() {
        return (
            <Grid className={'import container'}>
                <div className='import_progress_container'>
                    {this.state.running === undefined && <p>Checking if an import is already running</p>}
                    {this.state.running === true && this.state.message === null &&
                        <p><Icon class="fas fa-exclamation-triangle"/> An import is already running</p>}
                </div>

                {this.state.message && (
                    <div
                        className={'alert alert-' + (this.state.messageStatus ? this.state.messageStatus : 'primary')}
                        role="alert"
                    >
                        {String.nlToBr(this.state.message)}
                    </div>
                )}
                {this.state.progress || this.state.running === undefined || this.state.running ? (
                    <div className='import_progress_container'>
                        <CircularProgress/>
                        <>
                            {this.state.progress && 'Uploading ....'}
                            {(this.state.running === undefined || this.state.running) && (
                                <ButtonBar>
                                    <Link to={'/resource/models/list'}>
                                        <ActionButton>Back to Inventory</ActionButton>
                                    </Link>
                                </ButtonBar>
                            )}
                        </>
                    </div>
                ) : this.state.duplicates ? (
                    this.renderDuplicates()
                ) : (
                    <div>
                        <File
                            fileTypes={['xlsx']}
                            callback={(files, error) => {
                                this.onDrop(files, error);
                            }}
                            maxFile={1}
                        />
                        <ButtonBar>
                            <ActionButton onClick={this.save.bind(this)}>
                                Import
                            </ActionButton>
                        </ButtonBar>
                    </div>
                )}
            </Grid>
        );
    }
}

export default observer(Import);