import Button from '@material-ui/core/Button';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import React from 'react';
import RelationLinks from "../../../Components/AdditionalButtons/RelationLinks";
import Alert from "../../Alert";
import APIResourceStore from "../../../Store/APIResourceStore";
import {userHasRoleADMIN, userHasRoleMRM} from "../../../Store/ParameterStore";
import {CONTEXT_DELETE, CONTEXT_FILTER} from '../APIResource';
import FieldProviderStore from '../FieldProviders/__FieldProviderStore';

export function hasRightsForOperation(operation, { resource, entity, hasRole }) {
    if (!resource.operations[operation]) {
        return false;
    }

    //By pass pour admin et mrm
    if(userHasRoleADMIN() && operation !== CONTEXT_DELETE){
        return true;
    }

    const itemAccessCondition =
        resource.operations[operation].itemAccessCondition;
    if (itemAccessCondition) {
        return itemAccessCondition({ ...entity });
    }
    const routeAccessControl = resource.operations[operation].routeAccessControl;
    if (routeAccessControl) {
        if (!hasRole) {
            console.warn('MISSING HasRole for operation rights', hasRole);
            return false;
        }

        return hasRole(routeAccessControl);
    }
    return true;
}

export function hasRightsForField(field, { operation, entity, hasRole }) {
    let _operation = operation;
    if (!field.rights && !field.apiRights) {
        return true;
    }
    const rightsDefinition = _.merge(field.apiRights, field.rights);
    // if provided operation is not defined look for parent
    if (rightsDefinition[_operation] === undefined) {
        // if provided operation is not defined look for parent in instertAndEdit
        if (_operation === 'insert' || _operation === 'edit') {
            _operation = 'insertAndEdit';
            // if insertAndEdit is not defined fallback to all
            if (rightsDefinition[_operation] === undefined) {
                _operation = 'all';
            }
        }
        if (_operation === 'detail') {
            _operation = 'display';
            // if display is not defined fallback to all
            if (rightsDefinition[_operation] === undefined) {
                _operation = 'all';
            }
        } else {
            _operation = 'all';
        }
        if (field.rights === undefined || field.rights[operation] === undefined) {
            return true;
        }
    }

    // if definition is a function execute it, else use the role system
    const condition = rightsDefinition[_operation];
    if (typeof condition === 'boolean') {
        return condition;
    } else if (typeof condition === 'function') {
        return condition({ field, entity });
    } else if (hasRole) {
        return hasRole(condition);
    }
    // if we have an operation in the rights but no way to treat it block access by default
    return false;
}

export function renderButton(button, options = {
    index: Date.now()
}) {
    const { resource,
        index = Date.now(), entity, operation, resourceEditComponent } = options;

    if (!button.onClick && (button.links || button.link)) {

        if(button.doNotDisplayInModal && resourceEditComponent.props.inModal){
            return;
        }

        if(button.links && button.links.length === 1){
            button.link = button.links[0].link;
            delete button.links;
        }
        if(!button.links && button.link){
            return (
                <Link
                    to={button.link}
                    className={"item" + options.loading ? " loading" : ""}
                    key={'additional-action-button-' + index}
                >
                    <Button
                        variant="contained"
                        style={button.style}
                        className={'tooltip ' + (button.class || ' ') + (button.className || ' ')}
                    >
                        <i className={options.loading && (button.spinner && button.spinner === options.spinner) ? 'fa fa-circle-notch text-primary fa-rotate fa-spin' : 'fa fa-' + button.icon}></i>
                        <span className="tooltiptext">{button.tooltip}</span>
                    </Button>
                </Link>
            );
        }else{
            return <RelationLinks
                key="needs-a-key"
                links={button.links}
                tooltip={button.tooltip}
            />
        }
    }
    else {
        return (
            <Button
                variant="contained"
                style={button.style}
                key={index}
                className={'tooltip ' + (button.class || ' ') + (button.className || ' ')}
                onClick={() => {
                    if(operation === "detail" && resourceEditComponent.state.loadingEntity){
                        return Alert.show({ message : 'Some data are loading. Please try again in a few seconds.', type: 'warning'});
                    }else{
                        return button.onClick({ resource, index, entity, operation })
                    }
                }}
            >
                <i className={options.loading && (button.spinner && button.spinner === options.spinner) ? 'fa fa-circle-notch text-primary fa-rotate fa-spin' : 'fa fa-' + button.icon}></i>
                <span className="tooltiptext">{button.tooltip}</span>
            </Button>
        );
    }
}
export function renderAdditionalActionButtons(resource, entity, operation, resourceEditComponent, loading = false, spinner = false) {
    if (entity) {
        let additionalButtons =
            resource.operations[operation] &&
                resource.operations[operation].additionalActionButtons
                ? resource.operations[operation].additionalActionButtons(
                    entity,
                    resource,
                    resourceEditComponent,
                    new URLSearchParams(window.location.search),
                    loading
                )
                : [];
        let buttons = [];
        if(!additionalButtons){ return null; }
        buttons = additionalButtons.map((button, index) => renderButton(button, { index, resource, entity, operation, loading, spinner, resourceEditComponent }));

        return buttons || null;
    }
    return null;
}

export function renderAdditionalLinkButton(resource, entity, operation, resourceEditComponent) {
    if (entity) {
        let additionalButtons =
            resource.operations[operation] &&
                resource.operations[operation].additionalLinkButton
                ? resource.operations[operation].additionalLinkButton(
                    entity,
                    resource,
                    resourceEditComponent
                )
                : [];
        let buttons = [];
        if(!additionalButtons){ return null; }
        buttons = additionalButtons.map((button, index) => renderButton(button, { index, resource, entity, operation, resourceEditComponent }));

        return buttons || null;
    }
    return null;
}

export function renderAdditionalListActionButtons(resource, entity) {
    const operation = 'list';
    if (entity) {
        let additionalButtons =
            resource.operations[operation] &&
                resource.operations[operation].additionalListActionButtons
                ? resource.operations[operation].additionalListActionButtons(
                    entity,
                    resource
                )
                : [];
        let buttons = [];
        if(!additionalButtons){ return null; }
        buttons = additionalButtons.map((button, index) => renderButton(button, { index, resource, entity, operation }));

        return buttons || null;
    }
    return null;
}

export function getDetailRoute(path){
    let route = path.split('/');
    if(!(Array.isArray(route)
    && route.length > 0
    && route[1] === 'resource'
    && typeof route[2] === 'string'
    && typeof route[4] === 'string'
    && route[4] === 'detail'
    && !isNaN(parseInt(route[3], 10))
    && APIResourceStore.resources[route[2]]
    )){
        return null;
    }
    return {
        'resourceId': route[2],
        'id': parseInt(route[3], 10)
    };
}

export const firstLetterUppercase = (str) => str.charAt(0).toUpperCase() + str.slice(1, str.length);

export const genEntitySelectInstanceId = (instanceId) => `entity_select_${instanceId}`;

export const genEntitySelectContextInstanceId = (instanceId, context = CONTEXT_FILTER) => `entity_select_${instanceId}_${context}`;

/**
 * @param {import('../APIResource').APIResourceField} field
 * @param {*} value
 * @returns {{field: string, detail: string}|false} - Renvoie un objet qui détaille les erreurs ou false si aucune erreur
 */
export const fieldTypeFormatValidate = (field, value) => {

    if (typeof FieldProviderStore[field.type] !== 'undefined'){
      // format validation
      if (typeof FieldProviderStore[field.type].typeFormatValidate === 'function' && !field.disableTypeFormatValidation ){
        if (typeof value !== 'undefined' && value && value !== ''){
          let stringValue = String(value);
          const [hasError, errorText] = FieldProviderStore[field.type].typeFormatValidate(stringValue);
          if (hasError){
              return {
                  field: field.title,
                  detail: errorText,
              };
          }
        }
      }
      // maxLength validation 
      if (typeof FieldProviderStore[field.type].typeLengthValidate === 'function' && field.maxLength ){
        const [hasError, errorText] = FieldProviderStore[field.type].typeLengthValidate(value, field.maxLength);
        if (hasError){
            return {
                field: field.title,
                detail: errorText,
            };
        }
      }
    }     
    return false;
}

