import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { NavLink } from 'react-router-dom';

/**
 * children contient les sous éléments.
 *
 * @param {*} props
 */
function ListItemLink(props) {
    const { to, className, children } = props;

    /**
     * On utilise ça pour pouvoir utiliser le Router avec Material-ui
     * https://material-ui.com/guides/composition/#caveat-with-inlining
     */
    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <NavLink exact to={to} ref={ref} {...itemProps} />
            )),
        [to]
    );

    return (
        <ListItem
            button
            component={renderLink}
            className={className}
            children={children}
        />
    );
}

/**
 * NB: children ne contient pas les sous élements du parent, mais les sous éléments
 * du composant (ie les icones, textes ...)
 */
function ListItemParent(props) {
    const { className, onClick, children } = props;

    return (
        <ListItem
            button
            className={className}
            onClick={onClick}
            children={children}
        />
    );
}

const MainMenuItemComponent = (props) => {
    const { className, onClick, to, children } = props;

    // Le parent peut utiliser onClick pour replier ses enfants
    // Attention il ne faut pas déclarer de lien pour un parent !
    if (!to) {
        return <ListItemParent {...{ className, children, onClick }} />;
    }

    // ListItem spécifique si on doit utiliser le Router
    return <ListItemLink {...{ className, children, to }} />;
};

export default MainMenuItemComponent;
