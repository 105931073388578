import React, {Component} from "react";
import Modal from '../../Modal';
import TextField from '@material-ui/core/TextField/index';
import '../BulkEdit/bulk-edit.css';
import Http from '../../Http';
import Alert from '../../Alert';
import APIResourceStore from '../../../Store/APIResourceStore';
import FieldProviderStore from "../../APIResource/FieldProviders/__FieldProviderStore";
import FormControl from "@material-ui/core/FormControl";
import {userHasRoleMRM} from "../../../Store/ParameterStore";
import {ActionButton} from "../../../Components/Modal/ActionButton";
import {CircularProgress} from "@material-ui/core";
import { openLoadingModal, validateIds } from "../bulkUtils";

export class NewUse {
    getId(){
        return 'bulkNewUse'
    }

    getLabel(){
        return 'New use'
    }

    /**
     * 
     * @param {import('../../APIResource/APIResource').APIResourceBulkParams} params
     */
    setParameters(params){
        this.parameters = params;
    }

    async run(ids, parent = null){
        this.ids = ids;

        const errorMessage = this.parameters.forbiddenAccessMessage || "You don't have the rights to create new Uses.";

        openLoadingModal();

        if (
            !(await validateIds(
                ids,
                this.parameters.itemAccessCondition,
                this.parameters.resource,
                this.parameters.itemAccessConditionEntityFields,
                this.parameters.bulkValidateRoles
            ))
        ) {
            Modal.close();
            Alert.show({ message: errorMessage, type: 'warning' });
            return;
        }
        
        Modal.open({
            title: this.getLabel(),
            content: <NewUseForm
                fields={this.parameters.fields}
                ids={this.ids}
                parent={parent}
                resource={APIResourceStore.resources.model_uses}
            />,
        })
    }
}

export class NewUseForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            fields: {},
            changeLogComment: '',
            progress: false,
        }

        this.changedFields = {};
    }

    handleChange(fieldId, value){
        if(fieldId === 'changeLogComment') {
            this.setState({changeLogComment: value});
        } else {
            let fields = this.state.fields;
            fields[fieldId] = value;
            this.setState({fields: fields});
        }
        if (value === '' || value === null || (Array.isArray(value) && !value.length)) {
            if (userHasRoleMRM()) {
                this.changedFields[fieldId] = value;
            } else {
                delete this.changedFields[fieldId];
            }
        }
        else {
            this.changedFields[fieldId] = value;
        }
    }

    save(){
        this.setState({progress: true});
        Http.post('models_new_uses/bulk/new', {
            ids: this.props.ids,
            fields: this.changedFields,
            changeLogComment: this.state.changeLogComment
        }).then((response) => {
            this.props.resource.apiGetCollection({forceReload: true})
                .then(() => this.props.parent.setState({selection: [], progress: false}));
            Alert.show({message: response.message || 'Bulk Edit successfully processed.', type: "success"});
            Modal.close();
        });
    }

    genForm(){
        let fields = [];
        for(let i in this.props.fields){
            let field = this.props.fields[i];
            const fieldId = i;
            let component = null;
            field.issueButton = false;
            field.required = false;

            if(field.edit){
                component = field.edit(field, this.state.fields[fieldId], (value) => {this.handleChange(fieldId, value)}, this.state.fields, {});
            }
            else if(FieldProviderStore[field.type] && FieldProviderStore[field.type].getEdit){
                component = FieldProviderStore[field.type].getEdit(field, this.state.fields[fieldId], (value) => {this.handleChange(fieldId, value)}, this.state.fields, {});
            }
            else if(!field.component){
                if(this.state.fields[i] === undefined){
                    this.state.fields[i] = '';
                }
                component =
                    <div key={i}>
                        <FormControl>
                            <TextField
                                id={'field-' + i}
                                label={field.title}
                                className={'field-' + i}
                                onChange={(event) => {this.handleChange(i, event.target.value)}}
                                value={this.state.fields[i]}
                            />
                        </FormControl>
                    </div>
                ;
            }
            else{
                component = field.component((value) => {this.handleChange(i, value)});
            }
            fields.push(<div className="field" key={i}>{component}</div>);
        }

        let bottomFields = []
        Object.entries({
            changeLogComment: {title: 'Justification of the data update', type: 'text', rows: 3,  display: () => null, displayList: () => null}
        }).map(([ fieldId, field ]) => {
            this.state.changeLogComment = this.state.changeLogComment || '';
            bottomFields.push(<div className="field changelog" key={fieldId}>
                {FieldProviderStore[field.type].getEdit(field, this.state.changeLogComment, (value) => {
                    this.handleChange(fieldId, value)
                }, this.state.fields)}
            </div>)
        });

        return(
            <div className="bulk-edit">

                <div className={"alert alert-info"} role="alert">
                    You are about to create a new use with all the selected models.
                </div>

                <div className="fields main-fields"> {/* grand padding pour éviter que la dernière entity select ne soit invisible */}
                    {fields}
                </div>

                <div className="fields">
                    {bottomFields}
                    <ActionButton
                        onClick={this.save.bind(this)}
                        disabled={this.state.progress}
                    >
                        Create the use for {this.props.ids.length} {this.props.resource.resourceId} {this.state.progress && <CircularProgress size={20} />}
                    </ActionButton>
                </div>

            </div>
        );
    }

    render(){
        return(this.genForm());
    }
}
