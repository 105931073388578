import React from 'react';
import PropTypes from 'prop-types';
import { ModalContent } from '../../Modal/ModalContent';
import { Typography } from '@material-ui/core';
import { ActionButton } from '../../Modal/ActionButton';
import { ButtonBar } from '../../Modal/ButtonBar';
import ModalComplementary from '../../../Services/ModalComplementary';
import APIResourceStore from '../../../Store/APIResourceStore';
import ParameterStore from '../../../Store/ParameterStore';
import { FINDING_STATUS_CLOSED, FINDING_STATUS_DELETED, FINDING_STATUS_DISMISSED } from '../../../Admin/FindingAdmin';

export const IncoherentModelsInformationModal = (props) => {
    const { onConfirm, onCancel, mraExists = false } = props;

    const message =
        'Regarding the automatic scoring of the MRA subdimensions associated to notices, we invite you ' +
        (mraExists
            ? 'to issue a notice with the same number of models as the issued MRA of the review'
            : 'to split your MRA to reflect in the most accurate way the findings/notices you issued.');

    const onClickCancel = () => {
        ModalComplementary.close();
        onCancel?.();
    };

    const onClickConfirm = () => {
        ModalComplementary.close();
        onConfirm?.();
    };

    return (
        <ModalContent>
            <Typography component={'p'}>{message}</Typography>
            <ButtonBar>
                <ActionButton onClick={onClickCancel} disabled={!onCancel}>Cancel</ActionButton>
                <ActionButton onClick={onClickConfirm}>Confirm</ActionButton>
            </ButtonBar>
        </ModalContent>
    );
};
IncoherentModelsInformationModal.propTypes = {
    /** le onConfirm doit fermer la Modal qu'il a ouverte. */
    onConfirm: PropTypes.func,
    /** le onCancel doit fermer la Modal qu'il a ouverte. Si vide la modal est fermée automatiquement. */
    onCancel: PropTypes.func,
    /** Si le Mra est préexistant ou non, on change les messages et les actions */
    mraExists: PropTypes.bool,
};

export const openIncoherentModelsInformationModal = ({ onConfirm, onCancel, mraExists }) => {
    ModalComplementary.open({
        title: 'Different number of models between the MRA and the notices',
        content: <IncoherentModelsInformationModal onConfirm={onConfirm} onCancel={onCancel} mraExists={mraExists} />,
        size: 'medium',
    });
};

/**
 * Le but de cette modal est d'inciter l'utilisateur à ouvrir un MRA pour chaque Finding/Notice,
 * mais sans le contraindre.
 * L'idéal étant : si un MRA couvre un ou plusieurs Models, que tous ces Models soient
 * couverts par un même Finding (mais qui peut en couvrir d'autres, ce n'est pas grave).
 *
 * Montrer la Modal si cette fonction renvoie true.
 * {@see https://app.asana.com/0/1134038211401766/1206348402476855/f}
 *
 * @param {Array<string>} modelsIri
 * @returns {Promise<boolean>}
 */
export const hasIncoherentModels = async (modelsIri) => {
    const models = await Promise.all(
        modelsIri.map((modelIri) => {
            return APIResourceStore.resources.models.getItemFromResourcePath(modelIri, true);
        })
    );
    const findingId = getCommonFindingForAllModels(models);

    return findingId === null;
};

/**
 * On veut s'assurer que tous les models seront couverts par une même notice, donc on vérifie
 * s'ils sont couverts par un même Finding.
 *
 * @param {Array<{findingsEntities: Array<{id: string, notices: Array<string>, status: string}>}>} models
 * @return {number|null} FindingId du finding commun à tous les models donnés
 */
const getCommonFindingForAllModels = (models) => {
    const results = models
        .filter((model) => model.findingsEntities)
        .reduce((acc, model) => {
            model.findingsEntities
                .filter(
                    (f) =>
                        ![FINDING_STATUS_CLOSED, FINDING_STATUS_DELETED, FINDING_STATUS_DISMISSED]
                            .map(ParameterStore)
                            .includes(f.status)
                )
                .forEach((f) => (acc[f.id] = (acc?.[f.id] || 0) + 1));
            return acc;
        }, {});

        const [findingId, modelsNb] = Object.entries(results)
        .sort(([_ka, va], [_kb, vb]) => va - vb)
        ?.pop() || [0, 0];
    console.log("finding 0 ? ", findingId)
    // Si findingId = 0, alors il n'y a aucun finding associé aux models
    // donc on ne doit pas remonter d'erreur.
    if (modelsNb === models.length || findingId === 0) return findingId;

    return null;
};

/**
 * On estime qu'un Finding et un MRA sont cohérents si les Models du MRA sont
 * inclus dans ceux du Finding. {@see Finding::getCoherentMras } en back.
 * Si on donne des mras en entrée alors on vérifie que les MRAs cohérents
 * des findings sont inclus dans cette liste.
 *
 * Montrer la Modal si cette fonction renvoie false.
 *
 * @param {Array<{id: string, coherentMras: Array<string>}} findings Voir findingsEntitiesTrait en back
 * @param {Array<{id: string, }} [mras] Voir mrasEntitiesTrait en back, tableau des MRAs à tester
 * @returns {[boolean, {id: string, coherentMras: Array<string>}]} Renvoie un tableau qui contient un booléen et le premier Finding en erreur s'il y en a un.
 */
export const doAllFindingsHaveCoherentMras = (findings, mras = []) => {
    let incoherentFinding;
    if (mras && !mras.length) {
        const res = findings.every((finding) => {
            const isCoherent = finding?.coherentMras?.length > 0;
            if (!isCoherent) incoherentFinding = finding;
            return isCoherent;
        });
        return [res, incoherentFinding];
    }

    const res = findings.every((finding) => {
        const isCoherent = mras.some((mra) => finding?.coherentMras.includes(`/api/mras/${mra.id}`));
        if (!isCoherent) incoherentFinding = finding;
        return isCoherent;
    });
    return [res, incoherentFinding];
};
