import { APIResource } from '../Services/APIResource/APIResource';


/** @type {Object.<string, import('../Services/APIResource/APIResource').APIResourceField} */
const DEPARTMENT_FIELDS = {
    id: { 
        title: 'ID', 
        helperTextEdit: 'System IT ID defined by MRM tool',
    },
    name: {
        title: 'Name',
        type: 'text',
        maxLength:255, 
        helperTextEdit: 'Name of the Department',
    },
    scopes: {
        title: 'Teams',
        type: 'entityTree',
        params: {
            resource: 'scopes',
            displayField: 'title',
            multi: true,
            links: true,
            linkPath: (entity) => `/resource/scopes/${entity.id}/detail`,
            childrenPropertyName: 'childScopes',
            endpoints: {
                getAll: 'scopes/all-scopes/all',
            },
        },
    },
};

const DEPARTMENT_LAYOUT = {
    tabs: {
        Properties: {
            rows: [
                {
                    panels: {
                        Identification: {
                            cols: 6,
                            fields: [
                                'id',
                                'name',
                                'scopes',
                            ],
                        },
                    },
                },
            ],
        },
    },
};

export const ApiResourceDefaultParams = {
    id: 'departments',
    instanceId: 'departments',
    name: 'Department',
    fieldForTitle: 'name',
};

export default class DepartmentAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        const departmentsResource = new APIResource(ApiResourceDefaultParams);
        departmentsResource
            .setFields(DEPARTMENT_FIELDS)
            .setLayout(DEPARTMENT_LAYOUT)
            .genListView()
            .genInsertView({
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'Department';
                },
                postSaveRedirect: "detail",
            })
            .genEditView({
                fields: [
                    'name',
                    'scopes',
                ]
            })
            .genDetailView()
            .allowDelete()
        ;
    }
}
