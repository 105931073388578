import React from 'react';
import { EntityDisplay } from '../../../Components/Display/EntityDisplay/EntityDisplay';
import { ModelQuickSelect } from '../../../Components/Model/ModelQuickSelect';
import TextProvider from './TextProvider';
import {CONTEXT_DETAIL, CONTEXT_LIST} from "../APIResource";
import { getParamByIri } from '../../../Store/ParameterStore';

class ModelProvider {
    getDisplay(field, value, entity, props, _resource, context = CONTEXT_DETAIL) {
        const modelTooltip = (entity) => {
            return !entity.lastTieringTierResultFinal
                ? entity?.name
                : `${entity?.name}, ${getParamByIri(entity.lastTieringTierResultFinal)?.label}`;
        }
        return (
            <EntityDisplay
                {...props}
                defaultFields={[
                    ...(field.params?.defaultFields || []),
                    "name",
                    ...(context === CONTEXT_LIST ? []: ["lastTieringTierResultFinal"]) // Pour améliorer les perfs, on n'affiche pas le tierResult en mode list
                ]}
                resourceId={field.params.resource || 'models'}
                instanceId={field.params.instanceId}
                resourceLabel={field.params.displayField}
                label={field.title}
                value={value}
                links={field.params.links}
                linkPath={field.params.linkPath}
                linkAction={field.params.linkAction}
                parentEntity={entity}
                issueButton={'issueButton' in field ? field.issueButton : false}
                tooltip={context !== CONTEXT_LIST && (field.params.tooltip || modelTooltip)}
                context={context}
                preload={field?.params?.preload ?? null}
                preloadMaxItem={field?.params?.preloadMaxItem ?? null}
            />
        );
    }

    getDisplayList(field, value, entity, props, resource) {
        return this.getDisplay(field, value, entity, props, resource, CONTEXT_LIST);
    }

    getEdit(field, value, onChange, entity, props = {}, loading = false) {
        var displayRequired = {};
        if(typeof field.required === 'function'){
            displayRequired = {required: field.required(entity, field.id)};
        }else if(field.required === true){
            displayRequired = {required: true};
        }
        const className = field.errorHelperText ? "field-error-control" : "";
        // les props sont les routeParams, donc si on a un "id" on est en edition :
        let context = props.id ? 'edit' : 'add';

        const render = <>
                {this.getDisplay(field, value, entity, {...props, style:{flexGrow: "1"}, ...displayRequired, className})}
                <ModelQuickSelect
                    label={field.title}
                    field={field}
                    entity={entity}
                    context={context}
                    values={value ? (Array.isArray(value) ? value : [value]) : []}
                    resourceId={field.params.resource || 'models'}
                    instanceId={field.params.instanceId || 'models'}
                    onChange={onChange}
                    multi={field.params.multi}
                    endpoints={field.params.endpoints}
                    filters={field.params.filters}
                    clearable={!('required' in field && field.required === true)}
                    //disabled={loading}
                    additionalProperties={[...(field.params?.additionalProperties ||[]), field.params.displayField ]}
                    limitStatuses={field.params?.limitStatuses && field.params?.limitStatuses()}
                />
            </>;

        return (
            <>
                {!field.helperText ?
                    <section style={{ display: 'flex', justifyContent: "space-between", flexGrow: "1" }}>
                        {render}
                    </section>
                : render }
            </>
        );
    }

    /**
     * @param {{field: import('../APIResource').APIResourceField, value: any, onChange: Function}} options
     */
    getFilter(options) {
        options.field.params.filterMulti = options.field?.params?.filterMulti ?? true
        return TextProvider.getFilter(options);
    }

    /* Plus besoin, on utilise un filtre "flou" en back (https://app.asana.com/0/1134038211401766/1202223031992856/f) 
    
    getFilter(options = {}) {
        const { field, value, onChange } = options;
    */
        /** @param {string} inputValue */
        /* const onInputChange = (inputValue) => {
            let input;

            const inputValues = inputValue.split(/[\s,]+/);
            if (inputValues.length <= 1) {
                input = inputValue;
            } else {
                // On récupères les IRI des models associés
                let found = [];
                input = APIResourceStore.resources.allModels
                    .filterItems((model) => inputValues.includes(model.modelID) || inputValues.includes(model.functionalID) || inputValues.includes(model.functionalIDLong) || inputValues.includes(model.initialID))
                    .map((model) => {
                        found = [...found, model.modelID, model.functionalID, model.functionalIDLong, model.initialID];
                        return model['@id'] 
                    });
                if (input.length) {
                    onChange([...(value || []), ...input]);
                    // on réaffiche les inputs non trouvés :
                    input = inputValues.filter(i => !found.includes(i)).join(',');
                } else {
                    input = inputValue;
                }
            }
            return input;
        }
 
        return (
            <EntitySelect
                resourceId={field.params.resource || 'models'}
                instanceId={field.params.instanceId || 'models'}
                context="filter"
                resourceLabel={field.params.displayField}
                onChange={onChange}
                clearable={true}
                multi={true}
                filters={field.params.filters}
                value={value ? value : null}
                endpoints={field.params.endpoints}
                onInputChange={onInputChange}
                orExistsMultiFilterNotAllowed={field.params.orExistsMultiFilterNotAllowed || false}
            />
        );
    } */
}

export default new ModelProvider();
