import React, { Component } from "react";
import Modal from "../../Services/Modal";
import {DatePicker} from "../Forms/DatePicker/DatePicker";
import Alert from "../../Services/Alert";
import {userHasRoleMRM, userHasValidatorRights, userHasValidatorTeamRights} from "../../Store/ParameterStore";
import User from "../../Services/User/User";
import Http from "../../Services/Http";
import { ButtonBar } from "../Modal/ButtonBar";
import { ActionButton } from "../Modal/ActionButton";
import { ModalContent } from "../Modal/ModalContent";

export class PlannedDocumentationDeliveryDateModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plannedDocumentationDeliveryDate: null,
        };
    }

    componentDidMount() {
        this.state.plannedDocumentationDeliveryDate = this.props.entity.plannedDocumentationDeliveryDate;
    }

    save() {
        if (
            !this.state.plannedDocumentationDeliveryDate
        ) {
            Alert.show({
                message: "Confirm the date: Planned documentation delivery date should be filled.",
                type: "error"
            });
            return;
        }

        this.setState({progress: true});

        let approvedBy = 'Lod1';
        if (userHasValidatorTeamRights(User.profile.teams, this.props.entity) || userHasRoleMRM()) {
            approvedBy = 'Lod2';
        }
        Http.post("review/planned-documentation", {
            reviewId: this.props.entity.id,
            plannedDocumentationDeliveryDate: this.state.plannedDocumentationDeliveryDate,
            approvedBy: approvedBy,
        }).then(() => {
            this.props.resource.getItem(this.props.entity.id, true).then(() => {
                Modal.close();
                this.setState({progress: false});
            });
        }).catch(err => {
            console.error(err);
            Modal.close();
            this.setState({progress: false});
        });
    }

    render() {
        return (
            <ModalContent>
                <DatePicker
                    label={'Planned documentation delivery date'}
                    value={this.props.entity.plannedDocumentationDeliveryDate ?? this.props.entity.plannedDocumentationDeliveryDate}
                    onChange={(event) => this.setState({ plannedDocumentationDeliveryDate: event })}
                    required={true}
                />
                <ButtonBar>                   
                    <ActionButton
                        loading={this.state.progress}
                        onClick={() => {
                            this.save();
                        }}
                    >
                        Confirm the date
                    </ActionButton>
                </ButtonBar>
            </ModalContent>
        );
    }
}

let styles = {
    actionLink: {
        marginLeft: 5
    }
};
