import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './display-score-slider.scss';

const CIRCLE_SIZE = 48;
const CIRCLE_PADDING = 4;
const MAX_SCORE = 5;
const MIN_SCORE = 1;

const getColor = (score) => {
    if (score === 'N/A') {
        return 'rgba(0,0,0,0.25)';
    }
    if (score <= 3) {
        return '#A0CF63';
    }
    if (score <= 4) {
        return '#F6C343';
    }

    return '#EA3323';
};

const DisplayScoreSlider = (props) => {
    const { score, label, highlight } = props;
    let scoreShown = score;
    if (score < 1) {
        scoreShown = 'N/A';
    }

    const [circleStyle, setCircleStyle] = useState({});

    const calcCircleStyle = (score) => {
        let position = 50; // au milieu par défaut, notamment pour N/A !
        if (score !== 'N/A') {
            position = ((score - MIN_SCORE) * 100) / (MAX_SCORE - MIN_SCORE).toFixed(0);
            position = Math.max(position, 0);
        }

        //  /!\ on déduit 7% pour la largeur approximative de la moitié du CIRCLE_SIZE /!\
        return {
            backgroundColor: getColor(score),
            width: CIRCLE_SIZE,
            height: CIRCLE_SIZE / 2,
            borderRadius: CIRCLE_SIZE / 2 + CIRCLE_PADDING,
            position: 'relative',
            padding: CIRCLE_PADDING,
            left: position - 7 + '%',
            bottom: '16px',
        };
    };

    useEffect(() => {
        setCircleStyle(calcCircleStyle(scoreShown));
    }, [scoreShown]);

    return (
        <div style={highlight ? styles.shadow : { ...styles.shadow, opacity: 0.5 }}>
            <div style={styles.line} className="line">
                <div style={circleStyle} className="chip">
                    <div className="score">{scoreShown}</div>
                </div>
                {label && <div style={styles.label}>{label}</div>}
            </div>
        </div>
    );
};

DisplayScoreSlider.propTypes = {
    score: PropTypes.string.isRequired,
};

const styles = {
    line: {
        backgroundColor: 'black',
        height: 2,
        width: '100%',
        position: 'relative',
    },
    label: {
        position: 'absolute',
        top: '18px',
        right: 0,
        fontSize: '12px',
        background: 'white',
        opacity: '0.85',
        borderRadius: '10px',
        padding: '0 0 0 8px',
    },
    shadow: {
        boxShadow: '0 0 1px 6px rgba(104, 104, 104, 0.08)',
    },
};

export default DisplayScoreSlider;
