import React, {useState} from "react";
import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";
import {ButtonBar} from "../Modal/ButtonBar";
import {ActionButton} from "../Modal/ActionButton";
import Modal from "../../Services/Modal";
import Alert from "../../Services/Alert";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import Navigation from "../../Services/Navigation";
import {CONTEXT_CUSTOM, CONTEXT_DETAIL} from "../../Services/APIResource/APIResource";
import {OpenModal} from "../Modal/OpenModal";
import { Init as MraInit } from "../../Components/Mra/Init"
import ParameterStore from '../../Store/ParameterStore';

const NoticeAfterClosingModal = (props) => {
    const { notice, resource, resourceComponent } = props;
    const [progress, setProgress] = useState(false)
    const [showJustification, setShowJustification] = useState(false)
    const [justification, setJustification] = useState(null)

    const save = () => {
        setProgress(true);

        if(justification === null){
            Alert.show({ message: "Please justify the absence of MRA update.", type: "error" });
            setProgress(false);
            return;
        }

        notice.absenceOfMRAUpdate = justification;

        resource.apiPut(notice).then(() => {
            resource.getItem(notice.id, true).then((e) => {
                Modal.close();
                setProgress(false);
                resourceComponent.entity = e;
                resourceComponent.forceUpdate();
                Navigation.router.history.push('/blank');
                setTimeout(() => {
                    Navigation.router.history.push(
                        `/resource/notices/${notice.id}/detail?tab=Findings`
                    );
                    resourceComponent.setState({currentTab: resource.getTabId(CONTEXT_DETAIL, 'Findings')})
                },10)
            });
        }).catch(err => {
            console.error(err);
            Modal.close();
            setProgress(false);
        });
    }

    return (
        <div className={"notice-after-closing-modal"}>
            {
                notice.hasAtLeastOneFindingClosure &&
                <Typography component={'p'} style={{marginBottom: '15px'}}>Following the closure of the notice, the related findings have been closed.</Typography>
            }

            {
                notice.blockingNotice && Array.isArray(notice.blockingNoticeModels) && notice.blockingNoticeModels.length > 0 &&
                <Typography component={'p'} style={{marginBottom: '15px'}}>Following the closure of this blocking notice, the validation status of the model(s) { notice.blockingNoticeModels.join(', ') } has been automatically updated.</Typography>
            }

            <Typography component={'p'}>
                Does the closure of this notice entail a MRA update ?
                <br /><span style={{fontStyle: 'italic'}}>On the Wholesale Banking Perimeter, please update the validation report if necessary (notice follow up, scorecard, spiderchart, Model Risk Assessment and Model Risk Assessment details) and upload it in the "Documents" tab of the model.</span>
            </Typography>
            <ButtonBar>
                <OpenModal
                    context={CONTEXT_CUSTOM}
                    customInnerView={<MraInit
                        initialModels={notice.models}
                        process={ParameterStore('MRA_PROCESS_LOD2')}
                        mraSource={notice.models[0]}
                        context="CONTEXT_NOTICE_AFTER_CLOSING"
                    />}
                    modalTitle={'MRA'}
                    preload={true}
                    button={
                        <ActionButton>
                            MRA update
                        </ActionButton>
                    }
                />
                <ActionButton
                    onClick={() => setShowJustification(true)}
                >
                    No MRA update
                </ActionButton>
            </ButtonBar>

            {
                showJustification &&
                <>
                    <FormControl className="container_textarea">
                        <label>Absence of MRA update *</label>
                        <TextareaAutosize
                            value={justification ? justification : ""}
                            onChange={event => setJustification(event.target.value)}
                            rows={5}
                            rowsMax={10}
                            required={true}
                        />
                    </FormControl>
                    <ButtonBar>
                        <ActionButton onClick={Modal.close}>
                            Close
                        </ActionButton>
                        <ActionButton
                            loading={progress}
                            onClick={save}
                        >
                            Save
                        </ActionButton>
                    </ButtonBar>
                </>
            }

        </div>
    );
};
NoticeAfterClosingModal.propTypes = {
    notice: PropTypes.shape({
        blockingNotice: PropTypes.bool,
        blockingNoticeModels: PropTypes.array,
    }),
}

export default NoticeAfterClosingModal;