import React, {Component} from 'react';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import TieringForDate from "./Tiering/TieringForDate"
import MraStateByTieringForDate from "./Mra/MraStateByTieringForDate";
import Grid from "@material-ui/core/Grid";
import Http from "../../Services/Http";
import DateFormatter from "../../Services/DateFormatter";
import ModelsByStatusForDate from "./Validate/ModelsByStatusForDate"
import ChartWithDateRangePicker from "./ChartWithDateRangePicker";
import ValidModelsByModelTypesForDate from "./Validate/ValidModelsByModelTypesForDate";
import ModelsByScoreAndModelTypeForDate from "./Mra/ModelsByScoreAndModelTypeForDate";
import AppBarStore from "../../Store/AppBarStore";

class Reporting extends Component {
    constructor(props) {
        super(props);

        AppBarStore.title = "Reporting";

        // Liste par défaut des reportings (pour le 1er appel http)
        let date = DateFormatter.dateToIso8601(new Date());
        let startDate = DateFormatter.addDays(-45,new Date());

        let reportingList = {
            'tieringForDate': {'date' : date },
            'tieringBetweenDates': {'start' : startDate, 'end' : date},
            'mraStateByTieringForDate': {'date' : date },
            'modelByMraStatusBetweenDates': {'start' : startDate, 'end' : date},
            'modelsByStatusForDate' : {'date' : date },
            'modelsByStatusBetweenDates': {'start' : startDate, 'end' : date},
            'validationsModelsBetweenDates': {'start' : startDate, 'end' : date},
            'validModelsByModelTypesForDate': {'date' : date },
            'validModelsByModelTypesBetweenDates': {'start' : startDate, 'end' : date},
            'modelsByScoreAndModelTypeForDate': {'date' : date },
            'modelsByScoreBetweenDates': {'start' : startDate, 'end' : date},
        };

        this.state = {
            currentTab: 0,
            dateAt: DateFormatter.dateToIso8601(new Date()),
            reportingList: reportingList,
            data: null,
        };
        this.loadData();
    }


    loadData(){
        Http.post('reporting/calculate', this.state.reportingList).then((response) => {
            if(response.results){
                this.setState({data: response.results})
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.currentTab !== this.state.currentTab){
            this.setState({data: this.state.data})
        }
    }

    getUpdateData(statRequest){
        let reporting = null;

        if(typeof statRequest.request === 'string'){
            // requetes avec 1 date
            reporting = {
                [statRequest.name]: {'date' : statRequest.request }
            };
        }else{
            // requetes avec une start date et une end date
            reporting = {
                [statRequest.name]: {'start' : statRequest.request.start, 'end': statRequest.request.end }
            };
        }

        Http.post('reporting/calculate', reporting ).then((response) => {
            if(response.results){

                if(this.state.data[statRequest.name]){
                    let datas = {...this.state.data};
                    datas[statRequest.name] = response.results[statRequest.name];
                    this.setState({data: datas});
                }
            }
        });
    }

    onChangeDate(statRequest){
        this.getUpdateData(statRequest);
    }

    tierings(){
        return (
            <Grid container spacing={0} className="container">
                <Grid item xs={12}>
                    <TieringForDate data={this.state.data} onChangeDate={this.onChangeDate.bind(this)}/>
                </Grid>
                <Grid item xs={12} className="reporting-section">
                    <ChartWithDateRangePicker
                        data={this.state.data}
                        onChangeDate={this.onChangeDate.bind(this)}
                        reportingType="tieringBetweenDates"
                        title="Tiering evolution"
                    />
                </Grid>
            </Grid>
        );
    }

    mras(){
        return (
            <Grid container spacing={0} className="container container-no-padding">
                <Grid item xs={12}>
                    <MraStateByTieringForDate data={this.state.data} onChangeDate={this.onChangeDate.bind(this)} />
                </Grid>
                <Grid item xs={12} className="reporting-section">
                    <ChartWithDateRangePicker
                        data={this.state.data}
                        onChangeDate={this.onChangeDate.bind(this)}
                        reportingType="modelByMraStatusBetweenDates"
                        title="MRA status evolution"
                    />
                </Grid>
                <Grid item xs={12}>
                    <ModelsByScoreAndModelTypeForDate data={this.state.data} onChangeDate={this.onChangeDate.bind(this)}/>
                </Grid>
                <Grid item xs={12} className="reporting-section">
                    <ChartWithDateRangePicker
                        data={this.state.data}
                        onChangeDate={this.onChangeDate.bind(this)}
                        reportingType="modelsByScoreBetweenDates"
                        title="MRA scores evolution"
                    />
                </Grid>
            </Grid>
        );
    }

    validations(){
        return (
            <Grid container spacing={0} className="container">
                <Grid item xs={4}>
                    <ModelsByStatusForDate data={this.state.data} onChangeDate={this.onChangeDate.bind(this)} />
                </Grid>
                <Grid item xs={8}>
                    <ValidModelsByModelTypesForDate data={this.state.data} onChangeDate={this.onChangeDate.bind(this)} />
                </Grid>
                <Grid item xs={12} className="reporting-section">
                    <ChartWithDateRangePicker
                        data={this.state.data}
                        onChangeDate={this.onChangeDate.bind(this)}
                        reportingType="modelsByStatusBetweenDates"
                        title="Model by status evolution"
                    />
                </Grid>
                <Grid item xs={12} className="reporting-section">
                    <ChartWithDateRangePicker
                        data={this.state.data}
                        onChangeDate={this.onChangeDate.bind(this)}
                        reportingType="validationsModelsBetweenDates"
                        title="Validations evolution"
                    />
                </Grid>
            </Grid>
        );

        /*

                <Grid item xs={12} className="reporting-section">
                    <ChartWithDateRangePicker
                        data={this.state.data}
                        onChangeDate={this.onChangeDate.bind(this)}
                        reportingType="validModelsByModelTypesBetweenDates"
                        title="Validations by model types evolution"
                    />
                </Grid>
         */
    }

    //modelsByStatusBetweenDates

    render() {
        let self = this;

        return (
            <div className="container reporting">
                <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.currentTab}
                        onChange={(event, val) => {self.setState({currentTab: val})}}
                        className="tabs-menu"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="Tiering" />
                        <Tab label="MRA" />
                        <Tab label="Validation"/>
                    </Tabs>
                </AppBar>
                { this.state.currentTab === 0 ? this.tierings() : null }
                { this.state.currentTab === 1 ? this.mras() : null }
                { this.state.currentTab === 2 ? this.validations() : null }
            </div>
        )
    }
}

export default Reporting;
