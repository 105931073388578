import React from "react";
import { EntityDisplay } from "../../../Components/Display/EntityDisplay/EntityDisplay";
import { ParameterSelect } from "../../../Components/Forms/ParameterSelect/ParameterSelect";
import {CONTEXT_LIST} from "../APIResource";

class ParameterProvider {
  getDisplay(field, value, entity, props, _resource, context) {
    return (
        <EntityDisplay
            {...props}
            resourceId="parameters"
            resourceLabel="label"
            label={field.title}
            value={value}
            links={false}
            context={context}
            defaultFields={['id','type','verified','dependentFields','systemId']}
        />
    );
  }

  getDisplayList(field, value, entity, props, resource) {
    return this.getDisplay(field, value, entity, props, resource, CONTEXT_LIST);
  }

  getEdit(field, value, onChange, entity, _props, loading = false) {
    return (
      <ParameterSelect
        field={field}
        value={value}
        onChange={onChange}
        issueButton={"issueButton" in field ? field.issueButton : true}
        entity={entity}
        clearable={!("required" in field && field.required === true)}
        multi={"multi" in field.params ? field.params.multi : false}
        required={field.requiredComputed}
        error={!!field.errorHelperText}
        className={field.errorHelperText ? "field-error-control" : ""}
        helperText={field.errorHelperText}
        //disabled={loading}
      />
    );
  }

  getFilter(options = {}) {
    const { field, value, entity, onChange, resourceId } = options;
    field.resourceId = resourceId;

    return (
      <ParameterSelect
        label=""
        field={field}
        onChange={onChange}
        clearable={true}
        multi={true}
        entity={entity}
        value={value ? value : ""}
        allowNotVerified={field.params?.allowNotVerified === false ? false: true}
        context={'filter'}
        orExistsMultiFilterNotAllowed={field.params.orExistsMultiFilterNotAllowed || false}
      />
    );
  }
}

export default new ParameterProvider();
