import React, { PureComponent } from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import {Paper} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DateFormatter from "../../../Services/DateFormatter";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';


const COLORS = ['#E74D5D','#00838A', '#F6A800',  '#BDCF41'];

export default class MraStateByTieringForDate extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data : [],
            date: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.data && (this.props.data.mraStateByTieringForDate
            && (this.state.data.length === 0 || this.props.data.mraStateByTieringForDate !== prevProps.data.mraStateByTieringForDate))){
            let dateList = Object.keys(this.props.data.mraStateByTieringForDate);
            this.setState({date: dateList[0]});
            this.setState({data: this.formatData(this.props.data.mraStateByTieringForDate)});
        }
    }

    formatData(rawData){
        let datas = [];
        if(rawData.length === 0){
            return null;
        }

        for(let date in rawData){
            for(let mraStatus in rawData[date]){
                let categoryResult = rawData[date][mraStatus];
                datas.push({name: this.getMraStatusValue(mraStatus), 'No tier': categoryResult['NoTier'], 'Tier 1': categoryResult[1], 'Tier 2': categoryResult[2], 'Tier 3': categoryResult[3]});
            }
        }
        return datas;
    }

    updateValue(reportingName, value){
        if(this.props.onChangeDate){
            this.props.onChangeDate({'name': reportingName, 'request': value});
            this.setState({data: []});
        }
    }

    getMraStatusValue(val){
        switch (parseInt(val)) {
            case 0: return 'Validated';
            case 1: return 'From LoD1';
            case 2: return 'Modified by LoD2';
            case 3: return 'Reviewed by LoD1';
            default: return '';
        }
    }

    render() {
        return (
            <div className="container container-no-padding-sides" style={styles.margin}>
                <Paper>
                    <AppBar position="static" className="background-lowlight background-linear-gradient">
                        <Toolbar style={styles.toolBar}>
                            <Typography variant="h6">
                                MRA state by tiering
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={styles.date}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                margin="normal"
                                id="startDate"
                                label="Date"
                                value={ this.state.date ? this.state.date : null }
                                onChange={(date) => { this.updateValue('mraStateByTieringForDate', DateFormatter.dateToIso8601(date)) }}
                                format="dd MMMM yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div style={styles.chart}>
                        <ResponsiveContainer>
                            <BarChart
                                width={800}
                                height={350}
                                data={this.state.data}
                                margin={{
                                    top: 5, right: 0, left: 0, bottom: 20,
                                }}
                            >
                                <CartesianGrid stroke="#f5f5f5" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend wrapperStyle={{ fontSize: 16, top : -25 }} verticalAlign="top" height={40} />
                                <Bar dataKey="No tier" fill={COLORS[0]} />
                                <Bar dataKey="Tier 1" fill={COLORS[1]} />
                                <Bar dataKey="Tier 2" fill={COLORS[2]} />
                                <Bar dataKey="Tier 3" fill={COLORS[3]} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Paper>
            </div>
        );
    }
}
const styles = {
    toolBar: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    container:{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    chart:{
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        marginTop: 10,
        height: 400,
    },
    date:{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 0,
        marginBottom: 30,
        width: 200,
    },
    margin: {
        paddingBottom: 10,
    }
}