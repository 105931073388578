import React, { Component, useState } from "react";
import { observer } from "mobx-react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Switch,
  makeStyles,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import DoneIcon from "@material-ui/icons/Done";
import ParameterStore from "../../../Store/ParameterStore";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    marginLeft: "auto",
  },
});

const ModelTesterResponse = (props) => {
  const { answers, k, q, handleChange, handleChangeComment } = props;
  const classes = useStyles();

  const [checked, setChecked] = useState(
    k in answers ? answers[k].comment : false
  );

  const toggleChecked = () => {
    setChecked((prev) => !prev);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{q.question}</FormLabel>
      {q.note && (
        <Typography variant="body2" color="textSecondary" component="span">
          {q.note}
        </Typography>
      )}
      <RadioGroup
        aria-label="position"
        name="position"
        value={
          k in answers && answers[k].value !== undefined
            ? answers[k].value
              ? "true"
              : "false"
            : ""
        }
        onChange={(event) => handleChange(k, event.target.value === "true")}
        row
      >
        <FormControlLabel
          value="true"
          control={<Radio color="primary" />}
          label={q.yesLabel || "Yes"}
          labelPlacement="start"
        />
        <FormControlLabel
          value="false"
          control={<Radio color="secondary" />}
          label={q.noLabel || "No"}
          labelPlacement="start"
        />
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={toggleChecked}
              name="show comment"
              color="primary"
            />
          }
          label="Add comment"
          classes={{ root: classes.root }}
        />
      </RadioGroup>
      {checked && (
        <TextField
          label=""
          placeholder="Comment"
          multiline
          rows={3}
          rowsMax={4}
          value={k in answers ? answers[k].comment || "" : ""}
          onChange={(event) => handleChangeComment(k, event.target.value)}
          variant="outlined"
        />
      )}
    </FormControl>
  );
};

ModelTesterResponse.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.bool, comment: PropTypes.string })
  ).isRequired,
  k: PropTypes.number.isRequired,
  q: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeComment: PropTypes.func.isRequired,
};

class ModelTesterQuestions extends Component {
  state = {
    /**
     * @param {Array<{value: Boolean, comment: String}>} answers - Answers is a list of Objects {comment, value}
     */
    answers: [],
    disabled: false,
    championOrChallenger: null,
    expertOrQuantitative: null,
    specificFramework: null,
  };

  /**
   * @typedef {Object} Question
   * @property {string} question - Title of the question
   * @property {string} yes - Key of the next question if answered yes
   * @property {string} no - Key of the next question if answered no
   * @property {string} note - Additional note
   * @property {function} calcAdditionalFields - Function returning additional fields to save in the state
   * @property {string} yesLabel - Alternative label for the yes answer
   * @property {string} noLabel - Alternative label for the "no" answer
   * @property {string} end - Text indicating that the question tree has ended
   * @property {('model'|'tool'|'info')} action - Action to execute at the end of the question tree
   *
   * @param {Object.<string, Question>} questions - Object of Questions
   */
  questions = {
    /* Désactive les questions Natixis US
        0: {
            question: 'Does this model belong to the Natixis US group entity?',
            yes: 101,
            no: 1
        },
        */
    0: {
      question: "Are inputs being transformed into outputs ?",
      yes: 2,
      no: "tool",
    },
    2: {
      question:
        "Are outputs used in front end decision, reporting or do they impact supervisors or internal & external stakeholders of the group ?",
      yes: 6,
      no: 3,
    },
    3: {
      question:
        "Is the candidate a challenger developed by the 1st or 2nd line of defense ?",
      note: (
        <span>
          A Champion model is a models developed and used for decision making
          purpose.
          <br />A Challenger model is a model built to provide another set of
          outcomes and is used as a benchmark to the Champion.
        </span>
      ),
      yes: 6,
      no: 4,
      calcAdditionalFields: (value) => {
        return {
          championOrChallenger: value
            ? ParameterStore("CHAMPION_OR_CHALLENGER_CHALLENGER")
            : ParameterStore("CHAMPION_OR_CHALLENGER_CHAMPION"),
        };
      },
    },
    4: {
      question: "Are the outputs feeding multiple downstream models ?",
      yes: 6,
      no: 5,
    },
    5: {
      question: "Are the outputs feeding a unique downstream tool/model ?",
      yes: "downstream",
      no: "tool",
    },
    6: {
      question:
        "Is there any uncertainty in the outputs (e.g. the outputs are estimates or predictions) ?",
      yes: 7,
      no: "tool",
    },
    7: {
      question: "Is it primarily an expert judgment approach ?",
      yes: 9,
      no: 8,
      calcAdditionalFields: (value) => {
        return {
          expertOrQuantitative: value
            ? ParameterStore("MODEL_NATURE_STANDARD_EXPERT")
            : null,
        };
      },
    },
    8: {
      question:
        "Is it primarily a statistical/financial/mathematical/probabilistic/economic approach with assumptions ? " +
        "(i.e. it is not a simple formula and it bears uncertainty or estimations errors)",
      yes: 9,
      no: "tool",
      calcAdditionalFields: (value) => {
        return {
          expertOrQuantitative: value
            ? ParameterStore("MODEL_NATURE_STANDARD_QUANTITATIVE")
            : null,
        };
      },
    },
    9: {
      question:
        "Is the model used only once or for a Non Standard Transaction ?",
      yes: 10,
      no: "model inventory",
      yesLabel: "Yes",
      noLabel: "No", // dumb example to document property
    },
    10: {
      question: "This model is used :",
      yes: "only once",
      no: "non standard",
      yesLabel: "Only once",
      noLabel: "For a non-standard transaction",
      calcAdditionalFields: (value) => {
        return {
          specificFramework: value
            ? ParameterStore("MODEL_SPECIFIC_FRAMEWORK_USED_ONLY_ONCE")
            : ParameterStore(
                "MODEL_SPECIFIC_FRAMEWORK_NON_STANDARD_TRANSACTION"
              ),
        };
      },
    },

    // Natixis US
    101: {
      question: "Is there any input being used?",
      yes: 102,
      no: "downstream",
    },
    102: {
      question: "Is data used as an input?",
      yes: 105,
      no: 103,
    },
    103: {
      question: "Are assumptions used as inputs?",
      yes: 105,
      no: 104,
    },
    104: {
      question: "Is other model‘s output used as an input?",
      yes: 105,
      no: "downstream",
    },
    105: {
      question: "Is the input transformed as an output?",
      yes: 106,
      no: "downstream",
    },
    106: {
      question: "Is there any uncertainty in the output?",
      note: "(e.g. is the output an estimate or prediction?)",
      yes: 107,
      no: "downstream",
    },
    107: {
      question: "Is this primarily an expert judgement approach?",
      yes: "expert",
      no: 108,
    },
    108: {
      question:
        "Is this primarily an statistical/financial/probabilistic/economic/mathematical approach?",
      yes: "quant",
      no: "downstream",
    },

    downstream: {
      end:
        "Part of the downstream tool/model – Questions to be applied on the overarching model",
      action: "info",
    },
    "model inventory": {
      end: "Model inventory",
      action: "model",
    },
    "only once": {
      end: "Model: used only once",
      action: "model",
    },
    "non standard": {
      end: "Model: Non standard transaction",
      action: "model",
    },
    tool: {
      end: "Tool",
      action: "tool",
    },
  };

  questionsDisplayed() {
    const { answers } = this.state;

    let questions = [];

    let currentQuestion = this.questions[0];
    questions.push(currentQuestion);

    answers.forEach((a) => {
      // On n'affiche pas la question suivante si la réponse n'est pas donnée.
      // Ce cas apparaît si on remplit le commentaire avant d'avoir donné une réponse.
      if (a.value !== undefined) {
        currentQuestion = this.questions[
          currentQuestion[a.value ? "yes" : "no"]
        ];
        questions.push(currentQuestion);
      }
    });

    return questions;
  }

  formatAnswers() {
    const { answers } = this.state;

    let questions = [];

    let currentQuestion = this.questions[0];

    answers.forEach((a) => {
      let yesLabel = currentQuestion.yesLabel || "Yes";
      let noLabel = currentQuestion.noLabel || "No";
      questions.push(
        "· " +
          [
            currentQuestion.question,
            a.value ? yesLabel : noLabel,
            a.comment || "",
          ].join(" ")
      );
      currentQuestion = this.questions[currentQuestion[a.value ? "yes" : "no"]];
    });

    return questions.join("\n");
  }

  /**
   * Traitement lors du choix de la réponse.
   *
   * @param {number} k
   * @param {boolean} value
   */
  handleChange(k, value) {
    let { answers } = this.state;
    // On supprime les questions suivantes, mais on garde la question en cours
    answers = answers.slice(0, k + 1);
    // en particulier pour récupérer la valeur éventuelle du commentaire :
    answers[k] = { ...(answers[k] || {}), value };

    //const lastQ = this.questionsDisplayed().pop();
    let additionalFields = {
        championOrChallenger: null,
        expertOrQuantitative: null,
        specificFramework: null,
    };
    // on remet à zéro et on recalcule les champs 
    // pour le cas où on reset certaines questions
    this.questionsDisplayed().slice(0, k+1).forEach((q, i) => {
        let addFields = q.calcAdditionalFields
        ? q.calcAdditionalFields(answers[i].value)
        : {};
        additionalFields = {...additionalFields, ...addFields};
    })

    this.setState({ ...additionalFields, answers, disabled: false });
  }

  /**
   * Traitement si on commente la réponse.
   *
   * @param {number} k
   * @param {String} comment
   */
  handleChangeComment(k, comment) {
    let { answers } = this.state;
    // Le commentaire doit bien évidemment conserver la valeur de la réponse :
    answers[k] = { ...(answers[k] || {}), comment };

    this.setState({ answers });
  }

  /**
   *
   * @param {*} answers - Answers already given
   * @param {Question} q - Question to display
   * @param {*} k - Index of the question
   */
  displayQuestion(answers, q, k) {
    return (
      q &&
      !q.end && (
        <li key={k} style={styles.listStyle}>
          <ModelTesterResponse
            answers={answers}
            k={k}
            q={q}
            handleChange={this.handleChange.bind(this)}
            handleChangeComment={this.handleChangeComment.bind(this)}
          />
        </li>
      )
    );
  }

  /**
   *
   * @param {Question} lastQ
   */
  displayEndButton(lastQ) {
    if (lastQ.action === "tool") {
      return (
        <div style={styles.buttons}>
          <Chip
            color="secondary"
            label="Result : Tool"
            icon={<DoneIcon />}
            variant="outlined"
            style={styles.chip}
          />
          <Button
            variant="contained"
            color="secondary"
            disabled={this.state.disabled}
            onClick={() => {
              this.setState({ disabled: true });
              this.props.onReject([
                {
                  field: "treeDeterminationAnswers",
                  value: this.formatAnswers(),
                },
                {
                  field: "treeDeterminationResult",
                  value: lastQ.end,
                },
              ]);
            }}
          >
            Submit to mrm team for confirmation
          </Button>
        </div>
      );
    } else if (lastQ.action === "model") {
      return (
        <div style={styles.buttons}>
          <Chip
            color="primary"
            label={"Result : " + lastQ.end}
            icon={<DoneIcon />}
            variant="outlined"
            style={styles.chip}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={this.state.disabled}
            onClick={() => {
              this.setState({ disabled: true });
              this.props.onSave([
                {
                  field: "treeDeterminationAnswers",
                  value: this.formatAnswers(),
                },
                {
                  field: "modelNatureStandard",
                  value: this.state.expertOrQuantitative,
                },
                {
                  field: "championOrChallenger",
                  value: this.state.championOrChallenger,
                },
                {
                  field: "specificFramework",
                  value: this.state.specificFramework,
                },
              ]);
            }}
          >
            Save &amp; Continue
          </Button>
        </div>
      );
    } else {
      return (
        <div style={styles.buttons}>
          <Chip
            color="primary"
            label={lastQ.end}
            variant="outlined"
            style={styles.chip}
          />
        </div>
      );
    }
  }

  getQuestions() {
    let { answers } = this.state;
    const lastQ = this.questionsDisplayed().pop();

    return (
      <div>
        <ul>
          {this.questionsDisplayed().map((q, k) =>
            this.displayQuestion(answers, q, k)
          )}
        </ul>
        {!lastQ
          ? "Woops… Something awry just happened, please contact support."
          : lastQ.end
          ? this.displayEndButton(lastQ)
          : null}
      </div>
    );
  }

  render() {
    return this.getQuestions();
  }
}

export default observer(ModelTesterQuestions);

const styles = {
  listStyle: {
    marginBottom: 15,
  },
  buttons: {
    display: "flex",
    alignContent: "space-between",
    flexDirection: "column",
  },
  chip: {
    alignSelf: "center",
    marginBottom: "1.5em",
  },
};
