import React, { Component } from "react";
import Modal from "../../../Services/Modal";
import Alert from "../../../Services/Alert";
import Navigation from "../../../Services/Navigation";
import { Grid, Typography} from "@material-ui/core";
import ParameterStore from "../../../Store/ParameterStore";
import { EntityForm } from "../../Forms/EntityForm/EntityForm";
import { ActionButton } from "../../Modal/ActionButton";
import { ButtonBar } from "../../Modal/ButtonBar";
import { ModalContent } from "../../Modal/ModalContent";
import {PARAMETER_TYPE_SPECIFIC_FRAMEWORK} from "../../../Admin/ParameterAdmin";
import APIResourceStore from "../../../Store/APIResourceStore";
import {
    DOCUMENT_ACTION_DELETE,
    DOCUMENT_ACTION_SHOW,
    DOCUMENT_CATEGORY_SPECIFIC_FRAMEWORK,
    DocumentManager
} from "../../Display/DocumentManager/DocumentManager";
import TextareaProvider from "../../../Services/APIResource/FieldProviders/TextareaProvider";

export const specificFrameworkConversionFields = {
    convertSpecificFrameworkDocumentsEntities: {
        title: "Please attach documents to justify the conversion if necessary.",
        type: "documents",
        params: {
            entityResource: "models",
            propertyName: "convertSpecificFrameworkDocumentsEntities",
            fieldName: "Document",
            allowedCategory: false,
            forceCategory: () => ParameterStore(DOCUMENT_CATEGORY_SPECIFIC_FRAMEWORK), // fonction pour retarder le moment où on récupère la valeur du paramètre, pour qu'il soit non "false"
            links: false,
            allowedAction: (entity, document, action) =>
                [DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_DELETE].includes(action),
        },
        // eslint-disable-next-line react/display-name
        display: (field, value, entity) => (
            <DocumentManager
                values={value}
                entity={entity}
                entityResource={"models"}
                fieldName={"Document"}
                propertyName={"convertSpecificFrameworkDocumentsEntities"}
                defaultValues={{category: ParameterStore(DOCUMENT_CATEGORY_SPECIFIC_FRAMEWORK)}}
                allowedAction={(entity, document, action) =>
                    [DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_DELETE].includes(action)
                }
            />
        ),
        displayConditions: (entity) => entity.specificFramework,
    },
    specificFrameworkJustification: {
        title: "Justification of the conversion to specific framework",
        type: "textarea",
        required: true,
        displayList: (field, value, entity, props, resource) => resource.instanceId === 'specific_frameworks' ? TextareaProvider.getDisplayList(field, value, entity, props, resource) : null,
        displayConditions: (entity) => entity.specificFramework,
    },
    specificFramework: {
        title: 'Specific framework',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_SPECIFIC_FRAMEWORK,
            multi: false,
        },
        required: true,
    },
}

export class ConvertSpecificFrameworkForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: false,
            entity: {
                documentsEntities: null,
                specificFrameworkJustification: null,
                specificFramework: null,
                ...this.props.entity,
            }
        };
    }

    convert(resource) {
        if (!this.state.entity.specificFrameworkJustification) {
            Alert.show({
                message: "The justification is required before conversion.",
                type: "error"
            });
            return;
        }
        if (!this.state.entity.specificFramework) {
            Alert.show({
                message: "The specific framework is required before conversion.",
                type: "error"
            });
            return;
        }
        
        this.setState({progress: true});

        resource.apiPut(this.state.entity).then((entity) => {
            Navigation.router.history.push(
                `/resource/specific_frameworks/${entity.id}/detail`
            );
            Alert.show({ message: `The model ${entity.functionalID} has been added to the specific framework list.`, type: "success" });
            APIResourceStore.resources.specific_frameworks.apiGetCollection({
                page: 1,
                rowsPerPage: 10000,
                forceReload: true
            });
            Modal.close();
            this.setState({progress: false});
        }).catch(err => {
            console.error(err);
            Modal.close();
            this.setState({progress: false});
        });
    }

    handleConvertClick() {
        const { entity, resource } = this.props;
        if (entity) {
            return this.convert(resource);
        }
    }

    updateState = (callback) => {
        this.setState((p) => {
            const newEntity = callback(p.entity);
            return {entity: newEntity}
        })
    }

    render() {
        return (
            <ModalContent className={'non-model-reject-form'}>
                <Grid container justify="center" spacing={2} className="container resource-edit">

                    <Grid item xs={12}>
                        <EntityForm
                            entity={this.state.entity}
                            onUpdate={this.updateState}
                            fields={specificFrameworkConversionFields}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            Do you confirm the conversion to specific framework ?
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonBar>
                            <ActionButton onClick={Modal.close} disabled={this.state.progress}>
                                Cancel
                            </ActionButton>
                            <ActionButton onClick={() => this.handleConvertClick()} loading={this.state.progress}>
                                Confirm
                            </ActionButton>
                        </ButtonBar>
                    </Grid>
                </Grid>
            </ModalContent>
        );
    }
}