import React, { Component } from 'react';
import User, { ROLE as Role, ROLE } from '../../Services/User/User';
import MainMenuItem from './MainMenuItem';

import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import HomeWorkRounded from '@material-ui/icons/HomeWorkRounded';
import ChevronRightRounded from '@material-ui/icons/ChevronRightRounded';
import { BusinessRoleOfEntityType, userHasRoleADMIN, userHasRoleMRM, userHasSpecificRole } from '../../Store/ParameterStore';

const ChevronEnfant = () => {
    return <ChevronRightRounded size="small" />;
};

class MainMenu extends Component {
    /*state = {
        menuItems: RouteStore.routes,
    }; */

    /**
     * Liste des éléments du menu.
     *
     * Format d'un élément :
     *   {
     *      text: 'Workflows',
     *      icon: <Icon className="fas fa-stream" />,
     *      role: ROLE.MRM || role: () => return true, // facultatif
     *      children: [], // liste d'objets comme celui ci
     *      to: "/url", // "to" et "children" sont exclusifs l'un de l'autre
     *   }
     */
    menu = [
        {
            text: 'Home',
            icon: <HomeWorkRounded />,
            to: '/',
        },
        {
            text: 'Dashboard',
            icon: <Icon className="fas fa-chart-bar" />,
            to: '/dashboard',
            role: () => !userHasSpecificRole(Role.IG) || userHasRoleMRM(),
        },
        {
            text: 'Models Inventory',
            icon: <Icon className="fas fa-database" />,
            children: [
                {
                    text: 'Inventory',
                    icon: <ChevronEnfant />,
                    to: '/resource/models/list',
                    className: 'menu-item menu-root-item',
                },
                {
                    text: 'My validation perimeter',
                    icon: <ChevronEnfant />,
                    to: '/resource/models_val/list',
                    role: () => User.profile.isMemberOfValidatorTeam === true,
                },
                {
                    text: 'My models',
                    icon: <ChevronEnfant />,
                    to: '/resource/my_models/list',
                    role: () => User.profile.isMemberOfValidatorTeam !== true && (
                        !userHasSpecificRole(Role.IG)
                        || User.is(BusinessRoleOfEntityType.OWNER_OF_MODELS)
                        || User.is(BusinessRoleOfEntityType.DELEGATED_OWNER_OF_MODELS)
                        || User.is(BusinessRoleOfEntityType.IN_DEVELOPER_TEAM_OF_MODELS)
                    ),
                },
                {
                    text: 'My draft models',
                    icon: <ChevronEnfant />,
                    to: '/resource/my_draft_models/list',
                    role: () => (!userHasSpecificRole(Role.IG)
                        || User.is(BusinessRoleOfEntityType.DECLARER_OF_MODELS)
                        || User.is(BusinessRoleOfEntityType.OWNER_OF_MODELS)
                        || User.is(BusinessRoleOfEntityType.DELEGATED_OWNER_OF_MODELS)
                        || User.is(BusinessRoleOfEntityType.IN_DEVELOPER_TEAM_OF_MODELS)
                    ),
                },
                {
                    text: 'My declarations in progress',
                    icon: <ChevronEnfant />,
                    to: '/resource/declaration_models/list',
                    role: () => (!userHasSpecificRole(Role.IG)
                        || User.is(BusinessRoleOfEntityType.DECLARER_OF_MODELS)
                        || User.is(BusinessRoleOfEntityType.OWNER_OF_MODELS)
                        || User.is(BusinessRoleOfEntityType.DELEGATED_OWNER_OF_MODELS)
                        || User.is(BusinessRoleOfEntityType.IN_DEVELOPER_TEAM_OF_MODELS)
                    ),
                },
                {
                    text: 'Specific frameworks',
                    icon: <ChevronEnfant />,
                    to: '/resource/specific_frameworks/list',
                },
                {
                    text: 'Retired models',
                    icon: <ChevronEnfant />,
                    to: '/resource/retired_models/list',
                },
                {
                    text: 'Audit trail',
                    icon: <ChevronEnfant />,
                    to: '/resource/change_logs/list',
                    role: () => !userHasSpecificRole(Role.MRM), // MRM voit le lien d'en dessous pour les Changelogs
                },
            ],
        },
        {
            text: 'Inventory Management',
            icon: <Icon className="fas fa-tools" />,
            role: () => userHasSpecificRole(Role.IG) || userHasRoleMRM(),
            children: [
                {
                    text: 'Changelogs',
                    icon: <ChevronEnfant />,
                    to: '/resource/change_logs/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'Pending requests',
                    icon: <ChevronEnfant />,
                    to: '/resource/models_pending_request/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'Models under declaration',
                    icon: <ChevronEnfant />,
                    to: '/resource/models_under_declaration/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'Deleted models',
                    icon: <ChevronEnfant />,
                    to: '/resource/deleted_models/list',
                    role: () => userHasSpecificRole(Role.IG) || userHasRoleMRM(),
                },
                {
                    text: 'Non-Models',
                    icon: <ChevronEnfant />,
                    to: '/resource/non_models/list',
                    role: () => userHasSpecificRole(Role.IG) || userHasRoleMRM(),
                },
            ],
        },
        {
            text: 'Lists',
            icon: <Icon className="fas fa-tasks" />,
            children: [
                {
                    text: 'Model Uses',
                    icon: <ChevronEnfant />,
                    to: '/resource/model_uses/list',
                },
                {
                    text: 'All Model Uses',
                    icon: <ChevronEnfant />,
                    to: '/resource/model_uses_all/list',
                    role: ROLE.ADMIN,
                },
                {
                    text: 'Implementations',
                    icon: <ChevronEnfant />,
                    to: '/resource/implementations/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'IT System',
                    icon: <ChevronEnfant />,
                    to: '/resource/i_t_systems/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'Mitigation Actions',
                    icon: <ChevronEnfant />,
                    to: '/resource/mitigation_actions/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'MRAs',
                    icon: <ChevronEnfant />,
                    to: '/resource/mras/list',
                },
                {
                  text: 'Deleted MRAs',
                  icon: <ChevronEnfant />,
                  to: '/resource/deleted_mras/list',
                  role: () => userHasRoleMRM(),
                },
                {
                    text: 'Committees',
                    icon: <ChevronEnfant />,
                    to: '/resource/review_committees/list',
                    role: () => User.profile.isMemberOfValidatorTeam === true || userHasSpecificRole(Role.IG) || userHasRoleMRM(),
                },
                {
                    text: 'Tierings',
                    icon: <ChevronEnfant />,
                    to: '/resource/tierings/list',
                    role: () => userHasRoleMRM(),
                },
            ],
        },
        {
            text: 'Certification',
            icon: <Icon className="fas fa-stamp" />,
            className: 'menu-item menu-root-item has-children',
            role: () => (!userHasSpecificRole(Role.IG)
                || User.is(BusinessRoleOfEntityType.OWNER_OF_CERTIFICATION_CAMPAIGNS)
            ),
            children: [
                {
                    text: 'MRM dashboard',
                    icon: <ChevronEnfant />,
                    to: '/resource/model_certification_campaigns/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'My certifications',
                    icon: <ChevronEnfant />,
                    to: '/resource/my_model_certification_campaigns/list',
                    role: () => (!userHasSpecificRole(Role.IG)
                        || User.is(BusinessRoleOfEntityType.OWNER_OF_CERTIFICATION_CAMPAIGNS)
                    ),
                },
                {
                    text: 'Deleted campaigns',
                    icon: <ChevronEnfant />,
                    to: '/resource/deleted_model_certification_campaigns/list',
                    role: ROLE.MRM,
                },
            ],
        },
        {
            text: 'Reviews',
            icon: <Icon className="fas fa-search" />,
            //env: 'dev',
            children: [
                {
                    text: 'All reviews',
                    icon: <ChevronEnfant />,
                    to: '/resource/all_reviews/list',
                },
                {
                    text: 'Planned reviews',
                    icon: <ChevronEnfant />,
                    to: '/resource/planned_reviews/list',
                },
                {
                    text: 'Ongoing reviews',
                    icon: <ChevronEnfant />,
                    to: '/resource/ongoing_reviews/list',
                },
                {
                    text: 'Pending Requests',
                    icon: <ChevronEnfant />,
                    to: '/resource/pending_reviews/list',
                    role: () => (!userHasSpecificRole(Role.IG)
                        || User.is(BusinessRoleOfEntityType.AUTHOR_OF_REVIEWS)
                        || User.is(BusinessRoleOfEntityType.IN_VALIDATOR_TEAM_OF_MODELS)
                    ),
                },
                {
                    text: 'My assigned reviews',
                    icon: <ChevronEnfant />,
                    to: '/resource/my_assigned_reviews/list',
                    role: () =>
                        User.profile.isMemberOfValidatorTeam === true &&
                        !userHasRoleMRM() &&
                        !userHasRoleADMIN(),
                },
                {
                    text: 'Reviews awaiting validation',
                    icon: <ChevronEnfant />,
                    to: '/resource/awaiting_reviews/list',
                    role: () => User.profile.isManagerOfValidatorTeam === true,
                },
                {
                    text: 'Closed reviews',
                    icon: <ChevronEnfant />,
                    to: '/resource/closed_reviews/list',
                },
                {
                    text: 'Cancelled reviews',
                    icon: <ChevronEnfant />,
                    to: '/resource/dismissed_reviews/list',
                    role: () =>
                        (User.profile.isMemberOfValidatorTeam === true ||
                        userHasRoleMRM() ||
                        userHasRoleADMIN()),
                },
            ],
        },
        {
            text: 'Notices',
            icon: <Icon className="fas fa-bullhorn" />,
            //env: 'dev',
            children: [
                {
                    text: 'Findings',
                    icon: <ChevronEnfant />,
                    to: '/resource/findings/list',
                },
                {
                    text: 'Deleted findings',
                    icon: <ChevronEnfant />,
                    to: '/resource/deleted_findings/list',
                    role: () => userHasRoleMRM(),
                },
                {
                    text: 'Notices',
                    icon: <ChevronEnfant />,
                    to: '/resource/notices/list',
                },
                {
                    text: 'My notices',
                    icon: <ChevronEnfant />,
                    to: '/resource/my_notices/list',
                    role: () => (!userHasSpecificRole(Role.IG)
                        || User.is(BusinessRoleOfEntityType.OWNER_OF_NOTICES)
                        || User.is(BusinessRoleOfEntityType.ISSUER_OF_NOTICES)
                        || User.is(BusinessRoleOfEntityType.IN_CHARGE_OF_NOTICES)
                    ),
                },
                {
                    text: 'Deleted notices',
                    icon: <ChevronEnfant />,
                    to: '/resource/deleted_notices/list',
                    role: () => userHasRoleMRM(),
                },
                {
                    text: 'Changelogs',
                    icon: <ChevronEnfant />,
                    to: '/resource/change_logs_issues/list',
                    role: User.profile.isMemberOfValidatorTeam === true || userHasRoleMRM(),
                },
            ],
        },
        {
            text: 'IGG / BCE Recos',
            icon: <Icon className="fas fa-university" />,
            env: 'dev',
            role: () => !userHasSpecificRole(Role.IG),
            children: [
                {
                    text: 'List',
                    icon: <ChevronEnfant />,
                    to: '/resource/recommendation_igg_bces/list',
                },
                {
                    text: 'Import',
                    icon: <ChevronEnfant />,
                    to: '/import/recommendation',
                    role: ROLE.MRM,
                },
            ],
        },
        {
            text: 'Troubles',
            icon: <Icon className="fas fa-exclamation-triangle" />,
            role: () => (!userHasSpecificRole(Role.IG)
                || User.is(BusinessRoleOfEntityType.DECLARER_OF_ISSUES)
                || User.is(BusinessRoleOfEntityType.RECEIVER_OF_ISSUES)
            ),
            children: [
                {
                    text: 'My Troubles',
                    icon: <ChevronEnfant />,
                    to: '/resource/my_troubles/list',
                    role: () => (!userHasSpecificRole(Role.IG)
                        || User.is(BusinessRoleOfEntityType.DECLARER_OF_ISSUES)
                        || User.is(BusinessRoleOfEntityType.RECEIVER_OF_ISSUES)
                    ),
                },
                {
                    text: 'Waiting for assignment Troubles',
                    icon: <ChevronEnfant />,
                    to: '/resource/waiting_troubles/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'Open Troubles',
                    icon: <ChevronEnfant />,
                    to: '/resource/open_troubles/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'Closed Troubles',
                    icon: <ChevronEnfant />,
                    to: '/resource/closed_troubles/list',
                    role: ROLE.MRM,
                },
            ],
        },
        {
            text: 'Workflows',
            icon: <Icon className="fas fa-sitemap" />,
            role: ROLE.MRM,
            children: [
                {
                    text: 'List',
                    icon: <ChevronEnfant />,
                    to: '/resource/workflows/list',
                },
                {
                    text: 'New',
                    icon: <ChevronEnfant />,
                    to: '/resource/workflows/add',
                },
                {
                    text: 'Process',
                    icon: <ChevronEnfant />,
                    to: '/resource/process/list',
                },
            ],
        },
        {
            text: 'Procedures',
            icon: <Icon className="fas fa-book" />,
            to: '/resource/documents/list'
        },
        {
            text: 'Tool Management',
            icon: <Icon className="fas fa-cog" />,
            role: () => !userHasRoleMRM() && !User.profile.isMemberOfValidatorTeam === true,
            children: [
                {
                    text: 'Teams',
                    icon: <ChevronEnfant />,
                    to: '/resource/teams/list',
                },
            ]
        },
        {
            text: 'Tool Management',
            icon: <Icon className="fas fa-cog" />,
            role: () => userHasRoleMRM() || User.profile.isMemberOfValidatorTeam === true, /** @todo est-ce qu'on crée un nouveau groupe ou j'enlève le test ? */
            children: [
                {
                    text: 'Users',
                    icon: <ChevronEnfant />,
                    to: '/resource/users/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'Users assignation',
                    icon: <ChevronEnfant />,
                    to: '/resource/users/assignation',
                    role: ROLE.MRM,
                },
                {
                    text: 'Users departures',
                    icon: <ChevronEnfant />,
                    to: '/resource/users/departures',
                    role: ROLE.MRM,
                },
                {
                    text: 'Teams',
                    icon: <ChevronEnfant />,
                    to: '/resource/scopes/list',
                },
                {
                    text: 'Departments',
                    icon: <ChevronEnfant />,
                    to: '/resource/departments/list',
                    role: Role.ADMIN,
                },
                {
                    text: 'Establishments',
                    icon: <ChevronEnfant />,
                    to: '/resource/establishments/list',
                },
                {
                    text: 'Legal Entities',
                    icon: <ChevronEnfant />,
                    to: '/resource/legal_entities/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'Business Lines',
                    icon: <ChevronEnfant />,
                    to: '/resource/b_ls/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'Organizational Units',
                    icon: <ChevronEnfant />,
                    to: '/resource/o_us/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'Parameters',
                    icon: <ChevronEnfant />,
                    to: '/resource/parameters/list',
                    role: ROLE.ADMIN,
                },
                {
                    text: 'Variables',
                    icon: <ChevronEnfant />,
                    to: '/resource/variables/list',
                    role: ROLE.MRM,
                },
                {
                    text: 'Notifications',
                    icon: <ChevronEnfant />,
                    to: '/resource/notifications-all/list',
                    role: ROLE.ADMIN,
                }
            ],
        },
    ];

    /*
    toggleItem(itemKey, event) {
        event.stopPropagation();
        if (RouteStore.routes[itemKey]) {
            RouteStore.routes[itemKey].active = RouteStore.routes[itemKey]
                .active
                ? false
                : true;
        }
        for (let i in RouteStore.routes) {
            if (RouteStore.routes[i].children) {
                for (let j in RouteStore.routes[i].children) {
                    RouteStore.routes[i].children[j].active = false;
                    if (j == itemKey) {
                        RouteStore.routes[i].children[j].active = true;
                    }
                }
            }
        }
    }

    renderRow() {
        let children = (menuItem) => {
            let childrenItems = [];
            for (let i in menuItem.children) {
                if (menuItem.children[i].menuItem) {
                    childrenItems.push(
                        <li
                            key={i}
                            className={
                                'menu-item menu-sub-item menu-' +
                                i +
                                (menuItem.children[i].active ? ' active' : '')
                            }
                            onClick={(e) => this.toggleItem(i, e)}
                        >
                            <Link to={menuItem.children[i].path}>
                                {menuItem.children[i].menuItem.title}
                            </Link>
                        </li>
                    );
                }
            }

            return childrenItems.length > 0 ? <ul>{childrenItems}</ul> : null;
        };
        let menuItems = [];
        for (let i in RouteStore.routes) {
            const route = RouteStore.routes[i];
            if (
                !route.accessControl ||
                (typeof route.accessControl === 'function'
                    ? route.accessControl({})
                    : User.hasOneRole(route.accessControl))
            ) {
                if (RouteStore.routes[i].menuItem) {
                    menuItems.push(
                        <li
                            key={i}
                            className={
                                'menu-item menu-root-item menu-' +
                                i +
                                (RouteStore.routes[i].children
                                    ? ' has-children'
                                    : '') +
                                (RouteStore.routes[i].active ? ' active' : '')
                            }
                            onClick={(e) => this.toggleItem(i, e)}
                        >
                            <Link to={RouteStore.routes[i].path}>
                                {RouteStore.routes[i].menuItem.icon ? (
                                    <i
                                        className={
                                            'fas fa-' +
                                            RouteStore.routes[i].menuItem.icon
                                        }
                                    />
                                ) : (
                                    <i className={'fas fa-chevron-right'} />
                                )}
                                {RouteStore.routes[i].menuItem.title}
                                {RouteStore.routes[i].children ? (
                                    <i className={'deploy fas fa-caret-down'} />
                                ) : null}
                            </Link>
                            {children(RouteStore.routes[i])}
                        </li>
                    );
                }
            }
        }
        return menuItems;
    }
    */

    render() {
        /*return(
            <ul id="main-menu">{this.renderRow()}</ul>
        );*/

        return (
            <List component="nav">
                {this.menu.map((o, index) =>
                    !Object.keys(User.profile).length || !o.env || o.env === User.profile.env ? (
                        <MainMenuItem {...o} key={index} />
                    ) : null
                )}
            </List>
        );
    }
}

export default MainMenu;
