import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import React, {Component} from "react";
import {observer} from "mobx-react";

export class WorkflowStepProvider extends Component{
    render(){
        return <WorkflowStep
            key={Date.now()}  //Fix pour le rerender dans le contexte du didUpdate sans différence d'objet entre props et prevProps
            {...this.props}
        />
    }
}

export const WorkflowStep = observer(class WorkflowStep extends Component {
    entity;

    constructor(props) {
        super(props);

        this.entity = this.props.entity;
        this.state = {
            validatedStep: null
        }
    }

    componentDidMount() {
        this.entity = this.props.entity;
        this.getValidatedStep();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.entity !== prevProps.entity)) {
            this.entity = this.props.entity;
            this.getValidatedStep();
        }
    }

    getValidatedStep() {
        let entity = this.entity;

        if(typeof this.props.forceStep !== 'undefined'){
            this.setState({validatedStep: this.props.forceStep});
            return;
        }

        if (entity === null || typeof entity === "undefined") {
            this.setState({validatedStep: null});
            return;
        }

        let validatedStep = this.props.activeStep(entity);
        this.setState({validatedStep});
    }

    renderStep(){
        let steps = [];
        this.props.steps.forEach((step) => {
            steps.push(<Step key={step.key}>
                <StepLabel>{step.label}</StepLabel>
            </Step>)
        });
        return steps;
    }

    render() {
        return (
            <div>
                { this.state.validatedStep !== null ?
                    <Stepper activeStep={this.state.validatedStep}>
                        {this.renderStep()}
                    </Stepper>
                : null }
            </div>
        );
    }

});
