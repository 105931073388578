import React, {Component} from 'react';
import './logout.css';
import User from "../../Services/User/User";
import App from "../../Config/App";
import Modal from "../../Services/Modal";

export class Logout extends Component{
    constructor(props) {
        super(props);
        User.logout();
        Modal.close();
    }
    render(){
        return(
            <div className="container logout">
                <div className="icon"><i className="fa fa-5x fa-sign-out-alt"/></div>
                <div className="item">You are now logged out from this application.</div>
                <a href={App.backend + '/auth/login'}>Login</a>
            </div>
        );
    }
}