import { getIdFromIri } from "../../Services/utils";

/**
 * Transforme la collection de Entities en arbre.
 *
 * @param {Array[Object]} list Collection de Entities
 * @param {string} [childrenPropertyName] Nom de la propriété qui contient les enfants, défaut: 'children'
 * @param {boolean} asyncLoading Indique que l'arbre n'est pas encore complet
 * @param {string} resourceLabel Champ qui contient le label (utilisé pour remplacer le texte en cas de asyncLoading)
 */
export const entitiesToTree = (
  list,
  childrenPropertyName = "children",
  asyncLoading = false,
  resourceLabel = "title"
) => {
  var map = {},
    roots = [],
    childrenIds = [];

  list.forEach((o) => {
    let obj = { ...o };
    map[obj.id] = obj;
    obj.childEntitiesObj = [];
    obj.childEntitiesIds = [];
    obj.parent = undefined;
    obj[childrenPropertyName].forEach((c) => {
      let id = getIdFromIri(c);
      childrenIds.push(id);
      obj.childEntitiesIds.push(id);
    });
  });

  Object.entries(map).forEach(([key, obj]) => {
    obj.childEntitiesIds.forEach((id) => {
      let child = map[id];
      if (child) {
        obj.childEntitiesObj.push(child);
        child.parent = obj;
      } else if (asyncLoading) {
        obj.childEntitiesObj.push({
          id,
          parent: obj,
          [resourceLabel]: "loading...",
          notLoaded: true,
        });
      }
    });

    if (!childrenIds.includes(key)) {
      roots.push(obj);
    }
  });

  return roots;
};

/**
 * Renvoie la liste des parents d'un noeud donné.
 *
 * NB: Le noeud doit posséder un lien vers son parent, stocké dans la propriété
 *     que l'on passe à parentPropertyName
 *
 * @param {} node Noeud de départ
 * @param {} parentPropertyName default "parent"
 */
export const nodeGetParents = (node, parentPropertyName = "parent") => {
  return node.parent
    ? [node[parentPropertyName], ...nodeGetParents(node[parentPropertyName])]
    : [];
};

/**
 * Aplatit un arbre pour renvoyer une liste de tous les noeuds de l'arbre.
 *
 * Les noeuds doivent posséder la liste de leurs enfants dans la propriété donnée
 * dans childrenPropertyName.
 *
 * @param {Array} subtree Arbre à aplatir
 * @param {} childNodePropertyName nom de la propriété du noeud qui contient les noeuds enfants, default children
 */
export const flattenTree = (subtree, childrenPropertyName = "children") => {
  return subtree
    ? subtree.reduce(
        (acc, obj) => [
          ...acc,
          obj,
          ...flattenTree(obj[childrenPropertyName], childrenPropertyName),
        ],
        []
      )
    : [];
};

/**
 * Adapte un arbre de Scopes à un arbre standard pour TreeView
 *
 * @param {*} tree Arbre de Scopes
 * @param {} childrenObjsPropertyName nom de la propriété du noeud qui contient les objets enfants, default childrenObjs
 */
export const adaptTreeToTreeView = (
  tree,
  childrenObjsPropertyName = "childrenObjs",
  resourceLabel = "title"
) => {
  const tv = tree.map((obj) => {
    return {
      key: String(obj.id),
      nodeId: String(obj.id),
      objId: obj.id,
      obj: obj,
      name: obj[resourceLabel],
      children: obj[childrenObjsPropertyName]
        ? adaptTreeToTreeView(
            obj[childrenObjsPropertyName],
            childrenObjsPropertyName,
            resourceLabel
          )
        : [],
    };
  });
  return tv;
};