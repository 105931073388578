/**
 * Si on importe ce module, alors la console sera sauvegardée dans consoleBuffer,
 * en plus d'être affichée ...  dans la console.
 */
const consoleBuffer = [];

//  Remplacement de console.log pour pouvoir récupérer son contenu
window.console = (function(console){
    // Limit buffer to 40 logs :
    if (consoleBuffer.length === 40) consoleBuffer.pop();

    return {
        log: function(...args){
            const text = args.shift()
            console.log(text, ...args);
            consoleBuffer.push(text);
        },
        info: function (...args) {
            const text = args.shift()
            console.info(text, ...args);
            consoleBuffer.push(text);
        },
        warn: function (...args) {
            const text = args.shift()
            console.warn(text, ...args);
            consoleBuffer.push(text);
        },
        error: function (...args) {
            const text = args.shift()
            console.error(text, ...args);
            consoleBuffer.push(text);
        }
    };
}(window.console));

window.addEventListener('error', function(event) {
    consoleBuffer.push(event.message);
})

export { consoleBuffer };
