import React from 'react';
import { APIResource } from '../Services/APIResource/APIResource';

export const ApiResourceDefaultParams = {
    id: 'review_model_uses',
};

export default class ReviewModelUseAdmin {

    constructor() {
        new APIResource(ApiResourceDefaultParams);
    }
}
