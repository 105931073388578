import RouteStore, {Route} from "../../Store/RouteStore";
import React from "react";
import RecommendationImport from "./RecommmendationImport";

RouteStore.routes.importRecommendation = new Route({
    path: '/import/recommendation',
    component: (props) => <RecommendationImport {...props}/>,
    menuItem: {
        title: 'Import'
    }
});
