import React from 'react';
import { Button, styled } from '@material-ui/core';

const SimpleButton = styled(Button)({
    margin: '1',
});

export const OpenButton = (props) => (
    <SimpleButton variant="contained" color="primary" className="button-table show" {...props}>
        { props.children || "Open"}
    </SimpleButton>
);
