import React from "react";

class Float {
    format(nb,decimal){
        if(nb){
            return parseFloat(nb).toFixed(decimal)
        }else{
            return null;
        }
    }
}

export default new Float();