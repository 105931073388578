import React from 'react';
import { EntitySelect } from '../../../Components/Forms/EntitySelect/EntitySelect';
import APIResourceStore from '../../../Store/APIResourceStore';
import { parseEmails } from '../../utils';
import EntityAsyncProvider from './EntityAsyncProvider';
import {CONTEXT_LIST} from "../APIResource";
import {EntitySelectAsync} from "../../../Components/Forms/EntitySelectAsync/EntitySelectAsync";
import UserProvider from "./UserProvider";

class UserAsyncProvider {
    getDisplay(field, value, entity, props, resource, context) {
        return EntityAsyncProvider.getDisplay(field, value, entity, props, resource, context);
    }

    getDisplayList(field, value, entity, props, resource) {
        return UserProvider.getDisplayList(field, value, entity, props, resource);
    }

    getEdit(field, value, onChange, entity, routeParams, loading = false) {
        return EntityAsyncProvider.getEdit(field, value, onChange, entity, routeParams, loading);
    }

    getFilter(options = {}) {
        const { field, value, onChange } = options;

        const onInputChange = (inputValue) => {
            let input;

            const foundEmails = parseEmails(inputValue);
            if (!foundEmails.length) {
                input = inputValue;
            } else {
                // On récupères les IRI des users associés
                input = APIResourceStore.resources.users_all
                    .filterItems((user) => foundEmails.includes(user.email))
                    .map((user) => user['@id'] );
                if (input.length) {
                    onChange([...(value || []), ...input]);
                    input = '';
                } else {
                    input = inputValue;
                }
            }
            return input;
        }

        return (
            <EntitySelectAsync
                resourceId={field.params.resource}
                instanceId={field.params.instanceId}
                resourceLabel={field.params.editDisplayField !== undefined ? field.params.editDisplayField : field.params.displayField}
                value={value}
                onChange={onChange}
                onInputChange={onInputChange}
                context="filter"
                clearable={!('required' in field && field.required === true)}
                multi={field.params.multi}
                filters={field.params.filters}
                required={'required' in field && field.requiredComputed === true}
                error={!!field.errorHelperText}
                className={field.errorHelperText ? 'field-error-control' : ''}
                helperText={field.errorHelperText}
                endpoints={field.params.endpoints}
                noOption={field.params.hasOwnProperty('noOption') ? field.params.noOption : true}
            />
        );
    }
}

export default new UserAsyncProvider();
