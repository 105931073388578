import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import Modal from "../../../Modal";
import {APIResource} from "../../APIResource";
import Navigation from "../../../Navigation";

export class LocalCurrentEditing extends Component{
    constructor(props){
        super(props);
        this.restore = this.restore.bind(this);
        this.cancel = this.cancel.bind(this);

        this.resource = null;
        this.state = {
            title: null,
            date: null,
            restoreRoute: null
        };
    }

    componentDidMount() {
        this.resource = new APIResource({instanceId: this.props.local.instanceId, id: this.props.local.entityType, name: this.props.local.resourceName, fieldForTitle: 'title'});
        let date = new Date(this.props.local.date);
        date = date.toDateString() + ' at ' + date.toLocaleTimeString();
        let restoreRoute = this.props.local.entity.id ? '/resource/' + this.resource.instanceId + '/' + this.props.local.entity.id + '/edit' : '/resource/' + this.resource.instanceId + '/add';
        if(this.resource.fieldForTitle && this.props.local.entity[this.resource.fieldForTitle]){
            this.setState({title: this.props.local.entity[this.resource.fieldForTitle], date: date, restoreRoute: restoreRoute});
        }
        else{
            this.setState({title: '#' + this.props.local.entity.id, date: date, restoreRoute: restoreRoute});
        }
    }

    restore(){
        Navigation.router.history.push(this.state.restoreRoute);
        Modal.close();
    }

    cancel(){
        Modal.close();
        APIResource.deleteLocalCurrentEditingCopy();
        window.location.reload();
    }

    render() {
        return(
            <div>
                <p>You were interruped while you were editing an entity.</p>
                <p><strong>Entity type: </strong>{this.props.local.resourceName}</p>
                <p><strong>Title: </strong>{
                    this.state.title ? this.state.title : null
                }</p>
                <p><strong>Version date: </strong>{this.state.date}</p>
                <p>Do you want to restore this entity at its latest edition state?</p>
                <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Button style={{marginTop: 10}} variant="contained" color="secondary" className="save button-general" onClick={this.cancel}>
                        Cancel
                    </Button>
                    <Button style={{marginTop: 10}} variant="contained" color="secondary" className="save button-general" onClick={this.restore}>
                        Restore
                    </Button>
                </div>
            </div>
        );
    }
}