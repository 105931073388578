import React from 'react';

class String {
    /**
     * @param {string} text 
     */
    fmtGuide(text) {
        // add new line before numbered bullet points
        const regexBulletPoints = /([0-9]:|- |[0-9]\. )/g;
        const regexBr = /<(br[ /]*)>/g;
        const cleanedText = text
            .replace(regexBulletPoints, '\n$1')
            .replace(regexBr, '\n')
        ;
        return this.nlToBr(cleanedText);
    }

    nlToBr(str) {
        if (!str) {
            return null;
        }
        return str.split('\n').map((item, key) => {
            return (
                <span key={key}>
                    {item}
                    <br />
                </span>
            );
        });
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    random(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}

export default new String();
