import React, { Component } from "react";
import Modal from "../../Services/Modal";
import FormControl from "@material-ui/core/FormControl";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {DatePicker} from "../Forms/DatePicker/DatePicker";
import {DisplayTextField} from "../Display/DisplayTextField/DisplayTextField";
import Alert from "../../Services/Alert";
import { ModalContent } from "../Modal/ModalContent";
import { ButtonBar } from "../Modal/ButtonBar";
import { ActionButton } from "../Modal/ActionButton";
import ModalComplementary from "../../Services/ModalComplementary";
import DateFormatter from "../../Services/DateFormatter";

export class PostponeRequestForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deadline: DateFormatter.toOnlyDateInUTC(this.props.entity.deadline) ?? null,
            revisedDeadline: DateFormatter.toOnlyDateInUTC(this.props.entity.revisedDeadline) ?? null,
            justification: this.props.entity.revisedDeadlineJustification ?? null,
            requestApproval: this.props.requestApproval,
        };
    }

    handlePostponeRequest(){
        if (
            this.state.revisedDeadline === null
            || this.state.justification === null
        ) {
            Alert.show({
                message: "The new deadline date and the justification are required.",
                type: "error"
            });
            return;
        }


        // Add Dates checks
        if (
            this.state.revisedDeadline
            && this.state.deadline
            && (this.state.revisedDeadline < this.state.deadline)
        ) {
            Alert.show({
                type: "error",
                message: "The new proposed deadline can't precede the deadline",
            });
            return;
        }

        this.setState({progress: true});

        this.props.entity.revisedDeadline = this.state.revisedDeadline;
        this.props.entity.revisedDeadlineJustification = this.state.justification;
        this.props.entity.revisedDeadlineAuthorRole = this.props.userBusinessRole;
        this.props.entity.revisedDeadlineWaitingForApproval = true;

        this.save("Your request has been sent.");
    }

    handlePostponeApprove(){
        this.setState({progress: true});
        this.props.entity.revisedDeadlineWaitingForApproval = false;

        this.save("Postpone request has been approved.");
    }

    /**
     * Modifier la revisedDeadline revient à déclencher une nouvelle requête de postponement.
     * 
     * @param {Date} newRevisedDeadline 
     */
    handleRevisedDeadline(newRevisedDeadline){
        if (newRevisedDeadline?.toLocaleDateString() !== new Date(this.props.entity?.revisedDeadline).toLocaleDateString()){
            this.setState({revisedDeadline: newRevisedDeadline, requestApproval: false});
        } else {
            this.setState({revisedDeadline: newRevisedDeadline, requestApproval: true});
        }
    }

    save(message){
        //@todo: probleme de refresh de l'objet si on passe en édition tout de suite après
        this.props.resource.apiPut(this.props.entity).then(() => {
            Alert.show({ message: message, type: "success" });
            this.props.resource.apiGetOne(this.props.entity.id, true).then((entity) => {
                ModalComplementary.close();
                if (this.props.parentResourceComponent) {
                    this.props.parentResourceComponent.entity = entity;
                    this.props.parentResourceComponent.forceUpdate();
                }
                this.setState({progress: false});
            });
        }).catch(err => {
            console.error(err);
            ModalComplementary.close();
            this.setState({progress: false});
        });
    }

    render() {
        return (
            <ModalContent>
                <DisplayTextField
                    fieldName={'Deadline'}
                    fieldType={'date'}
                    value={this.props.entity.deadline ?? ''}
                />
                <DatePicker
                    label={'New proposed deadline'}
                    minDate={this.props.entity.deadline ?? ''}
                    value={this.state.revisedDeadline}
                    minDateMessage={'The new proposed deadline should not precede the deadline'}
                    onChange={(value) => this.handleRevisedDeadline(value)}
                    required={true}
                />
                {
                    this.state.requestApproval ?
                        <DisplayTextField
                            fieldName={'Justification'}
                            value={this.props.entity.revisedDeadlineJustification ?? ''}
                            entity={this.props.entity}
                        />
                        :
                        <FormControl className="container_textarea">
                            <label>Justification *</label>
                            <TextareaAutosize
                                value={this.state.justification ? this.state.justification : ""}
                                onChange={event => this.setState({ justification: event.target.value })}
                                rows={5}
                                rowsMax={10}
                                required={true}
                            />
                        </FormControl>
                }
                <ButtonBar>
                    <ActionButton
                        onClick={() => {
                            if(this.state.requestApproval){
                                this.handlePostponeApprove();
                            }else{
                                this.handlePostponeRequest();
                            }
                        }}
                        loading={this.state.progress}
                    >
                        Confirm the date
                    </ActionButton>
                </ButtonBar>
            </ModalContent>
        );
    }
}
