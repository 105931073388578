import React from 'react';
import PropTypes from 'prop-types';
import { DeleteForm } from '../Forms/DeleteForm/DeleteForm';
import APIResourceStore from '../../Store/APIResourceStore';
import ModalDocument from '../../Services/ModalDocument';

export const DeleteDocumentForm = (props) => {
    const { document, resource, callback, onCancelCallback = ModalDocument.close } = props;

    return <DeleteForm
        bulk={false}
        goBackToListView={false}
        entity={document}
        entityTitle={document.title ?? document.documentName}
        titleOverride={"You are about to delete this document, do you confirm ?"}
        resource={resource || APIResourceStore.resources.documents}
        callback={callback}
        onCancelCallback={onCancelCallback}
    />
}
DeleteDocumentForm.propTypes = {
    document: PropTypes.shape({title: PropTypes.string, documentName: PropTypes.string}),
    resource: PropTypes.any,
    callback: PropTypes.func,
    onCancelCallback: PropTypes.func,
}