import React from 'react';
import {APIResource, CONTEXT_PRELOAD} from '../Services/APIResource/APIResource';
import {userHasRoleADMIN} from "../Store/ParameterStore";

export const ApiResourceDefaultParams = {
    id: 'notifications',
    instanceId: 'notifications-all',
    name: 'Notifications',
    preLoad: true,
    endpoints: {
        getAll: 'notifications/all',
    },
};

export default class NotificationAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        if(!userHasRoleADMIN()){
            return;
        }

        let notifications = new APIResource(ApiResourceDefaultParams);
        notifications
            .setFields({
                id: {
                    title: 'ID'
                },
                fromUser: {
                    title: 'FromUser',
                    type: 'user',
                    params: {
                        resource: 'users',
                        displayField: 'toString',
                        editDisplayField: 'fullNameWithTeam',
                        sortField: 'lastName',
                        endpoints: {
                            getAll: 'users/all-users/all',
                        },
                    },
                },
                toUser: {
                    title: 'ToUser',
                    type: 'user',
                    params: {
                        resource: 'users',
                        instanceId: 'users_all',
                        displayField: 'toString',
                        editDisplayField: 'fullNameWithTeam',
                        sortField: 'lastName',
                        endpoints: {
                            getAll: 'users/all-users/all',
                        },
                    },
                },
                content: {
                    title: 'Content',
                },
                link: {
                    title: 'Link',
                },
                byEmail: {
                    title: 'ByEmail',
                    type: 'bool'
                },
                status: {
                    title: 'Status',
                },
                date: {
                    title: 'Date',
                    type: 'date'
                },
                readDate: {
                    title: 'ReadDate',
                    type: 'date'
                },
                deletedDate: {
                    title: 'DeletedDate',
                    type: 'date'
                },
            })
            .setLayout({
                tabs: {
                    Notification: {
                        rows: [
                            {
                                panels: {
                                    Properties: {
                                        cols: 6,
                                        fields: [
                                            'fromUser',
                                            'toUser',
                                            'content',
                                            'link',
                                            'byEmail',
                                            'status',
                                            'date',
                                            'readDate',
                                            'deletedDate',
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                menuItem: { title: 'Notifications' },
            })
            .genDetailView({
                menuItem: { title: 'Notifications' },
            })
        ;
        notifications.apiGetCollection({ page: 1, rowsPerPage: -1, context: CONTEXT_PRELOAD });
    }
}
