import React from 'react';
import {APIResource} from "../Services/APIResource/APIResource";
import {
    DOCUMENT_ACTION_ADD, DOCUMENT_ACTION_DELETE, DOCUMENT_ACTION_LIST,
    DOCUMENT_ACTION_SHOW,
    DocumentManager
} from "../Components/Display/DocumentManager/DocumentManager";
import ParameterStore, {
    userHasInChargeRights,
    userHasNoticeOwnershipRights, userHasRoleIG,
    userHasRoleMRM, userHasValidatorRights, userHasValidatorTeamRights, userValidatorTeamManagedRights
} from "../Store/ParameterStore";
import User from "../Services/User/User";
import APIResourceStore from "../Store/APIResourceStore";
import Navigation from "../Services/Navigation";
import TextareaProvider from "../Services/APIResource/FieldProviders/TextareaProvider";
import DateProvider from "../Services/APIResource/FieldProviders/DateProvider";
import Breadcrumb from "../Components/Breadcrumb/Breadcrumb";

const requiredByRole = (entity, property) => {
    if(
        (
            userHasValidatorRights(User.getId(), entity)
            || userHasInChargeRights(User.getId(), entity)
            || userHasValidatorTeamRights(User.profile.teams, entity)
            || userValidatorTeamManagedRights(User.profile.managedScopes, entity)
            || userHasRoleMRM()
        )
        && ['notice', 'actionDeadline', 'actionPlan', 'deliverables'].includes(property)
    ){
        return true;
    }

    if(
        (
            userHasNoticeOwnershipRights(User.getId(), entity)
            || userHasRoleMRM()
        )
        && ['deliveryComment'].includes(property)
    ){
        return true;
    }

    return false;
}

const validationByRole = (entity) => {
    let errors = [];
    let detailViewTab = null;
    if(entity.status === ParameterStore("NOTICE_STATUS_WAITING_FOR_VALIDATION")){
        if(
            typeof entity.documentsEntities === "undefined"
            || entity.documentsEntities.length === 0
        ){
            errors.push({
                field: "Document",
                detail: "At least one document is required at this stage.",
            });
            detailViewTab = 'Documents'
        }
    }

    if (errors.length > 0) {
        if (detailViewTab !== null) {
            Navigation.router.history.push(
                `/resource/notices/${entity.id}/detail?tab=${detailViewTab}`
            );
        }
        return errors;
    }
    return true;
}

const onInit = ({ entity, routeParams }) => {
    if(!entity.id){
        entity['@type'] = 'NoticeAction';
    }
    entity.notice = routeParams.noticeId ? '/api/notices/' + routeParams.noticeId : entity.notice;
    if(entity.notice){
        let notice = APIResourceStore.resources.notices.getObservableItemByPath(entity.notice);
        //Pour la gestion des droits sur cette action
        entity.assignedValidator = notice.assignedValidator;
        entity.noticeOwners = notice.noticeOwners;
        entity.validatorTeams = notice.validatorTeams;
    }
}

const additionalLinkButton = (entity, resource) => {
    let additionalActionButtons = [];

    let notice = entity.notice;
    if(notice){
        notice = notice.split('/');
        let noticeId = notice[notice.length - 1];
        additionalActionButtons.push({
            link:
                '/resource/notices/' +
                noticeId +
                '/detail?tab=Action plan',
            tooltip: 'Associated notice',
            icon: 'link',
        });
    }

    return additionalActionButtons;
};

export const ApiResourceDefaultParams = {
    id: 'notice_actions',
    name: 'Actions',
    breadcrumbName: "Action",
    componentForTitle: (entity, resource, resourceEditComponent) => {
        return <Breadcrumb entity={entity} resource={resource} resourceEditComponent={resourceEditComponent} relationalProperty={'notice'} resourcePath={'notices'} relationalLabel={'Notice'} />
    },
};

export default class NoticeActionAdmin {
    constructor(){
        this.configure();
    }

    configure(){
        let resourceNoticeActions = new APIResource(ApiResourceDefaultParams);
        resourceNoticeActions
            .setFields({
                id: {title: 'ID'},
                notice: {
                    title: 'Notice',
                    type: 'entity',
                    params: {
                        resource: 'notices',
                        displayField: 'toString',
                        links: true,
                        endpoints: {
                            getAll: 'i_t_systems/all-i_t_systems',
                        },
                    },
                },
                actionDeadline: {
                    title: 'Action deadline',
                    type: 'date',
                    helperText: 'Planned delivery date of the expected elements',
                    required: (entity) => requiredByRole(entity, 'actionDeadline'),
                    edit: (field, value, onChange, entity, routeParams) => {
                        if(requiredByRole(entity, 'actionDeadline')){
                            return DateProvider.getEdit(field, value, onChange, entity, routeParams);
                        }else{
                            return DateProvider.getDisplay(field, value, entity, {});
                        }
                    }
                },
                deliveryDate: {
                    title: 'Delivery date',
                    type: 'date',
                    helperText: 'Effective delivery date of the expected elements',
                    required: (entity) => requiredByRole(entity, 'deliveryDate'),
                    edit: (field, value, onChange, entity, routeParams) => {
                        return DateProvider.getDisplay(field, value, entity, {});
                    }
                },
                actionPlan: {
                    title: 'Action plan',
                    type: 'textarea',
                    helperText: 'Description of the action plan proposed by LoD 1 in order to remediate the notice',
                    required: (entity) => requiredByRole(entity, 'actionPlan'),
                    edit: (field, value, onChange, entity, routeParams) => {
                        if(requiredByRole(entity, 'actionPlan')){
                            return TextareaProvider.getEdit(field, value, onChange, entity, routeParams);
                        }else{
                            return TextareaProvider.getDisplay(field, value, entity, {});
                        }
                    }
                },
                deliverables: {
                    title: 'Deliverables',
                    type: 'textarea',
                    helperText: 'Description of the expected elements resulting from the action plan execution',
                    // required: (entity) => requiredByRole(entity, 'deliverables'),
                    edit: (field, value, onChange, entity, routeParams) => {
                        if(requiredByRole(entity, 'deliverables')){
                            return TextareaProvider.getEdit(field, value, onChange, entity, routeParams);
                        }else{
                            return TextareaProvider.getDisplay(field, value, entity, {});
                        }
                    }
                },
                deliveryComment: {
                    title: 'Delivery comment',
                    type: 'textarea',
                    helperText: 'Comment provided by LoD 1 when completing the action',
                    // required: (entity) => requiredByRole(entity, 'deliveryComment'),
                    edit: (field, value, onChange, entity, routeParams) => {
                        if(requiredByRole(entity, 'deliveryComment')){
                            return TextareaProvider.getEdit(field, value, onChange, entity, routeParams);
                        }else{
                            return TextareaProvider.getDisplay(field, value, entity, {});
                        }
                    }
                },
                documentsEntities: {
                    title: 'Document',
                    display: (field, value, entity, props) =>
                        <DocumentManager
                            values={value}
                            entity={entity}
                            entityResource={"notice_actions"}
                            limit={1}
                            allowedAction={(entity, document, action) => {
                                if (action === DOCUMENT_ACTION_ADD) {
                                    return userHasNoticeOwnershipRights(User.getId(), entity)
                                        || userHasRoleMRM()
                                        ;
                                }else if(action === DOCUMENT_ACTION_SHOW || action === DOCUMENT_ACTION_LIST){
                                    return userHasNoticeOwnershipRights(User.getId(), entity)
                                        || userHasInChargeRights(User.getId(), entity)
                                        || userHasRoleMRM()
                                        || userHasRoleIG()
                                        ;
                                }else if(action === DOCUMENT_ACTION_DELETE){
                                    return userHasNoticeOwnershipRights(User.getId(), entity)
                                        || userHasRoleMRM()
                                        ;
                                }
                                return false;
                            }}
                        />,
                },
            })
            .setLayout({
                tabs: {
                    'General': {
                        rows: [
                            {
                                panels: {
                                    'Action plan': {
                                        cols: 6,
                                        fields: ['notice', 'actionDeadline', 'actionPlan', 'deliverables']
                                    },
                                }
                            },
                            {
                                panels: {
                                    'Delivery': {
                                        cols: 6,
                                        fields: ['deliveryDate', 'deliveryComment', 'documentsEntities']
                                    },
                                }
                            }
                        ]
                    },
                    'Documents': {
                        rows: [
                            {
                                panels: {
                                    'Documents': {
                                        cols: 12,
                                        fields: []
                                    }
                                }
                            }
                        ]
                    },
                }
            })
            .genInsertView({
                menuItem: {title: 'Add'},
                additionalRoutes: ['/resource/notice_actions/add/:noticeId'],
                onInit: onInit,
            })
            .genEditView({
                fields: ['actionDeadline', 'deliveryDate', 'actionPlan', 'deliverables', 'deliveryComment'],
                onInit: onInit,
            })
            .genDetailView({
                fields: ['actionDeadline', 'deliveryDate', 'actionPlan', 'deliverables', 'deliveryComment', 'documentsEntities'],
                additionalLinkButton: additionalLinkButton,
                itemAccessCondition: (entity) => {
                    return entity.amIModelOwner
                        || entity.amIModelOwnerDelegated
                        || userHasNoticeOwnershipRights(User.getId(), entity)
                        || entity.amIMemberOfDeveloperTeam
                        || userHasValidatorRights(User.getId(), entity)
                        || userValidatorTeamManagedRights(User.profile.managedScopes, entity)
                        || entity.amIBusinessSponsor
                        || userHasRoleMRM();
                }
            })
            .setValidation(validationByRole)
        ;
    }
}
