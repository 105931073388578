import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Grid from "@material-ui/core/Grid";
import {
    List,
    ListItem,
    IconButton,
    ListItemText,
    ListItemSecondaryAction,
    Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { APIResource } from "../../../Services/APIResource/APIResource";
import Navigation from "../../../Services/Navigation";
import String from "../../../Services/String";

import Http from "../../../Services/Http";
import { isThisSecond } from "date-fns";

export class NotificationWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notifications: []
        };

        this.notificationsResource = new APIResource({
            id: "notifications"
        });
    }

    componentDidMount() {
        if (this.props.notifications != undefined) {
            this.setState({ notifications: this.props.notifications });
        } else {
            this.getNotifications({ forceReload: false });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.notifications &&
            this.props.notifications != prevProps.notifications
        ) {
            this.setState({ notifications: this.props.notifications });
        }
    }

    getNotifications = (options = { forceReload: true }) => {
        options.rowsPerPage = 1000;
        this.notificationsResource.apiGetCollection(options).then(notifications => {
            if (!notifications) {
                return;
            }
            this.setState({ notifications });
        });
    };

    markAsRead = index => {

        const { notifications } = this.state;
        notifications[index].status = "read";
        this.setState({ notifications });
        Http.get('notifications/'+notifications[index]['id']+'/read').then(() => { });
        if (notifications[index].link) {
            Navigation.router.history.push(notifications[index].link);
        }
        if (this.props.onNotificationClick) {
            this.props.onNotificationClick(notifications[index]);
        }
    };

    handleNotificationDelete = async index => {
        const { notifications } = this.state;
        const notification = notifications[index];
        notifications.splice(index, 1);
        this.setState({ notifications });
        this.notificationsResource.apiDelete(notification);
    };

    handleDownload = async index => {
        const { notifications } = this.state;
        const notification = notifications[index];
        Http.openEncryptedFile(
            '/document/download/' + notification.downloadLink.split('#')[0],
            notification.downloadLink.split('#')[1]
        );
    };

    deleteAll = () => {
        this.state.notifications.forEach(
            n => this.notificationsResource.apiDelete(n)
        );
        this.setState({notifications: []});
    }

    renderlist() {
        const { notifications } = this.state;
        if (!notifications) {
            return;
        }
        let items = [];
        if (notifications.length < 1) {
            return (
                <ListItem>
                    <ListItemText primary="You don't have any notification." />
                </ListItem>
            );
        }
        for (let i = 0; i < notifications.length; i++) {
            items.push(this.renderlistItem(notifications[i], i));
        }

        return items;
    }

    renderSubText = notif => {
        let text = "";
        text +=
            notif.fromUser && notif.fromUser.fullName
                ? "From " + notif.fromUser.fullName
                : "";
        text += notif.date ? " on " + notif.date : "";
        return text;
    };

    renderlistItem(notif, index) {
        return (
            <ListItem
                key={index}
                selected={notif.status === "new"}
                style={styles.listItem}
                onClick={() => this.markAsRead(index)}
            >
                <ListItemText
                    primary={String.nlToBr(notif.content)}
                    secondary={this.renderSubText(notif)}
                />
                <ListItemSecondaryAction>
                    {notif.downloadLink ?
                        <IconButton
                            edge="end"
                            aria-label="download"
                            color="primary"
                            onClick={() => this.handleDownload(index)}
                            style={styles.downloadButton}
                        >
                            <CloudDownloadIcon style={styles.downloadIcon} />
                        </IconButton>
                    : null }

                    <IconButton
                        edge="end"
                        aria-label="delete"
                        color="primary"
                        onClick={() => this.handleNotificationDelete(index)}
                        style={styles.deleteButton}
                    >
                        <CloseIcon style={{ fontSize: 12 }} />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        );
    }
    render() {
        return (
            <div>
                <Grid item xs={12}>
                    <div className={"widget-notification"}>
                        <List dense={true} style={styles.list}>
                            <DeleteAllNotificationsButton 
                                notifications={this.state.notifications}
                                deleteAll={() => this.deleteAll()}    
                            />
                            {this.renderlist()}
                        </List>
                    </div>
                </Grid>
            </div>
        );
    }
}

const DeleteAllNotificationsButton = (props) => {
    const { notifications, deleteAll } = props;

    return (notifications.length > 0 &&
        <ListItem style={styles.deleteAllButton}>
            <Button
                size={"small"}
                variant={"outlined"}
                color={"secondary"}
                onClick={() => deleteAll()}
            >
                Delete all
            </Button>
        </ListItem>
    );
};
DeleteAllNotificationsButton.propTypes = {
    notifications: PropTypes.array,
    deleteAll: PropTypes.func,
}

const styles = {
    actionLink: {
        marginLeft: 5
    },
    deleteAllButton: {
        alignItems: "flex-end", 
        flexDirection: "column"
    },
    downloadButton: {
        marginRight: 10
    },
    downloadIcon: {
        fontSize: 24,
    },
    deleteButton: {},
    list: { paddingTop: 0, border: "1px solid #ddd" },
    listItem: {
        transition: "200ms ease-out all",
        cursor: "pointer"
    }
};
