import React, { Component } from "react";
import Modal from "../../Services/Modal";
import {DetailButton} from "../Buttons/DetailButton";
import {EditButton} from "../Buttons/EditButton";
import {
    ResourceDetail
} from "../../Services/APIResource/Components/ResourceDetail/ResourceDetail";
import {ResourceEdit} from "../../Services/APIResource/Components/ResourceEdit/ResourceEdit";
import {APIResource, CONTEXT_ADD, CONTEXT_DETAIL, CONTEXT_EDIT, CONTEXT_CUSTOM} from "../../Services/APIResource/APIResource";
import APIResourceStore from "../../Store/APIResourceStore";
import Button from "@material-ui/core/Button";
import ModalComplementary from "../../Services/ModalComplementary";

const ModalResourceEdit = ({ modal, resource, parentResource, parentResourceComponent }) => {
    return (
        <ResourceEdit
            inModal={true}
            openModalComponent={modal}
            resource={resource}
            context={modal.props.context}
            match={{ params: { id: modal.props.id, ...modal.props.routeParams } }}
            postSaveRedirect={(resource, item) => {
                if (parentResource) {
                    parentResource.apiGetOne(modal.props.parentId, true).then((e) => {
                        if(parentResourceComponent){
                            parentResourceComponent.entity = e;
                            parentResourceComponent.forceUpdate();
                        }
                        if(modal.props.newLayer) {
                            ModalComplementary.close()
                        }else{
                            Modal.close()
                        }
                    });
                } else {
                    if(modal.props.newLayer) {
                        ModalComplementary.close()
                    }else{
                        Modal.close()
                    }
                }
                if(modal.props.postSaveRedirectUrl){
                    return modal.props.postSaveRedirectUrl;
                }else if(typeof modal.props.postSaveRedirectAction === 'function'){
                    modal.props.postSaveRedirectAction(item);
                }
            }}
        />
    );
};

const ModalResourceDetail = ({ modal, resource, objectId}) => {
    return (
        <ResourceDetail
            inModal={true}
            openModalComponent={modal}
            resource={resource}
            objectId={objectId}
            key={'resource_detail_modal'}
        />
    );
};

const ModalChildrenRender = ({ modal, resource, parentResource, objectId, parentResourceComponent }) => {
    if (modal.props.context === CONTEXT_ADD || modal.props.context === CONTEXT_EDIT) {
        return <ModalResourceEdit modal={modal} resource={resource} parentResource={parentResource} parentResourceComponent={parentResourceComponent} />;
    } else if (modal.props.context === CONTEXT_CUSTOM) {
        return modal.props.customInnerView;
    } else {
        return <ModalResourceDetail modal={modal} resource={resource} objectId={objectId} />;
    }
};

export const ModalInnerView = ({ modal, resource, parentResource, objectId, parentResourceComponent }) => {
    return (
        <div id={'open-modal-container'}>
            <ModalChildrenRender
                modal={modal}
                resource={resource}
                parentResource={parentResource}
                objectId={objectId}
                parentResourceComponent={parentResourceComponent}
            />
        </div>
    );
};

export const OpenModalForEntities = ({context, instanceId, entity, value, resourceDetailComponent, resource = null}) => {
    let id = undefined;
    let modalTitle = undefined;
    let allowStayInModal = undefined;
    let parentInstanceId = undefined;
    let parentId = undefined;
    let postSaveRedirectUrl = undefined;

    if(instanceId === 'model_uses'){
        id = value.id;
        modalTitle = value.toString;
        allowStayInModal = true;
        parentInstanceId = resource ? resource.instanceId : 'models';
        parentId = entity.id;
        postSaveRedirectUrl = `/resource/${parentInstanceId}/${entity.id}/detail?tab=Uses`;
    }else if(instanceId === 'tierings'){
        id = value.id;
        modalTitle = value.toString;
        allowStayInModal = true;
        parentInstanceId = resource ? resource.instanceId : 'models';
        parentId = entity.id;
        postSaveRedirectUrl = `/resource/${parentInstanceId}/${entity.id}/detail?tab=Tiering`;
    }else if(instanceId === 'implementations'){
        id = value.id;
        modalTitle = value.toString;
        allowStayInModal = true;
        parentInstanceId = resource ? resource.instanceId : 'models';
        parentId = entity.id;
        postSaveRedirectUrl = `/resource/${parentInstanceId}/${entity.id}/detail?tab=Implementations`;
    }else if(instanceId === 'mitigation_actions'){
        id = value.id;
        modalTitle = value.mitigationMeasureTypeString;
        allowStayInModal = true;
        parentInstanceId = resource ? resource.instanceId : 'models';
        parentId = entity.id;
        postSaveRedirectUrl = `/resource/${parentInstanceId}/${entity.id}/detail?tab=Mitigation actions`;
    }else if(instanceId === 'findings'){
        id = value.id;
        modalTitle = value.title;
        allowStayInModal = true;
        parentInstanceId = 'reviews';
        parentId = entity.id;
        postSaveRedirectUrl = '/resource/reviews/' + entity.id + '/detail?tab=Notices';
    }else{
        return null;
    }

    return (
        <OpenModal
            instanceId={instanceId}
            id={id}
            context={context}
            modalTitle={modalTitle}
            allowStayInModal={allowStayInModal}
            //Pour le context d'édition ou en cas de d'édition depuis la modal detail
            parentInstanceId={parentInstanceId}
            parentId={parentId}
            postSaveRedirectUrl={postSaveRedirectUrl}
            parentResourceComponent={resourceDetailComponent}
        />
    );
}

/**
 * @todo refactor pour ne plus passer des props mystère par le component, mais demander explicitement
 *       et supprimer le component qui ne sert pas ici.
 * @param {{props: {parentInstanceId: any, context: string, instanceId: string, modalTitle: string, customFirstRessourceName: string, id: any, parentResourceComponent: any, newLayer: boolean, customInnerView: any, routeParams: any, parentId: any, postSaveRedirectUrl: any, postSaveRedirectAction: () => {}, allowStayInModal: any}}} component
 */
export const openModal = (component) => {
    let self = component;
    let parentResource = null;
    if (self.props.parentInstanceId && (self.props.context === CONTEXT_ADD || self.props.context === CONTEXT_EDIT)){
        parentResource = new APIResource({
            instanceId: self.props.parentInstanceId
        });
    }
    let resource = new APIResource({
        instanceId: self.props.instanceId,
        context: self.props.context
    });

    let title = self.props.modalTitle
    if (self.props.customFirstRessourceName) {
        title = self.props.customFirstRessourceName + ': ' + title
    } else if(resource && resource.name){
        title = resource.name.charAt(0).toUpperCase() + resource.name.slice(1) + ': ' + title
    }

    let modalParams = {
        title,
        content: <ModalInnerView modal={self} resource={resource} parentResource={parentResource} objectId={self.props.id} parentResourceComponent={self.props.parentResourceComponent} />,
        contentStyle: { padding: 0 },
        closeOnRouteChange: true,
        allowFullScreen: true
    };

    if(self.props.newLayer){
        ModalComplementary.open(modalParams);
    }else{
        Modal.open(modalParams);
    }
}

export class OpenModal extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        //By default, all object should be preloaded ; If ResourceStore knows objet it will be return immediately, if don't, forceReload is used to get objet from API
        if(this.props.instanceId){
            if(this.props.preload){
                APIResourceStore.resources[this.props.instanceId].apiGetOne(this.props.id,true);
            }
        }else if(!this.props.customInnerView){
            console.warn("Missing instanceId or customInnerView while using the OpenModal component", this.props)
        }
    }

    openModal() {
        openModal(this);
    }

    render() {
        let self = this;

        //console.log('render openModal', self.props)

        let style = {};
        if(this.props.inline){
            style = {display: "inline-flex"};
        }
        
        let button = null;
        if(self.props.flat){
            button = this.props.modalTitle;
        }else if(self.props.button){
            button = self.props.button;
        }else if(self.props.context === CONTEXT_DETAIL){
            button = self.props.withIcon ?
                <Button variant="contained" className="primary ">
                    <i className="fa fa-th-large"></i>
                </Button>
                : <DetailButton />;
        }else if(
            self.props.context === CONTEXT_ADD
            || self.props.context === CONTEXT_EDIT
        ){
            button = self.props.withIcon ?
                <Button variant="contained" className="button-edit warning tooltip">
                    <i className="fa fa-edit"></i>
                    <span className="tooltiptext">Edit</span>
                </Button>
                : <EditButton />
            ;
        }
        return (
            <div className={`open-modal context-${self.props.context} ${self.props.containerClassName}`} style={style}>
                <span onClick={() => this.openModal()} >
                    {button}
                </span>
            </div>
        );
    }
}
