import React, { Component } from 'react';
import { DateRangePicker as DRPicker } from 'react-date-range';
import DateFormatter from "../../Services/DateFormatter";
import {defaultStaticRanges} from "../Forms/DateRangePicker/DefautStaticRanges";

export class DateRangePicker extends Component{
    constructor(props){
        super(props);

        this.state = {
            selection: {
                startDate: DateFormatter.addDaysDateObject(-30, new Date()),
                endDate: new Date(),
                key: 'selection',
            },
        }
    }

    handleRangeChange(payload) {
        this.setState({
            selection: {
                startDate: payload.selection.startDate,
                endDate: payload.selection.endDate,
                key: 'selection',
            },
        });
        if(payload.selection.startDate.getTime() !== payload.selection.endDate.getTime()){
            if(this.props.onChange){
                this.props.onChange({'name': this.props.reportingType, 'request': { 'start': payload.selection.startDate, 'end': payload.selection.endDate}});
                this.props.modal.close();
            }
        }
    }

    render(){
        return (
            <div style={styles.dateRangePicker}>
                <DRPicker
                    showSelectionPreview={true}
                    showMonthAndYearPickers={true}
                    moveRangeOnFirstSelection={false}
                    showMonthArrow={true}
                    maxDate={new Date()}
                    months={1}
                    ranges={[this.state.selection]}
                    inputRanges={[]}
                    onChange={this.handleRangeChange.bind(this)}
                    direction="horizontal"
                    staticRanges={defaultStaticRanges}
                />
            </div>
        )
    }
}


const styles = {
    dateRangePicker: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 0,
        marginBottom: 0,
        width: '80%',
    },
};