import RouteStore, {Route} from "../../Store/RouteStore";
import React from "react";
import ExportDownload from "./ExportDownload";
import ReviewExport from "./ReviewExport";

RouteStore.routes.exportReview = new Route({
    path: '/export/reviews',
    component: (props) => <ReviewExport {...props}/>,
    menuItem: {
        title: 'Export reviews'
    }
});

RouteStore.routes.exportDownload = new Route({
    path: '/export/download/:id/:documentName',
    component: (props) => <ExportDownload {...props}/>,
    menuItem: {
        title: 'Export download'
    }
});
