import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import Alert from "../../Services/Alert";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { APIResource } from "../../Services/APIResource/APIResource";
import Modal from "../../Services/Modal";
import Icon from "@material-ui/core/Icon";
import {CircularProgress} from "@material-ui/core";
import Http from "../../Services/Http";
import { consoleBuffer } from '../../Services/Debug';
import { getIdFromIri } from '../../Services/utils';
import User, { ROLE } from '../../Services/User/User';
import {DisplayTextField} from "../Display/DisplayTextField/DisplayTextField";
import ModalIssue from "../../Services/ModalIssue";

export class IssueButton extends Component {

    constructor(props) {
        super(props);

        this.modalTitle = 'New request';
        this.issueTooltip = 'Report a missing value';
        this.issueTitle = 'Missing value on field ' + this.props.field;
        this.issueDescription = 'Detail about the missing value: ';
        this.issueText = 'Send request';
        this.issueType = null;
    }

    addIssue() {

        if(typeof this.props.issueButton === "object"){
            if(this.props.issueButton.modalTitle){
                this.modalTitle = this.props.issueButton.modalTitle;
            }
            if(this.props.issueButton.title){
                this.issueTitle = this.props.issueButton.title;
            }
            if(this.props.issueButton.description){
                this.issueDescription = this.props.issueButton.description;
            }
            if(this.props.issueButton.button){
                this.issueText = this.props.issueButton.button;
            }
            if(this.props.issueButton.type){
                this.issueType = this.props.issueButton.type;
            }
        }

        const resource = this.props?.entity?.['@id'] ? this.props?.entity?.['@id'].split('/')?.[2] : null;

        const models =
            resource === 'models'
                ? [this.props.entity['@id']]
                : this.props.entity?.models ??
                  (this.props.entity?.model ? [this.props.entity?.model['@id'] || this.props.entity?.model] : []);

        ModalIssue.open({
            title: this.modalTitle,
            content: <IssueAddForm
                title={this.issueTitle}
                description={this.issueDescription}
                textButton={this.issueText}
                type={this.issueType || this.props.type}
                entity={this.props.entity}
                models={models}
            />,
            size: "medium",
        });
    }

    render() {
        if(typeof this.props.issueButton === "object"){
            if(this.props.issueButton.tooltip){
                this.issueTooltip = this.props.issueButton.tooltip;
            }
        }
        // On désactive tous les boutons pour MRM qui n'a aucune raison de s'envoyer des tickets.
        if (User.hasOneRole(ROLE.MRM)) {
            return <></>;
        } else {
            return (
                <Button variant="contained" color="primary" onClick={() => this.addIssue()} className="tooltip">
                    <Icon className={'fa fa-headset'}></Icon>
                    <span className="tooltiptext">{this.issueTooltip}</span>
                </Button>
            );
        }
    }
}
IssueButton.propTypes = {
    issueButton: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    field: PropTypes.string,
    type: PropTypes.any,
    entity: PropTypes.object,
}

export class IssueAddForm extends Component {
    constructor(props) {
        super(props);
        this.resource = new APIResource({ id: 'issues' });
        this.modelResource = new APIResource({ id: 'models', systemId: 'issueModels' })
        this.state = {
            title: props.title ?? 'New Trouble',
            description: props.description ?? '',
            modelCertificationCampaign: props.modelCertificationCampaign,
            type: props.type,
            models: props.models,
            modelsFull: [],
            textButton: props.textButton ?? 'Send Trouble',
            isSaving: false
        };
    }

    componentDidUpdate(prevProps) {
        let newState = {};
        const checkProps = (prop) => {
            if (this.props[prop] && this.props[prop] !== prevProps[prop]) {
                newState[prop] = this.props[prop];
            }
        };
        ['models', 'modelCertificationCampaign', 'type'].forEach((prop) => checkProps(prop));

        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    /**
     * pour debug :
     */
    componentDidMount() {
        this.getModelsFull();
    }

    getModelsFull(){
        this.state.models.map((iri) => {
            let id = getIdFromIri(iri);
            this.modelResource
                .apiGetOne(id, true, ['functionalID', 'initialID'])
                .then((model) => this.setState((prev) => ({ modelsFull: [...prev.modelsFull, model] })));
        });
    }

    printModelsFull() {
        if (this.state.modelsFull.length){
            return this.state.modelsFull.map(m => {
                let name = [];
                name.push(m.functionalID);
                if(m.initialID){
                    name.push( '(' + m.initialID + ')');
                }
                return name.join(' ');
            }).join(',');
        }
        return "aucun";
    }

    printFrom(){
        let from = '';

        if(!this.props.entity){
            return '';
        }

        if(
            !this.props.entity.id
            && this.props.entity['@type']
        ){
            return 'From: New ' + this.props.entity['@type'];
        }else if(
            this.props.entity.id
            && this.props.entity['@id']
        ){
            let [before, api, resource, id] = this.props.entity['@id'].split('/');

            // Si l'entité source correspond au model donné, on ne le trace pas dans la description.
            if (this.state.models.every(m => m === this.props?.entity?.['@id'])) return '';

            switch (resource){
                case 'models':
                    from = this.props.entity.toString + ' (' + id + ')';
                    break;
                case 'reviews':
                    from = this.props.entity.toString + ' (' + id + ')';
                    break;
                case 'model_uses':
                    from = this.props.entity.toString + ' (' + id + ')';
                    break;
                case 'mitigation_actions':
                    from = this.props.entity.toString + ' (' + id + ')';
                    break;
                case 'notices':
                    from = this.props.entity.toString + ' (' + id + ')';
                    break;
                case 'tierings':
                    from = this.props.entity.toString + ' (' + id + ')';
                    break;
                case 'implementations':
                    from = this.props.entity.toString + ' (' + id + ')';
                    break;
            }
            return 'From ' + (this.props.entity['@type'] ?? '') + ': ' + (from ?? 'introuvable');
        }

        return 'From: -- missing entity @id --';
    }
    /**
     * Fin debug
     */

    save() {
        if (!this.state.title || !this.state.description) {
            Alert.show({ message: 'Please fill title and description', type: 'error' });
            return;
        }
        this.setState({isSaving: true});
        const description = this.state.description
            + "\n" + this.printFrom()
            // on n'affiche pas les models si il n'y en a qu'un et que c'est l'entity
            + (this.state.models.some(m => m !== this.props?.entity?.['@id']) ?
                "\nModels :"
                + "\n - FunctionalIds : " + this.printModelsFull()
                // + "\n - Iris : " + (this.state.models ? this.state.models.join(','): "aucun") /** redondant {@see https://app.asana.com/0/1173729351441775/1203197767995509} */
                : '')
        ;
        this.resource
            .apiPost({
                title: this.state.title,
                type: this.state.type,
                description: description,
                modelCertificationCampaign: this.state
                    .modelCertificationCampaign,
                models: this.state.models,
                debugUrl: window.location.href,
                debugConsole: consoleBuffer.join('\n'),
            })
            .then((response) => {
                Alert.show({
                    message: 'Your request has been sent successfully',
                    type: 'success',
                });
                ModalIssue.close();
                if (this.props.onClose) {
                    this.props.onClose(response);
                }
                this.setState({isSaving: false});
            }).catch((error) => {
                this.setState({ isSaving: false });
                Alert.show({
                    message: `Your trouble cannot be saved.\n${Http.getErrorMessage(
                        error
                    )}`,
                    type: "error",
                });
            });
    }

    render() {
        return (
            <div className="issue-modal-add">
                <div className="fields">
                    <FormControl>
                        {
                            this.state.title && <DisplayTextField
                                fieldName={'Title'}
                                value={this.state.title ? this.state.title : ''}
                            />
                        }
                        {
                            !this.state.title && <TextField
                                label="Title"
                                onChange={(event) => this.setState({ title: event.target.value })}
                                value={this.state.title ? this.state.title : ''}
                            />
                        }
                    </FormControl>
                    <FormControl className="container_textarea">
                        <label>Description</label><TextareaAutosize value={this.state.description ? this.state.description : ''} onChange={(event) => this.setState({ description: event.target.value })} rows={5} rowsMax={10} />
                    </FormControl>
                </div>
                {this.state.isSaving === true ? (
                    <Button
                        style={{ marginTop: 10 }}
                        variant="contained"
                        color="secondary"
                        className="save button-general"
                    >
                        <CircularProgress size={20} />
                    </Button>
                ) : (
                <Button variant="contained" color="secondary" className="save button-general" onClick={this.save.bind(this)}>
                    {this.state.textButton}
                </Button>
                )}
            </div>
        );
    }
}

IssueAddForm.propTypes = {
    /** Models en relation avec le Trouble */
    models: PropTypes.array,
    title: PropTypes.string,
    description:PropTypes.string,
    modelCertificationCampaign: PropTypes.string,
    /** Entité source du Trouble */
    entity: PropTypes.shape({
        '@id': PropTypes.string,
        '@type': PropTypes.string,
        '@context': PropTypes.string,
        id: PropTypes.any,
        toString: PropTypes.string,
    }),
    type: PropTypes.string,
    textButton: PropTypes.string,
    onClose: PropTypes.func,
}
