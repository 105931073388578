import RouteStore, {Route} from "../../Store/RouteStore";
import React from "react";
import Detail from "./Notice/Notice";
import ModelList from "./ModelList/ModelList";

RouteStore.routes.modelCertificationCampaignNotice = new Route({
    path: '/resource/my_model_certification_campaigns/:id/notice',
    component: (props) => <Detail {...props} />,
});

RouteStore.routes.myCertificationModels = new Route({
    path: '/resource/my_model_certification_campaigns/:id/detail',
    component: (props) => <ModelList {...props} />,
});
