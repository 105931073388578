import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { Typography } from '@material-ui/core';

const options = ['Refresh', 'Delete'];

const ITEM_HEIGHT = 48;

export class DashboardMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: React.createRef(),
        };
    }

    handleClick = (event) => {
        this.setState({ open: true, anchorEl: event.currentTarget });
    };

    handleRefresh = (event) => {
        if (this.props.onRefresh) {
            this.props.onRefresh(event);
        }
        this.setState({ open: false, anchorEl: null });
    };

    handleClose = (event) => {
        if (this.props.onClose) {
            this.props.onClose(event);
        }
        this.setState({ open: false, anchorEl: null });
    };

    handleDelete = (event) => {
        if (this.props.onDelete) {
            this.props.onDelete(event);
        }
        this.setState({ open: false, anchorEl: null });
    };

    handleEdit = (event) => {
        if (this.props.onEdit) {
            this.props.onEdit(event);
        }
        this.setState({ open: false, anchorEl: null });
    };

    handleCreate = (event) => {
        if (this.props.onCreate) {
            this.props.onCreate(event);
        }
        this.setState({ open: false, anchorEl: null });
    };

    render() {
        const { className, tabName, currentTab } = this.props;
        const { anchorEl, open } = this.state;

        return (
            <div className={className}>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    style={{ padding: 5, color: 'white' }}
                    onClick={this.handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={this.onClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 200,
                        },
                    }}
                >
                    <MenuItem onClick={this.handleCreate}>
                        <AddIcon classes={{ root: 'text-success' }} />{' '}
                        <Typography className="">Add dashboard</Typography>
                    </MenuItem>
                    {(!currentTab || !currentTab.isRef) && (
                        <>
                            <MenuItem onClick={this.handleEdit}>
                                <EditIcon /> <Typography className="">Edit this board</Typography>
                            </MenuItem>
                            <MenuItem onClick={this.handleDelete}>
                                <DeleteIcon classes={{ root: 'text-danger' }} />{' '}
                                <Typography className="">Delete this board</Typography>
                            </MenuItem>
                        </>
                    )}
                    <MenuItem onClick={this.handleClose}>
                        <CloseIcon /> <Typography>Close menu</Typography>
                    </MenuItem>
                </Menu>
            </div>
        );
    }
}
