import React, { Component } from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import FieldProviderStore from "../../../Services/APIResource/FieldProviders/__FieldProviderStore";
import _ from "lodash";

export class TableWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: null
        };
    }

    componentDidMount() {
        if (this.props.rows != undefined) {
            this.setState({ rows: this.props.rows });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.rows && this.props.rows != prevProps.rows) {
            this.setState({ rows: this.props.rows });
        }
    }

    tHeader() {
        let ths = [];
        for (let i in this.props.cols) {
            ths.push(
                <TableCell key={"th_" + i} style={styles.tableTh} className="condensed-header">
                    {_.startCase(this.props.cols[i].label)}
                </TableCell>
            );
        }
        if (this.props.actions) {
            ths.push(
                <TableCell key={"th_" + this.props.cols.length} style={styles.tableTh}>
                    {this.props.actionLabel ? this.props.actionLabel : "Actions"}
                </TableCell>
            );
        }

        return (
            <TableHead>
                <TableRow>{ths}</TableRow>
            </TableHead>
        );
    }

    tBody(rows) {
        let trs = [];
        for (let i in rows) {
            let tds = [];
            for (let j in this.props.cols) {
                if (!this.props.cols[j].styles) {
                    this.props.cols[j].styles = {};
                }
                let displayComponent = null;
                let fieldDefinition = this.props.cols[j];
                let field = fieldDefinition.field;
                let entity = rows[i];
                if (fieldDefinition.display) {
                    displayComponent = fieldDefinition.display(
                        field,
                        entity[this.props.cols[j].field],
                        entity,
                        {}
                    );
                } else if (
                    fieldDefinition.type &&
                    FieldProviderStore[fieldDefinition.type]
                ) {
                    displayComponent = FieldProviderStore[
                        fieldDefinition.type
                    ].getDisplayList(
                        fieldDefinition,
                        entity[fieldDefinition.field],
                        entity,
                        { flat: true, multi: true }
                    );
                }
                else {
                    displayComponent = (
                        <div>
                            {_.get(entity, fieldDefinition.field)}

                        </div>
                    );
                }
                tds.push(
                    <TableCell key={i + "_" + j} style={this.props.cols[j].styles} className="widget-table-field">
                        {displayComponent}
                    </TableCell>
                );
            }
            if (this.props.actions) {
                tds.push(
                    <TableCell
                        key={i + "_" + this.props.cols.length}
                        style={{ width: "25%" }}
                        className="td_actions"
                    >
                        {this.props.actions(rows[i])}
                    </TableCell>
                );
            }
            trs.push(
                <TableRow
                    key={i}
                    onClick={() => this.props.onRowClick && this.props.onRowClick(rows[i], i)}
                    style={this.props.onRowClick ? { cursor: 'pointer' } : { cursor: 'auto' }}
                >
                    {tds}
                </TableRow>
            );
        }

        return <TableBody>{trs}</TableBody>;
    }

    render() {
        return (
            <div>
                {this.props.label ? <label>{this.props.label} {this.state.rows.length}</label> : null}
                <Grid item xs={12}>
                    <Table size="small" className="widget-table" style={{ borderRadius: 0 }}>
                        {this.tHeader()}
                        {this.tBody(this.state.rows)}
                    </Table>
                </Grid>
            </div>
        );
    }
}

const styles = {
    actionLink: {
        marginLeft: 5
    },
    field: {

    }
};
