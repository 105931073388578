import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import {
    Button,
    Paper,
    FormControlLabel,
    FormGroup,
    FormControl,
    FormLabel,
    Input,
    AppBar,
    Box,
    Card,
    CardContent,
    Fade,
    Grid,
    LinearProgress,
    Switch,
    Typography,
    Tabs,
    Tab,

} from '@material-ui/core';

import Http from '../../../Services/Http';
import User from '../../../Services/User/User';
import Navigation from '../../../Services/Navigation';
import { APIResource } from '../../../Services/APIResource/APIResource';
import AppBarStore from '../../../Store/AppBarStore';

class UserSettingPage extends Component {


    constructor(props) {
        super(props);
        AppBarStore.title = 'User settings';
        this.state = {
            id: null,
            init: true,
            isLoading: false,
            currentTabIndex: 0,
            tabs: [{
                name: 'Settings'
            }],
        };
    }

    componentDidMount() {
        this.getSettings();
    }

    getSettings = () => {
        this.setState({ isLoading: true });
        return User.refresh().then(() => {
            const { inAppNotifications, emailNotifications } = User.profile;
            this.setState({
                inAppNotifications,
                emailNotifications,
                isLoading: false,
            })
        })

    }
    /**
 * When the user click on the tabs
 *
 * @memberof Dashboard
 */
    handleTabChange = (event, val) => {
        this.setState({
            currentTabIndex: val,
            currentTabCode: Object.keys(this.state.tabs)[
                val
            ]
        });
    }


    renderTabs = () => {
        let tabs = [];
        for (let tabId in this.state.tabs) {
            tabs.push(<Tab label={this.state.tabs[tabId].name} key={tabId} />);
        }
        return tabs;
    }

    handNotificationStateChange = (type, state) => {

        const newState = { [type]: state };
        this.setState({ isLoading: true });
        if (type === 'inAppNotifications' && !state) {
            newState.emailNotifications = false;
        }
        this.setState(newState);
        User.updateUser(newState)
            .then(this.getSettings)
            .finally(() => this.setState({ isLoading: false }));

    }

    renderTabHeader() {
        const { tabs, currentTabCode } = this.state;
        return (
            <AppBar position="relative" color="default">
                <Tabs
                    value={this.state.currentTabIndex}
                    onChange={this.handleTabChange}
                    className="tabs-menu"
                    indicatorColor="primary"
                    textColor="primary"
                    variant="standard"
                    scrollButtons="auto"
                    key={'resourceEditTab'}
                >
                    {this.renderTabs()}
                </Tabs>
            </AppBar>);
    }

    render() {
        const { isLoading, inAppNotifications, emailNotifications } = this.state;
        return (
            <div>{this.renderTabHeader()}
                {isLoading && (<LinearProgress></LinearProgress>)}
                <div className="container">
                    <Paper
                        className="resource-detail ressource-api-box"
                        style={styles.headerBlock}
                    >
                        <h2
                            className="background-linear-gradient"
                            style={{ color: 'white', paddingLeft: 10 }}
                        >
                            &nbsp;{' '}
                            Notifications
                        </h2>
                        <div className="ressource-api-container">
                            <div className="ressource-api-box">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={!!inAppNotifications}
                                                onChange={(event, state) => this.handNotificationStateChange('inAppNotifications', state)}
                                                name="inAppNotifications"
                                                color="primary"
                                            />
                                        }
                                        label="In app notifications"
                                    />

                                </FormGroup>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={!!emailNotifications}
                                                onChange={(event, state) => this.handNotificationStateChange('emailNotifications', state)}
                                                disabled={!inAppNotifications}
                                                name="emailNotifications"
                                                color="primary"
                                            />
                                        }
                                        label="E-mail notifications"
                                    />
                                    {emailNotifications}
                                </FormGroup>
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
        );
    }
}

const styles = {
    blockHeightStyle: {
        paddingBottom: 5,
        marginBottom: 35,
    },
    headerBlock: {
        margin: 15,
    },
    title: {
        color: 'white',
        padding: 10,
    },
    gridPaper: {
        marginTop: 15,
        marginBottom: 15,
        height: '100%',
    },
    gridBody: {
        padding: 15,
        paddingTop: 0,
        color: 'black',
        fontSize: 18,
        textAlign: 'center',
    },
    mrDetailBtn: {
        margin: 1,
    },
    footerActions: {
        margin: 15,
    },
    checkboxes: {
        justifyContent: 'center',
    },
    checkboxHeader: {
        color: 'white',
        fontWeight: 'bold'
    }
};

export default observer(UserSettingPage);
