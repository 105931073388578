import React from "react";
import { EntityDisplay } from "../../../Components/Display/EntityDisplay/EntityDisplay";
import { EntitySelect } from "../../../Components/Forms/EntitySelect/EntitySelect";
import {CONTEXT_LIST} from "../APIResource";
import APIResourceStore from "../../../Store/APIResourceStore";

class EntityProvider {
  /**
   * 
   * @param {import('../APIResource').APIResourceField} field 
   * @param {*} value 
   * @param {*} entity 
   * @param {{plainText: boolean, flat: boolean, showLoading: boolean, modalLinks: boolean}} props
   * @param {*} _resource 
   * @returns 
   */
  getDisplay(field, value, entity, props, _resource, context) {
    return (
        <EntityDisplay
            {...props}
            resourceId={field.params.resource}
            instanceId={field.params.instanceId}
            resourceLabel={field.params.displayField}
            label={field.title}
            value={value}
            links={field.params.links}
            linkPath={field.params.linkPath}
            linkAction={field.params.linkAction}
            modalLinks={field.params.modalLinks || props?.modalLinks}
            parentEntity={entity}
            issueButton={'issueButton' in field ? field.issueButton : false}
            tooltip={field.params.tooltip}
            context={context}
        />
    );
  }

  getDisplayList(field, value, entity, props, resource) {
    return this.getDisplay(field, value, entity, props, resource, CONTEXT_LIST);
  }

  getEdit(field, value, onChange, entity, _routeParams, loading = false) {
    // On fait cela pour passer onChange au composant, plus bas
    const IssueButtonComponent = field.issueButtonComponent;

    /** @param {string} inputValue */
    const onInputChange = (inputValue) => {
      let input;

      const inputValues = inputValue.split(/[\s,]+/);
      if (inputValues.length <= 1) {
          input = inputValue;
      } else {
          // On récupères les IRI des entités associées
          let found = [];
          input = APIResourceStore.resources[field.params.resource]
              .filterItems((entity) => (field.params?.searchedFields || ['id', '@id']).some(entityField => inputValues.includes(entity[entityField])))
              .map((entity) => {
                  found = [...found, ...(field.params?.searchedFields || ['id', '@id']).map(entityField => entity[entityField])];
                  return entity['@id'] 
              });
          if (input.length) {
              onChange([...(value || []), ...input]);
              // on réaffiche les inputs non trouvés :
              input = inputValues.filter(i => !found.includes(i)).join(',');
          } else {
              input = inputValue;
          }
      }
      return input;
    }

    const inputChangeProp = field.params?.allowPaste !== false ? {onInputChange: onInputChange} : {};


    return (
      <EntitySelect
        label={field.title}
        //disabled={loading}
        resourceId={field.params.resource}
        instanceId={field.params.instanceId}
        resourceLabel={field.params.editDisplayField !== undefined ? field.params.editDisplayField : field.params.displayField}
        value={value}
        onChange={onChange}
        clearable={!("required" in field && field.required === true)}
        multi={field.params.multi}
        issueButton={"issueButton" in field ? field.issueButton : true}
        issueButtonComponent={field.issueButtonComponent && <IssueButtonComponent onChange={onChange} />}
        entity={entity}
        filters={field.params.filters}
        required={"required" in field && field.requiredComputed === true}
        error={!!field.errorHelperText}
        className={field.errorHelperText ? "field-error-control" : ""}
        helperText={field.errorHelperText}
        endpoints={field.params.endpoints}
        neededFields={field.params.neededFields}
        {...inputChangeProp}
      />
    );
  }

  getFilter(options = {}) {
    const { field, value, onChange } = options;
    return (
      <EntitySelect
        resourceId={field.params.resource}
        instanceId={field.params.instanceId}
        resourceLabel={field.params.displayField}
        onChange={onChange}
        clearable={true}
        multi={true}
        filters={field.params.filters}
        value={value ? value : null}
        endpoints={field.params.endpoints}
        context='filter'
        orExistsMultiFilterNotAllowed={field.params.orExistsMultiFilterNotAllowed || false}
        neededFields={field.params.neededFields}
      />
    );
  }
}

export default new EntityProvider();
