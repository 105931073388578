import React from 'react';
import { APIResource } from "../Services/APIResource/APIResource";
import { EntitySelect } from "../Components/Forms/EntitySelect/EntitySelect";
import { PARAMETER_TYPE_WORKFLOW_ACTION_TYPE } from '../Admin/ParameterAdmin';
import { ActionParameters } from "../Components/Workflow/ActionParameters";

export const ApiResourceDefaultParams = {
    id: 'actions',
    name: 'Actions',
    canonicalName: 'action',
    icon: 'brain',
    fieldForTitle: 'id'
};

export default class ActionAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        // Models entities
        let actions = new APIResource(ApiResourceDefaultParams);
        actions
            .setFields({
                title: { title: 'Title', maxLength:255, required: true },
                type: { title: 'Type', type: 'parameter', params: { type: PARAMETER_TYPE_WORKFLOW_ACTION_TYPE, multi: false }, required: true, issueButton: false },
                //phase: {title: 'Phase', type: 'parameter', params: {type: PARAMETER_TYPE_ACTION_PHASE, multi: false}, required: true},
                priority: { title: 'Priority', type: 'int', required: true },
                parameters: {
                    title: 'Parameters',
                    edit: (field, value, onChange, entity, routeParams) => {
                        return <ActionParameters field={field} value={value} onChange={onChange} entity={entity} {...routeParams} />;
                    },
                    display: (field, value, entity, props) => {
                        return <ActionParameters field={field} value={value} onChange={() => null} entity={entity} readOnly={true} {...props} />;
                    },
                    displayList: () => null,
                    filter: () => null
                },

                workflow: {
                    title: 'Workflow',
                    type: 'entity',
                    params: { resource: 'workflows', displayField: 'title', links: true },
                    edit: (field, value, onChange, entity, routeParams) => {
                        entity.workflow = (routeParams.workflowId && !entity.workflow) ? '/api/workflows/' + routeParams.workflowId : value;
                        if (routeParams.workflowId) {
                            return null;
                        }
                        return <EntitySelect label="Workflow" resourceId="workflows" resourceLabel="title" value={entity.workflow} onChange={onChange} clearable={false} multi={false} issueButton={true} />
                    },
                    required: true
                },
            })
            .setValidation((entity) => { return true; })
            .setLayout({
                tabs: {
                    'Properties': {
                        rows: [
                            {
                                panels: {
                                    'Identity': {
                                        cols: 6,
                                        fields: ['title', 'workflow', 'type', 'priority', 'parameters']
                                    },
                                },
                            },
                        ]
                    },
                },
            })
            .genListView({
            })
            .genInsertView({
                menuItem: { title: 'Add' },
                additionalRoutes: ['/resource/actions/add/:workflowId'],
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'Action';
                },
            })
            .genEditView({
            })
            .genDetailView({
            })
            .allowDelete()
            ;
    }
}
