/**
 * @module admin/common Fonctions communes aux pages Admin.
 */

import Http from "../Services/Http";

/**
 * PERMISSIONS :
 *
 * Constantes pour la gestion des droits. (puissances de 2)
 * @see isDisplayed
 * @see isEditable
 * @see isRequired
 */
export const HIDDEN = 0;
export const REQUIRED = 1;
export const DISPLAY_DETAIL = 2;
export const DISPLAY_EDIT = 4;
export const DISPLAY_LIST = 8;
export const DISPLAY_ADD = 16;
export const EDITABLE = 32;

export const DISPLAY = DISPLAY_DETAIL + DISPLAY_EDIT + DISPLAY_LIST + DISPLAY_ADD;
export const EDIT = EDITABLE + DISPLAY;

export const HIGHLIGHT_SEPARATOR = '-';

/**
 * Common function to extract highlighted fields from queryParams.
 *
 * @param {Object} entity
 * @param {string} propertyName - aka fieldId
 * @param {Object} queryParams
 * @param {string} queryParamAttribute - Attribut des queryParams qui contient les champs à surligner, default 'highlight'
 * @returns {boolean}
 */
export const needHighlightField = (
    entity,
    propertyName,
    queryParams = new URLSearchParams(window.location.search),
    queryParamAttribute = 'highlight'
) => {
    if (!queryHasAttribute(queryParams, queryParamAttribute)) {
        return false;
    }
    let fields = queryParams.get(queryParamAttribute).split(HIGHLIGHT_SEPARATOR);
    if (fields.includes(propertyName)) {
        return true;
    }
    return false;
};

/**
 * Renvoie vrai si l'attribut est présent dans la requête.
 *
 * @returns {boolean}
 */
export const queryHasAttribute = (queryParams, queryParamAttribute) => {
    if (
        typeof queryParams !== 'object'
        || queryParams.get(queryParamAttribute) === undefined
        || queryParams.get(queryParamAttribute) === ''
        || queryParams.get(queryParamAttribute) === null
    ) {
        return false;
    }
    return true;
};

/**
 * Parametre des field {@see module:APIResource.APIResourceField} pour gérer la propriété "highlighted" via la fonction needHighlightField.
 *
 * Utilisation : à destructurer pour l'intégrer dans les champs existants.
 *
 * @returns {import("../Services/APIResource/APIResource").APIResourceField}
 */
export const fieldHighlightedParam = {
    highlighted: (entity, propertyName, queryParams) => needHighlightField(entity, propertyName, queryParams),
};

/**
 * Teste les droits vs les permissions.
 *
 * Les permissions sont les constantes "EDIT", "DISPLAY_DETAIL" etc.
 * Les droits également, mais à la charge de la page Admin, c'est une combinaison des permissions.
 *
 * @param {number} rights
 * @param {number} permission
 * @returns
 */
export const hasPermission = (rights, permission) => {
    // Ici c'est un peu touchy, c'est une opération sur la valeur binaire des droits
    // en gros : 2 (EDIT) => 0010 en binaire, donc si j'ai les droits 6 (= 0110 en binaire) alors 0110 & 0010 => 0010
    // mais si je ne les ai pas (genre 4, qui vaut 0100 en binaire) alors 0100 & 0010 => 0000
    // Le "&" est un opérateur binaire.
    return !!(rights & permission);
};

/**
 * Renvoie true si les droits correspondent au contexte.
 * 
 * @todo displayConditions n'est pas appelé pour le DISPLAY_LIST, donc on ne l'utilise pas.
 *
 * @param {number} rights
 * @param {string} context
 * @returns {boolean} Vrai si rights possède DISPLAY_EDIT en mode "edit", DISPLAY_ADD en mode "add", ou "DISPLAY_DETAIL" en mode "detail"
 */
export const isDisplayed = (rights, context) => {
    const permission = ['edit', 'add'].includes(context)
        ? context === 'add'
            ? DISPLAY_ADD
            : DISPLAY_EDIT
        : DISPLAY_DETAIL;
    return hasPermission(rights, permission);
};

/**
 * Renvoie true si les droits autorisent l'édition.
 *
 * @param {number} rights
 * @returns {boolean} Vrai si rights possède EDITABLE.
 */
export const isEditable = (rights) => {
    const permission = EDITABLE;
    return hasPermission(rights, permission);
};

/**
 * Renvoie true si les droits indiquent que le champ est requis.
 *
 * @param {number} rights
 * @returns {boolean} Vrai si rights possède EDITABLE.
 */
export const isRequired = (rights) => {
    const permission = REQUIRED;
    return hasPermission(rights, permission);
};

/**
 * Fonction **asynchrone** pour déterminer si l'utilisateur est autorisé pour le type de ressource donné.
 * Version par défaut de l'appel, à ne pas utiliser si besoin spécifique.
 * @see backend\src\Controller\IsGrantedController en back : déclenche les Voters associés.
 * 
 * @param {Object} entity
 * @param {string} resourceId
 * @param {string} voterRight - default "EDIT", voir dans les Voters les "droits" demandés
 * @returns {boolean}
 */
export const isGranted = async (entity, resourceId, voterRight = 'EDIT') => {
    let editGranted = await Http.get(`${resourceId}/${entity.id}/is_granted/${voterRight}`, { cache: true });
    return editGranted['hydra:member']['is_granted'];
};
