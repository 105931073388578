import React from 'react';
import { APIResource } from '../Services/APIResource/APIResource';
import {EntitySelect} from "../Components/Forms/EntitySelect/EntitySelect";

export const ApiResourceDefaultParams = {
    id: 'system_versions',
    instanceId: 'system_versions',
    name: 'System Versions',
    icon: 'fa-code-branch',
    fieldForTitle: 'versionNumber',
};

export default class SystemVersionAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        let systemVersions = new APIResource(ApiResourceDefaultParams);
        systemVersions
            .setFields({
                id: { title: 'ID' },
                versionNumber: {
                    title: 'Version number',
                    helperTextEdit: 'Number of the current version of the platform',
                },
                productionLaunchDate: {
                    title: 'Production launch date',
                    type: 'date',
                    helperTextEdit: 'Date of production launch of the current IT system version',
                },
                description: {
                    title: 'Description',
                    type: 'textarea',
                    helperTextEdit: 'General description of the new version and its evolutions',
                },
                ITSystem: {
                    title: 'IT System',
                    type: 'entity',
                    helperTextEdit: 'IT System name',
                    params: {
                        resource: 'i_t_systems',
                        displayField: 'name',
                        links: true,
                        linkPath: (entity) => '/resource/i_t_systems/' + entity.id + '/detail',
                    },
                    edit: (field, value, onChange, entity, routeParams) => {
                        if ((routeParams.ITSystemId || value) && !entity.ITSystem) {
                            entity.ITSystem = routeParams.ITSystemId 
                                    ? `/api/i_t_systems/${routeParams.ITSystemId}`
                                    : value;
                        }
                        return (
                            <EntitySelect
                                label="IT System"
                                resourceId="i_t_systems"
                                resourceLabel="name"
                                value={entity.ITSystem}
                                onChange={onChange}
                                clearable={false}
                                multi={false}
                                issueButton={true}
                            />
                        );
                    },
                },
                models: {
                    title: 'Models',
                    type: 'model',
                    helperTextEdit: 'List of the models running under the current IT system version',
                    params: {
                        resource: 'models',
                        instanceId: 'allModels',
                        displayField: 'functionalID',
                        multi: true,
                        links: true,
                        linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                        endpoints: {
                            getAll: 'models/all-models',
                        },
                    },
                },
            })
            .setLayout({
                tabs: {
                    Properties: {
                        rows: [
                            {
                                panels: {
                                    Identification: {
                                        cols: 6,
                                        fields: ['versionNumber', 'productionLaunchDate', 'description'],
                                    },
                                    Relation: {
                                        cols: 6,
                                        fields: ['ITSystem', 'models'],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genInsertView({
                menuItem: { title: 'Add' },
                fields: ['versionNumber', 'productionLaunchDate', 'description', 'ITSystem', 'models'],
                additionalRoutes: ['/resource/system_versions/add/:ITSystemId'],
                postSaveRedirect: 'detail',
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'SystemVersion';
                },
            })
            .genEditView({
                fields: ['versionNumber', 'productionLaunchDate', 'description', 'ITSystem', 'models'],
            })
            .genDetailView()
            .allowDelete();
    }
}
