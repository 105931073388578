import React from 'react';
import { ConfirmModal } from '../Modal/ConfirmModal';
import Http from '../../Services/Http';
import Alert from '../../Services/Alert';
import Modal from '../../Services/Modal';

// Reset the initial validation plan fields
const ModelFreezeInitialValidationPlanFields = () => {
    const freezeFields = async () => {
        const res = await Http.post('models/freeze-validation-plan-values', {});
        Alert.show({
            message: res.message,
            type: 'success',
        });
        console.log(res.data);
        Modal.close();
    };

    return (
        <ConfirmModal
            message={
                'All fields related to the intial validation plan of the year are going to be reset, do you confirm ?'
            }
            callback={freezeFields}
            autoCloseModal={false}
        />
    );
};

/**
 * @type {import('../../Services/APIResource/APIResource').AdditionalActionButton}
 */
export const freezeInitialValidationPlanFieldsButton = {
    tooltip: 'Reset the initial validation plan fields',
    icon: 'calendar',
    className: 'button-meeting',
    onClick: () => Modal.open({
        title: "Reset the initial validation plan fields",
        content: <ModelFreezeInitialValidationPlanFields />,
        size: "medium",
    }),
};