import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DuplicateIcon from '@material-ui/icons/AddToPhotos';
import SaveIcon from '@material-ui/icons/SaveAlt';
// import PrintIcon from '@material-ui/icons/Print';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Typography } from '@material-ui/core';

const ITEM_HEIGHT = 48;

export class WidgetMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorEl: React.createRef(),
        };
    }

    handleClick = (event) => {
        this.setState({ open: true, anchorEl: event.currentTarget });
    };

    handleRefresh = (event) => {
        if (this.props.onRefresh) {
            this.props.onRefresh(event);
        }
        this.setState({ open: false, anchorEl: null });
    };

    handleClose = (event) => {
        if (this.props.onClose) {
            this.props.onClose(event);
        }
        this.setState({ open: false, anchorEl: null });
    };

    handleDelete = (event) => {
        if (this.props.onDelete) {
            this.props.onDelete(event);
        }
        this.setState({ open: false, anchorEl: null });
    };
    handleEdit = (event) => {
        if (this.props.onEdit) {
            this.props.onEdit(event);
        }
        this.setState({ open: false, anchorEl: null });
    };
    handleDuplicate = (event) => {
        if (this.props.onDuplicate) {
            this.props.onDuplicate(event);
        }
        this.setState({ open: false, anchorEl: null });
    };
    handleSave = (event) => {
        if (this.props.onSave) {
            this.props.onSave(event);
        }
        this.setState({ open: false, anchorEl: null });
    };

    render() {
        const { className } = this.props;
        const { anchorEl, open } = this.state;
        return (
            <div className={`${className} widget-hover`}>
                <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={this.handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={this.onClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 200,
                        },
                    }}
                >
                    <MenuItem onClick={this.handleRefresh}>
                        <RefreshIcon className="text-primary" />
                        <Typography className="text-primary">Refresh</Typography>
                    </MenuItem>
                    {!this.props?.widget?.value?.isRef && (
                        <>
                            {['user-defined-widget', 'changelog-count-widget'].includes(
                                this.props?.widget?.value?.widgetSystemId
                            ) && (
                                <>
                                    <MenuItem onClick={this.handleEdit}>
                                        <EditIcon classes={{ root: 'text-primary' }} />{' '}
                                        <Typography className="text-primary">Edit</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={this.handleDuplicate}>
                                        <DuplicateIcon classes={{ root: 'text-primary' }} />{' '}
                                        <Typography className="text-primary">Duplicate</Typography>
                                    </MenuItem>
                                </>
                            )}
                        </>
                    )}
                    <MenuItem onClick={this.handleSave}>
                        <SaveIcon classes={{ root: 'text-primary' }} />{' '}
                        <Typography className="text-primary">Save</Typography>
                    </MenuItem>
                    {!this.props?.widget?.value?.isRef && (
                        <MenuItem onClick={this.handleDelete}>
                            <DeleteIcon classes={{ root: 'text-danger' }} />{' '}
                            <Typography className="text-danger">Delete</Typography>
                        </MenuItem>
                    )}
                    <MenuItem onClick={this.handleClose}>
                        <CloseIcon /> <Typography>Close menu</Typography>
                    </MenuItem>
                </Menu>
            </div>
        );
    }
}
