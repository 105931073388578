import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { APIResource } from "../../Services/APIResource/APIResource";

import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import EntitiesRecursiveTreeView from "./TreeView";
import { ModalTreeProvider } from "./ModalTree";
import Http from "../../Services/Http";
import { getIdFromIri } from "../../Services/utils";
import {IssueButton} from "../Issue/IssueButton";

/**
 * Widget générique pour afficher/sélectionner des infos dans un arbre de ressources API.
 */
const EntitiesTreeWidget = (props) => {
    const {
        values,
        multi,
        onChange,
        resourceId,
        resourceLabel = "title",
        resourceSearchLabel = "hierarchyDisplayFlat",
        resourceFilters,
        selectable,
        childrenPropertyName = "children",
        label = "Tree",
        required = false,
        errorHelperText = null,
        asyncLoading = true,
        buttonText = "edit",
        itemAdditionalComponent,
        parentEntity = null,
        issueButton = true
    } = props;

    const [selectedEntities, setSelectedEntities] = useState([]);
    const [asyncMissingSelected, setAsyncMissingSelected] = useState([]);
    const [entities, setEntities] = useState([]);
    const [loading, setLoading] = useState(true);

    // Mise en "cache" des props API
    const apiProps = useRef({
        filters: asyncLoading
            ? { ...resourceFilters, first_level: 1 }
            : resourceFilters,
        entitiesResource: new APIResource({
            id: resourceId,
            instanceId: "entity_tree_" + resourceId,
            endpoints: {
                //getAll: 'scopes/all-scopes/all'
            },
        }),
    });

    let modal;

    /**
     * Détermine les entités sélectionnées à partir des IRI données en entrée du composant.
     *
     * Si elles ne sont pas dans les entités racines, il faut télécharger leurs arborescences respectives.
     *
     * @param {*} entities
     * @param {*} values
     */
    const loadSelectedEntities = (entities, values) => {
        const updateSelectedEntities = (entities, values) => {
            selectedEntities = entities.filter((obj) =>
                values.includes(obj["@id"])
            );

            if (
                selectedEntities.length !== values.length &&
                !asyncMissingSelected.length
            ) {
                let missing = values.filter(
                    (v) => !selectedEntities.map((e) => e["@id"]).includes(v)
                );
                setAsyncMissingSelected((prev) => [
                    ...new Set([...prev, ...missing]),
                ]);
            } else if (selectedEntities.length === values.length) {
                setSelectedEntities(selectedEntities);
                setAsyncMissingSelected([]);
                setLoading(false);
            }
        };

        let selectedEntities = [];
        if (values) {
            updateSelectedEntities(entities, values);
        }
    };

    useEffect(() => {
        let mounted = true;
        asyncMissingSelected.length && setLoading(true);
        asyncMissingSelected.forEach((iri) => {
            if (iri === null) {
                setLoading(false);
                return;
            }
            // On limite les données à remonter pour accélérer la requête :
            const properties = [ "id", resourceLabel, resourceSearchLabel, childrenPropertyName, "customTeam" ]; /** @todo ajouter additionalProperties pour les itemAdditionalComponent */
            const propertiesStringGet = properties.reduce((p, c) => p + `properties[]=${c}&`, '');
            Http.get(`${resourceId}/${getIdFromIri(iri)}/parents?${propertiesStringGet}`, {
                cache: true,
            })
                .then((res) => res["hydra:member"])
                .then(
                    (entities) =>
                        mounted &&
                        setEntities((prev) => [
                            ...new Set([...prev, ...entities]),
                        ])
                );
        });
        return () => {
            mounted = false;
        };
    }, [asyncMissingSelected, resourceId]);

    /**
     * Effet déclenché lors du premier montage du composant
     */
    useEffect(() => {
        let mounted = true;
        const promise = apiProps.current.entitiesResource.apiGetCollection({
            page: 1,
            rowsPerPage:
                apiProps.current.entitiesResource.endpoints &&
                apiProps.current.entitiesResource.endpoints.getAll
                    ? -1
                    : 1000,
            filters: apiProps.current.filters || {},
        });
        promise.then((entities) => {
            if (mounted) {
                setEntities(entities);
                setLoading(false);
            }
        });

        // https://dev.to/otamnitram/react-useeffect-cleanup-how-and-when-to-use-it-2hbm
        return function cleanup() {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        loadSelectedEntities(entities, values);
    }, [entities, values]);

    /**
     * Méthode à appeler si on clique sur le bouton "valider" de ce widget, qui appelle elle-même la props "onChange".
     */
    const handleValidate = (selection) => {
        setSelectedEntities(selection);
        let iriList = selection.map((obj) => obj["@id"]);
        if (multi) {
            onChange(iriList);
        } else {
            onChange(iriList[0] || null);
        }
    };

    const asyncSearch = () => {
        return false;
    };

    const openWidgetTreeModal = () => {
        return modal.open({
            title: "Select " + label,
            content: (
                <EntitiesRecursiveTreeView
                    entities={entities}
                    setParentEntities={setEntities} // j'aurais du faire un contexte pour ça !
                    selectedEntities={selectedEntities}
                    selectable={selectable}
                    childrenPropertyName={childrenPropertyName}
                    childrenObjsPropertyName="childEntitiesObj"
                    resourceId={resourceId}
                    resourceLabel={resourceLabel}
                    resourceSearchLabel={resourceSearchLabel}
                    multi={multi}
                    onValidate={handleValidate}
                    asyncLoading={asyncLoading}
                    asyncSearch={asyncLoading ? asyncSearch : false}
                    modal={modal}
                    itemAdditionalComponent={itemAdditionalComponent}
                />
            ),
        });
    };

    return (
        <div
            className={
                "select-component " +
                (errorHelperText ? "field-error-control" : "")
            }
        >
            {label ? (
                <label>
                    {label}
                    {required ? " *" : ""}
                </label>
            ) : null}
            <div className={(issueButton
                    ? "with-issue-button"
                    : "")
                + " " + "entities-list"}>
                <Typography component={"span"}>
                    <ul>
                        {selectedEntities.map((s) => (
                            <li key={s.id}>{s[resourceLabel]}</li>
                        ))}
                    </ul>
                </Typography>
                {issueButton ? (
                    <IssueButton field={label} issueButton={issueButton} entity={parentEntity} />
                ) : null}
                <Button
                    variant="contained"
                    color="primary"
                    disabled={entities.length < 1 || loading}
                    style={styles.green}
                    onClick={openWidgetTreeModal}
                    className="edit"
                >
                    {loading ? (
                        <LoadingIndicator styles={styles.loadingIndicator} />
                    ) : (
                        buttonText
                    )}
                </Button>
            </div>
            <ModalTreeProvider
                ref={(ref) => {
                    modal = ref;
                }}
            />
        </div>
    );
};

EntitiesTreeWidget.propTypes = {
    /** Liste des IRI des entités affichées initialement */
    values: PropTypes.arrayOf(PropTypes.string),
    /** Détermine si on peut sélectionner une ou plusieurs entités */
    multi: PropTypes.bool,
    /**  */
    onChange: PropTypes.func,
    /** IRI de la ressource qui va fournir les entités */
    resourceId: PropTypes.string.isRequired,
    /**
     * Champ de l'entité à afficher, si vide on utilisera 'title'.
     * 
     * **Attention:** par soucis de cohérence avec ce qui est affiché, ce champ sert également à la recherche
     *              textuelle en back, donc il faut (pour l'instant) une propriété de l'entité, et pas "toString" par exemple. 
     */
    resourceLabel: PropTypes.string,
    /*** Champ del'entité à afficher dans le cas d'une recherche, si vide on utilisera la propriété resourceLabel */
    resourceSearchLabel: PropTypes.string,
    /** Objet contenant les filtres à appliquer sur l'appel ApiResource */
    resourceFilters: PropTypes.object,
    /** Callback appelé pour chaque item qui doit renvoyer True pour que l'item puisse être selectionné */
    selectable: PropTypes.func,
    /** Nom de la propriété qui contient les IRI des enfants, defaut 'children'  */
    childrenPropertyName: PropTypes.string,
    /** Label de la modale entre autres, défaut 'Tree' */
    label: PropTypes.string,
    /** Defaut false */
    required: PropTypes.bool,
    /** Texte d'aide */
    errorHelperText: PropTypes.string,
    /** Booléen si on veut du chargement async */
    asyncLoading: PropTypes.bool,
    /** Texte du bouton pour ouvrir la modal */
    buttonText: PropTypes.string,
    /** Fonction qui renvoie un composant à afficher pour l'item en cours */
    itemAdditionalComponent: PropTypes.func,
};

const styles = {
    green: {
        backgroundColor: "#0dbbb7",
        alignSelf: "end",
        padding: "5px 0",
    },
    loadingIndicator: {
        display: "flex",
        transition: "color 150ms",
        alignSelf: "center",
        fontSize: "0.5rem",
        margin: "0.5rem",
        textAlign: "center",
    },
};

export default EntitiesTreeWidget;
