import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import Http from '../../Services/Http';
import { TableDisplay } from '../Display/TableDisplay/TableDisplay';
import Alert from '../../Services/Alert';
import APIResourceStore from '../../Store/APIResourceStore';

const SearchResult = (_props) => {
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState({});
    const location = useLocation();
    const search = new URLSearchParams(location.search).get('value');
    const noFilter = location.state?.noFilter;
    const searchFiltered = noFilter ? '' : '&filtered=1';

    useEffect(() => {
        setLoading(true);

        const load = async () => {
            const searchResults = await Http.get(`search?value=${search}${searchFiltered}`);
            setResults(
                Object.fromEntries(
                    Object.entries(searchResults['hydra:member'])
                        .filter(([_k, v]) => v.length > 0)
                        .sort(([_k, v], [_k2, v2]) => {
                            if (_k === "models") return 1;
                            if (_k2 === "models") return -1;
                            return v.length - v2.length;
                        }) // 
                        .reverse()
                )
            );
            setLoading(false);
        };
        try {
            load();
        } catch (e) {
            Alert.show({
                message: e.message,
                type: "error"
            });
            setLoading(false);
        }
    }, [search]);

    /**
     * 
     * @param {string} resourceId 
     * @param {string} col 
     * @returns {import('../../Services/APIResource/APIResource').APIResourceField}
     */
    const getAPIResourceField = (resourceId, col) => {
        return APIResourceStore.resources[resourceId]?.fields?.[col];
    }

    return (
        <div className="container">
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </div>
        )}
            {!loading && Object.values(results).length === 0 && 
                <Paper style={styles.noResultPaper}>
                    <Typography>
                        There is no result to display
                    </Typography>
                </Paper>
            }
            <Grid container spacing={2} className={'container resource-detail'}>
                {Object.values(results).length > 0 &&
                    Object.entries(results).map(([k, v]) => {
                        return (
                            <Grid
                                key={k}
                                item
                                xs={6}
                                style={styles.blockHeightStyle}
                                className="ressource-api-container"
                            >
                                <Paper style={styles.gridPaper} className="ressource-api-box">
                                    <h1 className="background-linear-gradient">{APIResourceStore.resources[k]?.name} ({v?.length || 0})</h1>
                                    <TableDisplay
                                        key={k + search.replace(' ', '_')} // pour forcer un rerender du tableau de 0 et effacer les magic filters
                                        rows={v}
                                        cols={Object.keys(v?.[0])
                                            .filter((col) => !['@id'].includes(col))
                                            .map((col) => ({ label: getAPIResourceField(k, col)?.title || col, field: col }))}
                                        resource={APIResourceStore.resources[k]}
                                        allowClick={true}
                                    />
                                </Paper>
                            </Grid>
                        );
                    })}
            </Grid>
        </div>
    );
};
export default SearchResult;

const styles = {
    blockHeightStyle: {
        paddingBottom: 5,
        marginBottom: 35,
    },
    gridPaper: {
        marginTop: 15,
        marginBottom: 15,
        height: '100%',
    },
    noResultPaper: {
        display: 'flex', 
        justifyContent: 'center', 
        height: '5rem',
        alignItems: 'center'
    }
};
