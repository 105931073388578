import React, { Component } from 'react';
import { observer } from 'mobx-react';
import APIResourceStore from '../../Store/APIResourceStore';
import FieldProviderStore from '../../Services/APIResource/FieldProviders/__FieldProviderStore';
import { ROLE } from '../../Services/User/User';
import ParameterStore from '../../Store/ParameterStore';
import { WorkflowConditions } from './WorkflowConditions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Select } from '../Forms/Select/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

export const ActionParameters = observer(
    class ActionParameters extends Component {
        state = {
            focusedField: "body"
        };

        async componentDidMount() {
            this.scopes = await APIResourceStore.resources.scopes
                .apiGetCollection();
        }
        handleChange(field, value) {
            let entity = { ...this.props.value };
            entity[field] = value;

            if (field === "field") {
                entity.value = null;
            }
            this.props.onChange(entity);
        }

        /**
         * Retourne tous les champs pour de toutes les resources de l'app
         * @returns {{resourceId: *, field: any, label: *, fieldId: string}[]}
         */
        static getResourceFields(targetEntity) {
            const res =
                (targetEntity
                    ? WorkflowConditions.getResource(targetEntity)
                    : false) ||
                APIResourceStore.resources[targetEntity] ||
                {};

            return Object.fromEntries(
                [].concat(
                    ...(targetEntity
                        ? [res]
                        : Object.values(APIResourceStore.resources)
                    )
                        .filter(
                            resource => Object.values(resource.fields || {}).length
                        )
                        .filter(
                            resource => resource.instanceId == resource.resourceId
                        )
                        .map(resource =>
                            Object.entries(resource.fields).map(([fieldId, field]) => {
                                const label =
                                    (res ? "" : resource.name + " • ") + field.title;
                                if (field.label != label) {
                                    field.label = label;
                                }
                                return [fieldId, field];
                            })
                        )
                )
            );
        }

        /**
         * Génére le champ d'édition de la valeur, de la même manière que ResourceEdit
         * @param value
         * @param field
         * @param onChange
         * @returns {*}
         */
        genField = (value, field, onChange, entity) => {
            let editComponent = null;
            const fieldId = field.fieldId;
            field.issueButton = false;

            if (!this.props.readOnly) {
                if (field.edit) {
                    editComponent = field.edit(field, value, onChange, entity);
                } else if (
                    FieldProviderStore[field.type] &&
                    FieldProviderStore[field.type].getEdit
                ) {
                    editComponent = FieldProviderStore[field.type].getEdit(
                        field,
                        value,
                        onChange,
                        entity
                    );
                } else {
                    editComponent = FieldProviderStore.default.getEdit(
                        field,
                        value,
                        onChange,
                        entity
                    );
                }
            } else {
                if (field.display) {
                    editComponent = field.display(field, value, onChange, entity);
                } else if (
                    FieldProviderStore[field.type] &&
                    FieldProviderStore[field.type].getDisplay
                ) {
                    editComponent = FieldProviderStore[field.type].getDisplay(
                        field,
                        value,
                        onChange,
                        entity
                    );
                } else {
                    editComponent = FieldProviderStore.default.getDisplay(
                        field,
                        value,
                        onChange,
                        entity
                    );
                }
            }
            return (
                <div className={"edit-field"} key={fieldId}>
                    {editComponent}
                </div>
            );
        };

        getEmailTokens(availableFields) {
            return Object.fromEntries(
                []
                    .concat(
                        ...Object.entries(availableFields).map(
                            ([fieldId, field]) => {
                                // Exclue les relations *toMany (dont le nom fini par entities ou si field.token == false
                                if (fieldId.match(/Entities$/) ||
                                    field.token === false) {
                                    return null;
                                }
                                if (field.type != "entity" || (field.params && field.params.resource === 'scopes')) {
                                    return [[field.token || fieldId, field.label]];
                                }


                                // Pour les types entity, on prend seulement  les non multi et ceux qui sont bien définis
                                if (field.params &&
                                    field.params.resource &&
                                    !field.params.multi) {
                                    // on prends les fields enfants
                                    const subFields = Object.entries(
                                        ActionParameters.getResourceFields(
                                            field.params.resource
                                        )
                                    ).map(([subFieldId, subfield]) => {
                                        // Exclue les sous-champs de type *toMany et même *toOne (pas de récursivité)
                                        if (subFieldId.match(/Entities$/) ||
                                            subfield.type === "entity" ||
                                            subfield.token === false) {

                                            return null;
                                        }
                                        return [
                                            (field.token || fieldId) +
                                            ":" +
                                            (subfield.token || subFieldId),
                                            field.label + " • " + subfield.label
                                        ];
                                    });

                                    if (field.params.resource === 'scopes') {
                                        subFields.push([(field.token || fieldId), field.label]);
                                    }
                                    return subFields;
                                }
                                return null;
                            }
                        )
                    )
                    .filter(i => i)
            );
        }

        getNotificationTokens(availableFields) {
            const tokens = Object.assign({}, this.getEmailTokens(availableFields));
            tokens['CURRENT_USER'] = "The full name of user who made the action.";
            tokens['CURRENT_USER_ID'] = "The id of the user who made the action.";

            for (const role in ROLE) {
                tokens[ROLE[role]] = `All users with role ${role}`;
            }
            if (this.scopes) {
                this.scopes.forEach((scope) => {
                    if (scope.systemId) {
                        tokens[
                            `SCOPE_${scope.systemId}`
                        ] = `Scope ${scope.systemId}`;
                    }
                });
            }
            return tokens;
        }

        formatToken(element, fieldId, text) {
            text = text || "";
            let entity = this.props.value;

            let value =
                (entity && entity[fieldId]) ||
                element.value ||
                (element.tagName.toUpperCase() == "TEXTAREA"
                    ? element.innerText
                    : "");

            if (element.selectionStart || element.selectionStart === 0) {
                // Others
                let startPos = element.selectionStart;
                let endPos = element.selectionEnd;
                value =
                    value.substring(0, startPos) +
                    text +
                    value.substring(endPos, value.length);
                element.selectionStart = startPos + text.length;
                element.selectionEnd = startPos + text.length;
            } else {
                value += text;
            }
            return value
        }

        insertAtCaret = (element, fieldId, text) => {
            let value = this.formatToken(element, fieldId, text);
            element.focus();
            this.handleChange(fieldId, value);
        };

        render() {
            const { entity } = this.props;
            let { focusedField } = this.state;
            let fields;
            let tokens = [];
            let elements = {};


            const availableFields = ActionParameters.getResourceFields(
                this.props.targetEntity
            );
            switch (entity.type) {
                case ParameterStore("ACTION_TYPE_EMAIL"):
                    // Récupère les tokens disponibles depuis les APIResource (label et token || fieldId)
                    tokens = this.getEmailTokens(availableFields);

                    fields = {
                        subject: { title: "Subject", type: "text", required: true },
                        recipient: {
                            title: "Recipient",
                            type: "text",
                            required: true
                        },
                        body: { title: "Body", type: "textarea", required: true }
                    };

                    fields.suggestedTokens = {
                        title: "Available tokens",
                        edit: (...args) => (
                            <TokenComponent
                                tokens={tokens}
                                {...args}
                                onSelect={value => {
                                    if (!focusedField || !elements[focusedField]) {
                                        return;
                                    }
                                    this.insertAtCaret(
                                        elements[focusedField],
                                        focusedField,
                                        value
                                    );
                                }}
                            />
                        )
                    };

                    break;

                case ParameterStore("ACTION_TYPE_NOTIFICATION"):
                    tokens = this.getNotificationTokens(availableFields);
                    const tokensForAC = Object.keys(tokens).map(t => ({ label: tokens[t], key: t }));
                    fields = {
                        fromUser: {
                            title: "From user",
                            type: "string",
                            params: {
                            },
                            required: false
                        },
                        toUsers: {
                            title: "To users",
                            type: "text",
                            required: true,
                            params: {},
                            edit: (field, fieldValue, onChange, currentEntity) => {
                                const selection = fieldValue ? fieldValue.replace(/[\]\[]/g, '').split(',') : [];
                                selection.forEach(s => {
                                    if (!tokens[s]) {
                                        tokensForAC.push({ key: s, label: s })
                                    }
                                })
                                return (<Autocomplete
                                    multiple
                                    id="tags-standard"
                                    options={tokensForAC}
                                    defaultValue={''}
                                    freeSolo
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, value) => {
                                        onChange(value.map(v => v.key ? '[' + v.key + ']' : v).join(','))
                                    }}
                                    value={tokensForAC.filter(t => selection.indexOf(t.key || t) > -1)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="To users"
                                            placeholder=""
                                        />
                                    )}
                                />)
                            }
                        },
                        content: {
                            title: "Message",
                            type: "textarea",
                            params: {},
                            required: true
                        },
                        link: {
                            type: "string",
                            title: "Destination link"
                        },

                        byEmail: {
                            type: "bool",
                            title: "Send an e-mail alert",
                            required: true,
                        },
                        suggestedTokens: {
                            title: "Available tokens",
                            edit: (...args) => (
                                <TokenComponent
                                    tokens={tokens}
                                    {...args}
                                    onSelect={value => {
                                        this.insertAtCaret(
                                            elements[focusedField],
                                            focusedField,
                                            value
                                        );
                                    }}
                                />
                            )
                        },
                    };
                    break;
                case ParameterStore("ACTION_TYPE_DOCUMENT"):
                default:
                    // TODO document
                    break;
                case ParameterStore("ACTION_TYPE_VALUE"):
                    const fieldsMap = Object.fromEntries(
                        Object.entries(availableFields).map(([fieldId, field]) => [
                            fieldId,
                            field.label
                        ])
                    );

                    fields = {
                        field: {
                            title: "Field to update",
                            type: "mappedObject",
                            params: { mapping: fieldsMap },
                            required: true
                        }
                    };

                    const selectedField =
                        availableFields[(this.props.value || {}).field || ""];
                    if (selectedField) {
                        fields.value = Object.assign({}, selectedField, {
                            title: "Value to assign",
                            required: true
                        });
                    }
                    break;
            }

            // method to set focused field
            if (fields) {
                Object.entries(fields).map(([fieldId, field]) => {
                    if (["suggestedTokens"].indexOf(fieldId) > -1) {
                        return;
                    }
                    field.ref = e => (elements[fieldId] = e);
                    field.onFocus = () => this.setState({ focusedField: fieldId });
                });
            }

            const form = !fields
                ? ""
                : Object.entries(fields).map(([fieldId, field]) => (
                    <div
                        key={fieldId}
                        style={Object.assign(
                            { margin: "5px" },
                            field.styles || {}
                        )}
                    >
                        {this.genField(
                            (this.props.value || {})[fieldId],
                            field,
                            value => {
                                this.handleChange(fieldId, value);
                            },
                            this.props.value
                        )}
                    </div>
                ));

            return !form ? null : (
                <fieldset className="action-parameters display-text-field">
                    <legend>Parameters</legend>
                    {form}
                </fieldset>
            );
        }
    }
);

const TokenComponent = ({ field, tokens, onSelect }) => {
    if (!tokens) {
        tokens = [];
    }

    const [value, setValue] = React.useState('');
    return (
        <div>
            <hr />

            <div
                style={{
                    width: '45%',
                    marginLeft: '55%',
                    display: 'flex',
                    justifyContent: 'space-around',
                }}
            >
                <Tooltip
                    title="Pick a token in order for it to be added at the caret position of the current selected field. It will be replaced by the target entity value when the e-mail is sent."
                    placement={'left'}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ minWidth: '2rem', padding: '6px 0 6px 2px' }}
                    >
                        <Icon
                            className={'fa fa-code'}
                            style={{ fontSize: '1.2rem', width: '2rem' }}
                        ></Icon>
                    </Button>
                </Tooltip>
                <div
                    style={{
                        flex: 1,
                        marginLeft: '15px',
                        //  marginTop: "-10px"
                    }}
                >
                    <Select
                        placeholder={'Use a special token…'}
                        options={Object.keys(tokens)
                            .map((key) => {
                                return {
                                    value: '[' + key + ']',
                                    label: tokens[key],
                                };
                            })
                            .sort((a, b) =>
                                a.label < b.label ? -1 : +(a.label > b.label)
                            )}
                        value={value || null}
                        onChange={(item) => {
                            onSelect(item && item.value);
                            setValue(null);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
