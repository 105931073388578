import React, { PureComponent } from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import ObliqueAaxisTick from "../ObliqueAaxisTick";


const COLORS = ['#00838A','#F6A800', '#BDCF41',  '#E74D5D', "#00B2CB", "#A778AE", '#ABDDDC', '#EC7A94', "#3C003A", "#7C3423", "#646567"];
export default class ValidModelsByModelTypesBetweenDatesChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data : [],
            linesChartNames: [],
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.data && (this.props.data !== prevProps.data)){
            this.setState({data: this.props.data})
        }

        // on construit dynamiquement les lignes en fonction des noms de modèles
        if(this.state.linesChartNames.length === 0) {
            for (let i in this.state.data) {
                let linesChartNames = [];
                for (let modelType in this.state.data[i]) {
                    if (modelType !== 'name') {
                        linesChartNames.push(modelType);
                    }
                }

                this.setState({linesChartNames: linesChartNames});
                break;
            }
        }
    }

    render() {

        return (
            <ResponsiveContainer>
                <LineChart
                    width={800}
                    height={350}
                    data={this.state.data}
                    margin={{
                        top: 5, right: 0, left: 0, bottom: 75,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" tick={<ObliqueAaxisTick />} />
                    <YAxis />
                    <Tooltip />
                    <Legend wrapperStyle={{ fontSize: 14, top : -15 }} verticalAlign="top" height={40} />
                    { this.state.linesChartNames.map((val, index) => {
                          return <Line type="monotone" key={index} dataKey={val} stroke={ COLORS[index] } activeDot={{ r: 6 }} />
                    })}
                </LineChart>
            </ResponsiveContainer>
        )
    }
}