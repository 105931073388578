import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export class DoughnutWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialRows: null,
            rows: null,
        };
    }

    render() {
        const { rows, cols } = this.props;
        const data = {
            labels: cols,
            datasets: [
                {
                    data: rows,
                    backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                    hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                },
            ],
        };

        return (
            <Doughnut
                data={data}
                options={{
                    plugins: {
                        datalabels: {
                            color: 'black',
                        },
                    },
                }}
            />
        );
    }
}
