/**
 * https://medialab.github.io/iwanthue/
 * 70 colors generated.
 */

import color from "color"

const colors = ["#c45790",
"#59cc7c",
"#3d2582",
"#adbc3c",
"#5556bf",
"#92c857",
"#6e2e90",
"#63bb5b",
"#a83492",
"#417c25",
"#8e78e5",
"#c5a02c",
"#6089ed",
"#da8829",
"#426bc2",
"#728d23",
"#df73d1",
"#4bc291",
"#e2508f",
"#33d4d1",
"#d53e58",
"#579b59",
"#955cb9",
"#c4ba58",
"#454196",
"#aac36f",
"#752a7a",
"#296324",
"#c186e6",
"#71792c",
"#dc67b5",
"#bb8b38",
"#2f4083",
"#c86b2b",
"#449be1",
"#a73a1a",
"#8892de",
"#94501e",
"#4d63a8",
"#e48055",
"#48276a",
"#d4a262",
"#752063",
"#846625",
"#de9be3",
"#751610",
"#9b65ad",
"#e1554f",
"#6b0f48",
"#d97a61",
"#a95495",
"#7f361e",
"#e686b8",
"#74171f",
"#e2769a",
"#72182e",
"#e67676",
"#a0256a",
"#a3332d",
"#863563",
"#c94d58",
"#7c2146",
"#e07385",
"#9c2335",
"#ad5782",
"#a64546",
"#ae3164",
"#a34656",
"#da476f",
"#aa2c4f"]

export default colors

const datalabelsColors = colors.map(c => color(c).isLight() ? "black" : "white")

export {datalabelsColors}
