import React, { Component } from "react";
import MUIModal from "@material-ui/core/Modal";
import { AppBar, Backdrop, Fade, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

class ModalTree {
  constructor() {
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.refs = {};
  }
  open(params) {
    if (this.ref) {
      this.ref.open(params);
    }
    return this;
  }
  close() {
    /**if (this.ref) {
      console.log(this.ref);
      this.close_ref(this.ref);
    }*/
    // On stocke toutes les refs sinon il y a un bug quand on veut fermer la modal et qu'il y a >= 2 providers sur la meme page
    Object.keys(this.refs).forEach((k) => {
      let ref = this.refs[k];
      this.close_ref(ref);
      this.refs[k] = undefined;
    });
  }
  close_ref(ref) {
    if(ref && ref.close){
      ref.close();
    }
  }
}

export default new ModalTree();

export class ModalTreeProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      title: "",
      content: null,
      closable: true,
      hasFooter: false,
      disableSubmit: false,
      submitButtonText: "",
    };
  }

  open(params) {
    this.setState({
      open: true,
      ...params,
      closable: params.closable !== undefined ? params.closable : true,
      onClose: params.onClose,
      contentStyle: params.contentStyle || {},
      modalStyle: params.modalStyle || {},
    });
  }

  close() {
    this.setState({
      open: false,
      title: "",
      content: null,
      closable: true,
      hasFooter: false,
      disableSubmit: false,
      submitButtonText: "",
    });
    if (this.state.onClose) {
      this.state.onClose();
    }
  }

  render() {
    const { hasFooter, submitButtonText } = this.state;
    return (
      <MUIModal
        style={styles.modal}
        open={this.state.open}
        onClose={() => this.setState({ open: false }) && this.close()}
        onBackdropClick={() =>
          this.state.onBackdropClick && this.state.onBackdropClick()
        }
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        keepMounted={true}
      >
        <Fade in={this.state.open}>
          <div
            style={{
              ...styles.paper,
              ...{ paddingBottom: hasFooter ? 60 : 0 },
              ...this.state.modalStyle,
            }}
            className="modal-content"
          >
            <AppBar
              position="static"
              className="background-lowlight background-linear-gradient"
            >
              <Toolbar style={styles.toolBar}>
                <Typography style={styles.title} variant="h6">
                  {this.state.title}
                </Typography>
                {this.state.closable && (
                  <CloseIcon onClick={() => this.close()} />
                )}
              </Toolbar>
            </AppBar>
            <div style={{ ...styles.content, ...this.state.contentStyle }}>
              {this.state.content}
            </div>

            {this.state.hasFooter ? (
              <Toolbar className="footer" style={styles.toolBar}>
                <Button
                  variant="contained"
                  color="primary"
                  style={styles.bottomButton}
                  type={"button"}
                  disabled={this.state.disableSubmit}
                  onClick={this.onSubmit}
                  className="button-general"
                >
                  {submitButtonText || "Save"}
                </Button>
              </Toolbar>
            ) : null}
          </div>
        </Fade>
      </MUIModal>
    );
  }
}

const styles = {
  green: {
    backgroundColor: "#0dbbb7",
    alignSelf: "end",
    padding: "5px 0",
  },
  loadingIndicator: {
    display: "flex",
    transition: "color 150ms",
    alignSelf: "center",
    fontSize: "0.5rem",
    margin: "0.5rem",
    textAlign: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#ffffff",
    border: "2px solid #000",
    padding: 0,
  },
  title: {
    marginTop: 0,
    flex: 1,
  },

  content: {
    padding: 20,
    overflow: "auto",
    boxSizing: "border-box",
  },
  bottomButton: {
    position: "absolute",
    right: 15,
    bottom: 15,
  },
};
