import React from 'react';
// import { toJS } from 'mobx/lib/mobx';
import ScopeInput from './ScopeInput';
import OperatorSelect from './OperatorSelect';

function ScopeInputWithOperator({ setParameters, parameters = {} }) {
    const onOperatorChange = (selectvalue) => {
        setParameters({ ...parameters, operator: selectvalue && selectvalue.value });
    };

    const onScopeChange = (selectvalue) => {
        if (selectvalue) {
            let selectedValues;

            if (Array.isArray(selectvalue)) {
                selectedValues = selectvalue.map((i) => i.split('/').pop());
            } else {
                selectedValues = selectvalue.split('/').pop();
            }

            setParameters({ ...parameters, scopeId: selectedValues });
        }
    };

    return (
        <div style={{ position: 'relative', height: '500px' }}>
            <OperatorSelect
                name={'operator'}
                label={'Operator'}
                operators={['is', 'is not', 'is my team']}
                onChange={(v) => onOperatorChange(v)}
                value={parameters.operator}
            />
            {parameters.operator !== 'is my team' && (
                <ScopeInput value={parameters.scopeId} label={'Scope'} onChange={(v) => onScopeChange(v)} />
            )}
        </div>
    );
}

export default ScopeInputWithOperator;
