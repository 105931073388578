import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import EditAttributes from "@material-ui/icons/EditAttributes";
import SaveIcon from "@material-ui/icons/Save";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import Radio from "@material-ui/core/Radio";

import {
    adaptTreeToTreeView,
    entitiesToTree,
    flattenTree,
    nodeGetParents,
} from "./treeUtils";
import Http from "../../Services/Http";
import { LinearProgress } from "@material-ui/core";
import { ActionButton } from "../Modal/ActionButton";
import { ModalContent } from "../Modal/ModalContent";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: "800px",
        minHeight: "120px",
    },
    label: {
        display: "flex",
        justifyContent: "left",
    },
    filtered: {
        backgroundColor: "burlywood !important ",
    },
    selected: {
        backgroundColor: "darkmagenta !important",
        color: "white",
    },
    checkboxChecked: {
        color: "white !important",
    },
});

/**
 * Composant pour afficher un TreeView récursif pour les entities.
 *
 */
const EntitiesRecursiveTreeView = (props) => {
    const {
        childrenPropertyName,
        resourceId,
        resourceLabel,
        resourceSearchLabel = "hierarchyDisplayFlat",
        asyncLoading = false,
        setParentEntities = false,
        modal,
    } = props;
    const foundInit = { found: [], parents: [], init: true };

    const [entities, setEntities] = useState(props.entities);
    const [entitiesTree, setEntitiesTree] = useState([]);
    const [found, setFound] = useState(foundInit);

    useEffect(() => {
        let entitiesTree = entitiesToTree(
            entities,
            childrenPropertyName,
            asyncLoading
        );
        setEntitiesTree(entitiesTree);
        // On accumule les entities :
        // setEntitiesAll((prev) => [...new Set([...prev, ...entities])]);
    }, [entities]);

    /**
     * On remplit également le parent si asyncLoading car il ne possédait qu'une partie des données.
     */
    useEffect(() => {
        if (asyncLoading && setParentEntities) setParentEntities(entities);
    }, [entities]);

    useEffect(() => {
        setEntities((prev) =>{
        
        prev = prev.filter(p => !found.found.map(e => e.id).includes(p.id));
        prev = prev.filter(p => !found.parents.map(e => e.id).includes(p.id));
        return[...prev, ...found.found, ...found.parents];
        });
    }, [found]);

    const asyncNextLevel = (id) => {
        // On limite les données à remonter pour accélérer la requête :
        const properties = [ "id", resourceLabel, resourceSearchLabel, childrenPropertyName, "customTeam" ]; /** @todo ajouter additionalProperties pour les itemAdditionalComponent */
        const propertiesStringGet = properties.reduce((p, c) => p + `properties[]=${c}&`, '');
        Http.get(`${resourceId}/${id}/children?${propertiesStringGet}`, { cache: true })
            .then((res) => {
                let members = res["hydra:member"];
                let e = entities.filter(e => e.id === id)[0]; // l'entité dont on cherche les enfants
                if (members.length < e[childrenPropertyName].length){
                    // Si on récupère moins d'enfants que prévu (probablement du à un filtre en back)
                    // alors on supprime les enfants manquants, sinon on essaierait perpétuellement
                    // de les recharger.
                    let membersIri = members.map(m => m['@id']);
                    e[childrenPropertyName]  =  e[childrenPropertyName].filter(c => membersIri.includes(c));
                    members.push(e);
                }
                return members;
            })
            .then((entities) =>
                setEntities((prev) => {
                    prev = prev.filter(p => !entities.map(e => e.id).includes(p.id));
                    return [...prev, ...entities];
                })
            );
    };

    /**
     * @todo
     *
     * @param {string} input
     */
    const asyncSearch = (input, callback) => {
        return (
            Http.get(
                `${resourceId}/search_hierarchy?input=${input}&with_parents=1&resourceLabel=${resourceLabel}`,
                { cache: true }
            )
                .then((res) => res["hydra:member"])
                // Avant de filtrer les résultats on remplit les entités supplémentaires
                .then((obj) => {
                    setFound(obj); // Ecrase found.init, et remplit found.found et found.parent
                    return obj;
                })
                .then((obj) => callback(obj.found || [], obj.parents || []))
        );
    };

    const asyncSearchDebounced = _.debounce(asyncSearch, 1000);

    // s'il y a trop de résultats on remplace les entities à afficher par la liste simple des résultats
    return (
        entitiesTree && (
            <RecursiveTreeView
                {...props}
                resourceLabel={found.found.length > 0 ? resourceSearchLabel : resourceLabel}
                entitiesTree={
                    !found.init // Au premier chargement on affiche ce que l'on a, ensuite cela dépendra des recherches
                        ? entitiesToTree(
                              found.found.map((o) => ({
                                  ...o,
                                  [childrenPropertyName]: [],
                              })),
                              childrenPropertyName,
                              asyncLoading,
                              resourceSearchLabel
                          )
                        : entitiesTree
                }
                entitiesTreeFull={entitiesTree}
                asyncNextLevel={asyncLoading && asyncNextLevel}
                asyncSearch={asyncLoading && asyncSearchDebounced}
                reloadEntities={() => setFound(foundInit)}
                modal={modal}
            />
        )
    );
};

EntitiesRecursiveTreeView.propTypes = {
    /** Collection de tous les Entities sous forme d'arbre */
    entities: PropTypes.array,
    /** Valeur de départ des entities déjà sélectionnés */
    selectedEntities: PropTypes.array,
    /** Callback appelé pour chaque item qui doit renvoyer True pour que l'item puisse être selectionné */
    selectable: PropTypes.func,
    /** Callback au clic sur "valider" */
    onValidate: PropTypes.func,
    /** True si on peut sélectionner plusieurs entities, false sinon */
    multi: PropTypes.bool,
    /** Nom de la propriété qui contient les entités enfants, defaut "children" */
    childrenPropertyName: PropTypes.string,
    /** Nom de la propriété qui contient les objets enfants, defaut "childrenObjs" */
    childrenObjsPropertyName: PropTypes.string,
    /** Id de la ressource, correspond au niveau 1 de l'iri pour charger les entités en asynchone (ex: "scopes") */
    resourceId: PropTypes.string,
    /** Nom de la propriété qui contient la donnée à afficher */
    resourceLabel: PropTypes.string,
    /** Nom de la proriété qui contient la donnée à afficher en cas de recherche */
    resourceSearchLabel: PropTypes.string,
    /** True si on est en vue administrateur */
    adminView: PropTypes.bool,
    /** Indique si les données du composant sont chargées en asynchrone */
    asyncLoading: PropTypes.bool,
    /** Callback useState pour mettre à jour les entities au niveau du parent (EntitiesTreeWidget) */
    setParentEntities: PropTypes.func,
    /** Ref vers la modal en cours */
    modal: PropTypes.any,
    /** Fonction qui renvoie un composant à afficher pour l'item en cours */
    itemAdditionalComponent: PropTypes.func,
};

const RecursiveTreeView = (props) => {
    const {
        entitiesTree,
        entitiesTreeFull,
        selectedEntities,
        onValidate,
        multi,
        selectable,
        childrenObjsPropertyName = "childrenObjs",
        resourceLabel,
        adminView = false,
        asyncNextLevel = false,
        asyncSearch = false,
        reloadEntities,
        modal,
        itemAdditionalComponent,
    } = props;

    const QUERY_MIN_CHARS = 2;

    const secureSelectable = (item) => {
        if (!item || item.notLoaded === true) {
            return false;
        }
        return selectable ? selectable(item) : true;
    };

    const classes = useStyles();
    const [expanded, setExpanded] = useState([]);
    const [filtered, setFiltered] = useState([]); // Pour le style des items
    const [selected, setSelected] = useState(
        selectedEntities.map((obj) => obj.id)
    );
    const [loading, setLoading] = useState(false);

    // Liste de toutes les entities à afficher dans le composant, à partir de l'arbre
    const entitiesFlat = useMemo(
        () => flattenTree(entitiesTree, childrenObjsPropertyName),
        [entitiesTree, childrenObjsPropertyName]
    );
    // Liste de toutes les entities connues par le composant mais pas nécessairement affichées,
    // (notamment à la suite d'une recherche) 
    const entitiesFlatFull = useMemo(
        () => flattenTree(entitiesTreeFull, childrenObjsPropertyName),
        [entitiesTreeFull, childrenObjsPropertyName]
    );
    const data = adaptTreeToTreeView(
        entitiesTree,
        childrenObjsPropertyName,
        resourceLabel || undefined
    );

    const updateExpanded = useCallback(
        (expanded, selected) => {
            let _expanded = [];
            const init = entitiesFlatFull.filter((obj) =>
                selected.includes(obj.id)
            );
            init.forEach((obj) => _expanded.push(...nodeGetParents(obj)));
            _expanded = adaptTreeToTreeView(
                _expanded,
                childrenObjsPropertyName,
                resourceLabel || undefined
            );
            // On supprime les doublons :
            return [
                ...new Set([...expanded, ..._expanded.map((o) => o.nodeId)]),
            ];
        },
        [entitiesFlatFull, childrenObjsPropertyName]
    );

    useEffect(() => {
        // Si les éléments sélectionnés changent alors on les ajoute dans les items ouverts,
        // notamment pour le premier affichage.
        setExpanded((e) => updateExpanded(e, selected));
    }, [selected, updateExpanded]);

    useEffect(() => {
        if (asyncNextLevel) {
            let notLoaded = entitiesFlat
                .filter((obj) => expanded.includes(String(obj.id)))
                .filter(
                    (e) =>
                        e.childEntitiesObj &&
                        e.childEntitiesObj.reduce(
                            (prev, o) => prev || !!o.notLoaded,
                            false
                        )
                );
            notLoaded.map((node) => asyncNextLevel(node.id));
        }
    }, [expanded]);

    const handleValidate = () => {
        let _selected = [...selected];
        onValidate(
            _selected.map((objId) =>
                entitiesFlatFull.find((obj) => obj.id === objId)
            )
        );
        modal.close();
    };

    /**
     * Renvoie true si on peut sélectionner de nouveaux éléments, false sinon.
     * Pour l'instant on estime qu'on peut toujours sélectionner de nouveaux éléments.
     */
    const canSelect = () => {
        return true;
        //return !multi && selected.length > 0 ? false : true;
    };

    /**
     * On enlève ou on rajoute le node dans le state local des éléments sélectionnés.
     * ATTENTION si on désactive les boutons pour certains éléments alors on n'exécute
     * jamais cette fonction pour les éléments en question.
     *
     * @param {number} objId
     */
    const toggleSelectedNode = (objId) => {
        let _selected = [...selected];
        // Si l'élément existe on le supprime
        if (_selected.includes(objId)) {
            _selected = _selected.filter((id) => id !== objId);
        } else {
            // Sinon on l'ajoute si on a la possibilité
            if (!multi) {
                _selected = [objId];
            } else {
                _selected.push(objId);
            }
        }
        setSelected(_selected);
    };

    /**
     * Action déclenchée quand on appuie sur la checkbox de sélection.
     *
     * @param {*} event
     * @param {*} node
     */
    const handleSelectNode = (event, node) => {
        // on ne veut pas déclencher l'evenement du toggle :
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        if (node.objId !== undefined) {
            toggleSelectedNode(node.objId);
        }
    };

    /**
     * On a besoin du handleToggle pour remplacer le toggle par défaut de material-ui
     * car on souhaite gérer nous même la liste des "expanded", notamment pour la recherche.
     */
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    /**
     * Fonction qui renvoie les objets de flat qui matchent l'input
     */
    const filterInput = (input, flat) => {
        const r = RegExp(input, "i");
        const filteredEntities = flat.filter((obj) => r.test(obj.title));
        return filteredEntities;
    };

    /**
     * Evenement pour filtrer les entities dans le Tree
     *
     * On récupère les objets filtrés et on les ajoute (avec leurs parents) dans la
     * liste des ids "expanded". Puis on met à jour le state.
     *
     * @todo sauvegarder l'état expanded avant l'usage du filtre
     */
    const filterEvent = (event) => {
        /**
         *  _expanded et _filtered sont des listes d'objets Entities, donc il faut les adapter aux exigences de la TreeView
         * @param {*} _expanded
         * @param {*} _filtered
         */
        const updateState = (_expanded, _filtered) => {
            _expanded = adaptTreeToTreeView(
                _expanded,
                childrenObjsPropertyName,
                resourceLabel || undefined
            );
            _filtered = adaptTreeToTreeView(
                _filtered,
                childrenObjsPropertyName,
                resourceLabel || undefined
            );
            setExpanded([..._expanded.map((o) => o.nodeId)]);
            setFiltered(_filtered.map((o) => o.nodeId));
            setLoading(false);
        };

        setExpanded([]);

        if (event.target.value && event.target.value.length >= QUERY_MIN_CHARS) {
            setLoading(true);
            if (asyncSearch) {
                asyncSearch(event.target.value, (found, parents) =>
                    updateState(parents, found)
                );
            } else {
                let _filtered = filterInput(event.target.value, entitiesFlatFull);
                let _expanded = [];
                _filtered.forEach((obj) =>
                    _expanded.push(...nodeGetParents(obj))
                );
                updateState(_expanded, _filtered);
            }
        } else {
            // remise à zéro du filtre
            setExpanded([]);
            setFiltered([]);
            reloadEntities();
        }
    };

    const renderTreeItems = (nodes) => (
        <TreeItem
            key={nodes.key}
            nodeId={nodes.nodeId}
            classes={{
                label: classes.label,
                content: selected.includes(nodes.objId)
                    ? classes.selected
                    : filtered.includes(nodes.nodeId)
                    ? classes.filtered
                    : "",
            }}
            label={
                <CustomTreeLabel
                    node={nodes}
                    selected={selected}
                    selectable={secureSelectable}
                    canSelect={canSelect}
                    handleSelectNode={handleSelectNode}
                    multi={multi}
                    adminView={adminView}
                    modal={modal}
                    itemAdditionalComponent={itemAdditionalComponent}
                />
            }
        >
            {Array.isArray(nodes.children)
                ? nodes.children.map((node) => renderTreeItems(node))
                : null}
        </TreeItem>
    );

    const field = { name: "recherche", label: `Search (min. ${QUERY_MIN_CHARS} char.)` };

    return (
        <ModalContent>
            <Input
                placeholder={field.label}
                name={field.name}
                onChange={filterEvent}
            />
            { loading && <LinearProgress />}
            <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={[]}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={handleToggle}
            >
                {data.length ? data.map((d) => renderTreeItems(d)) : <i>No result matching your search</i>}
            </TreeView>
            <ActionButton
                startIcon={<SaveIcon />}
                style={styles.validateButton}
                onClick={handleValidate}
            >
                Validate
            </ActionButton>
        </ModalContent>
    );
};

RecursiveTreeView.propTypes = {
    /** Collection des Entities à afficher, sous forme d'arbre */
    entitiesTree: PropTypes.array.isRequired,
    /** Collection de toutes les Entities sous forme d'arbre */
    entitiesTreeFull: PropTypes.array.isRequired,
    /** Valeur de départ des entities déjà sélectionnés */
    selectedEntities: PropTypes.array,
    /** Callback appelé pour chaque item qui doit renvoyer True pour que l'item puisse être selectionné */
    selectable: PropTypes.func,
    /** Callback au clic sur "valider" */
    onValidate: PropTypes.func,
    /** True si on peut sélectionner plusieurs entities, false sinon */
    multi: PropTypes.bool,
    /** Nom de la propriété qui contient les objets enfants, defaut "childrenObjs" */
    childrenObjsPropertyName: PropTypes.string,
    /** Nom de la propriété qui contient la donnée à afficher */
    resourceLabel: PropTypes.string,
    /** True si on est en vue administrateur */
    adminView: PropTypes.bool,
    /** Déclenche le chargement asynchrone du niveau suivant */
    asyncNextLevel: PropTypes.func,
    /** Déclenche le chargement asynchrone du niveau suivant */
    asyncSearch: PropTypes.func,
    /** Remet à zéro les résultats de la recherche */
    reloadEntities: PropTypes.func,
    /** Contient la ref de la Modal utilisée */
    modal: PropTypes.any,
    /** Fonction qui renvoie un composant à afficher pour l'item en cours */
    itemAdditionalComponent: PropTypes.func, // besoin d'autres properties ?
};

/**
 * Composant pour afficher un Label Custom dans les Items du TreeView
 */
const CustomTreeLabel = (props) => {
    const {
        node,
        selected,
        selectable = () => true,
        handleSelectNode,
        canSelect,
        multi,
        adminView,
        modal,
        itemAdditionalComponent,
    } = props;

    const history = useHistory();
    const classes = useStyles();
    const RadioOrCheckbox = multi ? Checkbox : Radio;

    /**
     * NB: Peut recevoir (e, node) si besoin.
     */
    const redirect = () => {
        modal.close();
        history.push("/");
    };

    if (!node.objId) {
        // Pas de button si l'item n'a pas d'id, ce n'est pas un entity donc.
        return <div style={styles.treeLabel}>{node.name}</div>;
    } else {
        return (
            <>
                {!adminView && selectable(node) ? (
                    <RadioOrCheckbox
                        classes={{ checked: classes.checkboxChecked }}
                        checked={selected.includes(node.objId)}
                        disabled={
                            !(selected.includes(node.objId) || canSelect())
                        }
                        size="small"
                        onClick={(e) => handleSelectNode(e, node)}
                    />
                ) : (
                    // On met une box de la même taille qu'une "small" checkbox
                    <Box width="38px" height="38px"></Box>
                )}
                <div style={styles.treeLabel}>{node.name}</div>
                {itemAdditionalComponent && itemAdditionalComponent(node)}
                {adminView ? (
                    <Button
                        startIcon={<EditAttributes />}
                        onClick={(e) => redirect(e, node)}
                        /** @todo quelle est l'url pour les entities ? */
                    >
                        Détails{/*translation ?*/}
                    </Button>
                ) : null}
            </>
        );
    }
};

CustomTreeLabel.propTypes = {
    /** Noeud de l'arbre à afficher dans ce composant label */
    node: PropTypes.object.isRequired,
    /** Liste des éléments sélectionnés */
    selected: PropTypes.array,
    /** Doit prendre un node en entrée et renvoie si on peut le selectionner */
    selectable: PropTypes.func,
    /** Callback qui prend le node en entrée pour déclencher un traitement */
    handleSelectNode: PropTypes.func,
    /** Callback qui prend le node en entrée et renvoie True si le noeud est selectionnable */
    canSelect: PropTypes.func,
    /** Si True alors le composant affiche des informations spécifiques pour l'administrateur */
    adminView: PropTypes.bool,
    /** Contient la ref de la Modal utilisée */
    modal: PropTypes.any,
    /** Fonction qui renvoie un composant à afficher pour l'item en cours */
    itemAdditionalComponent: PropTypes.func,
};

const styles = {
    treeLabel: {
        alignSelf: "center",
        marginLeft: "14px",
    },
    validateButton: {
        bottom: "0px",
        right: "50px",
        position: "absolute",
    },
};

export { RecursiveTreeView };
export default EntitiesRecursiveTreeView;
