import React, { Component } from 'react';
import {Paper} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DateFormatter from "../../Services/DateFormatter";
import 'react-date-range/dist/styles.css'; // main style file for datapicker
import 'react-date-range/dist/theme/default.css'; // theme css file for datapicker
import './datePicker.css';
import { DateRangePicker } from './DateRangePicker';

import TieringBetweenDatesChart from "./Tiering/TieringBetweenDatesChart"
import ModelByMraStatusBetweenDatesChart from "./Mra/ModelByMraStatusBetweenDatesChart";
import ValidationsModelsBetweenDatesChart from "./Validate/ValidationsModelsBetweenDatesChart";
import ValidModelsByModelTypesBetweenDatesChart from "./Validate/ValidModelsByModelTypesBetweenDatesChart";
import ModelsByScoreBetweenDatesChart from "./Mra/ModelsByScoreBetweenDatesChart";
import ModelsByStatusBetweenDatesChart from "./Validate/ModelsByStatusBetweenDatesChart";
import Modal from "../../Services/Modal";
import Button from "@material-ui/core/Button";


export default class ChartWithDateRangePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : [],
            startDate: DateFormatter.addDaysDateObject(-30,new Date()),
            endDate: new Date(),
        };
    }

    componentDidMount() {
        if(this.props.data){
            let arrayLength = Object.keys(this.props.data[this.props.reportingType]).length - 1;
            let newStartDate = DateFormatter.dayStringToDateObject(Object.keys(this.props.data[this.props.reportingType])[0]);
            let newEndDate = DateFormatter.dayStringToDateObject(Object.keys(this.props.data[this.props.reportingType])[arrayLength]);

            this.updateStats(newStartDate, newEndDate);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((this.props.data && this.props.data[this.props.reportingType])){
            let arrayLength = Object.keys(this.props.data[this.props.reportingType]).length - 1;
            let newStartDate = DateFormatter.dayStringToDateObject(Object.keys(this.props.data[this.props.reportingType])[0]);
            let newEndDate = DateFormatter.dayStringToDateObject(Object.keys(this.props.data[this.props.reportingType])[arrayLength]);

            if(!(prevProps.data && prevProps.data[this.props.reportingType])){
                this.updateStats(newStartDate, newEndDate);
                return;
            }

            arrayLength = Object.keys(prevProps.data[this.props.reportingType]).length - 1;
            let oldStartDate = DateFormatter.dayStringToDateObject(Object.keys(prevProps.data[this.props.reportingType])[0]);
            let oldEndDate = DateFormatter.dayStringToDateObject(Object.keys(prevProps.data[this.props.reportingType])[arrayLength]);

            if(oldStartDate.getTime() !== newStartDate.getTime() || oldEndDate.getTime() !== newEndDate.getTime()){
                this.updateStats(newStartDate, newEndDate);
            }
        }
    }

    updateStats(startDate, endDate){
        this.setState({
            data: this.formatData(this.props.data[this.props.reportingType]),
            startDate: startDate,
            endDate: endDate,
        });
    }

    /**
     * Récupération du statutID dans le nom des résultats des stats
     * @param statusString
     * @returns {*|string}
     */
    getStatusId(statusString){
        let statusTab = statusString.split('_');
        return statusTab[2];
    }

    /**
     * Liste des statuts en fonction de leur Id
     * @param val
     * @returns {string}
     */
    getModelStatusValues(val){
        switch (val) {
            case 0 : return 'OK - points of attention';
            case 1 : return 'OK - with minor issues';
            case 2 : return 'OK - without reservation';
            case 3 : return 'Not OK - redesign';
            default:
                return '';
        }
    }

    formatData(rawData){
        let datas = [];

        switch (this.props.reportingType) {
            case 'tieringBetweenDates':
                for(let date in rawData){
                    if(rawData[date].length === 4){
                        datas.push({name: DateFormatter.frToUsFormat(date), 'No tier': rawData[date][0].quantity, 'Tier 1': rawData[date][1].quantity, 'Tier 2': rawData[date][2].quantity, 'Tier 3': rawData[date][3].quantity});
                    }
                }
                break;
            case 'modelByMraStatusBetweenDates':
                for(let date in rawData){
                    if(rawData[date].length === 4){
                        datas.push({name: DateFormatter.frToUsFormat(date), 'Validated': rawData[date][0].quantity, 'From LoD1': rawData[date][1].quantity, 'Modified by LoD2': rawData[date][2].quantity, 'Reviewed by LoD1': rawData[date][3].quantity});
                    }
                }
                break;
            case 'validationsModelsBetweenDates':
                for(let date in rawData){
                    datas.push({name: DateFormatter.frToUsFormat(date), 'Validated': rawData[date][0].validated, 'Not validated': rawData[date][0].not_validated});
                }
                break;
            case 'validModelsByModelTypesBetweenDates':
                for(let date in rawData){
                    let dataByModelType = [];
                    for(let modelType in rawData[date]){
                        dataByModelType[modelType + ' not validated'] = rawData[date][modelType]["not_validated"];
                        dataByModelType[modelType + ' validated'] = rawData[date][modelType]["validated"];
                    }
                    dataByModelType['name'] = DateFormatter.frToUsFormat(date);
                    datas.push({...dataByModelType});
                }
                break;
            case 'modelsByScoreBetweenDates':
                for(let date in rawData){
                    for(let i in rawData[date]){
                        let row = rawData[date][i];
                        datas.push({name: DateFormatter.frToUsFormat(date), 'Score 1 to 2': row.score_1_2, 'Score 2 to 3': row.score_2_3, 'Score 3 to 4': row.score_3_4});
                    }
                }
                break;
            case 'modelsByStatusBetweenDates':
                for(let date in rawData){
                    let dataByScore = [];
                    for(let i in rawData[date]){
                        let  dataForDay = rawData[date][i];
                        let statusId = this.getStatusId(i);

                        if(statusId === 'null'){
                            continue;
                        }

                        let statusName = this.getModelStatusValues(parseInt(statusId));
                        dataByScore[statusName] = dataForDay;
                    }
                    dataByScore['name'] = DateFormatter.frToUsFormat(date);
                    datas.push({...dataByScore});
                }

                break;
            default:
                return [];
        }
        return datas;
    }

    chart(){
        switch (this.props.reportingType) {
            case 'tieringBetweenDates':
                return ( <TieringBetweenDatesChart data={this.state.data}/>);
            case 'modelByMraStatusBetweenDates':
                return ( <ModelByMraStatusBetweenDatesChart data={this.state.data}/>);
            case 'validationsModelsBetweenDates':
                return ( <ValidationsModelsBetweenDatesChart data={this.state.data}/>);
            case 'validModelsByModelTypesBetweenDates':
                return ( <ValidModelsByModelTypesBetweenDatesChart data={this.state.data}/>);
            case 'modelsByScoreBetweenDates':
                return ( <ModelsByScoreBetweenDatesChart data={this.state.data}/>);
            case 'modelsByStatusBetweenDates':
                return ( <ModelsByStatusBetweenDatesChart data={this.state.data}/>);
            default:
                return <div>No Lines defined</div>;
        }
    }

    openModal(){
        Modal.open({
            title: 'Date range',
            content: <DateRangePicker onChange={this.props.onChangeDate} reportingType={this.props.reportingType} modal={Modal}/>
        })
    }

    dateRange(){
        let startDate = DateFormatter.dayIsoToString(this.state.startDate);
        let endDate = DateFormatter.dayIsoToString(this.state.endDate);

        let range = startDate + ' to ' + endDate;
        return (
            <fieldset className="display-text-field-centered" id={ this.props.id }>
                <legend className="legend-text-field">Date range</legend>
                { range }
                <Button variant="contained" color="primary" onClick={this.openModal.bind(this)} style={styles.changeRangeButton} className="save button-general">
                    Change range
                </Button>
            </fieldset>
        );
    }


    render() {
        return (
            <div className="container container-no-padding">
                <Paper style={styles.center}>
                    <AppBar position="static" className="background-lowlight background-linear-gradient">
                        <Toolbar style={styles.toolBar}>
                            <Typography variant="h6">
                                {this.props.title}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={styles.dateRangeContainer}>
                        { this.dateRange() }
                    </div>
                    <div style={styles.chart}>
                        { this.chart() }
                    </div>
                </Paper>
            </div>
        );
    }
}

const styles = {
    toolBar: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    center:{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
    },
    chart:{
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        marginTop: 20,
        height: 400,
    },
    dateRangeContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 0,
        marginBottom: 30,
        width: '30%',
        minWidth: 250,
    },
    changeRangeButton:{
        marginTop: 15,
    },
    margin: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}