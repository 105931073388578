import React from 'react';
import { DisplayTextField } from "../../../Components/Display/DisplayTextField/DisplayTextField";

class NaProvider {
  getDisplay(field, value, entity, props, resource) {
    return (
      <DisplayTextField
        {...props}
        fieldName={field.title}
        value={'NA'}
        className={'field-not-available'}
      />
    );
  }

  getDisplayList(field, value, entity, props, resource) {
    return null;
  }

  getEdit(field, value, onChange, entity, routeParams, loading = false) {
    return this.getDisplay(field, value, entity);
  }

  getFilter(options = {}) {
    return null;
  }
}

export default new NaProvider();