import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../Modal';
import { ValidationForm } from '../../../../Components/Forms/ValidationForm/ValidationForm';

export const ResourceDelete = (props) => {
    const { onDelete, item, resource, resourceName = 'items', confirmation } = props;

    const actions = [
        {
            label: "Cancel",
            onClick: () => Modal.close(),
        },
        {
            label: "Delete",
            onClick: () => onDelete(item),
        }
    ]

    let resourceLabel = resourceName;
    if(resourceName.toLowerCase().slice(-1) === 's'){
        resourceLabel = resourceName.toLowerCase().slice(0, -1);
    }
    return (
        <ValidationForm 
            description={confirmation || `Are you sure you want to delete this ${resourceLabel} ?`}
            actions={actions}
        />
    );
};
ResourceDelete.propTypes = {
    /** Nom de la resource à afficher */
    resourceName: PropTypes.string,
    /** item à supprimer */
    item: PropTypes.any,
    /** Fonction appelée pour supprimer l'item */
    onDelete: PropTypes.func,
    /** Message custom de confirmation */
    confirmation: PropTypes.string,
};
