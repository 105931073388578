import React, {Component} from 'react';
import {observer} from "mobx-react";
import AppBarStore from '../../Store/AppBarStore';
import {Header} from "../Header/Header";
import {APIResource} from "../../Services/APIResource/APIResource";
import {EntitySelectAsync} from "../Forms/EntitySelectAsync/EntitySelectAsync";
import {Box, CircularProgress} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import Http from "../../Services/Http";
import Alert from "../../Services/Alert";

class UserDepartures extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            users: [],
            assignation: []
        };

        this.usersResource = new APIResource({
            id: 'users',
            instanceId: 'users_departures',
            name: 'Users',
            icon: 'user',
            fieldForTitle: 'fullNameWithTeam',
            endpoints: {getAll: 'users/departures'}
        });
    }

    componentDidMount() {
        AppBarStore.title = 'User departures';
        this.usersResource.apiGetCollection({page: 1, rowsPerPage: -1}).then((users) => {
            this.setState({users, loading: false})
        });

    }

    hide(user){
        this.processing = true;
        this.setState({loading: true, processing: true});
        Http.post('users/departures/hide', {user: user.id}).then((res) => {
            this.setState({processing: false});
            this.usersResource.apiGetCollection({
                page: 1,
                rowsPerPage: -1,
                forceReload: true
            }).then((users) => {
                this.setState({users, loading: false, processing: false})
            }).catch(() => {
                this.setState({loading: false, processing: false});
            });
        });
    }

    genUserList() {
        let trs = [];
        for (let i in this.state.users) {
            let tds = [];
            const {companyPosition , fullName, manager, team} = this.state.users[i];
            const userManager = manager && `manager : ${manager}`
            const userInfo = [companyPosition, team , userManager].filter(Boolean).join(", ");;
            tds.push(
                <TableCell
                    key={i + '-user'}
                >
                    <div
                        className={'cell-content'}
                    >
                      { `${fullName} (${userInfo})`}
                    </div>
                </TableCell>
            );
            tds.push(
                <TableCell
                    key={i + '-role'}
                >
                    <div
                        className={'cell-content'}
                    >
                        {this.state.users[i].roles ? Object.values(this.state.users[i].roles).map(role => <p style={{marginTop:0,marginBottom:0}}>{role}</p>) : 'None'}
                    </div>
                </TableCell>
            );

            tds.push(
                <TableCell
                    key={i + '-previous'}
                >
                    <div
                        className={'cell-content'}
                    >
                        {this.state.users[i].departureScopes}
                    </div>
                </TableCell>
            );

            tds.push(
                <TableCell
                    key={i + '-next'}
                >
                    <div
                        className={'cell-content'}
                    >
                        {this.state.users[i].team}
                    </div>
                </TableCell>
            );

            tds.push(
                <TableCell
                    key={i + '-action'}
                >
                    <div
                        className={'cell-content'}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.hide(this.state.users[i])}
                        >
                            Masquer
                        </Button>
                    </div>
                </TableCell>
            );
            trs.push(
                <TableRow
                    key={i}
                >
                    {tds}
                </TableRow>
            );
        }

        return <TableBody>{trs}</TableBody>
    }

    genUserTable() {
        if(this.state.users.length === 0){
            return <Grid
                container
                className={'assignation-table-container'}
            >
                <Grid item xs={6}>
                    <Paper className="container-no-padding table-scroll no-result">
                        No users required your review.
                    </Paper>
                </Grid>
            </Grid>
        }

        return <Grid
            container
            className={'assignation-table-container'}
        >
            <Grid item xs={8}>
                <Paper className="container-no-padding table-scroll">
                    <Table
                        className={
                            'table-display small'
                        }
                        size={'small'}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell key={'th_user'}>
                                    User
                                </TableCell>
                                <TableCell key={'th_role'}>
                                    Roles
                                </TableCell>
                                <TableCell key={'th_choose'}>
                                    Previous team(s)
                                </TableCell>
                                <TableCell key={'th_choose'}>
                                    New team(s)
                                </TableCell>
                                <TableCell key={'th_action'}>
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {this.genUserList()}
                    </Table>
                </Paper>
            </Grid>
        </Grid>
    }

    render() {
        return (
            <div
                className={'userAssignation'}
            >
                <Header
                    text={'These users have changed teams, please review each of them.'}
                />
                {
                    this.state.loading ?
                        <Box textAlign="center">
                            <CircularProgress/>
                        </Box>
                        : this.genUserTable()
                }
                { !this.state.loading ?
                <div style={{textAlign: 'right'}} style={navStyles.nextButton}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={navStyles.saveButton}
                        onClick={() => this.save()}
                        disabled={this.state.processing}
                    >
                        <i className={
                            this.state.processing
                                ? 'fa fa-circle-notch text-primary fa-rotate fa-spin'
                                : 'fa fa-save'
                        }></i>
                    </Button>
                </div>
                : null}
            </div>
        )
    }
}


export default observer(UserDepartures);


const navStyles = {
    nextButton: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(255,255,255,0.75)',
        padding:3,
        borderTopLeftRadius: 5,
    },
    saveButton:{
        fontSize: 10,
        backgroundColor: '#0dbbb7',
        margin:3,
    }
};
