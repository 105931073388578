import React, { useCallback } from 'react';
import { toJS } from 'mobx/lib/mobx';
import BaseSelect from './fields/BaseSelect';
import { EntitySelectAsync } from '../../Forms/EntitySelectAsync/EntitySelectAsync';

const FILTER_BY_OPTIONS = [
    { label: 'MRM Team', value: 'MRM Team' },
    { label: 'Model validator team', value: 'Model validator team' },
];

const GROUP_BY_OPTIONS = [
    { label: 'Risk', value: 'Risk' },
    { label: 'Tier result', value: 'Tier result' },
];

const InventoriesReport = ({ parameters = { fields: [] }, setParameters }) => {
    const onInventoriesByChange = useCallback(
        (e) => {
            const inventoriesBy = e && e.value;

            if (inventoriesBy !== parameters.inventoriesBy) {
                setParameters({ ...parameters, inventoriesBy });
            }
        },
        [parameters, setParameters]
    );

    const onGroupByChange = useCallback(
        (e) => {
            const groupBy = e && e.value;

            if (groupBy !== parameters.groupBy) {
                setParameters({ ...parameters, groupBy });
            }
        },
        [parameters, setParameters]
    );

    const onTeamChange = useCallback(
        (teamPath) => {
            let scopeId;

            if (teamPath) {
                scopeId = teamPath.split('/').pop();
            }

            setParameters({ ...parameters, scopeId });
        },
        [parameters, setParameters]
    );

    return (
        <div style={{ minHeight: '600px' }}>
            <div>
                <BaseSelect
                    name="filterBy"
                    label="Filter by"
                    options={FILTER_BY_OPTIONS}
                    onChange={onInventoriesByChange}
                    value={parameters.inventoriesBy}
                />
            </div>
            <div>
                <EntitySelectAsync
                    label="Team"
                    resourceId={'scopes'}
                    resourceLabel={'title'}
                    clearable={true}
                    multi={false}
                    issueButton={false}
                    endpoints={{ getAll: 'scopes/all-scopes/all' }}
                    value={parameters.scopeId}
                    onChange={(v) => onTeamChange(v)}
                />
            </div>
            <div>
                <BaseSelect
                    name="groupBy"
                    label="Group by"
                    options={GROUP_BY_OPTIONS}
                    onChange={onGroupByChange}
                    value={parameters.groupBy}
                />
            </div>
        </div>
    );
};

export { InventoriesReport };
