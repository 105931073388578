import Http from "../Http";
import Navigation from "../Navigation";
import Alert from "../Alert";
import ParameterStore from "../../Store/ParameterStore";

export const convertToModel = (entity, resource) => {
    entity.nonModel = false;
    entity.nonModelStatus = null;
    entity.modelStatus = ParameterStore('MODEL_STATUS_DRAFT')
    resource.apiPut(entity).then((entity) => {
        Alert.show({
            message: 'This non-model has been converted to a draft model. The declarer has been notified.',
            type: "success"
        });
        Navigation.router.history.push(
            "/resource/my_draft_models/" + entity.id + '/detail'
        );
    });
};

export const confirmAsNonModel = (entity, resource) => {
    entity.nonModel = true;
    entity.nonModelStatus = ParameterStore('NON_MODEL_STATUS_NON_MODEL');
    resource.apiPut(entity).then((entity) => {
        Alert.show({
            message: 'The declarer has been notified that you confirmed this non-model as a tool.',
            type: "success"
        });
    });
};
