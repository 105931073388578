/* eslint-disable no-irregular-whitespace */
export default {
    // update page
    // 'Scoring Guide'               : 'Guide de notation',
    // detail page
    // overview panel
    // 'MRA Information'             : 'Information MRA',
    // 'Status'                      : 'Etat',
    // 'Author'                      : 'Auteur',
    // 'Synchronized MRA models'     : 'à traduire',
    // 'detach'                      : 'détacher',
    // 'MRA Scores'                  : 'Scores du MRA',
    // 'Lod1 Residual Score'         : 'à traduire',
    // 'Lod2 Residual Score'         : 'à traduire',
    // 'Committee Residual Score'    : 'à traduire',
    // 'MRA Conclusions'             : 'Conclusions du MRA',
    // 'Max Score'                   : 'Score max',
    // 'Critical Dimensions'         : 'Dimensions critiques',
    // 'Model Risk Assessment Result': 'à traduire',
    // table
    // 'Justification': 'Justification',
    // 'Subdimension': 'Sous-dimension',
    // 'LoD1 residual score': 'à traduire',

    // MRA DIMENSIONS GROUP
    'Model Input(s)': 'Entrée(s) du modèle',
    'Methodology and design': 'Conception et méthodologie',
    'On-going monitoring': 'Monitoring continu',
    'Model performance': 'Performance du modèle',
    Implementation: 'Implémentation',
    Governance: 'Gouvernance',
    // MRA SUBDIMENSIONS
    'Raw data quality assessment (upon receipt)': 'Evaluation de la qualité des données brutes (à réception)',
    'Assessment of controls and data processing (after receipt)':
        'Evaluation des contrôles et du traitement des données (après réception)',
    'Upstream models': 'Modèles en amont',
    'Scope of application and use': "Périmètre d'application et utilisation",
    'Methodology soundness': 'Pertinence de la méthodologie',
    Calibration: 'Calibrage',
    'On-going monitoring methodology': 'Méthodologie de monitoring continu',
    'Implementation of the monitoring methodology': 'Implémentation de la méthodologie de monitoring',
    'Monitoring results (at design and periodically)': 'Résultats du monitoring (à la construction et périodique)',
    'Implementation accuracy': 'Justesse d’implémentation',
    'Modeling documentation': 'Documentation de modélisation',
    'Code documentation': 'Documentation de code à la construction',
    'Monitoring documentation': 'Documentation de monitoring',
    'Data documentation': 'Documentation de données',
    'Model governance documentation': 'Documentation de gouvernance du modèle',
    'Implementation documentation': "Documentation d'implémentation",
    'Effectiveness of internal standards and model management processes':
        'Efficacité des normes internes et des processus de gestion du modèle',
};
