import React from 'react';
import {APIResource} from "../Services/APIResource/APIResource";
import {ROLE} from "../Services/User/User";

export const ApiResourceDefaultParams = {
    id: 'variables',
    name: 'Variables',
    icon: 'cog',
    fieldForTitle: 'name'
};

export default class VariableAdmin {
    constructor(){
        this.configure();
    }

    configure(){
        let variables = new APIResource(ApiResourceDefaultParams);
        variables
            .setFields({
                id: {title: 'ID'},
                name: {title: 'Name', type: 'text', required: true, width: 600},
                description: {title: 'Description', type:'textarea', width: 600},
                value: {title: 'Value'},
            })
            .setValidation((entity) => {return true;})
            .setLayout({
                tabs: {
                    'Information' : {
                        rows: [
                            {
                                panels: {
                                    'Properties': {
                                        cols: 12,
                                        fields: ['name', 'description', 'value']
                                    }
                                }
                            }
                        ]
                    },
                }
            })
            .genListView({
                fields: ['name', 'description', 'value'],
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genInsertView({
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'Variable';
                },
            })
            .genEditView({
                fields: ['name', 'description', 'value'],
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genDetailView({
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
        ;
    }
}
