import React, { Component } from 'react';
import { observer } from "mobx-react";
import APIResourceStore from "../../Store/APIResourceStore";
import FieldProviderStore from "../../Services/APIResource/FieldProviders/__FieldProviderStore";
import ParameterStore from "../../Store/ParameterStore";
import WorkflowAdmin from "../../Admin/WorkflowAdmin";
import Select from "react-select";
import { DependentFieldsSelect } from "../Parameter/DependentFieldsSelect";
import Grid from "@material-ui/core/Grid";
import { Checkbox, FormControlLabel } from '@material-ui/core';


class WorkflowCondition extends Component {

    state = {};

    constructor(props) {
        super(props);
    }


    componentDidMount() {
        this.getUsers();
    }

    handleChange(field, value) {
        let val = { ...this.props.value };
        val[field] = value;

        if (field === 'field') {
            delete val.value;
        }
        if (field === 'condition') {
            delete val.field;
            delete val.value;
        }
        this.props.onChange(val);
    }

    static getConditions(entity, availableFields) {

        let options = [];

        if (availableFields.length && entity.event !== ParameterStore('WORKFLOW_EVENT_NEW')) {
            options.push({ label: 'Old value', value: 'old' });
            options.push({ label: 'Change', value: 'change' });
        }
        if (availableFields.length && entity.event !== ParameterStore('WORKFLOW_EVENT_DELETE')) {
            options.push({ label: 'New value', value: 'new' });
        }

        options.push({ label: 'User', value: 'user' });

        return options;
    }

    static getFields(entity) {
        const resource = WorkflowConditions.getResource(entity.targetEntity);

        const fields = resource ? DependentFieldsSelect.getResourceFields(resource) : [];

        // On ne prend que ceux qui ont un type pour éviter toutes les sous-listes
        return fields.filter(f => !f.fieldId.match(/entities/i) && f.fieldId !== 'changeLogComment')
    }

    getUsers() {
        return APIResourceStore.resources.users_all.getAllItems().map(u => ({ label: u.fullName, id: u.id, value: u['@id'] }));
    }


    /**
     * Génére le champ d'édition de la valeur, de la même manière que ResourceEdit
     * @param value
     * @param field
     * @param onChange
     * @returns {*}
     */
    genField = (value, field, onChange) => {

        let editComponent = null;
        const fieldId = field.fieldId;
        field.title = "";

        if (field.params) {
            field.params.multi = true;
            field.issueButton = false;
        }

        if (!this.props.readOnly) {
            if (field.edit) {
                editComponent = field.edit(field, value, onChange);
            } else if (FieldProviderStore[field.type] && FieldProviderStore[field.type].getEdit) {
                editComponent = FieldProviderStore[field.type].getEdit(field, value, onChange);
            } else {
                editComponent = FieldProviderStore.default.getEdit(field, value, onChange);
            }
            // add null checkbox and hide component if null is checked
            editComponent = (<div>{value === null || editComponent}
                <FormControlLabel
                    control={
                        <Checkbox checked={value === null} onChange={(event, state) => {
                            if (state) {
                                onChange(null);
                                field.readOnly = true;
                            }
                            else {
                                field.readOnly = false;
                                onChange(value || undefined);
                            }
                            // this.forceUpdate()
                        }} />
                    }
                    label={<span>NULL</span>}
                />

            </div>)
        } else {
            if (field.display) {
                editComponent = field.display({ title: field.title || '-' }, value, onChange);
            } else if (FieldProviderStore[field.type] && FieldProviderStore[field.type].getDisplay) {
                editComponent = FieldProviderStore[field.type].getDisplay({ ...field, title: field.title || '-' }, value, onChange);
            } else {
                editComponent = FieldProviderStore.default.getDisplay({ ...field, title: field.title || '-' }, value, onChange);
            }
        }



        return (<div className={"edit-field"} key={fieldId}>
            {editComponent}
        </div>);
    }



    /**
     * Nettoie l'objet si ses propriétés ne sont plus valides
     *
     * Provoque des problèmes de performance… est-ce vraiment utile alors que l'interface se met à jour dtf ?…
     *
     * @param props
     * @param state
     * @returns {*}
     *
     *
    static getDerivedStateFromProps2(props, state) {
        const {value} = props;
        console.log('getDerivedStateFromProps');

        const availableFields = WorkflowCondition.getFields(props.entity);
        if(availableFields.length && value.field && !availableFields.find(f => f.fieldId == value.field)) {
            delete value.field;
            console.log('props onChange');
            props.onChange(value);
        }
        const conditions = WorkflowCondition.getConditions(props.entity, availableFields);
        if(value.condition && conditions.length && !conditions.find(f => f.value == value.condition)) {
            delete value.condition;
            console.log('props onChange');
            props.onChange(value);
        }

        return null;
    }
     */

    render() {

        const { value, readOnly, entity } = this.props;

        const availableFields = WorkflowCondition.getFields(entity);
        const options = WorkflowCondition.getConditions(entity, availableFields);
        const fields = ['old', 'new', 'change'].includes(value.condition) ? availableFields : false;
        const users = ['user'].includes(value.condition) ? this.getUsers() : false;
        const field = fields && fields.find(f => f.fieldId === value.field) || null;
        const selectedCondition = options.find(v => v.value === value.condition);
        const textStyle = readOnly ? styles.textLineHeightReadOnly : styles.textLineHeight;
        return <div className="MuiGrid-container" style={{ padding: '5px 0' }}>
            <div className="MuiGrid-grid-xs-3">
                {readOnly ? FieldProviderStore.default.getDisplay({ title: 'condition' }, selectedCondition && selectedCondition.label) : (
                    <Select
                        className="entity-select"
                        options={options}
                        isSearchable={!readOnly}
                        isClearable={!readOnly}
                        menuIsOpen={readOnly ? false : undefined}
                        placeholder={"Condition"}
                        isMulti={false}
                        onChange={selection => this.handleChange('condition', selection ? selection.value : null)}
                        value={options.find(v => v.value == value.condition) || null}
                    />)}
            </div>
            {fields &&
                <div className="MuiGrid-grid-xs-9 MuiGrid-container">
                    <div className="MuiGrid-grid-xs-1" style={textStyle}>
                        of
                    </div>
                    <div className="MuiGrid-grid-xs-4">
                        {readOnly ? FieldProviderStore.default.getDisplay({ title: 'field' }, field && field.label) : (
                            <Select
                                className="entity-select"
                                options={fields}
                                isSearchable={!readOnly}
                                isClearable={!readOnly}
                                menuIsOpen={readOnly ? false : undefined}
                                placeholder={"Field"}
                                isMulti={false}
                                onChange={selection => this.handleChange('field', selection ? selection.fieldId : null)}
                                value={field}
                            />
                        )}
                    </div>
                    {field &&
                        value.condition !== 'change' &&
                        < div className="MuiGrid-grid-xs-7 MuiGrid-container">
                            <div className="MuiGrid-grid-xs-1" style={textStyle}>
                                is
                            </div>
                            <div className="MuiGrid-grid-xs-10">
                                {readOnly && value && value.value === null ? FieldProviderStore.default.getDisplay({ title: 'value' }, 'null') :
                                    (
                                        this.genField(value.value, field.field, v => this.handleChange('value', v))
                                    )}
                            </div>
                        </div>
                    }
                </div>
            }
            {
                users &&
                <div className="MuiGrid-grid-xs-9 MuiGrid-container">
                    <div className="MuiGrid-grid-xs-1" style={textStyle}>
                        is
                    </div>
                    <div className="MuiGrid-grid-xs-4">
                        <Select
                            className="entity-select"
                            options={users}
                            isSearchable={!readOnly}
                            isClearable={!readOnly}
                            menuIsOpen={readOnly ? false : undefined}
                            placeholder={"User"}
                            isMulti={true}
                            onChange={selection => this.handleChange('value', (selection || []).map(s => s.id))}
                            value={users.filter(f => (value.value || []).includes(f.id)) || null}
                        />
                    </div>
                </div>
            }
        </div >;
    }
};

export const WorkflowConditions = observer(class WorkflowConditions extends Component {

    state = { value: [] };

    static getResource(targetEntity) {
        const typesMap = Object.fromEntries(Object.entries({
            WORKFLOW_TARGET_ENTITY_MODEL: 'models',
            WORKFLOW_TARGET_ENTITY_MODEL_USE: 'model_uses',
            WORKFLOW_TARGET_ENTITY_CERTIFICATION: 'certifications',
            WORKFLOW_TARGET_ENTITY_REVIEW: 'reviews',
            WORKFLOW_TARGET_ENTITY_ISSUE: 'issues', // TODO créer admin
            WORKFLOW_TARGET_ENTITY_DOCUMENT: 'documents', // TODO créer admin
            WORKFLOW_TARGET_ENTITY_USER: 'users',
            WORKFLOW_TARGET_ENTITY_NOTICE: 'notices',
            WORKFLOW_TARGET_ENTITY_REVIEW_COMMITTEE: 'review_committees',
            WORKFLOW_TARGET_ENTITY_MODEL_CERTIFICATION_CAMPAIGN: 'model_certification_campaigns',
            WORKFLOW_TARGET_ENTITY_SCOPE: 'scopes',
            WORKFLOW_TARGET_ENTITY_NON_MODEL: 'non_models',
            WORKFLOW_TARGET_ENTITY_LEGAL_ENTITY: 'legal_entities',
            WORKFLOW_TARGET_ENTITY_OU: 'o_us',
            WORKFLOW_TARGET_ENTITY_BL: 'b_ls',
        }).map(([k, v]) => [ParameterStore(k), APIResourceStore.resources[v]]));

        return typesMap[targetEntity];
    }

    handleChange(k, j, value) {
        let val = this.state.value;
        val[k] = val[k] || [];
        val[k][j] = value;

        // Clean the object from empty lines
        val = val
            // On ne garde que ceux qui ont une condition
            .map(v => v.filter(i => i.condition))
            // On supprime les lignes entièrement vides
            .filter(v => v.length);

        this.props.onChange(val);
    }


    /**
     * Nettoie l'objet si ses propriétés ne sont plus valides
     * @param props
     * @param state
     * @returns {*}
     */
    static getDerivedStateFromProps(props, state) {
        const { readOnly } = props;
        let value = JSON.parse(JSON.stringify(props.value || [[{}]]));

        if (!value || !value.length) {
            value = [[{}]];
        }

        if (!readOnly) {
            // Ajouter la prochaine clause OR si le dernier fieldId est rempli
            const last = value[value.length - 1];
            if (last && last[last.length - 1] && last[last.length - 1].condition) {
                value.push([{}]);
            }

            // Ajouter la prochaine clause ET pour chaque block si le dernier fieldId est rempli
            value.map(val => {
                const last = val[val.length - 1];
                if (last && last.condition) {
                    val.push({});
                }
            });
        }


        if (JSON.stringify(state.value) == JSON.stringify(value)) {
            return null;
        }

        return { value };
    }


    render() {
        const { entity, readOnly } = this.props;
        let { value } = this.state;

        return <div className="dependent-fields-editor">
            {
                value.map((conditions, k) =>
                    <div className="MuiGrid-container" key={k}>
                        <div className="MuiGrid-grid-xs-1 operator">
                            {k > 0 ? "OR" : ""}
                        </div>
                        <div className="MuiGrid-grid-xs-11">
                            {
                                conditions.map((val, j) =>
                                    <div className="MuiGrid-container" key={j}>
                                        {j > 0 &&
                                            <div className="MuiGrid-grid-xs-1 operator">
                                                AND
                                            </div>}
                                        <div className={"MuiGrid-grid-xs-" + (11 + !(j > 0))}>
                                            <WorkflowCondition value={val} entity={entity}
                                                readOnly={readOnly}
                                                onChange={v => this.handleChange(k, j, v)} />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>;
    }
});


const styles = {
    textLineHeight: {
        lineHeight: '38px',
        textAlign: 'center'
    },
    textLineHeightReadOnly: {
        lineHeight: 4,
        textAlign: 'center'
    }
}
