import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));

const RelationLinks = (props) => {
    const { withoutIcon, ...otherProps } = props;
    const { links, tooltip, style, withoutTooltip, label } = props;

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    function renderItem() {
        let render = [];
        links.forEach((item, key) => {
            if (item.link && item.title) {
                render.push(
                    <MenuItem key={'additional-action-button-' + key}>
                        <Link to={item.link} className="item">
                            {item.title}
                        </Link>
                    </MenuItem>
                );
            }
        });
        return render;
    }

    return (
        <>
            <Button
                ref={anchorRef}
                aria-haspopup="true"
                onClick={handleToggle}
                variant="contained"
                style={style}
                className={'tooltip '}
            >
                {!withoutIcon ? <i className={'fa fa-link'}></i> : null}
                {label ?? null}
                {!open && !withoutTooltip ? <span className="tooltiptext">{tooltip}</span> : null}
            </Button>
            <Popper
                {...otherProps}
                open={open}
                anchorEl={anchorRef.current}
                role={'navigation'}
                transition
                disablePortal
                className={'relation-link-popper'}
            >
                {({ TransitionProps }) => {
                    return (
                        <Grow {...TransitionProps} style={{ transformOrigin: 'center bottom' }}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        {renderItem()}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    );
                }}
            </Popper>
        </>
    );
};
RelationLinks.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            link: PropTypes.string,
        })
    ),
    tooltip: PropTypes.string,
    label: PropTypes.string,
    /** Objet de styles pour le bouton */
    style: PropTypes.object,
    withoutIcon: PropTypes.bool,
    withoutTooltip: PropTypes.bool,
};

export default RelationLinks;
