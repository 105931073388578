import React from 'react';
// import { toJS } from 'mobx/lib/mobx';
import { EntitySelectAsync } from '../../../Forms/EntitySelectAsync/EntitySelectAsync';

function ScopeInput({ value, label, onChange }) {

    return (
        <div style={{ position: 'relative', height: '500px' }}>
            <EntitySelectAsync
                label={label || 'Scope'}
                resourceId={'scopes'}
                resourceLabel={'title'}
                clearable={true}
                multi={true}
                issueButton={false}
                endpoints={{ getAll: 'scopes/all-scopes/all' }}
                value={value}
                onChange={(v) => onChange(v)}
            />
        </div>
    );
}

export default ScopeInput;
