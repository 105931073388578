import React, { Component } from "react";
import Modal from "../../../Services/Modal";
import Alert from "../../../Services/Alert";
import Navigation from "../../../Services/Navigation";
import { Grid, Typography} from "@material-ui/core";
import ParameterStore from "../../../Store/ParameterStore";
import { EntityForm } from "../../Forms/EntityForm/EntityForm";
import { ActionButton } from "../../Modal/ActionButton";
import { ButtonBar } from "../../Modal/ButtonBar";
import { ModalContent } from "../../Modal/ModalContent";
import APIResourceStore from "../../../Store/APIResourceStore";
import {DOCUMENT_ACTION_DELETE, DOCUMENT_ACTION_SHOW} from "../../Display/DocumentManager/DocumentManager";

const nonModelRejectFields = {
    documentsEntities: {
        title: "Please attach documents to justify the conversion if necessary.",
        type: "documents",
        params: {
            entityResource: "models",
            propertyName: "documentsEntities",
            fieldName: "Document",
            allowedCategory: false,
            links: false,
            allowedAction: (entity, document, action) =>
                [DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_DELETE].includes(action),
        },
    },
    nonModelComment: {
        title: "Justification of the conversion to non-model",
        type: "textarea",
        required: true
    },
}

export class NonModelRejectForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: false,
            entity: {
                nonModelComment: null,
                documentsEntities: null,
                ...this.props.entity,
            }
        };
    }

    reject(resource) {
        if (!this.state.entity.nonModelComment) {
            Alert.show({
                message: "The justification is required before conversion.",
                type: "error"
            });
            return;
        }
        
        this.setState({progress: true});

        const entity = {
            ...this.state.entity,
            nonModel: true,
            nonModelStatus: ParameterStore('NON_MODEL_STATUS_NON_MODEL'),
        };
        resource.apiPut(entity).then((entity) => {
            Navigation.router.history.push(
                `/resource/non_models/${entity.id}/detail`
            );
            Alert.show({ message: `The model ${entity.functionalID} has been added to the non-models list.`, type: "success" });
            resource.apiGetCollection({
                page: 1,
                rowsPerPage: 10000,
                forceReload: true
            });
            // On le pousse dans toutes les ressources "models"
            Object.values(APIResourceStore.resources).map(resource => resource.resourceId === 'models' && resource.setItem(entity));
            Modal.close();
            this.setState({progress: false});
        }).catch(err => {
            console.error(err);
            Modal.close();
            this.setState({progress: false});
        });
    }


    getTitle = () => {
        if (this.props.entity) {
            return `Do you confirm you want to convert the model ${this.props.entity.functionalID} to non-model ? It will be removed from the inventory.`;
        }
    };

    handleRejectClick() {
        const { entity, resource } = this.props;
        if (entity) {
            return this.reject(resource);
        }
    }

    updateState = (callback) => {
        this.setState((p) => {
            const newEntity = callback(p.entity);
            return {entity: newEntity}
        })
    }

    render() {
        return (
            <ModalContent className={'non-model-reject-form'}>
                <Grid container justify="center" spacing={2} className="container resource-edit">
                    <Grid item xs={12}>
                        <Typography>{this.getTitle()}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <EntityForm
                            entity={this.state.entity}
                            onUpdate={this.updateState}
                            fields={nonModelRejectFields}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonBar>
                            <ActionButton onClick={Modal.close} disabled={this.state.progress}>
                                Cancel
                            </ActionButton>
                            <ActionButton onClick={() => this.handleRejectClick()} loading={this.state.progress}>
                                Convert
                            </ActionButton>
                        </ButtonBar>
                    </Grid>
                </Grid>
            </ModalContent>
        );
    }
}