import RouteStore, {Route} from "../../Store/RouteStore";
import React from "react";
import UserAssignation from "./UserAssignation";
import UserDepartures from "./UserDepartures";

RouteStore.routes.resource_users_assignation = new Route({
    path: '/resource/users/assignation',
    component: (props) => <UserAssignation {...props}/>,
    menuItem: {
        title: 'User assignation',
        icon: 'brain'
    },
});

RouteStore.routes.resource_users_departures = new Route({
    path: '/resource/users/departures',
    component: (props) => <UserDepartures {...props}/>,
    menuItem: {
        title: 'User departures',
        icon: 'brain'
    },
});