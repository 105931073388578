import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import String from "../../Services/String";

export class HelperButton extends Component {

    constructor(props) {
        super(props);
        this.buttons = [];
    }

    renderHelpers(helper = null){
        let helperText = '';
        let type = '';
        let icon = 'fa-info';
        let key = Math.floor(Math.random() * 1000 );

        if(helper === null){
            helper = this.props.helperText;
        }

        if (typeof helper === 'string') {
            if(this.props.hideInformation){
                return null;
            }
            helperText = String.nlToBr(helper);
        } else if (typeof helper === 'function'){
            if(helper(this.props.entity)){
                return this.renderHelpers(helper(this.props.entity));
            }else{
                return null;
            }
        } else if(Array.isArray(helper)){
            let buttons = [];
            helper.forEach((button) => {
                buttons.push(this.renderHelpers(button));
            })
            return buttons;
        } else if (typeof helper === 'object') {
            helperText = helper.text ? String.nlToBr(helper.text) : '';
            type = helper.type ?? type;
            icon = helper.icon ?? icon;
            key = helper.key ?? key;
        }

        if(helperText === ''){
            return null;
        }

        return (
            <Button className={"helper-button tooltip " + (this.props.context === 'list' ? ' tooltip-right ' : '') + type} key={"helper-button-" + key} title='' variant="contained" disableRipple>
                <Icon className={"fa " + icon}></Icon>
                <span className="tooltiptext">{helperText}</span>
            </Button>
        );
    }

    render() {

        return (
            <React.Fragment>
                {this.renderHelpers()}
            </React.Fragment>
        );
    }
}
HelperButton.propTypes = {
    /** string, function(entity), array or object {text, type, icon} ! */
    helperText: PropTypes.any,
    entity: PropTypes.object,
}