import React, { useMemo } from 'react';

import Select from '../../../Forms/Select/Select';

function BaseSelect({ name, label, options, onChange, required, value, formatOptionLabel, isMulti = false }) {
    const currentValue = useMemo(() => {
        if (value) {
            const found = options.find((o) => {
                if (o.equals && typeof o.equals === 'function') {
                    return o.equals(o.value, value);
                }
                return o.value === value;
            });
            if (found) return found;
        } else {
            if (options) {
                onChange(options[0]);
            }
        }
        return options && options[0];
    }, [options, value]);

    return (
        <Select
            name={name}
            label={label}
            required={required}
            options={options}
            isMulti={isMulti}
            onChange={onChange}
            value={currentValue}
            formatOptionLabel={formatOptionLabel}

            /* defaultValue={options[0]} */
            // defaultInputValue={field.defaultValue}
        ></Select>
    );
}

export default BaseSelect;
