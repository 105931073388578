import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ModalContent} from '../../Modal/ModalContent';
import {TableDisplay} from '../../Display/TableDisplay/TableDisplay';
import {Checkbox, IconButton, Typography} from '@material-ui/core';
import {ButtonBar} from '../../Modal/ButtonBar';
import {ActionButton} from '../../Modal/ActionButton';
import Http from '../../../Services/Http';
import APIResourceStore from '../../../Store/APIResourceStore';
import {ModalLoadingContent} from '../../Modal/ModalLoadingContent';
import Alert from '../../../Services/Alert';

/**
 * Message : Please select the notice(s) leading to a one-notch downgrade. It will be applied to all future MRAs.

 Dans le tableau on affiche l'ID de la notice, le title, la description, la sévérité.

 On permet à l'utilisateur de cocher/décocher les notices.

 // http://localhost:3000/resource/mras/4197/update
 // on a set IND à la notice : 3539
 Si la notice est cochée et que l'utilisateur confirme, le champ MRA 1-notch downgrade de la notice mis en place dans MRA : 1-notch downgrade de la note systématisé depuis une notice passe à yes.

 * @param {*} props
 * @returns
 */
export const PropagateOneNotchDowngradeToNoticeModal = (props) => {
    const {callback, mraScore} = props;

    const [notices, setNotices] = useState([]);
    const [selected, setSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);

    const loadNotices = async () => {
        setLoading(true);
        const resource = APIResourceStore.resources.notices.clone('notice_related_to_mrascore');
        const response = await resource.apiGetCollection({
            filters: {linkedMraScore: mraScore['@id']},
            rowsPerPage: 10000,
            forceReload: true,
        });
        setSelected([
            ...selected,
            ...response.filter((notice) => notice.oneNotchDowngrade).map((notice) => notice.id),
        ]);
        setNotices(response);
        setLoading(false);
    };
    useEffect(() => {
        loadNotices();
        return () => {
            APIResourceStore.resources.notice_related_to_mrascore.currentRequestList.cancel('NoAlert');
        };
    }, []);

    const toggleSelected = (noticeId) => {
        if (selected.includes(noticeId)) {
            setSelected(selected.filter((id) => id !== noticeId));
        } else {
            setSelected([...selected, noticeId]);
        }
    };

    const propagateToNotices = async () => {
        setUpdating(true);
        await Promise.all(
            selected.map((noticeId) =>
                Http.put(`notices/${noticeId}`, {
                    oneNotchDowngrade: true,
                })
            )
        );
        Alert.show({
            message: 'Notice(s) updated',
            type: 'success',
        });
        callback();
    };

    return (
        <ModalContent>
            <Typography component={'p'} variant="body1">
                Please select the notice(s) leading to a one-notch downgrade. It will be applied to all future MRAs.
            </Typography>
            {loading && <ModalLoadingContent/>}
            {!loading && (
                <TableDisplay
                    rows={notices}
                    cols={[
                        {
                            label: '',
                            field: 'oneNotchDowngrade',
                            type: 'bool',
                            width: '90',
                            forceDisplay: true,
                            noTooltip: true,
                            display: (field, value, notice, _props) => {
                                return (
                                    <IconButton onClick={() => toggleSelected(notice.id)}>
                                        <Checkbox className="text-success" checked={selected.includes(notice.id)}/>
                                    </IconButton>
                                );
                            },
                        },
                        {
                            label: 'Id',
                            field: 'id',
                            // Décommenter pour avoir plus d'informations sur la notice en question
                            /* display: (field, value, notice, _props) => (
                                    <OpenModal
                                        instanceId={'notices'}
                                        id={notice.id}
                                        context={CONTEXT_DETAIL}
                                        modalTitle={value}
                                        flat={true}
                                        allowStayInModal={true}
                                        newLayer={true}
                                    />
                                )
                            },
                            */
                        },
                        {
                            label: 'Title',
                            field: 'title',
                        },
                        {
                            label: 'Description',
                            field: 'noticeDescription',
                        },
                        {
                            label: 'Severity',
                            field: 'noticeSeverity',
                            type: 'parameter',
                        },
                    ]}
                />
            )}
            <ButtonBar>
                <ActionButton loading={updating} onClick={propagateToNotices}>
                    Confirm
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
};
PropagateOneNotchDowngradeToNoticeModal.propTypes = {
    mraScore: PropTypes.shape({
        '@id': PropTypes.string,
    }),
    callback: PropTypes.func,
};
