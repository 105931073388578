import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Icon, InputBase } from '@material-ui/core';

export const Search = (props) => {
    const { noFilter } = props;

    const [value, setValue] = useState('');
    const history = useHistory();

    const onChange = (e) => {
        setValue(e.target.value);
    };

    const onKeyDown = (e) => {
        // touche entrée :
        if (e.keyCode === 13) {
            history.push({
                pathname: '/search',
                search: `?value=${value}`,
                state: {
                    noFilter,
                },
            });
        }
    };

    return (
        <div className="menu-search">
            <div className="menu-search-icon-wrapper">
                <Icon className="fa fa-search" />
            </div>
            <InputBase
                className="menu-search-input"
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={value}
                placeholder="Search... (then press Enter)"
                inputProps={{ 'aria-label': 'search' }}
            />
        </div>
    );
};
Search.propTypes = {
    noFilter: PropTypes.bool,
};
