import React, { useState, useCallback } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { useEffect } from 'react';

import { PRESET_WIDGETS } from './Presets';

const PresetReport = ({ setPresets }) => {
    const [choices, setChoices] = useState(
        PRESET_WIDGETS.reduce((choices, widget) => [...choices, widget.reportId], [])
    );

    useEffect(() => {
        setPresets(choices);
    }, [choices]);

    const toggleCheckBox = useCallback(
        (reportId) => {
            if (choices.includes(reportId)) {
                setChoices(choices.filter((c) => c !== reportId));
            } else {
                setChoices([...choices, reportId]);
            }
        },
        [choices]
    );

    return (
        <div>
            <div>Choose which chart you want to create:</div>
            <div>
                {PRESET_WIDGETS.map((widget) => (
                    <label>
                        {widget.title}
                        <Checkbox
                            checked={choices.includes(widget.reportId) || false}
                            onChange={() => toggleCheckBox(widget.reportId)}
                        />
                    </label>
                ))}
            </div>
        </div>
    );
};

export { PresetReport };
