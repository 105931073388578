import { decorate, observable } from 'mobx/lib/mobx';
class Navigation {
    router;
    id;
}

decorate(Navigation, {
    id: observable,
});

export default new Navigation();