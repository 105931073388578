class Config {
    constructor() {
        this.env = 'prod';
        this.backend = 'https://api.mrmsystem.groupebpce.com';
        this.kms = 'https://kms.mrm.bpce.audience-pro.com/';
        this.api = this.backend + '/api';
        this.notificationRefreshInterval = 60000 // ms
        this.threadRefreshInterval = 20000 // ms
    }
}

export default new Config();