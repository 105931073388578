import React from "react";
import { DisplayTextField } from "../../../Components/Display/DisplayTextField/DisplayTextField";
import TextField from "@material-ui/core/TextField";
import {CONTEXT_LIST} from "../APIResource";

class IntProvider {
  getDisplay(field, value, entity, props, resource, context) {
    return (
      <DisplayTextField {...props} fieldName={field.title} value={value} context={context} />
    );
  }

  getDisplayList(field, value, entity, props, resource) {
    return this.getDisplay(field, value, entity, props, resource, CONTEXT_LIST);
  }

  getEdit(field, value, onChange, entity, routeParams, loading = false) {
    return (
      <TextField
        label={field.title}
        type="number"
        onChange={event => {
          onChange(parseInt(event.target.value));
        }}
        value={isNaN(value) || value === null || typeof value === undefined ? '' : value}
        required={field.requiredComputed}
        error={!!field.errorHelperText}
        className={field.errorHelperText ? "field-error-control" : ""}
        helperText={field.errorHelperText}
        //disabled={loading}
      />
    );
  }

  getFilter(options  = {}) {
    const { field, value, onChange } = options;
    return (
      <TextField
        id={"filter-" + field.id}
        placeholder="Filter"
        onChange={event => {
          onChange(event.target.value);
        }}
        className="input-text-field-table"
        value={value ? value : ""}
      />
    );
  }
}

export default new IntProvider();
