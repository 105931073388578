import Http from '../../Http';
import Alert from "../../Alert";
import React, {Component} from "react";
import Modal from "../../Modal";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {DatePicker} from "../../../Components/Forms/DatePicker/DatePicker";
import Grid from "@material-ui/core/Grid";
import {userHasRoleMRM, userIsVal} from "../../../Store/ParameterStore";
import User from "../../User/User";

export class ReviewAlert {
    getId(){
        return 'reviewAlert'
    }

    getLabel(){
        return 'Request a date or a confirmation'
    }

    setParameters(params){
        this.parameters = params;
    }

    run(ids, params = null){
        this.ids = ids;

        Modal.open({
            title: 'Planned documentation delivery date',
            content: <ReviewAlertForm ids={this.ids}/>
        });
    }
}


export class ReviewAlertForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            progress: false,
            date: null
        };
    }

    componentDidMount() {
        this.setState({date: new Date()});
    }

    request(){
        this.setState({progress: true});
        let requestedBy = 'Lod1';
        if(User.profile.isMemberOfValidatorTeam ||  userHasRoleMRM()){
            requestedBy = 'Lod2';
        }
        Http.post('reviews/alert', {
            ids: this.props.ids,
            requestedBy: requestedBy
        }).then((response) => {
            if(response.status === 'ERROR'){
                Alert.show({ message : response.message, type: 'error'});
            }else if(response.status === 'OK'){
                if(response.message){
                    Alert.show({ message : response.message, type: 'success'});
                }
            }
            Modal.close();
            this.setState({progress: false});
        });
    }

    render() {
        return (
            <Grid className={'review_alert container'}>
                { this.state.progress ?
                    <div className={"review_alert_progress_container"}>
                        <CircularProgress />
                        <p>Please wait....</p>
                    </div>
                    :
                    <div>
                        <Button style={{marginTop: 10}} variant="contained" color="secondary" className="save button-general" onClick={this.request.bind(this)}>
                            Request a date or a confirmation
                        </Button>
                    </div>
                }
            </Grid>
        )
    }
}