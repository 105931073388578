import {APIResource} from '../../../Services/APIResource/APIResource';
import _ from 'lodash';
import {differenceInMinutes} from 'date-fns';
import User from '../../../Services/User/User';
import Config from '../../../Config/App';
import React, {Component} from 'react';

import {
    Button,
    CircularProgress,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
} from '@material-ui/core';
import Icon from "@material-ui/core/Icon";
import Modal from "../../../Services/Modal";
import Alert from "../../../Services/Alert";
import FormControl from "@material-ui/core/FormControl";
import Thread from "../Thread/Thread";
import Select from "react-select";
import Environment from "../../../Services/Environment";

export class Topic extends Component {
    handle = null;

    /**
     * props : {
     *    entityType,
     *    entityId,
     * }
     **/

    constructor(props) {
        super(props);
        this.state = {
            topics: [],
            isLoadingTopics: false,
            isLoadingFollowers: false,
            selectedTopic: null,
            followers: [],
            followersSelection: []
        };
        this.renderList.bind(this);
        this.pauseRefresh = false;
        this.listRef = React.createRef();

        this.topicsResource = new APIResource({
            id: 'topics',
        });

        this.followersResource = new APIResource({
            id: 'users',
            instanceId: 'users-topic-followers',
            endpoints: {getAll: 'users/topic/' + this.props.entityType + '/' + this.props.entityId},
        });
    }

    componentDidMount() {
        this.getTopics({forceReload: true});
        this.handle = setInterval(() => {
            if (this.pauseRefresh) {
                return;
            }
            if (
                User.lastActivity &&
                differenceInMinutes(new Date(), User.lastActivity) > 20
            ) {
                return;
            }

            this.getTopics({forceReload: true});
        }, Config.threadRefreshInterval || 5000);
        this.getUsers();
    }

    componentWillUnmount() {
        clearInterval(this.handle);
    }

    getUsers = (options = {}) => {
        options.rowsPerPage = -1;
        options.forceReload = true;

        this.setState({isLoadingFollowers: true});
        return this.followersResource
            .apiGetCollection(options)
            .then((followers) => {
                if (!followers) {
                    return;
                }
                if (this.props.followersFilter) {
                    let filteredFollowers = [];
                    followers.forEach((follower) => {
                        if (this.props.followersFilter(follower, this.props.entity)) {
                            filteredFollowers.push(follower);
                        }
                    });
                    this.setState({followers: filteredFollowers});
                } else {
                    this.setState({followers});
                }
                return followers;
            }).finally(() => {
                this.setState({isLoadingFollowers: false});
            });
    }

    getTopics = (options = {forceReload: true}) => {
        const {entityType, entityId} = this.props;
        this.pauseRefresh = true;
        options.rowsPerPage = 1000;
        options.filters = {
            [_.camelCase(entityType)]: `/api/${entityType}s/${entityId}`,
        };
        this.setState({isLoadingTopics: true});
        return this.topicsResource
            .apiGetCollection(options)
            .then((topics) => {
                if (!topics) {
                    return;
                }
                let filteredTopics = [];
                topics.forEach((topic) => {
                    if (
                        this.props.showCondition
                        && this.props.showCondition(topic) === true
                    ) {
                        filteredTopics.push(topic);
                    } else {//No showCondition or showCondition = FALSE => Only for followers
                        let amIFollower = false;
                        topic.followers.forEach((follower) => {
                            if (follower === '/api/users/' + User.getId()) {
                                amIFollower = true;
                            }
                        });
                        if (amIFollower === true) {
                            filteredTopics.push(topic);
                        }
                    }
                });

                this.setState({topics: filteredTopics});
                return topics;
            })
            .finally(() => {
                let selectedTopic = null;
                let forceSelectedTopic = null;
                this.state.topics.forEach((item) => {
                    if (this.state.selectedTopic === null) {
                        if (item.id === parseInt(Environment.getUriParameter('topic'))) {
                            selectedTopic = item;
                        }
                    } else {
                        if (this.state.selectedTopic.id === item.id) {
                            forceSelectedTopic = item;
                        }
                    }
                });
                selectedTopic = forceSelectedTopic ?? (this.state.selectedTopic ?? (selectedTopic ?? (this.state.topics ? this.state.topics[0] : null)));
                this.selectTopic(selectedTopic);
                this.pauseRefresh = false;
            });
    };

    onSave(topic, callback = null) {
        const {entityType, entityId} = this.props;
        let topicEntity = {
            name: topic.name,
        };
        topicEntity[_.camelCase(entityType)] = `/api/${entityType}s/${entityId}`;

        this.setState({isLoadingTopics: true});
        this.topicsResource.apiPost(topicEntity).then((topic) => {
            this.setState({isLoadingTopics: false, selectedTopic: topic});
            this.getTopics({forceReload: true});
            Modal.close();
            if (callback) {
                callback();
            }
        }).catch(() => {
            if (callback) {
                callback(true);
            }
        });
    }

    addTopic() {
        Modal.open({
            title: 'New topic',
            content: <TopicAddForm
                onSave={(topic, callback) => this.onSave(topic, callback)}
            />
        });
    }

    updateFollowers(followers, topic = null) {
        this.setState({followersSelection: followers});
        topic = topic !== null ? topic : this.state.selectedTopic;
        topic['followers'] = !followers ? [] : followers.map(item => item.value);
        delete topic.messages;//Ne pas mettre à jour les messages lors de la mise à jour des followers
        this.topicsResource.apiPut(topic).then((topic) => {
            this.setState({selectedTopic: topic, followersSelection: followers});
            if (this.props.onFollowersUpdate) {
                this.props.onFollowersUpdate(topic);
            }
        });
    }

    genFollowersItems() {
        let items = [];
        items = this.state.followers
            .map(item => ({
                id: item.id,
                value: item["@id"],
                label: item['fullName']
            }));
        return items;
    }

    selectTopic(topic) {
        let items = [];
        let promises = [];

        if (topic && topic.followers) {
            topic.followers.map(item => {
                let promise = this.followersResource
                    .getItemFromResourcePath(item)
                    .then((follower) => {
                        items.push({
                            id: follower.id,
                            value: follower["@id"],
                            label: follower['fullName']
                        });
                    });
                promises.push(promise);
            });
            Promise.all(promises).then(() => {
                this.setState({
                    isLoadingTopics: false,
                    isLoadingFollowers: false,
                    selectedTopic: topic,
                    followersSelection: items
                });
            });
        }
        this.setState({isLoadingTopics: false, isLoadingFollowers: false});
    }

    renderItem(index) {
        const topic = this.state.topics[index];

        return (
            <li
                className={this.state.selectedTopic && this.state.selectedTopic.id === topic.id ? 'selected' : ''}
                key={`topic-${index}`}
            >
                <Button
                    onClick={() => this.selectTopic(topic)}
                >
                    {topic.name}
                </Button>
            </li>
        )
    }

    renderList(topics) {
        if (!topics) {
            return;
        }
        if (topics.length < 1) {
            return (
                <React.Fragment key={-1}>
                    <ListItem
                        alignItems="flex-start"
                        style={{textAlign: 'center', 'fontStyle': 'italic'}}
                        selected={false}
                    >
                        <ListItemText
                            primary={
                                <Typography
                                    component="div"
                                    variant="caption"
                                    color="textPrimary"
                                >
                                    There no topics in this chat yet.
                                </Typography>
                            }
                        />
                    </ListItem>
                </React.Fragment>
            );
        }
        return topics.map((message, index) =>
            this.renderItem(index)
        );
    }

    render() {
        const {
            topics,
            isLoadingTopics,
            isLoadingFollowers
        } = this.state;
        return (
            <div style={styles.topicsBlock}>
                {
                    this.props.displayAddButton && this.props.displayAddButton() ?
                        <Button
                            key={'topic_button_add'}
                            style={{zIndex: 0}}
                            variant="contained"
                            color="primary"
                            className="button-linear"
                            onClick={this.addTopic.bind(this)}
                        >
                            <Icon
                                className={'fa fa-plus'}
                            ></Icon>
                            Add a topic
                        </Button>
                        : null
                }
                <div id="topics-list" style={styles.list}>
                    <div style={{height: 10}}>
                        {(isLoadingTopics || isLoadingFollowers) && <LinearProgress/>}
                    </div>
                    {
                        this.state.topics.length < 1
                            ?
                            <List ref={this.listRef}>
                                {this.renderList(topics)}
                            </List>
                            :
                            <ul ref={this.listRef} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                padding: 0,
                            }}>
                                {this.renderList(topics)}
                            </ul>
                    }
                </div>
                {
                    this.state.selectedTopic !== null
                        ?
                        <div>
                            <Thread
                                topic={this.state.selectedTopic}
                            />
                            <div className={"select-component"}>
                                <label>
                                    Followers
                                </label>
                                <div>
                                    <Select
                                        options={this.genFollowersItems()}
                                        isLoading={this.state.isLoadingFollowers}
                                        isSearchable={true}
                                        isClearable={true}
                                        placeholder={'Followers'}
                                        isMulti={true}
                                        label={'Followers'}
                                        required={false}
                                        onChange={(followers) => this.updateFollowers(followers)}
                                        value={this.state.followersSelection}
                                        className={"entity-select "}
                                    />
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        );
    }
}


export class TopicAddForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            isSaving: false
        };
    }

    save() {
        if (
            this.state.name === null
            || this.state.name === ""
        ) {
            Alert.show({message: 'Please fill the name', type: 'error'});
            return;
        }
        this.setState({isSaving: true});

        this.props.onSave({
            name: this.state.name
        }, () => {
            this.setState({isSaving: false});
        });
    }

    render() {
        return (
            <div className="topic-modal-add">
                <div className="fields">
                    <FormControl>
                        <TextField label="Name" onChange={(event) => this.setState({name: event.target.value})}
                                   value={this.state.name ? this.state.name : ''}/>
                    </FormControl>
                </div>
                {
                    this.state.isSaving === true
                        ?
                        <Button
                            style={{marginTop: 10}}
                            variant="contained"
                            color="secondary"
                            className="save button-general"
                        >
                            <CircularProgress size={20}/>
                        </Button>
                        :
                        <Button variant="contained" color="secondary" className="save button-general"
                                onClick={this.save.bind(this)}>
                            Save
                        </Button>
                }

            </div>
        );
    }
}

const styles = {
    deleteButton: {color: '#fff'},
    topicsBlock: {
        display: 'inline',
    },
    list: {
        minWidth: 500,
        marginTop: 14,
        backgroundColor: '#fff',
        overflow: 'auto',
        position: 'relative',
    },
    inputSection: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 'calc(100% - 50px)',
    },
    input: {
        margin: 1,
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
};

export default Topic;
