import React from 'react';
import Alert from '../../Alert';
import Modal from '../../Modal';
import { LogicalDeleteForm } from '../../../Components/Forms/LogicalDeleteForm/LogicalDeleteForm';
import { DeleteForm } from '../../../Components/Forms/DeleteForm/DeleteForm';
import { openLoadingModal, validateIds } from '../bulkUtils';

export class BulkDelete {
    getId() {
        return 'modelDelete';
    }

    getLabel() {
        return 'Delete';
    }

    /**
     * 
     * @param {import('../../APIResource/APIResource').APIResourceBulkParams} params
     */
    setParameters(params) {
        this.parameters = params;
    }

    async run(ids, params = null) {
        this.ids = ids;

        const softDelete = this.parameters.softDelete === false ? false : true;
        const errorMessage =
            this.parameters.forbiddenAccessMessage || "You don't have the rights to delete all this models.";

        openLoadingModal();

        if (
            !(await validateIds(
                ids,
                this.parameters.itemAccessCondition,
                this.parameters.resource,
                this.parameters.itemAccessConditionEntityFields,
                this.parameters.bulkValidateRoles
            ))
        ) {
            Modal.close();
            Alert.show({ message: errorMessage, type: 'warning' });
            return;
        }

        Modal.open({
            title: 'Deletion confirmation',
            content: softDelete ? (
                <LogicalDeleteForm
                    ids={this.ids}
                    resource={this.parameters.resource}
                    entityType={this.parameters.entityType}
                    entityTypeLabel={this.parameters.entityTypeLabel}
                />
            ) : (
                <DeleteForm
                    ids={this.ids}
                    resource={this.parameters.resource}
                    entityType={this.parameters.entityType}
                    entityTypeLabel={this.parameters.entityTypeLabel}
                />
            ),
        });
    }
}
