import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFormatter from '../../../Services/DateFormatter';
import { UTCUtils } from '../../../Services/APIResource/Utils/UTCUtils';

export class DatePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: null,
            onChangeValue: null, // Value envoyée au onChange
        };
    }
 
    componentDidMount() {
        if (this.props.value) {
            this.updateValue(this.props.value, this.props.value);
        }
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (this.props.value && this.props.value !== prevProps.value) {
            this.updateValue(this.props.value, this.props.value);
        }
    }

    updateValue(value, onChangeValue) {
        this.setState({ value, onChangeValue });
    }

    render() {
        return (
            <MuiPickersUtilsProvider utils={UTCUtils}>
                <KeyboardDatePicker
                    id="date-picker-dialog"
                    {...this.props}
                    label={this.props.label ? this.props.label : null}
                    format="dd/MM/yyyy"
                    placeholder="DD/MM/YYYY"
                    value={this.state.value}
                    required={this.props.required}
                    onChange={(value) => {
                        // ATTENTION on supprime le "Time" donc notre composant n'enverra plus l'heure etc.
                        let date = DateFormatter.onlyDateInUTC(value);
                        if (!(date instanceof Date && !isNaN(date))) {
                            date = null;
                        }
                        this.updateValue(value, date);
                        // Le onChange provoque un rerender de toute la page, pas juste du composant de Date
                        // donc on l'appelle avec parcimonie.
                        if (this.props.onChange && (
                            (date !== null)) || (date === null && this.props.value)
                        ) {
                            this.props.onChange(date);
                        }
                    }}
                    className={this.props.className}
                />
            </MuiPickersUtilsProvider>
        );
    }
}
DatePicker.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    required: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    /** Overrides the TextField helperText or error */
    errorHelperText: PropTypes.string,
    ...KeyboardDatePicker.propTypes,
};
