import React from 'react';
import { DisplayTextField } from "../../../Components/Display/DisplayTextField/DisplayTextField";
import { Select } from "../../../Components/Forms/Select/Select";

export let boolMap = {
  'false': 'No',
  'true': 'Yes'
};

class BoolProvider {
  getDisplay(field, value, entity, props, resource) {
    return (
      <DisplayTextField
        {...props}
        fieldName={field.title}
        value={value !== "" && value !== null ? (field.params && field.params.mapping && field.params.mapping[value] ? field.params.mapping[value] : boolMap[value]) : ""}
      />
    );
  }

  getDisplayList(field, value, entity, props, resource) {
    if (value === "true" || value === true) {
      return field.params && field.params.mapping && field.params.mapping['true'] ? field.params.mapping['true'] : "Yes";
    } else if (value === "false" || value === false) {
      return field.params && field.params.mapping && field.params.mapping['false'] ? field.params.mapping['false'] : "No";
    }
    return null;
  }

  getEdit(field, value, onChange, entity, routeParams, loading = false) {
    let preparedValue = null;
    if (value != null && value != undefined) {
      try {
        preparedValue = {
          value: JSON.parse(value),
          label: field.params && field.params.mapping && field.params.mapping[value] ? field.params.mapping[value] : boolMap[value]
        };
      } catch (e) {
        console.error(e, value);
      }
    }
    return (
      <Select
        label={field.title}
        //disabled={loading}
        options={Object.keys(field.params && field.params.mapping ? field.params.mapping : boolMap).map(key => {
          return {
            value: JSON.parse(key),
            label: field.params && field.params.mapping && field.params.mapping[key] ? field.params.mapping[key] : boolMap[key]
          };
        })}
        value={preparedValue}
        isClearable={!("required" in field && field.required === true)}
        onChange={item => {
          onChange(item ? item.value : null);
        }}
        required={field.requiredComputed}
        error={!!field.errorHelperText}
        className={field.errorHelperText ? "field-error-control" : ""}
        helperText={field.errorHelperText}
      />
    );
  }

  getFilter(options = {}) {
    const { field, value, onChange } = options;
    return (
      <Select
        options={Object.keys(field.params && field.params.mapping ? field.params.mapping : boolMap).map((key) => {
          return {
            value: key,
            label: field.params && field.params.mapping && field.params.mapping[key] ? field.params.mapping[key] : boolMap[key],
          };
        })}
        onChange={onChange}
        isClearable={true}
        value={value ? { value, label: field.params && field.params.mapping && field.params.mapping[value] ? field.params.mapping[value] : boolMap[value] } : value}
      />
    );
  }
}

export default new BoolProvider();
