import React, { Component } from 'react';
import { observer } from "mobx-react";
import Button from "@material-ui/core/Button";
import Http from "../../Services/Http";
import {
    Grid,
} from "@material-ui/core";

class ExportDownload extends Component {

    constructor(props) {
        super(props);
    }

    download(id, documentName){
        Http.openEncryptedFile(
            "/document/download/" + id
            , documentName
        )
    }

    render() {
        return (
            <Grid className={'export container'}>
                <h1>Your export is available :</h1>
                <div>
                    <div>
                        <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button variant="contained" color="secondary" className="button-general" onClick={() => this.download(this.props.match.params['id'],this.props.match.params['documentName'] + '.xlsx')}>
                                Download
                            </Button>
                        </div>
                    </div>
                </div>
            </Grid>
        )
    }
}

export default observer(ExportDownload);
