import Navigation from "../Navigation";
import Modal from "../Modal";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ParameterStore from "../../Store/ParameterStore";
import APIResourceStore from "../../Store/APIResourceStore";
import Alert from "../Alert";
import { ModalContent } from "../../Components/Modal/ModalContent";
import { ButtonBar } from "../../Components/Modal/ButtonBar";
import { ActionButton } from "../../Components/Modal/ActionButton";
import { Typography } from "@material-ui/core";
import { 
    REVIEW_STATUS_CLOSED,
    REVIEW_VALIDATION_STATUS_APPROVED,
    REVIEW_VALIDATION_STATUS_CONDITIONAL,
    REVIEW_VALIDATION_STATUS_REJECTED,
} from "../../Admin/ReviewAdmin";
import { retirementFields } from "../../Components/Model/Retire/RetireModelForm";

/**
 * 
 * @param {string} modelStatus 
 * @param {string} expectedStatus 
 * @returns {{modelStatus: string, expectedStatus: string}}
 */
export const getNextStatuses = (modelStatus, expectedStatus) => {
    const statuses = {
        modelStatus: null,
        expectedStatus: null
    };

    switch (`${modelStatus}${expectedStatus || ""}`) {
        case ParameterStore('MODEL_STATUS_DRAFT'):
            statuses.modelStatus = modelStatus;
            statuses.expectedStatus = ParameterStore('MODEL_STATUS_UNDER_DECLARATION');
            break;
        
        case ParameterStore('MODEL_STATUS_DRAFT') + ParameterStore('MODEL_STATUS_UNDER_DECLARATION'):
            statuses.modelStatus = expectedStatus;
            statuses.expectedStatus = null;
            break;
    
        case ParameterStore('MODEL_STATUS_UNDER_DECLARATION'):
            statuses.modelStatus = modelStatus;
            statuses.expectedStatus = ParameterStore('MODEL_STATUS_ACTIVE');
            break;
    
        case ParameterStore('MODEL_STATUS_UNDER_DECLARATION') + ParameterStore('MODEL_STATUS_ACTIVE'):
            statuses.modelStatus = expectedStatus;
            statuses.expectedStatus = null;
            break;

        case ParameterStore('MODEL_STATUS_ACTIVE'):
            break;
    
        default:
            console.error("bad input statuses", modelStatus, expectedStatus)
            return false;
    }

    return statuses;
}

/**
 * 
 * @param {import('../APIResource/APIResource').APIResource} resource 
 * @param {*} entity - Model à soumettre
 * @param {*} currentStatus 
 * @param {*} nextStatus 
 * @param {*} resourceEditComponent 
 */
const submitToMrmFunction = (
    resource,
    entity_,
    currentStatus,
    nextStatus,
    resourceEditComponent
) => {
    const entity = { ...entity_ };
    entity.insertionFromImport = false; //Pour hacker la fonction de requiredByStatus
    let previousExpectedStatus =
        typeof entity.expectedStatus === "undefined"
            ? null
            : entity.expectedStatus;
    let saveConfirmationTxt = "";
    let updateConfirmationTxt = "";
    let validationErrorIntroTxt = "";

    if (currentStatus === ParameterStore("MODEL_STATUS_DRAFT")) {
        entity.underDeclarationConversion = true;
        entity.expectedStatus = ParameterStore(
            "MODEL_STATUS_UNDER_DECLARATION"
        );
        saveConfirmationTxt =
            "Your candidate has been submitted to MRM for review.";
        validationErrorIntroTxt =
            "Your changes have been saved but your draft has not been submitted. The following fields are required in order to proceed:";
    } else if (
        currentStatus === ParameterStore("MODEL_STATUS_UNDER_DECLARATION")
    ) {
        entity.underDeclarationConversion = false;
        entity.inventoriedConversion = true;
        entity.expectedStatus = ParameterStore("MODEL_STATUS_ACTIVE");
        saveConfirmationTxt =
            "Your model has been submitted to MRM for final review.";
        validationErrorIntroTxt =
            "Your declaration is incomplete. In order to submit to MRM, the following information is required:";
    }
    updateConfirmationTxt = saveConfirmationTxt;

    resource.currentEditingActions
        .save(
            saveConfirmationTxt,
            updateConfirmationTxt,
            validationErrorIntroTxt,
            entity,
        )
        .then((entity) => {
            if (currentStatus === ParameterStore("MODEL_STATUS_DRAFT")) {
                entity.expectedStatus = ParameterStore(
                    "MODEL_STATUS_UNDER_DECLARATION"
                );
            } else if (
                currentStatus ===
                ParameterStore("MODEL_STATUS_UNDER_DECLARATION")
            ) {
                entity.expectedStatus = ParameterStore("MODEL_STATUS_ACTIVE");
            }
            resource
                .apiPut(entity)
                .then(() => {
                    Modal.close();
                })
                .catch(() => {
                    Modal.close();
                });
        })
        .catch(() => {
            Modal.close();
            if (
                currentStatus === ParameterStore("MODEL_STATUS_DRAFT") ||
                currentStatus ===
                    ParameterStore("MODEL_STATUS_UNDER_DECLARATION")
            ) {
                entity.expectedStatus = previousExpectedStatus;
                entity.modelStatus = currentStatus;
                if (currentStatus === ParameterStore("MODEL_STATUS_DRAFT")) {
                    entity.underDeclarationConversion = false;
                } else if (
                    currentStatus ===
                    ParameterStore("MODEL_STATUS_UNDER_DECLARATION")
                ) {
                    entity.inventoriedConversion = false;
                }
            }
            resource.apiPut(entity).then((entity) => {
                resourceEditComponent.setState({ entity: entity });
            });
        });
};

export const submitToMRM = (
    resource,
    entity,
    currentStatus,
    nextStatus,
    resourceEditComponent
) => {
    if (
        currentStatus === ParameterStore("MODEL_STATUS_DRAFT") ||
        currentStatus === ParameterStore("MODEL_STATUS_UNDER_DECLARATION")
    ) {
        let currentStatusId = currentStatus.split("/")[
            currentStatus.split("/").length - 1
        ];
        let nextStatusId = nextStatus.split("/")[
            nextStatus.split("/").length - 1
        ];
        Modal.open({
            title:
                currentStatus === ParameterStore("MODEL_STATUS_DRAFT")
                    ? "Candidate submission"
                    : "Model submission",
            content: (
                <SubmitToMrmModal
                    entity={entity}
                    currentStatus={APIResourceStore.resources.parameters.getObservableItem(
                        currentStatusId
                    )}
                    nextStatus={APIResourceStore.resources.parameters.getObservableItem(
                        nextStatusId
                    )}
                    callback={() =>
                        submitToMrmFunction(
                            resource,
                            entity,
                            currentStatus,
                            nextStatus,
                            resourceEditComponent
                        )
                    }
                />
            ),
        });
    } else {
        submitToMrmFunction(
            resource,
            entity,
            currentStatus,
            nextStatus,
            resourceEditComponent
        );
    }
};

/**
 * 
 * @param {import('../APIResource/APIResource').APIResource} resource 
 * @param {{reviewsEntities: Array, expectedStatus: string, modelStatus: string, validationStatus: string, productionRun: boolean}} entity 
 * @param {*} resourceDetailComponent 
 * @returns 
 */
export const validateModel = (resource, entity, resourceDetailComponent) => {
    if (entity.expectedStatus === ParameterStore('MODEL_STATUS_ACTIVE')) {
        if ((entity?.tierings || []).length > 0 && entity.hasTieringWithoutMRMVerification) {
            Alert.show({
                message: 'Tierings: Please confirm the tiering of the model before adding it to the inventory.',
                type: 'error',
            });
            return;
        }
        
        if ((entity?.modelValidatorTeams || []).length === 0) {
            Alert.show({
                message: 'Model validator teams: This field is required',
                type: 'error',
            });
            return;
        }

        const mrm_wholesale_banking = APIResourceStore.resources.scopes_mrm.filterItems((item) => item.title.toLowerCase() === 'model governance wholesale banking')?.[0];
        if (entity.mrmTeams.includes(mrm_wholesale_banking?.['@id']) && entity.coreModel === undefined) {
            Alert.show({
                message: 'Core Model: This field is required',
                type: 'error',
            });
            return;
        }
    }

    let submitModel = () => {
        let previousStatus = entity.modelStatus;
        let previousExpectedStatus = entity.expectedStatus;
        entity.modelStatus = entity.expectedStatus;
        entity.expectedStatus = null;

        let saveConfirmationTxt = "";

        if (
            entity.modelStatus === ParameterStore("MODEL_STATUS_UNDER_DECLARATION")
        ) {
            saveConfirmationTxt =
                "The draft has been converted to model under declaration. The declarer has been notified.";
        } else if (entity.modelStatus === ParameterStore("MODEL_STATUS_ACTIVE")) {
            saveConfirmationTxt =
                "The model has been added to the Inventory. The declarer has been notified.";
        }

        resource
            .apiPut(entity)
            .then((entity) => {
                Modal.close();
                Alert.show({
                    message: saveConfirmationTxt,
                    type: "success",
                });
                resourceDetailComponent.entity = entity;
                resourceDetailComponent.forceUpdate(); 
            })
            .catch(() => {
                Modal.close();
                entity.modelStatus = previousStatus;
                entity.expectedStatus = previousExpectedStatus;
            });
    }

    // Tests de validation, pour passage : 
    // MODEL_STATUS_SIMPLIFIED_AWAITING_VALIDATION (5) => MODEL_STATUS_SIMPLIFIED_INVENTORIED (6)
    if (
        entity.expectedStatus === ParameterStore("MODEL_STATUS_ACTIVE")
        && entity.modelStatus === ParameterStore("MODEL_STATUS_UNDER_DECLARATION")
    ) {
        if (entity.productionRun === true) { 
            if (
                !(
                    (
                        [ParameterStore(REVIEW_VALIDATION_STATUS_APPROVED), ParameterStore(REVIEW_VALIDATION_STATUS_CONDITIONAL)].includes(entity.validationStatus)
                        || (
                            entity.validationStatus === ParameterStore(REVIEW_VALIDATION_STATUS_REJECTED)
                            && entity.policyExceptionStartDate
                            && entity.policyExceptionEndDate
                        )
                    )
                )
                && !(
                    // Model avec ou sans Review mais validationStatus vide  {@see https://app.asana.com/0/0/1202480373094067/1202913303065748/f}
                    !entity.validationStatus
                    && entity.policyExceptionStartDate
                    && entity.policyExceptionEndDate
                )
            ) {
                Modal.open({
                    title: "Validate model",
                    content: (
                        <ValidateProductionRunModal
                            callback={(decision) => {
                                if(decision === true){
                                    submitModel();
                                }else{
                                    Modal.close();
                                    Navigation.router.history.push(`/blank`);
                                    setTimeout(() => {
                                        Navigation.router.history.push(`/resource/models/${entity.id}/edit?tab=Properties`);
                                        resourceDetailComponent.forceUpdate();
                                    }, 10);
                                }
                            }}
                        />
                    ),
                });

                return;
            }
        }
    }

    submitModel();
};

/**
 * Changement de retirementStatus dans le processus de retrait.
 *
 * On ne fait pas de contrôle au niveau de cet appel.
 * Si on veut plus de sécurité il faut retranscrire les règles de RetireModelForm en back.
 *
 * @param {import('../APIResource/APIResource').APIResource} resource
 * @param {*} entity
 * @param {*} newStatusSystemId
 * @param {*} message
 * @param {*} resourceDetailComponent
 * @param {*} callback
 */
export const retireModelStep = (
    entity,
    resource,
    newStatusSystemId,
    message,
    resourceDetailComponent,
    callback
) => {
    entity.retirementStatus = ParameterStore(newStatusSystemId);

    const entityPatch = Object.entries(entity).reduce((carry, [k, v])=> {
        if (['@id', 'id', ...Object.keys(retirementFields)].includes(k)) carry[k] = v;
        return carry
    }, {})

    return resource.apiPut(entityPatch).then((e) => {
        Alert.show({
            message,
            type: "success",
        });
        // On change l'onglet, et on l'indique dans la barre d'adresse
        resourceDetailComponent.setState({ currentTab: resource.getTabId('detail', 'Retirement') }, () => {
            callback(e);
            Navigation.router.history.push(`/blank`);
            setTimeout(() => {
                Navigation.router.history.push(`/resource/models/${e.id}/detail?tab=Retirement`);
                resourceDetailComponent.entity = e;
                resourceDetailComponent.forceUpdate();
            }, 10);
        });
    });
};

/**
 * Remise à zéro du statut retirement, en conservant les justifications ou non.
 *
 * @param {*} entity
 * @param {*} resource
 * @param {*} resourceDetailComponent
 * @param {*} message
 * @param {*} callback
 * @param {*} keepJustification
 */
export const resetRetirementStatus = (
    entity,
    resource,
    message,
    resourceDetailComponent,
    callback,
    keepJustification = true
) => {
    entity.retirementStatus = null;
    entity.retirementJustificationLod1 = keepJustification
        ? entity.retirementJustificationLod1
        : null;
    entity.retirementJustificationMrm = null;
    entity.retirementCommittee = null;
    entity.retirementRequestDate = null;
    entity.retirementExpectedDate = null;

    const entityPatch = Object.entries(entity).reduce((carry, [k, v])=> {
        if (['@id', 'id', ...Object.keys(retirementFields)].includes(k)) carry[k] = v;
        return carry
    }, {})

    return resource.apiPut(entityPatch).then((e) => {
        Alert.show({
            message,
            type: "success",
        });
        resourceDetailComponent.entity = e;
        resourceDetailComponent.setState({ entity: e }, () => callback(e));
        resourceDetailComponent.verifyAllTabs();
    });
};

const SubmitToMrmModal = (props) => {
    const [running, setRunning] = useState(false);

    const onClick = () => {
        setRunning(true);
        props.callback();
    }

    return (
        <ModalContent  className={"submit-to-mrm-modal"}>
            {props.currentStatus["@id"] ===
            ParameterStore("MODEL_STATUS_DRAFT") ? (
                <Typography component="p">
                    {"• You are about to submit " +
                        props.entity.functionalID +
                        " to MRM for review.\n" +
                        "• The MRM team will confirm that this candidate is indeed a model or will categorize it as a « Non-Model ».\n" +
                        "• All the fields of the « ID Card » and « Properties » tabs are mandatory in order to proceed."}
                </Typography>
            ) : (
                <Typography component="p">
                    {"• You are about to submit the model " +
                        props.entity.functionalID +
                        " to MRM for final review.\n" +
                        "• The MRM team will verify that all the information is correctly filled in and add the model to the inventory.\n" +
                        "• Please note that in order to proceed you must have declared at least one use, one implementation, one mitigation action (or indicate that this is not applicable) and the tiering of the model."}
                </Typography>
            )}
            <ButtonBar>
                <ActionButton
                    onClick={Modal.close}
                    disabled={running}
                >
                    Cancel
                </ActionButton>
                <ActionButton
                    onClick={onClick}
                    loading={running}
                >
                    Continue
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
    
}
SubmitToMrmModal.propTypes = {
    /** entity est en fait un Model */
    entity: PropTypes.object,
    currentStatus: PropTypes.object,
    nextStatus: PropTypes.object,
    callback: PropTypes.func,
};


const ValidateProductionRunModal = (props) => {
    const [running, setRunning] = useState(false);

    const onClick = (decision) => {
        setRunning(true);
        props.callback(decision);
    }

    return (
        <ModalContent  className={"validate-production-run-modal"}>
            <Typography component="p">
                {"A model currently running in production (field \"production run\" = yes) should either be validated or have a policy exception."}
            </Typography>
            <ButtonBar>
                <ActionButton
                    onClick={() => onClick(false)}
                    disabled={running}
                >
                    Update
                </ActionButton>
                <ActionButton
                    onClick={() => onClick(true)}
                    loading={running}
                >
                    Add to the inventory without policy exception
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );

}
ValidateProductionRunModal.propTypes = {
    /** entity est en fait un Model */
    entity: PropTypes.object,
    callback: PropTypes.func,
};

/**
 * Voir la règle également plus haut dans {@see ModelAction.validateModel}
 *
 * @param {{productionRun: boolean, modelStatus: string, validationStatus: string, policyExceptionStartDate: date, policyExceptionEndDate: date}} model 
 * @returns {boolean}
 */
export const isModelUnauthorizedInProduction = (model) => {
    return (
        model.productionRun === true
        && model.modelStatus === ParameterStore('MODEL_STATUS_ACTIVE')
        && (
            model.validationStatus === ParameterStore(REVIEW_VALIDATION_STATUS_REJECTED)
            || !model.validationStatus
        ) && (
            !model.policyExceptionStartDate
            || !model.policyExceptionEndDate
        )
    );
}