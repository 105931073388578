import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import colors from './colors';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export class BarWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialRows: null,
            rows: null,
        };
    }

    render() {
        const { rows, cols, label } = this.props;
        // Avoid showing dataset legend as we load only one.
        const legend = { display: false };

        // Compute bar height for adapting
        // the bar chart in a wide box
        // - a widget height is 25vh and its body is fixed at 20vh
        const barHeight = Math.round(window.innerHeight * 0.2);

        const data = {
            labels: cols,

            datasets: [
                {
                    label: false,
                    data: rows,
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                },
            ],
        };

        return (
            <Bar
                data={data}
                plugins={[ChartDataLabels]}
                legend={legend}
                height={barHeight}
                options={{
                    layout: {
                        padding: {
                            top: 32,
                        },
                    },
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                },
                            },
                        ],
                    },
                    plugins: {
                        datalabels: {
                            color: 'black',
                            align: 'end',
                            anchor: 'end',
                        },
                    },
                }}
            />
        );
    }
}
