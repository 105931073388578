import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as mobx from 'mobx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toJS } from 'mobx/lib/mobx';
import {
    AppBar,
    Box,
    Button,
    Card,
    CardContent,
    Fade,
    Grid,
    LinearProgress,
    Typography,
    Tabs,
    Tab,
    FormLabel,
    Input,
} from '@material-ui/core';
import _ from 'lodash';
import domtoimage from 'dom-to-image';
/*
Assets
 */
// import dashboardImage from '../../Styles/Assets/img/dashboard.jpg';
// import inventoryImage from '../../Styles/Assets/img/models-inventory.jpg';
// import reportingImage from '../../Styles/Assets/img/reporting.jpg';
// import recoImage from '../../Styles/Assets/img/recommendations.jpg';
// import planningImage from '../../Styles/Assets/img/planning.jpg';
// import issuesTrackingToolImage from '../../Styles/Assets/img/issue-tracking.jpg';
// import parametersImage from '../../Styles/Assets/img/tool-management.jpg';
import AppBarStore from '../../Store/AppBarStore';
// import WidgetStore from '../../Store/WidgetStore';
import AddIcon from '@material-ui/icons/Add';

import Modal from '../../Services/Modal';
import String from '../../Services/String';
import { APIResource } from '../../Services/APIResource/APIResource';
import Navigation from '../../Services/Navigation';
import WidgetSelectionModal from './Modals/WidgetSelectionModal';
import TabDefinitionModal from './Modals/TabDefinitionModal';
import {
    TableWidget,
    DoughnutWidget,
    BarWidget,
    BarStackedWidget,
    TextWidget,
    NotificationWidget,
    PieWidget,
    CountWidget,
    UserDefinedWidget,
} from './Widget';
import { WidgetMenu, DashboardMenu } from './Menu';
import Http from "../../Services/Http";

const TitleDetail = ({ title, detail }) => {
    return (
        <>
            <span style={styles.title}>{title}</span>
            {/* <span style={styles.titleDetail}>{detail}</span> */}
        </>
    );
};

const getNbElementsText = (data, boxId) => {
    const length = data.length;
    if (length > 6 && boxId < 4) {
        return `6/${length} rows shown`;
    }

    if (length > 20 && boxId < 6) {
        return `20/${length} rows shown`;
    }

    if (length > 40) {
        return `40/${length} rows shown`;
    }

    return '';
};

const getTitle = (widget, boxId) => {
    const { title, type, parameters } = widget.value;
    if (
        widget &&
        widget.data &&
        type === 'custom' &&
        parameters.chart &&
        parameters.chart !== 'table' &&
        parameters.chart !== 'pie chart'
    ) {
        return <TitleDetail title={title} detail={getNbElementsText(widget.data, boxId)} />;
    }

    return <TitleDetail title={title} />;
};

class Dashboard extends Component {
    unConstrainedWidgets = ['pie', 'chart', 'bar', 'doughnut'];
    constructor(props) {
        super(props);
        AppBarStore.reset();
        this.state = {
            allWidgets: [],
            widgets: [],
            widgetsData: [],
            widgetsAnimation: {},
            currentTabIndex: 0,
            currentTabCode: '',
            tabs: {},
            isLoading: false,
        };
        this.numberOfBlocks = 7;
        this.userSettingResource = new APIResource({ id: 'user_settings' });
        this.widgetDataResource = new APIResource({ id: 'dashboard/widgets/data' });
        this.notificationsResource = new APIResource({ id: 'notifications' });
    }

    componentDidMount() {
        this.getItems();
        window.dash = this;
    }

    getItems = () => {
        this.setState({ isLoading: true });
        this.getTabs()
            .then(() => this.getWidgets())
            .then(() => this.getWidgetsData());
    };
    getWidgets = async () => {
        let allWidgets = await this.userSettingResource.apiGetCollection({
            page: 1,
            rowsPerPage: 100,
            forceReload: true,
            filters: {
                type: 'dashboard-widget',
            },
        });
        this.setState({ allWidgets }, this.setCurrentTabWidgets);
    };

    setCurrentTabWidgets = () => {
        const { allWidgets } = this.state;
        // only keep the widget related to this tab
        if (this.state.currentTabCode) {
            const widgets = allWidgets.filter((w) => w.value.tab === this.state.currentTabCode);
            this.setState({ widgets });
            this.setState({ isLoading: true });
            this.getWidgetsData(widgets.map((w) => w.id));
        }
    };

    /**
     * Get the tabs from the apis and reduce them to the correct order
     *
     * @memberof Dashboard
     */
    getTabs = async () => {
        let newCurrentTabIndex;
        const tabs = await this.userSettingResource.apiGetCollection({
            page: 1,
            rowsPerPage: 100,
            forceReload: true,
            filters: {
                type: 'dashboard-tab',
            },
        });
        // sometimes the api return some other types alsos
        const displayTabs = tabs
            .filter((tab) => tab.type === 'dashboard-tab')
            .sort((a, b) => a.value.order - b.value.order)
            .reduce((prev, tab, index) => {
                prev[tab.code] = { id: tab.id, ...tab.value };
                if (tab.code === this.state.currentTabCode) {
                    newCurrentTabIndex = index;
                }
                return prev;
            }, {});

        // if the current tab index has changed put the new one in state.
        if (newCurrentTabIndex !== undefined && newCurrentTabIndex !== this.state.currentTabIndex) {
            this.setState({ currentTabIndex: newCurrentTabIndex });
        }

        // if the current tab index does exists or is not set, use the first tab
        if (!this.state.currentTabCode || !displayTabs[this.state.currentTabCode]) {
            const firstTab = Object.keys(displayTabs)[0];
            this.setState({ currentTabCode: firstTab, currentTabIndex: 0 });
        }

        this.setState({ tabs: displayTabs });
    };

    getWidgetsData = async (widgetIds) => {
        let newWidgetsData = await this.widgetDataResource
            .apiGetCollection({
                forceReload: true,
                page: 1,
                rowsPerPage: 25,
                filters: widgetIds ? { id: widgetIds } : undefined,
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
        newWidgetsData = newWidgetsData.filter((w) => w.value.tab === this.state.currentTabCode);
        if (widgetIds) {
            let oldWidgetData = this.state.widgetsData;
            if (oldWidgetData && oldWidgetData.length > 0) {
                oldWidgetData = oldWidgetData.filter((w) => widgetIds.indexOf(w.id) === -1);
                newWidgetsData = oldWidgetData.slice().concat(newWidgetsData.slice());
            }
        }

        const widgetsByColumns = this.splitWidgetsByColumns(newWidgetsData);
        return this.setState({
            widgetsData: newWidgetsData,
            widgetsByColumns,
        });
    };

    splitWidgetsByColumns(widgets) {
        const widgetsByColumns = {};
        for (let i = 0; i < this.numberOfBlocks; i++) {
            widgetsByColumns[i] = [];
        }

        widgets.forEach((w) => {
            if (widgetsByColumns[w.value.column]) {
                widgetsByColumns[w.value.column].push(w);
            }
        });
        return widgetsByColumns;
    }

    handleConfigWidgetAdded = async (args = { widget: null, userWidget: {} }) => {
        Modal.close();
        await this.getWidgetsData([args.widget.id]);
    };

    handleWidgetDelete = async (widget) => {
        const { widgetsAnimation, widgetsByColumns } = this.state;
        widgetsAnimation[widget.id] = true;
        this.setState({ widgetsAnimation });
        try {
            await this.userSettingResource.apiDelete(widget);

            widgetsByColumns[widget.value.column].splice(widget.value.row, 1);
            widgetsAnimation[widget.id] = false;
            this.setState({ widgetsAnimation, widgetsByColumns }, () => this.getWidgetsData());
        } catch (err) {
            console.warn(err.message);
            widgetsAnimation[widget.id] = true;
            this.setState({ widgetsAnimation });
        }
    };

    handleWidgetEdit = async (widget) => {
        this.openWidgetModal(widget);
    };

    handleWidgetDuplicate = async (widget) => {
        const newWidget = { ...widget };
        delete newWidget.id;
        delete newWidget.data;

        newWidget.code = `${newWidget.value.widgetSystemId}-${Date.now()}`;

        widget.value.title += ' (Copy)';
        await this.userSettingResource.apiPost(newWidget);

        // Refresh dashboard
        this.getItems();
    };

    handleSave = (widget) => {
        const realNode = document.getElementById(`widget-${widget.id}`);

        domtoimage.toJpeg(realNode, { quality: 0.95 }).then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = `${widget.value.title}.jpeg`;
            link.href = dataUrl;
            link.click();
        });
    };

    handleListItemClick = async (event, selectedIndex) => {
        this.setState({ selectedIndex });
    };

    handleDragEnd = (result) => {
        const { source, destination, draggableId } = result;
        if (
            !source ||
            !destination ||
            (source.droppableId === destination.droppableId && source.index === destination.index)
        ) {
            console.warn('unstable drag...');
            return;
        }
        let { widgetsData, widgetsByColumns } = this.state;
        source.droppableId = parseInt(source.droppableId);
        destination.droppableId = parseInt(destination.droppableId);
        const userWidget = widgetsByColumns[source.droppableId].find((w) => w.id === draggableId);

        widgetsByColumns[source.droppableId].splice(source.index, 1);

        userWidget.value.column = parseInt(destination.droppableId);
        userWidget.value.row = parseInt(destination.index);
        if (!widgetsByColumns[destination.droppableId]) {
            widgetsByColumns[destination.droppableId] = [];
        }
        widgetsByColumns[destination.droppableId].splice(userWidget.value.row, 0, userWidget);
        widgetsByColumns[destination.droppableId].forEach((widget, index) => {
            // using index to redefine columns indexes
            widget.value.row = index;
        });

        this.setState({ widgetsData, widgetsByColumns });
        this.saveDashboardState();
        // if (result.destination) {
        //   let newState = {
        //     available: [...this.state.available],
        //     selected: [...this.state.selected]
        //   };
        //   newState[result.source.droppableId].splice(result.source.index, 1);
        //   newState[result.destination.droppableId].splice(
        //     result.destination.index,
        //     0,
        //     this.state[result.source.droppableId][result.source.index]
        //   );
        //   this.setState({
        //     available: newState.available,
        //     selected: newState.selected
        //   });
        //   this.props.resource.currentList.displayFields = [...newState.selected];
        // }
    };

    openWidgetModal = (widget) => {
        return Modal.open({
            title: widget && widget.value ? `EDIT WIDGET ${widget.value.title}` : 'ADD A WIDGET TO THE DASHBOARD',
            //   contentStyle: { maxHeight: 600 },
            content: (
                <WidgetSelectionModal
                    onSelection={this.handleConfigWidgetAdded}
                    tab={this.state.currentTabCode}
                    widget={widget}
                    closeModal={() => Modal.close()}
                    refreshDashboard={this.getItems}
                />
            ),
        });
    };

    openTabEditModal = (tab) => {
        return Modal.open({
            title: tab ? 'Edit dashboard' : 'Add a dashboard',
            contentStyle: { height: 'auto' },
            modalStyle: { width: 450, overflow: 'hidden' },
            content: <TabDefinitionModal tab={tab} onSubmit={this.handleTabSubmit} />,
        });
    };

    /**
     * When the user click on the tabs
     *
     * @memberof Dashboard
     */
    handleTabChange = (event, val) => {
        // last tab is the new button
        if (val >= Object.keys(this.state.tabs).length) {
            this.setState({
                currentTabIndex: this.state.currentTabIndex,
                currentTabCode: Object.keys(this.state.tabs)[this.state.currentTabIndex],
            });
            return false;
        }
        this.setState(
            {
                widgetsByColumns: {},
                widgetsData: [],
                currentTabIndex: val,
                currentTabCode: Object.keys(this.state.tabs)[val],
            },
            () => this.setCurrentTabWidgets()
        );
    };

    /**
     * Save or edit a dashboard
     *
     * @memberof Dashboard
     */
    handleTabSubmit = ({ code, name, order, id }) => {
        const { tabs } = this.state;
        let promise;
        if (id) {
            promise = this.userSettingResource.apiPut({
                id,
                code,
                type: 'dashboard-tab',
                value: { code, name, order },
            });
        } else {
            promise = this.userSettingResource.apiPost({
                code,
                type: 'dashboard-tab',
                value: { code, name, order },
            });
        }
        promise.then((data) => {
            this.getTabs();
            Modal.close();
        });
    };

    handleTabDelete = async (tab) => {
        const { allWidgets } = this.state;
        const toDelete = allWidgets.filter((widget) => widget.value.tab === tab.code);
        try {
            Promise.all(toDelete.map((widget) => this.userSettingResource.apiDelete(widget)))
                .then(() => this.userSettingResource.apiDelete(tab))
                .then(() => {
                    return this.setState({
                        widgetsByColumns: {},
                        widgetsData: [],
                        currentTabIndex: null,
                        currentTabCode: null,
                    });
                })
                .then(this.getItems);
        } catch (err) {
            console.warn(err.message);
        }
    };

    /**
     *
     *
     * @memberof Dashboard
     */
    saveDashboardState = async (widgetId = undefined) => {
        const { widgetsData } = this.state;
        const userWidgets = widgetsData.filter((setting) =>
            setting.type === 'dashboard-widget' && widgetId ? setting.id === widgetId : true
        );
        // the spreading is to avoid putting data in the settings
        const promises = userWidgets.map((w) => this.userSettingResource.apiPut({ ...w, data: undefined }));
        await Promise.all(promises).catch(console.warn);
    };

    renderTableWidget(widget, options = {}) {
        const cols =
            widget.value.parameters && widget.value.parameters.fields
                ? widget.value.parameters.fields.map((f) =>
                      typeof f === 'string' ? { label: _.startCase(f), field: f } : f
                  )
                : [];
        const rows = widget.data || [];
        // row click from api
        if (!options.onRowClick && widget.value.parameters.onRowClick) {
            options.onRowClick = (entity, index) => {
                if(widget.value.parameters.onRowClick === "DOWNLOAD"){
                    console.log('ask download', entity, index);
                    Http.openEncryptedFile(
                        '/document/download/' + entity.downloadLink.split('#')[0],
                        entity.downloadLink.split('#')[1]
                    );
                }else {
                    let url = widget.value.parameters.onRowClick;
                    Object.entries(entity).forEach(([key, value]) => {
                        url = url.replace(`{${key}}`, value);
                    });

                    window.open(url);
                }
            };
        }
        return (
            <React.Fragment>
                <TableWidget
                    rows={rows}
                    cols={JSON.parse(JSON.stringify(cols))}
                    onRowClick={options.onRowClick}
                ></TableWidget>
            </React.Fragment>
        );
    }

    renderModelUpdateValidationWidget(widget) {
        widget.value.parameters.fields = [
            'model.functionalID',
            {
                field: 'changeDescription',
                label: 'changeDescription',
                display: (field, value) => (value ? String.nlToBr(value) : ''),
            },
        ];
        return this.renderTableWidget(widget, {
            onRowClick: (entity, index) => {
                window.open(
                    '/resource/models/' +
                        (entity.model.activeVersion ? entity.model.id : entity.model.currentVersion.id) +
                        '/detail?tab=Versions'
                );
            },
        });
    }

    renderModelCertificationWidget(widget) {
        return this.renderTableWidget(widget, {
            onRowClick: (entity, index) => {
                Navigation.router.history.push(
                    '/resource/my_model_certification_campaigns/' + entity.mccId + '/detail'
                );
            },
        });
    }

    renderDoughnutWidget(widget) {
        const rows = [];
        const cols = [];
        const { xAxisField, yAxisField } = widget.value.parameters;
        widget.data.forEach((d) => {
            rows.push(d[xAxisField]);
            cols.push(d[yAxisField]);
        });

        return (
            <React.Fragment>
                <DoughnutWidget rows={rows} cols={cols} />
            </React.Fragment>
        );
    }

    renderCountWidget(widget) {
        return (
            <React.Fragment>
                <CountWidget data={widget.data} />
            </React.Fragment>
        );
    }

    renderPieWidget(widget, options = {}) {
        const rows = [];
        const cols = [];
        const { xAxisField, yAxisField } = widget.value.parameters;
        widget.data.forEach((d) => {
            rows.push(d[xAxisField]);
            cols.push(d[yAxisField]);
        });
        return (
            <React.Fragment>
                <PieWidget box={options && options.boxId} rows={rows} cols={cols} />
            </React.Fragment>
        );
    }

    renderBarWidget(widget) {
        const rows = [];
        const cols = [];
        const { xAxisField, yAxisField } = widget.value.parameters;
        widget.data.forEach((d) => {
            rows.push(d[xAxisField]);
            cols.push(d[yAxisField]);
        });

        return (
            <React.Fragment>
                <BarWidget rows={rows} cols={cols} />
            </React.Fragment>
        );
    }

    renderBarStackedWidget(widget) {
        return (
            <React.Fragment>
                <BarStackedWidget rows={widget?.data?.rows} cols={widget?.data?.cols} />
            </React.Fragment>
        );
    }

    renderTextWidget(widget) {
        return <TextWidget data={widget.data} />;
    }

    renderUserDefinedWidget(widget, options) {
        return <UserDefinedWidget data={widget.data} parameters={widget.value.parameters} options={options} />;
    }

    renderNotificationWidget(widget) {
        return <NotificationWidget notifications={widget.data} />;
    }

    renderWidget(widget, options) {
        switch (widget.value.type) {
            case 'list':
            case 'table':
                return this.renderTableWidget(widget, options);
            case 'graph':
            case 'doughnut':
                return this.renderDoughnutWidget(widget, options);
            case 'count':
                return this.renderCountWidget(widget, options);
            case 'pie':
                return this.renderPieWidget(widget, options);
            case 'bar':
                return this.renderBarWidget(widget, options);
            case 'text':
                return this.renderTextWidget(widget, options);
            case 'custom':
                return this.renderUserDefinedWidget(widget, options);
            case 'bar-stacked':
                return this.renderBarStackedWidget(widget, options);
            case 'model-update-validation':
                return this.renderModelUpdateValidationWidget(widget, options);
            case 'model-certification-campaign':
                return this.renderModelCertificationWidget(widget, options);
            case 'notifications':
                return this.renderNotificationWidget(widget, options);
            default:
                return <pre>{JSON.stringify(widget, null, 2)}</pre>;
        }
    }

    /**
     * Return a fixed height based on the number of rows returned
     */
    getCardHeight(widget, boxId) {
        let multiplier = 1;
        const { parameters } = widget.value;

        if (boxId < 4) {
            return '25vh';
        }

        if (parameters && parameters.chart === 'pie chart') {
            if (boxId < 6 && boxId > 3) {
                multiplier = 2;
            }

            if (widget.data.length > 20) {
                return `${widget.data.length * multiplier}vh`;
            }

            if (widget.data.length > 10) {
                return `30vh`;
            }
        }

        // more square like ratio
        if (parameters && parameters.chart === 'bar chart') {
            return '45vh';
        }

        return '25vh';
    }

    renderColumn(columnNumber = 0) {
        const { widgetsData, widgetsByColumns, widgetsAnimation } = this.state;
        if (!widgetsData || widgetsData.length < 1) {
            return;
        }

        const userWidgets = widgetsByColumns[columnNumber]
            ? widgetsByColumns[columnNumber].sort((a, b) => a.value.row - b.value.row)
            : [];

        return userWidgets.map((widget, index) => (
            <Draggable key={widget.id} draggableId={widget.id} index={index}>
                {(provided, snapshot) => (
                    <Box mb={2} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Fade in={!widgetsAnimation[widget.id]}>
                            <Card
                                className={`widget-card  ${
                                    this.unConstrainedWidgets.indexOf(widget.value.type) > -1 ? 'chart-widget' : ''
                                }`}
                                style={{ height: '100%' }}
                                elevation={3}
                            >
                                <CardContent
                                    className="widget-card-content"
                                    style={{ background: 'white' }}
                                    id={`widget-${widget.id}`}
                                >
                                    <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="h2"
                                        bgcolor="primary.main"
                                        className="widget-card-title"
                                    >
                                        {getTitle(widget, columnNumber)}
                                        <WidgetMenu
                                            className="widget-card-menu"
                                            onRefresh={() => this.getWidgetsData([widget.id])}
                                            onDelete={() => this.handleWidgetDelete(widget)}
                                            onEdit={() => this.handleWidgetEdit(widget)}
                                            onDuplicate={() => this.handleWidgetDuplicate(widget)}
                                            onSave={() => this.handleSave(widget)}
                                            refreshDashboard={this.getItems}
                                            widget={widget}
                                        />
                                    </Typography>
                                    <hr />
                                    <div className="widget-card-content-body">
                                        {this.renderWidget(widget, { boxId: columnNumber })}

                                        {widget.value.parameters.link && (
                                            <Link to={widget.value.parameters.link} className="widget-hover">
                                                <div style={styles.linkButton}>
                                                    <i className="fa fa-eye" style={styles.infoIcon}></i>
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                </CardContent>
                            </Card>
                        </Fade>
                    </Box>
                )}
            </Draggable>
        ));
    }

    renderColumns({ isDropDisabled }) {
        const cols = [];
        let key = 0;
        const rows = [4, 2, 1];

        for (let j = 0; j < 3; j++) {
            const rowCount = rows[j];
            for (let i = 0; i < rowCount; i++) {
                const keyInContext = key;
                const widgets = this.state.widgetsByColumns && this.state.widgetsByColumns[keyInContext];
                const numberOfWidgets = widgets && widgets.length ? widgets.length : 1;
                cols.push(
                    <Droppable
                        droppableId={`${keyInContext}`}
                        key={keyInContext}
                        id={i}
                        isDropDisabled={isDropDisabled}
                    >
                        {(provided, snapshot) => {
                            return (
                                <Grid
                                    item
                                    className={`block-${keyInContext}`}
                                    xs={Math.round(12 / rowCount)}
                                    {...provided.droppableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    key={keyInContext}
                                    style={snapshot.isDraggingOver ? styles.dragzoneStyle : {}}
                                >
                                    <Card
                                        style={{
                                            ...styles.column,
                                            minHeight: numberOfWidgets * 25 + 'vh',
                                        }}
                                    >
                                        {this.renderColumn(keyInContext)}
                                    </Card>
                                </Grid>
                            );
                        }}
                    </Droppable>
                );
                key++;
            }
        }

        return cols;
    }

    renderTabHeader() {
        const { tabs, currentTabCode } = this.state;
        const currentTab = tabs[currentTabCode];
        return (
            <AppBar position="relative" color="default">
                <Tabs
                    value={this.state.currentTabIndex}
                    onChange={this.handleTabChange}
                    className="tabs-menu"
                    indicatorColor="primary"
                    textColor="primary"
                    variant="standard"
                    scrollButtons="auto"
                    key={'resourceEditTab'}
                >
                    {this.renderTabs()}
                </Tabs>
                <div key={'new-dashboard'} style={styles.rightTab}>
                    <DashboardMenu
                        style={styles.rightTabInner}
                        className="widget-card-menu"
                        tabName={currentTab && currentTab.name}
                        currentTab={currentTab}
                        onRefresh={() => this.getWidgetsData([])}
                        onCreate={() => this.openTabEditModal()}
                        onEdit={() => this.openTabEditModal(currentTab)}
                        onDelete={() => this.handleTabDelete(currentTab)}
                    />
                </div>
            </AppBar>
        );
    }

    renderTabs = function () {
        let tabs = [];
        for (let tabId in this.state.tabs) {
            tabs.push(<Tab label={this.state.tabs[tabId].name} key={tabId} />);
        }
        return tabs;
    };

    render() {
        const { isLoading, tabs, currentTabCode } = this.state;
        const numberOfTabs = Object.keys(this.state.tabs).length;

        const currentTab = tabs[currentTabCode];

        return (
            <div>
                {this.renderTabHeader()}
                {isLoading && <LinearProgress></LinearProgress>}
                <div className="container">
                    <Grid key={0} container spacing={3} style={{ marginBottom: 30 }}>
                        {numberOfTabs ? (
                            <DragDropContext onDragEnd={this.handleDragEnd}>
                                {this.renderColumns({ isDropDisabled: currentTab && currentTab.isRef })}
                            </DragDropContext>
                        ) : null}
                        {!numberOfTabs && !isLoading ? (
                            <div style={styles.firstTabButton}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={() => this.openTabEditModal()}
                                >
                                    Add your first dashboard
                                </Button>
                            </div>
                        ) : null}
                    </Grid>
                    {(!currentTab || !currentTab.isRef) &&
                        this.state.currentTabIndex !== undefined &&
                        numberOfTabs > 0 && (
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                style={styles.fab}
                                onClick={() => this.openWidgetModal()}
                                className="button-general"
                            >
                                ADD WIDGET
                            </Button>
                        )}
                    {currentTab && currentTab.isRef && (
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            style={styles.fab}
                            onClick={() => this.openTabEditModal()}
                            className="button-general"
                        >
                            ADD DASHBOARD
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

export default Dashboard;

const styles = {
    linkButton: {
        height: '25px',
        width: '25px',
        lineHeight: '25px',
        textAlign: 'center',
        borderRadius: '25px',
        background: 'rgba(0, 0, 0,0.05)',
        cursor: 'pointer',
        position: 'absolute',
        bottom: 0,
        right: '5px',
        margin: '5px',
    },
    column: {
        minHeight: '25vh',
        paddingBottom: 0,
        background: 'rgba(0,0,0,0.05)',
        boxShadow: 'none',
        /* maxHeight: "30vh" */
    },
    fab: {
        position: 'fixed',
        bottom: 10,
        right: 10,
    },
    firstTabButton: {
        position: 'absolute',
        margin: 'auto',
        top: '50%',
        height: 40,
        marginTop: -20,
        textAlign: 'center',
        width: '100%',
    },
    rightTab: { position: 'absolute', right: 0, margin: 0 },
    rightTabInner: { margin: 0, color: 'white' },
    dragzoneStyle: { backgroundColor: 'rgba(128, 0, 129, 0.3)', borderRadius: 5 },
    title: { flexGrow: 1, alignSelf: 'center', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
    titleDetail: {
        alignSelf: 'center',
        color: 'rgba(0,0,0,0.45)',
        marginLeft: '5px',
        fontSize: '12px',
        fontWeight: 'normal',
        flexShrink: 1,
        whiteSpace: 'nowrap',
    },
};
