/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React from 'react';
import { APIResource } from "../Services/APIResource/APIResource";
import { ChangeLog } from "../Components/Display/ChangeLog/ChangeLog";
import User, {ROLE} from "../Services/User/User";
import { EntitySelectAsync } from '../Components/Forms/EntitySelectAsync/EntitySelectAsync';
import {BulkEdit} from "../Services/BulkActions/BulkEdit/BulkEdit";
import {
    userHasMRMRights,
    userHasOwnershipRights,
    userHasRoleADMIN,
    userHasRoleMRM,
} from '../Store/ParameterStore';
import MappedStringProvider from "../Services/APIResource/FieldProviders/MappedStringProvider";
import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import BoolProvider from '../Services/APIResource/FieldProviders/BoolProvider';
import {isGranted} from "./common";
import APIResourceStore from "../Store/APIResourceStore";
import Http from "../Services/Http";

let rolesMap = {
    'ROLE_SUPER_ADMIN': 'Super Administrator',
    'ROLE_ADMIN': 'Administrator',
    'ROLE_MRM': 'Governance',
    'ROLE_IG': 'General Inspection',
    'ROLE_STD_USER': 'Standard User',
};
let adminRolesMap = {
    'ROLE_SUPER_ADMIN': 'Super Administrator',
    'ROLE_ADMIN': 'Administrator',
};
let restrictedRolesMap = {
    'ROLE_MRM': 'Governance',
    'ROLE_IG': 'General Inspection',
    'ROLE_STD_USER': 'Standard User',
};

export const ApiResourceDefaultParams = {
    id: 'users',
    instanceId: 'users',
    name: 'Users',
    icon: 'user',
    fieldForTitle: 'toString',
    fieldsAclLocation: 'annotations/user',
};

const isAllowToDeleteUser = (user) => {
    return true;
}

export default class UserAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        // UserRoles
        const users = new APIResource(ApiResourceDefaultParams);
        users
            .setFields({
                id: { title: 'ID' },
                firstName: { title: 'First name', maxLength:255 },
                lastName: { title: 'Last name', maxLength:255 },
                fullName: {
                    title: 'Full name',
                    displayList: () => null,
                    filter: () => null,
                },
                email: { title: 'E-mail', maxLength:255 },
                companyPosition: { title: 'Company position', maxLength:255 },
                manager: {
                    title: 'Manager',
                    type: 'userAsync',
                    params: {
                        resource: 'users',
                        instanceId: 'users_all',
                        displayField: 'toString',
                        links: true,
                        endpoints: {
                            getAll: 'users/all-users/all',
                        },
                    },
                    edit: () => null,
                },
                teams: {
                    title: 'Team',
                    type: 'entityTree',
                    params: {
                        resource: 'scopes',
                        instanceId: 'scopes_complete',
                        childrenPropertyName: 'childScopes',
                        displayField: 'title',
                        links: true,
                        linkPath: (entity) => `/resource/scopes/${entity.id}/detail`,
                        multi: true,
                        endpoints: {
                            getAll: 'scopes/all-scopes/complete',
                        },
                    },
                    filter: (field, onChange, value) => {
                        return (
                            <EntitySelectAsync
                                label={null}
                                resourceId={field.params.resource}
                                instanceId={field.params.instanceId}
                                resourceLabel={field.params.displayField}
                                value={value}
                                onChange={onChange}
                                clearable={true}
                                multi={field.params.multi}
                                endpoints={field.params.endpoints}
                            />
                        );
                    },
                    bulk: true
                },
                followedScopes: {
                    title: 'Followed teams',
                    type: 'entityTree',
                    params: {
                        resource: 'scopes',
                        instanceId: 'scopes_complete',
                        childrenPropertyName: 'childScopes',
                        displayField: 'title',
                        links: true,
                        linkPath: (entity) => `/resource/scopes/${entity.id}/detail`,
                        multi: true,
                        endpoints: {
                            getAll: 'scopes/all-scopes/complete',
                        },
                    },
                    filter: (field, onChange, value) => {
                        return (
                            <EntitySelectAsync
                                label={null}
                                resourceId={field.params.resource}
                                instanceId={field.params.instanceId}
                                resourceLabel={field.params.displayField}
                                value={value}
                                onChange={onChange}
                                clearable={true}
                                multi={field.params.multi}
                                endpoints={field.params.endpoints}
                            />
                        );
                    },
                    editConditions: () => (userHasRoleMRM() || userHasRoleADMIN())
                },
                managedScopes:{
                  title: 'Manager of the teams',
                  type: 'entityTree',
                  params: {
                    resource: 'scopes',
                    instanceId: 'scopes_complete',
                    childrenPropertyName: 'childScopes',
                    displayField: 'title',
                    links: true,
                    linkPath: (entity) => `/resource/scopes/${entity.id}/detail`,
                    multi: true,
                    endpoints: {
                        getAll: 'scopes/all-scopes/complete',
                    },
                  },
                },
                establishment: {
                    title: 'Establishment',
                    type: 'entityAsync',
                    params: {
                        resource: 'establishments',
                        displayField: 'title',
                        endpoints: {
                            getAll: 'establishments/all',
                        },
                        sortField: 'title',
                    },
                    bulk: true,
                },
                roles: {
                    title: 'Roles',
                    type: 'mappedObject',
                    params: {
                        mapping: rolesMap,
                        multi: true
                    },
                    token: false,
                    bulk: true,
                    edit: (field, value, onChange, entity, routeParams) => {
                        if(userHasRoleADMIN()){
                            field.params.mapping = rolesMap;
                            return MappedStringProvider.getEdit(field, value, onChange, entity, routeParams);
                        }
                        field.params.mapping = restrictedRolesMap;
                        return MappedStringProvider.getEdit(field, value, onChange, entity, routeParams);
                    },
                    filter: (field, onChange, value) => {
                        field.params.mapping = rolesMap;
                        return MappedStringProvider.getFilter({field, value, onChange});
                    },
                },
                overrideIAM: {
                    type: 'bool',
                    title: 'Sigma/Thor override',
                    required: true,
                    bulk: true,
                    helperText: "Give access to the tool regardless of the user's status in Sigma or Thor",
                },
                emailNotifications: {
                    type: 'bool',
                    title: 'E-mail notifications',
                    display: (field, value, entity) => {
                        return (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!!value}
                                            name="EmailNotifications"
                                            color="primary"
                                            disabled={true}
                                        />
                                    }
                                    label="E-mail notifications"
                                />
                            </FormGroup>
                        );
                    },
                    editConditions: () => userHasRoleADMIN(),
                    edit: (field, value, onChange, _entity, _routeParams) => {
                        return (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!!value}
                                            onChange={(event, val) => onChange(val)}
                                            name="EmailNotifications"
                                            color="primary"
                                        />
                                    }
                                    label="E-mail notifications"
                                />
                            </FormGroup>
                        );
                    },
                    displayList: (field, value, entity, props, resource) => BoolProvider.getDisplayList(field, !!value, entity, props, resource),
                },
                changeLogsEntities: {
                    title: 'Audit trail',
                    display: (field, value, entity, props) => (
                        <ChangeLog
                            field={field}
                            values={value}
                            entity={entity}
                            entityResource={'users'}
                            props={props}
                        />
                    ),
                    displayList: () => null,
                },

                //Additional fields without relation with User
                changeLogComment: {
                    title: 'Justification of the data update',
                    type: 'textarea',
                    display: (field, value, entity, props) => null,
                    displayList: (field, value, entity, props) => null,
                    token: false,
                },
            })
            .setLayout({
                tabs: {
                    'Personal Information': {
                        rows: [
                            {
                                panels: {
                                    Identity: {
                                        cols: 6,
                                        fields: ['firstName', 'lastName', 'companyPosition', 'teams', 'followedScopes', 'managedScopes', 'establishment', 'manager'],
                                    },
                                    Contacts: {
                                        cols: 6,
                                        fields: ['email'],
                                    },
                                    Notifications: {
                                        cols: 6,
                                        fields: ['emailNotifications'],
                                    },
                                },
                            },
                        ],
                    },
                    Access: {
                        rows: [
                            {
                                panels: {
                                    Roles: {
                                        cols: 12,
                                        fields: ['roles', 'overrideIAM'],
                                    },
                                },
                            },
                        ],
                    },
                    'Audit trail': {
                        rows: [
                            {
                                panels: {
                                    'Audit trail': {
                                        cols: 12,
                                        fields: ['changeLogsEntities', 'changeLogComment'],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                fields: ['id', 'firstName', 'lastName', 'email', 'roles', 'companyPosition'],
                menuItem: { title: 'Users' },
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genInsertView({
                fields: [
                    'firstName',
                    'lastName',
                    'email',
                    'roles',
                    'overrideIAM',
                    'companyPosition',
                    'teams',
                    'followedScopes',
                    'managedScopes',
                    'establishment',
                ],
                menuItem: { title: 'Add' },
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'User';
                },
            })
            .genEditView({
                fields: [
                    'firstName',
                    'lastName',
                    'email',
                    'roles',
                    'overrideIAM',
                    'companyPosition',
                    'teams',
                    'followedScopes',
                    'managedScopes',
                    'establishment',
                    'emailNotifications',
                    'changeLogComment',
                ],
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genDetailView({
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .allowDelete({
                itemAccessCondition: (entity) => {
                    const isGranted = async () => {
                        let editGranted = await Http.get(`users/${entity.id}/is_granted/DELETE`, { cache: false });
                        return (
                            editGranted['hydra:member']['is_granted']
                        );
                    };
                    return entity && entity.id ? isGranted() : false;
                }
            })
            .addBulkAction(BulkEdit, {
                resource: users,
                icon: 'edit',
                itemAccessCondition: (_entity) => userHasRoleMRM() || userHasRoleADMIN(),
                fields: Object.fromEntries(
                    Object.entries(users.fields)
                        .filter(([k, v]) => {
                            return v.bulk && users.operations.edit.fields.includes(k);
                        })
                        .map(([k, v]) => ((v.resourceId = users.id), [k, v]))
                ),
            })
        ;
    }
}
