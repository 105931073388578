import React, {Component} from 'react';
import {observer} from "mobx-react";
import Alert from "../../Services/Alert";
import Http from "../../Services/Http";
import CircularProgress from '@material-ui/core/CircularProgress';
import {Checkbox, FormControlLabel, Grid, Icon,} from "@material-ui/core";
import {ActionButton} from '../Modal/ActionButton';
import Modal from "../../Services/Modal";
import { ButtonBar } from '../Modal/ButtonBar';
import { DatePicker } from '../Forms/DatePicker/DatePicker';
import { userHasRoleMRM } from '../../Store/ParameterStore';

class ExportAsync extends Component {

    constructor(props) {
        super(props);

        this.state = {
            progress: false,
            date: null,
            exportAtModelLevel: false,
            entities: [],
            running: undefined,
            exportAsk: false
        };

        this.today = new Date();

    }

    async componentDidMount() {
        let response = await Http.get(`export/${this.props.resource}/running`, {cache: false}) || {};
        if (response.status === 'OK') {
            this.setState({running: response.running, date: new Date(), progress: false});
        } else {
            console.error(`unexpected response from export/${this.props.resource}/running`, response);
        }
    }

    export() {
        this.setState({progress: true});
        const options = {}
        if (this.props.withDate) {
            options['date'] = this.state.date;
        }
        if (this.props.showModelLevelCheckbox) {
            options['exportAtModelLevel'] = this.state.exportAtModelLevel;
        }
        Http.post(`${this.props.resource}/export`, options).then((response) => {
            if (response.status === 'ERROR') {
                Alert.show({message: response.message, type: 'error'});
            } else if (response.status === 'PENDING') {
                Alert.show({message: response.message, type: 'success'});
            } else if (response.status === 'OK') {
                if (response.route && response.fileName) {
                    Http.openFile(response.route, response.fileName);
                } else if (response.documentId && response.documentName) {
                    Http.openEncryptedFile(
                        "/document/download/" +
                        response.documentId,
                        response.documentName
                    )
                } else if (response.message) {
                    Alert.show({message: response.message, type: 'success'});
                }
            } else {
                Alert.show({message: "File can't be downloaded", type: "error"});
            }
            Modal.close();
            this.setState({progress: false});
        });
    }

    render() {
        return (
            <Grid className={'export container'}>
                {this.state.progress &&
                    <div className={"export_progress_container"}>
                        <CircularProgress/>
                        <p>Please wait....</p>
                    </div>
                }

                <div className='export_progress_container'>
                    {this.state.running === undefined && <><CircularProgress/><p>Checking if an export is already
                        running</p></>}
                    {this.state.running === true &&
                        <p><Icon class="fas fa-exclamation-triangle"/> Export in progress. You will be notified when it
                            is available.</p>}
                </div>

                {!this.state.progress && this.state.running === false &&
                <>
                    <div>
                        {this.props.withDate && (
                            <DatePicker
                                label={'Export inventory as of'}
                                value={this.state.date}
                                onChange={(value) => this.setState({ date: value })}
                                minDate={new Date('2022-04-23')}
                                maxDate={new Date()}
                            />
                        )}
                        {userHasRoleMRM() &&
                            this.props.showModelLevelCheckbox &&
                            this.state.date instanceof Date &&
                            this.state.date.toDateString() === this.today.toDateString() && (
                                <FormControlLabel
                                    label="Export at model level"
                                    control={
                                        <Checkbox
                                            checked={this.state.exportAtModelLevel}
                                            onChange={(event) => {
                                                this.setState({ exportAtModelLevel: event.target.checked });
                                            }}
                                        />
                                    }
                                />
                            )}
                    </div>
                    <ButtonBar>
                        <ActionButton onClick={() => this.export()} loading={this.state.progress}>
                            Export
                        </ActionButton>
                    </ButtonBar>
                </>
                }
            </Grid>
        )
    }
}

export default observer(ExportAsync);
