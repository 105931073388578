import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import ParameterStore from '../../Store/ParameterStore';
import Modal from "../../Services/Modal";
import APIResourceStore from "../../Store/APIResourceStore";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {submitReview} from "../../Services/Actions/ReviewActions";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import Http from "../../Services/Http";
import { EditButton } from "../Buttons/EditButton";
import Alert from "../../Services/Alert";
import { ActionButton } from "../Modal/ActionButton";
import { isSeverityAllowed } from "../../Admin/FindingAdmin";
import {APIResource, CONTEXT_DETAIL, CONTEXT_EDIT} from "../../Services/APIResource/APIResource";
import {OpenModal} from "../Modal/OpenModal";
import Navigation from "../../Services/Navigation";
import ModalComplementary from "../../Services/ModalComplementary";
import { extractErrorFieldnames } from "../ModelCertificationCampaign/ModelList/index"; 
import { ModalContent } from "../Modal/ModalContent";
import { Typography } from "@material-ui/core";
import { ButtonBar } from "../Modal/ButtonBar";
import { ModalLoadingContent } from "../Modal/ModalLoadingContent";
import {FINDING_STATUS_DRAFT, FINDING_STATUS_OPEN, FINDING_STATUS_DELETED} from "../../Admin/FindingAdmin";
import DateFormatter from "../../Services/DateFormatter";

const DisplayMissingData = ({ errors, type, id }) => {
    if(errors[type] && errors[type][id] && errors[type][id].hasError){
        const fields = errors[type][id].missingProperties.reduce((fields, mp) => {
            return {
                ...fields,
                [mp]: true
            }
        }, {})
        const names = extractErrorFieldnames(fields, `${type}s`)
        // Cas particulier, ce champ n'existe pas :
        if (errors[type][id].missingProperties.includes("deadlineConsistency")) names.push("deadlineConsistency");
        return names.map(name => <div key={`error_field_${name}`}>{Object.prototype.hasOwnProperty.call(displayMissingDataMessageDict, name)?displayMissingDataMessageDict[name]:name}</div>)
    }
    return null
}

const displayMissingDataMessageDict = {
  "Findings":"Association to a finding",
  "deadlineConsistency": "Deadline consistency",
}

const findingRequiredFields = [
    "title",
    "severity",
    "issuer",
    "models",
    "status",
    "findingMraRelations",
    "weaknessesDescription",
    "impactsDescription"
];

const noticeRequiredFields = [
    "title",
    "noticeDescription",
    "noticeValidators",
    "actionPlan",
    "noticeOwners",
    "deadline",
    "findings",
];

const requiredFields = {
    finding: findingRequiredFields,
    notice: noticeRequiredFields,
}

const fieldsAreRelational = [
    "models",
    "findingMraRelations",
    "noticeValidators",
    "noticeOwners",
    "findings"
];

/** Renvoie si on doit exclure l'item de la liste des findings ou notices à valider 
 *
 * @param {{status: string}} item
 * @param {'notice'|'finding'} type 
 * @param {boolean} fromNotice
 */
export const isItemExcludedFromValidation = (item, type, fromNotice) => {
    return (fromNotice === true && type === 'finding' && item.status !== ParameterStore(FINDING_STATUS_DRAFT) && item.status !== ParameterStore(FINDING_STATUS_OPEN))
    || (!fromNotice && type === 'finding' && item.status !== ParameterStore(FINDING_STATUS_DRAFT))
    || (type === 'notice' && item.status !== ParameterStore('NOTICE_STATUS_DRAFT'));
}

/**
 * 
 * @param {Object} props 
 * @param {import('../../Services/APIResource/APIResource').APIResource} props.resource
 * @param {import('../../Services/APIResource/APIResource').APIResource} props.resourceNotice
 * @param {import('../../Services/APIResource/APIResource').APIResource} props.resourceFinding
 * @returns 
 */
const ValidateFindingNotice = (props) => {

    const {resource, resourceFinding, resourceNotice, confirmationMessage, currentStatus, nextStatus, resourceComponent, tooltip, fromModel, fromNotice} = props;
    const [entity, setEntity] = useState(props.entity);
    const [findings, setFindings] = useState(props.findings);
    const [notices, setNotices] = useState(props.notices);
    const [processing, setProcessing] = useState(false);
    const [errors, setErrors] = useState([]);
    const [globalError, setGlobalError] = useState(undefined);
    const [checkingIssuanceDate, setCheckingIssuanceDate] = useState(true);
    const [issuanceDateRequired, setIssuanceDateRequired] = useState(false);

    useEffect(() => {
        if (!processing) {
            findErrors();
        }
    }, []);

    useEffect(() => {
        setEntity(props.entity);
    }, [props.entity]);

    useEffect(() => {
        setFindings(props.findings);
    }, [props.findings]);

    useEffect(() => {
        setNotices(props.notices);
    }, [props.notices]);

    const isMissingInItem = (property, item) =>
        (Array.isArray(item[property]) && item[property].length === 0) ||
        (!Array.isArray(item[property]) && !item[property]);

    const checkRequirements = async (findings, notices) => {
        const requirementsErrors = [];
        const promises = [
            ...findings.map((finding) => getItemFromResource(finding, 'finding')), 
            ...notices.map((notice) => getItemFromResource(notice, 'notice')),
        ];
    
        const items = await Promise.all(promises);
        items.forEach(({item, type}) => {
            if(!requirementsErrors[type]){
                requirementsErrors[type] = [];
            }
            requirementsErrors[type][item.id] = {hasError: false, missingProperties: [], onlyRelational: null};
            if(
                (type === 'finding' && item.status !== ParameterStore(FINDING_STATUS_DELETED))
                || (type === 'notice' && item.status === ParameterStore('NOTICE_STATUS_DRAFT'))
            ){
                // Si le Finding est hors revue alors issuanceDate est obligatoire
                if (type === 'finding' && !item.hasOriginReview) {
                    requiredFields[type].push("issuanceDate");
                    setIssuanceDateRequired(true);
                }
                requirementsErrors[type][item.id].hasError = requiredFields[type].some((property) => isMissingInItem(property, item));
                requirementsErrors[type][item.id].missingProperties = requiredFields[type].filter(p => isMissingInItem(p, item));
                // Cas particulier pour les notices :
                if (
                    type === 'notice'
                    && item.issuanceDate
                    && item.deadline
                    && DateFormatter.toOnlyDateInUTC(item.issuanceDate) > DateFormatter.toOnlyDateInUTC(item.deadline)
                ) {
                    requirementsErrors[type][item.id].hasError = true;
                    requirementsErrors[type][item.id].missingProperties.push("deadlineConsistency");
                }
                let missingRelationalProperties = requirementsErrors[type][item.id].missingProperties.filter((missingProperty) => fieldsAreRelational.includes(missingProperty));
                requirementsErrors[type][item.id].onlyRelational = missingRelationalProperties.length > 0
                    && requirementsErrors[type][item.id].missingProperties.length > 0
                    && requirementsErrors[type][item.id].missingProperties.length === missingRelationalProperties.length;
            }

            // Cas particulier pour les findings de sévérité NA :
            if (type === 'finding' && item.status !== ParameterStore(FINDING_STATUS_DELETED)) {
                if (!isSeverityAllowed(item)) {
                    requirementsErrors[type][item.id].hasError = true;
                    requirementsErrors[type][item.id].missingProperties.push('severity');
                }
            }
        })
    
        let error = false;
        for (var key in requirementsErrors) {
            for (var key2 in requirementsErrors[key]) {
                if(requirementsErrors[key][key2].hasError){
                    error = true;
                }
            }
        }
    
        return {requirementsErrors, error};   
    }
    
    const findErrors = async (callback = null) => {
        setProcessing(true);

        if (fromModel) {
            for (let findingOrNotice of [...findings, ...notices]) {
                if (findingOrNotice.reviews.length === 0) continue;

                for (let reviewIri of findingOrNotice.reviews) {                
                    let review = await APIResourceStore.resources.reviews.getItemFromResourcePath(reviewIri, true);
                    if(review && !review.reviewCommitteeMostRecent) {
                        Modal.open({
                            title: "Add a committee",
                            content: <ModalContent>
                                <Typography component={"p"}>Please add a committee to the review before confirming the notices and findings</Typography>
                                <ButtonBar>
                                    <ActionButton
                                        onClick={() => {
                                            Navigation.router.history.push(`/resource/reviews/${review.id}/detail?tab=Thread`);
                                            Modal.close();
                                        }}
                                    >
                                        Add a committee to the review
                                    </ActionButton>
                                </ButtonBar>
                            </ModalContent>
                        })
                        return;
                    }
                }
            }
        }
        setCheckingIssuanceDate(false);

        try {

            let findingsToCheck = findings.filter(item => !isItemExcludedFromValidation(item, 'finding', fromNotice))
            let noticesToCheck = notices.filter(item => !isItemExcludedFromValidation(item, 'notice', fromNotice))

            const {requirementsErrors, error } = await checkRequirements(findingsToCheck, noticesToCheck);
            setErrors(requirementsErrors);
            setGlobalError(error);
            if(callback){
                callback();
            }
        } catch (err) {
            Alert.show({
                message: "Error during loading findings and notices.",
                type: "error"
            })
            console.log('[findErrors]', err)
        } finally {
            setProcessing(false);
        }
    }
    
    const getItemFromResource = (item, type) => {
        return new Promise(function (resolve, _reject) {
            APIResourceStore.resources['resource_' + type + 's_reviews'].apiGetOne(item['id'], true).then((item) => {
                resolve({item: item, type: type});
            });
        });
    }
    
    const getLinkForItem = (item, type, errors = []) => {
        let routeParams = {
            highlight: errors?.[type]?.[item.id]?.missingProperties.join('-') || '',
        };
        if (type === 'finding') {
            routeParams['notice-id'] = notices?.[0]?.id;
        } else if (type === 'notice' && errors?.notice?.[item.id]?.missingProperties.includes('findings')) {
            routeParams['missing-finding'] = 'true';
            routeParams['tab'] = 'Notices';
        }
        if (type === 'notice' && errors?.notice?.[item.id]?.missingProperties.includes('deadlineConsistency')) {
            routeParams.highlight += `${routeParams.highlight ? '-': ''}deadline`;
            routeParams['tab'] = 'Notices';
        }

        if(
            Array.isArray(errors)
            && errors[type]
            && errors[type][item.id]
            && errors[type][item.id].onlyRelational
            && type === 'notice'
            && errors?.notice?.[item.id]?.missingProperties.includes('findings')
        ){
            return <EditButton onClick={() => {
                Navigation.router.history.push('/blank');
                setTimeout(() => {
                    Modal.close();
                    Navigation.router.history.push(`/resource/${resource.instanceId}/${entity.id}/detail?missing-finding=true&tab=Notices`);
                    if(resourceComponent.getComponentName() === 'ResourceEdit'){
                        resourceComponent.setState({currentTab: resource.getTabId(CONTEXT_DETAIL, 'Notices')})
                    }
                },10)
            }}/>;
        }

        return <OpenModal
            instanceId={type + 's'}
            id={item.id}
            modalTitle={item.title}
            context={CONTEXT_EDIT}
            routeParams={routeParams}
            newLayer={true}
            allowStayInModal={true}
            postSaveRedirectAction={() => {
                entity.fromNotice = fromNotice ? notices[0]['@id'] : null;
                if(!entity.fromNotice) {
                    resource.getItemFromResourcePath(`/api/reviews/${entity.id}`, true).then((e) => {
                        if (resourceComponent) {
                            if (resourceComponent.getComponentName() === 'ResourceEdit') {
                                resourceComponent.setState({entity: e});
                                resourceComponent.forceUpdate();
                            } else {
                                resourceComponent.entity = e;
                                resourceComponent.forceUpdate();
                            }
                        }
                        setEntity(e);
                        setFindings(e.findingsEntities);
                        setNotices(e.noticesEntities);
                        findErrors();
                    })
                }else{
                    resourceNotice.getItemFromResourcePath(`/api/notices/${entity.fromNotice}`, true).then((e) => {
                        if(resourceComponent && resourceComponent.getComponentName() === 'ResourceDetail'){
                            resourceComponent.entity = e;
                            resourceComponent.forceUpdate();
                            setEntity(e);
                            setFindings(e.findingsEntities);
                            setNotices([e]);
                            findErrors();
                        }
                    });
                }
            }}
        />;
    }


    const displayList = (source, type) => {
        let rows = [];
        source.forEach(
            (item, index) => {
                item.type = type;
                if (isItemExcludedFromValidation(item, type, fromNotice)) {
                    return;
                }
                rows.push(
                    <TableRow
                        key={'list-' + type + '-' + index}
                    >
                        <TableCell>
                            {
                                errors[item.type] && errors[item.type][item['id']].hasError === true
                                ? <CloseIcon className="text-danger" />
                                : null
                            }
                            {
                                errors[item.type] && errors[item.type][item['id']].hasError === false
                                ? <CheckIcon className="text-success" />
                                : null
                            }
                            {
                                !errors[item.type]
                                || (errors[item.type] && typeof errors[item.type][item['id']] === 'undefined')
                                ? <HourglassEmptyIcon className="text-valid" />
                                : null
                            }
                        </TableCell>
                        <TableCell>
                            {item.title}
                        </TableCell>
                        <TableCell>
                            {item.severityString ?? (item.severity ? APIResourceStore.resources.parameters.getObservableItemByPath(item.severity)?.label : '')}
                        </TableCell>
                        <TableCell>
                            {item.statusString ?? (item.status ? APIResourceStore.resources.parameters.getObservableItemByPath(item.status)?.label : '')}
                        </TableCell>
                        <TableCell>
                            <DisplayMissingData errors={errors} type={item.type} id={item['id']} />
                        </TableCell>
                        <TableCell>
                            {getLinkForItem(item, type, errors)}
                        </TableCell>
                    </TableRow>
                )
            }
        );
        return <Table
            className={'table-display small'}
            size={'small'}
        >
            <TableHead>
                <TableRow>
                    <TableCell key={'th_' + type + '_0'}>

                    </TableCell>
                    <TableCell key={'th_' + type + '_1'}>
                        Title
                    </TableCell>
                    <TableCell key={'th_' + type + '_2'}>
                        Severity
                    </TableCell>
                    <TableCell key={'th_' + type + '_3'}>
                        Status
                    </TableCell>
                    <TableCell key={'th_' + type + '_4'}>
                        Missing Data
                    </TableCell>
                    <TableCell key={'th_' + type + '_5'}>
                        Actions
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows}
            </TableBody>
        </Table>
    }

    const switchFindingAndNoticeStatus = (callback) => {
        entity.fromNotice = fromNotice ? notices[0]['@id'] : null;
        Http.post(`models/${entity.id}/confirm-finding-notice`, entity).then((_response) => {
            if(confirmationMessage){
                Alert.show({
                    message: confirmationMessage,
                    type: "success"
                });
            }
            resource.getItemFromResourcePath(`api/models/${entity.id}`, true)
            if(resourceNotice && notices.length > 0){
                notices.forEach((item) => {
                    let apiPath = item['@id'] !== undefined ? item['@id'] : (typeof item.id !== undefined ? `/api/notices/${item.id}` : item);
                    resourceNotice.getItemFromResourcePath(apiPath, true);
                });
            }
            if(resourceFinding && findings.length > 0){
                findings.forEach((item) => {
                    let apiPath = item['@id'] !== undefined ? item['@id'] : (typeof item.id !== undefined ? `/api/finding/${item.id}`  : item);
                    resourceFinding.getItemFromResourcePath(apiPath, true);
                });
            }
            resourceNotice.getItemFromResourcePath(`/api/notices/${entity.fromNotice}`, true).then((e) => {
                if(resourceComponent && resourceComponent.getComponentName() === 'ResourceDetail'){
                    resourceComponent.entity = e;
                    resourceComponent.forceUpdate();

                    //Quand la modale est ouverte depuis la review -> modal detail notice -> Validate Finding Notice, il faut pouvoir actualiser la review
                    if(resourceComponent.props.inModal && resourceComponent.props.openModalComponent){
                        let parentResource = null;
                        if (resourceComponent.props.openModalComponent.props.parentInstanceId){
                            parentResource = new APIResource({
                                instanceId: resourceComponent.props.openModalComponent.props.parentInstanceId
                            });
                        }
                        if (parentResource) {
                            parentResource.apiGetOne(resourceComponent.props.openModalComponent.props.parentId, true).then((e) => {
                                if(resourceComponent.props.openModalComponent.props.parentResourceComponent){
                                    resourceComponent.props.openModalComponent.props.parentResourceComponent.entity = e;
                                    resourceComponent.props.openModalComponent.props.parentResourceComponent.forceUpdate();
                                }
                                if(resourceComponent.props.openModalComponent.props.newLayer) {
                                    ModalComplementary.close()
                                }else{
                                    Modal.close()
                                }
                            });
                        }
                    }
                }
                callback();
            });

        });
    }

    return (
        checkingIssuanceDate ?
        <ModalLoadingContent />
        : <Grid container spacing={2} className="container">
            <Grid item xs={12} style={{fontSize:'15px'}}>
                {fromModel ? 'All the mandatory fields must be populated in order to confirm the creation of the notice(s) and associated finding(s).'
                    : 'Closing the current review will validate the following findings and notices, do you confirm ? All the mandatory fields must be filled to proceed.'}
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} className="container">
                    <Grid item xs={12} md={12} lg={6} style={{maxWidth:'unset'}}>
                        <div style={{minHeight:'5em'}}>
                          <strong>Required fields for Findings are :</strong>
                          <br />Title,
                          {issuanceDateRequired ? "Committee Issuance date, ": ""}
                          Severity,
                          MRA dimension(s),
                          Weaknesses,
                          Impacts
                        </div>
                        {displayList(findings, 'finding')}
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} style={{maxWidth:'unset'}}>
                        <div style={{minHeight:'5em'}}>
                          <strong>Required fields for Notices are :</strong>
                          <br />Title,
                          Validator in charge of the follow-up,
                          Notice Owner,
                          Notice description,
                          Action Plan,
                          Deadline,
                          Association to at least one finding
                        </div>
                        {displayList(notices, 'notice')}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between"
            }}>
                <Grid container spacing={2} className="container">
                    <Grid item xs={6}>
                        <ActionButton 
                            className={"" /* on écrase */} 
                            onClick={() => {
                                entity.reviewStatus = currentStatus;
                                Modal.close();
                            }}
                        >
                            Cancel
                        </ActionButton>
                    </Grid>
                    <Grid item xs={6} style={{textAlign:'right'}}>
                        <ActionButton
                            loading={processing !== undefined ? processing : false}
                            className={"" /* on écrase */} 
                            onClick={() => findErrors()}
                        >
                            Check{processing && 'ing'} requirements
                        </ActionButton>
                        <ActionButton
                            disabled={globalError || globalError === undefined}
                            onClick={() => {
                                findErrors(() => {
                                    if(fromModel){
                                        switchFindingAndNoticeStatus(() => {
                                            Modal.close();
                                            resourceComponent.refreshParent();
                                            resourceComponent.forceUpdate();
                                        });
                                    }else{
                                        entity.validate = true;
                                        submitReview(
                                            resource,
                                            entity,
                                            currentStatus,
                                            nextStatus,
                                            resourceComponent,
                                            tooltip
                                        );
                                        Modal.close();
                                    }
                                })
                            }}
                        >
                            {fromModel ? 'Validate' : 'Validate and close'}
                        </ActionButton>
                        
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
ValidateFindingNotice.propTypes = {
    findings: PropTypes.array, 
    notices: PropTypes.array,
    /** Resource des Reviews ou des Models si fromModel */ 
    resource: PropTypes.any, 
    /** Resource des Notices */ 
    resourceNotice: PropTypes.any, 
    /** Resource des Findings */ 
    resourceFinding: PropTypes.any, 
    /** Objet Review ou Model si fromModel */
    entity: PropTypes.object, 
    /** @todo Probablement une IRI de Parameter */
    currentStatus: PropTypes.any, 
    /** @todo Probablement une IRI de Parameter */
    nextStatus: PropTypes.any, 
    /** Composant (functionnel ou objet) : resourceEdit ou resourceDetail ... */
    resourceComponent: PropTypes.any,
    tooltip: PropTypes.string, 
    fromModel: PropTypes.bool,
    fromNotice: PropTypes.bool,
    /** Message de succès de confirmation finding-notice */
    confirmationMessage: PropTypes.string,
};

export default ValidateFindingNotice;
