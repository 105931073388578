import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from '../../Services/Alert';
import Http from '../../Services/Http';
import Grid from '@material-ui/core/Grid';
import Modal from '../../Services/Modal';
import { ButtonBar } from '../Modal/ButtonBar';
import { ActionButton } from '../Modal/ActionButton';
import {DatePicker} from "../Forms/DatePicker/DatePicker";
import {userHasRoleMRM} from "../../Store/ParameterStore";
import { Checkbox, FormControlLabel } from '@material-ui/core';
import {APIResource} from "../../Services/APIResource/APIResource";
import APIResourceStore from "../../Store/APIResourceStore";


export class DownloadDocument {
    getId() {
        return 'DownloadDocument';
    }

    getLabel() {
        return 'Download documents';
    }

    setParameters(params) {
        this.parameters = params;
    }

    /**
     * Déclenché lors des bulkActions
     * 
     * @param {array} ids
     */
    run(ids) {
        this.ids = ids;

        Http.openEncryptedFiles(this.ids);
    }
}