import React from "react";
import { APIResource } from "../Services/APIResource/APIResource";
import { TableDisplay } from "../Components/Display/TableDisplay/TableDisplay";
import Button from "@material-ui/core/Button";
import {
    PARAMETER_TYPE_WORKFLOW_TARGET_ENTITY,
    PARAMETER_TYPE_WORKFLOW_EVENT,
    PARAMETER_TYPE_WORKFLOW_PROCESS
} from "../Admin/ParameterAdmin";
import { WorkflowConditions } from "../Components/Workflow/WorkflowConditions";
import APIResourceStore from "../Store/APIResourceStore";
import { ParameterSelect } from "../Components/Forms/ParameterSelect/ParameterSelect";
import {ROLE} from "../Services/User/User";

export const ApiResourceDefaultParams = {
    id: 'workflows',
    name: 'Workflows',
    icon: 'brain',
    fieldForTitle: 'title'
};

export default class WorkflowAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        // Models entities
        let workflows = new APIResource(ApiResourceDefaultParams);

        workflows
            .setFields({
                title: { title: "Title", type: "text", maxLength:255, required: true, width: 600 },
                process: {
                    title: "Process",
                    type: "parameter",
                    params: { type: PARAMETER_TYPE_WORKFLOW_PROCESS, multi: false },
                    required: false,
                    edit: (field, value, onChange, entity, routeParams) => {
                        if (routeParams.process && !entity.process) {
                            entity.process = '/api/parameters/' + routeParams.process;
                        }
                        return (
                            <ParameterSelect
                                field={field}
                                value={entity.process}
                                onChange={onChange}
                                issueButton={true}
                                entity={entity}
                                clearable={true}
                                multi={false}
                            />
                        );
                    },
                },
                priority: { title: "Priority", type: "int", required: true },
                targetEntity: {
                    title: "Target entity",
                    type: "parameter",
                    params: {
                        type: PARAMETER_TYPE_WORKFLOW_TARGET_ENTITY,
                        multi: false,
                    },
                    required: true,
                },
                event: {
                    title: "Event",
                    type: "parameter",
                    params: { type: PARAMETER_TYPE_WORKFLOW_EVENT, multi: false },
                    required: true,
                },
                conditions: {
                    title: "Conditions",
                    edit: (field, value, onChange, entity, routeParams) => {
                        return (
                            <WorkflowConditions
                                field={field}
                                value={value}
                                onChange={onChange}
                                entity={entity}
                            />
                        );
                    },
                    display: (field, value, entity, props) => {
                        return (
                            <WorkflowConditions
                                field={field}
                                value={value}
                                onChange={() => null}
                                entity={entity}
                                readOnly={true}
                            />
                        );
                    },
                    displayList: () => null,
                    filter: () => null,
                    required: false,
                    token: false,
                },

                actionsEntities: {
                    title: "Actions",
                    display: (field, value, entity, props) => (
                        <TableDisplay
                            rows={value}
                            cols={[
                                { label: "Title", field: "title" },
                                { label: "Type", field: "typeString" },
                                { label: "Priority", field: "priority" },
                            ]}
                            actions={(value) => {
                                return (
                                    <div>
                                        <Button
                                            style={styles.mrDetailBtn}
                                            variant="contained"
                                            color="primary"
                                            dense="true"
                                            className="button-table"
                                            onClick={() =>
                                                APIResourceStore.resources.actions.openModal({
                                                    context: "edit",
                                                    style: {
                                                        maxHeight: 'calc(90vh - 130px)',
                                                        paddingBottom: 5,
                                                        paddingTop: 15,
                                                        overflowX: 'hidden',
                                                    }
                                                })(
                                                    {
                                                        workflowId: entity.id,
                                                        targetEntity: entity.targetEntity,
                                                        id: value.id,
                                                    },
                                                    () => {
                                                        workflows.apiGetOne(
                                                            entity.id,
                                                            true
                                                        );
                                                    }
                                                )
                                            }
                                        >
                                            Edit
                        </Button>
                                        <Button
                                            style={styles.mrDetailBtn}
                                            variant="contained"
                                            dense="true"
                                            className="disagree button-table"
                                            onClick={() =>
                                                APIResourceStore.resources.actions.deleteItemWithModalConfirmation(value)
                                                    .then(() =>
                                                        workflows.apiGetOne(
                                                            entity.id,
                                                            true
                                                        )
                                                    )
                                                    .then(() => {
                                                        if (props && props.refresh) {
                                                            props.refresh();
                                                        }
                                                    })
                                            }
                                        >
                                            Delete
                        </Button>
                                    </div>
                                );
                            }}
                            buttons={[
                                {
                                    label: "New action",
                                    //to: '/resource/actions/add/' + entity.id, icon: 'fa-plus',
                                    onClick: () =>
                                        APIResourceStore.resources.actions.openModal({
                                            context: "add",
                                            style: {
                                                maxHeight: 'calc(90vh - 130px)',
                                                paddingBottom: 5,
                                                paddingTop: 15,
                                                overflowX: 'hidden',
                                            }
                                        })(
                                            {
                                                workflowId: entity.id,
                                                targetEntity: entity.targetEntity,
                                            },
                                            () => {
                                                setTimeout(() => {

                                                    workflows.apiGetOne(
                                                        entity.id,
                                                        true
                                                    ).then(() => {
                                                        if (props && props.refresh) {
                                                            props.refresh();
                                                        }
                                                    });
                                                }, 3000);
                                            }
                                        ),
                                },
                            ]}
                        />
                    ),
                    edit: function (field, value, onChange, entity, props) {
                        return this.display(field, value, entity, props);
                    },
                    displayList: () => null,
                },
            })
            .setValidation((entity) => {
                return true;
            })
            .setLayout({
                tabs: {
                    "Workflow description": {
                        rows: [
                            {
                                panels: {
                                    Trigger: {
                                        cols: 3,
                                        fields: [
                                            "title",
                                            "process",
                                            "priority",
                                            "targetEntity",
                                            "event",
                                        ],
                                    },
                                    Conditions: {
                                        cols: 9,
                                        fields: ["conditions"],
                                    },
                                },
                            },
                            {
                                panels: {
                                    Actions: {
                                        cols: 12,
                                        fields: ["actionsEntities"],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                fields: ["title", "process", "priority", "targetEntity", "event"],
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genInsertView({
                menuItem: { title: "Add" },
                fields: [
                    "title",
                    "process",
                    "priority",
                    "targetEntity",
                    "event",
                    "conditions",
                ],
                additionalRoutes: ["/resource/workflows/add/:process"],
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'Workflow';
                },
            })
            .genEditView({
                fields: [
                    "title",
                    "process",
                    "priority",
                    "targetEntity",
                    "event",
                    "conditions",
                    "actionsEntities",
                ],
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genDetailView({ routeAccessControl: [ROLE.MRM, ROLE.ADMIN] })
            .allowDelete();
    }
}

const styles = {
    mrDetailBtn: {
        margin: 1
    },
    actionLink: {
        marginLeft: 5
    }
};
