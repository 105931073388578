import React from 'react';
import {DisplayTextField} from "../../../Components/Display/DisplayTextField/DisplayTextField";
import {Select} from "../../../Components/Forms/Select/Select";
import {MappedValueDisplay} from "../../../Components/Display/MappedValueDisplay/MappedValueDisplay";

if (typeof (String.prototype.localeCompare) === 'undefined') {
    String.prototype.localeCompare = function (str, locale, options) {
        return ((this == str) ? 0 : ((this > str) ? 1 : -1));
    };
}

class MappedStringProvider {
    getDisplay(field, value, entity, props, resource) {
        return <MappedValueDisplay
            {...props}
            fieldName={field.title}
            value={value}
            map={field.params.mapping}
            showFieldset={true}
        />;
    }

    getDisplayList(field, value, entity, props, resource) {
        if (Array.isArray(value)) {
            let values = [];
            value.forEach((val, index) => {
                values.push(<div key={index}>{field.params.mapping[val]}</div>);
            });
            return values;
        }
        return value !== '' && value !== null ? field.params.mapping[value] : '';
    }

    getEdit(field, value, onChange, entity, routeParams, loading = false) {
        let preparedValue = [];
        if (value !== null && value !== undefined) {
            if (!Array.isArray(value)) {
                value = [value];
            }
            preparedValue = value.map(v => ({
                value: v,
                label: field.params.mapping[v]
            }));
        }
        return (
            <div
                className={field.errorHelperText ? 'field-error-control' : ''}
            >
                <Select
                    label={field.title}
                    options={Object.keys(field.params.mapping)
                        .map(key => {
                            return {
                                value: key,
                                label: field.params.mapping[key]
                            };
                        })
                        .sort((a, b) => (a.label < b.label ? -1 : +(a.label > b.label)))}
                    value={field.params.multi ? preparedValue : preparedValue[0]}
                    onChange={item => {
                        onChange(
                            field.params.multi ? (item ? item.map((i) => i.value) : []) : item ? item.value : null
                        );
                    }}
                    isMulti={field.params.multi}
                    required={field.requiredComputed}
                    error={!!field.errorHelperText}
                    className={"entity-select "}
                    helperText={field.errorHelperText}
                    //disabled={loading}
                />
            </div>
        );
    }

  getFilter(options = {}) {
    let { field, value, onChange } = options;
      value = Array.isArray(value) ? value.map((val) => {
          return {
              value: val,
              label: field.params.mapping[val]
          }
      }) : value
    return (
      <Select
        options={Object.keys(field.params.mapping).map((val, key) => {
          return {
            value: val,
            label: field.params.mapping[val]
          }
        })}
        onChange={onChange}
        isClearable={value}
        value={value}
        isMulti={field.params.multi}
      />
    );
  }
}

export default new MappedStringProvider();
