import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Alert from '../../../Services/Alert';
import ParameterStore from "../../../Store/ParameterStore";
import {PARAMETER_TYPE_MODEL_BACKGROUND_TAG} from "../../../Admin/ParameterAdmin";
import Button from "@material-ui/core/Button";
import Modal from "../../../Services/Modal";
import Icon from "@material-ui/core/Icon";
import {ParameterSelect} from "../../Forms/ParameterSelect/ParameterSelect";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {APIResource} from "../../../Services/APIResource/APIResource";
import {DisplayTextField} from "../../Display/DisplayTextField/DisplayTextField";
import { ModelQuickSelect } from '../ModelQuickSelect';
import { ButtonBar } from '../../Modal/ButtonBar';
import { ActionButton } from '../../Modal/ActionButton';

/** @type {Object.<string, import('../../../Services/APIResource/APIResource').APIResourceField>} */
const fields = {
    foregroundModel: {
        title: 'Foreground model',
        type: 'model',
        params: {
            resource: 'models',
            instanceId: 'allModels',
            displayField: 'toString',
            multi: false,
            links: false,
            endpoints: {
                getAll: 'models/all-models',
            },
        },
        required: true,
        requiredComputed: true,
    },
    backgroundModel: {
        title: 'Background model',
        type: 'model',
        params: {
            resource: 'models',
            instanceId: 'allModels',
            displayField: 'toString',
            multi: false,
            links: false,
            endpoints: {
                getAll: 'models/all-models',
            },
            /**
             * Tous les status sont autorisés pour les backgroundModels
             * @see https://app.asana.com/0/1173729351441775/1208565978355117/f 
             */
            limitStatuses: () => [
                'MODEL_STATUS_DRAFT',
                'MODEL_STATUS_UNDER_DECLARATION',
                'MODEL_STATUS_ACTIVE',
                'MODEL_STATUS_RETIRED',
                'MODEL_STATUS_DELETED',
            ].map(ParameterStore),
        },
        required: true,
        requiredComputed: true,
    },
    tag: {
        title: 'Tag',
        type: 'parameter',
        params: { type: PARAMETER_TYPE_MODEL_BACKGROUND_TAG, multi: false },
        required: true,
        requiredComputed: true,
    },
    comment: {
        title: 'Comment',
        type: 'textarea',
        required: true,
        displayCondition: (entity) => entity.tag === ParameterStore('MODEL_BACKGROUND_TAG_OTHER'),
    },
};

class BackgroundModel extends Component {

    constructor(props) {
        super(props);
        this.openBackgroundModal = this.openBackgroundModal.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.resource = new APIResource({ id: this.props.field.params.resource, instanceId: 'background_model_' + this.props.field.params.resource });
        this.state = {
          value: '',
        };
    }

    componentDidMount() {
        this.updateValue(this.props.value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.value !== prevProps.value) || (this.props.entity !== prevProps.entity)) {
            this.updateValue(this.props.value);
        }
    }

    genBackgroundModelForm(){
        return <BackgroundModelForm
            resource={this.resource}
            entity={this.props.entity}
            field={this.props.field}
            onValidate={(entity) => {
                this.updateValue(entity[this.props.field.id]);
                this.props.onChange(this.props.entity[this.props.field.id]);
            }}
            inverseRelation={this.props.inverseRelation}
            modalRef={this.props.modalRef}
        />
    }

    openBackgroundModal(){
        const modal = this.props.modalRef || Modal;
        return modal.open({
            title: `Select ${this.props.inverseRelation ? 'an offspring' : 'a background'} model`,
            content: this.genBackgroundModelForm(),
        });
    };

    removeItem(i){
        this.props.entity[this.props.field.id].splice(i, 1);
        this.updateValue(this.props.entity[this.props.field.id]);
        this.props.onChange(this.props.entity[this.props.field.id]);
    }

    updateValue(value){
        if (Array.isArray(value) && value.length > 0 && typeof value[0] != 'object') {
            let promises = [];
            let values = [];
            value.forEach((val, index) => {
                const id = val && val.indexOf('/api') > -1 ? val.split('/').pop() : val;
                let promise = this.resource.getItemFromResourcePath(id).then((entity) => {
                    if (!entity) {
                        return;
                    }
                    let valLabel = entity[this.props.field.params.displayField] ? entity[this.props.field.params.displayField] : '-';
                    values.push(
                        <div key={index}>
                            {valLabel}
                            <Button
                                size="small"
                                style={{color: 'hsl(0,0%,80%)', marginLeft: 10, minWidth: 30}}
                                onClick={() => this.removeItem(index)}
                            >
                                <Icon className={"fa fa-times"}></Icon>
                            </Button>
                        </div>
                    );
                });
                promises.push(promise);
            });
            Promise.all(promises).then(() => {
                this.setState({value: <div>{values}</div>});
            });
        } else {
            this.setState({value: <div></div>});
        }
    }

    render() {
        return (
            <div
                className={
                    'select-component background-model'
                }
            >
                {this.props.field.label ? (
                    <label>
                        {this.props.field.label}
                    </label>
                ) : null}
                <div className={'with-edit-button'}>
                    <DisplayTextField
                        fieldName={this.props.field.title}
                        value={this.state.value}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.openBackgroundModal}
                    >
                        <Icon className={"fa fa-plus"}></Icon>
                    </Button>
                </div>
            </div>
        );
    }
}

export default observer(BackgroundModel);

class BackgroundModelForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modelRelation: null, // props.entity?.backgroundModelRelations, éventuellement si on ne veut plus se contenter de "création" systématique
            tag: null,
            comment: null,
        };
        this.validate = this.validate.bind(this);
    }

    validate() {
        let entity = {};
        let self = this;
        if (!self.props.inverseRelation) {
            entity.foregroundModel = self.props.entity['@id'];
            entity.backgroundModel = self.state.modelRelation;
            
        } else {
            entity.foregroundModel = self.state.modelRelation;
            entity.backgroundModel = self.props.entity['@id'];

        }
        entity.tag = self.state.tag;
        entity.comment = self.state.comment; 
        if( self.state.modelRelation === null || self.state.tag === null ){
            Alert.show({message: 'Please fill all fields', type: 'error'});
            return;
        }

        self.setState({ apiUpdateInProgress: true });
        self.props.resource.apiPost(entity).then((item) => {
            self.props.entity[self.props.field.id].push(item['@id']);
            self.props.onValidate(self.props.entity);
            Alert.show({ message: `${self.props.inverseRelation ? 'Foreground' : 'Background'} successfully added.`, type: "success" });
        })
        .finally(() => {
            self.props.modalRef ? self.props.modalRef.close() : Modal.close();
        });
    }

    render() {
        return (
            <div className={"new-background-relation-form"}>

                <ModelQuickSelect
                    label={fields['backgroundModel'].title}
                    resourceId={fields['backgroundModel'].params.resource}
                    resourceLabel={fields['backgroundModel'].params.displayField}
                    values={[]} // Création d'une relation
                    onChange={(item) => this.setState({modelRelation: item})}
                    clearable={false}
                    multi={false}
                    context={"add"} // Systématiquement en mode CREATION d'UNE relation
                    className={"field-error-control"}
                    withModal={false}
                    limitStatuses={fields['backgroundModel'].params.limitStatuses()}
                />

                <ParameterSelect
                    label={fields['tag'].title}
                    field={fields['tag']}
                    value={this.state.tag}
                    issueButton={true}
                    clearable={false}
                    multi={false}
                    required={true}
                    onChange={(item) => this.setState({tag: item})}
                />
                {
                    fields['comment'].displayCondition(this.state)
                    ?
                        <div className={"select-component"}>
                            <label>
                                {fields['comment'].title}
                                {fields['comment'].required ? " *" : ""}
                            </label>
                            <TextareaAutosize
                                value={this.state.comment ? this.state.comment : ""}
                                onChange={(item) => this.setState({comment: item.target.value})}
                                rows={fields['comment'].rows || 5}
                                rowsMax={fields['comment'].rows || 10}
                                ref={fields['comment'].ref}
                                onBlur={fields['comment'].onBlur}
                                required={fields['comment'].requiredComputed}
                                onFocus={fields['comment'].onFocus}
                            />
                        </div>
                    : null
                }

                <ButtonBar>
                    <ActionButton 
                        onClick={() => this.props.modalRef ? this.props.modalRef.close() : Modal.close()}
                        disabled={this.state.apiUpdateInProgress}
                    >
                        Cancel
                    </ActionButton>
                    <ActionButton 
                        onClick={() => this.validate()} 
                        loading={this.state.apiUpdateInProgress}>
                            Validate
                    </ActionButton>
                </ButtonBar>
            </div>
        );
    }
}
