import {APIResource} from '../Services/APIResource/APIResource';
import {
    PARAMETER_TYPE_MODEL_RISK_ASSESSMENT_RESULT,
    PARAMETER_TYPE_MRA_PROCESS,
    PARAMETER_TYPE_MRA_STATUS,
} from './ParameterAdmin';
import ParameterStore, {userHasRoleADMIN, userHasRoleIG, userHasRoleMRM,} from '../Store/ParameterStore';
import EntityProvider from '../Services/APIResource/FieldProviders/EntityProvider';
import FloatProvider from '../Services/APIResource/FieldProviders/FloatProvider';
import ParameterProvider from '../Services/APIResource/FieldProviders/ParameterProvider';
import DateProvider from '../Services/APIResource/FieldProviders/DateProvider';


const MraFields = {
    id: {title: 'ID'},
    quickMra: {
        title: 'Quick Mra',
        type: 'bool',
    },
    deletionDate: {
        title: 'Deletion date',
        type: 'date',
        edit: () => null,
        displayList: (field, value, entity, props, resource) => {
            if (resource.instanceId !== 'deleted_mras') {
                return null;
            }
            return DateProvider.getDisplayList(field, value, entity, props, resource)
        },
        displayConditions: (entity, entity2, key, context) => entity.status === ParameterStore('MRA_STATUS_DELETED'),
    },
    model: {
        title: 'Model',
        type: 'model',
        helperTextDisplay: 'Related model',
        params: {
            resource: 'models',
            instanceId: 'allModels',
            displayField: 'toString',
            links: true,
            linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
            multi: false,
            endpoints: {
                getAll: 'models/all-models',
            },
        },
    },
    modelRiskAssessmentResult: {
        title: 'Model  Risk Assessment Result',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_MODEL_RISK_ASSESSMENT_RESULT,
            multi: false,
        },
    },
    criticalDimensions: {
        title: 'Critical Score Dimensions',
        type: 'entity',
        params: {
            resource: 'mra_dimensions',
            displayField: 'title',
            multi: true,
            links: false,
        },
    },
    score: {
        title: 'Model Risk Score',
        type: 'float',
    },
    process: {
        title: 'Process',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_MRA_PROCESS,
            multi: false,
        },
    },
    status: {
        title: 'Status',
        type: 'parameter',
        params: {
            type: PARAMETER_TYPE_MRA_STATUS,
            multi: false,
        },
    },
    versionAuthor: {
        title: "Author",
        type: 'userAsync',
        params: {
            resource: 'users',
            instanceId: 'users_all',
            displayField: 'toString',
            sortField: 'lastName',
            links: true,
            endpoints: {
                getAll: 'users/all-users/all',
            },
        },
    },
};

export const ApiResourceDefaultParams = {
    id: 'mras',
    instanceId: 'mras',
    name: 'MRA',
    permanentFilters: null,
    fields: ['id', 'model', 'process', 'status', 'versionAuthor', 'modelRiskAssessmentResult', 'criticalDimensions', 'score']
};

export default class MraAdmin {
    constructor() {
        this.configure();
    }

    async configure() {

        const quickMras = new APIResource({
            id: 'mras',
            instanceId: 'quick_mras',
            name: 'MRA Quick Creation',
        });
        quickMras
            .setFields(MraFields)
            .setLayout({
                tabs: {
                    Properties: {
                        rows: [
                            {
                                panels: {
                                    MRA: {
                                        cols: 6,
                                        fields: [
                                            'id',
                                            'model',
                                            'modelRiskAssessmentResult',
                                            'criticalDimensions',
                                            'score',
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genInsertView({
                fields: ['model', 'modelRiskAssessmentResult', 'criticalDimensions', 'score'],
                additionalRoutes: ['/resource/quick_mras/add/model/:modelId'],
                onInit: ({entity, routeParams}) => {
                    entity['@type'] = 'Mra';
                    entity.quickMra = true;
                    if (routeParams.modelId && !entity.model) {
                        entity.model = '/api/models/' + routeParams.modelId;
                    }
                },
            })
            .genEditView({
                fields: ['model', 'modelRiskAssessmentResult', 'criticalDimensions', 'score'],
            })
            .genListView({
                fields: ['id', 'model', 'modelRiskAssessmentResult', 'criticalDimensions', 'score'],
                permanentFilters: {quickMra: true},
            })
            .genDetailView()
            .allowDelete({
                returnPath: () => {
                    return '/resource/models/list';
                },
            });


        const views = [
            ApiResourceDefaultParams,
            {
                instanceId: 'deleted_mras',
                name: 'Deleted mras',
                permanentFilters: {
                    deleted: true,
                },
                fields: ['id', 'deletionDate', 'model', 'process', 'status', 'versionAuthor', 'modelRiskAssessmentResult', 'criticalDimensions', 'score'],
            }
        ];

        views.forEach((view) => {
            const mraResource = new APIResource(
                {
                    ...ApiResourceDefaultParams,
                    ...{
                        instanceId: view.instanceId,
                        name: view.name,
                    }
                }
            );
            mraResource
                .setFields({
                    ...MraFields,
                    criticalDimensions: {
                        ...MraFields.criticalDimensions,
                        display: (field, value, entity, props, resource) => entity.status !== ParameterStore("MRA_STATUS_ON_GOING") && EntityProvider.getDisplayList(field, value, entity, props, resource)
                    },
                    score: {
                        ...MraFields.score,
                        display: (field, value, entity, props, resource) => entity.status !== ParameterStore("MRA_STATUS_ON_GOING") && FloatProvider.getDisplayList(field, value, entity, props, resource)
                    },
                    modelRiskAssessmentResult: {
                        ...MraFields.modelRiskAssessmentResult,
                        display: (field, value, entity, props, resource) => entity.status !== ParameterStore("MRA_STATUS_ON_GOING") && ParameterProvider.getDisplayList(field, value, entity, props, resource)
                    }
                })
                .genListView({
                    fields: view.fields,
                    permanentFilters: userHasRoleADMIN() || userHasRoleMRM() || userHasRoleIG() ? view.permanentFilters : {
                        ...view.permanentFilters,
                        'model.isModelStakeholder': true
                    },
                })
            ;
            // On trick ApiResource : la vue de detail n'est pas gérée par ApiResource mais par Mra/Detail.js
            // en passant detail à true, <ResourceList> va transmettre le doubleClick sur une ligne, alors que la resource Detail n'existe pas.
            mraResource.operations.detail = true;
        });

    }
}
