import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalContent } from '../Modal/ModalContent';
import { Typography } from '@material-ui/core';
import { ButtonBar } from '../Modal/ButtonBar';
import { ActionButton } from '../Modal/ActionButton';
import { NOTICE_FIELDS } from '../../Admin/NoticeAdmin';
import { EntityForm } from '../Forms/EntityForm/EntityForm';
import { ValidationForm } from '../Forms/ValidationForm/ValidationForm';
import Modal from '../../Services/Modal';
import APIResourceStore from '../../Store/APIResourceStore';
import { openModal } from '../Modal/OpenModal';
import { CONTEXT_ADD } from '../../Services/APIResource/APIResource';

export const UpcomingValidationForClosureModal = (props) => {
    const { notice, resourceDetailComponent } = props;

    const [showLinking, setShowLinking] = useState(false);
    const [showCreate, setShowCreate] = useState(false);

    /**
     * pour contourner le code étrange de la modalinnerview, on crafte un faux composant custom
     * avec les paramètres stockés dans un objet props:
     */
    const newReviewFakeModal = {
        props: {
            parentInstanceId: 'notices',
            context: CONTEXT_ADD,
            instanceId: 'reviews',
            routeParams: { noticeIdLinkedForUpcomingValidation: notice.id },
            parentId: notice.id,
            postSaveRedirectAction: (review) => {
                APIResourceStore.resources.notices
                    .apiPut({
                        '@id': notice['@id'],
                        id: notice.id,
                        upcomingReview: review['@id'],
                    })
                    .then((notice) => {
                        resourceDetailComponent.entity = notice;
                        resourceDetailComponent.forceUpdate();
                    })
                    .finally(Modal.close());
            },
            allowStayInModal: true,
        },
    };

    const showModalCreateReview = () => {
        openModal(newReviewFakeModal);
    };

    if (showCreate) showModalCreateReview();

    return (
        <ModalContent>
            {!showLinking && <DefaultContent setShowLinking={setShowLinking} setShowCreate={setShowCreate} />}
            {showLinking && <LinkingReview notice={notice} resourceDetailComponent={resourceDetailComponent} />}
        </ModalContent>
    );
};
UpcomingValidationForClosureModal.propTypes = {
    notice: PropTypes.shape({
        '@id': PropTypes.string,
        id: PropTypes.number,
        models: PropTypes.array,
        upcomingReview: PropTypes.string,
    }).isRequired,
    resourceDetailComponent: PropTypes.shape({
        entity: PropTypes.any,
        forceUpdate: PropTypes.func,
    }).isRequired,
};

const DefaultContent = (props) => {
    const { setShowLinking, setShowCreate } = props;

    return (
        <>
            <Typography component={'p'}>
                The received elements are deemed appropriate but require the planning of a model change or periodic
                review (back-testing) to be processed. Please fill in the ID of the planned review.
            </Typography>
            <ButtonBar>
                <ActionButton onClick={() => setShowLinking(true)}>Link to a review</ActionButton>
                <ActionButton onClick={() => setShowCreate(true)}>Create a new review</ActionButton>
            </ButtonBar>
        </>
    );
};
DefaultContent.propTypes = {
    setShowLinking: PropTypes.func,
    setShowCreate: PropTypes.func,
};

const LinkingReview = (props) => {
    const { resourceDetailComponent } = props;
    const [notice, setNotice] = useState({ ...props.notice });

    const upcomingReviewField = { upcomingReview: NOTICE_FIELDS().upcomingReview };

    return (
        <>
            <EntityForm entity={notice} fields={upcomingReviewField} onUpdate={setNotice} />

            <ValidationForm
                actions={[
                    {
                        label: 'Cancel',
                        onClick: () => Modal.close(),
                    },
                    {
                        label: 'Validate',
                        onClick: () =>
                            APIResourceStore.resources.notices
                                .apiPut({
                                    '@id': notice['@id'],
                                    id: notice.id,
                                    upcomingReview: notice.upcomingReview,
                                })
                                .then((notice) => {
                                    resourceDetailComponent.entity = notice;
                                    resourceDetailComponent.forceUpdate();
                                })
                                .finally(Modal.close()),
                    },
                ]}
            />
        </>
    );
};
LinkingReview.propTypes = {
    notice: PropTypes.object,
    resourceDetailComponent: PropTypes.shape({
        entity: PropTypes.any,
        forceUpdate: PropTypes.func,
    }),
};
