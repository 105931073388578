import React, { Component } from 'react';
import AppBarStore from '../../Store/AppBarStore';
import RelationLinks from '../AdditionalButtons/RelationLinks';
import { Link } from 'react-router-dom';
import { APIResource } from '../../Services/APIResource/APIResource';
import Http from '../../Services/Http';

const allowedEntities = [
    'model_uses',
    'tierings',
    'mitigation_actions',
    'implementations',
    'reviews',
    'findings',
    'notices',
    'mras',
    'notice_actions',
];

export class Breadcrumb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [],
            defaultTitle: '',
        };
        this.links = [];
        this.resourceModels = new APIResource({
            id: 'models',
            instanceId: 'allModels',
            endpoints: {
                getAll: 'models/all-models',
            },
        });
    }

    componentDidMount() {
        if (this.props.resourceEditComponent && this.props.resourceEditComponent.props.inModal) {
            return;
        }
        AppBarStore.appBarStoreTitleClass = 'breadcrumb';
        this.generateRelationLinks();
        this.getDefaultTitle();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.resourceEditComponent && this.props.resourceEditComponent.props.inModal) {
            return;
        }
        AppBarStore.appBarStoreTitleClass = 'breadcrumb';
        if (prevProps.entity !== this.props.entity) {
            this.generateRelationLinks();
        }
        if (prevProps.entity !== this.props.entity || prevProps.resource !== this.props.resource) {
            this.getDefaultTitle();
        }
    }

    getDefaultTitle() {
        const title =
            this.props.entity && this.props.resource.fieldForTitle
                ? this.props.entity[this.props.resource.fieldForTitle]
                : `#${this.props.entity.id} detail`;
        this.setState({ defaultTitle: `${this.props.resource.breadcrumbName}: ${title}` });
    }

    /**
     * @param {Array} items
     */
    async getLinkFromItems(items) {
        const ids = items.map((item) => {
            if (typeof item === 'object') {
                return item.id;
            } else {
                item = item.split('/');
                return item[item.length - 1];
            }
        });
        const response = await Http.post(`breadcrumb/${this.props.resourcePath}`, { ids });
        return response['hydra:member'].map(({ entityId, toString }) => ({
            link: `/resource/${this.props.resourcePath}/${entityId}/detail`,
            title: toString,
        }));
    }

    generateRelationLinks() {
        this.setState({ links: [] });

        const value = this.props.entity[this.props.relationalProperty];

        if (!value || (Array.isArray(value) && value.length === 0)) return;
        if (!Array.isArray(value)) AppBarStore.appBarStoreTitleClass = '';

        const items = Array.isArray(value) ? value : [value];

        this.getLinkFromItems(items).then((links) => {
            this.setState({ links });
        });
    }

    render() {
        return (
            <>
                {allowedEntities.includes(this.props.resource.resourceId) && this.state.links ? (
                    <>
                        {this.state.links.length > 0
                            ? (this.props.relationalLabel ?? 'Model') + (this.state.links.length > 1 ? 's' : '') + ': '
                            : null}
                        {this.state.links.length > 1 ? (
                            <RelationLinks
                                withoutTooltip={true}
                                withoutIcon={true}
                                label={(AppBarStore.previousTitle ?? this.state.links[0].title) + '...'}
                                links={this.state.links}
                            />
                        ) : null}
                        {this.state.links.length === 1 ? (
                            <Link to={this.state.links[0].link} className="item" key={'additional-action-button-0'}>
                                {this.state.links[0].title}
                            </Link>
                        ) : null}
                        {this.state.links.length > 0 ? <br /> : null}
                        {this.state.links.length > 0 ? <i className="fa fa-angle-right"></i> : null}
                    </>
                ) : null}
                <span>{this.state.defaultTitle}</span>
            </>
        );
    }
}

export default Breadcrumb;
