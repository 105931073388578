import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Modal from "../../../Services/Modal";
import {EntityForm} from "../../Forms/EntityForm/EntityForm";
import {ValidationForm} from "../../Forms/ValidationForm/ValidationForm";
import ParameterStore, {userHasOwnershipRights, userHasRoleADMIN, userHasRoleMRM,} from "../../../Store/ParameterStore";
import {
    DOCUMENT_ACTION_DELETE,
    DOCUMENT_ACTION_LIST,
    DOCUMENT_ACTION_SHOW,
    DocumentManager,
} from "../../Display/DocumentManager/DocumentManager";
import {resetRetirementStatus, retireModelStep,} from "../../../Services/Actions/ModelActions";
import {PARAMETER_TYPE_MODEL_RETIREMENT_STATUS} from "../../../Admin/ParameterAdmin";
import User from "../../../Services/User/User";
import Alert from "../../../Services/Alert";
import BackgroundModel from "../Add/BackgroundModel";
import {ModalTreeProvider} from "../../Tree/ModalTree";
import {Typography} from "@material-ui/core";
import DateProvider from "../../../Services/APIResource/FieldProviders/DateProvider";
import DateFormatter from "../../../Services/DateFormatter";
import {isFieldEmpty, isFieldRequired} from "../../../Services/APIResource/Components/ResourceEdit/ResourceEdit";
import EntityProvider from "../../../Services/APIResource/FieldProviders/EntityProvider";
import {fieldTypeFormatValidate} from "../../../Services/APIResource/Utils";

/** @type {Object.<string, import('../../../Services/APIResource/APIResource').APIResourceField>} */
export const retirementFields = {
    retirementStatus: {
        title: "Retirement Status",
        type: "parameter",
        params: {type: PARAMETER_TYPE_MODEL_RETIREMENT_STATUS, multi: false},
        bulk: true,
        displayConditions: (entity) => entity.retirementStatus !== undefined,
        editConditions: (field, value, entity) => userHasRoleMRM() && entity.retirementStatus !== undefined,
    },
    retirementCommittee: {
        title: "Retirement Committee",
        type: "entity",
        params: {
            resource: "review_committees",
            displayField: "displayString",
            links: true,
            filters: (c) =>
                c.type === ParameterStore("REVIEW_COMMITTEE_TYPE_COMMITTEE"),
            neededFields: ['type'],
        },
        bulk: true,
        issueButton: false,
        displayConditions: (entity) =>
            entity.retirementStatus !== undefined ||
            entity.modelStatus === ParameterStore("MODEL_STATUS_RETIRED"),
        edit: (field, value, onChange, entity, routeParams, operation, bulkEntities = [], loading) => {
            let authorizeEdit = true;
            if (bulkEntities.length) {
                bulkEntities.forEach((bulkEntity) => {
                    if (bulkEntity.retirementStatus === undefined) {
                        authorizeEdit = false;
                    }
                });
            }

            if (!userHasRoleADMIN() && !userHasRoleMRM()) {
                authorizeEdit = false;
            }
            if (entity.modelStatus !== undefined && entity.modelStatus !== ParameterStore("MODEL_STATUS_RETIRED")) {
                authorizeEdit = false;
            }

            if (!authorizeEdit) return EntityProvider.getDisplay(field, value, entity);

            return EntityProvider.getEdit(field, value, onChange, entity, routeParams, loading);
        }
    },
    retirementJustificationMrm: {
        title: "Retirement justification (MRM)",
        type: "textarea",
        displayConditions: (entity, entity2, key, context) =>
            (entity.retirementStatus !== undefined ||
                entity.modelStatus ===
                ParameterStore("MODEL_STATUS_RETIRED")) &&
            (context !== "edit" || userHasRoleMRM()),
        bulkable: userHasRoleMRM,
    },
    retirementRefusedJustification: {
        title: 'Refusal comment',
        type: 'textarea',
        displayConditions: (entity, entity2, key, context) =>
            (entity.retirementStatus !== undefined ||
                entity.modelStatus === ParameterStore('MODEL_RETIREMENT_STATUS_PROPOSED_LOD1') ||
                entity.modelStatus === ParameterStore('MODEL_RETIREMENT_STATUS_AWAITING_COMMITTEE')) &&
            (context !== 'edit' || userHasRoleMRM()),
    },
    retirementDocumentsEntities: {
        title:
            "Please attach documents to justify the retirement if necessary.",
        type: "documents",
        params: {
            entityResource: "models",
            propertyName: "retirementDocumentsEntities",
            fieldName: "Document",
            allowedCategory: false,
            forceCategory: () => ParameterStore("DOCUMENT_CATEGORY_RETIREMENT"), // fonction pour retarder le moment où on récupère la valeur du paramètre, pour qu'il soit non "false"
            links: false,
            allowedAction: (entity, document, action) =>
                [DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_LIST, DOCUMENT_ACTION_DELETE].includes(action),
        },
        // eslint-disable-next-line react/display-name
        display: (field, value, entity) => (
            <DocumentManager
                values={value}
                entity={entity}
                entityResource={"models"}
                fieldName={"Document"}
                propertyName={"retirementDocumentsEntities"}
                defaultValues={{category: ParameterStore("DOCUMENT_CATEGORY_RETIREMENT")}}
                allowedAction={(entity, document, action) =>
                    [DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_LIST, DOCUMENT_ACTION_DELETE].includes(action)
                }
            />
        ),
        // eslint-disable-next-line react/display-name
        displayList: () => false,
        displayConditions: (entity) => entity.retirementStatus !== undefined,
    },
    retirementJustificationLod1: {
        title: "Retirement justification (LoD1)",
        type: "textarea",
        displayConditions: (entity) => entity.retirementStatus !== undefined,
        bulkable: userHasRoleMRM,
    },
    retirementRequestDate: {
        title: 'Date of the retirement request',
        type: 'date',
        displayConditions: (entity) => entity.retirementStatus !== undefined,
        edit: (field, value, onChange, entity, routeParams, operation, bulkEntities = [], loading = false) => {
            return operation === "bulkEdit" ? DateProvider.getEdit(field, value, onChange, entity, routeParams, loading) : DateProvider.getDisplay(field, value, entity);
        },
        bulk: true,
    },
    retirementExpectedDate: {
        title: 'Expected date of the retirement',
        type: 'date',
        displayConditions: (entity) => entity.retirementStatus !== undefined,
        bulk: true,
    },
};

/**
 * Les retirementFields sont utilisés tels quels dans ModelAdmin.
 * Mais on veut écraser certains des champs de ModelAdmin ici, d'où retirementFieldsOverride.
 */
const retirementFieldsOverride = (modalRef, isBulk = false) => ({
    foregroundModelRelations: {
        title: 'Offspring Models',
        type: 'entity',
        params: {
            resource: 'background_model_relations',
            displayField: 'toStringForeground',
            multi: true,
            links: true,
            linkPath: (entity) => {
                let entityPath = entity.foregroundModel;
                if (!entityPath) return null;
                entityPath = entityPath.split('/');
                let modelId = entityPath[entityPath.length - 1];
                return '/resource/models/' + modelId + '/detail';
            },
            tooltip: (entity) => entity.foregroundModelName
        },
        edit: (field, value, onChange, entity, _routeParams) => (
            <div>
                <Typography>If the retired model is replaced by a new model, please add an offspring model.</Typography>
                <BackgroundModel field={field} entity={entity} value={value} onChange={onChange} inverseRelation={true}
                                 modalRef={modalRef}/>
            </div>
        ),
        displayConditions: (entity, entity2, key, context) => context === 'edit',
    },
    retirementJustificationLod1: {
        ...retirementFields.retirementJustificationLod1,
        editConditions: (field, value, entity) => userHasOwnershipRights(User.getId(), entity),
        required: (entity) => userHasOwnershipRights(User.getId(), entity),
    },
    retirementJustificationMrm: {
        ...retirementFields.retirementJustificationMrm,
        editConditions: () => userHasRoleMRM(),
        required: () => userHasRoleMRM(),
    },
    retirementExpectedDate: {
        ...retirementFields.retirementExpectedDate,
        required: (entity) => (!isBulk && userHasRoleMRM()) || userHasOwnershipRights(User.getId(), entity),
    },
    retirementCommittee: {
        ...retirementFields.retirementCommittee,
        edit: (field, value, onChange, entity, routeParams, operation, bulkEntities = [], loading) => {
            if (!userHasRoleADMIN() && !userHasRoleMRM()) {
                return EntityProvider.getDisplay(field, value, entity);
            }
            return EntityProvider.getEdit(field, value, onChange, entity, routeParams, loading);
        }
    }
});

/**
 * Form pour le workflow de retrait d'un model.
 * En fonction du role et du statut, les choix sont différents !
 *
 */
const RetireModelForm = (props) => {
    const {
        entity,
        resource,
        resourceDetailComponent,
        retirementCallback,
        retirementResetCallback,
        isBulk = false
    } = props;
    const showCancel = {label: "Cancel", onClick: () => Modal.close()};

    const [actions, setActions] = useState([]);
    const [description, setDescription] = useState();
    const [retiringModel, setRetiringModel] = useState(entity);
    const [fields, setFields] = useState({});
    const [commentRefusal, setCommentRefusal] = useState(false);

    /** Pour stocker la ref de la modal de backgroundModelRelation */
    const [modalRef, setModalRef] = useState();
    /** @type {Object.<string, import('../../../Services/APIResource/APIResource').APIResourceField} */
    const allRetirementFields = {...retirementFields, ...retirementFieldsOverride(modalRef, isBulk)};

    /**
     *
     * @param {*} entity
     * @param {string} fieldId
     * @param {Array<string>} requiredFieldsOverride - si présent et non vide, doit contenir l'ensemble des champs required, écrase le required de allRetirementFields.
     * @returns
     */
    const isValid = (entity, fieldId, requiredFieldsOverride = []) => (requiredFieldsOverride.length ? !requiredFieldsOverride.includes(fieldId) : !isFieldRequired(allRetirementFields[fieldId], entity, fieldId)) || !isFieldEmpty(allRetirementFields[fieldId], entity, fieldId);

    const retireToStep = (status, message, onError) => {
        if (retirementCallback) return retirementCallback(retiringModel, fields, status);

        retireModelStep(
            retiringModel,
            resource,
            status,
            message,
            resourceDetailComponent,
            Modal.close
        ).catch((e) => {
            Alert.show({message: e.message, type: "error"});
            onError(e);
        });
    };

    const resetStatus = (message, onError, keepJustification) => {
        if (retirementResetCallback) return retirementResetCallback(retiringModel, keepJustification);

        resetRetirementStatus(
            retiringModel,
            resource,
            message,
            resourceDetailComponent,
            Modal.close,
            keepJustification
        ).catch((e) => {
            Alert.show({message: e.message, type: "error"});
            onError(e);
        });
    };

    useEffect(() => {
        let _actions = [];
        let _description = '';
        let _requiredFields = [];
        let _fields = [];
        if (userHasRoleMRM() && !retiringModel.retirementStatus) {
            _actions = [
                {...showCancel},
                {
                    label: "Confirm",
                    onClick: (e, onError) => {
                        retireToStep(
                            "MODEL_RETIREMENT_STATUS_PROPOSED_MRM",
                            "Your retirement proposal has been submitted to the model owner of this model",
                            onError
                        );
                    },
                },
            ];
            retiringModel.retirementRequestDate = DateFormatter.onlyDateInUTC(new Date(Date.now()));
            _description = "Do you confirm your request of retirement ?";
            _fields = [
                "retirementRequestDate",
                "retirementExpectedDate",
                "retirementDocumentsEntities",
                "retirementJustificationMrm",
                "foregroundModelRelations",
            ];
        } else if (
            userHasOwnershipRights(User.getId(), retiringModel) &&
            !retiringModel.retirementStatus
        ) {
            _actions = [
                {...showCancel},
                {
                    label: 'Confirm',
                    onClick: (e, onError) => {
                        let message = ['retirementJustificationLod1', 'retirementExpectedDate']
                            .filter((fieldId) => !isValid(retiringModel, fieldId))
                            .map((fieldId) => `${allRetirementFields[fieldId].title}: This field is required`)
                            .join('\n\n');

                        let retirementJustificationLod1Error = fieldTypeFormatValidate(allRetirementFields['retirementJustificationLod1'], retiringModel.retirementJustificationLod1);
                        if (retirementJustificationLod1Error !== false) {
                            message += (message !== '' ? '\n\n' : '') + `${allRetirementFields['retirementJustificationLod1'].title}: ${retirementJustificationLod1Error.detail}`;
                        }

                        message
                            ? Alert.show({
                            message: message,
                            type: 'error',
                        }) || onError()
                            : retireToStep(
                                'MODEL_RETIREMENT_STATUS_PROPOSED_LOD1',
                                'Your retirement request has been submitted to MRM',
                                onError
                            );
                    },
                },
            ];
            retiringModel.retirementRequestDate = DateFormatter.onlyDateInUTC(new Date(Date.now()));
            _description =
                "Please justify and confirm your request of retirement.";
            _fields = [
                "retirementRequestDate",
                "retirementExpectedDate",
                "retirementDocumentsEntities",
                "retirementJustificationLod1",
                "foregroundModelRelations",
            ];
        } else if (
            userHasOwnershipRights(User.getId(), retiringModel) &&
            retiringModel.retirementStatus ===
            ParameterStore("MODEL_RETIREMENT_STATUS_PROPOSED_MRM")
        ) {
            /**
             * Ici on utilise commentRefusal pour effectuer un refus en 2 temps :
             * D'abord on laisse les deux actions : "Accepter" / "Refuser"
             * puis on affiche uniquement "Refuser" avec le champ de commentaire.
             */
            if (commentRefusal) _requiredFields.push("retirementRefusedJustification");
            _actions = [
                {
                    label: "Refuse",
                    onClick: (e, onError) => {
                        if (commentRefusal) {
                            const message = !isValid(retiringModel, 'retirementRefusedJustification', _requiredFields) ?
                                `${allRetirementFields['retirementRefusedJustification'].title}: This field is required`
                                : ''
                            ;

                            message
                                ? Alert.show({
                                message: message,
                                type: "error",
                            }) || onError()
                                : resetStatus(
                                    "You have refused the retirement request.",
                                    onError
                                );
                        } else {
                            setCommentRefusal(true);
                            onError(); // Stoppe le "progress" des boutons, cf le composant ValidationForm 
                        }
                    },
                },
            ];
            if (!commentRefusal) {
                _actions.push(
                    {
                        label: "Accept",
                        onClick: (e, onError) => {
                            retiringModel.retirementRefusedJustification = ''; // Empty the refusal justification if accept
                            let message = ['retirementJustificationLod1', 'retirementExpectedDate']
                                .filter((fieldId) => !isValid(retiringModel, fieldId))
                                .map((fieldId) => `${allRetirementFields[fieldId].title}: This field is required`)
                                .join('\n\n');

                            let retirementJustificationLod1Error = fieldTypeFormatValidate(allRetirementFields['retirementJustificationLod1'], retiringModel.retirementJustificationLod1);
                            if (retirementJustificationLod1Error !== false) {
                                message += (message !== '' ? '\n\n' : '') + `${allRetirementFields['retirementJustificationLod1'].title}: ${retirementJustificationLod1Error.detail}`;
                            }

                            message
                                ? Alert.show({
                                message: message,
                                type: "error",
                            }) || onError()
                                : retireToStep(
                                    "MODEL_RETIREMENT_STATUS_PROPOSED_LOD1",
                                    "Your retirement request has been submitted to MRM",
                                    onError
                                );
                        },
                    },
                );
            }
            _description =
                commentRefusal ? "" : "MRM suggests the retirement of the model, do you accept ?";
            if (isBulk) {
                _fields = [
                    "retirementExpectedDate",
                    "retirementDocumentsEntities",
                    "retirementJustificationLod1",
                ];
            } else {
                _fields = [
                    "retirementRequestDate",
                    "retirementExpectedDate",
                    "retirementDocumentsEntities",
                    "foregroundModelRelations",
                    "retirementJustificationLod1",
                ];
            }

            if (commentRefusal) _fields.push("retirementRefusedJustification"); // affiché si LoD1 veut refuser
        } else if (
            userHasRoleMRM() &&
            retiringModel.retirementStatus ===
            ParameterStore("MODEL_RETIREMENT_STATUS_PROPOSED_LOD1")
        ) {
            /**
             * Ici on utilise commentRefusal pour effectuer un refus en 2 temps :
             * D'abord on laisse les deux actions : "Accepter" / "Refuser"
             * puis on affiche uniquement "Refuser" avec le champ de commentaire.
             */
            _actions = [
                {
                    label: "Refuse",
                    onClick: (e, onError) => {
                        if (commentRefusal) {
                            retiringModel.retirementJustificationLod1 = ''; // Empty the LOD1 justification if refused by MRM
                            resetStatus(
                                "You have refused the retirement request.",
                                onError
                            );
                        } else {
                            setCommentRefusal(true);
                            onError(); // Stoppe le "progress" des boutons, cf le composant ValidationForm 
                        }
                    },
                },
            ];
            if (!commentRefusal) {
                _actions.push(
                    {
                        label: "Accept",
                        onClick: (e, onError) => {
                            retiringModel.retirementRefusedJustification = ''; // Empty the refusal justification if accept
                            retireToStep(
                                "MODEL_RETIREMENT_STATUS_AWAITING_COMMITTEE",
                                "This retirement request is now waiting for committee validation.",
                                onError
                            );
                        },
                    },
                );
            }
            _description = commentRefusal ? "" : "Do you accept the request of retirement ?";
            _fields = isBulk ? [
                "retirementDocumentsEntities",
                "retirementJustificationMrm",
            ] : [
                "retirementRequestDate",
                "retirementJustificationLod1",
                "retirementExpectedDate",
                "retirementDocumentsEntities",
                "retirementJustificationMrm",
                "foregroundModelRelations",
            ];
            if (commentRefusal) _fields.push("retirementRefusedJustification");
        } else if (
            userHasRoleMRM() &&
            retiringModel.retirementStatus ===
            ParameterStore("MODEL_RETIREMENT_STATUS_AWAITING_COMMITTEE")
        ) {
            /**
             * Ici on utilise commentRefusal pour effectuer un refus en 2 temps :
             * D'abord on laisse les deux actions : "Accepter" / "Refuser"
             * puis on affiche uniquement "Refuser" avec le champ de commentaire.
             */
            _actions = [
                {
                    label: "No",
                    onClick: (e, onError) => {

                        // feature/models-rendre-le-committee-obligatoire-lors-d-un-retirement
                        if (!retiringModel.retirementCommittee) {
                            Alert.show({
                                message: 'Please provide a Retirement Committee',
                                type: 'error',
                            })
                            onError(); // Stoppe le "progress" des boutons, cf le composant ValidationForm
                            return;
                        }

                        if (commentRefusal) {
                            resetStatus(
                                "The committee has refused the retirement request.",
                                onError
                            );
                        } else {
                            setCommentRefusal(true);
                            onError(); // Stoppe le "progress" des boutons, cf le composant ValidationForm
                        }
                    },
                },
            ];
            if (!commentRefusal) {
                _actions.push(
                    {
                        label: "Yes",
                        onClick: (e, onError) => {

                            // feature/models-rendre-le-committee-obligatoire-lors-d-un-retirement
                            if (!retiringModel.retirementCommittee) {
                                Alert.show({
                                    message: 'Please provide a Retirement Committee',
                                    type: 'error',
                                })
                                onError();
                                return;
                            }

                            retiringModel.retirementRefusedJustification = ''; // Empty the refusal justification if accept
                            retireToStep(
                                "MODEL_RETIREMENT_STATUS_COMPLETE",
                                "This model is now retired. Its model owner has been notified.",
                                onError
                            );
                        },
                    },
                );
            }
            _description = commentRefusal ? "" :
                "Does the Committee validate the request of retirement ?";
            _fields = commentRefusal ? [
                "retirementCommittee",
                "retirementRefusedJustification"
            ] : [
                "retirementCommittee",
            ];

            if (!commentRefusal) _requiredFields.push("retirementCommittee")
        }
        setActions(_actions || []);
        setDescription(_description);
        setFields(
            _fields.reduce(
                (acc, o) => ({
                    ...acc,
                    [o]: {
                        ...allRetirementFields[o],
                        displayCondition: () => true,
                        required: _requiredFields.length ? (entity, fieldId) => _requiredFields.includes(fieldId) : (allRetirementFields[o]?.required || false),
                    },
                }),
                {}
            )
        );
    }, [retiringModel, commentRefusal, modalRef]);

    return (
        <>
            <EntityForm
                entity={retiringModel}
                onUpdate={(e) => setRetiringModel(e)}
                fields={fields}
            />
            <ValidationForm description={description} actions={actions}/>
            <ModalTreeProvider ref={(ref) => setModalRef(ref)}/>
        </>
    );
};
RetireModelForm.propTypes = {
    entity: PropTypes.object,
    resource: PropTypes.any,
    resourceDetailComponent: PropTypes.any,
    /** Action à effectuer à la place de l'action par défaut (càd retireModelStep) */
    retirementCallback: PropTypes.func,
    /** Action à effectuer à la place de l'action par défaut (càd resetRetirementStatus) */
    retirementResetCallback: PropTypes.func,
    /**
     * Vrai si le composant est utilisé dans le cadre d'un bulk.
     * Dans ce cas l'entité n'est pas complète et les champs à afficher diffèrent.
     * @todo : à la place on pourrait demander la liste et les conditions des champs voulus par role / retirementStatus.
     */
    isBulk: PropTypes.bool,
};

export default RetireModelForm;
