import React from 'react';
import { DisplayTextField } from "../../../Components/Display/DisplayTextField/DisplayTextField";
import TextField from '@material-ui/core/TextField';
import {CONTEXT_LIST} from "../APIResource";

class TextProvider {

  getDisplay(field, value, entity, props, resource, context) {
    return (
      <DisplayTextField
        {...props}
        fieldName={field.title}
        value={value}
        context={context}
      />
    );
  }

  getDisplayList(field, value, entity, props, resource) {
    return this.getDisplay(field, value, entity, props, resource, CONTEXT_LIST);
  }

  getEdit(field, value, onChange, entity, routeParams, loading = false) {
    /** 
     * Comme le composant Text n'est plus contrôlé, defaultValue ne déclenche pas le rerender quand
     * les données du champs changent (il les gère dans son propre state), or si la valeur initiale
     * n'est pas chargée, on doit forcer le re-render au moment où elle arrive.
     * Plus précisément il faut rerender l'Input inclus dans le TextField, donc la mise à jour de la
     * plupart des props du TextField n'auront pas d'effet sur le rerender de l'Input.
     * Donc le plus simple est de changer la key du TextField, qui sera détruit et remonté.
     */
    const key = loading ? `text_preload_${field.id}` : `text_${field.id}`;
    return (
      <TextField
        key={key}
        //disabled={loading}
        label={field.title}
        onChange={event => {
          onChange(event.target.value);
        }}
        defaultValue={value ? value : ''}
        inputRef={field.ref}
        onBlur={field.onBlur}
        onFocus={field.onFocus}
        required={field.requiredComputed !== undefined ? field.requiredComputed : field.required}
        error={!!field.errorHelperText}

      // helperText={field.errorHelperText}
      //  placeholder={field.errorHelperText}
      />
    );
  }

  getFilter(options = {}) {
    const { field, value, onChange } = options;
    return (
      <TextField
        id={"filter-" + (field.id ?? field.field)}
        placeholder="Filter"
        value={value ? value : ""}
        onChange={event => {
          onChange(event.target.value);
        }}
        className="input-text-field-table"
      />
    );
  }

  typeLengthValidate(value, maxLength){
    var hasError = false;
    var errorText = '';
    const len = String(value).length;
    if (len > maxLength){
      hasError = true;
      errorText = 'Too many characters ('+len+'/'+maxLength+')';
    }
    return [hasError,errorText];
  }

  typeFormatValidate(stringValue){
    var hasError = false;
    var errorText = '';
    
    //var patternDiversity = "^(.)\\1+$"; // more than one character type 
    var patternRepetition=/.*([a-zA-Z])\1{3,}/; // is less than 4 alpha repetition in the string ?

    if (stringValue.length > 0){                        //allow empty field
      if (stringValue.trim().length === 0){             //do not allow space only string  
        hasError = true;
        errorText = 'Please enter a value';
      }
      else if (!stringValue.search(patternRepetition)){ // do not allow a repetition of alphanumeric character more than 2 times 
        let stringArray = stringValue.trim().split(' ');
        let stringWithError = [];
        stringArray.map((s) => {
          if(!s.search(patternRepetition)){
            stringWithError.push(s);
          }
        })
        hasError = true;
        errorText = `Please enter an acceptable value.` + (stringWithError.length > 0 ? ` (Error : ${stringWithError.join(',')})` : '');
      }
      /*
      else if (stringValue.length > 2){                
        if (stringValue.match(patternDiversity)){ //do not allow only one repetitive character when more than 2 characters
          hasError = true;
          errorText = 'Please enter an acceptable value';
        }
      }
      */        
    }
    return [hasError,errorText];
  }

}

export default new TextProvider();