import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Modal from "../../Services/Modal";
import {APIResource} from "../../Services/APIResource/APIResource";
import Alert from "../../Services/Alert";
import {CircularProgress} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControl from "@material-ui/core/FormControl";
import Navigation from "../../Services/Navigation";
import Http from "../../Services/Http";

export class ReviewRejectModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteProcessing: false,
            rejectComment: null

        };
        this.resource = new APIResource({ id: 'reviews', instanceId: 'reviews_request' });
    }

    delete(review) {

        this.deleteProcessing = true;
        this.setState({deleteProcessing: true});

        review.reject = true;
        review.rejectComment = this.state.rejectComment;
        
        Http.post('reviews/reject', {
            '@id': review['@id'],
            'rejectComment': review.rejectComment,
            'author': review.author,
            'models': review.models
        })
            .then((deletedEntity) => {
                Modal.close();
                Alert.show({
                    message: 'The review was successfully rejected. The requester was notify.',
                    type: 'success',
                });
                this.setState({deleteProcessing: false});
                this.props.resource.apiGetCollection({
                    page: 1,
                    rowsPerPage: 10000,
                    forceReload: true
                }).then(() => {
                    this.setState({deleteProcessing: false});
                    Modal.close();
                    Navigation.router.history.push(
                        `/resource/${this.props.resource.resourceId}/list`
                    );
                }).catch(() => {
                    this.setState({deleteProcessing: false});
                    Modal.close();
                });
            })
            .catch((err) => {
                this.setState({deleteProcessing: false});
                Modal.close();
            });
    }

    render() {
        return (
            <div>
                <p>Are you sure you want to reject this review request ?</p>
                <FormControl className="container_textarea">
                    <label>Optionally, you can explain your decision, and it will be communicate to the requester</label>
                    <TextareaAutosize
                        value={this.state.rejectComment ? this.state.rejectComment : ""}
                        onChange={(event) => this.setState({ rejectComment: event.target.value })}
                        rows={5}
                        rowsMax={10}
                        required={true}
                    />
                </FormControl>
                <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Button style={{marginTop: 10}} variant="contained" color="secondary" className="save button-general" onClick={Modal.close}>
                        Cancel
                    </Button>
                    {
                        this.state.deleteProcessing === true
                            ?
                            <Button
                                style={{ marginTop: 10 }}
                                variant="contained"
                                color="secondary"
                                className="save button-general"
                            >
                                <CircularProgress size={20} />
                            </Button>
                            :
                            <Button style={{marginTop: 10}} variant="contained" color="secondary" className="save button-general" onClick={() => this.delete(this.props.entity)}>
                                Delete
                            </Button>
                    }
                </div>
            </div>
        );
    }
}
