import React, {Component} from 'react';
import {observer} from "mobx-react";
import SelectReact from 'react-select';

export const Select = observer(class Select extends Component{
    constructor(props){
        super(props);

        this.state = {
            value: null
        };
    }

    componentDidMount(){
        if(this.props.value){
            this.updateValue(this.props.value);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.value && (this.props.value != prevProps.value)){
            this.updateValue(this.props.value);
        }
    }

    updateValue(value){
        this.setState({value: value});
    }

    render(){
        const { label, required, labelClassName} = this.props
        return (
          <div className="select-component">
            {label ? (
              <label style={required ? {textDecoration: 'underline'} : {}} className={labelClassName}>{label}{required ? ' *' : ''}</label>
            ) : (
              ""
            )}
            <SelectReact
                {...this.props}
                required={this.props.requiredComputed}
                menuPortalTarget={document.body}
                menuPosition={'absolute'}
                menuPlacement={'bottom'}
                isDisabled={this.props.disabled}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
          </div>
        );
    }
});

export default Select;