import React from 'react';
import PropTypes from 'prop-types';
import { ModalContent } from '../../Modal/ModalContent';
import { ButtonBar } from '../../Modal/ButtonBar';
import { ActionButton } from '../../Modal/ActionButton';
import { Typography } from '@material-ui/core';
import Modal from '../../../Services/Modal';
import MRA from '../../../Services/MRA';
import Alert from '../../../Services/Alert';

export const DetachMraModal = (props) => {
    const { mra, detachLoading, setDetachLoading, onSuccess } = props;

    const onDetachConfirm = async (mra) => {
        setDetachLoading(true);
        Modal.close();

        mra.group = null;
        mra.silent = false;

        const { success, payload } = await MRA.simpleSave(mra);
        if (!success) {
            Alert.show({
                message: `An error occurred detaching MRA of model ${mra.functionalID}`,
                type: 'error',
            });
        } else {
            Alert.show({
                message: `MRA of model ${mra.functionalID} successfully detached`,
                type: 'success',
            });

            onSuccess(payload);
        }
        setDetachLoading(true);
    };

    return (
        <ModalContent>
            <Typography component={'p'}>
                You are going to detach a model from this MRA and create a separate MRA for this model. There is no
                possible rollback.
            </Typography>
            <Typography component={'p'}>
                By confirming, we will detach the following model from the group MRA:
                <span style={{ fontWeight: 'bold' }}>{mra.functionalID}</span>
            </Typography>
            <ButtonBar>
                <ActionButton
                    loading={detachLoading}
                    onClick={() => {
                        onDetachConfirm(mra);
                    }}
                >
                    Yes, I want to detach
                </ActionButton>
                <ActionButton disabled={detachLoading} onClick={() => Modal.close()}>
                    Cancel
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );
};
DetachMraModal.propTypes = {
    mra: PropTypes.object,
    detachLoading: PropTypes.bool,
    setDetachLoading: PropTypes.func,
    /** de la forme (entity) => {} */
    onSuccess: PropTypes.func,
};
