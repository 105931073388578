import React from "react";
import { ButtonColumn } from "./ButtonColumn";
import Button from "@material-ui/core/Button";
import Navigation from "../../Services/Navigation";
import Modal from "../../Services/Modal";

const ChoiceModal = (props) => {
    const { choices } = props;

    const renderButtons = () => {
        const buttons = [];
        choices.forEach(
            (button, index) => {
                buttons.push(
                    <Button
                        key={"choice_" + index}
                        variant="contained"
                        color="primary"
                        className={'tooltip ' + (button.class || ' ') + (button.className || ' ')}
                        style={{marginBottom: 15}}
                        onClick={() => {
                            if(!button.onClick){
                                Navigation.router.history.push(button.link);
                            }else{
                                button.onClick()
                            }
                            Modal.close();
                        }}
                    >
                        { button.icon && <i className={'fa fa-' + button.icon}></i> }
                        { button.tooltip && <span className="tooltiptext">{button.tooltip}</span> }
                        { button.title && <div>{button.title}</div> }
                    </Button>
                );
            }
        );
        return buttons;
    }

    return (
        <div className={"choice-modal"}>
            <ButtonColumn>
                {renderButtons()}
            </ButtonColumn>
        </div>
    );
};

export default ChoiceModal;