import React from 'react';
import { APIResource } from '../Services/APIResource/APIResource';
import { ChangeLogExport } from '../Services/BulkActions/BulkExport/ChangeLogExport';
import { ChangeLogForm, VerifiedMenu } from '../Components/Display/ChangeLog/ChangeLog';
import { BulkVerify } from '../Services/BulkActions/BulkVerify/BulkVerify';
import {LinkPreload as Link} from '../Components/Link/Link';
import String from '../Services/String';
import Modal from '../Services/Modal';
import Alert from '../Services/Alert';
import User from "../Services/User/User";
import { BulkEdit } from '../Services/BulkActions/BulkEdit/BulkEdit';
import { userHasRoleADMIN, userHasRoleMRM } from '../Store/ParameterStore';

/**
 * 
 * @param {*} issues 
 * @param {import('../Services/APIResource/APIResource').APIResource} resourceChangeLogs 
 * @returns {Object.<string, import('../Services/APIResource/APIResource').APIResourceField>}
 */
const CHANGE_LOGS_FIELDS = (issues, resourceChangeLogs) => {
    let mapping = {
        implementation: 'Implementation',
        mitigationAction: 'Mitigation action',
        modelCertificationCampaign: 'Model certification campaign',
        model: 'Model',
        modelUse: 'Model use',
        review: 'Review',
        tiering: 'Tiering',
        mra: 'MRA',
        certification: 'Certification',
    };
    if(issues){
        mapping = {
            finding: 'Finding',
            notice: 'Notice',
        };
    }
    return {
        id: { title: 'ID' },
        verified: {
            title: 'Verified',
            type: 'bool',
            width: 100,
            displayList: (field, value, entity, _props) => (
                <VerifiedMenu value={value} changelogId={entity ? entity.id : null} />
            ),
            noTooltip: true,
        },
        date: { title: 'Date', type: 'date' },
        author: {
            title: 'Author',
            type: 'user',
            params: {
                resource: 'users',
                instanceId: 'users_ca',
                displayField: 'toString',
                editDisplayField: 'fullNameWithTeam',
                multi: false,
                links: false,
                endpoints: {
                    getAll: 'users/all-users/ca',
                },
            },
        },
        relatedEntityType: {
            title: 'Entity type',
            type: 'mapped',
            params: {
                mapping: mapping,
            },
        },
        relatedEntity: {
            title: 'Entity',
            display: (field, value, entity, _props, _resourceDetailComponent, _context) => {
                let resourceId = null;
                let entityId = null;
                if (entity.model) {
                    resourceId = 'models';
                    entityId = entity.model.split('/');
                    entityId = entityId[entityId.length - 1];
                }
                if (entity.modelUse) {
                    resourceId = 'model_uses';
                    entityId = entity.modelUse.split('/');
                    entityId = entityId[entityId.length - 1];
                }
                if (entity.implementation) {
                    resourceId = 'implementations';
                    entityId = entity.implementation.split('/');
                    entityId = entityId[entityId.length - 1];
                }
                if (entity.mitigationAction) {
                    resourceId = 'mitigation_actions';
                    entityId = entity.mitigationAction.split('/');
                    entityId = entityId[entityId.length - 1];
                }
                if (entity.review) {
                    resourceId = 'reviews';
                    entityId = entity.review.split('/');
                    entityId = entityId[entityId.length - 1];
                }
                if (entity.tiering) {
                    resourceId = 'tierings';
                    entityId = entity.tiering.split('/');
                    entityId = entityId[entityId.length - 1];
                }
                if (entity.finding) {
                    resourceId = 'findings';
                    entityId = entity.finding.split('/');
                    entityId = entityId[entityId.length - 1];
                    // On ajoute l'id devant le nom, comme pour la Notice qui le possède déjà dans son __toString back
                    value = `${entityId} ${value}`;
                }
                if (entity.notice) {
                    resourceId = 'notices';
                    entityId = entity.notice.split('/');
                    entityId = entityId[entityId.length - 1];
                }
                if (entity.modelCertificationCampaign) {
                    resourceId = 'model_certification_campaigns';
                    entityId = entity.modelCertificationCampaign.split('/');
                    entityId = entityId[entityId.length - 1];
                }
                if (entity.certification) {
                    resourceId = 'certifications';
                    entityId = entity.certification.split('/');
                    entityId = entityId[entityId.length - 1];
                }

                if (resourceId) {
                    return <Link to={'/resource/' + resourceId + '/' + entityId + '/detail'} key={`${resourceId}-${entity.id}`}>{value}</Link>;
                }
                return value;
            },
        },
        relatedModels: {
            title: 'Related Models',
            params: {
                filterMulti: true,
            },
            display: (field, value, entity, _props, _resourceDetailComponent, _context) => {
                return value.map((item) => <Link to={item.apiPath}  key={`related-${item.id}-${entity.id}`} style={{display: 'block'}}>{item.functionalID}{item.initialID?' ('+item.initialID+')':''}</Link>);
            },
        },
        model: {
            title: 'Model',
            type: 'model',
            params: {
                resource: 'models',
                instanceId: 'allModels',
                displayField: 'toString',
                multi: false,
                links: true,
                linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                endpoints: {
                    getAll: 'models/all-models',
                },
            },
            displayList: () => null,
        },
        modelUse: {
            title: 'Model Use',
            type: 'entity',
            params: {
                resource: 'model_uses',
                displayField: 'title',
                multi: false,
                links: true,
            },
            displayList: () => null,
        },
        implementation: {
            title: 'Implementation',
            type: 'entity',
            params: {
                resource: 'implementations',
                displayField: 'title',
                multi: false,
                links: true,
            },
            displayList: () => null,
        },
        mitigationAction: {
            title: 'Mitigation Action',
            type: 'entity',
            params: {
                resource: 'mitigation_actions',
                displayField: 'title',
                multi: false,
                links: true,
            },
            displayList: () => null,
        },
        review: {
            title: 'Review',
            type: 'entity',
            params: {
                resource: 'reviews',
                displayField: 'title',
                multi: false,
                links: true,
            },
            displayList: () => null,
        },
        changeDescription: {
            title: 'Changelogs',
            type: '',
            width: 700,
        },
        mrmTeams: {
            title: 'MRM Team',
            type: 'entityAsync',
            params: {
                resource: 'scopes',
                instanceId: 'scopes_mrm',
                displayField: 'title',
                multi: false,
                endpoints: {
                    getAll: 'scopes/all-scopes/mrm',
                },
            },
            edit: () => null,
        },
        comment: {
            title: 'Comment',
            width: 500,
            displayList: (field, value, entity, _props) => {
                return (
                    <div style={{ position: 'relative' }}>
                        {String.nlToBr(value)}
                        <Link to="#" onClick={() => ChangeLogAdmin.editComment(entity, resourceChangeLogs)} key={`edit-${entity?.id}`}>
                            Edit
                        </Link>
                    </div>
                );
            },
        },
    };
};

export const ApiResourceDefaultParams = {
    id: 'change_logs',
    name: 'Change Logs',
    icon: 'cog',
    fieldForTitle: 'id',
};

export default class ChangeLogAdmin {
    constructor() {
        this.configure();
    }

    /**
     * 
     * @param {*} changelog 
     * @param {import('../Services/APIResource/APIResource').APIResource} resourceChangeLogs 
     */
    static editComment(changelog, resourceChangeLogs) {
        Modal.open({
            title: 'Edit comment',
            content: (
                <ChangeLogForm
                    changeLog={changelog}
                    onSave={(changelog) => {
                        let promise;
                        if (changelog.id) {
                            promise = resourceChangeLogs.apiPut({
                                id: changelog.id,
                                comment: changelog.comment,
                            });
                            return promise.then((_entity) => {
                                Alert.show({ message: 'Change log has been updated', type: "success" });
                                resourceChangeLogs.apiGetCollection({ forceReload: true });
                                Modal.close();
                            });
                        }
                        return;
                    }}
                />
            ),
        });
    }

    configure() {
        let resourceChangeLogs = new APIResource(ApiResourceDefaultParams);
        this.resourceChangeLogs = resourceChangeLogs;
        resourceChangeLogs
            .setFields(CHANGE_LOGS_FIELDS(false, resourceChangeLogs))
            .setValidation((_entity) => {
                return true;
            })
            .genListView({
                fields: [
                    'verified',
                    'date',
                    'author',
                    'relatedEntityType',
                    'relatedEntity',
                    'relatedModels',
                    'mrmTeams',
                    'changeDescription',
                    'comment',
                ],
                permanentFilters: { "issues": false },
                // routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .addBulkAction(ChangeLogExport, {
                icon: ['fas', 'tasks'],
            })
            .addBulkAction(BulkVerify, {
                resource: resourceChangeLogs,
                icon: 'check',
                resourceId: 'change_logs',
            })
            .addBulkAction(BulkEdit, {
                resource: resourceChangeLogs,
                icon: 'edit',
                fields: {comment : CHANGE_LOGS_FIELDS(false, resourceChangeLogs).comment}
            })
            ;

        let resourceChangeLogsIssues = new APIResource({
            id: 'change_logs',
            instanceId: 'change_logs_issues',
            name: 'Change Logs',
            icon: 'cog',
            fieldForTitle: 'id',
        });
        this.resourceChangeLogsIssues = resourceChangeLogsIssues;
        resourceChangeLogsIssues
            .setFields(CHANGE_LOGS_FIELDS(true, resourceChangeLogsIssues))
            .setValidation((_entity) => {
                return true;
            })
            .genListView({
                fields: [
                    'verified',
                    'date',
                    'author',
                    'relatedEntityType',
                    'relatedEntity',
                    'relatedModels',
                    'mrmTeams',
                    'changeDescription',
                    'comment',
                ],
                permanentFilters: { "issues": true },
                routeAccessControl: () => userHasRoleMRM() || userHasRoleADMIN() || User.profile.isMemberOfValidatorTeam,
            })
            .addBulkAction(ChangeLogExport, {
                icon: ['fas', 'tasks'],
            })
            .addBulkAction(BulkVerify, {
                resource: resourceChangeLogsIssues,
                icon: 'check',
                resourceId: 'change_logs_issues',
            });
    }
}
