import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import colors, {datalabelsColors} from './colors';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export class PieWidget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialRows: null,
            rows: null,
        };
    }

    render() {
        const { rows, cols, box } = this.props;

        // if box on place 4 or more
        // put the legend on the right
        const position = box === undefined || parseInt(box, 10) < 4 ? 'bottom' : 'right';

        if (!rows || rows.length < 1) {
            return <div>No data to display</div>;
        }
        const data = {
            labels: cols,
            datasets: [
                {
                    data: rows,
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                },
            ],
        };
        return (
            <Pie
                data={data}
                plugins={[ChartDataLabels]}
                position="bottom"
                options={{
                    maintainAspectRatio: false,
                    plugins: {
                        datalabels: {
                            color: datalabelsColors,
                            font: {
                                weight: 'bold'
                            },
                            display: "auto"
                        },
                    },
                }}
                legend={{
                    display: box === undefined || !(parseInt(box, 10) < 4 && cols.length > 6),
                    position,
                    fullWidth: true,
                }}
            />
        );
    }
}
