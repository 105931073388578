import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker as DRPicker } from 'react-date-range';
import DateFormatter from "../../../Services/DateFormatter"
import { defaultStaticRanges } from "./DefautStaticRanges";
import Modal from '../../../Services/Modal';

export class DateRangePicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selection: {
                startDate: DateFormatter.addDaysDateObject(-30, new Date()),
                endDate: new Date(),
                key: 'selection'
            },
            step: 0,
        }
    }

    handleRangeChange(payload) {
        this.setState({
            selection: {
                startDate: payload.selection.startDate,
                endDate: payload.selection.endDate,
                key: 'selection'
            },
        });
        if (this.state.step === 1) {
            if (this.props.onChange) {
                this.props.onChange(payload.selection.startDate, payload.selection.endDate);
            }
            // allow disabling modal closing
            if (this.props.autoClose === undefined || this.props.autoClose) {
                Modal.close();
            }
        } else {
            this.setState({step: 1});
        }
    }

    render() {
        return (
            <div style={styles.dateRangePicker}>
                <DRPicker
                    showSelectionPreview={true}
                    showMonthAndYearPickers={true}
                    moveRangeOnFirstSelection={false}
                    showMonthArrow={true}
                    months={1}
                    ranges={[this.state.selection]}
                    inputRanges={[]}
                    onChange={this.handleRangeChange.bind(this)}
                    direction="horizontal"
                    staticRanges={this.props.staticRanges || defaultStaticRanges}
                />
            </div>
        )
    }
}
DateRangePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    autoClose: PropTypes.bool,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    staticRanges: PropTypes.array,
}


const styles = {
    dateRangePicker: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 0,
        marginBottom: 0,
        width: '80%',
    },
};
