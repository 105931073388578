import React from 'react';

export const CountWidget = ({ data }) => {
    let count = '-';
    if (data && data.length === 1) {
        count = data[0].value;
    }
    return (
        <div>
            <div style={{ fontSize: '42px', fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>{count}</div>
        </div>
    );
};
