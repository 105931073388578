import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { PieWidget } from './PieWidget';
import { BarWidget } from './BarWidget';
import { CountWidget } from './CountWidget';
import { TableWidget } from './TableWidget';
import DateFormatter from '../../../Services/DateFormatter';
import { toJS } from 'mobx/lib/mobx';
import { Link } from 'react-router-dom';
import _, { userHasRoleADMIN } from '../../../Store/ParameterStore';

// Filter out data when there's too much to display for the box
const limitDataBarChart = (data, options) => {
    if (options && options.boxId < 4) {
        // TOP 6 elements
        return data.slice(0, 6);
    }

    if (options && options.boxId < 6) {
        // TOP 20 elements
        return data.slice(0, 20);
    }

    // TOP 40 elements
    return data.slice(0, 40);
};

const shouldLimitData = (data, options) => {
    if (options && options.boxId < 4) {
        // TOP 5 elements
        return true;
    }

    if (options && options.boxId < 6) {
        // TOP 10 elements
        return true;
    }

    if (data.length > 20) {
        return true;
    }

    return false;
};

const sortDataByValue = (data) => {
    return data.sort((a, b) => {
        if (a.value > b.value) return -1;
        if (a.value < b.value) return 1;
        return 0;
    });
};

/**
 * 
 * Data format example
   const rows = [300, 50, 100];
   const cols = ['Red','Blue','Yellow'];
 */
const PieChart = ({ data, options }) => {
    const cols = data.map((datum) => datum.label);
    const rows = data.map((datum) => datum.value);

    return <PieWidget box={options && options.boxId} rows={rows} cols={cols} />;
};

const OrderByChart = (props) => {
    const { data = [] } = props;
    let cols = [];

    if (data.length > 0) {
        cols = Object.keys(data[0]).map((key) => ({
            label: key,
            field: key,
        }));
    }

    // check for date objects
    const rows = data.map((row) => {
        const d = {};
        Object.keys(row).map((key) => {
            const datum = row[key];
            if (datum && datum.date) {
                d[key] = DateFormatter.dateToIso8601(datum.date);
            } else {
                d[key] = datum;
            }
        });
        return d;
    });

    return (
        <React.Fragment>
            <TableWidget rows={rows} cols={cols}></TableWidget>
        </React.Fragment>
    );
};

/**
 * 
 * data format example
   const cols = [{label: "Colonne 1", field: "colonne1" }, {label: "Colonne 2", field: "colonne2" }]
   const rows = [{colonne1: "valeur 1", colonne2: "xkh sdkfjh" }, {colonne1: "valeur 2", colonne2: 23}];
 */
const TableChart = ({ data }) => {
    // const sortedData = sortDataByValue(data)
    const cols = [
        { label: 'label', field: 'label' },
        { label: 'value', field: 'value' },
    ];
    const rows = data.map((datum) => ({ label: datum.label, value: datum.value }));

    return (
        <React.Fragment>
            <TableWidget rows={rows} cols={cols}></TableWidget>
        </React.Fragment>
    );
};

/**
 * 
 * data format example
   const rows = [65, 59, 80, 81, 65, 59, 80, 81];
   const cols = ['January', 'February', 'March', 'April', 'January', 'February', 'March', 'April'];
 */
const BarChart = ({ data, options }) => {
    let sortedData = data;
    if (shouldLimitData(data, options)) {
        sortedData = sortDataByValue(sortedData);
    }

    const cols = limitDataBarChart(
        sortedData.map((datum) => datum.label),
        options
    );
    const rows = limitDataBarChart(
        sortedData.map((datum) => datum.value),
        options
    );

    return <BarWidget rows={rows} cols={cols} />;
};

const RatioChart = ({ data, options }) => {
    let ratio = '-';
    if (data && data.length === 2) {
        const count1 = data[0][0].value;
        const count2 = data[1][0].value;
        if (count2 > 0) {
            ratio = parseInt((count1 / count2) * 100, 10);
        }
    }
    return (
        <div>
            <div style={{ fontSize: '42px', fontWeight: 'bold', textAlign: 'center', marginTop: '20px' }}>{ratio}%</div>
        </div>
    );
};

const charts = {
    'pie chart': PieChart,
    'bar chart': BarChart,
    table: TableChart,
    top: TableChart,
    bottom: TableChart,
    count: CountWidget,
    ratio: RatioChart,
    orderDesc: OrderByChart,
    orderAsc: OrderByChart,
};

const formatValue = (str) => {
    // date format
    if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}T/.test(str)) {
        const day = str.split('T')[0];
        if (day) {
            return day;
        }
    }
    return str;
};

const keyLabels = {
    name: 'Entity',
    segment: 'Segment',
    'date-field': 'Date field',
    'date-period': 'Date period ',
    startDate: 'Start date',
    endDate: 'End date',
};

const RecursiveList = ({ obj, index = 1 }) => {
    if (obj && typeof obj === 'object') {
        return Object.keys(obj).map((key) => {
            const label = keyLabels[key] || key;
            if (typeof obj[key] === 'object') {
                const marginLeft = index * 10;
                return (
                    <>
                        <li>
                            <span style={{ fontWeight: 'bold' }}>{label}</span>
                        </li>
                        <div style={{ display: 'block', opacity: 0.75, marginLeft: `${marginLeft}px` }}>
                            <RecursiveList obj={obj[key]} index={index++} />
                        </div>
                    </>
                );
            }

            return (
                <li>
                    <span style={{ fontWeight: 'bold' }}>{label}</span>: {formatValue(obj[key])}
                </li>
            );
        });
    }
    return <div>Unable to show the settings</div>;
};

const WidgetSettings = ({ parameters }) => {
    return <RecursiveList obj={parameters['models']} />;
};

const ChartX = ({ type, data, options }) => {
    const Chart = charts[type];
    if (Chart) {
        return <Chart data={data} options={options} />;
    }

    return 'No matching display type found';
};

export class UserDefinedWidget extends Component {
    constructor(props) {
        super(props);

        this.popup = document.createElement('div');

        this.state = {
            initialRows: null,
            rows: null,
            showPopup: false,
            popupPosition: [],
        };
    }

    componentDidMount() {
        document.body.appendChild(this.popup);
    }
    componentWillUnmount() {
        document.body.removeChild(this.popup);
    }

    openPopup = (e) => {
        // remove 220px from pageX to avoid the popup to be hidden on the right
        // 220px is the popup size (200px) plus the button size (20px)
        this.setState({ showPopup: true, popupPosition: [e.pageX - 240, e.pageY - 120] });
    };

    hidePopup = () => {
        this.setState({ showPopup: false });
    };

    render() {
        const { data, options, parameters } = this.props;
        const { showPopup, popupPosition } = this.state;
        const [popupX, popupY] = popupPosition;

        return (
            <>
                <ChartX data={data} type={parameters.chart} options={options} />
               
                {userHasRoleADMIN() && (
                    <div
                        style={styles.infoButton}
                        onMouseEnter={this.openPopup}
                        onMouseLeave={this.hidePopup}
                        className={'widget-hover widget-question'}
                    >
                        <i className="fa fa-question" style={styles.infoIcon}></i>
                    </div>
                )}
                {showPopup &&
                    ReactDOM.createPortal(
                        <div style={{ ...styles.popup, top: `${popupY + 20}px`, left: `${popupX - 20}px` }}>
                            <h3>Widget settings quickview</h3>
                            <div>
                                <WidgetSettings parameters={parameters} />
                            </div>
                        </div>,
                        this.popup
                    )}
            </>
        );
    }
}

const styles = {
    linkButton: {
        height: '25px',
        width: '25px',
        lineHeight: '25px',
        textAlign: 'center',
        borderRadius: '25px',
        background: 'rgba(0, 0, 0,0.05)',
        cursor: 'pointer',
        position: 'absolute',
        bottom: 0,
        right: '5px',
        margin: '5px',
    },
    infoButton: {
        height: '25px',
        width: '25px',
        lineHeight: '25px',
        textAlign: 'center',
        borderRadius: '25px',
        background: 'rgba(0, 0, 0,0.05)',
        cursor: 'pointer',
        position: 'absolute',
        bottom: 0,
        right: 0,
        margin: '5px',
        right: '35px',
    },
    infoIcon: {
        color: 'rgba(0, 0, 0, 0.65)',
    },
    popup: {
        position: 'fixed',
        padding: '5px 20px 20px 20px',
        borderRadius: '6px',
        background: 'white',
        width: '200px',
        boxShadow: '0px 2px 10px 0 rgba(0, 0, 0, 0.15)',
        zIndex: 1000,
        overflow: 'hidden',
        fontSize: '12px',
        lineHeight: '16px',
    },
};
