import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from "@material-ui/core";
import { ModalContent } from "../../Modal/ModalContent";

export const UnauthorizedModelInProductionModal = (props) => {
    const { models, showModelsList = false } = props;

    return (
        <ModalContent>
            <Typography component={'p'}>
                A model running in production (field &quot;production run&quot; = yes) should either be validated or
                have a policy exception.
            </Typography>
            <Typography component={'p'}>
                Please contact Model Governance in order to comply with the Policy Exception Procedure.
            </Typography>
            {showModelsList && <>
            <Typography component={'h3'}>Models :</Typography>
            <Typography component={'ul'}>
                    {models.map(m => <Typography component={'li'} key={`unauthorized_model_${m.id}`}>{m.toString} - {m.name}</Typography>)}
            </Typography>
            </>}
        </ModalContent>
    );
};
UnauthorizedModelInProductionModal.propTypes = {
    models: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any,
        toString: PropTypes.string,
        name: PropTypes.string,
    })),
    showModelsList: PropTypes.bool,
}
