import React, { useState } from "react";
import PropTypes from "prop-types";
import { ButtonBar } from "../../Modal/ButtonBar";
import { ActionButton } from "../../Modal/ActionButton";
/**
 * Structure par défaut pour des choix simples
 */
export const ValidationForm = (props) => {
    const { description, actions } = props;

    const [loading, setLoading] = useState(false);
    const [buttonClicked, setButtonClicked] = useState();

    const clickWrapper = (onClick, clicked) => {
        setLoading(true);
        setButtonClicked(clicked);
        return onClick;
    };

    return (
        <>
            {description && <h2>{description}</h2>}
            <ButtonBar>
                {actions.map((action) => (
                    <ActionButton
                        key={action.label}
                        onClick={(e) =>
                            clickWrapper(action.onClick, action.label)(e, () =>
                                (setLoading(false), setButtonClicked())
                            )
                        }
                        disabled={loading}
                        loading={buttonClicked === action.label && loading}
                    >
                        {action.label}
                    </ActionButton>
                ))}
            </ButtonBar>
        </>
    );
};
ValidationForm.propTypes = {
    description: PropTypes.string,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            onClick: PropTypes.func,
        })
    ),
};
