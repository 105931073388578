import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Http from "../../Services/Http";
import Alert from "../../Services/Alert";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import ParameterStore, {userHasRoleMRM} from '../../Store/ParameterStore';
import Modal from "../../Services/Modal";
import User from "../../Services/User/User";
import { ActionButton } from "../Modal/ActionButton";
import { Typography } from "@material-ui/core";
import { ButtonBar } from "../Modal/ButtonBar";
import {FINDING_STATUS_DRAFT, FINDING_STATUS_CLOSED, FINDING_STATUS_DELETED} from "../../Admin/FindingAdmin";
import { openAutomaticScoringInformationModal } from "../Mra/Display/AutomaticScoringInformationModal";
import { doAllFindingsHaveCoherentMras, openIncoherentModelsInformationModal } from "../Mra/Display/IncoherentModelsInformationModal";

const AssociateFindingNotice = (props) => {

    const {entity, resource, resourcePath, findings = [], notices = [], parentResourceComponent} = props;
    const [selectionFinding, setSelectionFinding] = useState([]);
    const [selectionNotice, setSelectionNotice] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [errorOnSelection, setErrorOnSelection] = useState(false);
    const [NASelected, setNASelected] = useState(false);

    const history = useHistory();

    useEffect(() => {
        setErrorOnSelection(false);
        setNASelected(false);
        let previousItem = null
        selectionFinding.forEach((itemId) => {
            let item = findItemFromId(itemId, 'finding');
            if(previousItem === null){
                previousItem = item;
            }
            if(previousItem !== null && !sameSeverityCategory(previousItem,item)){
                setErrorOnSelection(true)
            }
            if (item.severity === ParameterStore('FINDING_SEVERITY_NA')) setNASelected(true);
        })
        selectionNotice.forEach((itemId) => {
            let item = findItemFromId(itemId, 'notice');
            if(previousItem === null){
                previousItem = item;
            }
            if(previousItem !== null && !sameSeverityCategory(previousItem,item) && item.severity !== ''){
                setErrorOnSelection(true)
            }
        })
    }, [selectionFinding, selectionNotice]);

    function onSelectionChange(item, type) {
        let sel = [...selectionFinding];
        if (type === 'notice') {
            sel = [...selectionNotice];
        }
        if (sel.indexOf(item.id) === -1) {
            sel.push(item.id);
        } else {
            for (let i in sel) {
                if (sel[i] === item.id) {
                    sel.splice(i, 1);
                }
            }
        }
        return sel;
    }

    function findItemFromId(id, type){
        let sel = [...findings];
        if (type === 'notice') {
            sel = [...notices];
        }
        let findItem = null
        sel.forEach((item) => {
            if(item.id === id && findItem === null){
                findItem = item
            }
        })
        return findItem
    }

    /**
     * @deprecated on ne fait plus de différence entre ntx et bpce pour la sévérité
     * {@see https://app.asana.com/0/1173729351441775/1208378365098004/f}
     */
    function sameSeverityCategory(_reference, _candidate) {
        return true;
    }

    const displayList = (source, type) => {
        let rows = [];
        source.forEach(
            (item, index) => {
                item.type = type;
                if (
                    (
                        type === 'finding'
                        && (
                            (item.status === ParameterStore(FINDING_STATUS_DRAFT) && (User.profile.isMemberOfValidatorTeam || userHasRoleMRM()))
                            || item.status !== ParameterStore(FINDING_STATUS_DRAFT)
                        )
                        && item.status !==  ParameterStore(FINDING_STATUS_DELETED)
                        && item.status !==  ParameterStore(FINDING_STATUS_CLOSED)
                    )
                    || (
                        type === 'notice'
                        && (
                            (item.status === ParameterStore('NOTICE_STATUS_DRAFT') && (User.profile.isMemberOfValidatorTeam || userHasRoleMRM()))
                            || item.status !== ParameterStore('NOTICE_STATUS_DRAFT')
                            )
                        && item.status !==  ParameterStore('NOTICE_STATUS_DELETED')
                        && item.status !==  ParameterStore('NOTICE_STATUS_CLOSED')
                    )
                ) {
                    rows.push(
                        <TableRow
                            key={'associate-checkbox-' + type + '-' + index}
                        >
                            <TableCell>
                                <Checkbox
                                    indeterminate={false}
                                    checked={
                                        (type === 'finding' && selectionFinding.indexOf(item.id) !== -1)
                                        || (type === 'notice' && selectionNotice.indexOf(item.id) !== -1)
                                    }
                                    onChange={() => {
                                        if (type === 'finding') {
                                            setSelectionFinding(onSelectionChange(item, type));
                                        } else {
                                            setSelectionNotice(onSelectionChange(item, type));
                                        }
                                    }}
                                    disabled={
                                        (type === 'finding' && item.status === ParameterStore(FINDING_STATUS_DELETED))
                                        || (type === 'notice' && item.status === ParameterStore('NOTICE_STATUS_DELETED'))
                                    }
                                />
                            </TableCell>
                            <TableCell>
                                {item.title}
                            </TableCell>
                            <TableCell>
                                {item.severityString}
                            </TableCell>
                            <TableCell>
                                {item.statusString}
                            </TableCell>
                        </TableRow>
                    )
                }
            }
        );
        return <Table
            className={'table-display small'}
            size={'small'}
        >
            <TableHead>
                <TableRow>
                    <TableCell key={'th_' + type + '_0'}>

                    </TableCell>
                    <TableCell key={'th_' + type + '_1'}>
                        Title
                    </TableCell>
                    <TableCell key={'th_' + type + '_2'}>
                        Severity
                    </TableCell>
                    <TableCell key={'th_' + type + '_3'}>
                        Status
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows}
            </TableBody>
        </Table>
    }

    const associate = () => {
        if (
            selectionFinding.length === 0
            || selectionNotice.length === 0
        ) {
            Alert.show({
                message: 'At least one finding and one notice are required',
                type: 'error',
            });
            return;
        }

        if ((entity.mrasEntities || []).filter(mra => mra.isOpen).length > 0) {
            const [isCoherent, incoherentFinding] = doAllFindingsHaveCoherentMras(findings.filter(f => selectionFinding.includes(f.id)), entity.mrasEntities);
            if (!isCoherent) {
                openIncoherentModelsInformationModal({
                    onConfirm: () => history.push({
                        pathname: `/resource/findings/${incoherentFinding.id}/edit`,
                        search: '?highlight=models',
                    }),
                    mraExists: true,
                });
                Modal.close();
                return;
            }
        }
        setProcessing(true);
        Http
            .post(
                'reviews/associate-finding-notice',
                {findings: selectionFinding, notices: selectionNotice}
            )
            .then((res) => {
                Alert.show({
                    message: 'Your findings and notices are successfully associated.',
                    type: 'success',
                })
                setProcessing(false)
                setSelectionFinding([])
                setSelectionNotice([])
                resource.apiGetOne(entity.id, true).then((e) => {
                    if(parentResourceComponent){
                        parentResourceComponent.entity = e;
                        parentResourceComponent.forceUpdate();
                    }
                    Modal.close()
                });
            })
            .catch((err) => {
                setProcessing(false)
            })
        ;
    }

    return (
        <Grid container spacing={2} className="container">
            {
                findings.length === 0 || notices.length === 0 ?
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        Please create at least one finding and one notice.
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <Grid container spacing={2} className="container">
                            <Grid item xs={6}>
                                {displayList(findings, 'finding')}
                            </Grid>
                            <Grid item xs={6}>
                                {displayList(notices, 'notice')}
                            </Grid>
                        </Grid>
                    </Grid>
            }
            <Grid item xs={12} style={{textAlign: 'center'}}>
                {NASelected ? (
                    <Typography>Error : you can&apos;t associate findings of severity NA with a notice</Typography>
                ) : (
                    errorOnSelection && (
                        <Typography>
                            Error: you can&apos;t associate findings/notices with different severity scales
                        </Typography>
                    )
                )}
                {   !errorOnSelection 
                    && !NASelected
                    && <ButtonBar>
                            <ActionButton
                                variant="contained"
                                color="secondary"
                                onClick={() => openAutomaticScoringInformationModal(() => associate())}
                                disabled={selectionFinding.length === 0 || selectionNotice.length === 0}
                                loading={processing}
                            >
                                Associate findings to notices
                            </ActionButton>
                        </ButtonBar>
                }
            </Grid>
        </Grid>
    );
}

export default AssociateFindingNotice;