import React from 'react';
import { APIResource } from "../Services/APIResource/APIResource";
import { ChangeLog } from "../Components/Display/ChangeLog/ChangeLog";
import {
    PARAMETER_TYPE_RECOMMENDATION_IGG_STATUS,
    PARAMETER_TYPE_RECOMMENDATION_PRIORITY,
    PARAMETER_TYPE_RECOMMENDATION_ORIGIN,
    PARAMETER_TYPE_RECOMMENDATION_RESPONSIBLE,
    PARAMETER_TYPE_RECOMMENDATION_RISK_CATEGORY
} from '../Admin/ParameterAdmin';
import { BulkEdit } from "../Services/BulkActions/BulkEdit/BulkEdit";
import APIResourceStore from "../Store/APIResourceStore";
import {userHasRoleMRM, userHasValidatorRights, userValidatorTeamManagedRights} from '../Store/ParameterStore';
import User from "../Services/User/User";

export const ApiResourceDefaultParams = {
    id: 'recommendation_igg_bces',
    name: 'IGG / BCE Recos',
    fieldForTitle: 'missionTitle'
};

export default class RecommendationIggBceAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        let resourceRecommendationIggBce = new APIResource(ApiResourceDefaultParams);
        resourceRecommendationIggBce
            .setFields({
                id: { title: 'ID', type: 'text' },
                missionTitle: { title: 'Mission title', type: 'text', maxLength:255 },
                reference: { title: 'Reference', type: 'text', maxLength:255 },
                reportReference: { title: 'Report reference', type: 'text', maxLength:255 },
                title: { title: 'Title', type: 'text' },
                status: {
                    title: 'Status',
                    type: 'parameter',
                    params: { type: PARAMETER_TYPE_RECOMMENDATION_IGG_STATUS, multi: false },
                    required: true,
                },
                priority: {
                    title: 'Priority',
                    type: 'parameter',
                    params: { type: PARAMETER_TYPE_RECOMMENDATION_PRIORITY, multi: false },
                    required: true,
                },
                origin: {
                    title: 'Origin',
                    type: 'parameter',
                    params: { type: PARAMETER_TYPE_RECOMMENDATION_ORIGIN, multi: false },
                    required: true,
                },
                responsibleEntities: {
                    title: 'Responsible entity',
                    type: 'parameter',
                    params: { type: PARAMETER_TYPE_RECOMMENDATION_RESPONSIBLE, multi: true },
                    required: true,
                },
                riskCategory: {
                    title: 'Risk Category',
                    type: 'parameter',
                    params: { type: PARAMETER_TYPE_RECOMMENDATION_RISK_CATEGORY, multi: false },
                    required: true,
                },
                deliverable: { title: 'Deliverable', type: 'text' },
                associatedWeakedPoint: { title: 'Associated weaked point', type: 'text' },
                actionPlan: { title: 'Action plan', type: 'text' },
                dueDate: { title: 'Due date', type: 'date' },
                lastValidatedProgress: { title: 'Progress (%)', type: 'float' },
                models: {
                    title: 'Models',
                    type: 'model',
                    params: {
                        resource: 'models',
                        instanceId: 'allModels',
                        displayField: 'toString',
                        links: true,
                        multi: true,
                        linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                        endpoints: {
                            getAll: 'models/all-models',
                        },
                    },
                    bulk: true,
                    /*edit: (field, value, onChange, entity, routeParams) => {
                        entity.models =
                            routeParams.modelId && !entity.models ? ['/api/models/' + routeParams.modelId] : value;
                        return (
                            <EntitySelect
                                label="Models"
                                resourceId="models"
                                resourceLabel="toString"
                                value={entity.models}
                                onChange={onChange}
                                clearable={true}
                                returnApiPath={true}
                                multi={true}
                                issueButton={true}
                                filters={field.params.filters}
                            />
                        );
                    },*/
                },
                comment: { title: 'Comment', type: 'textarea', bulk: true },
                importDate: { title: 'Import date', type: 'date' },
                updateDate: { title: 'Update date', type: 'date' },
                changeLogsEntities: {
                    title: 'Audit trail',
                    display: (field, value, entity, props) => (
                        <ChangeLog
                            field={field}
                            values={value}
                            entity={entity}
                            entityResource={'notices'}
                            props={props}
                        />
                    ),
                    displayList: () => null,
                },
                //Additional fields without relation with Notice
                changeLogComment: {
                    title: 'Justification of the data update',
                    type: 'textarea',
                    display: (field, value, entity, props) => null,
                    displayList: (field, value, entity, props) => null,
                    token: false,
                },
            })
            .setValidation((entity) => {
                return true;
            })
            .setLayout({
                tabs: {
                    General: {
                        rows: [
                            {
                                panels: {
                                    Identification: {
                                        cols: 4,
                                        fields: ['missionTitle', 'reference', 'reportReference', 'title'],
                                    },
                                    Planning: {
                                        cols: 4,
                                        fields: ['status', 'priority', 'lastValidatedProgress', 'dueDate'],
                                    },
                                    Attributions: {
                                        cols: 4,
                                        fields: ['origin', 'responsibleEntities'],
                                    },
                                },
                            },
                            {
                                panels: {
                                    Purpose: {
                                        cols: 6,
                                        fields: ['deliverable', 'associatedWeakedPoint', 'actionPlan', 'models'],
                                    },
                                    Risk: {
                                        cols: 6,
                                        fields: ['riskCategory'],
                                    },
                                },
                            },
                            {
                                panels: {
                                    Comment: {
                                        cols: 12,
                                        fields: ['comment'],
                                    },
                                },
                            },
                        ],
                    },
                    'Audit trail': {
                        rows: [
                            {
                                panels: {
                                    'Audit trail': {
                                        cols: 12,
                                        fields: ['changeLogsEntities', 'changeLogComment'],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                fields: [
                    'id',
                    'missionTitle',
                    'reference',
                    'reportReference',
                    'title',
                    'status',
                    'priority',
                    'origin',
                    'responsibleEntities',
                    'riskCategory',
                    'deliverable',
                    'associatedWeakedPoint',
                    'actionPlan',
                    'dueDate',
                    'lastValidatedProgress',
                    'models',
                    'importDate',
                    'updateDate',
                ],
            })
            .genInsertView({
                menuItem: { title: 'Add' },
                additionalRoutes: ['/resource/recommendation_igg_bces/add/model/:modelId'],
                itemAccessCondition: () => User.profile.isMemberOfValidatorTeam || userHasRoleMRM(),
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'RecommendationIggBce';
                },
            })
            .genEditView({
                fields: [
                    'id',
                    'missionTitle',
                    'reference',
                    'reportReference',
                    'title',
                    'status',
                    'priority',
                    'origin',
                    'responsibleEntities',
                    'riskCategory',
                    'deliverable',
                    'associatedWeakedPoint',
                    'actionPlan',
                    'dueDate',
                    'lastValidatedProgress',
                    'models',
                    'comment',
                    'importDate',
                    'changeLogComment',
                ],
                itemAccessCondition: () => User.profile.isMemberOfValidatorTeam || userHasRoleMRM(),
            })
            .genDetailView({
                fields: [
                    'id',
                    'missionTitle',
                    'reference',
                    'reportReference',
                    'title',
                    'status',
                    'priority',
                    'origin',
                    'responsibleEntities',
                    'riskCategory',
                    'deliverable',
                    'associatedWeakedPoint',
                    'actionPlan',
                    'dueDate',
                    'lastValidatedProgress',
                    'models',
                    'comment',
                    'importDate',
                    'changeLogsEntities',
                ],
                itemAccessCondition: (entity) => {
                    return entity.amIModelOwner
                    || entity.amIModelOwnerDelegated
                    || entity.amIMemberOfDeveloperTeam
                    || userHasValidatorRights(User.getId(), entity)
                    || userValidatorTeamManagedRights(User.profile.managedScopes, entity)
                    || entity.amIBusinessSponsor
                    || userHasRoleMRM();
                }
            })
            .allowDelete({
                itemAccessCondition: () => User.profile.isMemberOfValidatorTeam || userHasRoleMRM(),
            })
            .addBulkAction(BulkEdit, {
                resource: resourceRecommendationIggBce,
                icon: 'edit',
                itemAccessCondition: (_entity) => User.profile.isMemberOfValidatorTeam || userHasRoleMRM(),
                fields: Object.fromEntries(
                    Object.entries(APIResourceStore.resources.recommendation_igg_bces.fields)
                        .filter(
                            ([k, v]) =>
                                v.bulk &&
                                APIResourceStore.resources.recommendation_igg_bces.operations.edit.fields.includes(k)
                        )
                        .map(([k, v]) => ((v.resourceId = 'recommendation_igg_bces'), [k, v]))
                ),
            });
    }
}
