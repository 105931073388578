import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Styles/main.css'
import Root from './Components/Root/Root'
import * as serviceWorker from './serviceWorker';
import RootProvider from './Services/Root';
import './Config/i18n'

ReactDOM.render(<Root ref={(ref) => RootProvider.rootComponent = ref} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
