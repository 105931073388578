import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@material-ui/core';

const NoDecorationLink = styled(Link)({
    marginLeft: 5,
    textDecoration: 'none',
});

export const ActionLink = (props) => {
    return (
        <NoDecorationLink {...props}>
            {props.children}
        </NoDecorationLink>
    );
};
ActionLink.propTypes = Link.propTypes;
