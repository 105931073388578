import React, { Component } from "react";
import APIResourceStore from "../../Store/APIResourceStore";
import { Link as ReactLink  } from 'react-router-dom';
import {getDetailRoute} from "../../Services/APIResource/Utils";

export class LinkPreload extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        //By default all object should be preload ; If ResourceStore knows objet it will be return immediately, if don't, forceReload is used to get objet from API
        if(this.props.to){
            let route = getDetailRoute(this.props.to);
            if(route !== null){
                APIResourceStore.resources[route.resourceId].getObservableItem(route.id)
            }
        }
    }

    render() {
        let self = this;
        return (
            <ReactLink
                {...self.props}
            >
                {self.props.children}
            </ReactLink>
        );
    }
}