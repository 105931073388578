import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UsaFlagImage from '../../Styles/Assets/img/usa.png';
import FranceFlagImage from '../../Styles/Assets/img/france.png';

export const ChangeLangButton = (props) => {
    const { i18n } = useTranslation();

    const changeLang = () => {
        const currentLang = i18n.language;
        if (currentLang === 'en') {
            i18n.changeLanguage('fr');
        } else {
            i18n.changeLanguage('en');
        }
    };

    return (
        <Button variant="contained" className="tooltip tooltip-top button-table" onClick={changeLang} {...props}>
            <span style={{ height: '13px', fontSize: '22px', lineHeight: '0.6' }}>
                {i18n.language === 'en' ? (
                    <img src={UsaFlagImage} style={{ width: '24px', marginTop: '-5px' }} />
                ) : (
                    <img src={FranceFlagImage} style={{ width: '24px', marginTop: '-5px' }} />
                )}
            </span>
            <span className="tooltiptext" style={{ fontSize: '14px' }}>
                Change language
            </span>
        </Button>
    );
};
