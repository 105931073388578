import DefaultProvider from './_DefaultProvider';
import BoolProvider from './BoolProvider';
import DateProvider from './DateProvider';
import EntityProvider from './EntityProvider';
import EntityAsyncProvider from './EntityAsyncProvider';
import IntProvider from './IntProvider';
import FloatProvider from './FloatProvider';
import MappedValueProvider from './MappedValueProvider';
import TextareaProvider from './TextareaProvider';
import TextProvider from './TextProvider';
import ParameterProvider from './ParameterProvider';
import MappedStringProvider from './MappedStringProvider';
import EntityTreeProvider from './EntityTreeProvider';
import NaProvider from './NaProvider';
import LinksProvider from './LinksProvider';
import ModelProvider from './ModelProvider';
import UserProvider from './UserProvider';
import UserAsyncProvider from './UserAsyncProvider';

export default {
    default: DefaultProvider,
    bool: BoolProvider,
    date: DateProvider,
    entity: EntityProvider,
    entityAsync: EntityAsyncProvider,
    entityTree: EntityTreeProvider,
    int: IntProvider,
    float: FloatProvider,
    mapped: MappedValueProvider,
    mappedObject: MappedStringProvider,
    model: ModelProvider,
    parameter: ParameterProvider,
    textarea: TextareaProvider,
    text: TextProvider,
    user: UserProvider,
    userAsync: UserAsyncProvider,
    links: LinksProvider,
    na: NaProvider,
};
