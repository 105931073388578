import React from 'react';
import {Icon, styled}  from '@material-ui/core';

const LoadingIcon = styled(Icon)({ 
    marginLeft: "10px"
});


export const LoadingSpinner = (props) => {
    return <LoadingIcon className="fa fa-circle-notch text-primary fa-rotate fa-spin" {...props} />;
};

LoadingSpinner.propTypes = Icon.propTypes;