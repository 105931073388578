import React from 'react';
import PropTypes from 'prop-types';
import { components as ReactSelectComponents } from 'react-select';

const defaultStyles = {
    display: 'flex',
    transition: 'color 150ms',
    alignSelf: 'center',
    fontSize: '0.5rem',
    margin: '0.5rem',
    textAlign: 'center',
};

const LoadingIndicator = (props) => {
    const { styles = defaultStyles } = props;

    const { LoadingIndicator } = ReactSelectComponents; // https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/components/index.js

    return <LoadingIndicator {...props} cx={() => true} getStyles={() => styles} />;
};
LoadingIndicator.propTypes = {
    styles: PropTypes.object,
};

export default LoadingIndicator;
