/* eslint-disable react/prop-types */
import React, {Component} from "react";
import AsyncSelect from "react-select/async";
import {IssueButton} from "../../Issue/IssueButton";
import {APIResource} from "../../../Services/APIResource/APIResource";
import { asyncDebounce } from "../../../Services/utils";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

export class EntitySelectAsync extends Component{
    constructor(props) {
        super(props);
        this.loadOptions = this.loadOptions.bind(this);

        this.state = {
            value: -1,
            issueButton: this.props.issueButton,
        };
        this.init = false;

        this.resource = new APIResource({
            instanceId:
                "entity_select_async_" + (this.props.instanceId ? this.props.instanceId : this.props.resourceId),
            id: this.props.resourceId,
            name: this.props.label,
            endpoints: this.props.endpoints || null
        });
    }

    componentDidMount(){
        this.updateValue(this.props.value);
    }
    
    componentDidUpdate(prevProps){
        if(this.props.value !== prevProps.value){
            this.updateValue(this.props.value);
        }
    }

    updateValue(value){
        if(Array.isArray(value)){
            if (value.length) {
                if (typeof value[0] != "object") {
                    let promises = [];  
                    let values = [];
                    value.forEach(val => {
                        let promise = this.resource
                            .getItemFromResourcePath(val)
                            .then(entity => {
                                values.push({
                                    value: entity["@id"],
                                    label: entity[this.props.resourceLabel],
                                    id: entity.id
                                });
                            });

                        promises.push(promise);
                    });
                    Promise.all(promises).then(() => {
                        this.setState({value: values})
                    });
                } else {
                    this.setState({value: value})
                }
            }
        }
        else {
            if (value && typeof value != "object") {
                this.resource.getItemFromResourcePath(value).then(entity => {
                    if (entity) {
                        this.setState({value: {
                                value: entity["@id"],
                                label: entity[this.props.resourceLabel],
                                id: entity.id
                            }
                        });
                    }
                });
            } else {
                this.setState({value: value});
            }
        }
    }

    loadOptionsStandard(inputValue){
        return new Promise((resolve) => {
            if (this.props.noOption && inputValue.length < 3) {
                resolve([]);
                return;
            }
            if (inputValue === '' || inputValue.length >= 3) {
                this.resource?.currentRequestList?.cancel?.('NoAlert');
                this.getItems(inputValue).then((values) => {
                    resolve(values);
                });
            } else {
                resolve([]);
            }
        });
    }
    
    loadOptions = asyncDebounce(this.loadOptionsStandard.bind(this), 500);

    handleChange(selection, callback){
        if (!selection && this.props.multi) {
            selection = [];
        }
        if (this.props.onChange) {
            if (!Array.isArray(selection)) {
                if (!this.props.returnId) {
                    this.props.onChange(selection ? selection.value : null);
                } else {
                    this.props.onChange(selection ? selection.id : null);
                }
            } else {
                if (!this.props.returnId) {
                    this.props.onChange(selection.map(item => item.value));
                } else {
                    this.props.onChange(selection.map(item => item.id));
                }
            }
        }
        this.setState({ value: selection }, callback);
    }

    selectAll = () => {
        this.getItems('').then((values) => {
            this.updateValue(values);
            this.handleChange(values);
        });
    }

    getItems(search){
        return new Promise((resolve) => {
        this.resource
            .apiGetCollection({
                page: 1,
                rowsPerPage: -1,
                filters: {
                    search: search,
                },
            })
            .then((entities) => {
                let items = [];
                if (this.resource) {
                    items = this.resource
                        .filterItems(this.props.filters, this.props.entity)
                        .map((item) => ({
                            id: item.id,
                            value: item['@id'],
                            label: item[this.props.resourceLabel],
                        }));
                } else {
                    items = entities.map((entity) => {
                        return {
                            id: entity.id,
                            value: entity['@id'],
                            label: entity[this.props.resourceLabel],
                        };
                    });
                }
                resolve(items);
            });
        });
    }

    render() {
        return (
            <div className={'select-component' + ' ' + (this.props.helperText ? 'field-error-control' : '')}>
                {this.props.label ? (
                    <label>
                        {this.props.label}
                        {this.props.required ? ' *' : ''}
                    </label>
                ) : (
                    ''
                )}
                <div className={this.state.issueButton || this.props.addSelectAll ? 'with-issue-button' : ''}>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={this.loadOptions}
                        defaultOptions
                        isSearchable={this.props.searchable}
                        isClearable={this.props.clearable}
                        isDisabled={this.props.disabled}
                        placeholder="Search (min. 3 char.)"
                        isMulti={this.props.multi}
                        label={`${this.props.label}`}
                        required={this.props.required}
                        onChange={(selection) => this.handleChange(selection)}
                        onInputChange={this.props.onInputChange}
                        value={this.state.value}
                        error={!!this.props.helperText}
                        className={'entity-select entity-select-async'}
                        noOptionsMessage={() => "No result matching your search (type at least 3 characters)"}
                        components={{
                            DropdownIndicator: () => (
                                <i className="fas fa-search" style={{ padding: '0 10px', color: 'hsl(0,0%,60%)' }} />
                            ),
                        }}
                    />
                    {this.state.issueButton ? (
                        <IssueButton
                            field={this.props.label}
                            issueButton={this.state.issueButton}
                            entity={this.props.entity}
                        />
                    ) : null}
                    {this.props.addSelectAll ? (
                        <Button variant="contained" color="primary" onClick={this.selectAll} className={"select-all"}>
                            Select all
                        </Button>
                    ) : null}
                </div>
            </div>
        );
    }
}
