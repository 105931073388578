import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Alert from '../../Services/Alert';
import Http from '../../Services/Http';
import Grid from '@material-ui/core/Grid';
import Modal from '../../Services/Modal';
import {ButtonBar} from '../Modal/ButtonBar';
import {ActionButton} from '../Modal/ActionButton';
import {DatePicker} from "../Forms/DatePicker/DatePicker";
import {userHasRoleMRM} from "../../Store/ParameterStore";
import {Checkbox, FormControlLabel} from '@material-ui/core';


export class EntityExport {
    getId() {
        return 'EntityExport';
    }

    getLabel() {
        return 'Export';
    }

    setParameters(params) {
        this.parameters = params;
    }

    /**
     * Déclenché lors des bulkActions
     *
     * @param {array} ids
     * @param {ApiResource} params Utilisé quelque part ?
     */
    run(ids) {
        this.ids = ids;

        Modal.open({
            title: 'Export',
            content: <EntityExportForm ids={this.ids} {...this.parameters} />,
        });
    }
}

export const EntityExportForm = (props) => {
    const {ids, resource, label = '', context = '', withDate = false, showModelLevelCheckbox = false} = props;

    const [progress, setProgress] = useState(false);
    const [date, setDate] = useState(new Date());
    const [exportAtModelLevel, setExportAtModelLevel] = useState(false)

    const today = new Date();

    const close = () => {
        setProgress(false);
        Modal.close();
    };

    const save = () => {
        setProgress(true);
        const args = {};
        if (ids !== undefined && ids.length > 0) args[resource] = ids;
        args['context'] = context;
        if (withDate) {
            args['date'] = date;
        }
        if (showModelLevelCheckbox) {
            args['exportAtModelLevel'] = exportAtModelLevel;
        }
        Http.post(`${resource}/export`, args).then((response) => {
            if (response.status === 'ERROR') {
                Alert.show({message: response.message, type: 'error'});
                close();
            } else if (response.status === 'PENDING') {
                Alert.show({message: response.message, type: 'success'});
                close();
            } else if (response.status === 'OK') {
                if (response.route && response.fileName) {
                    Http.openFile(response.route, response.fileName).then(() => close());
                } else if (response.documentId && response.documentName) {
                    Http.openEncryptedFile(
                        "/document/download/" +
                        response.documentId,
                        response.documentName
                    )
                    close();
                } else if (response.message) {
                    Alert.show({message: response.message, type: 'success'});
                    close();
                }
            } else {
                Alert.show({message: "File can't be downloaded", type: "error"});
                close();
            }
        });
    };

    return (
        <Grid className={'export container'}>
            <h1 style={{textTransform: 'capitalize'}}>{label || `${resource} export`}</h1>
            {withDate && (
                <DatePicker label={"Export inventory as of"} value={date} onChange={(value) => setDate(value)}
                            minDate={new Date("2022-04-23")} maxDate={new Date()}/>
            )}
            {
                userHasRoleMRM() && showModelLevelCheckbox && date instanceof Date && date.toDateString() === today.toDateString() && (
                    <FormControlLabel
                        label="Export at model level"
                        control={
                            <Checkbox
                                checked={exportAtModelLevel}
                                onChange={(event) => {
                                    setExportAtModelLevel(event.target.checked);
                                }}
                            />
                        }
                    />
                )
            }
            <ButtonBar>
                <ActionButton onClick={save} loading={progress}>
                    {progress ? 'Please wait....' : 'Export'}
                </ActionButton>
            </ButtonBar>
        </Grid>
    );
};
EntityExportForm.propTypes = {
    /** Type de l'entité à exporter, ex : notices */
    resource: PropTypes.string.isRequired,
    label: PropTypes.string,
    /** Limite l'export à ces ids éventuels */
    ids: PropTypes.arrayOf(PropTypes.number),
    /** Affiche la checkbox pour déclencher un export avec une ligne par model, si l'utilisateur est MRM */
    showModelLevelCheckbox: PropTypes.bool,
};
