import React from "react";
import { APIResource } from "../Services/APIResource/APIResource";
import {
    DOCUMENT_ACTION_LIST,
    DOCUMENT_ACTION_SHOW,
    DOCUMENT_CATEGORY_MINUTES,
    DocumentManager
} from "../Components/Display/DocumentManager/DocumentManager";
import {
    PARAMETER_TYPE_REVIEW_COMMITTEE_SUBTYPE,
    PARAMETER_TYPE_REVIEW_COMMITTEE_TYPE,
} from "../Admin/ParameterAdmin";
import ParameterStore, {
    userHasRoleADMIN,
    userHasRoleMRM,
    userHasSpecificRole,
    userIsVal
} from "../Store/ParameterStore";
import EntityProvider from "../Services/APIResource/FieldProviders/EntityProvider";
import ParameterProvider from "../Services/APIResource/FieldProviders/ParameterProvider";
import TextareaProvider from "../Services/APIResource/FieldProviders/TextareaProvider";
import {Link} from "react-router-dom";
import User, {ROLE as Role} from "../Services/User/User";
import AssociateCommitteeReviewDocument from "../Components/Review/AssociateCommitteeReviewDocument";

const additionalLinkButton = (entity) => {
    let additionalActionButtons = [];

    let reviews = [];
    if(entity.reviewsEntities){
        entity.reviewsEntities.forEach((review) => {
            if(review) {
                reviews.push({
                    title: review.title,
                    link: '/resource/reviews/' + review.id + '/detail',
                });
            }
        })
    }
    if(reviews.length > 0){
        additionalActionButtons.push({
            links: reviews,
            tooltip: 'Associated reviews',
            icon: 'chevron-left'
        });
    }

    return additionalActionButtons;
};

export const ApiResourceDefaultParams = {
    id: "review_committees",
    name: "Committee",
};

new APIResource({
    id: "committee_review_documents",
    name: "Committee documents by review",
});

export default class ReviewCommitteeAdmin {
    constructor() {
        this.configure();
    }

    configure() {
        let resourceReviewCommittees = new APIResource(ApiResourceDefaultParams);
        resourceReviewCommittees
            .setFields({
                id: {title: "ID", type: "text"},
                type: {
                    title: "Type",
                    type: "parameter",
                    params: {
                        type: PARAMETER_TYPE_REVIEW_COMMITTEE_TYPE,
                        multi: false,
                    },
                    edit: (field, value, onChange, entity, routeParams) => {
                        if (routeParams.typeId) {
                            entity.type = routeParams.typeId
                                ? "/api/parameters/" + routeParams.typeId
                                : value;
                            return ParameterProvider.getDisplay(field, entity.type, entity, {});
                        }else{
                            return ParameterProvider.getEdit(field, entity.type, onChange, entity, {});
                        }
                    },
                    filter: () => null,
                },
                subType: {
                    title: "Sub-type",
                    type: "parameter",
                    params: {
                        type: PARAMETER_TYPE_REVIEW_COMMITTEE_SUBTYPE,
                        multi: false,
                        filters: (parameter, entity, key, context) => {
                            if (
                                !entity //Pas d'entity alors on est en liste, pour le cas de la liste Committee (la meeting n'existe pas), on ne souhaite pas avoir les sub-type des meetings en tant qu'option du select filter
                                && (
                                    parameter['@id'] === ParameterStore('REVIEW_COMMITTEE_SUB_TYPE_KICK_OFF_MEETING') ||  //Kick-Off Meeting
                                    parameter['@id'] === ParameterStore('REVIEW_COMMITTEE_SUB_TYPE_FOLLOW_UP_MEETING') || //Follow-up Meeting
                                    parameter['@id'] === ParameterStore('REVIEW_COMMITTEE_SUB_TYPE_MID_TERM_MEETING') ||  //Mid-term Meeting
                                    parameter['@id'] === ParameterStore('REVIEW_COMMITTEE_SUB_TYPE_CLOSING_MEETING')      //Closing Meeting

                                )
                            ) {
                                return false;
                            }
                            return true;
                        },
                    },
                    edit: (field, value, onChange, entity, routeParams) => {
                        if (routeParams.subTypeId && entity.subType === undefined) {
                            entity.subType = routeParams.subTypeId
                                ? "/api/parameters/" + routeParams.subTypeId
                                : value;
                        }
                        return ParameterProvider.getEdit(field, entity.subType, onChange, entity, {});
                    },
                },
                date: {
                    title: "Date",
                    type: "date",
                    required: true,
                },
                description: {
                    title: "Description",
                    type: "textarea",
                },
                reviews: {
                    title: "Reviews",
                    type: "entity",
                    params: {
                        resource: "reviews",
                        displayField: "toString",
                        links: true,
                        linkPath: (entity) => '/resource/reviews/' + entity.id + '/detail',
                        multi: true,
                        tooltip: (entity) => entity.title,
                        searchedFields: ['id', '@id', 'reviewId'],
                    },
                    edit: (field, value, onChange, entity, routeParams) => {
                        if (
                            !entity.reviews &&
                            (routeParams.reviewId || value)
                        ) {
                            entity.reviews = routeParams.reviewId
                                ? ["/api/reviews/" + routeParams.reviewId]
                                : value;
                        }

                        if(
                            !entity.type
                            || entity.type === ParameterStore('REVIEW_COMMITTEE_TYPE_COMMITTEE')
                            || (
                                entity.type === ParameterStore('REVIEW_COMMITTEE_TYPE_MEETING')
                                && userHasRoleMRM()
                            )
                        ){
                            return EntityProvider.getEdit(field, entity.reviews, onChange, entity, routeParams);
                        }
                        return EntityProvider.getDisplay(field, value, entity)
                    },
                },
                reviewsEntities: {
                    title: "Reviews",
                    type: "entity",
                    params: {
                        resource: "reviews",
                        displayField: "toString",
                        links: true,
                        multi: true,
                        filterPropertyName: 'reviews'
                    },
                    displayList: (field, value, entity, _props) => {
                        if(Array.isArray(value)){
                            let render = [];
                            for(let i in value){
                                if(
                                    userIsVal(User,value[i])
                                    || userHasRoleMRM()
                                ) {
                                    render.push(
                                        <>
                                            <Link
                                                to={
                                                    '/resource/reviews/' +
                                                    value[i].id +
                                                    '/detail'
                                                }
                                            >
                                                {value[i].toString}
                                            </Link>
                                            <br/>
                                        </>
                                    );
                                }else{
                                    render.push(
                                        <>
                                            {value[i].toString}
                                            <br/>
                                        </>
                                    );
                                }
                            }
                            return TextareaProvider.getDisplay(field, render, entity, _props);
                        }
                        return <></>;//Keep this instead of null
                    },
                    display: (field, value, entity, _props) => {
                        if(Array.isArray(value)){
                            let render = [];
                            for(let i in value){
                                if(
                                    userIsVal(User,value[i])
                                    || userHasRoleMRM()
                                ){
                                    render.push(
                                        <>
                                            <Link
                                                to={
                                                    '/resource/reviews/' +
                                                    value[i].id +
                                                    '/detail'
                                                }
                                            >
                                                {value[i].toString}
                                            </Link>
                                            <br/>
                                        </>
                                    );
                                }else{
                                    render.push(
                                        <>
                                            {value[i].toString}
                                            <br/>
                                        </>
                                    );
                                }
                            }
                            return TextareaProvider.getDisplay(field, render, entity, _props);
                        }
                        return <></>;//Keep this instead of null
                    },
                },
                retiredModels: {
                    title: "Retired models",
                    type: "model",
                    params: {
                        resource: "models",
                        displayField: "toString",
                        links: true,
                        linkPath: (entity) => '/resource/models/' + entity.id + '/detail',
                        multi: true,
                        endpoints: {
                            getAll: 'models/all-retired-models',
                        },
                    },
                },
                documentsEntities: {
                    title: "Documents",
                    display: (field, value, entity, _props, _resourceDetailComponent, _context) => (
                        <>
                            <AssociateCommitteeReviewDocument
                                entity={entity}
                                reviews={entity.reviewsEntities}
                                resource={resourceReviewCommittees}
                                parentResourceComponent={_resourceDetailComponent}
                            />
                            { Array.isArray(value) && value.length > 0 &&
                                <DocumentManager values={value} entity={entity} entityResource={'review_committees'} allowedAction={(entity, document, action) => { return action === DOCUMENT_ACTION_LIST || action === DOCUMENT_ACTION_SHOW; }} />
                            }
                        </>
                    ),
                    displayList: () => null,
                },
            })
            .setLayout({
                tabs: {
                    General: {
                        rows: [
                            {
                                panels: {
                                    Properties: {
                                        cols: 12,
                                        fields: [
                                            "type",
                                            "subType",
                                            "date",
                                            "reviews",
                                            "description",
                                            "retiredModels",
                                        ],
                                    },
                                },
                            },
                        ],
                    },
                    Documents: {
                        rows: [
                            {
                                panels: {
                                    Documents: {
                                        cols: 12,
                                        fields: ["documentsEntities"],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                fields: ["id", "type", "subType", "date", "reviewsEntities"],
                permanentFilters: { "type": ParameterStore('REVIEW_COMMITTEE_TYPE_COMMITTEE') },
            })
            .genInsertView({
                menuItem: {title: "Add"},
                additionalRoutes: [
                    "/resource/review_committees/add/:reviewId",
                    "/resource/review_committees/add/:reviewId/:typeId",
                    "/resource/review_committees/add/:reviewId/:typeId/:subTypeId",
                ],
                postSaveRedirect: 'detail',
                fields: [
                    "type",
                    "subType",
                    "date",
                    "reviews",
                    "description",
                    "retiredModels"
                ],
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'ReviewCommittee';
                },
                additionalLinkButton: (entity, resource, resourceEditComponent) => {
                    let additionalActionButtons = [];

                    if (
                        resourceEditComponent.props
                        && resourceEditComponent.props.match
                        && resourceEditComponent.props.match.params
                        && resourceEditComponent.props.match.params.reviewId
                    ) {
                        additionalActionButtons.push({
                            link:
                                '/resource/reviews/' + resourceEditComponent.props.match.params.reviewId + '/detail?tab=Meetings',
                            tooltip: 'Associated review',
                            icon: 'chevron-left'
                        });
                    }

                    return additionalActionButtons;
                }
            })
            .genEditView({
                fields: [
                    "type",
                    "subType",
                    "date",
                    "reviews",
                    "description",
                    "retiredModels"
                ],
                additionalLinkButton: (entity) => additionalLinkButton(entity),
                routeAccessControl: () => User.profile.isMemberOfValidatorTeam === true || userHasSpecificRole(Role.IG) || userHasRoleMRM(),
            })
            .genDetailView({
                fields: [
                    "type",
                    "subType",
                    "date",
                    "reviews",
                    "description",
                    "retiredModels",
                    "documentsEntities",
                ],
                additionalLinkButton: (entity) => additionalLinkButton(entity),
            })
            .allowDelete({
                itemAccessCondition: () => userHasRoleADMIN() || userHasRoleMRM(),
            })
        ;
    }
}