import {decorate, observable} from "mobx/lib/mobx";

class ModelStore{
    currentEditingModel = new Model();
}

decorate(ModelStore, {
    currentEditingModel: observable
});

export class Model {
    constructor(params){
        params = params ? params : {};
        this.id = params.id ? parseInt(params.id) : null;
        this.internalId = '';
        this.technicalId = '';
        this.bpceID = '';
        this.changeLogs = [];
        this.versionCertifications = [];
        this.name = '';
        this.upstreamModel = null;
        this.version = '';
        this.modelParameter = null;
        this.counterpartType = null;
        this.populations = null;
        this.subPopulations = null;
        this.subPopulations2 = null;
        this.additionalSegmentLevels = null;
        this.productionDate = null;
        this.firstUseDate = null;
        this.championOrChallenger = null;
        this.devMode = null;
        this.modelType = null;
        this.mainMethod = null;
        this.macroRiskfamily = null;
        this.microRiskFamily = null;
        this.microRiskSubFamily = null;
        this.quantifiedMateriality = null;
        this.materialityQuantifyingDate = null;
        this.materialityJustification = '';
        this.materialityEvaluation = null;
        this.externalImpactEvaluation = null;
        this.externalImpactEvaluationJustification = '';
        this.modelUseEvaluation = null;
        this.modelUseEvaluationJustification = '';
        this.categoryResult = null;
        this.dataSource = null;
        this.implementationDate = null;
        this.modelOwner = null;
        this.tradeSponsor = null;
        this.modelValidator = null;
        this.modelDeveloper = null;
        this.backtests = [];
        this.reviews = [];
        this.MRAs = [];
        this.operationalRiskFlag = null;
        this.lifeCycleStage = null;
        this.useAndScopes = null;
        this.mitigationActions = '';
        this.remediationActionPlan = '';
        this.limitations = '';
        this.technicalDocumentation = null;
        this.regulatorApprobationDocument = null;
        this.description = '';
        this.businessSponsor = null;
        this.modelOwnerDelegation = null;
        this.mrmId = null;
        this.riskLimits = '';
        this.impactAssessment = '';
        this.mainMethodDetail = '';
        this.informationSource = null;
        this.lastBCEReviewDate = null;
        this.approval = null;
        //this.mras = null;
        this.versionCollectionId = null;
    }
}

export default new ModelStore();