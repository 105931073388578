import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../../Services/Modal";
import Alert from "../../../Services/Alert";
// import FormControl from "@material-ui/core/FormControl";
// import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Navigation from "../../../Services/Navigation";
import Http from "../../../Services/Http";
import {CircularProgress, Typography} from "@material-ui/core";
import { ModalContent } from "../../Modal/ModalContent";
import { ActionButton } from "../../Modal/ActionButton";
import { ButtonBar } from "../../Modal/ButtonBar";

export const DeleteForm = (props) => {
    // const [comment, setComment] = useState(null);
    const [progress, setProgress] = useState(false);
    const [title, setTitle] = useState('');
    
    const { entity, ids, entityTypeLabel, entityTitle, goBackToListView = true } = props;
    const { bulk = true, callback, titleOverride, onCancelCallback } = props;
    /** @type {import("../../../Services/APIResource/APIResource").APIResource} */
    const resource = props.resource;

    const endpoint = `${resource.resourceId}/bulk/delete`;

    useEffect(() => {
        const getTitle = () => {
            if (titleOverride) return titleOverride;
            if (entity) {
                return `Are you sure you want to delete the ${entityTypeLabel ? entityTypeLabel.toLowerCase() : resource.name.toLowerCase()} ${entityTitle || entity.functionalID} ?`;
            }
            if (ids) {
                return `Are you sure you want to delete ${ids.length} ${entityTypeLabel.toLowerCase()}${ids.length > 1 ? 's' : ''} ?`;
            }
        };
        setTitle(getTitle());
    }, [entity, ids, entityTypeLabel, entityTitle, resource, titleOverride])
    

    const deleteItems = async (entities) => {
        /* Pas de commentaire pour les suppressions définitives, à l'inverse de LogicalDelete
        if (!comment) {
            Alert.show({
                message: "The comment is required before deletion.",
                type: "error"
            });
            return;
        } */

        setProgress(true);
        if (goBackToListView)
            Navigation.router.history.push(`/resource/${resource.instanceId}/list`);

        const ids_ = entities.map(e => e.id);

        try {
            if (!bulk && entity) {
                await resource.apiDelete(entity);
            } else {
                await Http.post(endpoint, {ids: ids_});// , deletionComment: comment});
            }
            if (callback) {
                callback();
            } else {
                const message = ids_.length === 1 ? "Item successfully deleted." : "Items successfully deleted.";
                Alert.show({ message: message, type: "success" });
                resource.apiGetCollection({
                    page: 1,
                    rowsPerPage: 10000,
                    forceReload: true
                });
            }
        }
        catch(err) {
            const errorMessage = Http.getErrorMessage(err);
            console.error(err);
            Alert.show({ message: errorMessage, type: "error" });
        }
        setProgress(false);
        Modal.close();
    }

    const handleDeleteClick = () => {
        if (entity || (ids && ids.length)) {
            const entities = entity ? [entity] : ids.map(id => ({ id }));
            return deleteItems(entities);
        }
    }

    const handleCancelClick = () => {
        if (onCancelCallback) {
            onCancelCallback();
        }
        else {
            Modal.close();
        }
    }
    
    return (
        <ModalContent>
            <Typography component="p">{title}</Typography>
            {/* TODO : pas de commentaire pour les suppressions définitives.
            <FormControl className="container_textarea">
                <label>Comment</label>
                <TextareaAutosize
                    value={comment ? comment : ""}
                    onChange={event => setComment(event.target.value)}
                    rows={5}
                    rowsMax={10}
                />
            </FormControl> */}
            <ButtonBar>
                <ActionButton disabled={progress} onClick={handleCancelClick}>
                    Cancel
                </ActionButton>
                <ActionButton loading={progress} onClick={handleDeleteClick}>
                    Delete
                </ActionButton>
            </ButtonBar>
        </ModalContent>
    );

}
DeleteForm.propTypes = {
    ids: PropTypes.array,
    /** @type {import("../../Services/APIResource/APIResource").APIResource} */
    resource: PropTypes.object,
    entity: PropTypes.object,
    entityTypeLabel: PropTypes.string,
    entityTitle: PropTypes.string,
    /** Remplace l'autogénération du titre */
    titleOverride: PropTypes.string,
    /** Options utiles surtout pour les cas non bulk */
    bulk: PropTypes.bool,
    callback: PropTypes.func,
    /** Callback si l'on clique sur Cancel */
    onCancelCallback: PropTypes.func,
    goBackToListView: PropTypes.bool,
}
