import React from "react";
import { Icon } from "@material-ui/core";
import { EntityDisplay } from "../../../Components/Display/EntityDisplay/EntityDisplay";
import { EntitySelect } from "../../../Components/Forms/EntitySelect/EntitySelect";
import EntitiesTreeWidget from "../../../Components/Tree/EntitiesTreeWidget";
import {CONTEXT_LIST} from "../APIResource";

class EntityTreeProvider {
  getDisplay(field, value, entity, props, resource, context) {
    return (
        <EntityDisplay
            {...props}
            resourceId={field.params.resource}
            instanceId={field.params.instanceId}
            resourceLabel={field.params.displayField || 'title'}
            label={field.title}
            value={value ? value : null}
            links={field.params.links}
            linkPath={field.params.linkPath}
            linkAction={field.params.linkAction}
            tooltip={field.params.tooltip}
            context={context}
        />
    );
  }

  getDisplayList(field, value, entity, props, resource) {
    return this.getDisplay(field, value, entity, props, resource, CONTEXT_LIST);
  }

  getEdit(field, value, onChange, entity, routeParams, selectable, loading = false) {
    const additionalProps = {};
    if (field.params.selectable) {
      additionalProps.selectable = field.params.selectable;
    }
    if (field.params.async !== undefined) {
      additionalProps.asyncLoading = field.params.async;
    }
    if (field.params.buttonText !== undefined) {
      additionalProps.buttonText = field.params.buttonText;
    }
    // Par défaut affiche un picto spécifique pour les scopes custom
    const defaultScopeAdditionalComponent = (item) =>
      item.obj.customTeam && (
          <Icon style={{ marginLeft: 'auto', alignSelf: 'center', paddingRight: '6px' }} className="fas fa-user-edit" />
      );
    return (
      <EntitiesTreeWidget
        resourceId={field.params.resource}
        resourceLabel={field.params.treeDisplayField || field.params.displayField}
        resourceSearchLabel={field.params.displaySearchField}
        resourceFilters={{
          verified: true,
          outOfRepository: false,
        }}
        childrenPropertyName={field.params.childrenPropertyName}
        multi={
          field.params && field.params.multi !== undefined
            ? field.params.multi
            : false
        }
        label={field.title || null}
        required={field.requiredComputed}
        onChange={onChange}
        values={
          value !== undefined ? (Array.isArray(value) ? value : [value]) : null
        }
        {...additionalProps}
        itemAdditionalComponent={field.params.itemAdditionalComponent || ( field.params.resource === "scopes" && defaultScopeAdditionalComponent) || null}
        errorHelperText={field.errorHelperText}
        //disabled={loading}
        parentEntity={entity}
        issueButton={"issueButton" in field ? field.issueButton : true}
      />
    );
  }

  getFilter(options = {}) {
    const { field, value, onChange } = options;
    if (field.params.resource.match(/scopes/g) && (!field.params.endpoints || !field.params.endpoints.getAll)) {
        if (!field.params.endpoints) {
            field.params.endpoints = {};
        }
        field.params.endpoints.getAll = 'scopes/all-scopes/all';
    }
    let multi = true
    if(field.params.hasOwnProperty('multi')){
      multi = field.params.multi
    }
    return (
        <EntitySelect
            resourceId={field.params.resource}
            instanceId={field.params.instanceId}
            context="filter"
            resourceLabel={field.params.displayField}
            onChange={onChange}
            clearable={true}
            multi={multi}
            filters={field.params.filters}
            value={value ? value : null}
            endpoints={field.params.endpoints}
            orExistsMultiFilterNotAllowed={field.params.orExistsMultiFilterNotAllowed || false}
        />
    );
  }
}

export default new EntityTreeProvider();
