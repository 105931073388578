import React from 'react';
import PropTypes from 'prop-types';
import { ModalContent } from '../../Modal/ModalContent';
import { Typography } from '@material-ui/core';
import { ActionButton } from '../../Modal/ActionButton';
import { ButtonBar } from '../../Modal/ButtonBar';
import ModalComplementary from '../../../Services/ModalComplementary';

export const AutomaticScoringInformationModal = (props) => {
    const { onConfirm, onCancel } = props;

    return (
        <ModalContent>
            <Typography component={'p'}>
                The association of findings and notices leads to an automatic scoring of the concerned MRA
                sub-dimensions. A severity modification or a cancellation, deletion or closure of this notice will
                automatically modify the associated MRA subdimensions score.
            </Typography>
            <Typography component={'p'}>Do you confirm your action ?</Typography>
            <ButtonBar>
                <ActionButton onClick={onCancel}>Cancel</ActionButton>
                <ActionButton onClick={onConfirm}>Confirm</ActionButton>
            </ButtonBar>
        </ModalContent>
    );
};
AutomaticScoringInformationModal.propTypes = {
    /** le onConfirm doit fermer la Modal qu'il a ouverte. */
    onConfirm: PropTypes.func,
    /** le onCancel doit fermer la Modal qu'il a ouverte. Si vide la modal est fermée automatiquement. */
    onCancel: PropTypes.func,
};

export const openAutomaticScoringInformationModal = (onConfirm, onCancel) => {
    ModalComplementary.open({
        title: 'Link between the MRA score and the association of findings and notices',
        content: (
            <AutomaticScoringInformationModal
                onConfirm={() => {
                    ModalComplementary.close();
                    onConfirm();
                }}
                onCancel={() => {
                    ModalComplementary.close();
                    onCancel?.();
                }}
            />
        ),
        size: 'medium',
    });
};
