import React from "react";

class ModalIssue {
  constructor() {
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }
  /**
   *
   * @param {import('./ModalProvider').ModalParams} params
   */
  open(params) {
    if (this.ref) {
      this.ref.open(params);
    }
    return this;
  }
  close() {
    if (this.ref) {
      this.ref.close();
    }
  }
}
export default new ModalIssue();
