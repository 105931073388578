import {decorate, observable} from "mobx/lib/mobx";

  /**
   * @typedef {import("../Services/APIResource/APIResource").APIResource} APIResourceDef
   * 
   * @class
   */
class APIResourceStore {
  /**
   * @type {Object<string, APIResourceDef>} Objet contenant les APIResource identifiées par leur instanceId
   */
  resources = {};

}

decorate(APIResourceStore, {
    resources: observable
})

export default new APIResourceStore();