import React from 'react';
import { APIResource } from '../Services/APIResource/APIResource';
import { ROLE } from '../Services/User/User';
import { BulkVerify } from '../Services/BulkActions/BulkVerify/BulkVerify';


export const ApiResourceDefaultParams = {
  id: 'establishments',
  name: 'Establishment',
  icon: 'fa-unused',
  fieldForTitle: 'title',
};

export default class EstablishmentAdmin{
    constructor() {
        this.configure();
    }

    configure() {
        let resource = new APIResource(ApiResourceDefaultParams);
        resource
            .setFields({
                id: { title: 'ID' },
                code: { title: 'Code', maxLength:255, type: 'text' },
                title: {
                    title: 'Title',
                    type: 'text',
                    maxLength:255, 
                },
            })
            .setLayout({
                tabs: {
                    'Establishment': {
                        rows: [
                            {
                                panels: {
                                    Identification: {
                                        cols: 6,
                                        fields: ['id', 'code', 'title'],
                                    },
                                },
                            },
                        ],
                    },
                },
            })
            .genListView({
                fields: [
                    'code',
                    'title',
                ],
                menuItem: { title: 'Establishments' },
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genInsertView({
                fields: [
                    'code',
                    'title',
                ],
                menuItem: { title: 'Add' },
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
                onInit: ({ entity, _resource, _context }) => {
                    entity['@type'] = 'Establishment';
                },
            })
            .genEditView({
                fields: [
                    'code',
                    'title',
                ],
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .genDetailView({
                fields: [
                    'id',
                    'code',
                    'title',
                ],
                routeAccessControl: [ROLE.MRM, ROLE.ADMIN],
            })
            .allowDelete()
            .addBulkAction(BulkVerify, {
                resource,
                icon: 'check',
                resourceId: "establishments",
            });
    }
}

//export default new EstablishmentAdmin();
