import React, { PureComponent } from 'react';
import {
    XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar, BarChart,
} from 'recharts';
import ObliqueAaxisTick from "../ObliqueAaxisTick";

const COLORS = ['#00838A','#BDCF41', '#F6A800',  '#E74D5D'];

export default class ModelsByStatusBetweenDatesChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data : []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.data && (this.props.data !== prevProps.data)){
            this.setState({data: this.props.data})
        }
    }

    render() {
        return (
            <ResponsiveContainer>
                <BarChart
                    width={800}
                    height={350}
                    data={this.state.data}
                    margin={{
                        top: 20, right: 0, left: 0, bottom: 75,
                    }}
                >
                    <CartesianGrid stroke="#f5f5f5" />
                    <XAxis dataKey="name" tick={<ObliqueAaxisTick />} />
                    <YAxis />
                    <Tooltip />
                    <Legend wrapperStyle={{ fontSize: 16, top : -25 }} verticalAlign="top" height={40} />

                    <Bar dataKey="Not OK - redesign" stackId="a" fill={COLORS[3]} />
                    <Bar dataKey="OK - points of attention" stackId="a" fill={COLORS[2]} />
                    <Bar dataKey="OK - without reservation" stackId="a" fill={COLORS[1]} />
                    <Bar dataKey="OK - with minor issues" stackId="a" fill={COLORS[0]} />


                </BarChart>
            </ResponsiveContainer>
        )
    }
}