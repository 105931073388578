import React, { useEffect } from 'react';

/**
 * Set link in widget parameters.
 * @param {*} param0 
 * @returns 
 */
function SetLink({ setParameters, parameters = {}, field }) {
    useEffect(() => {
        setParameters({...parameters, link: field.props.link})
    }, [])
    return <></>
}

export default SetLink;
