import React from 'react';
import { DisplayTextField } from '../../../Components/Display/DisplayTextField/DisplayTextField';
import { DatePicker } from '../../../Components/Forms/DatePicker/DatePicker';
import { DATE_PICKER_OPERATION_AFTER, DATE_PICKER_OPERATION_BEFORE, DATE_PICKER_OPERATION_BETWEEN, DATE_PICKER_OPERATION_EQUALS, ModalDateRangePicker } from '../../../Components/Forms/DateRangePicker/ModalDateRangePicker';
import DateFormatter from '../../DateFormatter';
import {CONTEXT_LIST} from "../APIResource";

class DateProvider {
    getDisplay(field, value, entity, props, _resource, context) {
        return <DisplayTextField {...props} fieldName={field.title} fieldType={'date'} value={value ?? ''} context={context} />;
    }

    getDisplayList(field, value, entity, props, resource) {
        return this.getDisplay(field, value, entity, props, resource, CONTEXT_LIST);
    }

    getEdit(field, value, onChange, _entity, _routeParams, loading = false) {
        // On n'envoie pas les props error et helperText s'il n'y a pas d'erreur,
        // pour ne pas écraser le comportement par défaut des tests du Composant
        // (en particulier: >minDate, <maxDate, minDateMessage etc.)
        let helperProps = field.errorHelperText
            ? {
                  helperText: field.errorHelperText,
                  error: !!field.errorHelperText,
                  className: 'field-error-control',
              }
            : {};

        return (
            <DatePicker
                label={field.title}
                //disabled={loading}
                value={value}
                {...field.params}
                onChange={onChange}
                required={field.requiredComputed}
                {...helperProps}
            />
        );
    }

    getFilter(options = {}) {
        const { field, value, onChange } = options;
        return (
            <ModalDateRangePicker
                placeholder="Filter"
                value={value}
                onChange={(startDate, endDate, operation) => {
                    onChange({ startDate: startDate, endDate: endDate, operation });
                }}
            />
        );
    }

    getValidationPattern() {
        return /.*/;
    }

    applyFilter(fieldId, value, activeFilters, filterTrigger) {
        if ((value.startDate == null || value.startDate === '') && value.endDate == null || value.endDate === '') {
            delete activeFilters[fieldId + '[after]'];
            delete activeFilters[fieldId + '[before]'];
        } else {
            switch (value.operation) {
                case DATE_PICKER_OPERATION_BETWEEN:
                    let endDate = new Date(value.endDate.getTime());
                    endDate.setHours(23);
                    endDate.setMinutes(59);
                    endDate.setSeconds(59);
                    activeFilters[fieldId + '[after]'] = DateFormatter.datetimeToRfc3339(value.startDate);
                    activeFilters[fieldId + '[before]'] = DateFormatter.datetimeToRfc3339(endDate);
                    break;

                case DATE_PICKER_OPERATION_AFTER:
                    activeFilters[fieldId + '[after]'] = DateFormatter.datetimeToRfc3339(value.startDate);
                    delete activeFilters[fieldId + '[before]'];
                    break;
            
                case DATE_PICKER_OPERATION_BEFORE:
                    activeFilters[fieldId + '[before]'] = DateFormatter.datetimeToRfc3339(value.endDate);
                    delete activeFilters[fieldId + '[after]'];
                    break;
                
                case DATE_PICKER_OPERATION_EQUALS:
                    let endEqualsDate = new Date(value.startDate.getTime());
                    endEqualsDate.setHours(23);
                    endEqualsDate.setMinutes(59);
                    endEqualsDate.setSeconds(59);
                    let startEqualsDate = new Date(value.startDate.getTime());
                    startEqualsDate.setHours(0);
                    startEqualsDate.setMinutes(0);
                    startEqualsDate.setSeconds(0);
                    activeFilters[fieldId + '[after]'] = DateFormatter.datetimeToRfc3339(startEqualsDate);
                    activeFilters[fieldId + '[before]'] = DateFormatter.datetimeToRfc3339(endEqualsDate);
                    break;
            
                default:
                    break;
            }
        }
        filterTrigger();
    }
}

export default new DateProvider();
