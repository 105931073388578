import React, {useState, useEffect, Component} from "react";
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Http from "../../Services/Http";
import Alert from "../../Services/Alert";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import ParameterStore from '../../Store/ParameterStore';
import Modal from "../../Services/Modal";
import { ActionButton } from "../Modal/ActionButton";
import {
    DOCUMENT_ACTION_SHOW,
    DOCUMENT_CATEGORY_MINUTES,
    DOCUMENT_CATEGORY_PRESENTATION,
    DocumentButtons
} from "../Display/DocumentManager/DocumentManager";
import {EntityForm} from "../Forms/EntityForm/EntityForm";
import {committeeReviewDocumentFields} from "../Display/ReviewEvent/ReviewEvent";
import {
    documentAllowedActionAdd,
    documentAllowedActionDelete,
    documentAllowedActionShow
} from "../../Admin/ReviewAdmin";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import {OptionalTooltip} from "../OptionalTooltip/OptionalTooltip";
import APIResourceStore from "../../Store/APIResourceStore";
import {ButtonColumn} from "../Modal/ButtonColumn";
import {ModalContent} from "../Modal/ModalContent";
import Navigation from "../../Services/Navigation";
import {APIResource, CONTEXT_DETAIL} from "../../Services/APIResource/APIResource";
import {IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ModalDocument from "../../Services/ModalDocument";
import { DeleteDocumentForm } from "../Document/DeleteDocumentForm";

const AssociateCommitteeReviewDocument = (props) => {

    const {entity, resource, parentResourceComponent, reviews} = props;
    const [selectionReview, setSelectionReview] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [allSelected, setAllSelected] = useState(false);

    useEffect(() => {
        let previousItem = null
        selectionReview.forEach((itemId) => {
            let item = findItemFromId(itemId);
            if(previousItem === null){
                previousItem = item;
            }
        })
    }, [selectionReview]);

    useEffect(() => {
        setAllSelected(selectionReview.length === reviews.filter(r => documentAllowedActionAdd(r)).length)    
    }, [selectionReview, reviews]);
    

    function onSelectionChange(item, type) {
        let sel = [...selectionReview];
        if (sel.indexOf(item.id) === -1) {
            sel.push(item.id);
        } else {
            for (let i in sel) {
                if (sel[i] === item.id) {
                    sel.splice(i, 1);
                }
            }
        }
        return sel;
    }

    const toggleAllSelected = () => {
        allSelected
            ? setSelectionReview([])
            : setSelectionReview(reviews.filter(r => documentAllowedActionAdd(r)).map((r) => r.id));
    };

    function findItemFromId(id, type){
        let sel = [...reviews];
        let findItem = null
        sel.forEach((item) => {
            if(item.id === id && findItem === null){
                findItem = item
            }
        })
        return findItem
    }

    function deleteDocument(document){
        let documentsResource = new APIResource({
            id: 'documents',
        });
        
        ModalDocument.open({
            title: "Delete this document",
            content: <DeleteDocumentForm
                    document={document}
                    resource={documentsResource}
                    callback={() => {
                        Alert.show({
                            message: 'Document successfully deleted.',
                            type: "success",
                        });
                        update(entity);
                        ModalDocument.close();
                    }}
                />,
            size: "small",
        })
    }

    const displayDocuments = (review, documentCategory) => {
        let documents = [];
        let crdForReview = entity.committeeReviewDocumentsEntities.filter(crd => crd.review === `/api/reviews/${review.id}` && crd.documentsEntities.some(d => d.category === ParameterStore(documentCategory)));

        if(Array.isArray(crdForReview) && crdForReview.length > 0){
            crdForReview = crdForReview[0];
            if(Array.isArray(crdForReview.documentsEntities) && crdForReview.documentsEntities.length > 0) {
                crdForReview.documentsEntities.filter(d => d.category === ParameterStore(documentCategory) && documentAllowedActionShow(review, d)).forEach((document, index) => {
                    documents.push(
                        <TableRow key={'document-row'- + index}>
                            <TableCell style={{padding: 0, width: "10%", border: 'none'}}>
                                <DocumentButtons
                                    key={'document-buttons-' + index}
                                    entity={entity}
                                    document={document}
                                    hideCommentButton={true}
                                    small={true}
                                    flat={true}
                                    allowedAction={(reviewCommittee, document, action) => {
                                        if(action === DOCUMENT_ACTION_SHOW){
                                            return documentAllowedActionShow(review, document);
                                        }
                                        return false;
                                    }}
                                    onDeleteCallback={() => {
                                        this.setLoadingData(true);
                                        this.resource.apiGetOne(entity.id, true).then((entity) => {
                                            this.update(entity);
                                        });
                                    }}
                                />
                            </TableCell>
                            <TableCell width={40} style={{padding: 0, paddingRight: 10, border: 'none'}}>
                                {
                                    documentAllowedActionDelete(review, document) &&
                                    <IconButton style={{padding: 0}} className={'flat-button'} onClick={() => deleteDocument(document)}>
                                        <DeleteIcon className="text-danger" />
                                    </IconButton>
                                }
                            </TableCell>
                            <TableCell style={{padding: 0, width: "90%", border: 'none'}}>
                                {document.title}
                            </TableCell>
                        </TableRow>
                    );
                })
            }
        }
        return <table
            className={'documents-for-committee'}
            style={{width: "100%"}}
        >
            <TableBody>
                {documents}
            </TableBody>
        </table>;
    }

    const displayList = (source, type) => {
        let rows = [];
        source.forEach(
            (item, index) => {
                rows.push(
                    <TableRow
                        key={'associate-checkbox-review-' + index}
                    >
                        <TableCell>
                            {
                                documentAllowedActionAdd(item) &&
                                <Checkbox
                                    indeterminate={false}
                                    checked={selectionReview.indexOf(item.id) !== -1}
                                    onChange={() => {
                                        setSelectionReview(onSelectionChange(item, type));
                                    }}
                                />
                            }

                        </TableCell>
                        <TableCell>
                            {item.toString}
                        </TableCell>
                        <TableCell>
                            {item.title}
                        </TableCell>
                        <TableCell>
                            { displayDocuments(item, DOCUMENT_CATEGORY_MINUTES) }
                        </TableCell>
                        {entity.isCommittee &&
                            <TableCell>
                                {displayDocuments(item, DOCUMENT_CATEGORY_PRESENTATION)}
                            </TableCell>
                        }
                    </TableRow>
                )
            }
        );
        return <Table
            className={'table-display small'}
            size={'small'}
        >
            <TableHead>
                <TableRow>
                    <TableCell  width={50} key={'th_review_associate_0'}>
                        <Checkbox
                            indeterminate={false}
                            checked={allSelected}
                            onChange={toggleAllSelected}
                        />
                    </TableCell>
                    <TableCell key={'th_review_associate_1'}>
                        Review
                    </TableCell>
                    <TableCell key={'th_review_associate_2'}>
                        Title
                    </TableCell>
                    <TableCell key={'th_review_associate_3'}>
                        Minutes
                    </TableCell>
                    {entity.isCommittee &&
                        <TableCell key={'th_review_associate_4'}>
                            Presentations
                        </TableCell>
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {rows}
            </TableBody>
        </Table>
    }

    function update(entity){
        setSelectionReview([])
        resource.apiGetOne(entity.id, true).then((e) => {
            if(parentResourceComponent){
                parentResourceComponent.entity = e;
                parentResourceComponent.forceUpdate();
                let pathname = window.location.pathname;
                Navigation.router.history.push('/blank');
                setTimeout(() => {
                    Navigation.router.history.push(
                        pathname
                    );
                },10)
            }
            Modal.close()
        });
    }
    const associate = () => {
        if (selectionReview.length === 0) {
            Alert.show({
                message: 'At least one review is required',
                type: 'error',
            });
            return;
        }

        Modal.open({
            title: "Add documents",
            content: <DocumentsForm
                resource={resource}
                entity={entity}
                onUpdate={(entity) => {
                    update(entity);
                }}
                reviewCommitteePath={entity['@id']}
                reviews={selectionReview}
            />
        })
    }

    return (
        <Grid container spacing={2} className="container detail-inner-new-button">
            <Grid item xs={12}>
                <Grid
                    container
                    spacing={2}
                    className={'container'}
                >
                {   selectionReview.length > 0
                    && <OptionalTooltip key={'tooltip_add_documents'} arrow placement="top">
                            <Button
                            key={'td_button_add_documents'}
                            style={{zIndex: 0}}
                            variant="contained"
                            color="primary"
                            className="button-general"
                            onClick={() => associate()}
                        >
                            <Icon
                                className={'fa fa-plus'}
                            ></Icon>
                            Add documents
                        </Button>
                    </OptionalTooltip>
                }
                </Grid>
            </Grid>
            {
                reviews.length === 0 ?
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        Please associate at least one review to this committee.
                    </Grid>
                    :
                    <Grid item xs={12}>
                        {displayList(reviews)}
                    </Grid>
            }
        </Grid>
    );
}
AssociateCommitteeReviewDocument.propTypes = {
    reviews: PropTypes.array,
}

export default AssociateCommitteeReviewDocument;

class DocumentsForm extends Component{
    constructor(props){
        super(props);

        this.state = {
            reviewCommittee: this.props.reviewCommitteePath,
            committeeReviewDocument: {
                documents: [],
                context: 'CONTEXT_ADD_DOCUMENT_TO_LINK_REVIEW_TO_COMMITTEE'
            },
            processing: false,
        }
    }

    save(){
        let self = this;
        let promises = [];
        self.setState({processing: true});

        let committeeReviewDocument = this.state.committeeReviewDocument;
        Http.post('review-committee-document/associate',{
            reviews: this.props.reviews,
            committee: this.props.reviewCommitteePath,
            documents: committeeReviewDocument.documents
        }).then(() => {
            self.props.resource.getItem(self.props.entity.id, true).then(() => {
                self.props.onUpdate(self.props.entity);
            });
            self.setState({processing: false});
            Modal.close();
        });
    }

    updateState = (callback) => {
        this.setState((p) => {
            const newEntity = callback(p.committeeReviewDocument);
            return {committeeReviewDocument: newEntity}
        })
    }

    genForm(){
        let fields = [];
        let self = this;

        return(
            <div className="bulk-associate-validation-family">
                <div className="fields main-fields resource-edit">

                    <div>
                        <label htmlFor="">Minutes</label>
                        <EntityForm
                            entity={this.state.committeeReviewDocument}
                            onUpdate={this.updateState}
                            fields={committeeReviewDocumentFields(DOCUMENT_CATEGORY_MINUTES)}
                        />
                    </div>
                    {self.props.entity.isCommittee &&
                        <div>
                            <label htmlFor="">Presentation</label>
                            <EntityForm
                                entity={this.state.committeeReviewDocument}
                                onUpdate={this.updateState}
                                fields={committeeReviewDocumentFields(DOCUMENT_CATEGORY_PRESENTATION)}
                            />
                        </div>
                    }
                </div>
                <ButtonColumn>
                    <ActionButton
                        onClick={this.save.bind(this)}
                        loading={this.state.processing}
                        style={{marginBottom: "10px", width: "100%"}}
                    >
                        {`Save documents for ${this.props.reviews.length} reviews`}
                    </ActionButton>
                </ButtonColumn>
            </div>
        );
    }

    render(){
        return (
            <ModalContent>
                { this.genForm() }
            </ModalContent>
        );
    }
}