import React from 'react';
import { EntitySelect } from '../../../Components/Forms/EntitySelect/EntitySelect';
import APIResourceStore from '../../../Store/APIResourceStore';
import { parseEmails } from '../../utils';
import EntityAsyncProvider from './EntityAsyncProvider';
import {CONTEXT_LIST} from "../APIResource";

class UserProvider {
    getDisplay(field, value, entity, props, resource, context) {
        return EntityAsyncProvider.getDisplay(field, value, entity, props, resource, context);
    }

    getDisplayList(field, value, entity, props, resource) {
        return this.getDisplay(field, value, entity, props, resource, CONTEXT_LIST);
    }

    getEdit(field, value, onChange, entity, routeParams, loading = false) {
        return EntityAsyncProvider.getEdit(field, value, onChange, entity, routeParams, loading);
    }

    getFilter(options = {}) {
        const { field, value, onChange } = options;

        const onInputChange = (inputValue) => {
            let input;

            const foundEmails = parseEmails(inputValue);
            if (!foundEmails.length) {
                input = inputValue;
            } else {
                // On récupères les IRI des users associés
                input = APIResourceStore.resources.users_all
                    .filterItems((user) => foundEmails.includes(user.email))
                    .map((user) => user['@id'] );
                if (input.length) {
                    onChange([...(value || []), ...input]);
                    input = '';
                } else {
                    input = inputValue;
                }
            }
            return input;
        }

        return (
            <EntitySelect
                resourceId={field.params.resource}
                instanceId={field.params.instanceId}
                context="filter"
                resourceLabel={field.params.displayField}
                onChange={onChange}
                clearable={true}
                multi={true}
                filters={field.params.filters}
                value={value ? value : null}
                endpoints={field.params.endpoints}
                onInputChange={onInputChange}
                orExistsMultiFilterNotAllowed={field.params.orExistsMultiFilterNotAllowed || false}
            />
        );
    }
}

export default new UserProvider();
