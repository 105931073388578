const PRESET_WIDGETS = [
    {
        reportId: 'Notices with past deadlines',
        title: 'Notices with past deadlines',
        id: 'user-defined-widget',
        widgetSystemId: 'user-defined-widget',
        description: 'Notices with past deadlines',
        column: 0,
        row: 0,
        isMRM: true,
        parameters: {
            widgetSystemId: 'user-defined-widget',
            chart: 'count',
            operation: 'count',
            models: [
                {
                    name: 'notice',
                    segments: [],
                    conditions: [
                        {
                            field: {
                                name: 'deadline',
                                type: 'datetime',
                                humanizedName: 'deadline',
                            },
                            op: 'before now',
                        },
                    ],
                },
            ],
            limit: 10,
        },
    },
    {
        isMRM: true,
        reportId: 'Models with open notices by tierings',
        title: 'Models with open notices by tierings',
        id: 'user-defined-widget',
        widgetSystemId: 'user-defined-widget',
        description: 'Models with open notices by tierings',
        column: 0,
        row: 0,
        parameters: {
            chart: 'pie chart',
            operation: 'groupby',
            models: [
                {
                    name: 'model',
                    segment: {
                        name: 'tierings >> tierResultFinal',
                        type: 'parameter',
                        code: 31,
                        humanizedName: 'tierings >> tierResultFinal',
                    },
                    segments: [],
                    conditions: [
                        {
                            field: {
                                name: 'notices >> status',
                                type: 'parameter',
                                code: 20,
                                humanizedName: 'notices >> status',
                            },
                            op: 'is',
                            value: '/api/parameters/1163',
                        },
                    ],
                },
            ],
            limit: 10,
        },
    },
];

export { PRESET_WIDGETS };
