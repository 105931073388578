class Environment {
    getUriParameter(paramId){
        let currentURL = new URL(window.location);
        return currentURL.searchParams.get(paramId);
    }
}

const GetEnvironmentUriParameter = (paramId) => {
    let currentURL = new URL(window.location);
    return currentURL.searchParams.get(paramId);
}

export default new Environment();
export {GetEnvironmentUriParameter}