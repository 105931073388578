import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, styled } from '@material-ui/core';

const ButtonFlexWithMargin = styled(Button)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    margin: '30px 0',
});

/**
 * @param {Object} props
 * @param {boolean} [props.loading] - Si true, désactive le bouton et affiche le CircularProgress. Utiliser disabled si l'on veut juste désactiver.
 */
const ActionButton = React.forwardRef((props, ref) => {
    const { children, loading, ...otherProps } = props;
    return (
        <ButtonFlexWithMargin
            variant="contained"
            color="secondary"
            className="button-general"
            disabled={loading}
            {...otherProps}
            ref={ref}
        >
            {children}
            {loading && <CircularProgress size={20} style={{marginLeft: 10}}/>}
        </ButtonFlexWithMargin>
    );
});
ActionButton.propTypes = {
    ...Button.propTypes,
    loading: PropTypes.bool,
};

export { ActionButton };
