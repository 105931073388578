import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { isID, QUERY_MIN_CHARS, QuickSelectForm } from '../Forms/QuickSelectForm/QuickSelectForm';
import { APIResource } from '../../Services/APIResource/APIResource';
import Modal from '../../Services/Modal';
import { ActionLink } from '../ActionLink/ActionLink';
import { PARAMETER_TYPE_REVIEW_STATUS } from '../../Admin/ParameterAdmin';

export const ReviewQuickSelect = (props) => {
    /** @type {import("../../Services/APIResource/APIResource").APIResource} */
    const resource = props.resource;
    const { instanceId = 'reviews', endpoints } = props;

    const {
        label = 'Select a review',
        multi = false,
        withModal = true,
        inModal = false,
        clearable,
        filters,
        context,
        buttonLabel = 'Select',
        values,
        onChange,
        openValidation,
        additionalProperties = [],
        buttonProps = {
            color: 'primary',
            variant: 'contained',
            style: styles.green,
        },
    } = props;

    const showOpenModalButton = inModal ? false : withModal;

    const [loaded, setLoaded] = useState(true);

    /**
     * @type {React.MutableRefObject<APIResource>} resourceNotices contient une APIResource dans "current"
     */
    const resourceReviews = useRef();
    useEffect(() => {
        resourceReviews.current = new APIResource({
            id: 'reviews',
            instanceId: instanceId,
            endpoints: endpoints || null,
        });
    }, [endpoints, instanceId]);

    /** Dans le cas d'une création, on n'a pas à attendre que l'entité soit chargée. */
    useEffect(() => {
        if (context === 'add') setLoaded(true);
    }, []);

    // useEffect(() => {
    //     if (finding && finding.id) setLoaded(true);
    // }, [finding]);

    // Renvoie les reviews qui respectent les filtres demandés.
    const apiSearchReview = async (searchValue, setCurrentRequests) => {
        if (!searchValue || (!isID(searchValue) && searchValue.length < QUERY_MIN_CHARS)) return;
        const properties = [...columns.map((f) => f.field), ...additionalProperties];

        const apiRequests = [];
        const newCurrentRequests = [];
        if (!isID(searchValue) && searchValue.length >= QUERY_MIN_CHARS) {
            apiRequests.push(
                resourceReviews.current.apiGetCollection({
                    page: 1,
                    rowsPerPage: 10000,
                    filters: { title: searchValue },
                    fields: properties,
                })
            );
            newCurrentRequests.push(resourceReviews.current.currentRequestList);
            apiRequests.push(
                resourceReviews.current.apiGetCollection({
                    page: 1,
                    rowsPerPage: 10000,
                    filters: { reviewId: searchValue },
                    fields: properties,
                })
            );
            newCurrentRequests.push(resourceReviews.current.currentRequestList);
        }
        // Si les données sont au mauvais format cela peut faire disparaître le filtre recherché,
        // donc on les ajoute que si les types sont bons.
        if (isID(searchValue)) {
            apiRequests.push(
                resourceReviews.current.apiGetCollection({
                    page: 1,
                    rowsPerPage: 10000,
                    filters: { id: searchValue },
                    fields: properties,
                })
            );
            newCurrentRequests.push(resourceReviews.current.currentRequestList);
        }
        // Pour que QuickSelectForm puisse les cancel si besoin.
        setCurrentRequests(newCurrentRequests);

        const responses = await Promise.all(apiRequests);
        const items = responses.reduce((p, c) => [...p, ...c], []); // responses est un tableau de tableaux

        if (filters) {
            /** @todo attention si les filters ont besoin de + de champs que les "fields", utiliser la prop additionalProperties */
            // return resourceReviews.current.filter(items, filters, entity);
            return filters(items);
        }
        return items;
    };

    /**
     * Désactivation du filtre sur les models communs aux finding et notices,
     * voir historique git et apiSearch plus haut.
     */
    const disableValue = () => {
        return false;
    };

    const columns = [
        {
            label: 'ID',
            field: 'id',
            forceDisplay: true,
            display: (field, value, entity) => (
                <ActionLink to={`/resource/reviews/${entity.id}/detail`} target="_blank">
                    {value}
                </ActionLink>
            ),
        },
        {
            label: 'Review ID',
            field: 'reviewId',
            forceDisplay: true,
            display: (field, value, entity) => (
                <ActionLink to={`/resource/reviews/${entity.id}/detail`} target="_blank">
                    {value}
                </ActionLink>
            ),
            // tooltip: (field, value, entity) => entity.title,
        },
        {
            label: 'Title',
            field: 'title',
            forceDisplay: true,
            display: (field, value, entity) => (
                <ActionLink to={`/resource/reviews/${entity.id}/detail`} target="_blank">
                    {value}
                </ActionLink>
            ),
            // tooltip: (field, value, entity) => entity.title,
        },
        {
            label: 'Status',
            field: 'reviewStatus',
            type: 'parameter',
            params: {
                type: PARAMETER_TYPE_REVIEW_STATUS,
                multi: false,
            },
            forceDisplay: true,
        },
    ];

    const onReviewChange = async (iris) => {
        Modal.close();
        onChange(iris);
    };

    const openModal = () => {
        Modal.open({
            title: label,
            content: (
                <QuickSelectForm
                    label={label}
                    values={values}
                    onChange={onReviewChange}
                    multi={false}
                    resource={resourceReviews.current}
                    resourceName="review"
                    apiSearch={apiSearchReview}
                    disableValue={disableValue}
                    clearable={clearable}
                    withModal={withModal}
                    columns={columns}
                    selectLabel="Select a Review"
                />
            ),
        });
    }

    const openReviewQuickSelectModal = () => {
        if(openValidation) {
            openValidation(openModal)
        } else {
            openModal()
        }
    };

    return (
        <>
            {showOpenModalButton && (
                <Button disabled={!loaded} onClick={openReviewQuickSelectModal} {...buttonProps}>
                    {loaded ? buttonLabel : <LoadingIndicator styles={styles.loadingIndicator} />}
                </Button>
            )}
            {!showOpenModalButton && !loaded && <LoadingIndicator styles={styles.loadingIndicator} />}
            {!showOpenModalButton && loaded && (
                <QuickSelectForm
                    label={label}
                    values={reviews}
                    onChange={onChange}
                    multi={reviews && reviews.length > 1 ? true : multi}
                    resource={resourceReviews.current}
                    resourceName="review"
                    apiSearch={apiSearchReview}
                    disableValue={disableValue}
                    clearable={clearable}
                    withModal={true}
                    columns={columns}
                    selectLabel="Select a Review"
                />
            )}
        </>
    );
};
ReviewQuickSelect.propTypes = {
    label: PropTypes.string,
    //values: PropTypes.arrayOf(PropTypes.string),
    multi: PropTypes.bool,
    clearable: PropTypes.bool,
    /**
     * Resource des Findings à mettre à jour avec les nouvelles notices.
     * @type {import("../../Services/APIResource/APIResource").APIResource}
     */
    resource: PropTypes.object,
    /** Instance Id et endpoints de la resource des notices */
    instanceId: PropTypes.string,
    endpoints: PropTypes.object,
    /** Affiche un bouton pour ouvrir la modal (default), ou directement le form */
    withModal: PropTypes.bool,
    /**
     * Indique si le composant est déjà affiché dans une Modal (default false),
     * auquel cas le bouton d'ouverture de modal n'est pas affiché, indépendamment de la valeur de withModal.
     */
    inModal: PropTypes.bool,
    /** Filters est de la forme "predicate", cf APIResource */
    filters: PropTypes.any,
    /** Contexte pour déterminer si on doit attendre le chargement de l'entité avant activation */
    context: PropTypes.oneOf(['edit', 'add']),
    /** ResourceDetail pour mettre à jour les données sélectionnées */
    resourceDetailComponent: PropTypes.shape({
        entity: PropTypes.object,
        forceUpdate: PropTypes.func,
    }),
};

const styles = {
    green: {
        backgroundColor: '#0dbbb7',
        alignSelf: 'flex-end',
        marginLeft: '10px',
        maxHeight: '32px',
    },
    search: {
        marginTop: '20px',
    },
    loadingIndicator: {
        display: 'flex',
        transition: 'color 150ms',
        alignSelf: 'center',
        fontSize: '0.5rem',
        margin: '0.5rem',
        textAlign: 'center',
    },
};
