import React, { PureComponent } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Legend,
  ResponsiveContainer
} from "recharts";
import { Paper } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import DateFormatter from "../../../Services/DateFormatter";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const COLORS = ["#E74D5D", "#00838A", "#F6A800", "#BDCF41"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name,
  value,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      style={{ fontSize: 18 }}
    >
      {`${(percent * 100).toFixed(0)}% (${value})`}
    </text>
  );
};

export default class TieringForDate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      date: null
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    /*
      console.info('this.props.data', this.props.data);
      console.info('this.props.data.tieringForDate', this.props.data ? this.props.data.tieringForDate : null);
      console.info('this.state.data.length', this.state.data.length);
      console.info('__________');*/
    if (
      this.props.data &&
        // @fixme data est a la fois array et objet ?
        ((this.props.data.tieringForDate && this.state.data.length === 0)
          &&  (!prevProps.data
          || this.props.data.tieringForDate !== prevProps.data.tieringForDate))
    ) {
      let dateList = Object.keys(this.props.data.tieringForDate);
      this.setState({
        date: dateList[0],
        data: this.formatData(this.props.data.tieringForDate[dateList[0]])
      });
    }
  }

  /**
   * Permet de formatter le tableau de données avec en clé name et value
   *
   * @param dataList
   * @returns {Array}
   */
  formatData(dataList) {
    let datas = [];
    for (let i in dataList) {
      let val = dataList[i];
      datas.push({
        value: parseInt(val.quantity),
        name: val.categoryResult ? "Tier " + val.categoryResult : "No tier"
      });
    }
    return datas;
  }

  updateValue(reportingName, value) {
    if (this.props.onChangeDate) {
      this.props.onChangeDate({ name: reportingName, request: value });
      this.setState({ data: [] });
    }
  }

    render() {
        return (
            <div className="container" style={styles.margin}>
                <Paper style={styles.center}>
                    <AppBar position="static" className="background-lowlight background-linear-gradient">
                        <Toolbar style={styles.toolBar}>
                            <Typography variant="h6">
                                Tiering state
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={styles.date}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                fullWidth={false}
                                margin="normal"
                                id="dateAt"
                                label="Date"
                                value={ this.state.date ? this.state.date : null }
                                onChange={(date) => { this.updateValue('tieringForDate',DateFormatter.dateToIso8601(date)) }}
                                format="dd MMMM yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div style={styles.chart}>
                        <ResponsiveContainer>
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={this.state.data}
                                    labelLine={true}
                                    label={renderCustomizedLabel}
                                    outerRadius={120}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {
                                        this.state.data.map((entry, index) =>{
                                            return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        })
                                    }
                                </Pie>
                                <Legend layout="horizontal" verticalAlign="top" align="center" />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </Paper>
            </div>
        );
    }
}

const styles = {
  toolBar: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center"
  },
  date: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 0,
    marginBottom: 0,
    width: 200
  },
  chart: {
    width: "90%",
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: 50,
    height: 400
  },
  center: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 0,
    marginBottom: 0,
    width: "40%"
  },
  margin: {
    paddingTop: 16,
    paddingBottom: 10
  }
};
