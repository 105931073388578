import React from "react";
import TextProvider from "./TextProvider";
import {CONTEXT_LIST} from "../APIResource";

class DefaultProvider {
  getDisplay(field, value, entity, props, resource, context) {
    return TextProvider.getDisplay(field, value, entity, props, resource, context);
  }

  getDisplayList(field, value, entity, props, resource) {
    return this.getDisplay(field, value, entity, props, resource, CONTEXT_LIST);
  }

  getEdit(field, value, onChange, entity, routeParams, loading = false) {
    return TextProvider.getEdit(field, value, onChange, entity, routeParams, loading);
  }

  getFilter(options) {
    return TextProvider.getFilter(options);
  }

  applyFilter(fieldId, value, activeFilters, filterTrigger) {
    if (value == null || value === "") {
      activeFilters[fieldId] = '';
    } else {
      if (value instanceof Array) {
        let values = [];
        value.forEach(item => {
          let val = item.id ? item.id : item.value ? item.value : item;
          values.push(val);
        });
        activeFilters[fieldId] = values;
      } else {
        value = value.id ? value.id : value.value ? value.value : value;
        activeFilters[fieldId] = value;
      }
    }
    filterTrigger();
  }
}

export default new DefaultProvider();
