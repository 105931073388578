import React, {Component} from "react";
import Modal from '../../Modal';
import TextField from '@material-ui/core/TextField/index';
import Button from '@material-ui/core/Button';
import '../BulkEdit/bulk-edit.css';
import Http from '../../Http';
import Alert from '../../Alert';
import FieldProviderStore from "../../APIResource/FieldProviders/__FieldProviderStore";
import FormControl from "@material-ui/core/FormControl";
import ParameterStore, {getParamByIri, userHasRoleADMIN, userHasRoleMRM} from "../../../Store/ParameterStore";
import Navigation from "../../Navigation";
import { openLoadingModal, validateIds } from "../bulkUtils";
import {ActionButton} from "../../../Components/Modal/ActionButton";
import {ButtonBar} from "../../../Components/Modal/ButtonBar";

export class RemindNotice {
    getId(){
        return 'bulkRemindNotice'
    }

    getLabel(){
        return 'Reminder'
    }

    /**
     * 
     * @param {import('../../APIResource/APIResource').APIResourceBulkParams} params
     */
    setParameters(params){
        this.parameters = params;
    }

    async run(ids, parent = null){
        this.ids = ids;

        openLoadingModal();

        Modal.open({
            title: this.getLabel(),
            content: <RemindNoticeConfirm
                ids={this.ids}
                resource={this.parameters.resource}
            />,
            modalStyle: { width: 520 },
        })
    }
}

export class RemindNoticeConfirm extends Component{
    constructor(props){
        super(props);
        this.state = {
            running: false
        }
    }

    save(){
        let self = this;
        self.setState({running: true});
        Http.post('notices/remind', {
            ids: this.props.ids
        }).then((response) => {
            self.props.resource.apiGetCollection({forceReload: true});
            Alert.show({message: response.message || 'Bulk reminder successfully processed.', type: "success"});
            Modal.close();
            self.setState({running: false});
        });
    }

    render(){
        return(
            <div>
                <div className={"alert alert-info"} role="alert">
                    {`You are about to send an email notification for these ${this.props.ids.length} notices. Do you confirm ?`}
                </div>

                <ButtonBar>
                    <ActionButton
                        onClick={Modal.close}
                        disabled={this.state.running}
                    >
                        Cancel
                    </ActionButton>
                    <ActionButton
                        onClick={this.save.bind(this)}
                        loading={this.state.running}
                    >
                        Confirm
                    </ActionButton>
                </ButtonBar>

            </div>
        );
    }
}