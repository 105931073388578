import React, {Component} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import AlertMui from '@material-ui/lab/Alert';
import String from "./String";

class Alert{
    /**
     * @param {{message: string, type: 'success'|'warning'|'error', noClickaway: boolean}} params
     */
    show(params){
        if(this.ref){
            this.ref.show(params);
        }
    }
}

export default new Alert();

export class AlertProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            message: null,
            type: null,
            noClickaway: null,
        }
    }

    show(params) {
        console.log('Show message', params.type ? params.type : 'success', params.message)
        this.setState({
            message: String.nlToBr(params.message),
            visible: true,
            type: params.type ? params.type : 'success',
            noClickaway: params?.noClickaway || false, 
        });
    }

    onClose (e, reason) {
        if (reason === "clickaway" && this.state.noClickaway) return;
        this.setState({visible: false});
    }

    render() {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.state.visible}
                onClose={this.onClose.bind(this)}
                autoHideDuration={this.state.noClickaway ? 6 * 1000 : 80000 * 1000}
            >
                <AlertMui severity={this.state.type}>{this.state.message}</AlertMui>
            </Snackbar>
        );
    }
}
