import React, { Component } from 'react';
import { observer } from 'mobx-react';
import './display-text-field.css';
import DateFormatter from '../../../Services/DateFormatter';
import String from '../../../Services/String';

export const DisplayTextField = observer(
    class DisplayTextField extends Component {
        constructor(props) {
            super(props);
            this.fieldName = null;
            this.fieldValue = null;
            this.fieldType = null;
        }

        render() {
            if (this.props.hasOwnProperty('fieldName')) {
                this.fieldName = this.props.fieldName;
            }

            if (this.props.hasOwnProperty('value')) {
                this.fieldValue = this.props.value;
            }

            if (this.props.hasOwnProperty('fieldType')) {
                switch (this.props.fieldType) {
                    case 'date':
                        if (this.fieldValue) {
                            this.fieldValue = DateFormatter.dateToIso8601(
                                this.fieldValue
                            );
                        }
                        break;
                    default:
                        break;
                }
            }

            return this.props.plainText ? (
                typeof this.fieldValue === 'string' ? (
                    <span style={this.props.textStyle}>
                        {String.nlToBr(this.fieldValue)}
                    </span>
                ) : this.fieldValue !== undefined &&
                  this.fieldValue !== null ? (
                    <span style={this.props.textStyle}>
                        {this.fieldValue}
                    </span>
                ) : (
                    ''
                )
            ) : (
                <fieldset
                    className={
                        'display-text-field ' + (this.props.className ?? '')
                    }
                    id={this.props.id}
                >
                    <legend className="legend-text-field">
                        {this.fieldName}
                    </legend>
                    <span style={this.props.textStyle}>
                        {typeof this.fieldValue === 'string'
                            ? String.nlToBr(this.fieldValue)
                            : this.fieldValue}
                    </span>
                </fieldset>
            );
        }
    }
);
