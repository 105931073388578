import React, { PureComponent } from 'react';
import {
    PieChart, Pie, Sector, Cell, Legend, ResponsiveContainer
} from 'recharts';
import {Paper} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import DateFormatter from "../../../Services/DateFormatter";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,KeyboardDatePicker} from '@material-ui/pickers';

const COLORS = ['#00838A','#BDCF41', '#F6A800',  '#E74D5D'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, name, value, index, }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central"
              style={{fontSize:16}}>
            {`${(percent * 100).toFixed(0)}% (${value})`}
        </text>
    );
};

export default class ModelsByStatusForDate extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data : [],
            date: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.data && ((this.props.data.modelsByStatusForDate && this.state.data.length === 0) || this.props.data !== prevProps.data)){
            let dateList = Object.keys(this.props.data.modelsByStatusForDate);
            this.setState({date: dateList[0]});
            this.setState({data: this.formatData(this.props.data.modelsByStatusForDate[dateList[0]])});
        }
    }

    /**
     * Permet de formatter le tableau de données avec en clé name et value
     *
     * @param dataList
     * @returns {Array}
     */
    formatData(dataList){
        let datas = [];

        for(let status in dataList){
            let val = dataList[status];
            let statusId = this.getStatusId(status);

            // on affiche pas la stat pour les statuts "Null"
            if(statusId === 'null'){
                continue;
            }

            datas.push({'name': this.getModelStatusValues(parseInt(statusId)), 'value': parseInt(val) });
        }
        return datas;
    }

    /**
     * Récupération du statutID dans le nom des résultats des stats
     * @param statusString
     * @returns {*|string}
     */
    getStatusId(statusString){
        let statusTab = statusString.split('_');
        return statusTab[2];
    }

    /**
     * Liste des statuts en fonction de leur Id
     * @param val
     * @returns {string}
     */
    getModelStatusValues(val){
        switch (val) {
            case 0 : return 'OK - points of attention';
            case 1 : return 'OK - with minor issues';
            case 2 : return 'OK - without reservation';
            case 3 : return 'Not OK - redesign';
            default:
                return '';
        }
    }

    updateValue(reportingName, value){
        if(this.props.onChangeDate){
            this.props.onChangeDate({'name': reportingName, 'request': value});
            this.setState({data: []});
        }
    }

    render() {
        return (
            <div className="container container-no-padding-sides" style={styles.margin}>
                <Paper style={styles.center}>
                    <AppBar position="static" className="background-lowlight background-linear-gradient">
                        <Toolbar style={styles.toolBar}>
                            <Typography variant="h6">
                                Validation status
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div style={styles.date}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                fullWidth={true}
                                margin="normal"
                                id="dateAt"
                                label="Date"
                                value={ this.state.date ? this.state.date : null }
                                onChange={(date) => { this.updateValue('modelsByStatusForDate',DateFormatter.dateToIso8601(date)) }}
                                format="dd MMMM yyyy"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div style={styles.chart}>
                        <ResponsiveContainer>
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={this.state.data}
                                    labelLine={true}
                                    label={renderCustomizedLabel}
                                    outerRadius={100}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {
                                        this.state.data.map((entry, index) =>{
                                            return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        })
                                    }
                                </Pie>
                                <Legend layout="horizontal" verticalAlign="top" align="center" />
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </Paper>
            </div>
        );
    }
}

const styles = {
    toolBar: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    container:{
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    chart:{
        width: '90%',
        marginLeft: '5%',
        marginRight: '5%',
        marginTop: 50,
        height: 400,
    },
    date:{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 0,
        marginBottom: 0,
        width: 200,
    },
    center:{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 0,
        marginBottom: 0,
        width: '100%',
        height: '100%',
    },
    margin: {
        paddingBottom: 0,
    }
}