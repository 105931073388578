import React, { Component } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField/index";
import Modal from "../../../Services/Modal";
import { DateRangePicker } from "./DateRangePicker";
import DateFormatter from "../../../Services/DateFormatter";
import { Button, Menu, MenuItem, Select } from "@material-ui/core";
import { DatePicker } from "../DatePicker/DatePicker";

export const DATE_PICKER_OPERATION_EQUALS = 'equals';
export const DATE_PICKER_OPERATION_AFTER = 'after';
export const DATE_PICKER_OPERATION_BEFORE = 'before';
export const DATE_PICKER_OPERATION_BETWEEN = 'between';
const DATE_PICKER_OPERATIONS = [
  DATE_PICKER_OPERATION_EQUALS,
  DATE_PICKER_OPERATION_AFTER,
  DATE_PICKER_OPERATION_BEFORE,
  DATE_PICKER_OPERATION_BETWEEN,
]

export class ModalDateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.openModalPicker = this.openModalPicker.bind(this);
    this.openModalDatePicker = this.openModalDatePicker.bind(this);
    this.openModalDateRangePicker = this.openModalDateRangePicker.bind(this);
    this.testEmptyValue = this.testEmptyValue.bind(this);
    this.editProcess = false;
    this.state = {
      value: this.formatValue(this.props.value),
      operation: DATE_PICKER_OPERATION_BETWEEN,
    };
  }

  componentDidMount() {
    this.setState({ value: this.formatValue(this.props.value) });
  }

  componentDidUpdate(prevProps) {
    if( this.props.value && this.props.value !== prevProps.value) {
      this.setState({ value: this.formatValue(this.props.value) });
    }
  }

  handleChange(firstDate, secondDate) {
    // On ferme la modal éventuellement encore ouverte par DatePicker
    Modal.close();

    this.editProcess = false;
    let startDate = firstDate;
    let endDate = secondDate;
    if (this.state.operation === DATE_PICKER_OPERATION_BEFORE) {
      startDate = null;
      endDate = firstDate;
    }
    this.setState({
      value: this.formatValue({ startDate, endDate })
    });
    if (this.props.onChange) {
      this.props.onChange(startDate, endDate, this.state.operation);
    }
  }

  formatValue(options = {}) {
    const { startDate = "", endDate = "" } = options;
    if (!options || (!startDate && !endDate)) {
      return "";
    }

    const displayString = (startDate && DateFormatter.dayIsoToString(startDate) || "" ) + (startDate && endDate && "-") + (endDate && DateFormatter.dayIsoToString(endDate) || "")
    return displayString;
  }

  openModalPicker() {
    if (this.state.operation === DATE_PICKER_OPERATION_BETWEEN) {
      this.openModalDateRangePicker();
    } else {
      this.openModalDatePicker();
    }
  }

  openModalDateRangePicker() {
    this.editProcess = true;
    this.setState({ value: "" });
    Modal.open({
      title: this.props.title ? this.props.title : "Date range",
      modalStyle: {minHeight: "550px"},
      content: <DateRangePicker onChange={this.handleChange.bind(this)} />
    });
  }

  openModalDatePicker() {
    this.editProcess = true;
    this.setState({ value: ""});
    Modal.open({
      title: "Date",
      size: "medium",
      content: <DatePicker onChange={this.handleChange.bind(this)} />,
      modalStyle: {
        top: "160px",
        position: "absolute",
      }
    })
  }

  testEmptyValue(_e) {
    if (this.editProcess) {
      this.editProcess = false;
      this.props.onChange(null, null);
    }
  }

  render() {
    return (
      <div style={{display: "flex"}}>
        <Select
            multiple={false}
            onChange={(e) => this.setState({operation: e.target.value, value: ""}, () => this.openModalPicker())}
            value={this.state.operation}
            style={styles.operationSelect}
        >
            {DATE_PICKER_OPERATIONS.map(
                (v, k) => <MenuItem key={`operation_${k}`} value={v}>{v}</MenuItem>                    
            )}
        </Select>
        <TextField
          value={this.state.value}
          onClick={this.openModalPicker}
          onFocus={this.testEmptyValue}
          placeholder={this.props.placeholder ? this.props.placeholder : ""}
          inputProps={{style: styles.displayString}}
        />
      </div>
    );
  }
}
ModalDateRangePicker.propTypes = {
    value: PropTypes.shape({
        startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
        endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    }),
    title: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
};
const styles = {
    operationSelect: {
        //paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        //marginLeft: "5px",
        //marginRight: "5px",
        fontSize: "0.8rem",
        lineHeight: "0.6rem",
        color: "white",
        width: "auto",
    },
    displayString: {
        fontSize: "0.8rem",
    },
}
