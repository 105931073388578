import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

/**
 * Tooltip optionnel, ne s'affiche que si le "title" est rempli.
 * 
 * @param {Object} props
 * @param {string} props.title Texte à afficher en tooltip, si vide, on n'affiche pas de tooltip
 */
export const OptionalTooltip = (props) => {
    return props.title ? <Tooltip {...props}>
        <div style={{display: "inline-block"}}>
            {props.children /** dans un wrapper div, car si children est "disabled", il n'envoie pas les events */}
        </div>
    </Tooltip> : <>{props.children}</>
}
OptionalTooltip.propTypes = Tooltip.propTypes;
