import React, {Component} from "react";
import Modal from "../../../Services/Modal";
import Alert from "../../../Services/Alert";
import Navigation from "../../../Services/Navigation";
import {Typography} from "@material-ui/core";
import ParameterStore from '../../../Store/ParameterStore';
import { ButtonBar } from "../../Modal/ButtonBar";
import { ActionButton } from "../../Modal/ActionButton";
import { ModalContent } from "../../Modal/ModalContent";
import {
    DOCUMENT_ACTION_DELETE,
    DOCUMENT_ACTION_SHOW,
    DocumentAddForm,
    DocumentManager
} from "../../Display/DocumentManager/DocumentManager";
import {EntityForm} from "../EntityForm/EntityForm";
import Http from "../../../Services/Http";
import {fieldTypeFormatValidate} from "../../../Services/APIResource/Utils";
import DateFormatter from "../../../Services/DateFormatter";

export class NoticeActionForm extends Component {
    constructor(props) {
        super(props);

        /** @type {{resource: import('../../../Services/APIResource/APIResource').APIResource}} */
        this.props;

        this.state = {
            comment: null,
            progress: false,
        };

        this.bulk = !!this.props.ids;

        this.notice = null;
        this.noticeFields = {
            deliveryDate: {
              title: 'Delivery date',
              type: 'date',
              helperText: 'Delivery date of LoD 1 documentation in order to close the notice',
              required:true
            },
            effectiveClosingDate: {
                title: 'Closing date',
                type: 'date',
                bulk: true,
                helperText: 'Closing date of the notice following LoD 2 validation',
                required: true
            },
            closingComment: {
                title: 'Closing comment',
                type: 'textarea',
                helperText: 'Comment following the validation of the notice closing by LoD 2',
            },
            documentsEntities: {
                title:
                    "Please attach documents to justify the delivery.",
                type: "documents",
                params: {
                    entityResource: "notices",
                    propertyName: "documentsEntities",
                    fieldName: "Document",
                    allowedCategory: false,
                    links: false,
                    allowedAction: (entity, document, action) =>
                        [DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_DELETE].includes(action),
                },
                // eslint-disable-next-line react/display-name
                display: (field, value, entity) => (
                    <DocumentManager
                        values={value}
                        entity={entity}
                        entityResource={"notices"}
                        fieldName={"Document"}
                        propertyName={"documentsEntities"}
                        allowedAction={(entity, document, action) =>
                            [DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_DELETE].includes(action)
                        }
                    />
                ),
            },
            requestComment: {
                title: 'Delivery comment',
                type: 'textarea',
            },
        }
        if(this.props.action === 'close'){
            this.noticeFields = {
                deliveryDate: {
                  title: 'Delivery date',
                  type: 'date',
                  helperText: 'Delivery date of LoD 1 documentation in order to close the notice',
                  required:true
                },
                effectiveClosingDate: {
                    title: 'Closing date',
                    type: 'date',
                    bulk: true,
                    helperText: 'Closing date of the notice following LoD 2 validation',
                    params: {
                        minDateMessage: "The delivery date should not be later than the Closing date",
                        minDate: !this.bulk ? this.props.entity.deliveryDate : null
                    }
                },
                closingComment: {
                    title: 'Closing comment',
                    type: 'textarea',
                    helperText: 'Comment following the validation of the notice closing by LoD 2',
                    required: true
                },
                documentsEntities: {
                    title:
                        "Please attach documents to justify the closure",
                    type: "documents",
                    params: {
                        entityResource: "notices",
                        propertyName: "documentsEntities",
                        fieldName: "Document",
                        allowedCategory: false,
                        links: false,
                        allowedAction: (entity, document, action) =>
                            [DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_DELETE].includes(action),
                    },
                    // eslint-disable-next-line react/display-name
                    display: (field, value, entity) => (
                        <DocumentManager
                            values={value}
                            entity={entity}
                            entityResource={"notices"}
                            fieldName={"Document"}
                            propertyName={"documentsEntities"}
                            allowedAction={(entity, document, action) =>
                                [DOCUMENT_ACTION_SHOW, DOCUMENT_ACTION_DELETE].includes(action)
                            }
                        />
                    ),
                },
            }
        }else if(this.props.action === 'dismiss'){
            this.noticeFields = {
                closingComment: {
                    title: 'Closing comment',
                    type: 'textarea',
                    helperText: 'Comment following the validation of the notice closing by LoD 2',
                    required: true
                },
            }
        }else if(this.props.action === 'moreInfo'){
            this.noticeFields = {
                requestComment: {
                    title: 'Delivery comment',
                    type: 'textarea',
                    required: true
                },
            }
        }
    }

    /**
     * @returns 
     */
    handleClick() {

        if (this.props.action === 'close') {
            if(!this.notice.deliveryDate){
                Alert.show({
                    message: "The delivery date is required.",
                    type: "error"
                });
                return;
            }          
            if(!this.notice.effectiveClosingDate){
                Alert.show({
                    message: "The closing date is required.",
                    type: "error"
                });
                return;
            }
            if(!this.notice.closingComment){
                Alert.show({
                    message: "The closing comment is required.",
                    type: "error"
                });
                return;
            }

            let closingCommentError = fieldTypeFormatValidate(this.noticeFields.closingComment, this.notice.closingComment);
            if(closingCommentError !== false){
                Alert.show({
                    message: `${this.noticeFields.closingComment.title}: ${closingCommentError.detail}`,
                    type: 'error'
                });
                return;
            }

            if(
                !this.notice.documentsEntities
                || (
                    Array.isArray(this.notice.documentsEntities)
                    && this.notice.documentsEntities.length === 0
                )
            ){
                Alert.show({
                    message: "Please attach a supporting document in order to close the notice.",
                    type: "error"
                });
                return;
            }

            if(
                this.notice.deliveryDate 
                && this.notice.effectiveClosingDate
                && (this.notice.deliveryDate > this.notice.effectiveClosingDate)
            ) {
                    Alert.show({
                        message: "The delivery date should not be later than the Closing date",
                        type: "error"
                    });
                    return;
            }

        }else if(
            (this.props.action === 'dismiss' && this.notice.closingComment === null)
            || (this.props.action === 'moreInfo' && this.notice.requestComment === null)
        ){
            Alert.show({
                message: "The comment is required.",
                type: "error"
            });
            return;
        }

        if(this.props.action === 'dismiss' || this.props.action === 'moreInfo'){
            let commentError = false;
            if(this.props.action === 'moreInfo'){
                commentError = fieldTypeFormatValidate(this.noticeFields.requestComment, this.notice.requestComment);
            }else{
                commentError = fieldTypeFormatValidate(this.noticeFields.closingComment, this.notice.closingComment);
            }

            if(commentError !== false){
                Alert.show({
                    message: `${this.noticeFields.closingComment.title}: ${commentError.detail}`,
                    type: 'error'
                });
                return;
            }
        }


        this.setState({progress: true});

        let currentStatus = this.notice.status;

        if(this.props.action === 'close'){
            this.notice.status = ParameterStore('NOTICE_STATUS_CLOSED');
        }else if(this.props.action === 'dismiss'){
            this.notice.status = ParameterStore('NOTICE_STATUS_DISMISSED');
        }else if(this.props.action === 'moreInfo'){
            this.notice.status = ParameterStore('NOTICE_STATUS_IN_PROGRESS');
        }

        let promise = null;
        if(!this.bulk){
            const newFields = Object.keys(this.noticeFields).reduce((acc, field) => {
                acc[field] = this.notice[field];
                return acc;
            }, {});
            promise = this.props.resource.apiPut({
                id: this.notice.id,
                status: this.notice.status,
                ...newFields,
            });
        }else{
            promise = Http.post('notices/close', {
                ids: this.props.ids,
                entity: this.notice
            });
        }

        promise.then(async (notice) => {

            if(this.props.successMessage){
                Alert.show({ message: this.props.successMessage, type: "success" });
            }

            if (this.bulk) {
                // Il faut mettre à jour les ressources depuis le back.
                // On utilise getOne pour récupérer les entities en particulier.
                this.props.ids.forEach(id => this.props.resource.apiGetOne(id, true));
            }

            if(this.props.callback){
                this.setState({progress: false});
                Modal.close();
                this.props.callback(notice, this.props.resourceDetailComponent);
            } else {
                Modal.close();
            }
            if(!this.bulk){
                Navigation.router?.history.push("/resource/" + this.props.resource.instanceId + "/" + this.notice.id + '/detail');
            }
        }).catch(err => {
            if(!this.bulk) {
                this.notice.status = currentStatus;
                this.setState({progress: false});
                Navigation.router?.history.push("/resource/" + this.props.resource.instanceId + "/" + this.notice.id + '/detail');
            }
            console.error(err);
            Modal.close();
        });
    }


    getTitle = () => {
        if (this.props.entity || this.props.ids) {
            if(this.props.action === 'close'){
                return `A supporting document is required in order to close the notice (LoD 1 documentation, folder path of the evidence or committee minutes).`;
            }else if(this.props.action === 'dismiss'){
                return `Do you want to cancel the notice ${this.props.entity.title} ? If so, please justify the cancellation.`;
            }else if(this.props.action === 'moreInfo'){
                return `You are about to request more information to the notice owner.`;
            }
        }
    };

    setNotice(getFields){
        this.notice = getFields(this.notice);
    }

    render() {
        this.notice = {...(this.props.entity ?? {})};
        if (this.props.action === 'close') {
            this.notice.effectiveClosingDate = this.notice.effectiveClosingDate ?? new Date();
            if (this.notice.deliveryDate) this.notice.deliveryDate = this.notice.deliveryDate instanceof Date ? this.notice.deliveryDate : DateFormatter.parseDate(this.notice.deliveryDate); 
        }
        return (
            <ModalContent>
                <Typography component={"p"}>{this.getTitle()}</Typography>
                <EntityForm
                    entity={this.notice}
                    fields={this.noticeFields}
                    onUpdate={(n) => this.setNotice(n)}
                />
                <ButtonBar>
                    <ActionButton
                        disabled={this.state.progress}
                        onClick={Modal.close}
                    >
                        Cancel
                    </ActionButton>
                    <ActionButton
                        onClick={() => this.handleClick()}
                        loading={this.state.progress}
                    >
                        {this.props.actionTitle}
                    </ActionButton>
                </ButtonBar>
            </ModalContent>
        );
    }
}
