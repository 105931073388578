import React, { useMemo } from 'react';
import BaseSelect from "./BaseSelect"

function OperatorSelect({ name, label, operators, onChange, value }) {
    if (value && !operators.includes(value)) {
        onChange(operators[0]);
    }

    if (!operators) return null;

    const options = useMemo(() => {
        return operators.map((field) => ({
            label: field,
            value: field,
        }));
    }, [operators]);

    return (
        <BaseSelect
            key={JSON.stringify(options)}
            name={name}
            label={label}
            options={options}
            value={value}
            onChange={onChange}
        />
    );
}

export default OperatorSelect;
