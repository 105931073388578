import App from "../../Config/App";
import Http from "../Http";
import { decorate, observable } from "mobx/lib/mobx";
import Environment from "../Environment";
import Navigation from "../Navigation";
import $ from "jquery";

class User {
    /**
     * @type {{isMemberOfValidatorTeam: boolean, id: any, roles: Array<string>, fullName: string}}
     */
    profile = {};
    jwt = null;
    lastActivity = new Date();
    settings = {};

    constructor() {
        this.init();
    }

    init() {
        this.jwt = null;
        this.profile = {};
        this.settings = {};
        this.updateJWTToken();
        this.restore();
        this.trackActivity();
    }

    updateJWTToken() {
        let jwt = Environment.getUriParameter("jwt");
        if (jwt) {
            this.jwt = jwt;
            this.save();
            setTimeout(() => {Navigation.router.history.push("/");}, 500);
        }
    }

    save() {
        localStorage.setItem("user", JSON.stringify(this));
        //Init les settings dans la sessionStorage
        this.profile.settings?.forEach((config) => {
            if(config.code.includes('resourceConfig')){
                let code = config.code.replace('resourceConfig', 'ApiResourceConfig')
                window.sessionStorage.setItem(code, JSON.stringify({
                    displayFields: config.value.displayFields,
                    filters: {},
                    page: 1,
                    rowsPerPage: 25
                }));
            }

        })

    }

    async restore(forceFromBackend = false) {
        if (window.location.pathname.match(/^\/logout$/)) {
            return Promise.resolve();
        }
        let user = localStorage.getItem("user");
        if(Environment.getUriParameter('dev')){
            this.profile.env = 'dev';
        }
        if (user || forceFromBackend) {
            user = JSON.parse(user);
            this.jwt = user.jwt;
            this.profile = user.profile;
            Http.setJWT(this.jwt);
            return Http.get("user/profile", { cache: forceFromBackend }).then(response => {
                if(Environment.getUriParameter('dev')){
                    response.profile.env = 'dev';
                }
                this.profile = response.profile;
                this.save();
            });
        } else {
            if (!Environment.getUriParameter("jwt")) {
                window.location = App.backend + "/auth/login";
            }
            return Promise.resolve();
        }
    }

    // semantically more correct than restore;
    refresh() {
        return this.restore();
    }

    getId() {
        if (this.profile && this.profile.id) {
            return this.profile.id;
        }
        let user = localStorage.getItem("user");
        if (user) {
            try {
                user = JSON.parse(user);
                this.profile = user.profile;
                return this.profile.id;
            } catch (err) {
                console.warn('JSON error in user data');
            }
        }
        throw new Error('no_id_found');
    }

    logout() {
        localStorage.removeItem("user");
        window.sessionStorage.clear();
        this.init();
    }

    trackActivity() {
        let tracking = () => {
            let now = new Date();
            let limitDate = new Date(now.getTime() - 60 * 60 * 1000);
            if (this.lastActivity.getTime() < limitDate.getTime()) {
                if (!window.location.pathname.match(/^\/logout$/)) {
                    Navigation.router.history.push("/logout");
                }
            }
        };
        setInterval(tracking, 1000);
        $("body").on("mousemove", () => {
            this.lastActivity = new Date();
        });
        $("body").on("keypress", () => {
            this.lastActivity = new Date();
        });
    }

    /**
     * Is the user has this specific rôle (no hierarchy logic)
     * @param role
     * @returns {boolean}
     */
    hasSpecificRole = (role) => {
        const userRoles = JSON.parse(JSON.stringify(this.profile.roles || []));
        // returns true if has the role
        return userRoles.includes(role);
    }

    /**
     * By hierarchy
     * @param roles
     * @returns {boolean}
     */
    hasOneRole = (roles) => {
        if (!roles) {
            return false;
        }
        roles = Array.isArray(roles) ? roles : [roles];
        const userRoles = JSON.parse(JSON.stringify(this.profile.roles || []));

        // Fulfill role hierarchy
        const roleHierarchy = {
            ROLE_SUPER_ADMIN: [ROLE.ADMIN],
            ROLE_ADMIN: [ROLE.MRM],
            ROLE_MRM: [ROLE.STD_USER, ROLE.IG]
        };

        let nbRoles;
        let allRoles = userRoles;
        do {
            nbRoles = allRoles.length;
            allRoles.map(r => {
                if (roleHierarchy[r]) {
                    allRoles = allRoles.concat(...roleHierarchy[r]);

                    // undup
                    allRoles = [...new Set(allRoles)];
                }
            });
        } while (nbRoles !== allRoles.length);

        // returns true if has at least one role
        return !!roles.map(role => allRoles.includes(role)).filter(i => i).length;
    }

    /**
     * Permet de savoir si un utilisateur possède un "BusinessRole" pour une collection
     * d'entités.
     * 
     * @param {string} businessRoleOfEntityType - {@see ParameterStore.BusinessRoleOfEntityType} pour les valeurs possibles
     * @returns 
     */
    is = (businessRoleOfEntityType) => {
        const userRoles = JSON.parse(JSON.stringify(Object.keys(this.profile.userRoles || {})));
        return userRoles.includes(businessRoleOfEntityType);
    }

    async getSettingByCode(code) {
        Http.setJWT(this.jwt);
        const currentRequest = Http.getRequest();
        if (this.currentSettingsGetRequest) {
            this.currentSettingsGetRequest.cancel('NoAlert');
        }
        if (this.settings?.[code]) return this.settings[code];

        this.currentSettingsGetRequest = currentRequest;
        return Http.get(`user_settings?code=${code}`, { 
            request: this.currentSettingsGetRequest,
            cache: false,
        }).then(
            (data) => this.extractSettings((data && data['hydra:member'] && data['hydra:member'][0]) || null, code)
        );
    }

    extractSettings(setting, code = undefined) {
        const settingCode = code || setting?.code;
        if (settingCode) this.settings[settingCode] = setting;
        return setting;
    }

    listSettings(type = undefined) {
        Http.setJWT(this.jwt);
        return Http.get(`user_settings${type ? '?type=' + type : ''}`, {
            cache: false,
        });
    }

    getSettingById(id) {
        return this.getSetting(id);
    }

    getSetting(settingId) {
        Http.setJWT(this.jwt);
        return Http.get(`user_settings/${settingId}`, { cache: false });
    }

    addSetting(setting) {
        Http.setJWT(this.jwt);
        if (this.currentSettingsPostRequest) {
            this.currentSettingsPostRequest.cancel('NoAlert');
        }
        this.currentSettingsPostRequest = Http.getRequest();
        return Http.post('user_settings', setting, { cache: false, request: this.currentSettingsPostRequest }).then(
            data => this.extractSettings(data)
        );
    }

    editSetting(setting) {
        Http.setJWT(this.jwt);
        if (this.currentSettingsPutRequest) {
            this.currentSettingsPutRequest.cancel('NoAlert');
        }
        this.currentSettingsPutRequest = Http.getRequest();
        return Http.put(`user_settings/${setting.id}`, setting, { request: this.currentSettingsPutRequest }).then(
            data => this.extractSettings(data)
        );
    }

    deleteSetting(setting) {
        Http.setJWT(this.jwt);
        if (this.currentSettingsDeleteRequest) {
            this.currentSettingsDeleteRequest.cancel('NoAlert');
        }
        this.currentSettingsDeleteRequest = Http.getRequest();
        return Http.delete(`user_settings/${setting.id || setting}`, {
            cache: false,
            request: this.currentSettingsDeleteRequest,
        }).then(() => setting?.code && delete this.settings[setting.code]);
    }

    updateUser(user) {
        Http.setJWT(this.jwt);
        return Http.put(`users/${this.getId()}`, user, {
            cache: false
        })
            .then(user => {
                this.profile = user;
                this.save();
                return this.profile;
            })
            ;
    }
}

decorate(User, {
    profile: observable
});

export default new User();

export const ROLE = {
    SUPER_ADMIN: "ROLE_SUPER_ADMIN",
    ADMIN: "ROLE_ADMIN",
    MRM: "ROLE_MRM",
    IG: "ROLE_IG",
    STD_USER: "ROLE_STD_USER"
};
